import {trackingDomainConstants} from "../constants/trackingDomainConstants";

const initialState = {
    trackingDomains: [],
    successMsg: '',
    errorMsg: '',
    isLoading: false,
    activeStep: 0,
    trackingDomain: ""
};

const trackingDomainReducer = (state = initialState, action) => {
    switch (action.type) {
        case trackingDomainConstants.TRACKING_DOMAIN_SET_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case trackingDomainConstants.LOADING_TRACKING_DOMAINS:
            return {
                ...state,
                trackingDomains: action?.result?.trackingDomains,
                successMsg: '',
                errorMsg: '',
                isLoading: false,
            };
        case trackingDomainConstants.LOADING_TRACKING_DOMAINS_ERROR:
            return {
                ...state,
                successMsg: '',
                errorMsg: 'Unable to load tracking domains. Please try again.',
                isLoading: false,
            };
        case trackingDomainConstants.DELETE_TRACKING_DOMAIN:
            return {
                ...state,
                trackingDomains: action.domains,
                successMsg: 'Tracking domain has been successfully deleted.',
                errorMsg: '',
                isLoading: false,
            };
        case trackingDomainConstants.DELETE_TRACKING_DOMAIN_ERROR:
            return {
                ...state,
                successMsg: '',
                errorMsg: action.error,
                isLoading: false,
            };
        case trackingDomainConstants.ADD_TRACKING_DOMAIN:
            return {
                ...state,
                trackingDomains: action.domains,
                successMsg: 'Tracking domain has been successfully added.',
                errorMsg: '',
                isLoading: false,
                activeStep: 0,
                trackingDomain: ""
            };
        case trackingDomainConstants.ADD_TRACKING_DOMAIN_ERROR:
            return {
                ...state,
                successMsg: '',
                errorMsg: action.error,
                isLoading: false
            };
        case trackingDomainConstants.TRACKING_DOMAIN_CLEAR_ERRORS:
            return {
                ...state,
                successMsg: '',
                errorMsg: ''
            };
        case trackingDomainConstants.SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.activeStep
            };
        case trackingDomainConstants.SET_TRACKING_DOMAIN:
            return {
                ...state,
                trackingDomain: action.trackingDomain
            };
        case trackingDomainConstants.SET_NEW_DEFAULT_DOMAIN:
            return {
                ...state,
                trackingDomains: state.trackingDomains.map((value, index, array) => {
                    value.isDefault = value?.domain === action?.newDomain;
                    return value;
                })
            }
        default:
            return state;
    }
};

export default trackingDomainReducer;