import React, {useState} from "react";
import PropTypes from "prop-types";
import GroupBySelect from "./GroupBySelect";
import {useForm} from "react-hook-form";
import {FileCopy, GetApp, Refresh, ViewColumn} from "@mui/icons-material";
import {connect} from "react-redux";
import {groupBy1Action} from "../../store/actions/groupBy1Action";
import {groupBy2Action} from "../../store/actions/groupBy2Action";
import {groupBy3Action} from "../../store/actions/groupBy3Action";
import {Box, IconButton, Tooltip, Button} from "@mui/material";
import AirbnbDateRangePicker from "../utils/AirbnbDateRangePicker";
import {ddToolbarActions} from "../../store/actions/ddToolbarActions";
import {GetLocalStorageCache, SetLocalStorageCache} from "../cache/Cache";
import ColumnSettings from "./ColumnSettings";

function DetailPanelToolbar(props) {
    const {campaignId, onClickRefresh, columns, report, columnGroup} = props;
    const {control, watch, setValue} = useForm();
    const {from, to, dateLabel} = props.ddToolbarState;

    const [openColumnSettings, setOpenColumnSettings] = useState(false);

    const handleGroupBySelectedOptionCache = (groupByNumber, groupBy) => {

        if (props?.campaignsState?.selectedRows.size > 1) {
            return false;
        }

        let trafficSourceName = "";
        props?.campaignsState?.data?.records?.forEach(item => {
            if (item.id === campaignId) {
                trafficSourceName = item?.trafficSourceName;
            }
        });

        let cache = GetLocalStorageCache("groupBySelect");
        if (cache !== null && cache !== undefined && cache !== "") {
            for (const item of cache) {
                if (item?.name === trafficSourceName) {
                    if (groupByNumber === 1) {
                        props.updateGroupBy1(groupBy)
                        item.g1 = groupBy;
                    } else if (groupByNumber === 2) {
                        props.updateGroupBy2(groupBy)
                        item.g2 = groupBy;
                    } else if (groupByNumber === 3) {
                        props.updateGroupBy3(groupBy)
                        item.g3 = groupBy;
                    }
                }
            }
            SetLocalStorageCache("groupBySelect", cache);
        }
    };

    const handleOnCloseColumnSettings = () => {
        setOpenColumnSettings(false);
        onClickRefresh();
    };

    const handleOpenColumnSettings = () => {
        setOpenColumnSettings(true);
    };

    return (
        <Box display="flex" flexDirection="row" sx={{
            overflow: "auto",
            paddingTop: 0,
            paddingLeft: 1,
            paddingBottom: 0,
            backgroundColor: "background.paper"
        }}>
            <Box display="flex" flexDirection="row">
                <Box mr={1} width="225px">
                    <GroupBySelect
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        updateGroupBy={(selectedOption) => {
                            props?.groupBy1State?.selectedRows?.clear();
                            props?.groupBy1State?.selectedUUIDs?.clear();
                            props?.groupBy2State?.selectedRows?.clear();
                            props?.groupBy2State?.selectedUUIDs?.clear();
                            props?.groupBy3State?.selectedRows?.clear();
                            props?.groupBy3State?.selectedUUIDs?.clear();

                            if (selectedOption?.groupBy === "ipv4") {
                                props?.updateGroupBy2({
                                    "label": "Date",
                                    "groupBy": "date",
                                    "sortBy": "date"
                                });
                                props?.updateGroupBy3({
                                    "label": "Hour Of Day",
                                    "groupBy": "hour_of_day",
                                    "sortBy": "hour_of_day"
                                });
                            }
                            handleGroupBySelectedOptionCache(1, selectedOption);
                            props?.updateGroupBy1(selectedOption)
                        }}
                        campaignId={campaignId}
                        inputName="groupBy"
                        inputLabel="Group by 1"
                        defaultValue={props?.groupBy1State?.groupBy}
                    />
                </Box>
                <Box mr={1} width="225px">
                    <GroupBySelect
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        updateGroupBy={(selectedOption) => {
                            props?.groupBy2State?.selectedRows?.clear();
                            handleGroupBySelectedOptionCache(2, selectedOption);
                            props?.updateGroupBy2(selectedOption)
                        }}
                        campaignId={campaignId}
                        inputName="groupBy2"
                        inputLabel="Group by 2"
                        defaultValue={props?.groupBy2State?.groupBy}
                    />
                </Box>
                <Box mr={1} width="225px">
                    <GroupBySelect
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        updateGroupBy={(selectedOption) => {
                            props?.groupBy3State?.selectedRows?.clear();
                            handleGroupBySelectedOptionCache(3, selectedOption);
                            props?.updateGroupBy3(selectedOption);
                        }}
                        campaignId={campaignId}
                        inputName="groupBy3"
                        inputLabel="Group by 3"
                        defaultValue={props?.groupBy3State.groupBy}
                    />
                </Box>
                <Box mt="7px">
                    <Tooltip title="Export to CSV" placement="top">
                        <Button
                            onClick={() => props.onClickExportCSV()}
                            variant="outlined"
                            color="inherit"
                            startIcon={<GetApp/>}
                            sx={{
                                height: 35.69,
                                textTransform: "capitalize",
                                borderColor: "border.button",
                                whiteSpace: "nowrap",
                                marginRight: "8px"
                            }}
                        >
                            CSV
                        </Button>
                    </Tooltip>
                </Box>
                <Box mt="7px">
                    <Tooltip title="Copy names (separated by comma)" placement="top">
                        <Button
                            onClick={() => props.onClickCopyNames()}
                            variant="outlined"
                            color="inherit"
                            sx={{
                                height: 35.69,
                                textTransform: "capitalize",
                                borderColor: "border.button",
                                whiteSpace: "nowrap",
                                marginRight: "8px"
                            }}
                            startIcon={<FileCopy color="primary"/>}
                        >
                            Copy
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            <Box display="flex" width="100%" justifyContent="flex-end"
                 style={{paddingLeft: 20, paddingRight: 8, paddingTop: 2}}>
                <Box style={{paddingTop: 5}}>
                    <AirbnbDateRangePicker
                        from={from}
                        to={to}
                        dateLabel={dateLabel}
                        onChangeDate={(from, to, dateLabel) => {
                            props?.updateDateRange(from, to, dateLabel)
                        }}
                    />
                </Box>
                <Box p={1}>
                    <Tooltip title="Column settings" placement="top">
                            <span>
                                <IconButton
                                    color="inherit"
                                    onClick={() => handleOpenColumnSettings()}
                                    aria-label="column-settings"
                                    size="small"
                                >
                                    <ViewColumn color="inherit"/>
                                </IconButton>
                            </span>
                    </Tooltip>
                    {openColumnSettings &&
                        <ColumnSettings
                            columns={columns}
                            onClose={handleOnCloseColumnSettings}
                            open={openColumnSettings}
                            report={report}
                            columnGroup={columnGroup}
                        />
                    }
                </Box>
                <Box p={1}>
                    <Tooltip title="Refresh" placement="top">
                            <span>
                                <IconButton
                                    color="inherit"
                                    onClick={onClickRefresh}
                                    aria-label="refresh"
                                    size="small"
                                >
                                    <Refresh color={"inherit"}/>
                                </IconButton>
                            </span>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
}

DetailPanelToolbar.prototype = {
    campaignId: PropTypes.number.isRequired,
    onClickRefresh: PropTypes.func.isRequired,
    onClickExportCSV: PropTypes.func.isRequired,
    onClickCopyNames: PropTypes.func.isRequired,
    report: PropTypes.func.isRequired,
    columns: PropTypes.func.isRequired,
    columnsGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        campaignsState: state.campaign,
        groupBy1State: state.groupBy1,
        groupBy2State: state.groupBy2,
        groupBy3State: state.groupBy3,
        ddToolbarState: state.ddToolbar
    }
};

const actionCreators = {
    updateGroupBy1: groupBy1Action.updateGroupBy,
    updateGroupBy2: groupBy2Action.updateGroupBy,
    updateGroupBy3: groupBy3Action.updateGroupBy,
    updateDateRange: ddToolbarActions.updateDateRange,
};

export default connect(mapStateToProps, actionCreators)(DetailPanelToolbar);