import {integrationActions} from "../../../store/actions/integrationAction";
import {connect} from "react-redux";
import {useEffect} from "react";
import Spinner from "../../spinner/Spinner";
import {useTheme} from "@mui/material";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        paddingTop: 8,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        height: "100%",
    },
    title: {
        textAlign: "center"
    },
    spinner: {
        textAlign: "center",
        paddingBottom: 50
    }
});

function TikTokLoginRedirect({history, integration, getTikTokLoginLink, alert}) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    useEffect(() => {
        if (integration.selectedIntegration.name === "TikTok") {
            getTikTokLoginLink();
        }
    }, [getTikTokLoginLink, integration?.selectedIntegration?.name]);

    useEffect(() => {
        if (integration?.tiktok?.loginLink !== null) {
            document.location.href = integration?.tiktok?.loginLink;
        }
    }, [integration?.tiktok?.loginLink])

    useEffect(() => {
        if (alert.errorMsg !== "") {
            history.push({pathname: "/settings/integrations"});
        }
    }, [history, alert?.errorMsg])

    return (
        <div className={classes?.root}>
            <h3 className={classes?.title}>Redirect to TikTok</h3>
            <div className={classes?.spinner}>
                <Spinner size={32} overComponentBox={false}/>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration,
        alert: state.alert
    };
};

const mapDispatchToProps = {
    getTikTokLoginLink: integrationActions.getTikTokLoginLink
};

export default connect(mapStateToProps, mapDispatchToProps)(TikTokLoginRedirect);