export const websiteDomainsConstants = {
    WEBSITE_DOMAINS_LOADING: 'WEBSITE_DOMAINS_LOADING',
    WEBSITE_DOMAINS_LOADING_ERROR: 'WEBSITE_DOMAINS_LOADING_ERROR',
    WEBSITE_DOMAINS_SET_IS_LOADING: 'WEBSITE_DOMAINS_SET_IS_LOADING',
    WEBSITE_DOMAINS_STOP_LOADING: 'WEBSITE_DOMAINS_STOP_LOADING',
    WEBSITE_DOMAINS_UPDATE_ORDER_BY: 'WEBSITE_DOMAINS_UPDATE_ORDER_BY',
    WEBSITE_DOMAINS_UPDATE_SORT_BY: 'WEBSITE_DOMAINS_UPDATE_SORT_BY',
    WEBSITE_DOMAINS_UPDATE_DOMAIN: 'WEBSITE_DOMAINS_UPDATE_DOMAIN',
    WEBSITE_DOMAINS_UPDATE_SORT_COLUMNS: 'WEBSITE_DOMAINS_UPDATE_SORT_COLUMNS',
    WEBSITE_DOMAINS_INFINITY_SCROLL: 'WEBSITE_DOMAINS_INFINITY_SCROLL',
    WEBSITE_DOMAINS_RERENDER: 'WEBSITE_DOMAINS_RERENDER',
    WEBSITE_DOMAINS_VERIFIED: 'WEBSITE_DOMAINS_VERIFIED',
    WEBSITE_DOMAINS_CNAME_RECORDS: 'WEBSITE_DOMAINS_CNAME_RECORDS',
    WEBSITE_DOMAINS_DELETE_DOMAIN_AND_CERT: 'WEBSITE_DOMAINS_DELETE_DOMAIN_AND_CERT',
    WEBSITE_DOMAINS_ADD_DOMAIN: 'WEBSITE_DOMAINS_ADD_DOMAIN',
    WEBSITE_DOMAINS_UPDATE_ACTIVE_STEP: 'WEBSITE_DOMAINS_UPDATE_ACTIVE_STEP'
};
