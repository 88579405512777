import {apiRequest} from "../helpers/utils";

export const workspaceServices = {
    create,
    update,
    deleteWorkspace,
    find,
    read,
    getUserWorkspaces,
    updateUserWorkspace,
    findWorkspaceUserIDs,
    deleteUserFromWorkspace,
    assignUserToWorkspace,
    findWorkspaceTrackingDomains
};

function read({name = "", workspaceTypes = "", isActive = true, isDeleted = false, pageSize = 250}) {
    return apiRequest("get", "v1/workspace", {}, {name, workspaceTypes, isActive, isDeleted, pageSize});
}

function create(formData) {
    return apiRequest("post", "v1/workspace", formData);
}

function update(id, formData) {
    return apiRequest("put", "v1/workspace/" + parseInt(id), formData);
}

function deleteWorkspace(workspaceId) {
    return apiRequest("delete", "v1/workspace/" + parseInt(workspaceId));
}

function find(id) {
    return apiRequest("get", "v1/workspace/" + parseInt(id));
}

function findWorkspaceUserIDs(id) {
    return apiRequest("get", "v1/workspace/" + parseInt(id) + "/users");
}

function getUserWorkspaces() {
    return apiRequest("get", "v1/user-workspace", {}, {});
}

function updateUserWorkspace(formData) {
    return apiRequest("put", "v1/user-workspace", formData);
}

function deleteUserFromWorkspace(workspaceId, userId) {
    return apiRequest("delete", "v1/user-workspace/" + parseInt(workspaceId), {userId: userId});
}

function assignUserToWorkspace({workspaceId, userId}) {
    return apiRequest("post", "v1/user-workspace", {userId, workspaceId});
}

function findWorkspaceTrackingDomains(workspaceId) {
    return apiRequest("get", "v1/workspace/" + parseInt(workspaceId) + "/tracking-domains");
}