import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import {Checkbox, FormControlLabel} from "@mui/material";

ReactHookFormCheckbox.propTypes = {
    name: PropTypes.string,
    label: PropTypes.any,
    labelPlacement: PropTypes.string,
    control: PropTypes.object.isRequired,
    updateOnChange: PropTypes.func
};

ReactHookFormCheckbox.defaultProps = {
    name: "",
    label: "",
    labelPlacement: "",
    control: {},
    updateOnChange: () => null,
};

export default function ReactHookFormCheckbox({
                                                  name,
                                                  label,
                                                  labelPlacement,
                                                  control,
                                                  updateOnChange,
                                                  defaultValue = false
                                              }) {
    const labelId = `${name}-label`;
    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    defaultValue={defaultValue}
                    render={({field: {onChange, value, ...props}}) =>
                        <Checkbox
                            color="primary"
                            checked={value === true}
                            onChange={(e, data) => {
                                onChange(data);
                                updateOnChange();
                            }}
                            {...props}
                        />
                    }
                    control={control}
                />
            }
            id={labelId}
            label={label}
            labelPlacement={labelPlacement}
        />
    );
}
