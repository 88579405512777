import Topbar from "../layout/Topbar";
import SettingsSidebar from "../settings/SettingsSidebar";
import {Box, CssBaseline, List, ListItemButton, ListItemText, useTheme} from "@mui/material";
import {NavLink} from "react-router-dom";
import React from "react";
import LandingPhpScript from "./LandingPhpScript";
import LandingJsScript from "./LandingJsScript";
import GenerateLandingProtection from "./GenerateLandingProtection";
import {SettingsContentBox, SettingsRoot} from "../settings/General";
import {SettingsGeneralHeader} from "../settings/ProfileSettings";

function LandingProtection(props) {
    const theme = useTheme();
    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <Box display="flex" flexDirection="column" flexWrap="noWrap" sx={{
                    width: "100%",
                    padding: "16px 30px 0 30px",
                    maxWidth: "1280px",
                    minWidth: "300px",
                    margin: "0 auto",
                    [theme.breakpoints.down(600)]: {
                        padding: 0
                    }
                }}>
                    <SettingsGeneralHeader>Landing Protection</SettingsGeneralHeader>

                    <Box display="flex" mb={4}>
                        <GenerateLandingProtection {...props}/>
                    </Box>

                    <Box display="flex">
                        <List sx={{display: 'flex', flexDirection: 'row', padding: 0}}>
                            <ListItemButton
                                component={NavLink}
                                to="/settings/landing-protection/php"
                                selected={props?.location?.pathname === "/settings/landing-protection/php"}
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: "background.defaultBlue",
                                        borderBottomWidth: "2px",
                                        borderBottomStyle: "solid",
                                        borderBottomColor: "primary.main",
                                        marginBottom: "-2px",
                                        ":hover": {
                                            backgroundColor: "background.rowHover"
                                        }
                                    },
                                    ":hover": {
                                        backgroundColor: "background.rowHover"
                                    }
                                }}
                            >
                                <ListItemText primary="PHP" sx={{fontSize: "14px", paddingLeft: 2, paddingRight: 2}}/>
                            </ListItemButton>
                            <ListItemButton
                                component={NavLink}
                                to="/settings/landing-protection/javascript"
                                selected={props?.location?.pathname === "/settings/landing-protection/javascript"}
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: "background.defaultBlue",
                                        borderBottomWidth: "2px",
                                        borderBottomStyle: "solid",
                                        borderBottomColor: "primary.main",
                                        marginBottom: "-2px",
                                        ":hover": {
                                            backgroundColor: "background.rowHover"
                                        }
                                    },
                                    ":hover": {
                                        backgroundColor: "background.rowHover"
                                    }
                                }}
                            >
                                <ListItemText
                                    primary="Javascript"
                                    sx={{fontSize: "14px", paddingLeft: 2, paddingRight: 2}}
                                />
                            </ListItemButton>
                        </List>
                    </Box>
                    <Box display="flex">
                        {props?.match?.isExact && props?.match?.path === "/settings/landing-protection/php" &&
                            <LandingPhpScript {...props}/>
                        }
                        {props?.match?.isExact && props?.match?.path === "/settings/landing-protection/javascript" &&
                            <LandingJsScript {...props}/>
                        }
                    </Box>
                </Box>
            </SettingsContentBox>
        </SettingsRoot>
    );
}

export default LandingProtection;