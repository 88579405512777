import React, {createContext, useContext, useEffect, useState} from "react";
import {subscriptionServices} from "../../service/subscriptionService";
import {
    Alert,
    Box, Button, Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Paper, Link
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import {useStripe, useElements, CardElement} from "@stripe/react-stripe-js";
import Logo from "../utils/Logo";
import {businessSubscriptionPlans, individualSubscriptionPlans} from "../utils/DataList";
import Cookies from "universal-cookie/es6";
import {styled} from "@mui/system";
import {NavLink} from "react-router-dom";

export const CustomItemText = styled(Box)(() => ({
    fontWeight: 600,
    fontSize: 18
}));

export const CustomSubItemText = styled(Box)(({theme}) => ({
    fontSize: 12,
    paddingTop: 4,
    color: theme.palette.text.disabledLight
}));

export const CustomTotalItemText = styled(Box)(({theme}) => ({
    margin: "0 auto",
    maxWidth: 200,
    fontSize: 12,
    color: theme.palette.text.disabledLight
}));

export const AccountSetupContext = createContext(null);

export default function AccountSetup(props) {
    const [step, setStep] = useState(1);
    const [planName, setPlanName] = useState("");
    const [subscriptionStatus, setSubscriptionStatus] = useState("");
    const [latestInvoicePaymentIntentStatus, setLatestInvoicePaymentIntentStatus] = useState("");

    return (
        <AccountSetupContext.Provider
            value={{
                step,
                setStep,
                planName,
                setPlanName,
                subscriptionStatus,
                setSubscriptionStatus,
                latestInvoicePaymentIntentStatus,
                setLatestInvoicePaymentIntentStatus
            }}>
            <Box pl={1} pr={1} display="flex" flexDirection="row" flexWrap="noWrap" sx={{maxWidth: 1200, margin: "0 auto"}}>
                <Box p={1} flexGrow={1} sx={{fontSize: 20, fontWeight: 600, marginTop: "4px"}}>
                    <Logo width={75}/>
                </Box>
                <Box mt={1.2} p={1}>
                    <Link color="error" component={NavLink} to="/logout"
                          sx={{
                              textDecoration: "none",
                              fontWeight: 600,
                              fontSize: "14px",
                              "&:hover": {color: "error.dark"}
                          }}
                    >
                        Log out
                    </Link>
                </Box>
            </Box>
            <Divider/>
            {step === 1 && <Step1ChoosePlan {...props}/>}
            {step === 2 && <Step2ConfirmPayment {...props}/>}
        </AccountSetupContext.Provider>
    );
}

const Step1ChoosePlan = () => {
    const {setStep, setPlanName} = useContext(AccountSetupContext);
    const [planType, setPlanType] = useState("individual");

    const handleChoosePlan = (planName) => {
        individualSubscriptionPlans.forEach((plan) => {
            if (planName === plan?.name) {
                setPlanName(plan?.name);
                setStep(2);
            }
        });
        businessSubscriptionPlans.forEach((plan) => {
            if (planName === plan?.name) {
                setPlanName(plan?.name);
                setStep(2);
            }
        });
    };

    const handleToggleButtonChange = (event, newPlanType) => {
        if (newPlanType === null) {
            return false;
        }
        setPlanType(newPlanType);
    };

    return (
        <Box sx={{width: "100%", maxWidth: 1200, margin: "0 auto"}}>

            <DialogTitle sx={{textAlign: "center", fontSize: 18, fontWeight: 600}}>
                Select a subscription plan
            </DialogTitle>

            <DialogContent sx={{paddingLeft: 1, paddingRight: 1, paddingTop: 1}}>
                <Box textAlign="center" pb={2}>
                    <ToggleButtonGroup
                        color="primary"
                        value={planType}
                        exclusive
                        onChange={handleToggleButtonChange}
                    >
                        <ToggleButton value="individual">Individual</ToggleButton>
                        <ToggleButton value="business">Business</ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                {planType === "individual" &&
                    <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="center">
                        {individualSubscriptionPlans?.map((plan, i) => {
                            return (
                                <Box sx={{width: "100%", minWidth: 200, maxWidth: 350}} p={1} key={i}>
                                    <PlanContainer
                                        planName={plan?.name}
                                        price={plan?.price}
                                        events={plan?.events}
                                        overage={plan?.overagePrice}
                                        customDomains={plan?.customDomains}
                                        months={plan?.dataRetentionInMonths}
                                        totalNumberOfRecords={plan?.totalNumberOfRecords}
                                        users={plan?.users}
                                        choosePlan={handleChoosePlan}
                                        integrations={plan?.integrations}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                }

                {planType === "business" &&
                    <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="center">
                        {businessSubscriptionPlans?.map((plan, i) => {
                            return (
                                <Box sx={{width: "100%", minWidth: 200, maxWidth: 350}} p={1} key={i}>
                                    <PlanContainer
                                        planName={plan?.name}
                                        price={plan?.price}
                                        events={plan?.events}
                                        overage={plan?.overagePrice}
                                        customDomains={plan?.customDomains}
                                        months={plan?.dataRetentionInMonths}
                                        totalNumberOfRecords={plan?.totalNumberOfRecords}
                                        users={plan?.users}
                                        choosePlan={handleChoosePlan}
                                        integrations={plan?.integrations}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                }
                <Box textAlign="center" pt={1} sx={{fontSize: 12, color: "text.disabledLight"}}>
                    * An event should be defined as every visit / click / conversion.
                </Box>
                <Box textAlign="center" pt={1} sx={{fontSize: 12, color: "text.disabledLight"}}>
                    ** Overage charge occurs when the plan’s included events limit is exceeded.
                </Box>
            </DialogContent>
        </Box>
    );
};

const PlanContainer = (props) => {
    const {
        planName, price, events, overage, customDomains, months, totalNumberOfRecords, users, choosePlan, integrations
    } = props;
    return (
        <Paper sx={{
            minWidth: 200,
            padding: "12px",
            backgroundColor: "background.darkBlue",
            textAlign: "center",
            "&:hover": {
                backgroundColor: "background.hover",
            }
        }}>
            <Box sx={{
                fontWeight: 600,
                fontSize: 20,
                paddingTop: 1,
            }}>
                {planName}
            </Box>
            <Box p={1} sx={{color: "text.disabledLight", fontSize: 14}}>
                {planName === "Free" ? "No CC required" : "CC required"}
            </Box>
            <Box m={4}>
                <Button type="button" variant="contained" color="primary" onClick={() => choosePlan(planName)}>
                    Select
                </Button>
            </Box>

            <Box display="flex" flexDirection="row" flexWrap="noWrap" justifyContent="center" mb={1}>
                <Box sx={{fontWeight: 400, fontSize: 32}} alignSelf="flex-end">€ {price}</Box>
                <Box sx={{fontSize: 14, paddingBottom: "5px", paddingLeft: "2px"}} alignSelf="flex-end">/ month</Box>
            </Box>

            {planName === "Free" && <Box sx={{fontSize: 12, color: "text.disabledLight"}}>No time limits</Box>}
            {planName === "Starter" && <Box p={1}/>}
            {planName === "Pro" && <Box sx={{fontSize: 12, color: "text.disabledLight"}}>Most popular</Box>}
            {planName === "Team" && <Box p={1}/>}
            {planName === "Enterprise" && <Box p={1}/>}

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {months} months
            </CustomItemText>
            <CustomSubItemText>
                Data retention
            </CustomSubItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {events}
            </CustomItemText>
            <CustomSubItemText>
                Events per month
            </CustomSubItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                <Box display="flex" flexDirection="row" flexWrap="noWrap" justifyContent="center">
                    <Box sx={{fontWeight: 400}}>€</Box>
                    <Box>{overage}</Box>
                </Box>
            </CustomItemText>
            <CustomSubItemText>
                Per 1000 events overage
            </CustomSubItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {customDomains}
            </CustomItemText>
            <CustomSubItemText>
                Custom domains with Free SSL
            </CustomSubItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {users}
            </CustomItemText>
            <CustomTotalItemText>
                Users
            </CustomTotalItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {integrations}
            </CustomItemText>
            <CustomTotalItemText>
                Integrations
            </CustomTotalItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {totalNumberOfRecords}
            </CustomItemText>
            <CustomTotalItemText>
                Total number of campaigns, offers, landings, flows, traffic sources and aff. networks
            </CustomTotalItemText>
        </Paper>
    );
};

const cookies = new Cookies();

const Step2ConfirmPayment = () => {
    const {setStep, planName} = useContext(AccountSetupContext);

    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptTermsError, setAcceptTermsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [couponResult, setCouponResult] = useState(null);
    const [coupon, setCoupon] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [planPrice, setPlanPrice] = useState(0.00);
    const [totalPrice, setTotalPrice] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);

    const handleConfirmPayment = async event => {
        event.preventDefault();

        if (!acceptTerms) {
            setAcceptTermsError(true);
            return;
        }

        setLoading(true);
        setErrorMsg("");

        if (!stripe || !elements) {
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (error) {
            setLoading(false);
            setErrorMsg(error?.message);
            return;
        }

        await subscriptionServices.create({
            planName,
            paymentMethodId: paymentMethod?.id,
            coupon: couponResult?.coupon,
            referralCode
        })
            .then(async (result) => {
                if (result?.status === "active") {
                    let skroClickID = cookies.get("sclid");
                    if (skroClickID !== null && skroClickID !== "" && skroClickID !== undefined) {
                        await subscriptionServices.skroPostback({
                            clickId: skroClickID,
                            payout: totalPrice
                        }).finally(() => {
                            document.location.replace("/");
                        });
                    } else {
                        document.location.replace("/");
                    }
                } else if (result?.paymentIntentStatus === "requires_action") {
                    stripe.confirmCardPayment(result?.paymentIntentClientSecret, {payment_method: result?.paymentMethodId})
                        .then(async (result) => {
                            if (result?.error) {
                                setErrorMsg(result?.error?.message);
                            } else {
                                if (result.paymentIntent.status === 'succeeded') {
                                    // There's a risk of the customer closing the window before callback
                                    // execution. To handle this case, set up a webhook endpoint and
                                    // listen to invoice.paid. This webhook endpoint returns an Invoice.
                                    await subscriptionServices.updateSubscriptionStatus().then(() => {
                                        let skroClickID = cookies.get("sclid");
                                        if (skroClickID !== null && skroClickID !== "" && skroClickID !== undefined) {
                                            subscriptionServices.skroPostback({
                                                clickId: skroClickID,
                                                payout: totalPrice
                                            }).finally(() => {
                                                document.location.replace("/");
                                            });
                                        } else {
                                            document.location.replace("/");
                                        }
                                    }).catch((error) => {
                                        setErrorMsg(error?.response?.data?.error);
                                    });
                                }
                            }
                        });
                } else if (result?.paymentIntentStatus === "requires_payment_method") {
                    setErrorMsg("Your card was declined. Please try a different payment method.");
                }
            }).catch((error) => {
                setErrorMsg(error?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleFreePlan = async () => {

        if (!acceptTerms) {
            setAcceptTermsError(true);
            return;
        }

        setLoading(true);
        setErrorMsg("");

        await subscriptionServices.create({planName, referralCode}).then(async () => {
            let skroClickID = cookies.get("sclid");
            if (skroClickID !== null && skroClickID !== "" && skroClickID !== undefined) {
                await subscriptionServices.skroPostback(
                    {clickId: skroClickID, payout: 0}
                ).finally(() => {
                    document.location.replace("/");
                });
            } else {
                document.location.replace("/");
            }
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleAcceptTerms = () => {
        setAcceptTermsError(false);
        if (acceptTerms) {
            setAcceptTerms(false);
        } else {
            setAcceptTerms(true);
        }
    };

    const handlePreviousStep = () => {
        setStep(1);
    };

    const handleApplyCoupon = async () => {
        setErrorMsg("");

        if (coupon === "") {
            setErrorMsg("Please enter your coupon code.")
            return false;
        }

        setLoading(true);
        await subscriptionServices.verifyCoupon(coupon).then((result) => {
            setCouponResult(result);
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (planName !== "" && planName !== undefined) {
            individualSubscriptionPlans.forEach((plan) => {
                if (planName === plan?.name) {
                    setPlanPrice(plan?.price);
                    setTotalPrice(plan?.price);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planName])

    useEffect(() => {
        if (planName !== "" && planName !== undefined) {
            businessSubscriptionPlans.forEach((plan) => {
                if (planName === plan?.name) {
                    setPlanPrice(plan?.price);
                    setTotalPrice(plan?.price);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planName])

    useEffect(() => {
        if (couponResult?.valid) {
            let price = planPrice;

            let off = 0;
            if (couponResult?.percentOff !== 0) {
                off = price * (couponResult?.percentOff / 100);
            }
            if (couponResult?.amountOff !== 0) {
                if (couponResult?.amountOff > price) {
                    off = price;
                } else {
                    off = couponResult?.amountOff;
                }
            }
            setDiscount(off);
            setTotalPrice(price - off);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponResult])

    useEffect(() => {
        const refer = cookies.get("refer");
        if (refer !== undefined) {
            setReferralCode(refer);
        }
    }, []);

    return (
        <Box sx={{width: "100%", maxWidth: 600, margin: "0 auto"}}>

            {loading && <Spinner size={32} overComponentBox={true}/>}

            <DialogTitle sx={{textAlign: "center", fontSize: 18, fontWeight: 600}}>
                Subscribing to plan <span style={{fontWeight: 600}}>{planName}</span>
            </DialogTitle>

            <DialogContent p={1}>
                <Alert severity="info" variant="filled" sx={{mb: 2}}>
                    The payment amount will be charged automatically each month. Subscription starts after successful
                    payment.
                </Alert>
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1} p={1} sx={{fontWeight: 600}}>
                        Subscribing to plan
                    </Box>
                    <Box p={1}>{planName}</Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1} p={1} sx={{fontWeight: 600}}>
                        Price
                    </Box>
                    <Box p={1}>€ {planPrice.toFixed(2)}</Box>
                </Box>
                {discount > 0 &&
                    <Box display="flex" flexDirection="row" sx={{color: "primary.main", fontWeight: 600}}>
                        <Box flexGrow={1} p={1} sx={{fontWeight: 600}}>
                            Discount
                        </Box>
                        <Box p={1}>- € {discount.toFixed(2)}</Box>
                    </Box>
                }
                <Divider/>
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1} p={1} sx={{fontWeight: 600}}>
                        Total due now
                    </Box>
                    <Box p={1}>€ {totalPrice.toFixed(2)}</Box>
                </Box>
                <Divider/>

                <Box display="flex" flexDirection="row" mt={4} mb={1}>
                    <Box flexGrow={1}>
                        <Box sx={{fontWeight: 600, paddingLeft: 1, fontSize: 14}}>Referral code</Box>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            name="referralCode"
                            type="text"
                            size="small"
                            onChange={(e) => setReferralCode(e.target.value)}
                            value={referralCode}
                            placeholder="Enter referral code"
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </Box>

                {planName !== "Free" && <Box display="flex" flexDirection="row" mt={1} mb={1}>
                    <Box flexGrow={1}>
                        <Box sx={{fontWeight: 600, paddingLeft: 1, fontSize: 14}}>Coupon code</Box>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            name="coupon"
                            size="small"
                            type="text"
                            onChange={(e) => setCoupon(e.target.value)}
                            placeholder="Enter coupon code"
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box mt={3.3} ml={1}>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={handleApplyCoupon}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
                }

                {couponResult?.valid &&
                    <Box sx={{fontSize: 14, textAlign: "center", paddingBottom: "3px", color: "primary.light"}}>
                        Coupon applied!
                    </Box>
                }

                {errorMsg !== "" &&
                    <Alert sx={{marginBottom: "10px"}} variant="filled" severity="error">{errorMsg}</Alert>}
                {planName !== "Free" &&
                    <Box mt={4}>
                        <Box>
                            <form onSubmit={handleConfirmPayment}>
                                <Box sx={{fontWeight: 600, paddingLeft: 1, fontSize: 14}}>Card details</Box>
                                <CardElement options={{
                                    style: {
                                        base: {
                                            width: "100%",
                                            iconColor: '#fff',
                                            fontSize: '16px',
                                            color: '#fff',
                                            '::placeholder': {
                                                color: '#fff',
                                                fontSize: "14px"
                                            },
                                            '::selection': {
                                                backgroundColor: "blue"
                                            }
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}/>
                                <Box sx={{textAlign: "center", fontSize: 12, color: "text.disabledLight"}}>
                                    <a href="https://www.stripe.com" target="_blank" rel="noreferrer">
                                        <img src="/assets/images/stripe.png" alt="Powered by Stripe"
                                             style={{width: 120}}/></a>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                }
                <Box pt={2} sx={{...(acceptTermsError && {color: "error.main"})}}>
                    <Checkbox
                        checked={acceptTerms}
                        onChange={handleAcceptTerms}
                        name="terms"
                        color="primary"
                    />
                    Read and accept our
                    <a
                        href="https://skro.eu/terms-and-conditions" target="_blank" rel="noreferrer"
                        style={{paddingLeft: "4px", color: "#fff"}}
                    >
                        Terms & Conditions
                    </a>
                </Box>
            </DialogContent>
            <DialogActions sx={{padding: "12px 15px"}}>
                <Button type="button" color="inherit" variant="outlined" onClick={handlePreviousStep}>
                    Back
                </Button>
                {planName === "Free" ?
                    <Button type="button" color="primary" variant="contained" onClick={handleFreePlan}
                            disabled={!acceptTerms}>
                        Subscribe
                    </Button>
                    :
                    <Button type="submit" color="primary" variant="contained" onClick={handleConfirmPayment}
                            disabled={!acceptTerms}>
                        Confirm Payment
                    </Button>
                }
            </DialogActions>
        </Box>
    );
};