import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {
    CreditCard,
    People,
    Settings,
    Adb,
    ExitToApp,
    Share,
    Star,
    Accessibility,
    EmojiEvents, Security, Language
} from "@mui/icons-material";
import {
    Badge, Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText, Tooltip
} from "@mui/material";
import {styled} from "@mui/system";

const CustomListItem = styled(ListItemButton)(({theme}) => {
    return ({
        "&:hover": {
            backgroundColor: theme.palette.background.rowHover
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.primary.light,
            "&:hover": {
                backgroundColor: theme.palette.background.rowHover
            },
        },
    })
});

const CustomListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.white,
    minWidth: 40,
    paddingLeft: 2
}));

const BackToTrackerIcon = styled(ListItemIcon)(({theme}) => ({
    minWidth: 40,
    paddingLeft: 2,
    color: theme.palette.secondary.main
}));

export const SettingsListItems = ({auth, location}) => {
    const [selected, setSelected] = useState("");
    useEffect(() => {
        if (location?.pathname) {
            setSelected(location?.pathname);
        }
    }, [location?.pathname]);
    return (
        <>
            <CustomListItem sx={{paddingTop: "12px", paddingBottom: "12px"}} component={NavLink} to="/campaigns">
                <Tooltip title="Back To Tracker">
                    <BackToTrackerIcon>
                        <ExitToApp/>
                    </BackToTrackerIcon>
                </Tooltip>
                <ListItemText primary="Back To Tracker" primaryTypographyProps={{color: "secondary"}}/>
            </CustomListItem>
            <Divider/>
            <CustomListItem
                selected={selected === "/settings/general"}
                component={NavLink}
                to="/settings/general"
            >
                <Tooltip title="General" placement="right-end">
                    <CustomListItemIcon>
                        <Settings/>
                    </CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="General"/>
            </CustomListItem>
            {(auth?.userSettings?.role === "owner" || auth?.userSettings?.role === "manager") &&
                <CustomListItem
                    selected={selected === "/settings/domains"}
                    component={NavLink}
                    to="/settings/domains"
                >
                    <Tooltip title="Domains" placement="right-end">
                        <CustomListItemIcon>
                            <Language/>
                        </CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Domains"/>
                </CustomListItem>
            }
            {(auth?.userSettings?.role === "owner" || auth?.userSettings?.role === "manager") &&
            <CustomListItem
                selected={selected === "/settings/bot-detection"}
                component={NavLink}
                to="/settings/bot-detection"
            >
                <Tooltip title="Bot Detection" placement="right-end">
                    <CustomListItemIcon>
                        <Adb/>
                    </CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Bot Detection"/>
            </CustomListItem>
            }
            {auth?.userSettings?.role === "owner" &&
                <CustomListItem
                    selected={selected === "/settings/subscription"}
                    component={NavLink}
                    to="/settings/subscription"
                >
                    <Tooltip title="Subscription" placement="right-end">
                        <CustomListItemIcon>
                            <CreditCard/>
                        </CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Subscription"/>
                </CustomListItem>
            }
            {(auth?.userSettings?.role === "owner" || auth?.userSettings?.role === "manager") &&
                <CustomListItem
                    selected={selected === "/settings/integrations"}
                    component={NavLink}
                    to="/settings/integrations"
                >
                    <Tooltip title="Integrations" placement="right-end">
                        <CustomListItemIcon>
                            <Badge color="error" variant="dot"><Share/></Badge>
                        </CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary={"Integrations"}/>
                </CustomListItem>
            }
            <CustomListItem
                selected={selected === "/settings/custom-events"}
                component={NavLink}
                to="/settings/custom-events"
            >
                <Tooltip title="Custom Conversions" placement="right-end">
                    <CustomListItemIcon>
                        <EmojiEvents/>
                    </CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Custom Conversions"/>
            </CustomListItem>
            <CustomListItem
                selected={selected === "/settings/landing-protection/php" || selected === "/settings/landing-protection/javascript"}
                component={NavLink}
                to="/settings/landing-protection/php"
            >
                <Tooltip title="Landing Protection" placement="right-end">
                    <CustomListItemIcon>
                        <Security/>
                    </CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Landing Protection"/>
            </CustomListItem>
            {(auth?.userSettings?.role === "owner" || auth?.userSettings?.role === "manager") &&
                <CustomListItem
                    selected={selected === "/settings/team/members" || selected === "/settings/team/workspaces"}
                    component={NavLink}
                    to="/settings/team/members"
                >
                    <Tooltip title="Team" placement="right-end">
                        <CustomListItemIcon>
                            <People/>
                        </CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Team"/>
                </CustomListItem>
            }
            {(auth?.userSettings?.role === "owner" || auth?.userSettings?.role === "manager") &&
                <CustomListItem
                    selected={selected === "/settings/api-access"}
                    component={NavLink}
                    to="/settings/api-access"
                >
                    <Tooltip title="API Access" placement="right-end">
                        <CustomListItemIcon>
                            <Accessibility/>
                        </CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary="API Access"/>
                </CustomListItem>
            }
            {auth?.userSettings?.role === "owner" &&
                <CustomListItem
                    selected={selected === "/settings/referral"}
                    component={NavLink}
                    to="/settings/referral"
                >
                    <Tooltip title="Refer & Earn" placement="right-end">
                        <CustomListItemIcon>
                            <Star sx={{color: "icon.yellow"}}/>
                        </CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Refer & Earn"/>
                </CustomListItem>
            }
        </>
    );
}
