import React from 'react';
import {sidebarActions} from "../../store/actions/sidebarAction";
import {connect} from "react-redux";
import {Help, Menu, Settings} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import AccountPopover from "./AccountPopover";
import Logo from "../utils/Logo";
import {
    AppBar,
    Box,
    Divider,
    IconButton,
    Toolbar,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";
import WorkspaceMultiSelect from "../workspace/WorkspaceMultiSelect";
import {styled} from "@mui/system";

const drawerWidth = 210;

const CustomAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== "isDesktop",
})(({theme, open, isDesktop}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isDesktop && open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!open && {
        width: "100%",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function Topbar(props) {

    const theme = useTheme();
    const down625 = useMediaQuery(theme.breakpoints.down(625));
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const {isOpen} = props?.sidebar;

    const handleDrawerOnClick = () => {
        if (isDesktop && !props?.sidebar?.isOpen) {
            props.handleDrawerOpen();
        } else if (isDesktop && props?.sidebar?.isOpen) {
            props.handleDrawerClose();
        }

        if (!isDesktop && props?.sidebar?.isOpenMobile) {
            props.handleDrawerClose();
        } else if (!isDesktop && !props?.sidebar?.isOpenMobile) {
            props.handleDrawerOpen();
        }
    };

    return (
        <CustomAppBar position="fixed" elevation={0} open={isOpen} isDesktop={isDesktop}>
            <Toolbar sx={{"&.MuiToolbar-root": {paddingLeft: 2, paddingRight: 1, minHeight: 48}}}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOnClick}
                    sx={{
                        marginRight: 1,
                        "&:hover": {
                            background: 0
                        },
                        ...(isDesktop && isOpen && {display: 'none'}),
                    }}
                >
                    <Menu sx={{marginLeft: "5px", marginRight: "12px"}}/>
                </IconButton>
                <Box display="flex" flexDirection="row" alignItems="stretch" width="100%">
                    <Box flex={1} alignSelf="center" pt="5px">
                        <Logo width={50}/>
                    </Box>
                    {!down625 &&
                        <Box flex={1} alignSelf="center" textAlign="center">
                            <WorkspaceMultiSelect/>
                        </Box>
                    }
                    <Box flex={1}>
                        <Box display="flex" flexDirection="row" flex="nowrap" justifyContent="flex-end">
                            {down625 &&
                                <Box mr={1}>
                                    <WorkspaceMultiSelect/>
                                </Box>
                            }
                            <Box mr={1}>
                                <Tooltip title="Documentation">
                                    <IconButton color="inherit" target="_blank"
                                                href="https://skro.eu/docs/facebook/how-to-create-a-campaign-to-track-facebook-traffic">
                                        <Help/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box mr={1}>
                                <Tooltip title="Settings">
                                    <IconButton color="inherit" component={NavLink} to="/settings/general">
                                        <Settings/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box>
                                <AccountPopover/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Toolbar>
            <Divider/>
        </CustomAppBar>
    );
}

const mapStateToProps = (state) => {
    return {
        sidebar: state.sidebar
    }
};

const actionCreators = {
    handleDrawerClose: sidebarActions.handleDrawerClose,
    handleDrawerOpen: sidebarActions.handleDrawerOpen
};

export default connect(mapStateToProps, actionCreators)(Topbar);