import authReducer from "./authReducer";
import sidebarReducer from "./sidebarReducer";
import {combineReducers} from 'redux';
import campaignsReducer from "./campaignsReducer";
import offersReducer from "./offersReducer";
import landingReducer from "./landingReducer";
import affiliateNetworkReducer from "./affiliateNetworkReducer";
import trafficSourceReducer from "./trafficSourceReducer";
import ddToolbarReducer from "./ddToolbarReducer";
import campaignFormReducer from "./campaignFormReducer";
import dashboardReducer from "./dashboardReducer";
import trackingDomainReducer from "./trackingDomainReducer";
import cloakingDomainReducer from "./cloakingDomainReducer";
import userReducer from "./userReducer";
import alertReducer from "./alertReducer";
import userSettingsReducer from "./userSettingsReducer";
import logClicksReducer from "./logClicksReducer";
import groupBy1Reducer from "./groupBy1Reducer";
import groupBy2Reducer from "./groupBy2Reducer";
import groupBy3Reducer from "./groupBy3Reducer";
import integrationReducer from "./integrationReducer";
import flowReducer from "./flowReducer";
import personReducer from "./personReducer";
import referralReducer from "./referralReducer";
import metricFilterReducer from "./metricFilterReducer";
import apiAccessReducer from "./apiAccessReducer";
import columnReducer from "./columnReducer";
import customEventReducer from "./customEventReducer";
import workspaceReducer from "./workspaceReducer";
import landingProtectionReducer from "./landingProtectionReducer";
import logConversionsReducer from "./logConversionsReducer";
import logPostbacksReducer from "./logPostbacksReducer";
import websiteDomainsReducer from "./websiteDomainsReducer";
import websiteBucketsReducer from "./websiteBucketsReducer";
import websiteDeploymentsReducer from "./websiteDeploymentsReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    sidebar: sidebarReducer,
    offer: offersReducer,
    campaign: campaignsReducer,
    campaignForm: campaignFormReducer,
    landing: landingReducer,
    affiliateNetwork: affiliateNetworkReducer,
    trafficSource: trafficSourceReducer,
    logClicks: logClicksReducer,
    logConversions: logConversionsReducer,
    logPostbacks: logPostbacksReducer,
    ddToolbar: ddToolbarReducer,
    dashboard: dashboardReducer,
    trackingDomain: trackingDomainReducer,
    cloakingDomain: cloakingDomainReducer,
    user: userReducer,
    userSettings: userSettingsReducer,
    groupBy1: groupBy1Reducer,
    groupBy2: groupBy2Reducer,
    groupBy3: groupBy3Reducer,
    integration: integrationReducer,
    flow: flowReducer,
    person: personReducer,
    referral: referralReducer,
    metricFilter: metricFilterReducer,
    apiAccess: apiAccessReducer,
    column: columnReducer,
    customEvent: customEventReducer,
    workspace: workspaceReducer,
    landingProtection: landingProtectionReducer,
    websiteDomains: websiteDomainsReducer,
    websiteBuckets: websiteBucketsReducer,
    websiteDeployments: websiteDeploymentsReducer
});

export default rootReducer;