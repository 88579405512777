import {apiRequest} from "../helpers/utils";

export const flowServices = {
    create,
    update,
    archive,
    find,
    restore,
    read,
    bulkUpdate
};

function read(name, countryCode) {
    let params = {
        name: name,
        countryCode: countryCode,
        isDeleted: false,
        pageSize: 250,
        includeGlobalCountries: true
    };
    return apiRequest("get", "v1/flow", {}, params);
}

function create(formData) {
    return apiRequest("post", "v1/flow", formData);
}

function update(id, formData) {
    return apiRequest("put", "v1/flow/" + parseInt(id), formData);
}

function archive(formData) {
    return apiRequest("post", "v1/flow/archive", formData);
}

function restore(formData) {
    return apiRequest("post", "v1/flow/restore", formData);
}

function find(id) {
    return apiRequest("get", "v1/flow/" + parseInt(id));
}

function bulkUpdate(formData) {
    return apiRequest("patch", "v1/flow", formData);
}