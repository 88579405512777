import {landingProtectionConstants} from "../constants/landingProtectionConstants";

const initialState = {
    token: "",
    isLoading: false,
};

const landingProtectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case landingProtectionConstants.LANDING_PROTECTION_LOADING_SPINNER:
            return {
                ...state,
                isLoading: action?.loading,
            };
        case landingProtectionConstants.LANDING_PROTECTION_SET_TOKEN:
            return {
                ...state,
                token: action?.token,
            };
        default:
            return state;
    }
};

export default landingProtectionReducer;