import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Alert,
    Tooltip,
    Box,
    Divider,
    Switch,
    FormControlLabel,
    InputAdornment,
    FormHelperText
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import React from "react";
import {Info} from "@mui/icons-material";
import {Controller} from "react-hook-form";
import PostbackURLWithParams from "../input/PostbackURLWithParams";
import IntegrationSelect from "./IntegrationSelect";
import WorkspaceDropdown from "../workspace/WorkspaceDropdown";
import ArbitrageIntegrationSelect from "./ArbitrageIntegrationSelect";
import CustomSnackbar from "../alert/CustomSnackbar";
import ReactHookTagsSelect from "../tags/ReactHookTagsSelect";

export const TrafficSourceForm = (props) => {
    const {
        title,
        register,
        handleClose,
        requestError,
        errors,
        handleSubmit,
        onSubmit,
        loading,
        setValue,
        watch,
        trafficSourceData,
        control,
        setRequestError,
        getValues
    } = props;

    return (
        <>
            <DialogTitle sx={{textAlign: "center"}}>{title}</DialogTitle>
            <DialogContent>
                {requestError.length > 1 && <CustomSnackbar clear={true} timeout={5} message={requestError}/>}
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} style={{minHeight: "300px"}}>
                    <Box display="flex" mb={2}>
                        <Box flexGrow={1}>
                            <WorkspaceDropdown
                                setValue={setValue} errors={errors} control={control}
                                workspaceId={trafficSourceData?.workspaceId}
                            />
                        </Box>
                        <Box p={1}>
                            <Box pt="10px">
                                <Tooltip disableFocusListener
                                         sx={{
                                             marginLeft: "6px",
                                             fontSize: "18px",
                                             position: "relative",
                                             cursor: "pointer"
                                         }}
                                         title="Workspace allows to make the required traffic source accessible only by a specific user or group of users.">
                                    <Info color={"primary"} fontSize={"small"}/>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column">
                        <Box flexGrow={1}>
                            <TextField
                                error={!!errors.name}
                                variant="outlined"
                                margin="dense"
                                id="name"
                                size="small"
                                {...register("name", {required: true, maxLength: 255, minLength: 3})}
                                name="name"
                                label="Name*"
                                placeholder="Traffic source name"
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box flexGrow={1}>
                            <Box pt={2} flexGrow={1}>
                                <ReactHookTagsSelect
                                    control={control}
                                    inputName="tags"
                                    inputLabel="Tags"
                                    inputLabelShrink={true}
                                    getValues={getValues}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column">
                        <Box mt={2}>
                            <TrafficSourcePostbackURL
                                control={control}
                                register={register}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                            />
                        </Box>
                    </Box>

                    <Divider sx={{mt: 2}}/>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex">
                            <Box>
                                <h3>Traffic Source Integration</h3>
                            </Box>
                            <Box mt={"18px"} ml={1}>
                                <Tooltip disableFocusListener
                                         sx={{
                                             marginLeft: "6px",
                                             fontSize: "18px",
                                             position: "relative",
                                             cursor: "pointer"
                                         }}
                                         title="Integrate your traffic source to perform actions on your campaigns
                                         straight from Skro. Navigate to Settings -> Integrations to add more
                                         integrations."
                                >
                                    <Info color={"primary"} fontSize={"small"}/>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box>
                            <IntegrationSelect
                                setValue={setValue}
                                control={control}
                                errors={errors}
                                trafficSourceData={trafficSourceData}
                                setRequestError={setRequestError}
                            />
                        </Box>
                    </Box>

                    <Divider sx={{mt: 3}}/>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex">
                            <Box>
                                <h3>Arbitrage Integration (Direct API tracking)</h3>
                            </Box>
                            <Box mt={"18px"} ml={1}>
                                <Tooltip disableFocusListener
                                         sx={{
                                             marginLeft: "6px",
                                             fontSize: "18px",
                                             position: "relative",
                                             cursor: "pointer"
                                         }}
                                         title="Search Feed Arbitrage integration gives you the ability to use direct
                                         tracking with Meta (Facebook) Ads, TikTok and Google Ads. Integrate your Sedo
                                         account and track clicks, conversions and revenue automatically. Navigate to
                                         Settings -> Integrations to add your Sedo account integration."
                                >
                                    <Info color={"primary"} fontSize={"small"}/>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box>
                            <ArbitrageIntegrationSelect
                                setValue={setValue}
                                control={control}
                                errors={errors}
                                trafficSourceData={trafficSourceData}
                                setRequestError={setRequestError}
                            />
                        </Box>
                        {watch("affiliateNetworkIntegrationId")?.label === "Sedo" &&
                            <Box mt={2}>
                                <Alert variant="filled" severity="info">
                                    {`To ensure accurate direct API tracking, please add the "sub1" and "sub2" parameters to your offer URL.`}
                                    <p>Meta (Facebook):</p>
                                    <ul>
                                        <li>{"&sub1={campaignId}|{offerId}&sub2={custom5}|{custom3}|{custom1}"}</li>
                                    </ul>
                                    <p>TikTok:</p>
                                    <ul>
                                        <li>{"&sub1={campaignId}|{offerId}&sub2={custom3}|{custom2}|{custom1}"}</li>
                                    </ul>
                                    <p>Google Ads:</p>
                                    <ul>
                                        <li>{"&sub1={campaignId}|{offerId}&sub2={custom3}|{custom1}|{custom2}"}</li>
                                    </ul>
                                </Alert>
                            </Box>
                        }
                    </Box>

                    <Divider sx={{marginTop: "24px"}}/>
                    <h3 style={{paddingTop: "6px"}}>Parameters</h3>

                    <Box sx={{width: '100%', minWidth: "780px"}}>
                        <Box display="flex" flexWrap="noWrap" justifyContent="space-between">
                            <Box mt={2} ml={9} pl={3} flexGrow={1}>
                                <HelperTooltip name="Name" title="Name for reporting"/>
                            </Box>
                            <Box mt={2} ml={3} pl={3} flexGrow={1}>
                                <HelperTooltip name="Parameter"
                                               title="Parameter will be appended to your tracking link as parameter name. For example: https://example.com/?param="
                                />
                            </Box>
                            <Box mt={2} ml={2} pl={1} flexGrow={1}>
                                <HelperTooltip name="Placeholder"
                                               title="Placeholder will be appended to your tracking URL as parameter value and will be replaced with actual values that characterize this person's visit. . For example: https://example.com/?param={placeholder}"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="noWrap">
                            <Box ml="76px" p={1} flexWrap="noWrap">
                                <Box display="flex" flexDirection="row" flexWrap="noWrap" flexGrow={1} width="100%">
                                    <Box>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            type="text"
                                            value="External ID"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box mt={2.5} mr={1}>
                                        <Tooltip disableFocusListener
                                                 sx={{
                                                     marginLeft: "6px",
                                                     fontSize: "18px",
                                                     position: "relative",
                                                     cursor: "pointer"
                                                 }}
                                                 title="Use this parameter to track external unique ID. This is typically used when you need to return an ID to traffic source.">
                                            <Info color={"primary"} fontSize={"small"}/>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                            <Box p={1} flexGrow={1}>
                                <TextField
                                    error={!!errors.externalClickIdParam}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    {...register("externalClickIdParam", {maxLength: 255})}
                                    name="externalClickIdParam"
                                    type="text"
                                    placeholder="e.g. click_id"
                                    fullWidth
                                    onChange={(e) => {
                                        setValue("externalClickIdPlaceholder", "{" + e.target.value + "}");
                                    }}
                                />
                                <FormHelperText sx={{color: "error.main"}}>
                                    {errors?.externalClickIdParam?.message}
                                </FormHelperText>
                            </Box>
                            <Box p={1} flexGrow={1}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    {...register("externalClickIdPlaceholder", {maxLength: 255})}
                                    name="externalClickIdPlaceholder"
                                    type="text"
                                    placeholder="e.g. {click_id}"
                                    fullWidth
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="noWrap">
                            <Box ml="76px" p={1} flexWrap="noWrap">
                                <Box display="flex" flexDirection="row" flexWrap="noWrap" flexGrow={1} width="100%">
                                    <Box>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            type="text"
                                            value="Cost"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box mt={2.5} mr={1}>
                                        <Tooltip disableFocusListener
                                                 sx={{
                                                     marginLeft: "6px",
                                                     fontSize: "18px",
                                                     position: "relative",
                                                     cursor: "pointer"
                                                 }}
                                                 title="Use this parameter if your traffic source can pass click / visitor costs. This will enable you to automatically track costs using the Auto setting in campaign setup.">
                                            <Info color={"primary"} fontSize={"small"}/>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                            <Box p={1} flexGrow={1}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    {...register("costParam", {maxLength: 255})}
                                    name="costParam"
                                    type="text"
                                    placeholder="e.g. bid"
                                    fullWidth
                                    onChange={(e) => {
                                        setValue("costPlaceholder", "{" + e.target.value + "}");
                                    }}
                                />
                            </Box>
                            <Box p={1} flexGrow={1}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    {...register("costPlaceholder", {maxLength: 255})}
                                    name="costPlaceholder"
                                    type="text"
                                    placeholder="e.g. {bid}"
                                    fullWidth
                                />
                            </Box>
                        </Box>
                        {CustomTokens({
                            title: "Custom 1",
                            customParam: "custom1Param",
                            customParamPlaceholder: "e.g. ad_id",
                            customPlaceholder: "custom1Placeholder",
                            customPlaceholderPlaceholder: "e.g. {ad_id}",
                            customName: "custom1Name",
                            customNamePlaceholder: "Ad ID",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 2",
                            customParam: "custom2Param",
                            customParamPlaceholder: "e.g. adset_id",
                            customPlaceholder: "custom2Placeholder",
                            customPlaceholderPlaceholder: "e.g. {adset_id}",
                            customName: "custom2Name",
                            customNamePlaceholder: "Adset ID",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 3",
                            customParam: "custom3Param",
                            customParamPlaceholder: "e.g. campaign_id",
                            customPlaceholder: "custom3Placeholder",
                            customPlaceholderPlaceholder: "e.g. {campaign_id}",
                            customName: "custom3Name",
                            customNamePlaceholder: "Campaign ID",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 4",
                            customParam: "custom4Param",
                            customParamPlaceholder: "e.g. ad_name",
                            customPlaceholder: "custom4Placeholder",
                            customPlaceholderPlaceholder: "e.g. {ad_name}",
                            customName: "custom4Name",
                            customNamePlaceholder: "Ad Name",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 5",
                            customParam: "custom5Param",
                            customParamPlaceholder: "e.g. adset_name",
                            customPlaceholder: "custom5Placeholder",
                            customPlaceholderPlaceholder: "e.g. {adset_name}",
                            customName: "custom5Name",
                            customNamePlaceholder: "Adset Name",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 6",
                            customParam: "custom6Param",
                            customParamPlaceholder: "e.g. campaign_name",
                            customPlaceholder: "custom6Placeholder",
                            customPlaceholderPlaceholder: "e.g. {campaign_name}",
                            customName: "custom6Name",
                            customNamePlaceholder: "Campaign Name",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 7",
                            customParam: "custom7Param",
                            customParamPlaceholder: "e.g. placement",
                            customPlaceholder: "custom7Placeholder",
                            customPlaceholderPlaceholder: "e.g. {placement}",
                            customName: "custom7Name",
                            customNamePlaceholder: "Placement",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 8",
                            customParam: "custom8Param",
                            customParamPlaceholder: "e.g. site_source_name",
                            customPlaceholder: "custom8Placeholder",
                            customPlaceholderPlaceholder: "e.g. {site_source_name}",
                            customName: "custom8Name",
                            customNamePlaceholder: "Site Source Name",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 9",
                            customParam: "custom9Param",
                            customParamPlaceholder: "e.g. banner",
                            customPlaceholder: "custom9Placeholder",
                            customPlaceholderPlaceholder: "e.g. {banner}",
                            customName: "custom9Name",
                            customNamePlaceholder: "Banner",
                            register,
                            setValue
                        })}
                        {CustomTokens({
                            title: "Custom 10",
                            customParam: "custom10Param",
                            customParamPlaceholder: "e.g. os",
                            customPlaceholder: "custom10Placeholder",
                            customPlaceholderPlaceholder: "e.g. {os}",
                            customName: "custom10Name",
                            customNamePlaceholder: "OS",
                            register,
                            setValue
                        })}
                    </Box>

                    <input type="submit" hidden="hidden"/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                {loading ? <Spinner/> :
                    <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Save & Close
                    </Button>
                }
            </DialogActions>
        </>
    );
};

function TrafficSourcePostbackURL({register, control, errors, watch, setValue}) {
    return (
        <>
            <Controller
                name="usePostback"
                control={control}
                render={({field: {onChange, value, ...props}}) => (
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                color="primary"
                            />
                        }
                        label={(
                            <Box display="flex" mt="4px">
                                <Box>Enable Postback</Box>
                                <Box ml={1}>
                                    <Tooltip disableFocusListener
                                             sx={{
                                                 marginLeft: "6px",
                                                 fontSize: "18px",
                                                 position: "relative",
                                                 cursor: "pointer"
                                             }}
                                             title="Use this option if you want to send conversions info back to your Traffic Source. It is not used for Facebook, Google Ads, Microsoft Ads and TikTok.">
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </Tooltip>
                                </Box>
                            </Box>
                        )}
                    />
                )}
                defaultValue={false}
            />

            {watch("usePostback") && <>
                <PostbackURLWithParams
                    label="Traffic Source Postback URL*"
                    name="postbackUrl"
                    register={register}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                />

                <Box display="flex" mt={1} mb={1}>
                    <Box>
                        <TextField
                            error={!!errors.postbackPercentage}
                            variant="outlined"
                            margin="dense"
                            id="postbackPercentage"
                            size="small"
                            {...register("postbackPercentage", {required: true, min: 1, max: 100})}
                            name="postbackPercentage"
                            label="Send Postback"
                            placeholder="e.g. 100"
                            type="text"
                            defaultValue={"100"}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{maxWidth: 170}}
                        />
                    </Box>
                    <Box p={1}>
                        <Box pt="10px">
                            <Tooltip disableFocusListener
                                     sx={{
                                         marginLeft: "6px",
                                         fontSize: "18px",
                                         position: "relative",
                                         cursor: "pointer"
                                     }}
                                     title="It will show the % of conversions you wish to forward to your traffic source. By default, it set to 100%. Only approved postback statuses will be sent."
                            >
                                <Info color={"primary"} fontSize={"small"}/>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </>
            }
        </>
    );
}

export function HelperTooltip({name, title}) {
    return (
        <span>
            {name}
            <Tooltip disableFocusListener
                     sx={{
                         marginLeft: "6px",
                         fontSize: "18px",
                         position: "relative",
                         cursor: "pointer"
                     }}
                     title={title}>
                <Info color={"primary"} fontSize={"small"}/>
            </Tooltip>
        </span>
    );
}

export function CustomTokens(props) {
    const {
        title,
        customParam,
        customParamPlaceholder,
        customPlaceholder,
        customPlaceholderPlaceholder,
        customName,
        customNamePlaceholder,
        register,
        setValue
    } = props;
    return (
        <Box display="flex" flexWrap="noWrap" justifyContent="space-between">
            <Box mt="28px" ml={1} mr={1} display="flex" flexWrap="noWrap">
                {title}
            </Box>
            <Box p={1} flexGrow={1}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    {...register(customName, {maxLength: 100})}
                    name={customName}
                    type="text"
                    placeholder={customNamePlaceholder}
                    fullWidth
                />
            </Box>
            <Box p={1} flexGrow={1}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    {...register(customParam, {maxLength: 100})}
                    name={customParam}
                    type="text"
                    placeholder={customParamPlaceholder}
                    fullWidth
                    onChange={(e) => {
                        setValue(customPlaceholder, "{" + e.target.value + "}");
                    }}
                />
            </Box>
            <Box p={1} flexGrow={1}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    size="small"
                    {...register(customPlaceholder, {maxLength: 100})}
                    name={customPlaceholder}
                    type="text"
                    placeholder={customPlaceholderPlaceholder}
                    fullWidth
                />
            </Box>
        </Box>
    );
}