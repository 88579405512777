import {alertActions} from "./alertActions";
import {logServices} from "../../service/logService";
import {logConversionsConstants} from "../constants/logConversionsConstants";

export const logConversionsActions = {
    getConversions,
    loadMoreConversionRows,
    updateClickId,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender
};

function getConversions(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: logConversionsConstants.LOG_CONVERSIONS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        logServices.getListOfConversions(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: logConversionsConstants.LOG_CONVERSIONS_LOADING, result});
            }).catch((error) => {
                dispatch({type: logConversionsConstants.LOG_CONVERSIONS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function loadMoreConversionRows(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: logConversionsConstants.LOG_CONVERSIONS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        logServices.getListOfConversions(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: logConversionsConstants.LOG_CONVERSIONS_INFINITY_SCROLL, result});
            }).catch((error) => {
                dispatch({type: logConversionsConstants.LOG_CONVERSIONS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: logConversionsConstants.LOG_CONVERSIONS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: logConversionsConstants.LOG_CONVERSIONS_UPDATE_SORT_BY, sortBy});
    };
}

function updateClickId(clickId) {
    return (dispatch) => {
        dispatch({type: logConversionsConstants.LOG_CONVERSIONS_UPDATE_CLICK_ID, clickId});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: logConversionsConstants.LOG_CONVERSIONS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: logConversionsConstants.LOG_CONVERSIONS_RERENDER});
    };
}
