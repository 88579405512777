import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from "react-redux";
import {landingActions} from "../../store/actions/landingAction";
import {landingServices} from "../../service/landingService";
import {LandingForm} from "./LandingForm";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";

function AddLanding(props) {
    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState(null);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, setError, control, watch, setValue, getValues, formState: {errors}} = useForm();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/landings", state: {from: props.location}});
    }, [props.history, props.location]);

    useEffect(() => {
        async function postData(): void {
            setRequestError("");
            setLoading(true);
            await landingServices.create(formData).then((response) => {
                if (response?.id > 0) {
                    props.rerender(true);
                    setLoading(false);
                    handleClose();
                } else {
                    setLoading(false);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
                setLoading(false);
            });
        }

        if (formData !== null) {
            postData();
        }

    }, [handleClose, formData, props]);

    const onSubmit = (data) => {

        if (data?.workspace?.value === 0) {
            console.log("on null");
            setError("workspace", {
                type: "manual",
                message: "Please select workspace."
            });
            return false;
        }

        let name = "";
        if (data?.name) {
            name = "Global - " + data?.name;
            if (data?.country?.label !== undefined) {
                name = data?.country?.label + " - " + data?.name;
            }
        }

        setFormData({
            name,
            countryCode: data?.country?.code ? data?.country?.code : "",
            url: data?.url ? data?.url : "",
            tags: data?.tags ? data?.tags : "",
            workspaceId: data?.workspace?.value,
        });
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
        >
            <LandingForm
                title="New landing"
                open={open}
                watch={watch}
                control={control}
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                getValues={getValues}
            />
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: landingActions.rerender
};

export default connect(null, mapDispatchToProps)(AddLanding);
