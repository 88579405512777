import {userSettingsConstants} from "../constants/userSettingsConstants";
import {settingsServices} from "../../service/settingsService";

export const userSettingsActions = {
    update
};

function update(userSettings) {
    return dispatch => {
        settingsServices.updateUserSettings(userSettings).then((response) => {
            localStorage.setItem("user-settings", JSON.stringify({
                timezone: response?.timezone,
                currencyCode: response?.currencyCode,
                defaultTrackingDomain: response?.defaultTrackingDomain
            }));
            dispatch({type: userSettingsConstants.USER_SETTINGS_UPDATE, response});
        });
    };
}
