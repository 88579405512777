import React, {useEffect, useState} from 'react';
import {
    Tooltip,
    Avatar,
    Box,
    Link,
    ListItemIcon,
    ListItemText, IconButton, Popover, Divider
} from '@mui/material';
import {NavLink} from "react-router-dom";
import {AccountCircle, Business} from "@mui/icons-material";
import {settingsServices} from "../../service/settingsService";
import Spinner from "../spinner/Spinner";
import {CustomListItem} from "./ListItems";

export default function AccountPopover() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setUserInfo(null);
        setAgencyId(null);
        setAnchorEl(null);
    };

    const [agencyId, setAgencyId] = useState(null);
    const [switchTo, setSwitchTo] = useState(null);
    const handleSwitchAgency = (e, agencyId) => {
        // switch only when you have multiple agencies
        if (userInfo?.agencies?.length > 1) {
            setAgencyId(agencyId);
        }
    };

    useEffect(() => {
        async function switchAgency(companyId): void {
            try {
                setLoading(true);
                const response = await settingsServices.switchAgency({agencyId: companyId});
                if (response?.token && response?.deviceId) {
                    setSwitchTo(response);
                }
            } catch ({e}) {
                console.log(e);
            } finally {
                setAgencyId(null);
                setLoading(false);
            }
        }

        if (agencyId !== null) {
            switchAgency(agencyId);
        }
    }, [agencyId]);

    const open = Boolean(anchorEl);
    const id = open ? 'change-company' : undefined;

    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function fetchUserInfo(): void {
            try {
                setLoading(true);
                const response = await settingsServices.fetchProfileData();
                setUserInfo(response);
            } catch ({e}) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }

        if (userInfo === null && anchorEl !== null) {
            fetchUserInfo();
        }
    }, [anchorEl, userInfo]);

    // switch company
    if (switchTo !== null) {
        localStorage.removeItem("access-token");
        localStorage.removeItem("user-settings");
        window.location.replace("/verify?token=" + switchTo?.token + "&deviceId=" + switchTo?.deviceId);
    }

    return (
        <Box>
            <Tooltip title="Account">
                <IconButton color="inherit" aria-describedby={id} onClick={handleClick}>
                    <AccountCircle/>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{minWidth: "300px", padding: 2}}>

                    {loading && <Spinner size={32} overComponentBox={true}/>}

                    <Box display="flex" p={1} justifyContent="center">
                        <Avatar>
                            <AccountCircle sx={{color: "background.default"}}/>
                        </Avatar>
                    </Box>

                    <Box display="flex" p={1} justifyContent="center">
                        {userInfo?.email}
                    </Box>

                    <Box
                        sx={{
                            textAlign: "center",
                            marginTop: "30px",
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "text.primary",
                            pb: 2
                        }}
                    >
                        Switch company
                    </Box>

                    <Divider/>

                    <div className="MainListItemsContainer">
                        {
                            userInfo?.agencies?.map((agency, i) => {
                                return (
                                    <CustomListItem key={i} onClick={(e) => handleSwitchAgency(e, agency?.id)}>
                                        <ListItemIcon sx={{fontSize: 14}}>
                                            <Business sx={{color: "background.default"}}/>
                                        </ListItemIcon>
                                        <ListItemText primary={agency.title}/>
                                    </CustomListItem>
                                );
                            })
                        }
                    </div>
                    <Divider sx={{marginBottom: 3}}/>
                    <Box display="flex" p={1} justifyContent="center">
                        <Link color="error" component={NavLink} to="/logout" sx={{
                            textDecoration: "none !important",
                            fontWeight: 600,
                            fontSize: "16px",
                            textAlign: "center",
                        }}>
                            Log out
                        </Link>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
}
