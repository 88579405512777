import {
    Box,
    Button,
    DialogActions,
    DialogContent, FormControlLabel, IconButton,
    InputAdornment,
    Radio, RadioGroup,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import TrafficSourceSelect from "./TrafficSourceSelect";
import {Controller, useForm} from "react-hook-form";
import CurrencySelect from "../offers/CurrencySelect";
import {currencies} from "../utils/DataList";
import TrafficSourcePopUp from "../traffic-sources/TrafficSourcePopUp";
import ConversionEventSelect from "./ConversionEventSelect";
import {integrationService} from "../../service/integrationService";
import {FormatGoogleAdAccountId} from "../../helpers/common";
import ConversionPixelSelect from "./ConversionPixelSelect";
import {Add, Info} from "@mui/icons-material";
import {CustomTooltip} from "../landings/LandingForm";

export const FormStep1 = (props) => {
    const {handleClose, actionName} = props;
    const {handleSubmit, control, watch, register, setValue, formState: {errors}} = useForm({
        defaultValues: props.formState
    });

    const onSubmit = (data) => {
        props.formStep1({
            trafficSource: data?.trafficSource !== undefined ? data?.trafficSource : props?.formState?.trafficSource,
            costModel: data?.costModel !== undefined ? data?.costModel : props?.formState?.costModel,
            costValue: data?.costValue !== undefined ? data?.costValue : 0,
            currency: data?.currency !== undefined ? data?.currency : props?.formState?.currency,
            conversionAction: data?.conversionAction !== undefined ? data?.conversionAction : null,
            conversionPixel: data?.conversionPixel !== undefined ? data?.conversionPixel : null
        });
    };

    const [openTrafficSourcePopUp, setOpenTrafficSourcePopUp] = useState(false);

    const handleCloseTrafficSourcePopUp = () => {
        setOpenTrafficSourcePopUp(false);
    };

    const handleClickOpenTrafficSourcePopUp = () => {
        setOpenTrafficSourcePopUp(true);
    };

    const renderRevShareInput = () => {
        if (watch("costModel") === "cps") {
            return (
                <TextField
                    error={!!errors.costValue}
                    variant="outlined"
                    margin="dense"
                    {...register("costValue", {required: true, min: 0.000001, max: 100, pattern: /^\d+(\.\d{1,4})?$/})}
                    name="costValue"
                    size="small"
                    defaultValue={watch("costValue")}
                    label="RevShare cost "
                    placeholder="e.g. 75"
                    type="text"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        }
    };

    const renderCostValueInput = () => {
        if (watch("costModel") === "cpa" || watch("costModel") === "cpc") {
            return (
                <TextField
                    error={!!errors.costValue}
                    variant="outlined"
                    margin="dense"
                    {...register("costValue", {required: true, pattern: /^\d+(\.\d{1,4})?$/})}
                    name="costValue"
                    size="small"
                    defaultValue={watch("costValue")}
                    label="Cost value"
                    placeholder="e.g. 0.50"
                    type="text"
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        }
    };

    const renderGoogleConversionEventInput = () => {
        if (props?.formState?.integration?.networkType === "google") {
            let showForm = false
            let adAccountId = "";

            props?.formState?.integration?.integrationCredentials?.forEach((i) => {
                if (i?.keyName === "google_enable_conversion_api" && i?.keyValue === "true") {
                    showForm = true;
                }
                if (i?.keyName === "google_ad_account_id" && i?.keyValue !== "") {
                    adAccountId = FormatGoogleAdAccountId(i?.keyValue);
                }
            });

            if (showForm) {
                return (
                    <Box display="flex" flexDirection="column" mb={3} mt={1.2}>
                        <Box>
                            <Typography variant="body2" color="primary">
                                Connected with Google Ads: {adAccountId}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body1">
                                Google Conversion API (optional)
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <ConversionEventSelect
                                control={control} errors={errors}
                                options={props?.formState?.integration?.integrationEvents}
                            />
                        </Box>
                    </Box>
                );
            }
        }
    };

    const renderMicrosoftConversionEventInput = () => {
        if (props?.formState?.integration?.networkType === "microsoft") {
            let showForm = false
            let adAccountId = "";

            props?.formState?.integration?.integrationCredentials?.forEach((i) => {
                if (i?.keyName === "microsoft_enable_conversion_api" && i?.keyValue === "true") {
                    showForm = true;
                }
                if (i?.keyName === "microsoft_ad_account_id" && i?.keyValue !== "") {
                    adAccountId = i?.keyValue;
                }
            });

            if (showForm) {
                return (
                    <Box display="flex" flexDirection="column" mb={3} mt={1.2}>
                        <Box>
                            <Typography variant="body2" color="primary">
                                Connected with Microsoft Ads: {adAccountId}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body1">
                                Microsoft Ads Conversion API (optional)
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <ConversionEventSelect
                                control={control} errors={errors}
                                options={props?.formState?.integration?.integrationEvents}
                            />
                        </Box>
                    </Box>
                );
            }
        }
    };

    const renderTaboolaConversionEventInput = () => {
        if (props?.formState?.integration?.networkType === "taboola") {

            let showForm = false
            let adAccountId = ""
            props?.formState?.integration?.integrationCredentials?.forEach((i) => {
                if (i?.keyName === "taboola_enable_conversion_api" && i?.keyValue === "true") {
                    showForm = true;
                }
                if (i?.keyName === "taboola_account_id" && i?.keyValue !== "") {
                    adAccountId = i?.keyValue;
                }
            });

            if (showForm) {
                return (
                    <Box display="flex" flexDirection="column" mb={3} mt={1.2}>
                        <Box>
                            <Typography variant="body2" color="primary">
                                Connected with Taboola: {adAccountId}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body1">
                                Taboola Conversion API (optional)
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <ConversionEventSelect
                                control={control} errors={errors}
                                options={props?.formState?.integration?.integrationEvents}
                            />
                        </Box>
                    </Box>
                );
            }
        }
    };

    const renderOutbrainConversionEventInput = () => {
        if (props?.formState?.integration?.networkType === "outbrain") {

            let showForm = false
            let adAccountName = ""
            props?.formState?.integration?.integrationCredentials?.forEach((i) => {
                if (i?.keyName === "outbrain_enable_conversion_api" && i?.keyValue === "true") {
                    showForm = true;
                }
                if (i?.keyName === "outbrain_account_name" && i?.keyValue !== "") {
                    adAccountName = i?.keyValue;
                }
            });

            if (showForm) {
                return (
                    <Box display="flex" flexDirection="column" mb={3} mt={1.2}>
                        <Box>
                            <Typography variant="body2" color="primary">
                                Connected with Outbrain: {adAccountName}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body1">
                                Outbrain Conversion API (optional)
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <ConversionEventSelect
                                control={control} errors={errors}
                                options={props?.formState?.integration?.integrationEvents}
                            />
                        </Box>
                    </Box>
                );
            }
        }
    };

    const renderMgidConversionEventInput = () => {
        if (props?.formState?.integration?.networkType === "mgid") {

            let showForm = false
            let adAccountId = ""
            props?.formState?.integration?.integrationCredentials?.forEach((i) => {
                if (i?.keyName === "mgid_enable_conversion_api" && i?.keyValue === "true") {
                    showForm = true;
                }
                if (i?.keyName === "mgid_account_id" && i?.keyValue !== "") {
                    adAccountId = i?.keyValue;
                }
            });

            if (showForm) {
                return (
                    <Box display="flex" flexDirection="column" mb={3} mt={1.2}>
                        <Box>
                            <Typography variant="body2" color="primary">
                                Connected with MGID: {adAccountId}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body1">
                                MGID Conversion API (optional)
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <ConversionEventSelect
                                control={control} errors={errors}
                                options={props?.formState?.integration?.integrationEvents}
                            />
                        </Box>
                    </Box>
                );
            }
        }
    };

    const renderFacebookConversionEventInput = () => {
        if (props?.formState?.integration?.networkType === "facebook") {
            let showForm = false
            let adAccountId = "";

            props?.formState?.integration?.integrationCredentials?.forEach((i) => {
                if (i?.keyName === "facebook_enable_conversion_api" && i?.keyValue === "true") {
                    showForm = true;
                }
                if (i?.keyName === "fb_ad_account_id" && i?.keyValue !== "") {
                    adAccountId = i?.keyValue;
                }
            });

            if (showForm) {
                return (
                    <Box display="flex" flexDirection="column" mb={3} mt={1.2}>
                        <Box>
                            <Typography variant="body2" color="primary">
                                Connected with Facebook (Meta) Ads: {adAccountId}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body1">
                                Facebook Conversion API (optional)
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <ConversionEventSelect
                                control={control} errors={errors}
                                options={props?.formState?.integration?.integrationEvents}
                            />
                        </Box>
                        <Box mt={1}>
                            <ConversionPixelSelect
                                control={control} errors={errors}
                                options={props?.formState?.integration?.integrationPixels}
                            />
                        </Box>
                    </Box>
                );
            }
        }
    };

    const renderTikTokConversionEventInput = () => {
        if (props?.formState?.integration?.networkType === "tiktok") {
            let showForm = false
            let adAccountId = "";

            props?.formState?.integration?.integrationCredentials?.forEach((i) => {
                if (i?.keyName === "tt_enable_conversion_api" && i?.keyValue === "true") {
                    showForm = true;
                }
                if (i?.keyName === "tt_ad_account_id" && i?.keyValue !== "") {
                    adAccountId = i?.keyValue;
                }
            });

            if (showForm) {
                return (
                    <Box display="flex" flexDirection="column" mb={3} mt={1.2}>
                        <Box>
                            <Typography variant="body2" color="primary">
                                Connected with TikTok: {adAccountId}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body1">
                                TikTok Conversion API (optional)
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <ConversionEventSelect
                                control={control}
                                errors={errors}
                                options={props?.formState?.integration?.integrationEvents}
                            />
                        </Box>
                        <Box mt={1}>
                            <ConversionPixelSelect
                                control={control}
                                errors={errors}
                                options={props?.formState?.integration?.integrationPixels}
                            />
                        </Box>
                    </Box>
                );
            }
        }
    };

    useEffect(() => {
        if (actionName === "update" || actionName === "duplicate") {
            if (props?.formState?.trafficSource?.hasOwnProperty("label")) {
                setValue("trafficSource", props?.formState?.trafficSource);
            }
            setTimeout(() => {
                setValue("costModel", props.formState.costModel);
                setValue("currency", props.formState.currency);
                setValue("costValue", props.formState.costValue);
            });
        }

        if (actionName === "create") {
            // change default currency
            if (localStorage.getItem("user-settings") !== null) {
                let userSettings = JSON.parse(localStorage.getItem("user-settings"));
                if (userSettings?.currencyCode !== "") {
                    currencies.forEach((item) => {
                        if (item?.code === userSettings?.currencyCode) {
                            setValue("currency", item);
                        }
                    })
                }
            }
        }
    }, [props.match, props.formState, setValue, actionName]);

    useEffect(() => {
        if (props?.formState?.trafficSource?.integrationId > 0) {
            integrationService.findIntegration(props?.formState?.trafficSource?.integrationId).then((resp) => {
                if (props?.formState?.integration !== null && resp?.id !== props?.formState?.integration?.id) {
                    props?.resetConversionActionEventAndPixel();
                    setTimeout(() => {
                        setValue("conversionAction", null);
                        setValue("conversionPixel", null);
                    });
                }
                props?.updateTrafficSourceIntegration({integration: resp});
            });
        } else if (props?.formState?.integration !== null) {
            props?.updateTrafficSourceIntegration({integration: null});
            props?.resetConversionActionEventAndPixel();
            setTimeout(() => {
                setValue("conversionAction", null);
                setValue("conversionPixel", null);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.formState?.trafficSource?.integrationId]);

    useEffect(() => {
        if (props?.formState?.integration !== null && props?.formState?.integration !== undefined) {
            const integration = props?.formState?.integration;
            if (["google", "microsoft", "taboola", "facebook", "mgid", "outbrain", "tiktok"].indexOf(integration?.networkType) !== -1) {
                for (const credential of integration?.integrationCredentials) {
                    if (["google_enable_conversion_api", "microsoft_enable_conversion_api", "taboola_enable_conversion_api", "facebook_enable_conversion_api", "mgid_enable_conversion_api", "outbrain_enable_conversion_api", "tt_enable_conversion_api"].indexOf(credential?.keyName) !== -1 && credential?.keyValue === "true") {
                        for (const event of integration?.integrationEvents) {
                            if (event?.id === props?.formState?.conversionActionId) {
                                props?.updateConversionActionEvent({id: event?.id, name: event?.name});
                                break;
                            }
                        }
                        for (const pixel of integration?.integrationPixels) {
                            if (pixel?.id === props?.formState?.conversionPixelId) {
                                props?.updateConversionActionPixel({
                                    id: pixel?.id,
                                    name: pixel?.name,
                                    pixelId: pixel?.pixelId
                                });
                                break;
                            }
                        }
                        break;
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.formState?.integration]);

    useEffect(() => {
        if (props?.formState?.conversionAction !== null) {
            setValue("conversionAction", props?.formState?.conversionAction);
        }
        if (props?.formState?.conversionPixel !== null) {
            setValue("conversionPixel", props?.formState?.conversionPixel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.formState?.conversionAction, props?.formState?.conversionPixel])

    return (
        <>
            <DialogContent sx={{minHeight: 222}}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                    <Box display="flex" mb={1}>
                        <Box flexGrow={1}>
                            <TrafficSourceSelect
                                control={control}
                                errors={errors}
                                updateFunc={props?.updateTrafficSource}
                            />
                        </Box>
                        <Box mt="34px" ml={1}>
                            <Tooltip title="Create a new traffic source">
                                <IconButton
                                    aria-label="Create new"
                                    color="primary"
                                    onClick={handleClickOpenTrafficSourcePopUp}
                                >
                                    <Add fontSize="medium"/>
                                </IconButton>
                            </Tooltip>
                            <TrafficSourcePopUp
                                open={openTrafficSourcePopUp}
                                onClose={handleCloseTrafficSourcePopUp}
                            />
                        </Box>
                    </Box>

                    {renderGoogleConversionEventInput()}
                    {renderMicrosoftConversionEventInput()}
                    {renderTaboolaConversionEventInput()}
                    {renderFacebookConversionEventInput()}
                    {renderTikTokConversionEventInput()}
                    {renderMgidConversionEventInput()}
                    {renderOutbrainConversionEventInput()}

                    <Box display="flex" mt={2} flexDirection="column">
                        <Typography variant="body1">
                            Cost Model
                        </Typography>
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <RadioGroup
                                    row
                                    aria-label="costModel"
                                    defaultValue="auto"
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                >
                                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                                        <Box display="flex" flexDirection="row" mr={3}>
                                            <Box>
                                                <FormControlLabel
                                                    value="notTrack"
                                                    control={<Radio color="primary"/>}
                                                    label="Do not track"
                                                />
                                            </Box>
                                            <Box sx={{paddingTop: "12px", marginLeft: "-18px"}}>
                                                <CustomTooltip
                                                    disableFocusListener
                                                    title="Select that option if you don't want to track cost
                                                         data. You can update campaign cost manually via
                                                         'Update Cost' option in campaigns view."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </CustomTooltip>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" mr={3}>
                                            <Box>
                                                <FormControlLabel value="auto" control={<Radio color="primary"/>}
                                                                  label="Auto"/>
                                            </Box>
                                            <Box sx={{paddingTop: "12px", marginLeft: "-18px"}}>
                                                <CustomTooltip
                                                    disableFocusListener
                                                    title="Auto is used when you have defined the cost
                                                         parameter in your traffic source or when your
                                                         traffic source has auto-cost update integration with Skro."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </CustomTooltip>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" mr={3}>
                                            <Box>
                                                <FormControlLabel value="cpc" control={<Radio color="primary"/>}
                                                                  label="CPC"/>
                                            </Box>
                                            <Box sx={{paddingTop: "12px", marginLeft: "-18px"}}>
                                                <CustomTooltip
                                                    disableFocusListener
                                                    title="CPC allows you to enter your cost per click."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </CustomTooltip>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" mr={3}>
                                            <Box>
                                                <FormControlLabel value="cpa" control={<Radio color="primary"/>}
                                                                  label="CPA"/>
                                            </Box>
                                            <Box sx={{paddingTop: "12px", marginLeft: "-18px"}}>
                                                <CustomTooltip
                                                    disableFocusListener
                                                    title="CPA allows you to enter your cost per conversion."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </CustomTooltip>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row">
                                            <Box>
                                                <FormControlLabel value="cps" control={<Radio color="primary"/>}
                                                                  label="CPS"/>
                                            </Box>
                                            <Box sx={{paddingTop: "12px", marginLeft: "-18px"}}>
                                                <CustomTooltip
                                                    disableFocusListener
                                                    title="CPS allows you to define percent of revenue that you
                                                         will pay to your traffic source. Use {cps} placeholder in
                                                         traffic source postback URL to send adjusted revenue to
                                                         traffic source."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </CustomTooltip>
                                            </Box>
                                        </Box>
                                    </Box>
                                </RadioGroup>
                            )}
                            control={control}
                            name="costModel"
                            defaultValue="auto"
                        />
                    </Box>

                    <Box display="flex" flexDirection="row" flexWrap="noWrap" alignItems="stretch">
                        <Box mr={1} flex={1}>
                            <CurrencySelect control={control}/>
                        </Box>
                        <Box flex={1}>
                            {renderCostValueInput()}
                            {renderRevShareInput()}
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined" sx={{ml: 2}}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                    Next
                </Button>
            </DialogActions>
        </>
    );
};