import {workspaceConstants} from "../constants/workspaceConstants";
import {workspaceServices} from "../../service/workspaceService";
import {alertActions} from "./alertActions";
import {userServices} from "../../service/userService";
import {userConstants} from "../constants/userConstants";

export const workspaceActions = {
    create,
    read,
    getUserWorkspaces,
    updateSelectedUserWorkspaces,
    getWorkspaceIDs,
    resetUpdatedWorkspaceValue,
    findWorkspaceUsers,
    deleteUserFromWorkspace,
    assignUserToWorkspace,
    updateWorkspace,
    deleteWorkspace
};

function create({name, trackingDomainIds}) {
    return dispatch => {
        dispatch({type: workspaceConstants.WORKSPACE_READ_WORKSPACES_LOADING_SPINNER, result: {loading: true}});
        workspaceServices.create({name, trackingDomainIds}).then((result) => {
            dispatch({type: workspaceConstants.WORKSPACE_SET_NEW_WORKSPACE, result});
            dispatch(alertActions.success("New workspace is successfully created."));
        }).catch((error) => {
            dispatch({type: workspaceConstants.WORKSPACE_READ_WORKSPACES_LOADING_SPINNER, result: {loading: false}});
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function read({name, workspaceTypes, isActive, isDeleted, pageSize}) {
    return dispatch => {
        dispatch({type: workspaceConstants.WORKSPACE_READ_WORKSPACES_LOADING_SPINNER, result: {loading: true}});
        workspaceServices.read({name, workspaceTypes, isActive, isDeleted, pageSize}).then((result) => {
            dispatch({type: workspaceConstants.WORKSPACE_READ_WORKSPACES_SET_RESULT, result});
        }).catch((error) => {
            dispatch({type: workspaceConstants.WORKSPACE_READ_WORKSPACES_LOADING_SPINNER, result: {loading: false}});
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function getUserWorkspaces() {
    return dispatch => {
        dispatch({type: workspaceConstants.WORKSPACE_GET_USER_WORKSPACES_LOADING_SPINNER, result: {loading: true}});
        workspaceServices.getUserWorkspaces().then((result) => {
            dispatch({type: workspaceConstants.WORKSPACE_GET_USER_WORKSPACES_SET_RESULT, result});
        }).catch((error) => {
            dispatch({
                type: workspaceConstants.WORKSPACE_GET_USER_WORKSPACES_LOADING_SPINNER,
                result: {loading: false}
            });
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function updateSelectedUserWorkspaces(checked, formData) {
    return dispatch => {
        dispatch({type: workspaceConstants.WORKSPACE_GET_USER_WORKSPACES_LOADING_SPINNER, result: {loading: true}});
        workspaceServices.updateUserWorkspace(formData).then(() => {
            dispatch({type: workspaceConstants.WORKSPACE_UPDATE_USER_WORKSPACE, checked});
        }).catch((error) => {
            dispatch({
                type: workspaceConstants.WORKSPACE_GET_USER_WORKSPACES_LOADING_SPINNER,
                result: {loading: false}
            });
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function getWorkspaceIDs() {
    return (dispatch, getState) => {
        const userWorkspaces = getState().workspace?.userWorkspaces;
        let list = "";
        userWorkspaces?.forEach((item) => {
            if (item?.selected) {
                if (list === "") {
                    list += item?.workspaceId;
                } else {
                    list += "," + item?.workspaceId;
                }
            }
        });
        return list;
    };
}

function resetUpdatedWorkspaceValue() {
    return dispatch => dispatch({type: workspaceConstants.WORKSPACE_RESET_UPDATED_VALUE});
}

function findWorkspaceUsers(workspaceId) {
    return (dispatch, getState) => {
        const wsUsers = getState().user.users;
        dispatch({type: workspaceConstants.WORKSPACE_FIND_WORKSPACE_USERS_LOADING_SPINNER, result: {loading: true}});
        workspaceServices.findWorkspaceUserIDs(workspaceId).then((workspaceUsers) => {
            if (wsUsers?.length === 0) {
                userServices.read().then((result) => {
                    let data = [];
                    result?.users?.forEach((user) => {
                        if (workspaceUsers.indexOf(parseInt(user?.id)) !== -1) {
                            data.push(user);
                        }
                    });
                    dispatch({type: userConstants.LOADING_USERS, result});
                    dispatch({type: workspaceConstants.WORKSPACE_SET_WORKSPACE_USERS, data});
                });
            } else {
                let data = [];
                wsUsers?.forEach((user) => {
                    if (workspaceUsers.indexOf(parseInt(user?.id)) !== -1) {
                        data.push(user);
                    }
                });
                dispatch({type: workspaceConstants.WORKSPACE_SET_WORKSPACE_USERS, data});
            }
        }).catch((error) => {
            dispatch({
                type: workspaceConstants.WORKSPACE_FIND_WORKSPACE_USERS_LOADING_SPINNER,
                result: {loading: false}
            });
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}


function assignUserToWorkspace({workspaceId, userId, user}) {
    return dispatch => {
        dispatch({
            type: workspaceConstants.WORKSPACE_ASSIGN_USER_TO_WORKSPACE_LOADING_SPINNER,
            result: {loading: true}
        });
        workspaceServices.assignUserToWorkspace({workspaceId, userId}).then(() => {
            dispatch({type: workspaceConstants.WORKSPACE_ASSIGN_USER_TO_WORKSPACE, user});
            dispatch(alertActions.success("Member is successfully assigned to workspace."));
        }).catch((error) => {
            dispatch({
                type: workspaceConstants.WORKSPACE_ASSIGN_USER_TO_WORKSPACE_LOADING_SPINNER,
                result: {loading: false}
            });
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function deleteUserFromWorkspace(workspaceId, userId) {
    return dispatch => {
        dispatch({
            type: workspaceConstants.WORKSPACE_DELETE_USER_FROM_WORKSPACE_LOADING_SPINNER,
            result: {loading: true}
        });
        workspaceServices.deleteUserFromWorkspace(workspaceId, userId).then(() => {
            dispatch({type: workspaceConstants.WORKSPACE_UPDATE_USERS_IN_WORKSPACE, userId});
            dispatch(alertActions.success("Member is successfully removed from given workspace."));
        }).catch((error) => {
            dispatch({
                type: workspaceConstants.WORKSPACE_DELETE_USER_FROM_WORKSPACE_LOADING_SPINNER,
                result: {loading: false}
            });
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function updateWorkspace(workspaceId, formData) {
    return dispatch => {
        dispatch({type: workspaceConstants.WORKSPACE_UPDATE_LOADING_SPINNER, result: {loading: true}});
        workspaceServices.update(workspaceId, formData).then(() => {
            dispatch({
                type: workspaceConstants.WORKSPACE_UPDATE_WORKSPACE,
                payload: {id: workspaceId, name: formData?.name, trackingDomainIds: formData?.trackingDomainIds}
            });
            dispatch(alertActions.success("Workspace updated successfully."));
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: workspaceConstants.WORKSPACE_UPDATE_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function deleteWorkspace(workspaceId) {
    return dispatch => {
        dispatch({
            type: workspaceConstants.WORKSPACE_UPDATE_LOADING_SPINNER,
            result: {loading: true}
        });
        workspaceServices.deleteWorkspace(workspaceId).then(() => {
            dispatch({type: workspaceConstants.WORKSPACE_DELETE_WORKSPACE, workspaceId});
            dispatch(alertActions.success("Workspace deleted successfully."));
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: workspaceConstants.WORKSPACE_UPDATE_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}