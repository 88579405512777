import {apiRequest} from "../helpers/utils";

export const campaignServices = {
    create,
    update,
    archive,
    find,
    restore,
    updateCost,
    bulkCampaignUpdate
};

function create(formData) {
    return apiRequest("post", "v1/campaign", formData);
}

function update(id, formData) {
    return apiRequest("put", "v1/campaign/" + parseInt(id), formData);
}

function archive(formData) {
    return apiRequest("post", "v1/campaign/archive", formData);
}

function restore(formData) {
    return apiRequest("post", "v1/campaign/restore", formData);
}

function find(id) {
    return apiRequest("get", "v1/campaign/" + parseInt(id));
}

function updateCost(formData) {
    return apiRequest("post", "v1/campaign/cost", formData);
}

function bulkCampaignUpdate(formData) {
    return apiRequest("patch", "v1/campaign", formData);
}
