import React from 'react';
import {addCurrency, addPercent, getCurrencySymbol} from "../utils/Filters";
import {
    useMediaQuery,
    useTheme,
    Paper,
    Divider,
    Box,
    List,
    ListItemText,
    ListItem,
    ListItemSecondaryAction
} from "@mui/material";

export default function Stats({stats}) {
    // update currency symbol
    let currencySymbol = getCurrencySymbol();
    const theme = useTheme();
    const down600 = useMediaQuery(theme.breakpoints.down(600));

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    [theme.breakpoints.up(600)]: {
                        display: "none"
                    },
                }}
            >
                <Paper
                    sx={{
                        minHeight: "275px",
                        minWidth: "250px",
                        backgroundColor: "background.darkBlue"
                    }}
                    elevation={3}
                >
                    <Box display="flex" flexWrap="nowrap" sx={{
                        fontSize: "16px",
                        padding: "12px 8px",
                        color: "rgb(138, 147, 159)"
                    }}>
                        <Box p={1} flexGrow={1}>Overview</Box>
                    </Box>
                    <Divider/>
                    <List disablePadding>
                        <ListItem disableGutters={false} divider={true}>
                            <ListItemText primary="Visits" disableTypography
                                          sx={{fontSize: "15px", color: "primary.main"}}/>
                            <ListItemSecondaryAction>
                                <Box sx={{fontSize: 15}}>{stats?.visits ? stats.visits : 0}</Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem disableGutters={false} divider={true}>
                            <ListItemText primary="Clicks" disableTypography
                                          sx={{fontSize: "15px", color: "primary.main"}}/>
                            <ListItemSecondaryAction>
                                <Box sx={{fontSize: 15}}>{stats?.clicks ? stats.clicks : 0}</Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem disableGutters={false} divider={true}>
                            <ListItemText primary="Conversions" disableTypography
                                          sx={{fontSize: "15px", color: "primary.main"}}/>
                            <ListItemSecondaryAction>
                                <Box sx={{fontSize: 15}}>{stats?.conversionsAll ? stats.conversionsAll : 0}</Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem disableGutters={false} divider={true}>
                            <ListItemText primary="Revenue" disableTypography
                                          sx={{fontSize: "15px", color: "primary.main"}}/>
                            <ListItemSecondaryAction>
                                <Box sx={{fontSize: 15}}>
                                    {stats?.revenue ? addCurrency(currencySymbol, stats.revenue, 2) : currencySymbol + " 0.00"}
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem disableGutters={false} divider={true}>
                            <ListItemText primary="Cost" disableTypography
                                          sx={{fontSize: "15px", color: "primary.main"}}/>
                            <ListItemSecondaryAction>
                                <Box sx={{fontSize: 15}}>
                                    {stats?.cost ? addCurrency(currencySymbol, stats.cost, 2) : currencySymbol + " 0.00"}
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem disableGutters={false} divider={true}>
                            <ListItemText primary="Profit" disableTypography
                                          sx={{fontSize: "15px", color: "primary.main"}}/>
                            <ListItemSecondaryAction>
                                <Box sx={{fontSize: 15}}>
                                    {stats?.profit ? addCurrency(currencySymbol, stats.profit, 2) : currencySymbol + " 0.00"}
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem disableGutters={false}>
                            <ListItemText primary="Return On Investment (ROI)" disableTypography
                                          sx={{fontSize: "15px", color: "primary.main"}}/>
                            <ListItemSecondaryAction>
                                <Box sx={{fontSize: 15}}>
                                    {stats?.roi ? addPercent(stats.roi, 2) : "0.00%"}
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Paper>
            </Box>
            <Box display={down600 ? "none" : "flex"} flexWrap="wrap" m={-1}>
                <StatPaper number={stats?.visits ? stats.visits : 0} caption="Visits"/>
                <StatPaper number={stats?.clicks ? stats.clicks : 0} caption="Clicks"/>
                <StatPaper number={stats?.conversionsAll ? stats.conversionsAll : 0} caption="Conversions"/>
                <StatPaper
                    number={stats?.revenue ? addCurrency(currencySymbol, stats.revenue, 2) : currencySymbol + " 0.00"}
                    caption="Revenue"/>
                <StatPaper number={stats?.cost ? addCurrency(currencySymbol, stats.cost, 2) : currencySymbol + " 0.00"}
                           caption="Cost"/>
                <StatPaper
                    number={stats?.profit ? addCurrency(currencySymbol, stats.profit, 2) : currencySymbol + " 0.00"}
                    caption="Profit"/>
                <StatPaper number={stats?.roi ? addPercent(stats.roi, 2) : "0.00%"} caption="ROI"/>
            </Box>
        </>
    );
}

function StatPaper({number, caption}) {
    return (
        <Box flexGrow={1} p={1}>
            <Paper
                sx={{height: "100px", minWidth: "190px", backgroundColor: "background.darkBlue", padding: 2}}
                elevation={3}
            >
                <Box sx={{
                    paddingTop: "4px",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "primary.main",
                    fontWeight: 600
                }}>
                    {caption}
                </Box>
                <Box sx={{paddingTop: "6px", textAlign: "center", fontSize: "24px"}}>
                    {number}
                </Box>
            </Paper>
        </Box>
    );
}
