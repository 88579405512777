export const affiliateNetworkConstants = {
    AFFILIATE_NETWORKS_LOADING_SPINNER: 'AFFILIATE_NETWORKS_LOADING_SPINNER',
    AFFILIATE_NETWORKS_STOP_LOADING_SPINNER: 'AFFILIATE_NETWORKS_STOP_LOADING_SPINNER',
    AFFILIATE_NETWORKS_INFINITY_SCROLL: 'AFFILIATE_NETWORKS_INFINITY_SCROLL',
    AFFILIATE_NETWORKS_GET_DATA: 'AFFILIATE_NETWORKS_GET_DATA',
    AFFILIATE_NETWORKS_ERROR: 'AFFILIATE_NETWORKS_ERROR',
    AFFILIATE_NETWORKS_UPDATE_ORDER_BY: 'AFFILIATE_NETWORKS_UPDATE_ORDER_BY',
    AFFILIATE_NETWORKS_UPDATE_SORT_BY: 'AFFILIATE_NETWORKS_UPDATE_SORT_BY',
    AFFILIATE_NETWORKS_UPDATE_NAME: 'AFFILIATE_NETWORKS_UPDATE_NAME',
    AFFILIATE_NETWORKS_UPDATE_TAGS: 'AFFILIATE_NETWORKS_UPDATE_TAGS',
    AFFILIATE_NETWORKS_UPDATE_SORT_COLUMNS: 'AFFILIATE_NETWORKS_UPDATE_SORT_COLUMNS',
    AFFILIATE_NETWORKS_RERENDER: 'AFFILIATE_NETWORKS_RERENDER',
    AFFILIATE_NETWORKS_ARCHIVE: 'AFFILIATE_NETWORKS_ARCHIVE',
    AFFILIATE_NETWORKS_RESTORE: 'AFFILIATE_NETWORKS_RESTORE',
    BULK_UPDATE_CHANGE_WORKSPACE: 'BULK_UPDATE_CHANGE_WORKSPACE'
};
