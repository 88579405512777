import {integrationConstants} from "../constants/integrationConstants";
import {alertActions} from "./alertActions";
import {integrationService} from "../../service/integrationService";

export const integrationActions = {
    getFacebookLoginLink,
    facebookCallback,
    getFacebookAdAccounts,
    integrateFacebookAdAccount,
    integrateFacebookConversionAPI,
    getTrafficSourceIntegrations,
    getAffiliateNetworkIntegrations,
    deleteIntegration,
    enableDataTransfer,
    getRenewableFacebookLoginLink,
    updateStep,
    updateSelectedIntegration,
    rerender,
    reset,
    createPushGroundIntegration,
    findIntegration,
    updatePushGroundIntegration,
    createPropellerAdsIntegration,
    updatePropellerAdsIntegration,
    createExoClickIntegration,
    updateExoClickIntegration,
    createMondiadIntegration,
    updateMondiadIntegration,
    createClickBankIntegration,
    updateClickBankIntegration,
    createSedoIntegration,
    updateSedoIntegration,
    createSystem1Integration,
    updateSystem1Integration,
    getGoogleLoginLink,
    getRenewableGoogleLoginLink,
    googleCallback,
    getGoogleAdAccounts,
    integrateGoogleAdAccount,
    integrateGoogleConversionAPI,
    getMicrosoftLoginLink,
    microsoftCallback,
    getRenewableMicrosoftLoginLink,
    getMicrosoftAdAccounts,
    integrateMicrosoftAdAccount,
    integrateMicrosoftConversionAPI,
    createBodisIntegration,
    updateBodisIntegration,
    createDomainActiveIntegration,
    updateDomainActiveIntegration,
    createAdsComIntegration,
    updateAdsComIntegration,
    createTonicIntegration,
    updateTonicIntegration,
    getTaboolaAdAccounts,
    createTaboolaIntegration,
    updateTaboolaIntegration,
    createMgidIntegration,
    updateMgidIntegration,
    createOutbrainIntegration,
    updateOutbrainIntegration,
    getOutbrainAdAccounts,
    getTikTokLoginLink,
    getRenewableTikTokLoginLink,
    tikTokCallback,
    getTikTokAdAccounts,
    integrateTikTokAdAccount,
    integrateTikTokConversionAPI,
    getRsocIntegrations,
    getServerIntegrations,
    createRsocSystem1Integration,
    updateRsocSystem1Integration,
    createRsocInuvoIntegration,
    updateRsocInuvoIntegration,
    createServerAwsIntegration,
    updateServerAwsIntegration,
    createDomainCloudflareIntegration,
    updateDomainCloudflareIntegration,
    createDomainNamesiloIntegration,
    updateDomainNamesiloIntegration
};

function getFacebookLoginLink(renewIntegrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_FB_LOGIN_LINK_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getFacebookLoginLink(renewIntegrationId)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_FB_LOGIN_LINK, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getRenewableFacebookLoginLink(renewIntegrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_VIEW_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getFacebookLoginLink(renewIntegrationId)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_RENEWABLE_FB_LOGIN_LINK, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function facebookCallback(code, state) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.facebookCallback(code, state)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error("Facebook integration failed: " + error?.response?.data?.error));
            }
        );
    };
}

function getFacebookAdAccounts(id) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getFacebookAdAccounts(id)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function integrateFacebookAdAccount(adAccountId, adAccountName, adAccountTimezone, integrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.integrateFacebookAdAccount(adAccountId, adAccountName, adAccountTimezone, integrationId)
            .then(() => {
                integrationService.getFacebookAdAccounts(integrationId)
                    .then((result) => {
                        dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS, result});
                    }).catch((error) => {
                        dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                        dispatch(alertActions.error(error?.response?.data?.error));
                    }
                );
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error("Facebook ad account integration failed: " + error?.response?.data?.error));
            }
        );
    };
}

function integrateFacebookConversionAPI({integrationId = 0, enableConversionAPI = false, events = [], pixels = []}) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.integrateFacebookConversionAPI({
            integrationId, enableConversionAPI, events, pixels
        }).then(() => {
            dispatch(alertActions.success("Facebook (Meta) Ads is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("Facebook (Meta) Ads integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function getTrafficSourceIntegrations() {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_SPINNER});
        integrationService.getIntegrations({category: "traffic-source"})
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_INTEGRATIONS, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getAffiliateNetworkIntegrations() {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AFFILIATE_NETWORK_INTEGRATIONS_SPINNER});
        integrationService.getIntegrations({category: "affiliate-network"})
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_AFFILIATE_NETWORK_INTEGRATIONS, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function deleteIntegration(integrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.deleteIntegration(integrationId).then(() => {
            dispatch({type: integrationConstants.INTEGRATIONS_RESET});
        }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function enableDataTransfer(integrationId, enableDataTransfer) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_VIEW_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.enableDataTransfer(integrationId, enableDataTransfer).then(() => {
            dispatch({type: integrationConstants.INTEGRATIONS_UPDATE_DATA_TRANSFER_STATUS, integrationId});
        }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updateStep(formStep) {
    return dispatch => {
        dispatch({type: integrationConstants.INTEGRATIONS_UPDATE_STEP, formStep});
    };
}

function updateSelectedIntegration(selectedIntegration) {
    return dispatch => {
        dispatch({type: integrationConstants.INTEGRATIONS_UPDATE_SELECTED_INTEGRATION, selectedIntegration});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: integrationConstants.INTEGRATIONS_RERENDER});
    };
}

function reset() {
    return dispatch => {
        dispatch({type: integrationConstants.INTEGRATIONS_RESET});
    };
}

function createPushGroundIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createPushGroundIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updatePushGroundIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updatePushGroundIntegration(id, data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function findIntegration(id) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.findIntegration(id)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function createPropellerAdsIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createPropellerAdsIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updatePropellerAdsIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updatePropellerAdsIntegration(id, data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function createExoClickIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createExoClickIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateExoClickIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateExoClickIntegration(id, data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function createMondiadIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createMondiadIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateMondiadIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateMondiadIntegration(id, data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function createClickBankIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createClickBankIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateClickBankIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateClickBankIntegration(id, data).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createSedoIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createSedoIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Sedo is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Sedo integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateSedoIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateSedoIntegration(id, data).then(() => {
            dispatch(alertActions.success("Bodis integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Sedo integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createSystem1Integration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createSystem1Integration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("System1 is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("System1 integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateSystem1Integration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateSystem1Integration(id, data).then(() => {
            dispatch(alertActions.success("System1 integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("System1 integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function getGoogleLoginLink(renewIntegrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_GOOGLE_LOGIN_LINK_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getGoogleLoginLink(renewIntegrationId)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_GOOGLE_LOGIN_LINK, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getRenewableGoogleLoginLink(renewIntegrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_VIEW_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getGoogleLoginLink(renewIntegrationId)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_RENEWABLE_GOOGLE_LOGIN_LINK, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function googleCallback(code, state) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.googleCallback(code, state)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error("Google integration failed: " + error?.response?.data?.error));
            }
        );
    };
}

function getGoogleAdAccounts(id) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getGoogleAdAccounts(id)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS, result: {result: result}});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function integrateGoogleAdAccount(
    {adAccountId, adAccountName, adAccountTimezone, adAccountCurrencyCode, integrationId}
) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.integrateGoogleAdAccount({
            adAccountId, adAccountName, adAccountTimezone, adAccountCurrencyCode, integrationId
        }).then(() => {
            dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNT_INTEGRATED, adAccountId});
        }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error("Google ad account integration failed: " + error?.response?.data?.error));
            }
        );
    };
}

function integrateGoogleConversionAPI({integrationId = 0, enableConversionAPI = false, events = []}) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.integrateGoogleConversionAPI({
            integrationId, enableConversionAPI, events
        }).then(() => {
            dispatch(alertActions.success("Google Ads is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("Google Ads integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function getMicrosoftLoginLink(renewIntegrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_MICROSOFT_LOGIN_LINK_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getMicrosoftLoginLink(renewIntegrationId)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_MICROSOFT_LOGIN_LINK, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getRenewableMicrosoftLoginLink(renewIntegrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_VIEW_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getMicrosoftLoginLink(renewIntegrationId)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_RENEWABLE_MICROSOFT_LOGIN_LINK, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function microsoftCallback(code, state) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.microsoftCallback(code, state)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error("Microsoft integration failed: " + error?.response?.data?.error));
            }
        );
    };
}

function getMicrosoftAdAccounts(id) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getMicrosoftAdAccounts(id)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS, result: {result: result}});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function integrateMicrosoftAdAccount({accountId, integrationId, customerId}) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.integrateMicrosoftAdAccount({accountId, integrationId, customerId}).then(() => {
            dispatch({type: integrationConstants.INTEGRATIONS_SET_MICROSOFT_AD_ACCOUNT_INTEGRATED, accountId});
        }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error("Microsoft Ads ad account integration failed: " + error?.response?.data?.error));
            }
        );
    };
}

function integrateMicrosoftConversionAPI({integrationId = 0, enableConversionAPI = false, events = []}) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.integrateMicrosoftConversionAPI({
            integrationId, enableConversionAPI, events
        }).then(() => {
            dispatch(alertActions.success("Microsoft Ads is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("Microsoft Ads integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function createBodisIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createBodisIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Bodis is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Bodis integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateBodisIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateBodisIntegration(id, data).then(() => {
            dispatch(alertActions.success("Bodis integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Bodis integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createDomainActiveIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createDomainActiveIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Domain Active is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Domain Active integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateDomainActiveIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateDomainActiveIntegration(id, data).then(() => {
            dispatch(alertActions.success("Domain Active integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Domain Active integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createAdsComIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createAdsComIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Ads.com is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Ads.com integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateAdsComIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateAdsComIntegration(id, data).then(() => {
            dispatch(alertActions.success("Ads.com integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Ads.com integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createTonicIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createTonicIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Tonic is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Tonic integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateTonicIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateTonicIntegration(id, data).then(() => {
            dispatch(alertActions.success("Tonic integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Tonic integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function getTaboolaAdAccounts({apiClient, apiSecret, integrationId}) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getTaboolaAdAccounts({apiClient, apiSecret, integrationId})
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS, result: {result: result}});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function createTaboolaIntegration({
                                      name,
                                      apiClient,
                                      apiSecret,
                                      accountId,
                                      currency,
                                      timezone,
                                      enableConversionAPI = false,
                                      events = []
                                  }) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.createTaboolaIntegration({
            name, apiClient, apiSecret, accountId, currency, timezone, enableConversionAPI, events
        }).then(() => {
            dispatch(alertActions.success("Taboola is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("Taboola integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function updateTaboolaIntegration(
    {
        integrationId = 0,
        name,
        apiClient,
        apiSecret,
        accountId,
        currency,
        timezone,
        enableConversionAPI = false,
        events = []
    }
) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.updateTaboolaIntegration(integrationId, {
            name, apiClient, apiSecret, accountId, currency, timezone, enableConversionAPI, events
        }).then(() => {
            dispatch(alertActions.success("Taboola integration is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("Updating Taboola integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function createMgidIntegration({name, apiClient, apiSecret, enableConversionAPI = false, events = []}) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.createMgidIntegration({
            name, apiClient, apiSecret, enableConversionAPI, events
        }).then(() => {
            dispatch(alertActions.success("MGID is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("MGID integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function updateMgidIntegration(
    {integrationId = 0, name, apiClient, apiSecret, enableConversionAPI = false, events = []}
) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.updateMgidIntegration(integrationId, {
            name, apiClient, apiSecret, enableConversionAPI, events
        }).then(() => {
            dispatch(alertActions.success("MGID integration is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("Updating MGID integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function createOutbrainIntegration({
                                       name,
                                       apiToken,
                                       adAccountId,
                                       adAccountName,
                                       currency,
                                       enableConversionAPI = false,
                                       events = []
                                   }) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.createOutbrainIntegration({
            name, apiToken, adAccountId, adAccountName, currency, enableConversionAPI, events
        }).then(() => {
            dispatch(alertActions.success("Outbrain is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("Outbrain integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function updateOutbrainIntegration(
    {integrationId = 0, name, apiToken, adAccountId, adAccountName, currency, enableConversionAPI = false, events = []}
) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.updateOutbrainIntegration(integrationId, {
            name, apiToken, adAccountId, adAccountName, currency, enableConversionAPI, events
        }).then(() => {
            dispatch(alertActions.success("Outbrain integration is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("Updating Outbrain integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function getOutbrainAdAccounts({apiToken, integrationId}) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getOutbrainAdAccounts({apiToken, integrationId})
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS, result: {result: result}});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getTikTokLoginLink(renewIntegrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_TIKTOK_LOGIN_LINK_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getTikTokLoginLink(renewIntegrationId)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_TIKTOK_LOGIN_LINK, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getRenewableTikTokLoginLink(renewIntegrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_VIEW_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getTikTokLoginLink(renewIntegrationId)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_RENEWABLE_TIKTOK_LOGIN_LINK, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function tikTokCallback(code, state) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.tikTokCallback(code, state)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error("TikTok integration failed: " + error?.response?.data?.error));
            }
        );
    };
}

function getTikTokAdAccounts(id) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.getTikTokAdAccounts(id)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS, result});
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function integrateTikTokAdAccount(adAccountId, adAccountName, integrationId) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.integrateTikTokAdAccount(adAccountId, adAccountName, integrationId)
            .then(() => {
                integrationService.getTikTokAdAccounts(integrationId)
                    .then((result) => {
                        dispatch({type: integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS, result});
                    }).catch((error) => {
                        dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                        dispatch(alertActions.error(error?.response?.data?.error));
                    }
                );
            }).catch((error) => {
                dispatch({type: integrationConstants.INTEGRATIONS_ERROR, error});
                dispatch(alertActions.error("TikTok ad account integration failed: " + error?.response?.data?.error));
            }
        );
    };
}

function integrateTikTokConversionAPI({integrationId = 0, enableConversionAPI = false, events = [], pixels = []}) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER, result: {loading: true}});
        dispatch(alertActions.error(""));
        integrationService.integrateTikTokConversionAPI({
            integrationId, enableConversionAPI, events, pixels
        }).then(() => {
            dispatch(alertActions.success("TikTok is successfully integrated."));
        }).catch((error) => {
            dispatch(alertActions.error("TikTok integration failed: " + error?.response?.data?.error));
        }).finally(() => {
            dispatch({
                type: integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER,
                result: {loading: false}
            });
        });
    };
}

function getRsocIntegrations() {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_RSOC_INTEGRATIONS_SPINNER});
        integrationService.getIntegrations({category: "rsoc"})
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_RSOC_INTEGRATIONS, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getServerIntegrations() {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_SERVER_INTEGRATIONS_SPINNER});
        integrationService.getIntegrations({category: "server"})
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_SERVER_INTEGRATIONS, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function createRsocSystem1Integration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createRsocSystem1Integration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Rsoc System1 is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Rsoc System1 integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateRsocSystem1Integration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateRsocSystem1Integration(id, data).then(() => {
            dispatch(alertActions.success("Rsoc System1 integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Rsoc System1 integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createRsocInuvoIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createRsocInuvoIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Rsoc Inuvo is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Rsoc Inuvo integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateRsocInuvoIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateRsocInuvoIntegration(id, data).then(() => {
            dispatch(alertActions.success("Rsoc Inuvo integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Rsoc Inuvo integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createServerAwsIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createServerAwsIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("AWS API is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("AWS API integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateServerAwsIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateServerAwsIntegration(id, data).then(() => {
            dispatch(alertActions.success("AWS API integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("AWS API integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createDomainCloudflareIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createDomainCloudflareIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Cloudflare API is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Cloudflare API integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateDomainCloudflareIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateDomainCloudflareIntegration(id, data).then(() => {
            dispatch(alertActions.success("Cloudflare API integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Cloudflare API integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}

function createDomainNamesiloIntegration(data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.createDomainNamesiloIntegration(data)
            .then((result) => {
                dispatch({type: integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION, result});
                dispatch(alertActions.success("Namesilo API is successfully integrated."));
            }).catch((error) => {
                dispatch(alertActions.error("Namesilo API integration failed: " + error?.response?.data?.error));
                dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
            }
        );
    };
}

function updateDomainNamesiloIntegration(id, data) {
    return (dispatch) => {
        dispatch({type: integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER});
        dispatch(alertActions.error(""));
        integrationService.updateDomainNamesiloIntegration(id, data).then(() => {
            dispatch(alertActions.success("Namesilo API integration is successfully updated."));
        }).catch((error) => {
            dispatch(alertActions.error("Namesilo API integration update failed: " + error?.response?.data?.error));
            dispatch({type: integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER});
        });
    };
}