import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme,
    Alert,
    Box
} from "@mui/material";
import React, {useEffect, useState, useCallback} from "react";
import Spinner from "../../../spinner/Spinner";
import {sharedAffiliateNetworkService} from "../../../../service/sharedAffiliateNetworkService";
import useCustomStyles from "../../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    errorMsg: {
        marginBottom: 10
    },
    template: {
        width: 180,
        height: 56,
        margin: 6,
        backgroundColor: theme.palette.common.white,
        textTransform: "none",
        [theme.breakpoints.down(500)]: {
            width: 140,
            height: 56,
        }
    },
    logo: {
        maxWidth: "94%",
        maxHeight: "84%"
    },
    contentBox: {
        [theme.breakpoints.up(959)]: {
            minHeight: "calc(100vh - 208px)"
        }
    }
});

function ChooseAffiliateNetworkIntegration(props) {

    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const [loading, setLoading] = useState(true);
    const [requestError, setRequestError] = useState("");
    const [templates, setTemplates] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const integrations = ["ClickBank", "Sedo", "System1", "Bodis", "Tonic", "Ads.com", "Domain Active"];

    const [open, setOpen] = useState(true);

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const selectIntegration = (item) => {
        if (item?.name === "ClickBank") {
            props.history.push({pathname: "/settings/integrations/clickbank", state: {from: props.location}});
        }
        if (item?.name === "Sedo") {
            props.history.push({pathname: "/settings/integrations/sedo", state: {from: props.location}});
        }
        if (item?.name === "System1") {
            props.history.push({pathname: "/settings/integrations/system1", state: {from: props.location}});
        }
        if (item?.name === "Bodis") {
            props.history.push({pathname: "/settings/integrations/bodis", state: {from: props.location}});
        }
        if (item?.name === "Tonic") {
            props.history.push({pathname: "/settings/integrations/tonic", state: {from: props.location}});
        }
        if (item?.name === "Ads.com") {
            props.history.push({pathname: "/settings/integrations/ads-com", state: {from: props.location}});
        }
        if (item?.name === "Domain Active") {
            props.history.push({pathname: "/settings/integrations/domain-active", state: {from: props.location}});
        }
    };

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [props]);

    const filteredTemps = !searchQuery
        ? templates
        : templates?.filter(item =>
            item?.name?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );

    useEffect(() => {
        async function fetchData() {
            setRequestError("");
            setLoading(true);
            await sharedAffiliateNetworkService.read().then((response) => {
                if (response !== null && response !== undefined) {
                    setTemplates(response?.sharedAffiliateNetworks);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (templates === null) {
            fetchData();
        }
    }, [templates]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="choose-affiliate-network-integration"
        >
            <DialogTitle style={{textAlign: "center"}}>Add integration</DialogTitle>
            <DialogContent className={classes?.contentBox}>
                {loading && <Spinner overComponentBox={true}/>}
                {requestError.length > 1 &&
                    <Alert className={classes?.errorMsg} variant="outlined" severity="error">
                        {requestError}
                    </Alert>
                }
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                    <TextField
                        variant="outlined"
                        margin="dense"
                        name="affiliate-network"
                        placeholder="Search..."
                        size="small"
                        type="text"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={searchQuery}
                        onChange={handleChange}
                    />

                    {filteredTemps?.map((item, index) => {
                        if (integrations.includes(item?.name)) {
                            return (
                                <Box key={index} flexGrow={0}>
                                    <Button className={classes?.template} variant="contained" color="inherit"
                                            onClick={() => selectIntegration(item)}
                                    >
                                        {item?.logoUrl !== ""
                                            ? <img className={classes?.logo} src={item?.logoUrl} alt={item?.name}/>
                                            : item?.name
                                        }
                                    </Button>
                                </Box>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {filteredTemps?.length < 1 && <p style={{textAlign: "center"}}>No records found.</p>}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChooseAffiliateNetworkIntegration;