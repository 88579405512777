import {
    Box,
    Button,
    Dialog, List, ListItem, ListItemIcon, ListItemText,
    TextField,
    useMediaQuery,
    useTheme,
    Alert, Link
} from "@mui/material";
import {Check} from "@mui/icons-material";
import {integrationActions} from "../../../store/actions/integrationAction";
import {connect} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Spinner from "../../spinner/Spinner";
import {integrationService} from "../../../service/integrationService";
import {alertActions} from "../../../store/actions/alertActions";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        paddingTop: 8,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15
    },
    title: {
        paddingTop: 20,
        paddingBottom: 20,
        textAlign: "center"
    },
    chooseAccount: {
        fontWeight: 500,
        fontSize: 14,
        paddingTop: 24,
        paddingBottom: 4
    },
    adAccountList: {
        maxHeight: 500,
        overflow: "auto",
        paddingBottom: 8,
        marginBottom: 32
    },
    adAccountListItem: {
        paddingBottom: 16
    },
    listItem: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0
    },
    listItemText: {
        whiteSpace: "normal",
    },
    listItemIcon: {
        minWidth: 42
    },
    infoAlert: {
        marginTop: 12
    }
});

function PropellerAdsIntegration(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {integration, create, error, update, reset} = props;

    const integrationId = props?.match?.params?.id;

    const {register, handleSubmit, setValue, formState: {errors}} = useForm();
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        reset();
        setOpen(false);
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [reset, props]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleOnSubmit = useCallback((data) => {
        if (integrationId > 0) {
            update(integrationId, data);
        } else {
            create(data);
        }
    });

    useEffect(() => {
        if (integration?.currentIntegration !== null) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integration?.currentIntegration])

    useEffect(() => {
        async function find(id): void {
            setLoading(true);
            await integrationService.findIntegration(id).then((result) => {
                setValue("name", result?.networkName);
                setValue("token", result?.integrationCredentials[0]?.keyValue);
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                error(e?.response?.data?.error);
                setOpen(false);
                props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
            });
        }

        if (integrationId > 0) {
            find(integrationId);
        }
    }, [error, integrationId, props.history, props.location, setValue]);

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                handleSubmit(handleOnSubmit)();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [handleOnSubmit, handleSubmit]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="propeller-ads-integration"
        >
            <div className={classes?.root}>

                {(integration.loading.integration || loading) && <Spinner size={32} overComponentBox={true}/>}

                <div className={classes?.title}>
                    <img src="/assets/images/traffic-sources/propellerads.svg" alt="PropellerAds integration"
                         width={170}/>
                </div>

                <p style={{paddingLeft: 10}}>
                    Please check our integration
                    guide <Link target="_blank" underline="hover" rel="noreferrer"
                                href="https://skro.eu/docs/integrations/propellerads-integration"
                >
                    here
                </Link>.
                </p>

                <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete={"off"}>
                    <Box display="flex" flexWrap={"wrap"}>
                        <Box p={1} width="100%">
                            <TextField
                                error={!!errors.name}
                                variant="outlined"
                                margin="dense"
                                id="name"
                                label="Integration Name"
                                {...register("name", {required: true, maxLength: 255, minLength: 2})}
                                name="name"
                                size="small"
                                type="text"
                                placeholder="Enter your integration name"
                                fullWidth={true}
                                defaultValue="PropellerAds"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box p={1} width="100%">
                            <TextField
                                error={!!errors.token}
                                variant="outlined"
                                margin="dense"
                                id="token"
                                label="API Token"
                                {...register("token", {required: true, maxLength: 1024, minLength: 2})}
                                name="token"
                                size="small"
                                type="text"
                                placeholder="Enter your API token"
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </form>

                <Box display="flex" flexDirection="row">
                    <Box>
                        <List>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes?.listItemText}}>
                                    Sync cost for campaigns, zones and sites every 30 min.
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes?.listItemText}}>
                                    Check the status of your campaigns and creativities.
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes?.listItemText}}>
                                    Pause or resume campaigns, creativities and zones.
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes?.listItemText}}>
                                    Change the bid value for campaigns and zones.
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                <Box display="flex" ml={1}>
                    <Box flexGrow={1}>
                        <Alert variant="filled" severity="warning">Don't forget to associate given integration with your
                            traffic source.</Alert>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Box mr={2}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                            {integrationId > 0 ? "Update Integration" : "Create Integration"}
                        </Button>
                    </Box>
                </Box>
            </div>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration
    };
};

const mapDispatchToProps = {
    reset: integrationActions.reset,
    create: integrationActions.createPropellerAdsIntegration,
    update: integrationActions.updatePropellerAdsIntegration,
    error: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(PropellerAdsIntegration);