import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import {customEventServices} from "../../service/customEventService";
import {Autocomplete, TextField} from "@mui/material";

export default function CustomConversionEventSelect({control, errors, selectedValue}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await customEventServices.fetch({});
                setOptions(
                    response?.map(item => {
                        return {
                            value: item?.id,
                            label: item?.name
                        }
                    })
                );
            } catch (e) {
                console.log({e});
            } finally {
                setLoading(false);
            }
        }

        if (open) {
            fetchData();
        }
    }, [open]);

    useEffect(() => {
        if (selectedValue !== null) {

        }
    }, [selectedValue])

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    getOptionLabel={(option) => option?.label}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={!!errors?.customConversionEventId}
                            placeholder="Select a custom conversion event"
                            variant="outlined"
                            size="small"
                            margin="dense"
                            label="Custom Conversion Event"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={null}
            name="customConversionEventId"
            control={control}
            defaultValue={null}
        />
    );
}
