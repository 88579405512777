import {authConstants} from "../constants/authConstants";
import isEmpty from "lodash/isEmpty"
import {postbackDomain} from "../../helpers/config";

const initialState = {
    authError: null,
    isAuthenticated: false,
    userSettings: {
        timezone: "Etc/UTC",
        currencyCode: "EUR",
        defaultTrackingDomain: postbackDomain,
        role: "",
        loaded: false
    },
    user: {},
    subscription: {
        plan: null,
        status: null,
        loaded: false,
        usedEvents: 0,
        hasPaymentMethod: false
    }
};

const authReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case authConstants.VERIFY_GOOGLE_LOGIN_ERROR:
            return {
                ...state,
                authError: action?.errorMessage,
                isAuthenticated: false
            };
        case authConstants.VERIFY_EMAIL_LOGIN_ERROR:
            return {
                ...state,
                authError: action?.errorMessage,
                isAuthenticated: false
            };
        case authConstants.VERIFY_EMAIL_LOGIN_SUCCESS:
            return {
                ...state,
                authError: null,
                isAuthenticated: !isEmpty(action?.payload?.user),
                user: action?.payload?.user
            };
        case authConstants.UPDATE_AUTHENTICATION_STATUS:
            return {
                ...state,
                authError: null,
                isAuthenticated: !isEmpty(action?.user),
                user: action.user
            };
        case authConstants.UPDATE_USER_SETTINGS:
            return {
                ...state,
                userSettings: {
                    timezone: action?.userSettings?.timezone,
                    currencyCode: action?.userSettings?.currencyCode,
                    defaultTrackingDomain: action?.userSettings?.defaultTrackingDomain,
                    role: action?.userSettings?.role,
                    loaded: true
                }
            };
        case authConstants.USER_SETTINGS_UPDATE_TIMEZONE:
            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    timezone: action?.timezone,
                }
            };
        case authConstants.UPDATE_SUBSCRIPTION:
            return {
                ...state,
                subscription: {
                    plan: action?.subscription?.plan?.name,
                    status: action?.subscription?.plan?.status,
                    usedEvents: action?.subscription?.usedEvents,
                    hasPaymentMethod: action?.subscription?.hasPaymentMethod,
                    loaded: true
                }
            };
        case authConstants.LOGOUT:
            return {
                ...state,
                authError: null,
                isAuthenticated: false,
                userSettings: {},
                user: {}
            };
        default:
            return state;
    }
};

export default authReducer;