import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme,
    Box
} from "@mui/material";
import React, {useState, useCallback} from "react";

function ChooseServerIntegration(props) {

    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState("");

    const templates = [
        {
            name: "AWS",
            logoUrl: "/assets/images/servers/aws.svg"
        },
        {
            name: "Cloudflare",
            logoUrl: "/assets/images/servers/cloudflare.svg"
        },
        {
            name: "Namesilo",
            logoUrl: "/assets/images/servers/namesilo.svg"
        }
    ];

    const integrations = ["AWS", "Cloudflare", "Namesilo"];

    const [open, setOpen] = useState(true);

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const selectIntegration = (item) => {
        if (item?.name === "AWS") {
            props.history.push({pathname: "/settings/integrations/server-aws", state: {from: props.location}});
        }
        if (item?.name === "Cloudflare") {
            props.history.push({pathname: "/settings/integrations/server-cloudflare", state: {from: props.location}});
        }
        if (item?.name === "Namesilo") {
            props.history.push({pathname: "/settings/integrations/server-namesilo", state: {from: props.location}});
        }
    };

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [props]);

    const filteredTemps = !searchQuery
        ? templates
        : templates?.filter(item =>
            item?.name?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="choose-server-or-domain-integration"
        >
            <DialogTitle style={{textAlign: "center"}}>Add integration</DialogTitle>
            <DialogContent sx={{[theme.breakpoints.up(959)]: {minHeight: "calc(100vh - 208px)"}}}>
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                    <TextField
                        variant="outlined"
                        margin="dense"
                        name="server"
                        placeholder="Search..."
                        size="small"
                        type="text"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={searchQuery}
                        onChange={handleChange}
                    />

                    {filteredTemps?.map((item, index) => {
                        if (integrations.includes(item?.name)) {
                            return (
                                <Box key={index} flexGrow={0}>
                                    <Button
                                        sx={{
                                            width: "180px",
                                            height: "56px",
                                            margin: "6px",
                                            backgroundColor: theme.palette.common.white,
                                            textTransform: "none",
                                            [theme.breakpoints.down(500)]: {
                                                width: 140,
                                                height: 56,
                                            }
                                        }}
                                        variant="contained"
                                        color="inherit"
                                        onClick={() => selectIntegration(item)}
                                    >
                                        {item?.logoUrl !== "" ?
                                            <img
                                                style={{maxWidth: "94%", maxHeight: "84%"}}
                                                src={item?.logoUrl}
                                                alt={item?.name}
                                            />
                                            : item?.name
                                        }
                                    </Button>
                                </Box>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {filteredTemps?.length < 1 &&
                        <Box p={3} textAlign="center">No records found.</Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChooseServerIntegration;