import React from "react";
import {
    Button,
    Divider,
    List,
    ListItem,
    Box,
    ListItemText,
    ListItemIcon,
    useTheme,
    Typography, Link
} from "@mui/material";
import {Check} from "@mui/icons-material";
import {SettingsGeneralPaper} from "../../ProfileSettings";

function RsocNoIntegrationsView(props) {
    const theme = useTheme();
    const addIntegration = () => {
        props.history.push({
            pathname: "/settings/integrations/add-rsoc",
            state: {background: props.location}
        });
    };

    return (
        <Box sx={{
            width: "100%",
            padding: "24px 30px 0 30px",
            maxWidth: "1280px",
            minWidth: "300px",
            margin: "0 auto",
            [theme.breakpoints.down(600)]: {
                padding: 0,
                marginTop: "16px"
            },
        }}>
            <SettingsGeneralPaper elevation={0}>
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1}>
                        <Typography variant="h3" sx={{
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "bold",
                            display: "block",
                            paddingTop: "16px",
                            paddingBottom: "24px"
                        }}>
                            Revenue Sync - RSOC
                        </Typography>
                    </Box>
                    <Box pt={1}>
                        <Button type="button" onClick={addIntegration} color="primary" variant="contained">
                            Add
                        </Button>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" pb={2}>
                    <Box flexGrow={1} sx={{color: "text.disabledLight"}}>
                        Google's Related Search for Content (RSOC) displays search terms related to the current page on
                        a publisher's site. Users can click these terms to explore similar topics and ads on the site,
                        boosting engagement, traffic, and ad revenue. <Link target="_blank" underline="hover"
                                                                            rel="noreferrer"
                                                                            href="https://blog.google/products/adsense/introducing-related-search-content-pages/"
                    >
                        Learn more
                    </Link>
                    </Box>
                </Box>
                <Divider/>
                <Box pt={2} pb={1} sx={{[theme.breakpoints.down(600)]: {overflow: "auto"}}}>
                    <Box display="flex" flexDirection="row" alignItems="end" flexWrap="nowrap">
                        <Box mt={1}>
                            <img src="/assets/images/affiliate-networks/system1.svg" alt="System1 logo"
                                 width={120} style={{
                                backgroundColor: theme.palette.background.default,
                                marginRight: "8px",
                                borderRadius: "4px",
                                padding: "8px"
                            }}/>
                        </Box>
                        <Box mt={1}>
                            <img src="/assets/images/affiliate-networks/inuvo.svg" alt="Inuvo logo"
                                 width={115} style={{
                                backgroundColor: theme.palette.background.default,
                                marginRight: "8px",
                                borderRadius: "4px",
                                padding: "6px 14px"
                            }}/>
                        </Box>
                    </Box>
                </Box>
                <Box pt={1} pb={1} sx={{color: "text.disabledLight"}}>
                    You don't have any integrations. Integrate now to stream revenue data.
                </Box>
                <Box flex="display" flexWrap="wrap">
                    <Box>
                        <List>
                            <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Stream revenue from search feed arbitrage."/>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </SettingsGeneralPaper>
        </Box>
    );
}

export default RsocNoIntegrationsView;