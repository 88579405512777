import React, {useEffect} from "react";
import {Paper, useTheme} from "@mui/material";
import {connect} from "react-redux";
import {trackingDomainActions} from "../../../store/actions/trackingDomainAction";
import TrackingDomainsList from "./TrackingDomainsList";
import AddTrackingDomain from "./AddTrackingDomain";
import Spinner from "../../spinner/Spinner";
import useCustomStyles from "../../utils/UseCustomStyles";
import CustomSnackbar from "../../alert/CustomSnackbar";

const customStyles = (theme) => ({
    root: {
        width: "100%",
        padding: "20px 30px",
        [theme.breakpoints.up('lg')]: {
            padding: "0px 15px 20px 30px",
        },
        [theme.breakpoints.down(600)]: {
            padding: 0,
        },
    },
    paper: {
        position: "relative",
        padding: 12,
        minHeight: 200,
        minWidth: 300,
        maxWidth: "100%",
        backgroundColor: theme.palette.background.defaultBlue
    },
    header: {
        fontSize: 18,
        paddingBottom: 8,
        [theme.breakpoints.down(600)]: {
            paddingLeft: 16,
        },
    },
    email: {
        padding: "20px 12px 20px 12px",
        fontSize: 16
    },
    actions: {
        flex: "0 0 auto",
        display: "flex",
        padding: 8,
        alignItems: "center",
        justifyContent: "flex-end"
    }
});

const TrackingDomains = (props) => {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const {loadTrackingDomains} = props;
    const {errorMsg, successMsg, trackingDomains, isLoading} = props?.state;

    useEffect(() => {
        loadTrackingDomains();
    }, [loadTrackingDomains]);

    return (
        <div className={classes?.root}>
            <h3 className={classes?.header}>Tracking Domains</h3>
            <Paper className={classes?.paper} elevation={0}>
                {isLoading && <Spinner size={32} overComponentBox={true}/>}

                {errorMsg !== "" && <CustomSnackbar timeout={2500} message={errorMsg}/>}
                {successMsg !== "" && <CustomSnackbar timeout={2500} message={successMsg} severity="success"/>}


                <AddTrackingDomain {...props}/>

                <TrackingDomainsList data={trackingDomains} title={"Domain with HTTPS"}/>
            </Paper>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        state: state.trackingDomain
    }
};

const mapDispatchToProps = {
    loadTrackingDomains: trackingDomainActions.fetch,
    addAndVerify: trackingDomainActions.addAndVerify,
    clearErrors: trackingDomainActions.clearErrors,
    setActiveStep: trackingDomainActions.setStep,
    setTrackingDomain: trackingDomainActions.setTrackingDomain
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingDomains);