import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Radio, RadioGroup,
    useMediaQuery,
    useTheme,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import Spinner from "../../spinner/Spinner";
import {referralServices} from "../../../service/referralService";
import {connect} from "react-redux";
import {alertActions} from "../../../store/actions/alertActions";

function Withdraw(props) {

    const {onClose, open, errorMsg, successMsg, clear} = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loading, setLoading] = useState(false);

    const {register, handleSubmit, setValue, formState: {errors}} = useForm();

    const onSubmit = async (data) => {

        setLoading(true);
        let formData = {
            method: data?.paymentMethod,
            account: data?.account
        };

        if (paymentMethod?.id > 0) {
            await referralServices.UpdatePaymentMethod(paymentMethod?.id, formData).then(() => {
                setLoading(false);
                successMsg("Payment method successfully updated.");
                handleClose();
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    errorMsg(e.response.data.error);
                }
                setLoading(false);
            });
        } else {
            await referralServices.AddPaymentMethod(formData).then(() => {
                setLoading(false);
                successMsg("Payment method successfully added.");
                handleClose();
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    errorMsg(e.response.data.error);
                }
                setLoading(false);
            });
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        setTimeout(() => {
            clear();
        }, 3000);

        setPaymentMethod(null);
        onClose();
    };

    useEffect(() => {
        async function fetchPaymentMethod(): void {
            await referralServices.findPaymentMethod().then((result) => {
                setLoading(false);
                if (result?.id > 0) {
                    setPaymentMethod(result);
                    setValue("paymentMethod", result?.method);
                    setValue("account", result?.account);
                }
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    errorMsg(e.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (open) {
            fetchPaymentMethod();
        }

    }, [errorMsg, open, setValue]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="withdraw-form"
                maxWidth={"sm"}
        >
            <DialogTitle style={{textAlign: "center"}}>Payment method info</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                    <RadioGroup aria-label="paymentMethod" name="paymentMethod" value="paypal">
                        <FormControlLabel
                            value="paypal" control={<Radio checked color="primary"/>} label="Paypal"
                        />
                    </RadioGroup>
                    <TextField
                        error={!!errors?.account}
                        type="text"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        label="Account"
                        name="account"
                        fullWidth
                        id="account"
                        {...register("account", {required: true, maxLength: 255, minLength: 3})}
                        placeholder="Enter your account"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                {loading ? <Spinner/> :
                    <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Save & Close
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

Withdraw.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        state: {
            alert: state.alert,
        }
    };
};

const mapDispatchToProps = {
    errorMsg: alertActions.error,
    successMsg: alertActions.success,
    clear: alertActions.clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw);