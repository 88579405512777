import {alertActions} from "./alertActions";
import {logServices} from "../../service/logService";
import {logPostbacksConstants} from "../constants/logPostbacksConstants";

export const logPostbacksActions = {
    getPostbacks,
    loadMorePostbackRows,
    updateClickId,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender
};

function getPostbacks(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: logPostbacksConstants.LOG_POSTBACKS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        logServices.getListOfPostbacks(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: logPostbacksConstants.LOG_POSTBACKS_LOADING, result});
            }).catch((error) => {
                dispatch({type: logPostbacksConstants.LOG_POSTBACKS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function loadMorePostbackRows(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: logPostbacksConstants.LOG_POSTBACKS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        logServices.getListOfPostbacks(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: logPostbacksConstants.LOG_POSTBACKS_INFINITY_SCROLL, result});
            }).catch((error) => {
                dispatch({type: logPostbacksConstants.LOG_POSTBACKS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: logPostbacksConstants.LOG_POSTBACKS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: logPostbacksConstants.LOG_POSTBACKS_UPDATE_SORT_BY, sortBy});
    };
}

function updateClickId(clickId) {
    return (dispatch) => {
        dispatch({type: logPostbacksConstants.LOG_POSTBACKS_UPDATE_CLICK_ID, clickId});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: logPostbacksConstants.LOG_POSTBACKS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: logPostbacksConstants.LOG_POSTBACKS_RERENDER});
    };
}
