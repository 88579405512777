export const groupBy2Constants = {
    GROUP_BY_2_LOADING_SPINNER: 'GROUP_BY_2_LOADING_SPINNER',
    GROUP_BY_2_STOP_LOADING_SPINNER: 'GROUP_BY_2_STOP_LOADING_SPINNER',
    GROUP_BY_2_INFINITY_SCROLL: 'GROUP_BY_2_INFINITY_SCROLL',
    GROUP_BY_2_GET_DATA: 'GROUP_BY_2_GET_DATA',
    GROUP_BY_2_ERROR: 'GROUP_BY_2_ERROR',
    GROUP_BY_2_UPDATE_ORDER_BY: 'GROUP_BY_2_UPDATE_ORDER_BY',
    GROUP_BY_2_UPDATE_SORT_BY: 'GROUP_BY_2_UPDATE_SORT_BY',
    GROUP_BY_2_UPDATE_SORT_COLUMNS: 'GROUP_BY_2_UPDATE_SORT_COLUMNS',
    GROUP_BY_2_RERENDER: 'GROUP_BY_2_RERENDER',
    GROUP_BY_2_SET_SELECTED_ROW: 'GROUP_BY_2_SET_SELECTED_ROW',
    GROUP_BY_2_UPDATE_GROUP_BY: 'GROUP_BY_2_UPDATE_GROUP_BY',
    GROUP_BY_2_RESET: 'GROUP_BY_2_RESET'
};
