export const flowConstants = {
    FLOWS_LOADING_SPINNER: 'FLOWS_LOADING_SPINNER',
    FLOWS_STOP_LOADING_SPINNER: 'FLOWS_STOP_LOADING_SPINNER',
    FLOWS_INFINITY_SCROLL: 'FLOWS_INFINITY_SCROLL',
    FLOWS_GET_DATA: 'FLOWS_GET_DATA',
    FLOWS_ERROR: 'FLOWS_ERROR',
    FLOWS_UPDATE_ORDER_BY: 'FLOWS_UPDATE_ORDER_BY',
    FLOWS_UPDATE_SORT_BY: 'FLOWS_UPDATE_SORT_BY',
    FLOWS_UPDATE_NAME: 'FLOWS_UPDATE_NAME',
    FLOWS_UPDATE_TAGS: 'FLOWS_UPDATE_TAGS',
    FLOWS_UPDATE_SORT_COLUMNS: 'FLOWS_UPDATE_SORT_COLUMNS',
    FLOWS_RERENDER: 'FLOWS_RERENDER',
    FLOWS_ARCHIVE: 'FLOWS_ARCHIVE',
    FLOWS_RESTORE: 'FLOWS_RESTORE',
    FLOWS_UPDATE_SHARED_FLOW: 'FLOWS_UPDATE_SHARED_FLOW',
    BULK_UPDATE_CHANGE_WORKSPACE: 'BULK_UPDATE_CHANGE_WORKSPACE'
};
