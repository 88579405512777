import React, {createRef, useCallback, useEffect, useMemo, useState} from 'react';
import Topbar from "../layout/Topbar";
import Sidebar from "../layout/Sidebar";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomSnackbar from "../alert/CustomSnackbar";
import DataGrid, {SelectColumn} from "react-data-grid";
import {CustomTableHeaderCellRender, EmptyRowsRenderer, IsAtBottom} from "../reporting-table/ReactDataGridUtils";
import {debounce} from "lodash";
import Spinner from "../spinner/Spinner";
import CustomToolbar from "../utils/CustomToolbar";
import {DynamicGridColumnCreator, MarkColumn} from "../reporting-table/Columns";
import {alertActions} from "../../store/actions/alertActions";
import {flowActions} from "../../store/actions/flowAction";
import {columnActions} from "../../store/actions/columnAction";
import {workspaceActions} from "../../store/actions/workspaceAction";
import {GetCommaSeparatedList} from "../../helpers/common";
import {CssBaseline, Divider, useTheme} from "@mui/material";
import useCustomStyles from "../utils/UseCustomStyles";
import {CustomDataGridBox, CustomMainRootBox} from "../dashboard/Dashboard";
import {CustomTableContainer} from "../logs/LogClicksTable";

const customStyles = (theme) => ({
    dataGrid: theme.defaultDataGrid
});

function FlowsGrid(props) {

    const columnGroup = "flows"
    const report = "flow"
    const groupBy = "flow_id";
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {
        alert, getReport, updateSortBy, updateOrderBy, updateSortColumns, loadMoreRows, getWorkspaceIDs
    } = props;
    const {data, isLoading, name, tags, orderBy, sortBy, sortColumns, rerender} = props.flowState;
    const {from, to, isDeleted} = props.ddToolbarState;
    const {timezone, currencyCode} = props.auth.userSettings;

    const gridRef = createRef(null);

    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [columns, setColumns] = useState(null);

    const updateColumnResize = (idx, width) => {
        props.updateColumnWidth({columnKey: columns[idx]?.key, width, columnGroup, report});
    };

    const modifiedTableColumns = useMemo(() => {
        function CustomHeaderCell(props) {
            return <CustomTableHeaderCellRender {...props}/>;
        }

        return columns?.map((c) => {
            if (c?.key === "select-row" || c?.key === "mark") {
                return c;
            }
            return {...c, renderHeaderCell: CustomHeaderCell};
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, columnGroup, report]);

    const getTableColumns = () => {
        let columns = [];
        props?.column?.data[columnGroup]?.forEach((col) => {
            if (!col?.hidden) {
                columns.push(col?.reportColumnKey);
            }
        });
        return columns;
    };

    const handleGetReportData = ({exportCSV = "", pageSize = 100}) => {
        gridRef?.current?.element?.scrollTo(0, 0);
        getReport(groupBy, from, to, timezone, name, tags, isDeleted, 1, pageSize, orderBy, sortBy, currencyCode, exportCSV, props?.metricFilter.filters, getTableColumns(), getWorkspaceIDs());
    };

    const handleInfinityScroll = (event) => {
        if (isLoading || !IsAtBottom(event)) {
            return;
        }

        let disable = data.page >= Math.ceil(data?.total?.totalRecords / data?.recordsPerPage);
        if (!disable) {
            loadMoreRows(groupBy, from, to, timezone, name, tags, isDeleted, data.page + 1, 100, orderBy, sortBy, currencyCode, props?.metricFilter.filters, getTableColumns(), getWorkspaceIDs());
        }
    };

    const handleRefresh = () => {
        gridRef?.current?.element?.scrollTo(0, 0);
        handleGetReportData({});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleColumnResize = useCallback(debounce(updateColumnResize, 500), [columns, columnGroup, report]);

    const handleColumnRender = () => {
        let columns = [
            SelectColumn,
            MarkColumn,
            ...DynamicGridColumnCreator({columns: props?.column?.data[columnGroup]})
        ];
        setColumns(columns);
    };

    // 1. Generate grid columns dynamically at once.
    useEffect(() => {
        if (props?.column?.data !== null && columns === null) {
            handleColumnRender();
            handleRefresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.column?.data]);

    // 2. Get report data.
    useEffect(() => {
        if (columns !== null) {
            handleGetReportData({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to, timezone, name, tags, isDeleted, sortBy, orderBy, currencyCode, props?.metricFilter?.filters]);

    // 3. Rerender
    useEffect(() => {
        if (rerender && columns !== null) {
            handleGetReportData({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerender]);

    useEffect(() => {
        if (columns !== null) {
            handleColumnRender();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.column?.data]);

    useEffect(() => {
        if (sortColumns.length === 0) {
            updateSortBy("");
            updateOrderBy("");
            return () => {
            };
        }
        const {columnKey, direction} = sortColumns[0];
        updateOrderBy(columnKey);
        updateSortBy(direction);
    }, [updateOrderBy, updateSortBy, sortColumns]);

    // 6. Rerender if user workspaces has changed.
    useEffect(() => {
        if (props?.workspace?.updated !== null) {
            handleGetReportData({});
            props?.resetUpdatedWorkspaceValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.workspace?.updated])

    return (
        <CustomMainRootBox>
            <CssBaseline/>
            <Sidebar {...props}/>
            <Topbar/>
            <CustomTableContainer>
                <CustomToolbar
                    columns={columns}
                    selectedRows={selectedRows}
                    onChangeToPage={props.rerender}
                    searchTerm={name}
                    onChangeSearch={props.updateName}
                    tagsTerm={tags}
                    onChangeTags={props.updateTags}
                    onClickAdd={() => {
                        props.history.push({
                            pathname: "/flows/new",
                            state: {background: props.location}
                        });
                    }}
                    onClickRefresh={handleRefresh}
                    onClickExportCSV={() => handleGetReportData({exportCSV: "csv", pageSize: 10000})}
                    onClickEdit={(selectedRowId) => {
                        props.history.push({
                            pathname: "/flows/edit/" + selectedRowId,
                            state: {background: props.location}
                        });
                    }}
                    onClickDuplicate={(selectedRowId) => {
                        props.history.push({
                            pathname: "/flows/duplicate/" + selectedRowId,
                            state: {background: props.location}
                        });
                    }}
                    onClickArchive={(selectedRowsIds) => {
                        selectedRows.clear();
                        props.archive({ids: selectedRowsIds})
                    }}
                    onClickRestore={(selectedRowsIds) => {
                        selectedRows.clear();
                        props.restore({ids: selectedRowsIds})
                    }}
                    onSubmitChangeWorkspace={(data) => {
                        data.flowIds = '' + GetCommaSeparatedList(selectedRows);
                        props?.changeWorkspace(data);
                    }}
                    report={report}
                    columnGroup={columnGroup}
                />
                <Divider/>
                <CustomDataGridBox>

                    {alert.errorMsg !== "" && <CustomSnackbar message={alert.errorMsg}/>}
                    {isLoading && <Spinner overComponentBox={true}/>}

                    {columns !== null && classes !== null &&
                        <DataGrid
                            ref={gridRef}
                            rowKeyGetter={(row) => row.id}
                            columns={modifiedTableColumns}
                            rows={data?.records ? data.records : []}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                                draggable: false
                            }}
                            onColumnResize={handleColumnResize}
                            sortColumns={sortColumns}
                            onSortColumnsChange={updateSortColumns}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            onSelectedCellChange={({rowIdx, row, column}) => {
                                if (rowIdx !== -1 && column?.key !== "select-row") {
                                    setSelectedRows(new Set().add(row.id));
                                }
                            }}
                            onScroll={handleInfinityScroll}
                            noRowsFallback={<EmptyRowsRenderer/>}
                            bottomSummaryRows={[data]}
                            enableVirtualization={true}
                            className={classes?.dataGrid}
                        />
                    }
                </CustomDataGridBox>
            </CustomTableContainer>
        </CustomMainRootBox>
    );
}

const mapStateToProps = (state) => {
    return {
        flowState: state.flow,
        ddToolbarState: state.ddToolbar,
        auth: state.auth,
        alert: state.alert,
        metricFilter: state.metricFilter,
        column: state.column,
        workspace: state.workspace
    }
};

const actionCreators = {
    getReport: flowActions.getReport,
    loadMoreRows: flowActions.loadMoreRows,
    updateOrderBy: flowActions.updateOrderBy,
    updateSortBy: flowActions.updateSortBy,
    updateName: flowActions.updateName,
    updateTags: flowActions.updateTags,
    updateSortColumns: flowActions.updateSortColumns,
    changeWorkspace: flowActions.changeWorkspace,
    archive: flowActions.archive,
    restore: flowActions.restore,
    error: alertActions.error,
    refresh: flowActions.rerender,
    updateColumnWidth: columnActions.updateColumnWidth,
    updateColumnSequence: columnActions.updateColumnSequence,
    getWorkspaceIDs: workspaceActions.getWorkspaceIDs,
    resetUpdatedWorkspaceValue: workspaceActions.resetUpdatedWorkspaceValue
};

export default compose(connect(mapStateToProps, actionCreators))(FlowsGrid);