import {customEventConstants} from "../constants/customEventConstants";

const initialState = {
    data: null,
    isLoading: false,
    rerender: false
};

const customEventReducer = (state = initialState, action) => {
    switch (action.type) {
        case customEventConstants.CUSTOM_EVENT_LOADING_SPINNER:
            return {
                ...state,
                isLoading: true,
            };
        case customEventConstants.CUSTOM_EVENT_STOP_LOADING_SPINNER:
            return {
                ...state,
                isLoading: false,
            };
        case customEventConstants.CUSTOM_EVENT_GET_DATA:
            return {
                ...state,
                data: action?.result,
                isLoading: false,
                rerender: false
            };
        case customEventConstants.CUSTOM_EVENT_ARCHIVE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case customEventConstants.CUSTOM_EVENT_RESTORE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case customEventConstants.CUSTOM_EVENT_RERENDER:
            return {
                ...state,
                rerender: true
            };
        default:
            return state;
    }
};

export default customEventReducer;