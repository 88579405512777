import {apiRequest} from "../helpers/utils";

export const tagServices = {
    read,
    bulkUpdate
};

function read({name}) {
    return apiRequest("get", "v1/tag", {}, {name});
}

function bulkUpdate(formData) {
    return apiRequest("patch", "v1/tag", formData);
}