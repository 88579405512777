import {CopyToClipboard} from "react-copy-to-clipboard";
import {Button, Box, TextField, InputAdornment} from "@mui/material";
import React from "react";

export default function CopyContentToClipboard({label, copyText = "", showStartAdornment = false, startAdornmentText}) {
    const renderInputStartAdornment = (showStartAdornment, startAdornmentText) => {
        if (showStartAdornment) {
            return (
                <InputAdornment position="start">{startAdornmentText}</InputAdornment>
            );
        }
    };
    return (
        <div>
            <Box display="flex" flexWrap="nowrap">
                <Box flexGrow={1}>
                    <TextField
                        type="text"
                        value={copyText || ""}
                        size="small"
                        variant="outlined"
                        margin="dense"
                        label={label}
                        fullWidth
                        readOnly
                        onClick={(e) => {
                            if (e.target.localName === "input") {
                                return e?.target?.setSelectionRange(0, e?.target?.value?.length)
                            }
                        }}
                        InputProps={{
                            startAdornment: renderInputStartAdornment(showStartAdornment, startAdornmentText)
                        }}
                    />
                </Box>
                <Box mt={1.2} ml={1}>
                    <CopyToClipboard text={copyText}>
                        <Button color="primary">Copy</Button>
                    </CopyToClipboard>
                </Box>
            </Box>
        </div>
    );
}