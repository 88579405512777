import React from 'react';
import {Controller} from "react-hook-form";
import {countries} from "../../utils/DataList";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Chip, TextField} from "@mui/material";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

export default function CountryMultiSelect({control, inputName, inputLabel, updateCondition}) {
    const listOfCountries = () => {
        // eslint-disable-next-line array-callback-return
        return countries.filter((country) => {
            if (country?.label !== "Global") {
                return country;
            }
        })
    };

    return (
        <Controller
            render={({field: {onChange, ...props}}) => {
                return (
                    <Autocomplete
                        multiple
                        disableCloseOnSelect={true}
                        id="country-multi-select"
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        getOptionLabel={(option) => option.label}
                        options={listOfCountries()}
                        renderOption={(props, option, {selected, index}) => (
                            <li {...props} key={index}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    sx={{marginRight: "8px", padding: "2px"}}
                                    checked={selected}
                                    color="primary"
                                />
                                {option?.label}
                            </li>
                        )}
                        renderTags={(value, getTagProps) =>
                            value?.map((option, index) => (
                                <Chip key={index} variant="outlined" color="primary" size="small"
                                      label={option.label} {...getTagProps({index})} />
                            ))
                        }
                        renderInput={params => (
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        margin="dense"
                                        label={inputLabel}
                                    />
                                </Box>
                            </Box>
                        )}
                        onChange={(e, data) => {
                            onChange(data);
                            updateCondition();
                        }}
                        {...props}
                    />
                )
            }}
            name={inputName}
            control={control}
            onChange={([, data]) => data}
        />
    );
}
