import {history} from '../../helpers/history';
import {cloakingDomainConstants} from "../constants/cloakingDomainConstants";
import {cloakingDomainServices} from "../../service/cloakingDomainService";

export const cloakingDomainAction = {
    fetch,
    remove,
    clearErrors,
    addAndVerify,
    setStep,
    setCloakingDomain
};

function fetch(name) {
    return dispatch => {
        dispatch({type: cloakingDomainConstants.SET_CLOAKING_IS_LOADING});
        cloakingDomainServices.read(name)
            .then((result) => {
                dispatch({type: cloakingDomainConstants.LOADING_CLOAKING_DOMAINS, result});
                history.push('/settings/domains');
            }).catch((error) => {
                dispatch({type: cloakingDomainConstants.LOADING_CLOAKING_DOMAINS_ERROR, error});
            }
        );
    };
}

function clearErrors() {
    return dispatch => {
        dispatch({type: cloakingDomainConstants.CLEAR_CLOAKING_ERRORS});
    };
}

function setStep(activeStep) {
    return dispatch => {
        dispatch({type: cloakingDomainConstants.SET_CLOAKING_ACTIVE_STEP, activeStep});
    };
}

function setCloakingDomain(cloakingDomain) {
    return dispatch => {
        dispatch({type: cloakingDomainConstants.SET_CLOAKING_DOMAIN, cloakingDomain});
    };
}

function addAndVerify(domainName) {
    return (dispatch, getState) => {
        dispatch({type: cloakingDomainConstants.SET_CLOAKING_IS_LOADING});
        cloakingDomainServices.addAndVerify(domainName).then((domain) => {
            let domains = getState().cloakingDomain.cloakingDomains;
            domains.push(domain);
            dispatch({type: cloakingDomainConstants.ADD_CLOAKING_DOMAIN, domains});
            history.push('/settings/domains');
        }).catch((error) => {
            if (error?.response?.status === 403) {
                dispatch({
                    type: cloakingDomainConstants.ADD_CLOAKING_DOMAIN_ERROR,
                    error: "Access forbidden."
                });
            } else {
                dispatch({
                    type: cloakingDomainConstants.ADD_CLOAKING_DOMAIN_ERROR,
                    error: error?.response?.data?.error
                });
            }
        }).finally(() => {
            setTimeout(() => {
                dispatch({type: cloakingDomainConstants.CLEAR_CLOAKING_ERRORS});
            }, 3500);
        });
    };
}

function remove(id) {
    return (dispatch, getState) => {
        dispatch({type: cloakingDomainConstants.SET_CLOAKING_IS_LOADING});
        let domains = getState().cloakingDomain.cloakingDomains.filter((domain) => domain.id !== id);
        cloakingDomainServices.remove(id).then(() => {
            dispatch({type: cloakingDomainConstants.DELETE_CLOAKING_DOMAIN, domains});
            history.push('/settings/domains');
        }).catch((error) => {
            if (error?.response?.status === 403) {
                dispatch({
                    type: cloakingDomainConstants.DELETE_CLOAKING_DOMAIN_ERROR,
                    error: "Access forbidden."
                });
            } else {
                dispatch({
                    type: cloakingDomainConstants.DELETE_CLOAKING_DOMAIN_ERROR,
                    error: "Unable to delete this cloaking domain. Please try again."
                });
            }
        }).finally(() => {
            setTimeout(() => {
                dispatch({type: cloakingDomainConstants.CLEAR_CLOAKING_ERRORS});
            }, 3500);
        });
    };
}
