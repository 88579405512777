import {alertActions} from "./alertActions";
import {referralConstants} from "../constants/referralConstants";
import {referralServices} from "../../service/referralService";

export const referralActions = {
    fetchBalance,
    fetchTransactions
};

function fetchBalance() {
    return dispatch => {
        dispatch({type: referralConstants.REFERRAL_BALANCE_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        referralServices.fetchBalance()
            .then((result) => {
                dispatch({type: referralConstants.REFERRAL_BALANCE_FETCH, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function fetchTransactions() {
    return dispatch => {
        dispatch({type: referralConstants.REFERRAL_TRANSACTIONS_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        referralServices.fetchTransactions()
            .then((result) => {
                dispatch({type: referralConstants.REFERRAL_TRANSACTIONS_FETCH, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}