export const integrationConstants = {
    INTEGRATIONS_ERROR: 'INTEGRATIONS_ERROR',
    INTEGRATIONS_LOADING_INTEGRATIONS_SPINNER: 'INTEGRATIONS_LOADING_INTEGRATIONS_SPINNER',
    INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER: 'INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER',
    INTEGRATIONS_LOADING_FB_LOGIN_LINK_SPINNER: 'INTEGRATIONS_LOADING_FB_LOGIN_LINK_SPINNER',
    INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER: 'INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER',
    INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER: 'INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER',
    INTEGRATIONS_LOADING_INTEGRATIONS_VIEW_SPINNER: 'INTEGRATIONS_LOADING_INTEGRATIONS_VIEW_SPINNER',
    INTEGRATIONS_RERENDER: 'INTEGRATIONS_RERENDER',
    INTEGRATIONS_UPDATE_STEP: 'INTEGRATIONS_UPDATE_STEP',
    INTEGRATIONS_UPDATE_SELECTED_INTEGRATION: 'INTEGRATIONS_UPDATE_SELECTED_INTEGRATION',
    INTEGRATIONS_RESET: 'INTEGRATIONS_RESET',
    INTEGRATIONS_SET_FB_LOGIN_LINK: 'INTEGRATIONS_SET_FB_LOGIN_LINK',
    INTEGRATIONS_SET_CURRENT_INTEGRATION: 'INTEGRATIONS_SET_CURRENT_INTEGRATION',
    INTEGRATIONS_SET_AD_ACCOUNTS: 'INTEGRATIONS_SET_AD_ACCOUNTS',
    INTEGRATIONS_SET_INTEGRATIONS: 'INTEGRATIONS_SET_INTEGRATIONS',
    INTEGRATIONS_UPDATE_DATA_TRANSFER_STATUS: 'INTEGRATIONS_UPDATE_DATA_TRANSFER_STATUS',
    INTEGRATIONS_SET_RENEWABLE_FB_LOGIN_LINK: 'INTEGRATIONS_SET_RENEWABLE_FB_LOGIN_LINK',
    INTEGRATIONS_LOADING_AFFILIATE_NETWORK_INTEGRATIONS_SPINNER: 'INTEGRATIONS_LOADING_AFFILIATE_NETWORK_INTEGRATIONS_SPINNER',
    INTEGRATIONS_SET_AFFILIATE_NETWORK_INTEGRATIONS: 'INTEGRATIONS_SET_AFFILIATE_NETWORK_INTEGRATIONS',

    INTEGRATIONS_LOADING_GOOGLE_LOGIN_LINK_SPINNER: 'INTEGRATIONS_LOADING_GOOGLE_LOGIN_LINK_SPINNER',
    INTEGRATIONS_SET_GOOGLE_LOGIN_LINK: 'INTEGRATIONS_SET_GOOGLE_LOGIN_LINK',
    INTEGRATIONS_SET_RENEWABLE_GOOGLE_LOGIN_LINK: 'INTEGRATIONS_SET_RENEWABLE_GOOGLE_LOGIN_LINK',
    INTEGRATIONS_SET_AD_ACCOUNT_INTEGRATED: 'INTEGRATIONS_SET_AD_ACCOUNT_INTEGRATED',
    INTEGRATIONS_CONVERSION_API_LOADING_SPINNER: 'INTEGRATIONS_CONVERSION_API_LOADING_SPINNER',

    INTEGRATIONS_SET_MICROSOFT_LOGIN_LINK: 'INTEGRATIONS_SET_MICROSOFT_LOGIN_LINK',
    INTEGRATIONS_LOADING_MICROSOFT_LOGIN_LINK_SPINNER: 'INTEGRATIONS_LOADING_MICROSOFT_LOGIN_LINK_SPINNER',
    INTEGRATIONS_SET_RENEWABLE_MICROSOFT_LOGIN_LINK: 'INTEGRATIONS_SET_RENEWABLE_MICROSOFT_LOGIN_LINK',
    INTEGRATIONS_SET_MICROSOFT_AD_ACCOUNT_INTEGRATED: 'INTEGRATIONS_SET_MICROSOFT_AD_ACCOUNT_INTEGRATED',

    INTEGRATIONS_LOADING_TIKTOK_LOGIN_LINK_SPINNER: 'INTEGRATIONS_LOADING_TIKTOK_LOGIN_LINK_SPINNER',
    INTEGRATIONS_SET_TIKTOK_LOGIN_LINK: 'INTEGRATIONS_SET_TIKTOK_LOGIN_LINK',
    INTEGRATIONS_SET_RENEWABLE_TIKTOK_LOGIN_LINK: 'INTEGRATIONS_SET_RENEWABLE_TIKTOK_LOGIN_LINK',

    INTEGRATIONS_LOADING_RSOC_INTEGRATIONS_SPINNER: 'INTEGRATIONS_LOADING_RSOC_INTEGRATIONS_SPINNER',
    INTEGRATIONS_SET_RSOC_INTEGRATIONS: 'INTEGRATIONS_SET_RSOC_INTEGRATIONS',

    INTEGRATIONS_LOADING_SERVER_INTEGRATIONS_SPINNER: 'INTEGRATIONS_LOADING_SERVER_INTEGRATIONS_SPINNER',
    INTEGRATIONS_SET_SERVER_INTEGRATIONS: 'INTEGRATIONS_SET_SERVER_INTEGRATIONS'
};
