import {apiRequest} from "../helpers/utils";
import {accessApiUrl} from "../helpers/config";

export const referralServices = {
    fetchBalance,
    fetchTransactions,
    AddPaymentMethod,
    UpdatePaymentMethod,
    findPaymentMethod
};

function fetchBalance() {
    return apiRequest("get", "v1/referral/balance", {}, {}, true, false, accessApiUrl);
}

function fetchTransactions() {
    return apiRequest("get", "v1/referral/transaction", {}, {page: 1, pageSize: 2000}, true, false, accessApiUrl);
}

function AddPaymentMethod(formData) {
    return apiRequest("post", "v1/referral/payment-method", formData, {}, true, false, accessApiUrl);
}

function UpdatePaymentMethod(id, formData) {
    return apiRequest("put", "v1/referral/payment-method/" + parseInt(id), formData, {}, true, false, accessApiUrl);
}

function findPaymentMethod() {
    return apiRequest("get", "v1/referral/payment-method", {}, {}, true, false, accessApiUrl);
}