export default function ExportCSV({fileName, data}) {
    let name = "skro_" + fileName;
    const blob = new Blob([data], {type: "text/csv"});
    const a = document.createElement("a");
    a.download = name;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
}