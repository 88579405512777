import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {offerServices} from "../../service/offerService";
import {connect} from "react-redux";
import {offersActions} from "../../store/actions/offersAction";
import {affNetworkServices} from "../../service/affiliateNetworkService";
import {countries, currencies} from "../utils/DataList";
import {OfferForm} from "./OfferForm";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";

function DuplicateOffer(props) {
    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState(null);
    const [offerData, setOfferData] = useState(null);
    const [affiliateNetworkData, setAffiliateNetworkData] = useState(null);
    const [affiliateNetworkId, setAffiliateNetworkId] = useState(0);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");

    const {register, handleSubmit, control, watch, setValue, getValues, formState: {errors}} = useForm();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/offers", state: {from: props.location}});
    }, [props.location, props.history]);

    useEffect(() => {
        async function findOfferById(offerId) {
            setRequestError("");
            setLoading(true);
            await offerServices.find(offerId).then((response) => {
                if (response.hasOwnProperty("id") && response.id > 0) {
                    setOfferData(response);
                    setAffiliateNetworkId(response.affiliateNetworkId ? response.affiliateNetworkId : 0);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (props?.match?.params?.id && offerData === null) {
            findOfferById(props.match.params.id);
        }

    }, [offerData, props]);

    useEffect(() => {
        async function findAffiliateNetworkById(affiliateNetworkId) {
            setRequestError("");
            setLoading(true);
            await affNetworkServices.find(affiliateNetworkId).then((response) => {
                setAffiliateNetworkData({
                    value: response?.id,
                    label: response?.name,
                    sharedAffiliateNetworkId: response?.sharedAffiliateNetworkId,
                    usePostbackProtection: response?.usePostbackProtection,
                    postbackProtectionToken: response?.postbackProtectionToken
                });
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (offerData !== null && affiliateNetworkId !== 0) {
            findAffiliateNetworkById(offerData.affiliateNetworkId)
        }

    }, [affiliateNetworkId, offerData]);

    useEffect(() => {
        if (offerData !== null) {
            setDialogTitle("Duplicate: " + offerData.name.slice(offerData.name.indexOf("-") + 2));

            setTimeout(() => {
                for (const [key, value] of Object.entries(offerData)) {
                    if (key === "dailyCapFailoverOfferId") {
                        continue;
                    }
                    if (key === "name") {
                        setValue("name", offerData.name.slice(offerData.name.indexOf("-") + 2) + " - [copy]");
                    } else {
                        setValue(key, value);
                    }
                }
            });

            if (offerData.currencyCode !== "") {
                currencies.forEach((item) => {
                    if (item.code === offerData.currencyCode) {
                        setValue("currency", item)
                    }
                });
            }

            if (offerData.countryCode !== "") {
                countries.forEach((item) => {
                    if (item.code === offerData.countryCode) {
                        setValue("country", item)
                    }
                });
            }
        }
    }, [offerData, setValue]);

    useEffect(() => {
        if (affiliateNetworkData !== null) {
            setValue("affiliateNetwork", affiliateNetworkData);
        }
    }, [affiliateNetworkData, setValue]);

    useEffect(() => {
        async function editData() {
            setRequestError("");
            setLoading(true);
            await offerServices.create(formData).then((response) => {
                if (response?.id > 0) {
                    props.rerender(true);
                    setLoading(false);
                    handleClose();
                } else {
                    setLoading(false);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
                setLoading(false);
            });
        }

        if (formData !== null) {
            editData();
        }

    }, [handleClose, formData, props]);

    const onSubmit = (data) => {
        let offerName = "";
        if (data.name) {
            offerName = "Global - " + data.name;
            if (data.country && data.country.label) {
                offerName = data.country.label + " - " + data.name;
            }
        }

        setFormData({
            workspaceId: data?.workspace?.value,
            affiliateNetworkId: data.affiliateNetwork && data.affiliateNetwork.value ? data.affiliateNetwork.value : 0,
            clickIdParam: "",
            countryCode: data.country && data.country.code ? data.country.code : "",
            currencyCode: data.currency && data.currency.code ? data.currency.code : "EUR",
            dailyCapFailoverOfferId: data?.dailyCapFailoverOfferId?.value ? data.dailyCapFailoverOfferId.value : 0,
            dailyCapLimit: data?.dailyCapLimit ? data?.dailyCapLimit : "",
            dailyCapTimezone: data?.timezone?.timezone ? data.timezone.timezone : "",
            enableDailyCap: data?.enableDailyCap ? data.enableDailyCap : false,
            name: offerName,
            payoutSum: data.payoutSum ? parseFloat(data.payoutSum) : 0,
            payoutType: data.payoutType ? data.payoutType : "auto",
            url: data.url ? data.url : "",
            tags: data?.tags ? data?.tags : []
        });
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="duplicate-offer-form"
                maxWidth={"md"}
        >
            <OfferForm
                title={dialogTitle}
                watch={watch}
                control={control}
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                offerData={offerData}
                getValues={getValues}
            />
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: offersActions.rerender
};

export default connect(null, mapDispatchToProps)(DuplicateOffer);