import {apiRequest} from "../helpers/utils";

export const integrationService = {
    getFacebookLoginLink,
    facebookCallback,
    getFacebookAdAccounts,
    integrateFacebookAdAccount,
    integrateFacebookConversionAPI,
    updateFacebookStatus,
    updateFacebookDailyBudget,
    updateFacebookAdSetBid,
    deleteIntegrationPixel,
    getIntegrations,
    deleteIntegration,
    enableDataTransfer,
    findIntegration,
    createPushGroundIntegration,
    updatePushGroundIntegration,
    findPushgroundCampaign,
    updatePushgroundCampaignStatus,
    updatePushgroundCampaignBid,
    getPushgroundCampaignCreativities,
    updatePushgroundCreativityStatus,
    getPushgroundCampaignSuppliesAndSitesData,
    updatePushgroundCampaignSupplyAndSiteStatus,
    updatePushgroundCampaignSupplyBid,
    createPropellerAdsIntegration,
    updatePropellerAdsIntegration,
    findPropellerAdsCampaigns,
    updatePropellerAdsCampaignStatus,
    updatePropellerAdsCampaignBid,
    updatePropellerAdsZoneStatus,
    findPropellerAdsZoneRatesAndStatus,
    updatePropellerAdsZoneBid,
    getPropellerAdsBanners,
    updatePropellerAdsBannerStatus,
    createExoClickIntegration,
    updateExoClickIntegration,
    findExoClickCampaigns,
    updateExoClickCampaignStatus,
    updateExoClickCampaignBid,
    updateExoClickZoneStatus,
    findExoClickZoneRatesAndStatus,
    updateExoClickZoneBid,
    getExoClickSiteStatuses,
    updateExoClickSiteStatus,
    getExoClickBanners,
    updateExoClickBannerStatus,
    createClickBankIntegration,
    updateClickBankIntegration,
    createMondiadIntegration,
    updateMondiadIntegration,
    findMondiadCampaigns,
    updateMondiadCampaignStatus,
    updateMondiadCampaignBid,
    updateMondiadZoneStatus,
    findMondiadZoneRatesAndStatus,
    updateMondiadZoneBid,
    findMondiadSiteRatesAndStatuses,
    updateMondiadSiteStatus,
    updateMondiadSiteBid,
    findMondiadCreatives,
    updateMondiadCreativeStatus,
    createSedoIntegration,
    updateSedoIntegration,
    createSystem1Integration,
    updateSystem1Integration,
    createBodisIntegration,
    updateBodisIntegration,
    createDomainActiveIntegration,
    updateDomainActiveIntegration,
    createAdsComIntegration,
    updateAdsComIntegration,
    createTonicIntegration,
    updateTonicIntegration,
    getGoogleLoginLink,
    googleCallback,
    getGoogleAdAccounts,
    integrateGoogleAdAccount,
    integrateGoogleConversionAPI,
    deleteIntegrationEvent,
    findGoogleAdsCampaigns,
    updateGoogleAdsCampaignStatus,
    findGoogleAdsAdGroups,
    updateGoogleAdsAdGroupStatus,
    findGoogleAdsAdGroupAds,
    updateGoogleAdsAdGroupAdStatus,
    getMicrosoftLoginLink,
    microsoftCallback,
    getMicrosoftAdAccounts,
    integrateMicrosoftAdAccount,
    integrateMicrosoftConversionAPI,
    findMicrosoftAdsCampaigns,
    updateMicrosoftAdsCampaignStatus,
    findMicrosoftAdsAdGroups,
    updateMicrosoftAdsAdGroupStatus,
    findMicrosoftAdsAdGroupAds,
    updateMicrosoftAdsAdStatus,
    findFacebookMetricsAndOtherFields,
    getTaboolaAdAccounts,
    createTaboolaIntegration,
    updateTaboolaIntegration,
    findTaboolaCampaigns,
    updateTaboolaCampaignStatus,
    updateTaboolaCampaignDailyBudget,
    findTaboolaCampaignAds,
    findTaboolaCampaign,
    updateTaboolaCampaignAdStatus,
    updateTaboolaCampaignSiteStatus,
    updateTaboolaCampaignSiteBid,
    createMgidIntegration,
    updateMgidIntegration,
    findMgidCampaigns,
    updateMgidCampaignStatus,
    findMgidAds,
    updateMgidAdStatus,
    updateMgidAdBid,
    findMgidWidgets,
    updateMgidWidgetStatus,
    createOutbrainIntegration,
    updateOutbrainIntegration,
    getOutbrainAdAccounts,
    findOutbrainCampaigns,
    updateOutbrainCampaignStatus,
    updateOutbrainCampaignBid,
    updateOutbrainCampaignBudget,
    findOutbrainAds,
    updateOutbrainAdStatus,
    updateOutbrainPublisherStatus,
    updateOutbrainSectionStatus,
    updateOutbrainSectionBid,
    getTikTokLoginLink,
    tikTokCallback,
    getTikTokAdAccounts,
    integrateTikTokAdAccount,
    integrateTikTokConversionAPI,
    findTikTokMetricsAndOtherFields,
    updateTikTokStatus,
    updateTikTokDailyBudget,
    createRsocSystem1Integration,
    updateRsocSystem1Integration,
    createRsocInuvoIntegration,
    updateRsocInuvoIntegration,
    createServerAwsIntegration,
    updateServerAwsIntegration,
    createDomainCloudflareIntegration,
    updateDomainCloudflareIntegration,
    createDomainNamesiloIntegration,
    updateDomainNamesiloIntegration
};

function getFacebookLoginLink(renewIntegrationId) {
    return apiRequest("get", "v1/integration/facebook/login", {}, {renewIntegrationId: renewIntegrationId});
}

function facebookCallback(code, state) {
    return apiRequest("post", "v1/integration/facebook/callback", {code, state});
}

function getFacebookAdAccounts(id) {
    return apiRequest("get", "v1/integration/facebook/ad-accounts/" + parseInt(id), {}, {});
}

function integrateFacebookAdAccount(adAccountId, adAccountName, adAccountTimezone, integrationId) {
    return apiRequest("post", "v1/integration/facebook/ad-account", {
        adAccountId: adAccountId,
        adAccountName: adAccountName,
        adAccountTimezone: adAccountTimezone,
        integrationId: parseInt(integrationId)
    });
}

function integrateFacebookConversionAPI({integrationId, enableConversionAPI, events, pixels}) {
    return apiRequest("post", "v1/integration/facebook/conversion-api", {
        integrationId: parseInt(integrationId),
        enableConversionAPI, events, pixels
    });
}

function updateFacebookStatus({status, integrationId, id}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/facebook/" + id + "/status",
        {status}
    );
}

function updateFacebookDailyBudget({dailyBudget, integrationId, id}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/facebook/" + id + "/daily-budget",
        {integrationId: integrationId, dailyBudget: parseFloat(dailyBudget)}
    );
}

function updateFacebookAdSetBid({bid, integrationId, id}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/facebook/" + id + "/bid",
        {bid: parseFloat(bid)}
    );
}

function deleteIntegrationPixel({integrationId, pixelId}) {
    return apiRequest("post", "v1/integration/" + integrationId + "/pixel/" + pixelId, {});
}

function getIntegrations({name, category}) {
    return apiRequest("get", "v1/integration", {}, {name, category});
}

function findIntegration(id) {
    return apiRequest("get", "v1/integration/" + parseInt(id), {}, {});
}

function deleteIntegration(id) {
    return apiRequest("delete", "v1/integration/" + parseInt(id), {}, {});
}

function enableDataTransfer(id, enableDataTransferStatus) {
    return apiRequest("put", "v1/integration/" + parseInt(id), {enableDataTransferStatus});
}

function createPushGroundIntegration(data) {
    return apiRequest("post", "v1/integration/pushground", data);
}

function updatePushGroundIntegration(id, data) {
    return apiRequest("put", "v1/integration/pushground/" + parseInt(id), data);
}

function findPushgroundCampaign(integrationId, pushgroundId) {
    return apiRequest("get", "v1/integration/pushground/campaign", {}, {integrationId, pushgroundId});
}

function updatePushgroundCampaignStatus(status, integrationId, pushgroundCampaignId) {
    return apiRequest("patch", "v1/integration/pushground/campaign/" + parseInt(pushgroundCampaignId), {
        integrationId,
        status
    });
}

function updatePushgroundCampaignBid(bid, integrationId, pushgroundCampaignId) {
    return apiRequest("put", "v1/integration/pushground/campaign/" + parseInt(pushgroundCampaignId), {
        integrationId: integrationId,
        bid: parseFloat(bid)
    });
}

function getPushgroundCampaignCreativities(integrationId, pushgroundCampaignId) {
    return apiRequest("get", "v1/integration/pushground/creativities/" + parseInt(pushgroundCampaignId), {}, {integrationId});
}

function updatePushgroundCreativityStatus(status, integrationId, creativityId) {
    return apiRequest("patch", "v1/integration/pushground/creativities/" + parseInt(creativityId),
        {integrationId: parseInt(integrationId), status}
    );
}

function getPushgroundCampaignSuppliesAndSitesData(integrationId, pushgroundCampaignId) {
    return apiRequest("get", "v1/integration/pushground/supplies-and-sites/" + parseInt(pushgroundCampaignId), {}, {integrationId});
}

function updatePushgroundCampaignSupplyAndSiteStatus(status, integrationId, pushgroundCampaignId, supplyId, siteId) {
    return apiRequest("patch", "v1/integration/pushground/supplies-and-sites/" + parseInt(pushgroundCampaignId),
        {integrationId: parseInt(integrationId), status, supplyId: parseInt(supplyId), siteId}
    );
}

function updatePushgroundCampaignSupplyBid({bid, integrationId, supplyId, pushgroundCampaignId}) {
    return apiRequest("put", "v1/integration/pushground/supply/" + parseInt(pushgroundCampaignId), {
        integrationId: parseInt(integrationId),
        supplyId: parseInt(supplyId),
        bid: parseFloat(bid)
    });
}

function createPropellerAdsIntegration(data) {
    return apiRequest("post", "v1/integration/propeller-ads", data);
}

function updatePropellerAdsIntegration(id, data) {
    return apiRequest("put", "v1/integration/propeller-ads/" + parseInt(id), data);
}

function findPropellerAdsCampaigns({integrationId, campaignIds}) {
    return apiRequest("get", "v1/integration/propeller-ads/campaign", {}, {integrationId, campaignIds});
}

function updatePropellerAdsCampaignStatus({status, integrationId, campaignId}) {
    return apiRequest("put", "v1/integration/propeller-ads/campaign/" + parseInt(campaignId), {
        integrationId,
        status
    });
}

function updatePropellerAdsCampaignBid({bid, integrationId, campaignId}) {
    return apiRequest("put", "v1/integration/propeller-ads/campaign/" + parseInt(campaignId) + "/bid", {
        integrationId: integrationId,
        bid: parseFloat(bid)
    });
}

function updatePropellerAdsZoneStatus({status, integrationId, campaignId, zoneId}) {
    return apiRequest("put", "v1/integration/propeller-ads/campaign/" + parseInt(campaignId) + "/zone", {
        integrationId,
        status,
        zoneId
    });
}

function findPropellerAdsZoneRatesAndStatus({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/propeller-ads/campaign/" + parseInt(campaignId) + "/zone", {}, {
        integrationId
    });
}

function updatePropellerAdsZoneBid({bid, integrationId, campaignId, zoneId}) {
    return apiRequest("put", "v1/integration/propeller-ads/campaign/" + parseInt(campaignId) + "/zone/" + parseInt(zoneId) + "/bid", {
        integrationId,
        bid: parseFloat(bid)
    });
}

function getPropellerAdsBanners({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/propeller-ads/campaign/" + parseInt(campaignId) + "/banner", {}, {integrationId});
}

function updatePropellerAdsBannerStatus({status, integrationId, bannerId}) {
    return apiRequest("put", "v1/integration/propeller-ads/banner/" + parseInt(bannerId), {
        integrationId,
        status
    });
}

function createExoClickIntegration(data) {
    return apiRequest("post", "v1/integration/exoclick", data);
}

function updateExoClickIntegration(id, data) {
    return apiRequest("put", "v1/integration/exoclick/" + parseInt(id), data);
}

function findExoClickCampaigns({integrationId, campaignIds}) {
    return apiRequest("get", "v1/integration/exoclick/campaign", {}, {integrationId, campaignIds});
}

function updateExoClickCampaignStatus({status, integrationId, campaignId}) {
    return apiRequest("put", "v1/integration/exoclick/campaign/" + parseInt(campaignId), {
        integrationId,
        status
    });
}

function updateExoClickCampaignBid({bid, integrationId, campaignId}) {
    return apiRequest("put", "v1/integration/exoclick/campaign/" + parseInt(campaignId) + "/bid", {
        integrationId: integrationId,
        bid: parseFloat(bid)
    });
}

function updateExoClickZoneStatus({status, integrationId, campaignId, zoneId}) {
    return apiRequest("put", "v1/integration/exoclick/campaign/" + parseInt(campaignId) + "/zone", {
        integrationId,
        status,
        zoneId
    });
}

function findExoClickZoneRatesAndStatus({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/exoclick/campaign/" + parseInt(campaignId) + "/zone", {}, {
        integrationId
    });
}

function updateExoClickZoneBid({bid, integrationId, campaignId, zoneId}) {
    return apiRequest("put", "v1/integration/exoclick/campaign/" + parseInt(campaignId) + "/zone/" + parseInt(zoneId) + "/bid", {
        integrationId,
        bid: parseFloat(bid)
    });
}

function getExoClickSiteStatuses({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/exoclick/campaign/" + parseInt(campaignId) + "/site", {}, {integrationId});
}

function updateExoClickSiteStatus({campaignId, status, integrationId, siteId}) {
    return apiRequest("put", "v1/integration/exoclick/campaign/" + parseInt(campaignId) + "/site/" + parseInt(siteId), {
        integrationId,
        status
    });
}

function getExoClickBanners({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/exoclick/campaign/" + parseInt(campaignId) + "/banner", {}, {integrationId});
}

function updateExoClickBannerStatus({campaignId, status, integrationId, bannerId}) {
    return apiRequest("put", "v1/integration/exoclick/campaign/" + parseInt(campaignId) + "/banner/" + parseInt(bannerId), {
        integrationId,
        status
    });
}

function createClickBankIntegration(data) {
    return apiRequest("post", "v1/integration/clickbank", data);
}

function updateClickBankIntegration(id, data) {
    return apiRequest("put", "v1/integration/clickbank/" + parseInt(id), data);
}

function createMondiadIntegration(data) {
    return apiRequest("post", "v1/integration/mondiad", data);
}

function updateMondiadIntegration(id, data) {
    return apiRequest("put", "v1/integration/mondiad/" + parseInt(id), data);
}

function findMondiadCampaigns({integrationId, campaignIds}) {
    return apiRequest("get", "v1/integration/mondiad/campaign", {}, {integrationId, campaignIds});
}

function updateMondiadCampaignStatus({status, integrationId, campaignId}) {
    return apiRequest("put", "v1/integration/mondiad/campaign/" + parseInt(campaignId), {
        integrationId,
        status
    });
}

function updateMondiadCampaignBid({bid, integrationId, campaignId}) {
    return apiRequest("put", "v1/integration/mondiad/campaign/" + parseInt(campaignId) + "/bid", {
        integrationId: integrationId,
        bid: parseFloat(bid)
    });
}

function updateMondiadZoneStatus({status, integrationId, campaignId, zoneId}) {
    return apiRequest("put", "v1/integration/mondiad/campaign/" + parseInt(campaignId) + "/zone", {
        integrationId,
        status,
        zoneId: parseInt(zoneId)
    });
}

function findMondiadZoneRatesAndStatus({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/mondiad/campaign/" + parseInt(campaignId) + "/zone", {}, {
        integrationId
    });
}

function updateMondiadZoneBid({bid, integrationId, campaignId, zoneId}) {
    return apiRequest("put", "v1/integration/mondiad/campaign/" + parseInt(campaignId) + "/zone/" + parseInt(zoneId) + "/bid", {
        integrationId,
        bid: parseFloat(bid)
    });
}

function findMondiadSiteRatesAndStatuses({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/mondiad/campaign/" + parseInt(campaignId) + "/site", {}, {integrationId});
}

function updateMondiadSiteStatus({campaignId, status, integrationId, siteId}) {
    return apiRequest("put", "v1/integration/mondiad/campaign/" + parseInt(campaignId) + "/site/" + siteId, {
        integrationId,
        status
    });
}

function updateMondiadSiteBid({bid, integrationId, campaignId, siteId}) {
    return apiRequest("put", "v1/integration/mondiad/campaign/" + parseInt(campaignId) + "/site/" + siteId + "/bid", {
        integrationId,
        bid: parseFloat(bid)
    });
}

function findMondiadCreatives({integrationId, ids}) {
    return apiRequest("get", "v1/integration/mondiad/creative", {}, {integrationId, ids});
}

function updateMondiadCreativeStatus({status, integrationId, creativeId}) {
    return apiRequest("put", "v1/integration/mondiad/creative/" + parseInt(creativeId), {
        integrationId,
        status
    });
}

function createSedoIntegration(data) {
    return apiRequest("post", "v1/integration/sedo", data);
}

function updateSedoIntegration(id, data) {
    return apiRequest("put", "v1/integration/sedo/" + parseInt(id), data);
}

function createSystem1Integration(data) {
    return apiRequest("post", "v1/integration/system1", data);
}

function updateSystem1Integration(id, data) {
    return apiRequest("put", "v1/integration/system1/" + parseInt(id), data);
}

function createBodisIntegration(data) {
    return apiRequest("post", "v1/integration/bodis", data);
}

function updateBodisIntegration(id, data) {
    return apiRequest("put", "v1/integration/bodis/" + parseInt(id), data);
}

function createDomainActiveIntegration(data) {
    return apiRequest("post", "v1/integration/domain-active", data);
}

function updateDomainActiveIntegration(id, data) {
    return apiRequest("put", "v1/integration/domain-active/" + parseInt(id), data);
}

function createAdsComIntegration(data) {
    return apiRequest("post", "v1/integration/ads-com", data);
}

function updateAdsComIntegration(id, data) {
    return apiRequest("put", "v1/integration/ads-com/" + parseInt(id), data);
}

function createTonicIntegration(data) {
    return apiRequest("post", "v1/integration/tonic", data);
}

function updateTonicIntegration(id, data) {
    return apiRequest("put", "v1/integration/tonic/" + parseInt(id), data);
}

function getGoogleLoginLink(renewIntegrationId) {
    return apiRequest("get", "v1/integration/google/login", {}, {renewIntegrationId: renewIntegrationId});
}

function googleCallback(code, state) {
    return apiRequest("post", "v1/integration/google/callback", {code, state});
}

function getGoogleAdAccounts(id) {
    return apiRequest("get", "v1/integration/google/ad-accounts/" + parseInt(id), {}, {});
}

function integrateGoogleAdAccount(
    {adAccountId, adAccountName, adAccountTimezone, adAccountCurrencyCode, integrationId}
) {
    return apiRequest("post", "v1/integration/google/ad-account", {
        adAccountId: adAccountId,
        adAccountName: adAccountName,
        adAccountTimezone: adAccountTimezone,
        adAccountCurrencyCode: adAccountCurrencyCode,
        integrationId: parseInt(integrationId)
    });
}

function integrateGoogleConversionAPI({integrationId, enableConversionAPI, events}) {
    return apiRequest("post", "v1/integration/google/conversion-api", {
        integrationId: parseInt(integrationId),
        enableConversionAPI, events
    });
}

function deleteIntegrationEvent({integrationId, eventId}) {
    return apiRequest("post", "v1/integration/" + integrationId + "/event/" + eventId, {});
}

function findGoogleAdsCampaigns({integrationId, campaignIds}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/google-campaign", {}, {campaignIds});
}

function updateGoogleAdsCampaignStatus({status, integrationId, campaignId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/google-campaign/" + parseInt(campaignId),
        {status}
    );
}

function findGoogleAdsAdGroups({integrationId, adGroupIds}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/google-ad-group", {}, {adGroupIds});
}

function updateGoogleAdsAdGroupStatus({status, integrationId, adGroupId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/google-ad-group/" + parseInt(adGroupId),
        {status}
    );
}

function findGoogleAdsAdGroupAds({integrationId, adIds}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/google-ad", {}, {adIds});
}

function updateGoogleAdsAdGroupAdStatus({status, integrationId, adGroupId, adId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/google-ad-group/" + parseInt(adGroupId) + "/google-ad/" + parseInt(adId),
        {status}
    );
}

function getMicrosoftLoginLink(renewIntegrationId) {
    return apiRequest("get", "v1/integration/microsoft/login", {}, {renewIntegrationId: renewIntegrationId});
}

function microsoftCallback(code, state) {
    return apiRequest("post", "v1/integration/microsoft/callback", {code, state});
}

function getMicrosoftAdAccounts(id) {
    return apiRequest("get", "v1/integration/microsoft/ad-account/" + parseInt(id), {}, {});
}

function integrateMicrosoftAdAccount({accountId, integrationId, customerId}) {
    return apiRequest("post", "v1/integration/microsoft/ad-account", {
        accountId: accountId,
        customerId: customerId,
        integrationId: parseInt(integrationId)
    });
}

function integrateMicrosoftConversionAPI({integrationId, enableConversionAPI, events}) {
    return apiRequest("post", "v1/integration/microsoft/conversion-api", {
        integrationId: parseInt(integrationId),
        enableConversionAPI, events
    });
}

function findMicrosoftAdsCampaigns({integrationId, campaignIds}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/microsoft/campaign", {}, {campaignIds});
}

function updateMicrosoftAdsCampaignStatus({status, integrationId, campaignId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/microsoft/campaign/" + parseInt(campaignId),
        {status}
    );
}

function findMicrosoftAdsAdGroups({integrationId, adGroupIds, campaignId}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/microsoft/campaign/" + parseInt(campaignId) + "/ad-group", {}, {adGroupIds});
}

function updateMicrosoftAdsAdGroupStatus({status, integrationId, campaignId, adGroupId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/microsoft/campaign/" + parseInt(campaignId) + "/ad-group/" + parseInt(adGroupId),
        {status}
    );
}

function findMicrosoftAdsAdGroupAds({integrationId, adGroupId, adIds}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/microsoft/ad-group/" + parseInt(adGroupId) + "/ad", {}, {adIds});
}

function updateMicrosoftAdsAdStatus({status, integrationId, adId, adGroupId, adType}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/microsoft/ad-group/" + parseInt(adGroupId) + "/ad/" + parseInt(adId),
        {status, adType}
    );
}

function findFacebookMetricsAndOtherFields({integrationId, level, ids, dateStart, dateStop, breakdownByDay}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/facebook/metrics-and-fields", {}, {
        level,
        ids,
        dateStart,
        dateStop,
        breakdownByDay
    });
}

function getTaboolaAdAccounts({apiClient, apiSecret, integrationId}) {
    return apiRequest("post", "v1/integration/taboola/ad-account", {apiClient, apiSecret, integrationId});
}

function createTaboolaIntegration(data) {
    return apiRequest("post", "v1/integration/taboola", data);
}

function updateTaboolaIntegration(id, data) {
    return apiRequest("put", "v1/integration/" + parseInt(id) + "/taboola", data);
}

function findTaboolaCampaigns({integrationId}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/taboola/campaign", {}, {});
}

function findTaboolaCampaignAds({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/taboola/campaign/" + parseInt(campaignId) + "/ad", {}, {});
}

function findTaboolaCampaign({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/taboola/campaign/" + parseInt(campaignId), {}, {});
}

function updateTaboolaCampaignStatus({status, integrationId, campaignId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/taboola/campaign/" + parseInt(campaignId),
        {status}
    );
}

function updateTaboolaCampaignAdStatus({status, integrationId, campaignId, adId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/taboola/campaign/" + parseInt(campaignId) + "/ad/" + parseInt(adId),
        {status}
    );
}

function updateTaboolaCampaignSiteStatus({status, integrationId, campaignId, site}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/taboola/campaign/" + parseInt(campaignId) + "/site",
        {status, site}
    );
}

function updateTaboolaCampaignSiteBid({integrationId, campaignId, site, bid}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/taboola/campaign/" + parseInt(campaignId) + "/site-bid",
        {bid: parseFloat(bid), site}
    );
}

function createMgidIntegration(data) {
    return apiRequest("post", "v1/integration/mgid", data);
}

function updateMgidIntegration(id, data) {
    return apiRequest("put", "v1/integration/" + parseInt(id) + "/mgid", data);
}

function findMgidCampaigns({integrationId, campaignIds}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/mgid/campaign", {}, {campaignIds});
}

function updateMgidCampaignStatus({status, integrationId, campaignId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/mgid/campaign/" + parseInt(campaignId) + "/status",
        {status}
    );
}

function findMgidAds({integrationId, adIds}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/mgid/ad", {}, {adIds});
}

function updateMgidAdStatus({status, integrationId, adId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/mgid/ad/" + parseInt(adId) + "/status",
        {status}
    );
}

function updateMgidAdBid({bid, integrationId, adId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/mgid/ad/" + parseInt(adId) + "/bid", {bid: bid * 100}
    );
}

function findMgidWidgets({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/mgid/campaign/" + parseInt(campaignId) + "/listed-widgets", {}, {});
}

function updateMgidWidgetStatus({status, integrationId, campaignId, widgetId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/mgid/campaign/" + parseInt(campaignId) + "/widget/status",
        {status, widgetId}
    );
}

function createOutbrainIntegration(data) {
    return apiRequest("post", "v1/integration/outbrain", data);
}

function updateOutbrainIntegration(id, data) {
    return apiRequest("put", "v1/integration/" + parseInt(id) + "/outbrain", data);
}

function getOutbrainAdAccounts({apiToken, integrationId}) {
    return apiRequest("post", "v1/integration/outbrain/ad-account", {apiToken, integrationId});
}

function findOutbrainCampaigns({integrationId, campaignIds}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/outbrain/campaign", {}, {campaignIds});
}

function updateOutbrainCampaignStatus({status, integrationId, campaignId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/outbrain/campaign/" + campaignId + "/status",
        {status}
    );
}

function updateOutbrainCampaignBid({bid, integrationId, campaignId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/outbrain/campaign/" + campaignId + "/bid",
        {bid: parseFloat(bid)}
    );
}

function updateOutbrainCampaignBudget({amount, integrationId, budgetId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/outbrain/budget/" + budgetId,
        {amount: parseFloat(amount)}
    );
}

function updateTaboolaCampaignDailyBudget({amount, integrationId, campaignId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/taboola/campaign/" + campaignId + "/budget",
        {amount: parseFloat(amount)}
    );
}

function findOutbrainAds({integrationId, campaignId}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/outbrain/campaign/" + campaignId + "/ad", {}, {});
}

function updateOutbrainAdStatus({status, integrationId, adId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/outbrain/ad/" + adId + "/status",
        {status}
    );
}

function updateOutbrainPublisherStatus({status, integrationId, campaignId, publisherId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/outbrain/campaign/" + campaignId + "/publisher/" + publisherId + "/status",
        {status}
    );
}

function updateOutbrainSectionStatus({status, integrationId, campaignId, sectionId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/outbrain/campaign/" + campaignId + "/section/" + sectionId + "/status",
        {status}
    );
}

function updateOutbrainSectionBid({bid, integrationId, sectionId, campaignId}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/outbrain/campaign/" + campaignId + "/section/" + sectionId + "/bid",
        {bid: parseFloat(bid)}
    );
}

function getTikTokLoginLink(renewIntegrationId) {
    return apiRequest("get", "v1/integration/tiktok/login", {}, {renewIntegrationId: renewIntegrationId});
}

function tikTokCallback(code, state) {
    return apiRequest("post", "v1/integration/tiktok/callback", {code, state});
}

function getTikTokAdAccounts(id) {
    return apiRequest("get", "v1/integration/tiktok/ad-accounts/" + parseInt(id), {}, {});
}

function integrateTikTokAdAccount(adAccountId, adAccountName, integrationId) {
    return apiRequest("post", "v1/integration/tiktok/ad-account", {
        adAccountId: adAccountId,
        adAccountName: adAccountName,
        integrationId: parseInt(integrationId)
    });
}

function integrateTikTokConversionAPI({integrationId, enableConversionAPI, events, pixels}) {
    return apiRequest("post", "v1/integration/tiktok/conversion-api", {
        integrationId: parseInt(integrationId),
        enableConversionAPI, events, pixels
    });
}

function findTikTokMetricsAndOtherFields({integrationId, level, ids, dateStart, dateStop, breakdownByDay}) {
    return apiRequest("get", "v1/integration/" + parseInt(integrationId) + "/tiktok/metrics-and-fields", {}, {
        level,
        ids,
        dateStart,
        dateStop,
        breakdownByDay
    });
}

function updateTikTokStatus({status, level, integrationId, id}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/tiktok/" + id + "/status",
        {status, level}
    );
}

function updateTikTokDailyBudget({dailyBudget, level, integrationId, id}) {
    return apiRequest(
        "patch",
        "v1/integration/" + parseInt(integrationId) + "/tiktok/" + id + "/daily-budget",
        {dailyBudget: parseFloat(dailyBudget), level}
    );
}

function createRsocSystem1Integration(data) {
    return apiRequest("post", "v1/integration/rsoc/system1", data);
}

function updateRsocSystem1Integration(id, data) {
    return apiRequest("put", "v1/integration/rsoc/system1/" + parseInt(id), data);
}

function createRsocInuvoIntegration(data) {
    return apiRequest("post", "v1/integration/rsoc/inuvo", data);
}

function updateRsocInuvoIntegration(id, data) {
    return apiRequest("put", "v1/integration/rsoc/inuvo/" + parseInt(id), data);
}

function createServerAwsIntegration(data) {
    return apiRequest("post", "v1/integration/server/aws", data);
}

function updateServerAwsIntegration(id, data) {
    return apiRequest("put", "v1/integration/server/aws/" + parseInt(id), data);
}

function createDomainCloudflareIntegration(data) {
    return apiRequest("post", "v1/integration/server/cloudflare", data);
}

function updateDomainCloudflareIntegration(id, data) {
    return apiRequest("put", "v1/integration/server/cloudflare/" + parseInt(id), data);
}

function createDomainNamesiloIntegration(data) {
    return apiRequest("post", "v1/integration/server/namesilo", data);
}

function updateDomainNamesiloIntegration(id, data) {
    return apiRequest("put", "v1/integration/server/namesilo/" + parseInt(id), data);
}
