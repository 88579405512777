import React, {useEffect, useState} from "react";
import Spinner from "../../spinner/Spinner";
import {Controller, useForm} from "react-hook-form";
import {Info} from "@mui/icons-material";
import {
    Button,
    TextField,
    RadioGroup,
    Box,
    FormControlLabel,
    Radio,
    Tooltip
} from "@mui/material";
import {settingsServices} from "../../../service/settingsService";
import CustomSnackbar from "../../alert/CustomSnackbar";
import {connect} from "react-redux";
import {alertActions} from "../../../store/actions/alertActions";
import {
    SettingsGeneralButtonBox,
    SettingsGeneralHeader,
    SettingsGeneralPaper,
    SettingsGeneralRoot
} from "../ProfileSettings";

function BotRedirect(props) {

    const [loading, setLoading] = useState(false);
    const [companySettings, setCompanySettings] = useState(null);

    const {
        handleSubmit,
        register,
        control,
        watch,
        setValue,
        formState: {errors}
    } = useForm({defaultValues: {botRedirectValue: "", botRedirectUrl: ""}});

    const onSubmit = async (data) => {

        if (data?.botRedirectValue !== "redirect") {
            data.botRedirectUrl = "";
            setValue("botRedirectUrl", "");
        }

        setLoading(true);
        props?.clear();
        let formData = {
            botRedirectValue: data?.botRedirectValue,
            botRedirectUrl: data?.botRedirectUrl,
        };
        await settingsServices.updateBotDetection(formData).then((response) => {
            if (response !== null && response !== undefined) {
                props?.successMsg("Bot redirect settings has been successfully updated.");
            }
        }).catch((error) => {
            if (error?.response?.status === 403) {
                props?.errorMsg("Access forbidden. You have no rights to update bot redirect settings.");
            } else if (error?.response?.data?.error) {
                props?.errorMsg(error.response.data.error);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        async function fetchCompanySettings() {
            props?.clear();
            setLoading(true);
            await settingsServices.fetchCompanySettings().then((response) => {
                if (response !== null && response !== undefined) {
                    setCompanySettings(response);
                    setValue("botRedirectValue", response?.botRedirectValue);
                    setValue("botRedirectUrl", response?.botRedirectUrl);
                }
            }).catch((error) => {
                if (error?.response?.status === 403) {
                    props?.errorMsg("Access forbidden. You have no rights to fetch bot redirect settings.");
                } else if (error?.response?.data?.error) {
                    props?.errorMsg(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (companySettings === null) {
            fetchCompanySettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companySettings, setValue]);

    const renderRedirectURL = () => {
        if (watch("botRedirectValue") === "redirect") {
            return (
                <TextField
                    error={!!errors?.botRedirectUrl}
                    variant="outlined"
                    margin="dense"
                    {...register("botRedirectUrl", {
                        required: true,
                        pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z\d]+([-.]{1}[a-z\d]+)*\.[a-z]{2,10}(:\d{1,5})?(\/.*)?$/,
                        maxLength: 2048
                    })}
                    size="small"
                    name="botRedirectUrl"
                    label=""
                    placeholder="e.g. https://google.com"
                    type="text"
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        }
    };

    return (
        <SettingsGeneralRoot>
            <SettingsGeneralHeader variant="h3">Bot Detection</SettingsGeneralHeader>
            <SettingsGeneralPaper elevation={0}>
                {loading && <Spinner size={32} overComponentBox={true}/>}

                {props?.state?.alert?.errorMsg !== "" &&
                    <CustomSnackbar message={props?.state?.alert?.successMsg} severity="error" timeout={3500}/>
                }
                {props?.state?.alert?.successMsg !== "" &&
                    <CustomSnackbar message={props?.state?.alert?.successMsg} severity="success" timeout={3500}/>
                }

                <h3>Manage Bot Traffic</h3>

                <Box sx={{color: "text.disabledLight", pb: 1}}>
                    Bots have a malicious effect on the performance of your campaigns. That is why we have built
                    bot detect system which automatically detects bots by User Agent.
                </Box>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                    <Box display="flex" mt={2} flexDirection="column">
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <RadioGroup
                                    row
                                    aria-label="Mark as Bot"
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                >
                                    <Box display="flex" flexDirection="column" flexWrap="wrap">
                                        <Box display="flex" flexDirection="row" mr={3}>
                                            <Box>
                                                <FormControlLabel
                                                    value=""
                                                    control={<Radio color="primary"/>}
                                                    label="Mark as Bot"
                                                />
                                            </Box>
                                            <Box sx={{
                                                paddingTop: "11px",
                                                marginLeft: "-8px",
                                                cursor: "pointer"
                                            }}>
                                                <Tooltip disableFocusListener
                                                         title="Treated as normal traffic. All the data on bot clicks and
                                                     visits will be recorded in the statistics. When creating a
                                                     campaign then you can create a rule-based path to your bots."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" mr={3}>
                                            <Box>
                                                <FormControlLabel value="block" control={<Radio color="primary"/>}
                                                                  label="Block (403 Forbidden)"/>
                                            </Box>
                                            <Box sx={{
                                                paddingTop: "11px",
                                                marginLeft: "-8px",
                                                cursor: "pointer"
                                            }}>
                                                <Tooltip disableFocusListener
                                                         title="Connection will be closed and bot will be redirected to
                                                     the empty page with error 403 Access Forbidden. The bot traffic
                                                     will not be displayed in the stats."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" mr={3}>
                                            <Box>
                                                <FormControlLabel
                                                    value="404"
                                                    control={<Radio color="primary"/>}
                                                    label="Block (404 Not Found)"
                                                />
                                            </Box>
                                            <Box sx={{
                                                paddingTop: "11px",
                                                marginLeft: "-8px",
                                                cursor: "pointer"
                                            }}>
                                                <Tooltip disableFocusListener
                                                         title="Connection will be closed and bot will be redirected to
                                                     the empty page with error 404 Not Found. The bot traffic
                                                     will not be displayed in the stats."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" mr={3}>
                                            <Box>
                                                <FormControlLabel
                                                    value="redirect"
                                                    control={<Radio color="primary"/>}
                                                    label="Redirect to URL"
                                                />
                                            </Box>
                                            <Box sx={{
                                                paddingTop: "11px",
                                                marginLeft: "-8px",
                                                cursor: "pointer"
                                            }}>
                                                <Tooltip disableFocusListener
                                                         title="Bot traffic will be redirected to the URL. The bot traffic
                                                      will not be displayed in the stats."
                                                >
                                                    <Info color={"primary"} fontSize={"small"}/>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Box>
                                </RadioGroup>
                            )}
                            control={control}
                            name="botRedirectValue"
                            defaultValue=""
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="space-between">
                        <Box width="100%">
                            {renderRedirectURL()}
                        </Box>
                    </Box>
                    <SettingsGeneralButtonBox>
                        <Button type="submit" onClick={handleSubmit(onSubmit)} color="primary" variant="contained"
                                disabled={loading}>
                            Update
                        </Button>
                    </SettingsGeneralButtonBox>
                </form>
            </SettingsGeneralPaper>
        </SettingsGeneralRoot>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            alert: state.alert,
        }
    };
};

const mapDispatchToProps = {
    errorMsg: alertActions.error,
    successMsg: alertActions.success,
    clear: alertActions.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(BotRedirect);