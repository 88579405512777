import {Box, CssBaseline, Divider, List, ListItemButton, ListItemText} from "@mui/material";
import Sidebar from "../layout/Sidebar";
import Topbar from "../layout/Topbar";
import React from "react";
import {CustomMainRootBox} from "../dashboard/Dashboard";
import {NavLink} from "react-router-dom";
import DomainsTable from "./DomainsTable";
import {styled} from "@mui/system";
import BucketsTable from "./buckets/BucketsTable";
import DeploymentsTable from "./deployments/DeploymentsTable";

const MainContainer = styled(Box)(({theme}) => ({
    height: "var(--app-height)",
    width: "100%",
    overflow: "auto",
    borderLeft: "1px solid " + theme.palette.background.tableBorder,
    paddingTop: "49px",
    [theme.breakpoints.down(600)]: {
        paddingTop: "49px",
    }
}));

const NavItemButton = ({to, selected, label}) => (
    <ListItemButton
        component={NavLink}
        to={to}
        selected={selected}
        sx={{
            minWidth: "100px",
            height: "53px",
            paddingLeft: 1,
            paddingRight: 1,
            textAlign: "center",
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
            borderBottomColor: "background.darkBlue",
            borderRightWidth: "1px",
            borderRightStyle: "solid",
            borderRightColor: "background.tableBorder",
            "&.Mui-selected": {
                backgroundColor: "background.darkBlue",
                borderBottomWidth: "2px",
                borderBottomStyle: "solid",
                borderBottomColor: "primary.main",
                ":hover": {
                    backgroundColor: "background.darkBlue"
                }
            },
            ":hover": {
                backgroundColor: "background.darkBlue"
            }
        }}
    >
        <ListItemText primary={label} sx={{fontSize: 14, paddingLeft: 2, paddingRight: 2}}/>
    </ListItemButton>
);

function Websites({location, ...props}) {
    return (
        <CustomMainRootBox>
            <CssBaseline/>
            <Topbar/>
            <Sidebar {...props}/>
            <MainContainer>
                <Box sx={{overflow: "auto", minHeight: 53}}>
                    <List sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 0,
                        margin: 0,
                        flexWrap: "noWrap",
                        backgroundColor: "background.darkBlue"
                    }}>
                        <NavItemButton
                            to="/websites/domains"
                            selected={location?.pathname === "/websites/domains"}
                            label="Domains"
                        />
                        <NavItemButton
                            to="/websites/s3-buckets"
                            selected={location?.pathname === "/websites/s3-buckets"}
                            label="S3 Buckets"
                        />
                        <NavItemButton
                            to="/websites/deployments"
                            selected={location?.pathname === "/websites/deployments"}
                            label="Deployments"
                        />
                    </List>
                </Box>
                <Divider/>
                {location?.pathname === "/websites/domains" && <DomainsTable/>}
                {location?.pathname === "/websites/s3-buckets" && <BucketsTable/>}
                {location?.pathname === "/websites/deployments" && <DeploymentsTable/>}
            </MainContainer>
        </CustomMainRootBox>
    );
}

export default Websites;