import {currencies} from "./DataList";

export const addCurrency = (currency, number, decimalNumber) => {
    return currency + " " + parseFloat(number).toFixed(decimalNumber);
};

export const addPercent = (number, decimalNumber) => {
    return parseFloat(number).toFixed(decimalNumber) + "%";
};

export function getCurrencySymbol() {
    let userSettings = JSON.parse(localStorage.getItem("user-settings"));
    let currency = "€";
    currencies.forEach((item) => {
        if (item?.code === userSettings?.currencyCode) {
            currency = item.symbol;
        }
    });
    return currency;
}

export function findCurrencySymbol(currencyCode) {
    let currency = "€";
    currencies.forEach((item) => {
        if (item?.code === currencyCode) {
            currency = item.symbol;
        }
    });
    return currency;
}