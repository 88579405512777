import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import {tagServices} from "../../service/tagService";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Chip, TextField} from "@mui/material";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

function TagFilter({inputName, inputValue, updateOnChange}) {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    const {control, getValues, setValue} = useForm();

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await tagServices.read({name: search});
                setOptions(
                    response.tags.map(item => {
                        return item?.name;
                    })
                );
            } catch (e) {
                console.log("Error: ", e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData();
        }

    }, [search, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    useEffect(() => {
        if (inputValue?.length > 0) {
            setValue("tags", inputValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    multiple
                    disableCloseOnSelect={true}
                    id="tags-multi-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                        updateOnChange(getValues("tags"));
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => option}
                    options={options}
                    loading={loading}
                    value={search}
                    renderOption={(props, option, {selected, index}) => (
                        <li {...props} key={index}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{marginRight: "8px", padding: "2px"}}
                                checked={selected}
                                color="primary"
                            />
                            {option}
                        </li>
                    )}
                    renderTags={(value, getTagProps) => {
                        const numTags = value.length;
                        const limitTags = 1;
                        return (
                            <Box sx={{
                                textOverflow: 'ellipsis',
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                marginTop: "-4.5px"
                            }}>
                                {value?.slice(0, limitTags)?.map((option, index) => (
                                    <Chip
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            maxWidth: "120px"
                                        }}
                                        variant="outlined" color="primary" size="small"
                                        {...getTagProps({index})}
                                        key={index}
                                        label={option}
                                    />
                                ))}

                                {numTags > limitTags && ` +${numTags - limitTags}`}
                            </Box>
                        );
                    }}
                    renderInput={params => {
                        return (
                            <Box display="flex" flexDirection="row" flexWrap="noWrap">
                                <Box width="270px">
                                    <TextField
                                        {...params}
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                                "& input": {
                                                    backgroundColor: "background.defaultBlue",
                                                },
                                                '&.MuiOutlinedInput-root': {
                                                    backgroundColor: "background.defaultBlue",
                                                    height: 34.75,
                                                    borderWidth: "1px",
                                                    borderColor: "border.button",
                                                    borderStyle: "solid"
                                                },
                                                '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
                                                    marginTop: "-1.75px",
                                                    padding: "0px 3px",
                                                    minWidth: "25px",
                                                    "&::placeholder": {
                                                        fontSize: "13px",
                                                        fontWeight: 600,
                                                        color: "common.white"
                                                    }
                                                }
                                            }
                                        }}
                                        variant="outlined"
                                        placeholder={getValues("tags")?.length === 0 ? "Select tags" : ""}
                                        onChange={e => handleSearch(e.target.value)}
                                    />
                                </Box>
                            </Box>
                        )
                    }}
                    onChange={(e, data, reason) => {
                        onChange(data);
                        if (reason === "clear") {
                            onChange(data);
                            updateOnChange(data);
                        }
                        if (reason === "remove-option") {
                            onChange(data);
                            updateOnChange(data);
                        }
                    }}
                    {...props}
                />
            )}
            defaultValue={[]}
            name={inputName}
            control={control}
            onChange={([, data]) => data}
        />
    );
}

TagFilter.propTypes = {
    inputName: PropTypes.string,
    updateOnChange: PropTypes.func
};

TagFilter.defaultProps = {
    inputName: "tags",
    updateOnChange: () => null,
};

export default TagFilter;