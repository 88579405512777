import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {
    Dialog,
    useMediaQuery,
    useTheme
} from "@mui/material";
import PropTypes from "prop-types";
import {AffiliateNetworkTemplates} from "./AffiliateNetworkTemplates";
import {AffiliateNetworkForm} from "./AffiliateNetworkForm";
import {GetPostbackLink} from "../../helpers/common";
import {postbackURL} from "../../helpers/config";
import {affNetworkServices} from "../../service/affiliateNetworkService";

function AffiliateNetworkPopUp({onClose, open}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState("");
    const [step, setStep] = useState("templates");
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [postbackLink, setPostbackLink] = useState(postbackURL);

    const {register, handleSubmit, setValue, watch, control, getValues, formState: {errors}} = useForm();

    const handleSelectedTemplate = (data) => {
        setSelectedTemplate(data);
        setStep("custom");
    };

    useEffect(() => {
        if (selectedTemplate !== undefined && selectedTemplate !== null) {
            setValue("name", selectedTemplate?.name);
            setValue("allowDoubleConversions", selectedTemplate?.allowDoubleConversions ? selectedTemplate?.allowDoubleConversions : false);
            setValue("usePostbackStatuses", selectedTemplate?.usePostbackStatuses);
            setValue("postbackOnlyWhiteIps", selectedTemplate?.postbackOnlyWhiteIps);

            setTimeout(() => {
                setValue("postbackStatusApproved", selectedTemplate?.postbackStatusApproved);
                setValue("postbackStatusPending", selectedTemplate?.postbackStatusPending);
                setValue("postbackStatusDeclined", selectedTemplate?.postbackStatusDeclined);
                setValue("postbackStatusRefund", selectedTemplate?.postbackStatusRefund);
                setValue("postbackWhiteIps", selectedTemplate?.postbackWhiteIps);
                setPostbackLink(
                    GetPostbackLink(
                        selectedTemplate?.clickIdPlaceholder,
                        selectedTemplate?.payoutPlaceholder,
                        selectedTemplate?.transactionIdPlaceholder,
                        selectedTemplate?.statusPlaceholder
                    )
                );
            });
        }
    }, [setValue, selectedTemplate, open]);

    const onSubmit = async (formData) => {
        let submitData = {
            workspaceId: formData?.workspace?.value,
            name: formData.name,
            sharedAffiliateNetworkId: selectedTemplate?.id,
            postbackOnlyWhiteIps: formData.postbackOnlyWhiteIps,
            postbackWhiteIps: formData.postbackWhiteIps ? formData.postbackWhiteIps : "",
            allowDoubleConversions: formData.allowDoubleConversions,
            usePostbackStatuses: formData.usePostbackStatuses,
            postbackStatusApproved: formData.postbackStatusApproved ? formData.postbackStatusApproved : "",
            postbackStatusPending: formData.postbackStatusPending ? formData.postbackStatusPending : "",
            postbackStatusDeclined: formData.postbackStatusDeclined ? formData.postbackStatusDeclined : "",
            postbackStatusRefund: formData.postbackStatusRefund ? formData.postbackStatusRefund : "",
            usePostbackProtection: formData.usePostbackProtection,
            postbackProtectionToken: formData.postbackProtectionToken,
            tags: formData?.tags ? formData?.tags : []
        };

        setLoading(true);
        await affNetworkServices.create(submitData).then(() => {
            handleClose();
        }).catch((e) => {
            if (e?.response?.data?.error !== undefined) {
                setRequestError(e.response.data.error);
            }
            setLoading(false);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setRequestError("");
        setStep("templates");
        setPostbackLink(postbackURL);
        setSelectedTemplate(null);
        setLoading(false);
        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"md"}
                aria-labelledby="affiliate-network-form"
        >
            {step === "templates" && <AffiliateNetworkTemplates
                handleClose={handleClose}
                handleSelectedTemplate={handleSelectedTemplate}
            />}

            {step === "custom" && <AffiliateNetworkForm
                title="New affiliate network"
                handleClose={handleClose}
                requestError={requestError}
                onSubmit={onSubmit}
                control={control}
                register={register}
                errors={errors}
                handleSubmit={handleSubmit}
                loading={loading}
                postbackLink={postbackLink}
                watch={watch}
                formData={selectedTemplate}
                getValues={getValues}
                setValue={setValue}
            />}
        </Dialog>
    );
}

AffiliateNetworkPopUp.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default AffiliateNetworkPopUp;