import React, {useEffect, useState} from 'react'
import {Box, Paper} from "@mui/material";
import {Line} from "react-chartjs-2";
import {CategoryScale, Chart, LinearScale, PointElement, LineElement, Legend, Tooltip} from "chart.js";

Chart.register(LinearScale, CategoryScale, PointElement, LineElement, Legend, Tooltip);

const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    legend: {
        display: true,
        labels: {
            fontColor: '#fff'
        }
    },
    scales: {
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            gridLines: {
                color: '#e6e6e6',
                drawBorder: false,
            },
        },
    },
};

function LineChart({data}) {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    })

    useEffect(() => {
        if (data !== null) {
            setChartData(
                {
                    labels: data?.labels ? data?.labels : [],
                    datasets: [
                        {
                            label: 'Visits',
                            data: data?.visits ? data?.visits : [],
                            fill: false,
                            backgroundColor: 'rgb(255, 99, 132)',
                            borderColor: 'rgba(255, 99, 132, 0.8)',
                            tension: 0.3
                        },
                        {
                            label: 'Clicks',
                            data: data?.clicks ? data?.clicks : [],
                            fill: false,
                            backgroundColor: 'rgb(235,140,24)',
                            borderColor: 'rgba(235,132,21, 0.8)',
                            tension: 0.3
                        },
                        {
                            label: 'Conversions',
                            data: data?.conversions ? data?.conversions : [],
                            fill: false,
                            backgroundColor: 'rgb(228,235,31)',
                            borderColor: 'rgba(234,235,39, 0.8)',
                            tension: 0.3
                        },
                        {
                            label: 'Revenue',
                            data: data?.revenue ? data?.revenue : [],
                            fill: false,
                            backgroundColor: 'rgb(30,49,235)',
                            borderColor: 'rgba(51,61,235, 0.8)',
                            tension: 0.3
                        },
                        {
                            label: 'Cost',
                            data: data?.cost ? data?.cost : [],
                            fill: false,
                            backgroundColor: 'rgb(255,39,44)',
                            borderColor: 'rgba(235,44,48, 0.8)',
                            tension: 0.3
                        },
                        {
                            label: 'Profit',
                            data: data?.profit ? data?.profit : [],
                            fill: false,
                            backgroundColor: 'rgb(68, 199, 103)',
                            borderColor: 'rgba(68, 199, 103, 0.8)',
                            tension: 0.3
                        },
                    ],
                }
            );
        }
    }, [data]);

    return (
        <Box sx={{maxHeight: "450px", width: "100%", height: '100%'}}>
            <Paper sx={{padding: 2, backgroundColor: "background.darkBlue"}} elevation={3}>
                <Line data={chartData} options={options} height={400}/>
            </Paper>
        </Box>
    );
}

export default LineChart;