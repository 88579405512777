import {metricFilterConstants} from "../constants/metricFilterConstants";
import {GetLocalStorageCache} from "../../components/cache/Cache";

export const metricFilterActions = {
    addMetric,
    removeMetric,
    setMetricFiltersFromCache
};

export function setMetricFiltersFromCache() {
    return dispatch => {
        let metricFilters = GetLocalStorageCache("metricFilters");
        if (metricFilters !== null && metricFilters !== undefined) {
            dispatch({type: metricFilterConstants.METRIC_FILTER_SET, metricFilters});
        }
    };
}

function addMetric(metric) {
    return (dispatch) => {
        dispatch({type: metricFilterConstants.METRIC_FILTER_ADD, metric});
    };
}

function removeMetric(key) {
    return (dispatch) => {
        dispatch({type: metricFilterConstants.METRIC_FILTER_REMOVE, key});
    };
}