import {groupBy1Constants} from "../constants/groupBy1Constants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        totalRecords: 0,
        total: {}
    },
    integration: null,
    trafficSource: null,
    errorMsg: "",
    isLoading: false,
    name: "",
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false,
    selectedUUIDs: new Set(),
    selectedRows: new Set(),
    groupBy: {
        "label": "Country",
        "groupBy": "country",
        "sortBy": "country"
    }
};

const groupBy1Reducer = (state = initialState, action) => {
    switch (action.type) {
        case groupBy1Constants.GROUP_BY_1_LOADING_SPINNER:
            return {
                ...state,
                isLoading: true,
            };
        case groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER:
            return {
                ...state,
                isLoading: false,
            };
        case groupBy1Constants.GROUP_BY_1_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    totalRecords: action?.result?.totalRecords,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false,
            };
        case groupBy1Constants.GROUP_BY_1_GET_DATA:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    totalRecords: action?.result?.totalRecords,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false,
                rerender: false,
            };
        case groupBy1Constants.GROUP_BY_1_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load data. Please try again.',
                rerender: false,
                isLoading: false,
            };
        case groupBy1Constants.GROUP_BY_1_SET_TRAFFIC_SOURCE_DATA:
            return {
                ...state,
                trafficSource: action.trafficSource,
            };
        case groupBy1Constants.GROUP_BY_1_SET_INTEGRATION_DATA:
            return {
                ...state,
                integration: action.integration,
            };
        case groupBy1Constants.GROUP_BY_1_SET_SELECTED_NAME:
            return {
                ...state,
                name: action.name
            };
        case groupBy1Constants.GROUP_BY_1_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case groupBy1Constants.GROUP_BY_1_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case groupBy1Constants.GROUP_BY_1_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case groupBy1Constants.GROUP_BY_1_RERENDER:
            return {
                ...state,
                rerender: true
            };
        case groupBy1Constants.GROUP_BY_1_SET_SELECTED_ROW:
            return {
                ...state,
                selectedUUIDs: action?.payload?.selectedUUIDs,
                selectedRows: action?.payload?.selectedRows
            };
        case groupBy1Constants.GROUP_BY_1_UPDATE_GROUP_BY:
            return {
                ...state,
                groupBy: action.groupBy
            };
        case groupBy1Constants.GROUP_BY_1_RESET:
            return initialState;
        default:
            return state;
    }
};

export default groupBy1Reducer;