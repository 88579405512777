import {history} from '../../helpers/history';
import {trackingDomainConstants} from "../constants/trackingDomainConstants";
import {trackingDomainServices} from "../../service/trackingDomainService";

export const trackingDomainActions = {
    fetch,
    remove,
    clearErrors,
    addAndVerify,
    setStep,
    setTrackingDomain,
    setNewDefaultTrackingDomain
};

function fetch(name) {
    return dispatch => {
        dispatch({type: trackingDomainConstants.TRACKING_DOMAIN_SET_IS_LOADING});
        trackingDomainServices.read(name)
            .then((result) => {
                dispatch({type: trackingDomainConstants.LOADING_TRACKING_DOMAINS, result});
                history.push('/settings/domains');
            }).catch((error) => {
                dispatch({type: trackingDomainConstants.LOADING_TRACKING_DOMAINS_ERROR, error});
            }
        );
    };
}

function clearErrors() {
    return dispatch => {
        dispatch({type: trackingDomainConstants.TRACKING_DOMAIN_CLEAR_ERRORS});
    };
}

function setStep(activeStep) {
    return dispatch => {
        dispatch({type: trackingDomainConstants.SET_ACTIVE_STEP, activeStep});
    };
}

function setTrackingDomain(trackingDomain) {
    return dispatch => {
        dispatch({type: trackingDomainConstants.SET_TRACKING_DOMAIN, trackingDomain});
    };
}

function addAndVerify(domainName) {
    return (dispatch, getState) => {
        dispatch({type: trackingDomainConstants.TRACKING_DOMAIN_SET_IS_LOADING});
        trackingDomainServices.addAndVerify(domainName).then((domain) => {
            let domains = getState().trackingDomain.trackingDomains;
            domains.push(domain);
            dispatch({type: trackingDomainConstants.ADD_TRACKING_DOMAIN, domains});
            history.push('/settings/domains');
        }).catch((error) => {
            if (error?.response?.status === 403) {
                dispatch({
                    type: trackingDomainConstants.ADD_TRACKING_DOMAIN_ERROR,
                    error: "Access forbidden."
                });
            } else {
                dispatch({
                    type: trackingDomainConstants.ADD_TRACKING_DOMAIN_ERROR,
                    error: error?.response?.data?.error
                });
            }
        }).finally(() => {
            setTimeout(() => {
                dispatch({type: trackingDomainConstants.TRACKING_DOMAIN_CLEAR_ERRORS});
            }, 3500);
        });
    };
}

function remove(id) {
    return (dispatch, getState) => {
        dispatch({type: trackingDomainConstants.TRACKING_DOMAIN_SET_IS_LOADING});
        let domains = getState().trackingDomain.trackingDomains.filter((domain) => domain.id !== id);
        trackingDomainServices.remove(id).then(() => {
            dispatch({type: trackingDomainConstants.DELETE_TRACKING_DOMAIN, domains});
            history.push('/settings/domains');
        }).catch((error) => {
            if (error?.response?.status === 403) {
                dispatch({
                    type: trackingDomainConstants.DELETE_TRACKING_DOMAIN_ERROR,
                    error: "Access forbidden."
                });
            } else {
                dispatch({
                    type: trackingDomainConstants.DELETE_TRACKING_DOMAIN_ERROR,
                    error: "Unable to delete this tracking domain. Please try again."
                });
            }
        }).finally(() => {
            setTimeout(() => {
                dispatch({type: trackingDomainConstants.TRACKING_DOMAIN_CLEAR_ERRORS});
            }, 3500);
        });
    };
}

function setNewDefaultTrackingDomain(newDomain) {
    return dispatch => {
        dispatch({type: trackingDomainConstants.SET_NEW_DEFAULT_DOMAIN, newDomain});
    };
}
