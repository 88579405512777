import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    useTheme
} from "@mui/material";
import {connect} from "react-redux";
import {personActions} from "../../../store/actions/personAction";
import {referralActions} from "../../../store/actions/referralAction";
import Spinner from "../../spinner/Spinner";
import CopyContentToClipboard from "../../utils/CopyContentToClipboard";
import moment from "moment-timezone";
import Withdraw from "./Withdraw";
import CustomSnackbar from "../../alert/CustomSnackbar";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        width: "100%",
        flex: 1,
        padding: "0px 16px 8px 16px",
        [theme.breakpoints.down(870)]: {
            flex: "none",
            marginTop: 20,
            padding: 0,
        }
    },
    paper: {
        width: "100%",
        padding: 12,
        backgroundColor: theme.palette.background.defaultBlue,
        [theme.breakpoints.down(600)]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    header: {
        fontSize: 18,
        padding: "8px 16px 0 24px",
        [theme.breakpoints.down(870)]: {
            paddingLeft: 16,
            paddingTop: 4,
            marginBottom: 8
        },
    },
    text: {
        color: theme.palette.text.disabledLight
    },
    commissionPercent: {
        paddingTop: 0,
        fontSize: 16,
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    balanceTitleBox: {
        marginTop: -36,
        [theme.breakpoints.down(450)]: {
            marginTop: -8,
        }
    },
    balanceTitle: {
        textAlign: "center",
    },
    balanceAmount: {
        textAlign: "center",
        fontSize: 32,
        fontWeight: 400,
        color: theme.palette.primary.main,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 16
    },
    balanceRoot: {
        width: "100%",
        flex: 1,
        padding: "0px 16px 8px 16px",
        [theme.breakpoints.down(870)]: {
            flex: "none",
            marginTop: 20,
            padding: 0,
        }
    },
    linksRoot: {
        width: "100%",
        padding: "20px 15px 8px 15px",
        [theme.breakpoints.down(870)]: {
            marginTop: 20,
            padding: 0,
        }
    },
    transactionsRoot: {
        width: "100%",
        padding: "20px 15px 8px 15px",
        [theme.breakpoints.down(870)]: {
            marginTop: 20,
            padding: 0,
        }
    },
});


function ReferAndEarn(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const {person, referral} = props.state;

    const [open, setOpen] = useState(false);

    const handleOnClose = () => {
        setOpen(false);
    };

    const handleOnClick = () => {
        setOpen(true);
    };

    useEffect(() => {
        props.fetchPerson(); // load only once
        props.fetchBalance(); // load only once
        props.fetchTransactions(); // load only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box display="flex" flexDirection="column" sx={{maxWidth: "1280px", margin: "0 auto"}}>
            {props.state.alert.errorMsg !== "" && <CustomSnackbar message={props.state.alert.errorMsg}/>}
            {props.state.alert.successMsg !== "" &&
                <CustomSnackbar message={props.state.alert.successMsg} severity="success"/>}
            <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="stretch">
                <Box width="100%">
                    <h3 className={classes?.header}>Refer & Earn</h3>
                </Box>
                <Box display="flex" flexDirection="column" className={classes?.root}>
                    <Box>
                        <Paper className={classes?.paper} elevation={0} style={{minHeight: 214}}>
                            {person?.isLoading && <Spinner overComponentBox={true}/>}
                            <h3>Become A Skro Affiliate</h3>
                            <p className={classes?.text} style={{paddingTop: 8, paddingBottom: 8}}>
                                Join the Skro affiliate program and earn money with the easiest way. Free to join and
                                easy to set up. Nothing needs to be done except for sharing your referral link.
                                Connect your friends to a tool you trust and earn lifetime commission for every
                                purchase they make.
                            </p>
                            <div className={classes?.commissionPercent}>
                                Lifetime Commission: {person?.person?.referral?.commissionPercent}%
                            </div>
                        </Paper>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" flexWrap="wrap" className={classes?.balanceRoot}>
                    <Box>
                        <Paper className={classes?.paper} elevation={0} style={{minHeight: 214}}>
                            {referral?.loading?.balance && <Spinner overComponentBox={true}/>}
                            <Box display="flex" flexDirection="column" flexWrap="wrap">
                                <Box textAlign="right">
                                    <Withdraw onClose={handleOnClose} open={open}/>
                                    <Button variant="contained" color="primary"
                                            onClick={handleOnClick}>Withdraw</Button>
                                </Box>
                                <Box className={classes?.balanceTitleBox}>
                                    <h3 className={classes?.balanceTitle}>Your Referral Balance</h3>
                                </Box>
                            </Box>
                            <div className={classes?.balanceAmount}>
                                € {referral?.balance.toFixed(2)}
                            </div>
                            <div className={classes?.text}>
                                Rewards are paid once your referral bonuses will reach the €50 threshold. To request
                                referral payout or use referral balance to pay Skro subscription please contact our
                                support team through the live chat or at support@skro.eu
                            </div>
                        </Paper>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" className={classes?.linksRoot}>
                <Paper className={classes?.paper} elevation={0}>
                    {person?.isLoading && <Spinner overComponentBox={true}/>}
                    <h3>Referral link and code</h3>
                    <p className={classes?.text}>
                        Share your referral link or referral code with new user and get rewards from here.
                    </p>
                    <Box display="flex" flexDirection="row" alignItems="stretch" flexWrap="wrap">
                        <Box flexGrow={1} flexBasis={1} flexShrink={1} minWidth={300} mr={1}>
                            {person?.person?.referral?.referralCode &&
                                <CopyContentToClipboard
                                    label="Homepage"
                                    copyText={"https://skro.eu/?refer=" + person?.person?.referral?.referralCode}
                                />
                            }
                        </Box>
                        <Box flexGrow={1} flexBasis={1} flexShrink={1} minWidth={300} mr={1}>
                            {person?.person?.referral?.referralCode &&
                                <CopyContentToClipboard
                                    label="Sign Up"
                                    copyText={"https://app.skro.eu/login?refer=" + person?.person?.referral?.referralCode}
                                />
                            }
                        </Box>
                        <Box flexGrow={1} flexBasis={1} flexShrink={1} minWidth={300} mr={1}>
                            {person?.person?.referral?.referralCode &&
                                <CopyContentToClipboard
                                    label="Referral Code"
                                    copyText={person?.person?.referral?.referralCode}
                                />
                            }
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <Box display="flex" flexDirection="column" className={classes?.transactionsRoot}>
                <Paper className={classes?.paper} elevation={0}>
                    {person?.isLoading && <Spinner overComponentBox={true}/>}
                    <h3>Transactions</h3>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Transaction ID</TableCell>
                                    <TableCell align="left">Payout Type</TableCell>
                                    <TableCell align="left">Subscription Plan</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left">Created</TableCell>
                                </TableRow>
                            </TableHead>
                            {referral?.transactions?.referralTransactions?.length > 0 &&
                                <TableBody>
                                    {referral?.transactions?.referralTransactions?.map((row) => (
                                        <TableRow key={row?.id}>
                                            <TableCell component="th" scope="row">{row?.id}</TableCell>
                                            <TableCell align="left">{row?.payoutType}</TableCell>
                                            <TableCell align="left">{row?.subscriptionPlan}</TableCell>
                                            <TableCell align="left">€ {row?.amount.toFixed(3)}</TableCell>
                                            <TableCell align="left">
                                                {moment(row?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            }
                        </Table>
                        {referral?.transactions?.referralTransactions?.length === 0 &&
                            <Box textAlign="center" sx={{pt: 2, pb: 1}}>
                                No records
                            </Box>
                        }
                    </TableContainer>
                </Paper>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            alert: state.alert,
            person: state.person,
            referral: state.referral,
        }
    };
};

const mapDispatchToProps = {
    fetchPerson: personActions.fetch,
    fetchBalance: referralActions.fetchBalance,
    fetchTransactions: referralActions.fetchTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferAndEarn);