import {Box, Chip, TextField} from "@mui/material";
import React, {useState} from "react";

export default function UrlWithParams(params) {
    const {label, name, register, errors, watch, setValue} = params;
    const [cursor, setCursor] = useState("");

    const handleChipClick = (item) => {
        let e = cursor;
        if (cursor === "" || cursor === undefined) {
            setValue(name, watch(name) + item);
            return false;
        }

        let s = {start: 0, end: 0};
        if (typeof e.target.selectionStart == "number" && typeof e.target.selectionEnd == "number") {
            s.start = e.target.selectionStart;
            s.end = e.target.selectionEnd;
        }

        if (s.start !== s.end) {
            // Replace selected text range.
            let before = e.target.value.slice(0, s.start);
            let after = e.target.value.slice(s.end);
            e.target.value = before + item + after;
            e.target.selectionEnd = before?.length + item?.length;
        } else {
            // Set new item where cursor is.
            let cursorPosition = e.target.selectionStart;
            let textBeforeCursorPosition = e.target.value.substring(0, cursorPosition);
            let textAfterCursorPosition = e.target.value.substring(cursorPosition, e.target.value.length);
            e.target.value = textBeforeCursorPosition + item + textAfterCursorPosition;
            e.target.selectionEnd = textBeforeCursorPosition?.length + item?.length;
        }

        setValue(name, e.target.value);
    };

    const urlChip = (name) => {
        let url = watch("url");
        return (
            <Chip label={name} color="primary" size="small"
                  sx={{fontSize: 12}}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => handleChipClick(name)}
                  disabled={!!url?.includes(name)}
            />
        );
    };

    const handleOnClick = (e) => {
        setCursor(e);
    };

    return (
        <Box sx={{pt: 1, paddingBottom: "12px"}}>
            <TextField
                error={!!errors?.url}
                variant="outlined"
                margin="dense"
                {...register(name, {
                    required: true,
                    pattern: /^https?:\/\/(www\.)?[-a-zA-Z\d@:%._+~#=]{1,512}\.[a-zA-Z\d()]{1,20}\b([-a-zA-Z\d()@:%_+.~#?&/=]*)/
                })}
                name={name}
                size="small"
                label={label}
                placeholder="e.g. https://example.com"
                type="text"
                fullWidth
                onClick={handleOnClick}
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{marginTop: "18px", marginBottom: "20px"}}
            />
            {urlChip("{clickId}")}
            {urlChip("{externalId}")}
            {urlChip("{campaignId}")}
            {urlChip("{campaignName}")}
            {urlChip("{trafficSourceId}")}
            {urlChip("{trafficSourceName}")}
            {urlChip("{landingId}")}
            {urlChip("{landingName}")}
            {urlChip("{offerId}")}
            {urlChip("{offerName}")}
            {urlChip("{deviceType}")}
            {urlChip("{deviceVendor}")}
            {urlChip("{deviceModel}")}
            {urlChip("{browser}")}
            {urlChip("{browserVersion}")}
            {urlChip("{os}")}
            {urlChip("{osVersion}")}
            {urlChip("{ip}")}
            {urlChip("{isp}")}
            {urlChip("{country}")}
            {urlChip("{state}")}
            {urlChip("{city}")}
            {urlChip("{lang}")}
            {urlChip("{userAgent}")}
            {urlChip("{trackingDomain}")}
            {urlChip("{referrerDomain}")}
            {urlChip("{custom1}")}
            {urlChip("{custom2}")}
            {urlChip("{custom3}")}
            {urlChip("{custom4}")}
            {urlChip("{custom5}")}
            {urlChip("{custom6}")}
            {urlChip("{custom7}")}
            {urlChip("{custom8}")}
            {urlChip("{custom9}")}
            {urlChip("{custom10}")}
            {urlChip("{custom1Hash}")}
            {urlChip("{custom2Hash}")}
            {urlChip("{custom3Hash}")}
            {urlChip("{custom4Hash}")}
            {urlChip("{custom5Hash}")}
            {urlChip("{custom6Hash}")}
            {urlChip("{custom7Hash}")}
            {urlChip("{custom8Hash}")}
            {urlChip("{custom9Hash}")}
            {urlChip("{custom10Hash}")}
            {urlChip("{landingKey}")}
        </Box>
    );
}