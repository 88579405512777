import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import PropTypes from "prop-types";
import {integrationService} from "../../service/integrationService";
import Spinner from "../spinner/Spinner";
import {Autocomplete, TextField} from "@mui/material";

function ArbitrageIntegrationSelect({control, setValue, errors, trafficSourceData, setRequestError}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [findIntegrationLoading, setFindIntegrationLoading] = useState(false);

    useEffect(() => {
        if (trafficSourceData?.affiliateNetworkIntegrationId > 0) {
            setFindIntegrationLoading(true);
            integrationService.findIntegration(trafficSourceData?.affiliateNetworkIntegrationId)
                .then((result) => {
                    let data = {
                        value: result?.id,
                        label: result?.networkName
                    };
                    setValue("affiliateNetworkIntegrationId", data);
                }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error?.response?.data?.error);
                }
            }).finally(() => {
                setFindIntegrationLoading(false);
            });
        }
    }, [trafficSourceData, control, setRequestError, setValue])

    useEffect(() => {
        if (open && options?.length === 0) {
            setLoading(true);
            integrationService.getIntegrations({category: "affiliate-network"}).then((result) => {
                setOptions(
                    result?.map(i => {
                        return {
                            value: i?.id,
                            label: i?.networkName ? i?.networkName : ""
                        }
                    })
                );
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error?.response?.data?.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, setRequestError]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="arbitrage-integration-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    getOptionLabel={(option) => option?.label ? option.label : ""}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <>
                            {findIntegrationLoading ? <Spinner/> :
                                <TextField
                                    {...params}
                                    error={!!errors?.affiliateNetworkIntegrationId}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    placeholder="Choose your Sedo integration"
                                    value={search}
                                    onChange={e => handleSearch(e.target.value)}
                                />
                            }
                        </>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            name="affiliateNetworkIntegrationId"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

ArbitrageIntegrationSelect.propTypes = {
    setValue: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    trafficSourceData: PropTypes.object,
    setRequestError: PropTypes.func
};

export default ArbitrageIntegrationSelect;