import React from "react";
import {useForm} from "react-hook-form";
import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme
} from "@mui/material";
import PropTypes from "prop-types";
import WorkspaceDropdown from "./WorkspaceDropdown";

function ChangeWorkspacePopUp({onClose, open, onSubmit}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {handleSubmit, control, setValue, formState: {errors}} = useForm();

    const handleOnSubmit = (data) => {
        onSubmit({workspaceId: data?.workspace?.value});
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="change-workspace-form"
                maxWidth={"sm"}
        >
            <DialogTitle sx={{textAlign: "center"}}>Bulk update for workspace</DialogTitle>
            <DialogContent>
                <Box p={1} sx={{color: "text.disabledLight"}}>
                    Select multiple rows and change the workspace for all of them simultaneously.
                </Box>
                <Box dispaly="flex" flexDirection="column">
                    <Box pt={1} flexGrow={1}>
                        <WorkspaceDropdown errors={errors} control={control} setValue={setValue}/>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                    Save & Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ChangeWorkspacePopUp.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default ChangeWorkspacePopUp;