import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import {FormControlLabel, Radio, RadioGroup, useTheme} from "@mui/material";
import useCustomStyles from "../utils/UseCustomStyles";

const customStyles = (theme) => ({
    radio: {
        '&$checked': {
            color: theme.palette.error.light
        }
    },
    checked: {}
});

ReactHookFormRadio.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    options: PropTypes.array,
    updateOnChange: PropTypes.func
};

ReactHookFormRadio.defaultProps = {
    name: "",
    control: {},
    defaultValue: "",
    options: [],
    updateOnChange: () => null,
};

export default function ReactHookFormRadio({name, control, defaultValue, options, updateOnChange}) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const generateRadioOptions = (props) => {
        return options.map((o) => (
            <FormControlLabel
                key={o?.value}
                value={o?.value}
                label={o?.label}
                control={
                    <Radio
                        color="primary"
                        classes={o?.color === "error" ? {root: classes?.radio, checked: classes?.checked} : {}}
                        checked={props?.value === o?.value}
                    />
                }
            />
        ));
    };

    return <Controller
        name={name}
        control={control}
        render={({field: {onChange, ...props}}) => {
            return (
                <RadioGroup
                    row
                    name={name}
                    defaultValue={defaultValue}
                    onChange={(e, data) => {
                        onChange(data);
                        updateOnChange();
                    }}
                >
                    {generateRadioOptions(props)}
                </RadioGroup>
            )
        }}
    />
}