import {CssBaseline, Box, Alert} from "@mui/material";
import Topbar from "../../layout/Topbar";
import SettingsSidebar from "../SettingsSidebar";
import React from "react";
import TrackingDomains from "./TrackingDomains";
import CloakingDomains from "./CloakingDomains";
import {SettingsContentBox, SettingsRoot} from "../General";

export default function Domains(props) {
    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <Box display="flex" flexWrap={"wrap"} flexDirection="row" flex="1 0">
                    <Box display="flex" mt={4} ml={3} mr={3} mb={1}>
                        <Alert severity="warning" variant="filled">
                            To run high-volume campaigns, it is highly recommended that you set up and use a custom
                            domains. The
                            default <strong>skrotrack.com</strong> and <strong>skrocloak.com</strong> domains
                            are same for all users and may potentially get flagged by antivirus software and
                            traffic sources. That's why is meant for testing purposes only.
                        </Alert>
                    </Box>
                    <Box flex="1 0">
                        <TrackingDomains/>
                    </Box>
                    <Box flex="1 0">
                        <CloakingDomains/>
                    </Box>
                </Box>
            </SettingsContentBox>
        </SettingsRoot>
    );
};