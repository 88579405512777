import React, {createContext, useContext, useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme,
    Button,
    Box,
    Paper,
    Divider,
    Alert,
    ToggleButtonGroup,
    ToggleButton
} from "@mui/material";
import {subscriptionServices} from "../../../service/subscriptionService";
import {CreditCard, Add, Edit} from "@mui/icons-material";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import Spinner from "../../spinner/Spinner";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import {businessSubscriptionPlans, individualSubscriptionPlans} from "../../utils/DataList";
import {CustomItemText, CustomSubItemText, CustomTotalItemText} from "../../auth/AccountSetup";

export const ChangePlanContext = createContext(null);

function ChangePlan(props) {
    const [step, setStep] = useState(1);
    const [planName, setPlanName] = useState("");
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        async function fetchData() {
            await subscriptionServices.fetchSubscription().then((result) => {
                setDetails(result);
            }).catch(() => {
            }).finally(() => {
                setLoading(false);
            });
        }

        if (details === null && props?.open) {
            fetchData();
        }
    }, [details, props.open]);

    return (
        <ChangePlanContext.Provider
            value={{step, setStep, planName, setPlanName, loading, setLoading, details, setDetails}}>
            {step === 1 && <Step1ChoosePlan {...props}/>}
            {step === 2 && <Step2ConfirmPayment {...props}/>}
        </ChangePlanContext.Provider>
    );
}

const Step1ChoosePlan = (props) => {
    const {setStep, setPlanName, loading, details, setDetails} = useContext(ChangePlanContext);
    const {onClose, open} = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [planType, setPlanType] = useState("individual");

    const handleChoosePlan = (planName) => {

        if (planName === details?.plan?.name) {
            return;
        }

        individualSubscriptionPlans.forEach((plan) => {
            if (planName === plan?.name) {
                setPlanName(plan?.name);
                setStep(2);
            }
        });

        businessSubscriptionPlans.forEach((plan) => {
            if (planName === plan?.name) {
                setPlanName(plan?.name);
                setStep(2);
            }
        });
    };

    const handleOnClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setDetails(null);
        onClose();
    };

    const handleToggleButtonChange = (event, newPlanType) => {
        if (newPlanType === null) {
            return false;
        }
        setPlanType(newPlanType);
    };

    return (
        <Dialog
            open={open}
            onClose={handleOnClose}
            fullWidth={true}
            fullScreen={fullScreen}
            aria-labelledby="step1-choose-subscription-plan"
            maxWidth="lg"
        >
            {loading && <Spinner size={32} overComponentBox={true}/>}

            <DialogTitle sx={{textAlign: "center"}}>
                Change subscription plan
            </DialogTitle>

            {!loading && <DialogContent>

                <Box display="flex" justifyContent="center" alignItems="center">
                    <ToggleButtonGroup
                        color="primary"
                        value={planType}
                        exclusive
                        onChange={handleToggleButtonChange}
                        sx={{p: 2}}
                    >
                        <ToggleButton value="individual">Individual</ToggleButton>
                        <ToggleButton value="business">Business</ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                {planType === "individual" &&
                    <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="center">
                        {individualSubscriptionPlans?.map((plan, i) => {
                            return (
                                <Box sx={{width: "100%", minWidth: 200, maxWidth: 350}} p={1} key={i}>
                                    <PlanContainer
                                        planName={plan?.name}
                                        price={plan?.price}
                                        events={plan?.events}
                                        overage={plan?.overagePrice}
                                        customDomains={plan?.customDomains}
                                        months={plan?.dataRetentionInMonths}
                                        totalNumberOfRecords={plan?.totalNumberOfRecords}
                                        users={plan?.users}
                                        choosePlan={handleChoosePlan}
                                        details={details}
                                        integrations={plan?.integrations}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                }

                {planType === "business" &&
                    <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="center">
                        {businessSubscriptionPlans?.map((plan, i) => {
                            return (
                                <Box sx={{width: "100%", minWidth: 200, maxWidth: 350}} p={1} key={i}>
                                    <PlanContainer
                                        planName={plan?.name}
                                        price={plan?.price}
                                        events={plan?.events}
                                        overage={plan?.overagePrice}
                                        customDomains={plan?.customDomains}
                                        months={plan?.dataRetentionInMonths}
                                        totalNumberOfRecords={plan?.totalNumberOfRecords}
                                        users={plan?.users}
                                        choosePlan={handleChoosePlan}
                                        details={details}
                                        integrations={plan?.integrations}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                }

                <Box sx={{paddingTop: 1, textAlign: "center", fontSize: 12, color: "text.disabledLight"}}>
                    * An event should be defined as every visit / click / conversion.
                </Box>
                <Box sx={{paddingTop: 1, textAlign: "center", fontSize: 12, color: "text.disabledLight"}}>
                    ** Overage charge occurs when the plan’s included events limit is exceeded.
                </Box>
            </DialogContent>}
            <DialogActions sx={{padding: "15px"}}>
                <Button type="button" color="inherit" variant="outlined" onClick={handleOnClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const PlanContainer = (
    {
        planName, price, events, overage, customDomains, months, totalNumberOfRecords, users, choosePlan, details,
        integrations
    }
) => {
    return (
        <Paper sx={{
            minWidth: 200,
            padding: "12px",
            backgroundColor: "background.darkBlue",
            textAlign: "center",
            "&:hover": {
                backgroundColor: "background.hover",
            }
        }}>
            <Box sx={{fontWeight: 600, fontSize: 20, paddingTop: 1}}>
                {planName}
            </Box>

            <Box m={2}>
                <Button type="button" variant="contained" color="primary" onClick={() => choosePlan(planName)}
                        disabled={details?.plan?.name === planName}>
                    {details?.plan?.name === planName ? "Current" : "Choose"}
                </Button>
            </Box>

            <Box display="flex" flexDirection="row" flexWrap="noWrap" justifyContent="center" mb={1}>
                <Box sx={{fontWeight: 400, fontSize: 32}} alignSelf="flex-end">€ {price}</Box>
                <Box sx={{fontSize: 14, paddingBottom: "7px", paddingLeft: "2px"}} alignSelf="flex-end">/ month</Box>
            </Box>

            {planName === "Free" && <Box p={1}/>}
            {planName === "Starter" && <Box p={1}/>}
            {planName === "Pro" && <Box p={1}/>}
            {planName === "Team" && <Box p={1}/>}
            {planName === "Enterprise" && <Box p={1}/>}


            <CustomItemText>
                {months} months
            </CustomItemText>
            <CustomSubItemText>
                Data retention
            </CustomSubItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {events}
            </CustomItemText>
            <CustomSubItemText>
                Events per month
            </CustomSubItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                <Box display="flex" flexDirection="row" flexWrap="noWrap" justifyContent="center">
                    <Box sx={{fontWeight: 400}}>€</Box>
                    <Box>{overage}</Box>
                </Box>
            </CustomItemText>
            <CustomSubItemText>
                Per 1000 events overage
            </CustomSubItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {customDomains}
            </CustomItemText>
            <CustomSubItemText>
                Custom domains with Free SSL
            </CustomSubItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {users}
            </CustomItemText>
            <CustomTotalItemText>
                Users
            </CustomTotalItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {integrations}
            </CustomItemText>
            <CustomTotalItemText>
                Integrations
            </CustomTotalItemText>

            <Divider sx={{mt: 1, mb: 1}}/>
            <CustomItemText>
                {totalNumberOfRecords}
            </CustomItemText>
            <CustomTotalItemText>
                Total number of campaigns, offers, landings, flows, traffic sources and aff. networks
            </CustomTotalItemText>
        </Paper>
    );
};

const Step2ConfirmPayment = (props) => {
    const {setStep, planName, setDetails, loading, setLoading} = useContext(ChangePlanContext);
    const {onClose, open} = props;

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [openUpdatePaymentMethod, setOpenUpdatePaymentMethod] = useState(false);
    const [reloadPaymentMethod, setReloadPaymentMethod] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [upcomingInvoice, setUpcomingInvoice] = useState(null);
    const [couponResult, setCouponResult] = useState(null);
    const [coupon, setCoupon] = useState("");
    const [renewalPrice, setRenewalPrice] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);
    const [planPrice, setPlanPrice] = useState(0.00);
    const [totalDueNow, setTotalDueNow] = useState(0.00);
    const [discountCouponName, setDiscountCouponName] = useState("");
    const [discountPercent, setDiscountPercent] = useState(0);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const stripe = useStripe();
    const elements = useElements();

    const handleConfirmPayment = () => {
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        setErrorMsg("");

        subscriptionServices.update(planName, discountCouponName).then((result) => {
            if (result?.status === "active") {
                onClose();
                setStep(1);
                setDetails(null);
            } else if (result?.paymentIntentStatus === "requires_action" || result?.paymentIntentStatus === "requires_payment_method") {
                stripe.confirmCardPayment(result?.paymentIntentClientSecret, {payment_method: result?.paymentMethodId})
                    .then((result) => {
                        if (result?.error) {
                            setErrorMsg(result?.error?.message);
                        } else {
                            if (result.paymentIntent.status === 'succeeded') {
                                // There's a risk of the customer closing the window before callback
                                // execution. To handle this case, set up a webhook endpoint and
                                // listen to invoice.paid. This webhook endpoint returns an Invoice.
                                subscriptionServices.updateSubscriptionStatus().then(() => {
                                    onClose();
                                    setStep(1);
                                    setDetails(null);
                                }).catch((error) => {
                                    setErrorMsg(error?.response?.data?.error);
                                });
                            }
                        }
                    });
            }
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handlePreviousStep = () => {
        setLoading(true);
        setDetails(null);
        setStep(1);
    };

    const handleClickOpenUpdatePaymentMethod = () => {
        setOpenUpdatePaymentMethod(true);
    };

    const handleCloseUpdatePaymentMethod = () => {
        setOpenUpdatePaymentMethod(false);
    };

    const handleReloadPaymentMethod = () => {
        setReloadPaymentMethod(true);
    };

    const handleApplyCoupon = async () => {
        setErrorMsg("");

        if (coupon === "") {
            setErrorMsg("Please enter your coupon code.")
            return false;
        }

        setLoading(true);
        await subscriptionServices.applyCouponToSubscription(coupon).then((result) => {
            setCouponResult(result);
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
            setLoading(false);
        });
    };

    useEffect(() => {
        async function fetchData(planName) {
            setLoading(true);
            await subscriptionServices.retrieveUpcomingInvoice(planName).then((result) => {
                setUpcomingInvoice(result);
            }).catch((error) => {
                setErrorMsg(error?.response?.data?.error);
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
        }

        fetchData(planName);
    }, [planName, setLoading, couponResult]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await subscriptionServices.getPaymentMethod().then((result) => {
                setPaymentMethod(result);
            }).finally(() => {
                setLoading(false);
                setReloadPaymentMethod(false);
            });
        }

        if (reloadPaymentMethod) {
            fetchData();
        }
    }, [reloadPaymentMethod, setLoading]);

    useEffect(() => {
        if (planName !== "" && planName !== undefined) {
            individualSubscriptionPlans.forEach((plan) => {
                if (planName === plan?.name) {
                    setPlanPrice(plan?.price);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planName]);

    useEffect(() => {
        if (planName !== "" && planName !== undefined) {
            businessSubscriptionPlans.forEach((plan) => {
                if (planName === plan?.name) {
                    setPlanPrice(plan?.price);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planName]);

    useEffect(() => {
        if (upcomingInvoice !== null) {
            setTotalDueNow(upcomingInvoice?.amountDue / 100);

            if (upcomingInvoice?.appliedDiscount?.name !== "") {
                let nextPlanPrice = planPrice;
                let off = 0.00;
                if (upcomingInvoice?.appliedDiscount?.percentOff !== 0) {
                    off = nextPlanPrice * (upcomingInvoice?.appliedDiscount?.percentOff / 100);
                }
                setDiscountCouponName(upcomingInvoice?.appliedDiscount?.name);
                setDiscountPercent(upcomingInvoice?.appliedDiscount?.percentOff);
                setDiscount(off);
                setRenewalPrice(nextPlanPrice - off);
            }
        }
    }, [discount, planName, planPrice, upcomingInvoice]);

    useEffect(() => {
        if (errorMsg !== "") {
            setTimeout(() => {
                setErrorMsg("");
            }, 3000);
        }
    }, [errorMsg])

    return (
        <Dialog open={open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        return false;
                    }
                    onClose();
                }}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="selected-plan"
                maxWidth="sm"
        >
            {loading && <Spinner size={32} overComponentBox={true}/>}
            <DialogTitle sx={{textAlign: "center"}}>Change plan to {planName}</DialogTitle>

            <DialogContent>
                <Alert severity="info" variant="filled" sx={{marginBottom: "20px"}}>
                    The payment amount will be charged automatically each month.
                    Subsequent payment is scheduled upon renewal on {upcomingInvoice?.nextPaymentDate}
                </Alert>
                <Box display="flex" flexDirection="row" sx={{fontWeight: 400}}>
                    <Box flexGrow={1} p={1}>
                        Subscribing to plan
                    </Box>
                    <Box p={1}>{planName}</Box>
                </Box>
                <Box display="flex" flexDirection="row" sx={{fontWeight: 400}}>
                    <Box flexGrow={1} p={1}>
                        Current balance:
                    </Box>
                    <Box
                        p={1}>€ {((upcomingInvoice?.currentBalance ? upcomingInvoice?.currentBalance : 0) / 100).toFixed(2)}</Box>
                </Box>
                <Box display="flex" flexDirection="row" sx={{fontWeight: 400}}>
                    <Box flexGrow={1} p={1}>
                        Price
                    </Box>
                    <Box p={1}>€ {planPrice.toFixed(2)}</Box>
                </Box>
                {discount > 0 &&
                    <>
                        <Box display="flex" flexDirection="row" sx={{color: "primary.main", fontWeight: 600}}>
                            <Box flexGrow={1} p={1}>
                                Discount - {discountCouponName} - [{discountPercent}% off]
                            </Box>
                            <Box p={1}>- € {discount.toFixed(2)}</Box>
                        </Box>
                        <Divider/>
                        <Box display="flex" flexDirection="row" sx={{fontWeight: 600}}>
                            <Box flexGrow={1} p={1}>
                                Total cost on renewal (should be paid on {upcomingInvoice?.nextPaymentDate})
                            </Box>
                            <Box p={1}>€ {renewalPrice.toFixed(2)}</Box>
                        </Box>
                    </>
                }

                <Divider/>
                <Box display="flex" flexDirection="row" sx={{fontWeight: 600}}>
                    <Box flexGrow={1} p={1}>
                        Total due now (prorated charges)
                    </Box>
                    <Box p={1}>€ {totalDueNow.toFixed(2)}</Box>
                </Box>
                <Divider/>

                {planName !== "Free" &&
                    <Box display="flex" flexDirection="column" mt={4} mb={1}>
                        <Box><span style={{fontWeight: 600, paddingLeft: 10, fontSize: 14}}>Coupon code</span></Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <Box flexGrow={1}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    name="coupon"
                                    type="text"
                                    size="small"
                                    onChange={(e) => setCoupon(e.target.value)}
                                    placeholder="Enter coupon code"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box ml={1} sx={{paddingTop: "4px"}}>
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleApplyCoupon}
                                >
                                    Apply
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                }

                {couponResult?.valid &&
                    <Box sx={{
                        fontSize: 14,
                        textAlign: "center",
                        paddingBottom: "3px",
                        color: "primary.light"
                    }}>
                        Coupon applied!</Box>
                }

                <Divider/>

                <Box mt={3}>
                    <h4 style={{paddingLeft: 10, marginBottom: 8}}>Payment method</h4>
                    <Divider/>

                    {paymentMethod?.brand === "" && loading === false &&
                        <Box sx={{textAlign: "center", padding: 2}}>
                            <Button type="button" variant="text" color="primary" sx={{textTransform: "none"}}
                                    onClick={handleClickOpenUpdatePaymentMethod}>
                                <Add/> Add payment method
                            </Button>
                            <UpdatePaymentMethod open={openUpdatePaymentMethod}
                                                 onClose={handleCloseUpdatePaymentMethod}
                                                 reloadPaymentMethod={handleReloadPaymentMethod}/>
                        </Box>
                    }

                    {paymentMethod?.brand !== "" && loading === false &&
                        <Box>
                            <Box display="flex" flexWrap="nowrap" flexDirection="row" mt={1} pl={1}>
                                <Box pr={1}><CreditCard/></Box>
                                <Box flexGrow={1} pr={1} sx={{
                                    "&::first-letter": {
                                        textTransform: "capitalize"
                                    }
                                }}>
                                    {paymentMethod?.brand} •••• {paymentMethod?.last4}
                                </Box>
                                <Box textAlign="right">
                                    Expires {paymentMethod?.expMonth}/{paymentMethod?.expYear}
                                </Box>
                            </Box>
                            <Box sx={{textAlign: "center", padding: 2}}>
                                <Button type="button" variant="text" color="primary" sx={{textTransform: "none"}}
                                        onClick={handleClickOpenUpdatePaymentMethod}>
                                    <Edit style={{marginRight: 6, fontSize: 18}}/> Update payment method
                                </Button>
                                <UpdatePaymentMethod open={openUpdatePaymentMethod}
                                                     onClose={handleCloseUpdatePaymentMethod}
                                                     reloadPaymentMethod={handleReloadPaymentMethod}/>
                            </Box>
                        </Box>
                    }

                </Box>

                {errorMsg !== "" &&
                    <Alert sx={{marginBottom: 1, marginTop: 1}} variant="filled" severity="error">
                        {errorMsg}
                    </Alert>
                }

            </DialogContent>
            <DialogActions sx={{padding: 2}}>
                <Button type="button" color="inherit" variant="outlined" onClick={handlePreviousStep}>
                    Back
                </Button>
                <Button
                    type="button"
                    disabled={paymentMethod === null || paymentMethod?.brand === ""}
                    color="primary"
                    variant="contained"
                    onClick={handleConfirmPayment}
                >
                    Confirm Payment
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangePlan;