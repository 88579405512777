import {Box, Button, Divider, IconButton, InputAdornment, TextField} from "@mui/material";
import {Add, Delete, Edit, Launch} from "@mui/icons-material";
import LandingSelect from "./LandingSelect";
import React, {useEffect, useState} from "react";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {isNumber} from "chart.js/helpers";
import {landingServices} from "../../service/landingService";
import {alertActions} from "../../store/actions/alertActions";
import LandingEditPopUp from "../landings/LandingEditPopUp";
import LandingAddPopUp from "../landings/LandingAddPopUp";
import {Controller} from "react-hook-form";

function PathLandings(props) {

    const {
        errors,
        control,
        setValue,
        campaignFormState: {flow, selectedRuleNumber, selectedPathNumber, selectedPath},
    } = props;

    const [open, setOpen] = useState(false);
    const [openLandingEditPopUp, setOpenLandingEditPopUp] = useState(false);
    const [editableLanding, setEditableLanding] = useState(null);

    const maxLandings = 10;

    useEffect(() => {
        if (selectedPath === null) {
            props.updateSelectedPath(selectedRuleNumber, selectedPathNumber, flow?.rules[selectedRuleNumber]?.paths[selectedPathNumber]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPath]);

    const handleOnClose = () => {
        setOpen(false);
    };

    const handleClickOpenLandingPopUp = () => {
        setOpen(true);
    };

    const handleOnCloseLandingEditPopUp = (landingName) => {
        setOpenLandingEditPopUp(false);
        if (landingName !== undefined && landingName !== null && landingName !== "") {
            updateLandingName(selectedRuleNumber, selectedPathNumber, editableLanding?.landingIndex, editableLanding?.landingId, landingName);
        }
    };

    const handleOnClickLandingEditPopUp = (landingIndex, landingId) => {
        if (landingId > 0) {
            setEditableLanding({landingIndex: landingIndex, landingId: landingId});
            setOpenLandingEditPopUp(true);
        }
    };

    const addLanding = (ruleIndex, pathIndex) => {
        props.addLandingToPath(ruleIndex, pathIndex);
    };

    const removeLanding = (ruleIndex, pathIndex, landingIndex) => {
        props.removeLandingFromPath(ruleIndex, pathIndex, landingIndex);
    };

    const updateLandingName = (ruleIndex, pathIndex, landingIndex, landingId, name) => {
        props.updateLandingNameInPath(ruleIndex, pathIndex, landingIndex, landingId, name);
    };

    const handleLandingWeightUpdate = (ruleIndex, pathIndex, landingIndex, weight) => {
        if (isNumber(weight)) {
            weight = parseInt(weight);
        } else {
            weight = "";
        }
        props.updateLandingWeightInPath(ruleIndex, pathIndex, landingIndex, weight);
    };

    const handleOnClickPreview = async (id) => {
        if (id === 0) {
            props.error("Landing page not found.");
            return
        }

        await landingServices.find(id).then((result) => {
            if (result?.url) {
                window.open(result?.url, '_blank').focus();
            }
        }).catch((e) => {
            if (e?.response?.data?.error) {
                props.error("Landing page not found.");
            }
        });
    }

    // Ensure setValue is only called when landings or their weights change
    useEffect(() => {
        if (selectedPath?.landings) {
            selectedPath?.landings?.forEach((landing, landingIndex) => {
                setValue(`landing.rules[${selectedRuleNumber}].paths[${selectedPathNumber}].landings[${landingIndex}].weight`, parseInt(landing?.weight));
            });
        }
    }, [selectedPath?.landings, selectedRuleNumber, selectedPathNumber, setValue]);

    return (
        <Box sx={{
            backgroundColor: "background.darkBlue",
            borderRadius: "4px",
            padding: 1,
            marginBottom: 2
        }}>
            <Box display="flex" p={1} sx={{height: "44px", alignItems: "center"}}>
                <Box flexGrow={1} sx={{fontSize: "14px", fontWeight: "bold"}}>
                    Landings {selectedPath?.landings?.length}/{maxLandings}
                </Box>
                <Box>
                    <Button color="primary" sx={{textTransform: "none", padding: "2px 8px"}} startIcon={<Add/>}
                            onClick={() => handleClickOpenLandingPopUp()}>
                        Create new
                    </Button>
                    <LandingAddPopUp open={open} onClose={handleOnClose}/>
                </Box>
            </Box>
            {
                selectedPath?.landings.map((landing, landingIndex) => {
                    return (
                        <Box display="flex" flexWrap="nowrap" sx={{height: "42px"}} key={landingIndex}>
                            <Box sx={{paddingTop: "20px", paddingLeft: "4px", paddingRight: "4px"}}>
                                {(landingIndex + 1)}
                            </Box>
                            <Box p="10px" flexGrow={1}>
                                <LandingSelect
                                    errors={errors}
                                    control={control}
                                    updateLandingInPath={(value, label) => updateLandingName(selectedRuleNumber, selectedPathNumber, landingIndex, value, label)}
                                    inputValue={landing.landingId === 0 ? null : {
                                        value: landing.landingId,
                                        label: landing.name
                                    }}
                                    inputName={`defaultLandings.paths[${selectedPathNumber}].landings[${landingIndex}].landingId`}
                                    ruleIndex={0}
                                    pathIndex={selectedPathNumber}
                                    landingIndex={landingIndex}
                                    countryCode={props.campaignFormState?.country?.code}
                                    setValue={setValue}
                                />
                            </Box>
                            <Box p="10px">
                                <Controller
                                    name={`landing.rules[${selectedRuleNumber}].paths[${selectedPathNumber}].landings[${landingIndex}].weight`}
                                    control={control}
                                    defaultValue={100}
                                    rules={{
                                        required: true,
                                        min: 0,
                                        max: 100,
                                        validate: (value) => Number.isInteger(Number(value)),
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            sx={{maxWidth: "120px", fontSize: "14px"}}
                                            label="Weight"
                                            variant="outlined"
                                            margin="dense"
                                            type="text"
                                            size="small"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleLandingWeightUpdate(selectedRuleNumber, selectedPathNumber, landingIndex, e.target.value)
                                            }}
                                            error={!!errors?.landing?.rules[selectedRuleNumber]?.paths[selectedPathNumber]?.landings[landingIndex]?.weight}
                                            InputProps={{
                                                inputMode: 'numeric', // Mobile-friendly number input
                                                pattern: '[0-9]*', // Allows only numeric input
                                                sx: {
                                                    "input": {
                                                        fontSize: "14px",
                                                        height: "10px"
                                                    }
                                                },
                                                endAdornment: <InputAdornment position="end">
                                                    <Divider sx={{height: "28px", margin: "4px"}}
                                                             orientation="vertical"/>
                                                    <Box sx={{
                                                        paddingLeft: "6px",
                                                        fontSize: "14px",
                                                        color: "text.disabledLight"
                                                    }}>
                                                        ({props.getCurrentWeight(landing.weight, selectedPath.landings)}%)
                                                    </Box>
                                                </InputAdornment>,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row" flexWrap="nowrap">
                                <Box>
                                    <IconButton
                                        aria-label="preview"
                                        color="secondary"
                                        title="Preview"
                                        size={"small"}
                                        sx={{marginTop: "18px"}}
                                        disabled={landing?.landingId === 0}
                                        onClick={() => handleOnClickPreview(landing?.landingId)}>
                                        <Launch color={landing?.landingId !== 0 ? "secondary" : "inherit"}
                                                fontSize="small"/>
                                    </IconButton>
                                </Box>
                                <Box>
                                    <IconButton
                                        aria-label="edit"
                                        color="inherit"
                                        title="Edit"
                                        size={"small"}
                                        sx={{marginTop: "18px"}}
                                        disabled={landing?.landingId === 0}
                                        onClick={() => handleOnClickLandingEditPopUp(landingIndex, landing?.landingId)}>
                                        <Edit fontSize="small"/>
                                    </IconButton>
                                </Box>
                                <Box>
                                    {selectedPath.landings.length > 1 &&
                                        <IconButton
                                            aria-label="delete"
                                            color="error"
                                            title="Delete"
                                            size="small"
                                            sx={{marginTop: "18px", color: "error.dark"}}
                                            onClick={() => removeLanding(selectedRuleNumber, selectedPathNumber, landingIndex)}>
                                            <Delete fontSize="small"/>
                                        </IconButton>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    );
                })
            }
            <Box display="flex" pt={3} justifyContent="center">
                {selectedPath?.landings?.length < maxLandings &&
                    <Button
                        sx={{textTransform: "none", padding: "4px 12px"}}
                        color="primary"
                        startIcon={<Add/>}
                        onClick={() => addLanding(selectedRuleNumber, selectedPathNumber)}
                    >
                        Add more
                    </Button>
                }
            </Box>
            <LandingEditPopUp
                open={openLandingEditPopUp}
                onClose={handleOnCloseLandingEditPopUp}
                landingId={editableLanding?.landingId}
            />
        </Box>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    addLandingToPath: campaignFormActions.addLandingToPath,
    removeLandingFromPath: campaignFormActions.removeLandingFromPath,
    updateLandingNameInPath: campaignFormActions.updateLandingNameInPath,
    updateLandingWeightInPath: campaignFormActions.updateLandingWeightInPath,
    updateSelectedPath: campaignFormActions.updateSelectedPath,
    error: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(PathLandings);
