import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Geo from "./Geo";
import DayOfWeek from "./DayOfWeek";
import TimeOfDay from "./TimeOfDay";
import TimeZoneSection from "./TimeZoneSection";
import Referrer from "./Referrer";
import ReferrerDomain from "./ReferrerDomain";
import CustomConditions from "./CustomConditions";
import DeviceType from "./DeviceType";
import BrowserLanguage from "./BrowserLanguage";
import UserAgent from "./UserAgent";
import OS from "./OS";
import Browser from "./Browser";
import DeviceVendor from "./DeviceVendor";
import ISP from "./ISP";
import IP from "./IP";
import Uniqueness from "./Uniqueness";
import BotFilterMarks from "./BotFilterMarks";
import {AppBar, Box, Tab, Tabs, useTheme} from "@mui/material";
import useCustomStyles from "../../utils/UseCustomStyles";



const customStyles = (theme) => ({
    tab: {
        width: 105,
        minWidth: 75,
        fontSize: 14,
        textTransform: "capitalize"
    },
    tabContainer: {
        marginTop: theme.spacing(1),
        borderRadius: 4
    }
});

function TabPanel(props) {
    const {children, value, index, classes, ...other} = props;

    return (
        <Box
            pt={1}
            sx={{borderRadius: "4px"}}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>{children}</Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

function ConditionTabs() {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{flexGrow: 1, width: '100%', backgroundColor: "background.defaultBlue"}}>
            <AppBar position="static" sx={{backgroundColor: "background.paper"}} elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="conditions-tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{backgroundColor: "background.darkBlue", borderRadius: "4px"}}
                >
                    <Tab classes={{root: classes?.tab}} label="Geo" aria-label="Geo" {...a11yProps(0)} />
                    <Tab classes={{root: classes?.tab}} label="Time" aria-label="Time" {...a11yProps(1)} />
                    <Tab classes={{root: classes?.tab}} label="Device" aria-label="Device" {...a11yProps(2)} />
                    <Tab classes={{root: classes?.tab}} label="Connection" aria-label="Connection" {...a11yProps(3)} />
                    <Tab classes={{root: classes?.tab}} label="Referrer" aria-label="Referrer" {...a11yProps(4)} />
                    <Tab classes={{root: classes?.tab}} label="Custom" aria-label="Custom" {...a11yProps(5)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Geo/>
            </TabPanel>
            <TabPanel value={value} index={1} classes={classes}>
                <DayOfWeek/>
                <TimeOfDay/>
                <TimeZoneSection/>
            </TabPanel>
            <TabPanel value={value} index={2} classes={classes}>
                <BotFilterMarks/>
                <DeviceType/>
                <DeviceVendor/>
                <OS/>
                <Browser/>
                <BrowserLanguage/>
                <UserAgent/>
            </TabPanel>
            <TabPanel value={value} index={3} classes={classes}>
                <Uniqueness/>
                <ISP/>
                <IP/>
            </TabPanel>
            <TabPanel value={value} index={4} classes={classes}>
                <Referrer/>
                <ReferrerDomain/>
            </TabPanel>
            <TabPanel value={value} index={5} classes={classes}>
                <CustomConditions/>
            </TabPanel>
        </Box>
    );
}

export default ConditionTabs;
