import React, {useEffect, useState} from "react";
import {Paper, Box, Button, useTheme,} from "@mui/material";
import {CreditCard} from '@mui/icons-material';
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import {subscriptionServices} from "../../../service/subscriptionService";
import Spinner from "../../spinner/Spinner";
import UpdateBillingDetails from "./UpdateBillingDetails";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        width: "100%",
        padding: "2px 16px 16px 16px",
        backgroundColor: theme.palette.background.defaultBlue,
        borderRadius: 4
    },
    paper: {
        position: "relative",
        minHeight: 120
    },
    header: {
        fontSize: 18,
        padding: "0px 16px 0 0px"
    },
    paymentMethodNotFound: {
        paddingTop: 10,
        textAlign: "center",
        fontWeight: 400,
        color: theme.palette.text.disabledLight
    },
    card: {
        backgroundColor: theme.palette.background.darkBlue
    },
    cardBrand: {
        paddingTop: 2,
        paddingLeft: 10,
        "&::first-letter": {
            textTransform: "capitalize"
        }
    },
    cardNumber: {
        textAlign: "right"
    },
    cardExp: {
        textAlign: "right",
        paddingTop: 18,
        paddingRight: 15
    },
    updatePaymentMethodButton: {
        [theme.breakpoints.up(408)]: {
            marginLeft: theme.spacing(2)
        }
    }
});

function PaymentMethod() {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const [openUpdatePaymentMethod, setOpenUpdatePaymentMethod] = useState(false);
    const [openUpdateBillingDetails, setOpenUpdateBillingDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [reloadPaymentMethod, setReloadPaymentMethod] = useState(true);

    const handleCloseUpdateBillingDetails = () => {
        setOpenUpdateBillingDetails(false);
    };

    const handleClickOpenUpdateBillingDetails = () => {
        setOpenUpdateBillingDetails(true);
    };

    const handleClickOpenUpdatePaymentMethod = () => {
        setOpenUpdatePaymentMethod(true);
    };

    const handleCloseUpdatePaymentMethod = () => {
        setOpenUpdatePaymentMethod(false);
    };

    const handleReloadPaymentMethod = () => {
        setReloadPaymentMethod(true);
    };

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await subscriptionServices.getPaymentMethod().then((result) => {
                setPaymentMethod(result);
            }).finally(() => {
                setLoading(false);
                setReloadPaymentMethod(false);
            });
        }

        if (reloadPaymentMethod) {
            fetchData();
        }
    }, [reloadPaymentMethod]);

    return (
        <Box className={classes?.root}>
            <Box>
                <Box>
                    <h3>Payment Method</h3>
                </Box>
                <Box>
                    <Paper className={classes?.paper} elevation={0}>
                        {loading && <Spinner size={32} overComponentBox={true} noBackShadow={true}/>}
                        {!loading &&
                            <div>
                                {paymentMethod !== null && paymentMethod?.brand !== "" &&
                                    <Box display="flex" flexWrap="nowrap" flexDirection="row" className={classes?.card}>
                                        <Box display="flex" p={1} m={1} flexGrow={1}>
                                            <CreditCard/>
                                            <span className={classes?.cardBrand}>
                                                {paymentMethod?.brand} •••• {paymentMethod?.last4}
                                            </span>
                                        </Box>
                                        <Box className={classes?.cardExp}>
                                            Expires {paymentMethod?.expMonth}/{paymentMethod?.expYear}
                                        </Box>
                                    </Box>
                                }

                                {paymentMethod?.brand === "" &&
                                    <p className={classes?.paymentMethodNotFound}>Payment method not found.</p>
                                }
                                <Box display="flex" flexDirection="row" justifyContent="stretch" flexWrap="wrap"
                                     style={{paddingTop: 12}}>
                                    <Box flexGrow={1} mt={1}>
                                        <Button type="button" variant="contained" color="secondary" fullWidth
                                                onClick={handleClickOpenUpdateBillingDetails}>
                                            Update billing details
                                        </Button>
                                        <UpdateBillingDetails
                                            open={openUpdateBillingDetails}
                                            onClose={handleCloseUpdateBillingDetails}
                                        />
                                    </Box>
                                    <Box flexGrow={1} mt={1} className={classes?.updatePaymentMethodButton}>
                                        <Button type="button" variant="contained" color="primary" fullWidth
                                                onClick={handleClickOpenUpdatePaymentMethod}>
                                            Update payment method
                                        </Button>
                                        <UpdatePaymentMethod
                                            open={openUpdatePaymentMethod}
                                            onClose={handleCloseUpdatePaymentMethod}
                                            reloadPaymentMethod={handleReloadPaymentMethod}
                                        />
                                    </Box>
                                </Box>
                            </div>
                        }
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}

export default PaymentMethod;