import {integrationActions} from "../../../store/actions/integrationAction";
import {connect} from "react-redux";
import {useEffect} from "react";
import Spinner from "../../spinner/Spinner";
import {useTheme} from "@mui/material";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        paddingTop: 8,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        height: "100%",
    },
    title: {
        textAlign: "center"
    },
    spinner: {
        textAlign: "center",
        paddingBottom: 50
    }
});

function FacebookCallback({history, integration, facebookCallback, alert}) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    useEffect(() => {
        const query = new URLSearchParams(history?.location?.search);
        const code = query.get("code");
        const state = query.get("state");

        if (code !== "" && state !== "") {
            facebookCallback(code, state);
        }
    }, [facebookCallback, history?.location?.search]);

    useEffect(() => {
        if (integration?.currentIntegration?.id) {
            history.push({
                pathname: "/settings/integrations/facebook/" + parseInt(integration?.currentIntegration?.id),
                state: {background: {pathname: "/settings/integrations"}}
            });
        }

        if (alert.errorMsg !== "") {
            history.push({pathname: "/settings/integrations"});
        }
    }, [history, integration?.currentIntegration?.id, alert.errorMsg])

    return (
        <div className={classes?.root}>
            <h3 className={classes?.title}>Integrating Facebook</h3>
            <div className={classes?.spinner}>
                <Spinner size={32} overComponentBox={false}/>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration,
        alert: state.alert
    };
};

const mapDispatchToProps = {
    facebookCallback: integrationActions.facebookCallback
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookCallback);