import {
    Box,
    Button, Divider,
    IconButton,
    InputAdornment,
    InputBase,
    Radio,
    Switch,
    TextField,
    Tooltip, useTheme
} from "@mui/material";
import {Add, Delete, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import React from "react";
import {connect} from "react-redux";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {isNumber} from "chart.js/helpers";
import useCustomStyles from "../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.darkBlue,
        borderRadius: 4,
        marginTop: theme.spacing(2),
        padding: "6px 8px 6px 8px",
    },
    button: {
        textTransform: "none",
        padding: "2px 8px"
    },
    deleteButton: {
        color: theme.palette.error.dark
    },
    ruleBasedPathHeader: {
        alignItems: "center",
        padding: "2px 0"
    },
    ruleHeader: {
        marginTop: 6,
        borderTop: "1px solid " + theme.palette.background.tableBorder,
        borderBottom: "1px solid " + theme.palette.background.tableBorder,
        padding: "2px 0 4px 0"
    },
    checkedRule: {
        marginTop: 6,
        padding: "3px 0 5px 0",
        backgroundColor: theme.palette.background.defaultBlue,
        borderRadius: 4
    },
    ruleTitle: {
        color: theme.palette.primary.main,
        paddingTop: 10,
        paddingLeft: 8,
        fontSize: 14,
        width: 250,
        fontWeight: "bold",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    pathHeader: {
        alignItems: "center",
        padding: "6px 0px 6px 10px"
    },
    pathTitle: {
        fontSize: 14,
        fontWeight: "bold"
    },
    disabledPathTitle: {
        color: "#656a6e",
        "&:hover": {
            backgroundColor: theme.palette.background.input,
            borderRadius: 4
        }
    },
    paths: {
        //height: 42,
        marginBottom: 2,
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 4,
        "&:hover": {
            backgroundColor: theme.palette.background.paper
        }
    },
    margin: {
        "&:hover": {
            backgroundColor: theme.palette.background.input,
            borderRadius: 4
        }
    },
    inputWeight: {
        fontSize: 14,
        height: 10
    },
    inputWeightPercent: {
        paddingLeft: 6,
        fontSize: 14,
        color: theme.palette.text.disabledLight
    }
});

function RuleBasedPaths(props) {

    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const activeRowColor = "rgb(18, 29, 39)";

    const {flow, selectedRuleNumber, selectedPathNumber, selectedRule} = props.campaignFormState;
    const maxPaths = 10;
    const maxRules = 25;

    const updateSelectedRule = (ruleIndex, rule) => {
        props.updateSelectedRule(ruleIndex, rule);
    };

    const updateRulePathWeight = (ruleIndex, pathIndex, weight) => {
        if (isNumber(weight)) {
            weight = parseInt(weight);
        } else {
            weight = "";
        }
        props.updateRulePathWeight(ruleIndex, pathIndex, weight);
    };

    const updateSelectedPath = (ruleIndex, pathIndex, path) => {
        props.updateSelectedPath(ruleIndex, pathIndex, path);
    };

    const updateRulePathName = (ruleIndex, pathIndex, name) => {
        props.updateRulePathName(ruleIndex, pathIndex, name);
    };

    const updateRulePathStatus = (ruleIndex, pathIndex, checked) => {
        props.updateRulePathStatus(ruleIndex, pathIndex, checked);
    };

    const removeRulePath = (ruleIndex, pathIndex) => {
        props.removeRulePath(ruleIndex, pathIndex);
    };

    const addNewRule = () => {
        let priority = flow.rules.length;
        props.addRule(priority);
    };

    const removeRule = (index) => {
        props.removeRule(index);
        let rulesLength = flow.rules.length;
        if (index === 1 && rulesLength === 2) {
            props.updateSelectedRule(0, null);
        } else if (index >= 1 && rulesLength > 2) {
            props.updateSelectedRule(1, flow.rules[1]);
        }
    };

    const updateRuleStatus = (checked, index) => {
        props.updateRuleStatus(checked, index);
    };

    const addRulePath = (index) => {
        props.addRulePath(index);
    };

    const reducePriority = (ruleIndex, to) => {
        if (ruleIndex > 0) {
            props.changeRulePriority(ruleIndex, to);
            props.updateSelectedRule(ruleIndex + to, flow.rules[ruleIndex]);
        }
    };

    return (
        <div className={classes?.root}>
            <Box display="flex" className={classes?.ruleBasedPathHeader}>
                <Box flexGrow={1}>
                    <div className={classes?.pathTitle}>Rule-based paths {flow?.rules?.length - 1}/{maxRules}</div>
                </Box>
                <Box>
                    {
                        flow?.rules?.length < maxRules + 1 &&
                        <Button
                            color="primary" startIcon={<Add/>}
                            onClick={addNewRule}
                            className={classes?.button}
                        >
                            Add rule
                        </Button>
                    }
                </Box>
            </Box>
            {
                flow?.rules?.map((rule, ruleIndex) => {
                    if (ruleIndex > 0) {
                        return (
                            <div key={ruleIndex}>
                                <Box
                                    display="flex"
                                    className={(selectedRuleNumber === ruleIndex && selectedRule !== null) ? classes?.checkedRule : classes?.ruleHeader}
                                >
                                    <Box>
                                        <Radio
                                            checked={(selectedRuleNumber === ruleIndex && selectedRule !== null)}
                                            onChange={() => updateSelectedRule(ruleIndex, rule)}
                                            size="small"
                                            name="selected-radio-button"
                                            inputProps={{'aria-label': 'Selected'}}
                                            color="primary"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <div className={classes?.ruleTitle}>
                                            {rule?.name}
                                        </div>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            disabled={ruleIndex === 1}
                                            aria-label="move-up"
                                            color="inherit"
                                            title="Move up"
                                            size={"small"}
                                            sx={{marginTop: "7px"}}
                                            onClick={() => reducePriority(ruleIndex, -1)}
                                        >
                                            <Tooltip title="Increase priority">
                                                <KeyboardArrowUp fontSize="small"/>
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            disabled={ruleIndex + 1 === flow?.rules?.length}
                                            aria-label="move-down"
                                            color="inherit"
                                            title="Move down"
                                            size={"small"}
                                            sx={{marginTop: "7px"}}
                                            onClick={() => reducePriority(ruleIndex, 1)}
                                        >
                                            <Tooltip title="Reduce priority">
                                                <KeyboardArrowDown fontSize="small"/>
                                            </Tooltip>
                                        </IconButton>
                                    </Box>
                                    <Box sx={{paddingTop: 1}} mr={1}>
                                        <Switch
                                            checked={flow?.rules[ruleIndex]?.status}
                                            onChange={(e, checked) => updateRuleStatus(checked, ruleIndex)}
                                            name={`flow.rules[${ruleIndex}].status`}
                                            color="primary"
                                            size="small"
                                        />
                                    </Box>
                                    <Box>
                                        <IconButton
                                            aria-label="delete"
                                            color="default"
                                            title="Delete"
                                            size={"small"}
                                            sx={{marginTop: "7px"}}
                                            className={classes?.deleteButton}
                                            onClick={() => removeRule(ruleIndex)}
                                        >
                                            <Delete fontSize="small"/>
                                        </IconButton>
                                    </Box>
                                </Box>
                                {
                                    flow?.rules[ruleIndex].paths?.length > 0 &&
                                    <Box display="flex" className={classes?.pathHeader}>
                                        <Box flexGrow={1}>
                                            <div className={classes?.pathTitle}>
                                                Paths {flow?.rules[ruleIndex].paths?.length}/10
                                            </div>
                                        </Box>
                                        <Box>
                                            {flow?.rules[ruleIndex].paths?.length < maxPaths &&
                                                <Button
                                                    color="primary"
                                                    startIcon={<Add/>}
                                                    onClick={() => addRulePath(ruleIndex)}
                                                    className={classes?.button}
                                                >
                                                    Add path
                                                </Button>
                                            }
                                        </Box>
                                    </Box>
                                }
                                {
                                    flow?.rules[ruleIndex]?.paths?.map((path, pathIndex) => {
                                        if (ruleIndex > 0) {
                                            return (
                                                <Box display="flex" flexWrap="nowrap" className={classes?.paths}
                                                     key={pathIndex}
                                                     bgcolor={(selectedRuleNumber === ruleIndex && selectedPathNumber === pathIndex && selectedRule === null) ? activeRowColor : ""}>
                                                    <Box>
                                                        <Radio
                                                            checked={(selectedRuleNumber === ruleIndex && selectedPathNumber === pathIndex && selectedRule === null)}
                                                            onChange={() => updateSelectedPath(ruleIndex, pathIndex, path)}
                                                            size="small"
                                                            name="selected-radio-button"
                                                            inputProps={{'aria-label': 'Selected'}}
                                                            color="primary"
                                                        />
                                                    </Box>
                                                    <Box ml={1} flexGrow={1}
                                                         onClick={() => updateSelectedPath(ruleIndex, pathIndex, path)}>
                                                        <InputBase
                                                            sx={{maxWidth: "200px"}}
                                                            className={!path?.status ? classes?.disabledPathTitle : classes?.margin}
                                                            value={path?.name}
                                                            name={`flow.rules[${ruleIndex}].paths[${pathIndex}].name`}
                                                            inputProps={{'aria-label': 'naked'}}
                                                            onChange={(e) => updateRulePathName(ruleIndex, pathIndex, e.target.value)}
                                                        />
                                                    </Box>
                                                    <Box mr={1}>
                                                        <TextField
                                                            sx={{maxWidth: 110, marginTop: "4px"}}
                                                            label="Weight"
                                                            variant="outlined"
                                                            margin="dense"
                                                            type="text"
                                                            size="small"
                                                            value={path?.weight}
                                                            onChange={e => updateRulePathWeight(ruleIndex, pathIndex, e.target.value)}
                                                            InputProps={{
                                                                classes: {input: classes?.inputWeight},
                                                                endAdornment: <InputAdornment position="end">
                                                                    <Divider className={classes?.divider}
                                                                             orientation="vertical"/>
                                                                    <span className={classes?.inputWeightPercent}>
                                                                        ({props.getCurrentWeight(path.weight, flow.rules[ruleIndex].paths)}%)
                                                                     </span>
                                                                </InputAdornment>
                                                            }}
                                                            InputLabelProps={{
                                                                sx: {fontSize: 14},
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Box>
                                                    {
                                                        flow?.rules[ruleIndex]?.paths?.length > 1 &&
                                                        <Box display="flex">
                                                            <Box mr={1}>
                                                                <Switch
                                                                    checked={path?.status}
                                                                    onChange={(e, checked) => updateRulePathStatus(ruleIndex, pathIndex, checked)}
                                                                    name={`flow.rules[${ruleIndex}].paths[${pathIndex}].status`}
                                                                    color="primary"
                                                                    size="small"
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    title="Delete"
                                                                    size={"small"}
                                                                    className={classes?.deleteButton}
                                                                    onClick={() => removeRulePath(ruleIndex, pathIndex)}
                                                                >
                                                                    <Delete fontSize="small"/>
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    }
                                                </Box>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        );
                    } else {
                        return null;
                    }
                })
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    addRule: campaignFormActions.addRule,
    removeRule: campaignFormActions.removeRule,
    updateRuleStatus: campaignFormActions.updateRuleStatus,
    addRulePath: campaignFormActions.addRulePath,
    removeRulePath: campaignFormActions.removeRulePath,
    updateRulePathStatus: campaignFormActions.updateRulePathStatus,
    updateRulePathName: campaignFormActions.updateRulePathName,
    updateSelectedPath: campaignFormActions.updateSelectedPath,
    updateRulePathWeight: campaignFormActions.updateRulePathWeight,
    updateSelectedRule: campaignFormActions.updateSelectedRule,
    changeRulePriority: campaignFormActions.changeRulePriority,
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleBasedPaths);