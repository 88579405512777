import React from "react";
import Logo from "../utils/Logo";
import {Box, Button} from "@mui/material";

function PageNotFound() {
    return (
        <Box sx={{
            display: 'flex',
            backgroundColor: "background.defaultBlue",
            color: "common.white",
            maxWidth: '100%'
        }}>
            <Box sx={{
                height: '100%',
                width: '100%',
                overflow: 'auto',
                paddingTop: "120px",
                paddingBottom: 2,
                textAlign: "center",
                fontSize: "16px"
            }}>
                <Box display="flex" flexWrap={"wrap"} flexDirection="row" flex="1 0" justifyContent="center">
                    <Box p={1}>
                        <Box sx={{
                            fontSize: "20px",
                            fontWeight: 600,
                            textAlign: "center",
                            textDecorations: "none"
                        }}>
                            <Logo width={85}/>
                        </Box>
                        <Box>
                            <Box pt={1} sx={{fontSize: "24px", fontWeight: 600}}>
                                404 - Page not found
                            </Box>
                            <Box textAlign="center" pb="5px" pt={1}>
                                The page you are looking for might have been removed or is
                                temporarily unavailable.
                            </Box>
                            <Button type="button" variant="contained" color="primary" size="large" href={"/"}>
                                Go to dashboard
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default PageNotFound;