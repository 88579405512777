import React, {Component} from 'react';
import {connect} from 'react-redux'
import {authActions} from "../../store/actions/authAction";
import {Redirect} from "react-router-dom";
import Spinner from "../spinner/Spinner";

class VerifyGoogleLogin extends Component {
    constructor(props) {
        super(props);

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const state = params.get('state');
        const code = params.get('code');

        this.state = {
            applicationName: "skro",
            code: code,
            state: state,
        };
    }

    componentDidMount() {
        const {applicationName, state, code} = this.state;
        if (state && code) {
            this.props.verifyGoogleLogin(applicationName, state, code);
        }
    }

    render() {
        const {isAuthenticated, authError} = this.props.auth;
        const {loaded} = this.props.auth.userSettings;

        if (authError) {
            return (
                <Redirect to={{pathname: "/login"}}/>
            );
        }

        if (isAuthenticated && loaded) {
            return (
                <Redirect to={{pathname: "/"}}/>
            );
        } else {
            return (
                <Spinner overComponentBox={true}/>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const actionCreators = {
    verifyGoogleLogin: authActions.verifyGoogleLogin
};

export default connect(mapStateToProps, actionCreators)(VerifyGoogleLogin);