import {apiRequest} from "../helpers/utils";

export const noteServices = {
    create,
    update,
    archive,
    read
};

function read({campaignId}) {
    return apiRequest("get", "v1/campaign/" + parseInt(campaignId) + "/note", {}, {});
}

function create({campaignId, formData}) {
    return apiRequest("post", "v1/campaign/" + parseInt(campaignId) + "/note", formData);
}

function update({campaignId, noteId, formData}) {
    return apiRequest("put", "v1/campaign/" + parseInt(campaignId) + "/note/" + parseInt(noteId), formData);
}

function archive({campaignId, noteId}) {
    return apiRequest("delete", "v1/campaign/" + parseInt(campaignId) + "/note/" + parseInt(noteId), {});
}
