import PropTypes from "prop-types";
import React, {Component} from "react";
import {
    Add,
    FileCopy,
    Refresh,
    RestoreFromTrash,
    Link,
    AttachMoney,
    ViewColumn,
    Delete,
    Launch,
    Clear, SpeakerNotes, PlayArrow, FileDownload
} from "@mui/icons-material";
import debounce from "lodash/debounce";
import {connect} from "react-redux";
import {compose} from "redux";
import {ddToolbarActions} from "../../store/actions/ddToolbarActions";
import AlertDialog from "../alert/AlertDialog";
import ColumnSettings from "../reporting-table/ColumnSettings";
import AirbnbDateRangePicker from "./AirbnbDateRangePicker";
import {Badge, Box, Button, ButtonGroup, IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import {columnActions} from "../../store/actions/columnAction";
import NotesForm from "../notes/NotesForm";
import EditButtonGroup from "./EditButtonGroup";
import ChangeTrackingDomainPopUp from "../tracking-domain/ChangeTrackingDomainPopUp";
import ChangeWorkspacePopUp from "../workspace/ChangeWorkspacePopUp";
import TagFilter from "../tags/TagFilter";
import OfferUrlBulkUpdatePopUp from "../offers/OfferUrlBulkUpdatePopUp";
import BulkUpdateForLandingUrlPopUp from "../landings/BulkUpdateForLandingUrlPopUp";

class CustomToolbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: props.searchTerm,
            tags: props.tagsTerm,
            onlyCampaignView: props.onlyCampaignView,
            onlyOfferView: props.onlyOfferView,
            onlyLandingView: props.onlyLandingView,
            allowPreviewButton: props.allowPreviewButton,
            open: true,
            openColumnSettings: false,
            filterPopup: null,
            archivePrompt: false,
            restorePrompt: false,
            openNotes: false,
            report: props.report,
            columnGroup: props.columnGroup,
            openTrackingDomainPopUp: false,
            openWorkspacePopUp: false,
            openOfferUrlBulkUpdatePopUp: false,
            openBulkUpdateForLandingUrlPopUp: false,
            searchBox: "text"
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateSearchChange = this.updateSearchChange.bind(this);
        this.updateTagsChange = this.updateTagsChange.bind(this);
        this.toggleOnClickNotes = this.toggleOnClickNotes.bind(this);

        // Debounce
        this.updateSearchChange = debounce(this.updateSearchChange, 800);
        this.updateTagsChange = debounce(this.updateTagsChange, 500);
    }

    handleChange(e) {
        this.setState({search: e.target.value});
        this.updateSearchChange();
    }

    updateSearchChange() {
        this.props.onChangeSearch(this.state.search);
    }

    handleTagsChange(v) {
        this.setState({tags: v});
        this.updateTagsChange();
    }

    updateTagsChange() {
        this.props.onChangeTags(this.state.tags);
    }

    handleStatusChange = () => {
        if (this.props.ddToolbarState.isDeleted) {
            this.props.updateIsDeleted(false);
        } else {
            this.props.updateIsDeleted(true);
        }
    };

    handleOnCloseColumnSettings = () => {
        this.setState({openColumnSettings: false});
        this.props.onClickRefresh();
    };

    handleOpenColumnSettings = () => {
        this.setState({openColumnSettings: true});
    };

    handleArchivePrompt = () => {
        this.setState({archivePrompt: true});
    };

    handleArchiveClick = (confirm) => {
        if (!confirm) {
            this.setState({archivePrompt: false});
            return false;
        }
        this.setState({archivePrompt: false});
        this.props.onClickArchive(this.getListOfSelectedRowsIDs(this.props.selectedRows));
    };

    handleRestorePrompt = () => {
        this.setState({restorePrompt: true});
    };

    handleRestoreClick = (confirm) => {
        if (!confirm) {
            this.setState({restorePrompt: false});
            return false;
        }
        this.setState({restorePrompt: false});
        this.props.onClickRestore(this.getListOfSelectedRowsIDs(this.props.selectedRows));
    };

    getSelectedRowID = (selectedRows) => {
        let rowId = 0;
        for (const id of selectedRows) {
            rowId = id;
        }
        return rowId;
    };

    getListOfSelectedRowsIDs = (selectedRows) => {
        let ids = [];
        for (const id of selectedRows) {
            ids.push(id);
        }
        return ids;
    };

    toggleOnClickNotes = (open) => {
        this.setState({openNotes: open});
    };

    handleOnClickMenuItemChangeTrackingDomain = () => {
        this.setState({openTrackingDomainPopUp: true})
    };

    handleOnCloseTrackingDomainPopUp = () => {
        this.setState({openTrackingDomainPopUp: false})
    };

    handleOnSubmitChangeTrackingDomain = (data) => {
        this.handleOnCloseTrackingDomainPopUp();
        this.props.onSubmitChangeTrackingDomain(data);
    };

    handleOnClickMenuItemChangeWorkspace = () => {
        this.setState({openWorkspacePopUp: true})
    };

    handleOnCloseWorkspacePopUp = () => {
        this.setState({openWorkspacePopUp: false})
    };

    handleOnSubmitChangeWorkspace = (data) => {
        this.handleOnCloseWorkspacePopUp();
        this.props.onSubmitChangeWorkspace(data);
    };

    handleOnClickMenuItemOfferUrlBulkUpdatePopUp = () => {
        this.setState({openOfferUrlBulkUpdatePopUp: true})
    };

    handleOnCloseOfferUrlBulkUpdatePopUp = () => {
        this.setState({openOfferUrlBulkUpdatePopUp: false})
    };

    handleOnSubmitOfferUrlBulkUpdate = (data) => {
        this.handleOnCloseOfferUrlBulkUpdatePopUp();
        this.props.onSubmitOfferUrlBulkUpdate(data);
    };

    handleOnClickMenuItemBulkUpdateForLandingUrlPopUp = () => {
        this.setState({openBulkUpdateForLandingUrlPopUp: true})
    };

    handleOnCloseBulkUpdateForLandingUrlPopUp = () => {
        this.setState({openBulkUpdateForLandingUrlPopUp: false})
    };

    handleOnSubmitBulkUpdateForLandingUrl = (data) => {
        this.handleOnCloseBulkUpdateForLandingUrlPopUp();
        this.props.onSubmitBulkUpdateForLandingUrl(data);
    };

    handleOnClickSearchFilter = (v) => {
        this.setState({searchBox: v})
    };

    componentDidUpdate() {
        if ((this.props.selectedRows.size === 0 || this.props.selectedRows.size > 1) && this.state.openNotes) {
            this.toggleOnClickNotes(false);
        }
    }

    render() {

        const {selectedRows} = this.props;
        const {from, to, dateLabel, isDeleted} = this.props.ddToolbarState;
        const {
            archivePrompt, restorePrompt, onlyCampaignView, allowPreviewButton, onlyOfferView, onlyLandingView
        } = this.state;

        return (
            <Box sx={{
                color: "text.secondary",
                backgroundColor: "background.paper",
                overflow: "auto",
                width: "100%",
                paddingTop: "2px",
                paddingBottom: 0,
            }}>
                {archivePrompt &&
                    <AlertDialog question="Archive confirmation"
                                 text={"Are you sure you want to archive selected " + (selectedRows?.size > 1 ? "rows?" : "row?")}
                                 confirm={this.handleArchiveClick}/>
                }
                {restorePrompt &&
                    <AlertDialog question="Restore confirmation"
                                 text={"Are you sure you want to restore selected " + (selectedRows?.size > 1 ? "rows?" : "row?")}
                                 confirm={this.handleRestoreClick}/>
                }
                <Box display="flex" flexWrap="nowrap">
                    <Box display="flex" flexGrow={1}>
                        <Box mt="5px" ml={1}>
                            <Button
                                onClick={() => this.props.onClickAdd()}
                                variant="contained"
                                color="primary"
                                sx={{
                                    textTransform: "capitalize",
                                    borderColor: "border.button",
                                    whiteSpace: "nowrap",
                                    padding: "6px 10px"
                                }}
                                startIcon={<Add size="small"/>}
                            >
                                Create
                            </Button>
                        </Box>
                        <Box mt="5px" ml={1}>
                            <Button
                                onClick={() => this.props.onClickDuplicate(this.getSelectedRowID(selectedRows))}
                                variant="outlined"
                                color="inherit"
                                sx={{
                                    textTransform: "capitalize", borderColor: "border.button", whiteSpace: "nowrap",
                                    padding: "5px 10px"
                                }}
                                startIcon={<FileCopy size="small"/>}
                                disabled={selectedRows.size !== 1}
                            >
                                Duplicate
                            </Button>
                        </Box>
                        <Box mt="5px" ml={1}>
                            <EditButtonGroup
                                clickEditButton={this.props.onClickEdit}
                                selectedId={this.getSelectedRowID(selectedRows)}
                                selectedRows={selectedRows}
                                onlyCampaignView={onlyCampaignView}
                                onlyOfferView={onlyOfferView}
                                onlyLandingView={onlyLandingView}
                                handleOnClickMenuItemChangeTrackingDomain={this.handleOnClickMenuItemChangeTrackingDomain}
                                handleOnClickMenuItemChangeWorkspace={this.handleOnClickMenuItemChangeWorkspace}
                                handleOnClickMenuItemOfferUrlBulkUpdate={this.handleOnClickMenuItemOfferUrlBulkUpdatePopUp}
                                handleOnClickMenuItemBulkUpdateForLandingUrl={this.handleOnClickMenuItemBulkUpdateForLandingUrlPopUp}
                            />
                            <ChangeTrackingDomainPopUp
                                open={this.state.openTrackingDomainPopUp}
                                onClose={this.handleOnCloseTrackingDomainPopUp}
                                onSubmit={this.handleOnSubmitChangeTrackingDomain}
                            />
                            <ChangeWorkspacePopUp
                                open={this.state.openWorkspacePopUp}
                                onClose={this.handleOnCloseWorkspacePopUp}
                                onSubmit={this.handleOnSubmitChangeWorkspace}
                            />
                            <OfferUrlBulkUpdatePopUp
                                open={this.state.openOfferUrlBulkUpdatePopUp}
                                onClose={this.handleOnCloseOfferUrlBulkUpdatePopUp}
                                onSubmit={this.handleOnSubmitOfferUrlBulkUpdate}
                            />
                            <BulkUpdateForLandingUrlPopUp
                                open={this.state.openBulkUpdateForLandingUrlPopUp}
                                onClose={this.handleOnCloseBulkUpdateForLandingUrlPopUp}
                                onSubmit={this.handleOnSubmitBulkUpdateForLandingUrl}
                            />
                        </Box>
                        {onlyCampaignView &&
                            <Box mt="5px" ml={1}>
                                <Button
                                    onClick={() => this.props.onClickCampaignLinks(this.getSelectedRowID(selectedRows))}
                                    variant="outlined"
                                    color="inherit"
                                    sx={{
                                        textTransform: "capitalize",
                                        borderColor: "border.button",
                                        whiteSpace: "nowrap",
                                        padding: "5px 10px"
                                    }}
                                    startIcon={<Link color={"inherit"}/>}
                                    disabled={selectedRows.size !== 1}
                                >
                                    Links
                                </Button>
                            </Box>
                        }
                        {onlyCampaignView &&
                            <Box mt="5px" ml={1}>
                                <Tooltip title="Notes" placement="top">
                                    <span>
                                        <Button
                                            sx={{
                                                p: "5px 6px",
                                                minWidth: 30,
                                                minHeight: 34.75
                                            }}
                                            onClick={() => this.toggleOnClickNotes(true)}
                                            variant="outlined"
                                            color="inherit"
                                            disabled={selectedRows.size !== 1}
                                        >
                                            <SpeakerNotes fontSize="small" color="inherit"/>
                                        </Button>
                                    </span>
                                </Tooltip>
                                {this.state.openNotes &&
                                    <NotesForm open={this.state.openNotes} toggleDrawer123={this.toggleOnClickNotes}
                                               campaignId={this.getSelectedRowID(selectedRows)}/>
                                }
                            </Box>
                        }
                        {onlyCampaignView &&
                            <Box mt="5px" ml={1}>
                                <Tooltip title="Update cost manually" placement="top">
                                    <span>
                                        <Button
                                            sx={{
                                                p: "5px 6px",
                                                minWidth: 30,
                                                minHeight: 34.75
                                            }}
                                            onClick={() => this.props.onClickCampaignCost(this.getSelectedRowID(selectedRows))}
                                            variant="outlined"
                                            color="inherit"
                                            disabled={selectedRows.size !== 1}
                                        >
                                            <AttachMoney fontSize="small" color="inherit"/>
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                        }
                        {isDeleted ?
                            <Box mt="5px" ml={1}>
                                <Tooltip title="Restore" placement="top">
                                    <span>
                                        <Button
                                            sx={{
                                                p: "5px 6px",
                                                minWidth: 30,
                                                minHeight: 34.75
                                            }}
                                            onClick={this.handleRestorePrompt}
                                            variant="outlined"
                                            color="inherit"
                                            disabled={selectedRows.size < 1}
                                        >
                                            <RestoreFromTrash color={selectedRows.size < 1 ? "inherit" : "primary"}/>
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                            :
                            <Box mt="5px" ml={1}>
                                <Tooltip title="Archive" placement="top">
                                    <span>
                                        <Button
                                            sx={{
                                                p: "5px 6px",
                                                minWidth: 30,
                                                minHeight: 34.75
                                            }}
                                            onClick={this.handleArchivePrompt}
                                            variant="outlined"
                                            color="inherit"
                                            disabled={selectedRows.size < 1}
                                        >
                                            <Delete color={selectedRows.size < 1 ? "inherit" : "error"}/>
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                        }
                        {allowPreviewButton &&
                            <Box mt="5px" ml={1}>
                                <Button
                                    onClick={() => this.props.onClickPreview(this.getSelectedRowID(selectedRows))}
                                    variant="outlined"
                                    color="inherit"
                                    sx={{
                                        textTransform: "capitalize",
                                        borderColor: "border.button",
                                        whiteSpace: "nowrap",
                                        padding: "5px 10px"
                                    }}
                                    startIcon={<Launch color={selectedRows.size < 1 ? "inherit" : "secondary"}/>}
                                    disabled={selectedRows.size !== 1}
                                >
                                    Preview
                                </Button>
                            </Box>
                        }
                        <Box mt="5px" ml={1}>
                            <Tooltip title="Export to CSV" placement="top">
                                <Button
                                    sx={{
                                        p: "5px 6px",
                                        minWidth: 30,
                                        minHeight: 34.75
                                    }}
                                    onClick={() => this.props.onClickExportCSV()}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    <FileDownload/>
                                </Button>
                            </Tooltip>
                        </Box>
                        <Box mt="5px" ml={1}>
                            <Tooltip title={this.props.ddToolbarState.isDeleted ? "View Active" : "View Archived"}
                                     placement="top">
                                <Button
                                    onClick={this.handleStatusChange}
                                    variant={"outlined"}
                                    sx={{
                                        textTransform: "capitalize",
                                        borderColor: "border.button",
                                        whiteSpace: "nowrap",
                                        padding: "5px 10px"
                                    }}
                                    color="inherit"
                                    startIcon={this.props.ddToolbarState.isDeleted ? <Delete color="error"/> :
                                        <PlayArrow color="primary"/>}
                                >
                                    {this.props.ddToolbarState.isDeleted ? "Archived" : "Active"}
                                </Button>
                            </Tooltip>
                        </Box>
                        <Box mt="5px" ml={3} display="flex" flexDirection="row" flexWrap="noWrap"
                             sx={{minWidth: "150px", height: 34.75}}>
                            <Box>
                                <ButtonGroup size="small" variant="outlined" aria-label="small outlined button group">
                                    <Button
                                        onClick={() => this.handleOnClickSearchFilter("text")}
                                        color="inherit"
                                        sx={{
                                            borderColor: "border.button",
                                            minWidth: "20px",
                                            padding: "2px 6px",
                                            height: 34.75,
                                            ...(this.state.searchBox === "text" && {
                                                backgroundColor: "secondary.main",
                                                "&:hover": {
                                                    backgroundColor: "secondary.main"
                                                }
                                            })
                                        }}
                                    >
                                        Text
                                    </Button>
                                    <Button
                                        onClick={() => this.handleOnClickSearchFilter("tags")}
                                        color="inherit"
                                        sx={{
                                            borderColor: "border.button",
                                            minWidth: "20px",
                                            padding: "2px 6px",
                                            height: 34.75,
                                            ...(this.state.searchBox === "tags" && {
                                                backgroundColor: "secondary.main",
                                                "&:hover": {
                                                    backgroundColor: "secondary.main"
                                                }
                                            })
                                        }}
                                    >
                                        Tags
                                    </Button>
                                </ButtonGroup>
                            </Box>
                            {this.state.searchBox === "text" &&
                                <Box ml={1}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Search..."
                                        value={this.state.search}
                                        size="small"
                                        onChange={this.handleChange}
                                        InputProps={{
                                            sx: {
                                                "& input": {
                                                    paddingTop: "10px",
                                                    backgroundColor: "background.defaultBlue",
                                                    width: "204px",
                                                    height: "24px",
                                                    "&::placeholder": {
                                                        fontSize: "13px",
                                                        fontWeight: 600,
                                                        color: "common.white"
                                                    },
                                                    '&.MuiOutlinedInput-input': {
                                                        padding: "0px 9px",
                                                        marginTop: "-1px",
                                                    }
                                                },
                                                '&.MuiOutlinedInput-root': {
                                                    backgroundColor: "background.defaultBlue",
                                                    height: 34.75,
                                                    borderWidth: "1px",
                                                    borderColor: "border.button",
                                                    borderStyle: "solid"
                                                }
                                            },
                                            'aria-label': 'Search...',
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {this.state.search.length > 0 &&
                                                        <IconButton
                                                            color="inherit"
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.target.value = "";
                                                                this.handleChange(e);
                                                            }}
                                                        >
                                                            <Clear sx={{color: "text.primary"}} color="inherit"
                                                                   fontSize="small"/>
                                                        </IconButton>
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            }
                            {this.state.searchBox === "tags" &&
                                <Box ml={1}>
                                    <TagFilter
                                        inputName="tags"
                                        inputValue={this.state.tags}
                                        updateOnChange={(s) => {
                                            this.handleTagsChange(s)
                                        }}
                                    />
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box display="flex" pr={1} pl="20px">
                        <Box pt="5px">
                            <AirbnbDateRangePicker
                                from={from}
                                to={to}
                                dateLabel={dateLabel}
                                onChangeDate={(from, to, dateLabel) => this.props.updateDateRange(from, to, dateLabel)}
                            />
                        </Box>
                        <Box p={1}>
                            <Tooltip title="Column settings" placement="top">
                                <span>
                                    <IconButton
                                        color="inherit"
                                        onClick={() => this.handleOpenColumnSettings()}
                                        aria-label="column-settings"
                                        size="small"
                                    >
                                        <Badge color="error" variant="dot">
                                            <ViewColumn color="inherit"/>
                                        </Badge>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {this.state.openColumnSettings &&
                                <ColumnSettings
                                    columns={this.props.columns}
                                    onClose={this.handleOnCloseColumnSettings}
                                    open={this.state.openColumnSettings}
                                    report={this.state.report}
                                    columnGroup={this.state.columnGroup}
                                />
                            }
                        </Box>
                        <Box p={1}>
                            <Tooltip title="Refresh" placement="top">
                                <span>
                                    <IconButton
                                        color="inherit"
                                        onClick={() => this.props.onClickRefresh()}
                                        aria-label="refresh"
                                        size="small"
                                    >
                                        <Refresh color={"inherit"}/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                        <div>
                            {this.props.children}
                        </div>
                    </Box>
                </Box>
            </Box>
        );
    }
}

CustomToolbar.propTypes = {
    columns: PropTypes.array,
    onClickAdd: PropTypes.func,
    onClickRefresh: PropTypes.func,
    onClickExportCSV: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDuplicate: PropTypes.func,
    onClickArchive: PropTypes.func,
    onClickRestore: PropTypes.func,
    onChangeSearch: PropTypes.func,
    onClickCampaignLinks: PropTypes.func,
    onClickCampaignCost: PropTypes.func,
    onSubmitChangeTrackingDomain: PropTypes.func,
    searchTerm: PropTypes.string,
    tagsTerm: PropTypes.array,
    onlyCampaignView: PropTypes.bool,
    onlyOfferView: PropTypes.bool,
    allowPreviewButton: PropTypes.bool,
    report: PropTypes.string.isRequired,
    columnGroup: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ddToolbarState: state.ddToolbar,
        column: state.column
    }
};

const actionCreators = {
    updateIsDeleted: ddToolbarActions.updateIsDeleted,
    updateDateRange: ddToolbarActions.updateDateRange,
    updateColumnVisibility: columnActions.updateColumnVisibility,
};

export default compose(connect(mapStateToProps, actionCreators))(CustomToolbar);