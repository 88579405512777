import {apiRequest} from "../helpers/utils";
import {accessApiUrl} from "../helpers/config";

export const subscriptionServices = {
    create,
    update,
    retrieveUpcomingInvoice,
    getPaymentMethod,
    updatePaymentMethod,
    updateBillingDetails,
    getBillingDetails,
    fetchSubscription,
    retryInvoice,
    updateSubscriptionStatus,
    getInvoices,
    verifyCoupon,
    applyCouponToSubscription,
    skroPostback
};

function create({planName, paymentMethodId, coupon, referralCode}) {
    return apiRequest("post", "v1/subscription", {planName, paymentMethodId, coupon, referralCode}, {}, true, false, accessApiUrl);
}

function update(planName, coupon) {
    return apiRequest("put", "v1/subscription", {planName, coupon}, {}, true, false, accessApiUrl);
}

function fetchSubscription(limits, period, events, latestInvoice) {
    return apiRequest("get", "v1/subscription", {}, {limits, period, events, latestInvoice}, true, false, accessApiUrl);
}

function retrieveUpcomingInvoice(planName) {
    return apiRequest("post", "v1/subscription/retrieve-upcoming-invoice", {planName}, {}, true, false, accessApiUrl);
}

function getPaymentMethod() {
    return apiRequest("get", "v1/subscription/payment-method", {}, {}, true, false, accessApiUrl);
}

function updatePaymentMethod(paymentMethodId) {
    return apiRequest("put", "v1/subscription/payment-method", {paymentMethodId}, {}, true, false, accessApiUrl);
}

function updateBillingDetails(data) {
    return apiRequest("put", "v1/subscription/billing-details", data, {}, true, false, accessApiUrl);
}

function getBillingDetails() {
    return apiRequest("get", "v1/subscription/billing-details", {}, {}, true, false, accessApiUrl);
}

function retryInvoice(paymentMethodId) {
    return apiRequest("post", "v1/subscription/retry-invoice", {paymentMethodId}, {}, true, false, accessApiUrl);
}

function updateSubscriptionStatus() {
    return apiRequest("put", "v1/subscription/status", {}, {}, true, false, accessApiUrl);
}

function getInvoices() {
    return apiRequest("get", "v1/subscription/invoices", {}, {}, true, false, accessApiUrl);
}

function verifyCoupon(coupon) {
    return apiRequest("get", "v1/subscription/coupon/" + coupon, {}, {}, true, false, accessApiUrl);
}

function applyCouponToSubscription(coupon) {
    return apiRequest("post", "v1/subscription/coupon", {coupon}, {}, true, false, accessApiUrl);
}

function skroPostback({clickId, payout}) {
    return apiRequest("get", "/postback", {}, {clickId, payout}, true, false, "https://goskro.click");
}