export const websiteBucketsConstants = {
    WEBSITE_BUCKETS_LOADING: 'WEBSITE_BUCKETS_LOADING',
    WEBSITE_BUCKETS_LOADING_ERROR: 'WEBSITE_BUCKETS_LOADING_ERROR',
    WEBSITE_BUCKETS_SET_IS_LOADING: 'WEBSITE_BUCKETS_SET_IS_LOADING',
    WEBSITE_BUCKETS_UPDATE_ORDER_BY: 'WEBSITE_BUCKETS_UPDATE_ORDER_BY',
    WEBSITE_BUCKETS_UPDATE_SORT_BY: 'WEBSITE_BUCKETS_UPDATE_SORT_BY',
    WEBSITE_BUCKETS_UPDATE_BUCKET: 'WEBSITE_BUCKETS_UPDATE_BUCKET',
    WEBSITE_BUCKETS_UPDATE_SORT_COLUMNS: 'WEBSITE_BUCKETS_UPDATE_SORT_COLUMNS',
    WEBSITE_BUCKETS_INFINITY_SCROLL: 'WEBSITE_BUCKETS_INFINITY_SCROLL',
    WEBSITE_BUCKETS_RERENDER: 'WEBSITE_BUCKETS_RERENDER',
    WEBSITE_BUCKETS_DELETE_BUCKET: 'WEBSITE_BUCKETS_DELETE_BUCKET',
    WEBSITE_BUCKETS_CREATE_BUCKET: 'WEBSITE_BUCKETS_CREATE_BUCKET',
    WEBSITE_BUCKETS_UPLOAD_FILES: 'WEBSITE_BUCKETS_UPLOAD_FILES',
    WEBSITE_BUCKETS_UPDATE_UPLOADED_FILES_STATUS: 'WEBSITE_BUCKETS_UPDATE_UPLOADED_FILES_STATUS',
    WEBSITE_BUCKETS_DEPLOY_WEBSITE: 'WEBSITE_BUCKETS_DEPLOY_WEBSITE',
    WEBSITE_BUCKETS_UPDATE_DEPLOYMENT_STATUS: 'WEBSITE_BUCKETS_UPDATE_DEPLOYMENT_STATUS'
};
