import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import {trackingDomainServices} from "../../service/trackingDomainService";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Chip, TextField} from "@mui/material";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

function TrackingDomainMultiSelect({control, inputLabel, inputName}) {

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await trackingDomainServices.read();
                setOptions(
                    response.trackingDomains.map(item => {
                        return {
                            value: item.id,
                            label: item.domain
                        }
                    })
                );
            } catch (e) {
                console.log(e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();

    }, []);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    multiple
                    disableCloseOnSelect={true}
                    id="tracking-domain-multi-select"
                    limitTags={3}
                    isOptionEqualToValue={(option, value) => option?.label === value?.label}
                    getOptionLabel={(option) => option?.label}
                    options={options}
                    loading={loading}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                                color="primary"
                                size="small"
                            />
                            {option?.label}
                        </li>
                    )}
                    renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                            <Chip variant="outlined" color="primary" size="small"
                                  label={option?.label} {...getTagProps({index})} />
                        ))
                    }
                    renderInput={params => (
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    label={inputLabel}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name={inputName}
                                    placeholder="Select tracking domains"
                                />
                            </Box>
                        </Box>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            defaultValue={[]}
            name={inputName}
            control={control}
            onChange={([, data]) => data}
        />
    );
}

TrackingDomainMultiSelect.propTypes = {
    control: PropTypes.object.isRequired,
    inputLabel: PropTypes.string,
    inputName: PropTypes.string,
};

TrackingDomainMultiSelect.defaultProps = {
    control: {},
    inputName: "trackingDomain",
    inputLabel: "Select tracking domain",
};

export default TrackingDomainMultiSelect;