import {apiRequest} from "../helpers/utils";

export const landingServices = {
    create,
    update,
    archive,
    find,
    restore,
    read,
    bulkUpdate,
    bulkUpdateForUrl
};

function read(name, countryCode, includeGlobalCountries) {
    let params = {
        name,
        countryCode,
        isDeleted: false,
        pageSize: 250,
        includeGlobalCountries
    };
    return apiRequest("get", "v1/landing", {}, params);
}

function create(formData) {
    return apiRequest("post", "v1/landing", formData);
}

function update(id, formData) {
    return apiRequest("put", "v1/landing/" + parseInt(id), formData);
}

function archive(formData) {
    return apiRequest("post", "v1/landing/archive", formData);
}

function restore(formData) {
    return apiRequest("post", "v1/landing/restore", formData);
}

function find(id) {
    return apiRequest("get", "v1/landing/" + parseInt(id));
}

function bulkUpdate(formData) {
    return apiRequest("patch", "v1/landing", formData);
}

function bulkUpdateForUrl(formData) {
    return apiRequest("patch", "v1/landing/url", formData);
}
