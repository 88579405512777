import axios from 'axios';
import {authServices} from "../service/authService";
import {adsManagerApiUrl} from "./config";

const apiRequestCreator = () => {
    //let call;
    return async (method, path, data, params, useToken = true, useCancelToken = false, baseUrl = adsManagerApiUrl) => {
        // Check if we made a request
        /*if (call) {
            // Cancel the previous request before making a new request
            if (useCancelToken) {
                call.cancel()
            }
        }*/
        // Create a new CancelToken
        //call = axios.CancelToken.source();

        let accessToken;
        if (useToken) {
            accessToken = localStorage.getItem('access-token')
        }

        try {
            const response = await axios({
                method: method,
                baseURL: baseUrl,
                url: path,
                params: params,
                data: data,
                //cancelToken: call.token,
                headers: {
                    'Authorization': accessToken
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            if (error?.response?.status === 401) {
                authServices.logout();
                document.location = "/login";
                return Promise.reject({
                    response: {
                        data: {
                            error: "Session has expired."
                        }
                    }
                });
            }

            if (!error?.response?.status) {
                return Promise.reject({
                    response: {
                        data: {
                            error: "Network error. Please check your Internet connection."
                        }
                    }
                })
            }

            return Promise.reject(error);
        }
    }
};

export const apiRequest = apiRequestCreator();

const formDataApiRequestCreator = () => {
    return async (method, path, data, params, useToken = true, useCancelToken = false, baseUrl = adsManagerApiUrl) => {
        let accessToken;
        if (useToken) {
            accessToken = localStorage.getItem('access-token')
        }

        try {
            const response = await axios({
                method: method,
                baseURL: baseUrl,
                url: path,
                params: params,
                data: data,
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'multipart/form-data',
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            if (error?.response?.status === 401) {
                authServices.logout();
                document.location = "/login";
                return Promise.reject({
                    response: {
                        data: {
                            error: "Session has expired."
                        }
                    }
                });
            }

            if (!error?.response?.status) {
                return Promise.reject({
                    response: {
                        data: {
                            error: "Network error. Please check your Internet connection."
                        }
                    }
                })
            }

            return Promise.reject(error);
        }
    }
};

export const apiRequestWithFormData = formDataApiRequestCreator();