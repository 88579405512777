export const groupBy3Constants = {
    GROUP_BY_3_LOADING_SPINNER: 'GROUP_BY_3_LOADING_SPINNER',
    GROUP_BY_3_STOP_LOADING_SPINNER: 'GROUP_BY_3_STOP_LOADING_SPINNER',
    GROUP_BY_3_INFINITY_SCROLL: 'GROUP_BY_3_INFINITY_SCROLL',
    GROUP_BY_3_GET_DATA: 'GROUP_BY_3_GET_DATA',
    GROUP_BY_3_ERROR: 'GROUP_BY_3_ERROR',
    GROUP_BY_3_UPDATE_ORDER_BY: 'GROUP_BY_3_UPDATE_ORDER_BY',
    GROUP_BY_3_UPDATE_SORT_BY: 'GROUP_BY_3_UPDATE_SORT_BY',
    GROUP_BY_3_UPDATE_SORT_COLUMNS: 'GROUP_BY_3_UPDATE_SORT_COLUMNS',
    GROUP_BY_3_RERENDER: 'GROUP_BY_3_RERENDER',
    GROUP_BY_3_SET_SELECTED_ROW: 'GROUP_BY_3_SET_SELECTED_ROW',
    GROUP_BY_3_UPDATE_GROUP_BY: 'GROUP_BY_3_UPDATE_GROUP_BY',
    GROUP_BY_3_RESET: 'GROUP_BY_3_RESET'
};
