import {reportServices} from "../../service/reportService";
import {flowConstants} from "../constants/flowConstants";
import {alertActions} from "./alertActions";
import {flowServices} from "../../service/flowService";
import ExportCSV from "../../components/export/ExportCSV";

export const flowActions = {
    getReport,
    archive,
    restore,
    loadMoreRows,
    updateName,
    updateTags,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    updateSharedFlow,
    changeWorkspace
};

function loadMoreRows(groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, metricFilters, columns, workspace) {
    return dispatch => {
        dispatch({type: flowConstants.FLOWS_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        reportServices.getReport(
            {
                groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode,
                metricFilters, columns, workspace
            }
        )
            .then((result) => {
                dispatch({type: flowConstants.FLOWS_INFINITY_SCROLL, result});
            }).catch((error) => {
            dispatch({type: flowConstants.FLOWS_STOP_LOADING_SPINNER});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getReport(groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, exportData, metricFilters, columns, workspace) {
    return dispatch => {
        dispatch({type: flowConstants.FLOWS_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        reportServices.getReport(
            {
                groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, exportData,
                metricFilters, columns, workspace
            }
        )
            .then((result) => {
                if (exportData === "csv") {
                    ExportCSV({fileName: "flows_" + from + "_to_" + to, data: result});
                    dispatch({type: flowConstants.FLOWS_STOP_LOADING_SPINNER});
                } else {
                    dispatch({type: flowConstants.FLOWS_GET_DATA, result});
                }
            }).catch((error) => {
            dispatch({type: flowConstants.FLOWS_STOP_LOADING_SPINNER});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function archive(ids) {
    return dispatch => {
        dispatch({type: flowConstants.FLOWS_LOADING_SPINNER});
        flowServices.archive(ids)
            .then(() => {
                dispatch({type: flowConstants.FLOWS_ARCHIVE});
            }).catch((error) => {
                dispatch({type: flowConstants.FLOWS_ERROR, error});
            }
        );
    };
}

function restore(ids) {
    return dispatch => {
        dispatch({type: flowConstants.FLOWS_LOADING_SPINNER});
        flowServices.restore(ids)
            .then(() => {
                dispatch({type: flowConstants.FLOWS_RESTORE});
            }).catch((error) => {
                dispatch({type: flowConstants.FLOWS_ERROR, error});
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: flowConstants.FLOWS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: flowConstants.FLOWS_UPDATE_SORT_BY, sortBy});
    };
}

function updateName(name) {
    return (dispatch) => {
        dispatch({type: flowConstants.FLOWS_UPDATE_NAME, name});
    };
}

function updateTags(tags) {
    return (dispatch) => {
        dispatch({type: flowConstants.FLOWS_UPDATE_TAGS, tags});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: flowConstants.FLOWS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: flowConstants.FLOWS_RERENDER});
    };
}

function updateSharedFlow(flow) {
    return (dispatch) => {
        dispatch({type: flowConstants.FLOWS_UPDATE_SHARED_FLOW, payload: {flow}});
    };
}

function changeWorkspace(data) {
    return dispatch => {
        dispatch({type: flowConstants.FLOWS_LOADING_SPINNER});
        flowServices.bulkUpdate(data)
            .then(() => {
                dispatch({type: flowConstants.BULK_UPDATE_CHANGE_WORKSPACE});
                dispatch(alertActions.success("Workspace is successfully updated."));
            }).catch((error) => {
                dispatch(alertActions.error("Changing workspace failed: " + error?.response?.data?.error));
            }
        );
    };
}
