import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./store/reducers/rootReducer";
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import authMiddleware from "./components/auth/AuthMiddleware";
import {loadStripe} from "@stripe/stripe-js";
import {GA4MeasurementID, stripePK} from "./helpers/config";
import {Elements} from "@stripe/react-stripe-js";
import ReactGA4 from "react-ga4";

(async () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(applyMiddleware(thunkMiddleware)));
    const stripePromise = loadStripe(stripePK);

    authMiddleware(store);

    ReactGA4.initialize(GA4MeasurementID);

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <Elements stripe={stripePromise}>
                <Provider store={store}>
                    <App/>
                </Provider>
            </Elements>
        </React.StrictMode>
    );

    serviceWorker.unregister();
})();
