import {apiRequest} from "../helpers/utils";

export const websiteDomainsServices = {
    read,
    verifyDomain,
    getDomainVerifyRecords,
    deleteDomainAndCert,
    addDomain,
    getOnlyVerifiedDomains
};

function read(domain, page, orderBy, sortBy, workspace) {
    let params = {
        domain,
        page,
        orderBy,
        sortBy,
        workspace,
        pageSize: 100
    };
    return apiRequest("get", "v1/aws/domain", {}, params);
}

function getOnlyVerifiedDomains(domain, status) {
    let params = {
        domain,
        status,
        pageSize: 100
    };
    return apiRequest("get", "v1/aws/domain", {}, params);
}

function addDomain(formData) {
    return apiRequest("post", "v1/aws/domain/add", formData);
}

function verifyDomain(id) {
    return apiRequest("post", "v1/aws/domain/" + parseInt(id) + "/verify", {});
}

function getDomainVerifyRecords(id) {
    return apiRequest("get", "v1/aws/domain/" + parseInt(id), {});
}

function deleteDomainAndCert(id) {
    return apiRequest("delete", "v1/aws/domain/" + parseInt(id), {});
}