import React, {useEffect, useState} from "react";
import {Box, Button, IconButton, useTheme} from "@mui/material";
import {campaignFormActions} from "../../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import ReactHookFormChipInput from "../../input/ReactHookFormChipInput";
import ReactHookFormCheckbox from "../../input/ReactHookFormCheckbox";
import ReactHookFormRadio from "../../input/ReactHookFormRadio";
import {Add, Delete} from "@mui/icons-material";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        marginTop: theme.spacing(1),
        padding: "12px 10px",
        backgroundColor: theme.palette.background.darkBlue,
        borderRadius: 4
    },
    title: {
        paddingTop: 4,
        fontSize: 14,
        fontWeight: 600
    },
    radio: {
        '&$checked': {
            color: theme.palette.error.light
        }
    },
    checked: {},
    deleteButton: {
        color: theme.palette.error.dark
    },
    addConditionButton: {
        textTransform: "none",
        padding: "2px 8px"
    }
});

const CustomInputs = [
    {
        defaultValues: {
            custom1Condition: "is",
            custom1IncludeEmptyValue: false,
            custom1Values: []
        },
        label: "Custom 1",
        conType: "custom1",
        condition: "custom1Condition",
        includeEmptyValue: "custom1IncludeEmptyValue",
        values: "custom1Values",
    },
    {
        defaultValues: {
            custom2Condition: "is",
            custom2IncludeEmptyValue: false,
            custom2Values: []
        },
        label: "Custom 2",
        conType: "custom2",
        condition: "custom2Condition",
        includeEmptyValue: "custom2IncludeEmptyValue",
        values: "custom2Values",
    },
    {
        defaultValues: {
            custom3Condition: "is",
            custom3IncludeEmptyValue: false,
            custom3Values: []
        },
        label: "Custom 3",
        conType: "custom3",
        condition: "custom3Condition",
        includeEmptyValue: "custom3IncludeEmptyValue",
        values: "custom3Values",
    },
    {
        defaultValues: {
            custom4Condition: "is",
            custom4IncludeEmptyValue: false,
            custom4Values: []
        },
        label: "Custom 4",
        conType: "custom4",
        condition: "custom4Condition",
        includeEmptyValue: "custom4IncludeEmptyValue",
        values: "custom4Values",
    },
    {
        defaultValues: {
            custom5Condition: "is",
            custom5IncludeEmptyValue: false,
            custom5Values: []
        },
        label: "Custom 5",
        conType: "custom5",
        condition: "custom5Condition",
        includeEmptyValue: "custom5IncludeEmptyValue",
        values: "custom5Values",
    },
    {
        defaultValues: {
            custom6Condition: "is",
            custom6IncludeEmptyValue: false,
            custom6Values: []
        },
        label: "Custom 6",
        conType: "custom6",
        condition: "custom6Condition",
        includeEmptyValue: "custom6IncludeEmptyValue",
        values: "custom6Values",
    },
    {
        defaultValues: {
            custom7Condition: "is",
            custom7IncludeEmptyValue: false,
            custom7Values: []
        },
        label: "Custom 7",
        conType: "custom7",
        condition: "custom7Condition",
        includeEmptyValue: "custom7IncludeEmptyValue",
        values: "custom7Values",
    },
    {
        defaultValues: {
            custom8Condition: "is",
            custom8IncludeEmptyValue: false,
            custom8Values: []
        },
        label: "Custom 8",
        conType: "custom8",
        condition: "custom8Condition",
        includeEmptyValue: "custom8IncludeEmptyValue",
        values: "custom8Values",
    },
    {
        defaultValues: {
            custom9Condition: "is",
            custom9IncludeEmptyValue: false,
            custom9Values: []
        },
        label: "Custom 9",
        conType: "custom9",
        condition: "custom9Condition",
        includeEmptyValue: "custom9IncludeEmptyValue",
        values: "custom9Values",
    },
    {
        defaultValues: {
            custom10Condition: "is",
            custom10IncludeEmptyValue: false,
            custom10Values: []
        },
        label: "Custom 10",
        conType: "custom10",
        condition: "custom10Condition",
        includeEmptyValue: "custom10IncludeEmptyValue",
        values: "custom10Values",
    }
];

function CustomConditions(props) {
    return (
        CustomInputs.map((obj) => {
            return (
                <div key={obj?.conType}>
                    {CustomCondition(props, obj)}
                </div>
            );
        })
    );
}

function CustomCondition(props, obj) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const [customInputLabel, setCustomInputLabel] = useState("");
    const {campaignFormState: {flow, selectedRuleNumber, selectedRule, trafficSource}} = props;

    const {control, setValue, reset, getValues} = useForm({
        defaultValues: obj?.defaultValues
    });

    const radioConditions = [
        {label: "Is", value: "is", color: "primary"},
        {label: "Is not", value: "isNot", color: "error"},
        {label: "Contains", value: "contains", color: "primary"},
        {label: "Not contain", value: "notContain", color: "error"},
    ];

    useEffect(() => {
        let hasCon = false;
        for (const [k] of Object.entries(selectedRule?.conditions)) {
            if (k === obj?.conType) {
                hasCon = true;
            }
        }

        if (!hasCon) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRule, selectedRuleNumber]);

    useEffect(() => {
        if (customInputLabel === "" && trafficSource !== null) {
            for (const [key, value] of Object.entries(trafficSource)) {
                if (key === obj?.conType) {
                    if (value !== "") {
                        setCustomInputLabel(obj?.label + ": (" + value + ")");
                    } else {
                        setCustomInputLabel(obj?.label);
                    }
                }
            }
        } else if (customInputLabel === "" && trafficSource === null) {
            setCustomInputLabel(obj?.label);
        }
    }, [obj?.conType, obj?.label, trafficSource, customInputLabel]);

    useEffect(() => {
        if (selectedRule !== null) {
            for (const [k, v] of Object.entries(flow?.rules[selectedRuleNumber]?.conditions)) {
                if (k === obj?.conType) {
                    setValue(obj?.condition, v?.condition);
                    setValue(obj?.includeEmptyValue, v?.includeEmptyValue);
                    setValue(obj?.values, v?.values !== null ? v.values : []);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRuleNumber, selectedRule, flow.rules]);

    const updateCondition = () => {
        props.updateCondition(
            props.campaignFormState.selectedRuleNumber,
            obj?.conType,
            getValues(obj?.condition),
            getValues(obj?.includeEmptyValue),
            getValues(obj?.values)
        );
    };

    const removeCondition = () => {
        props.updateCondition(props.campaignFormState.selectedRuleNumber, obj?.conType, "", false, null);
    };

    const addCondition = () => {
        props.updateCondition(props.campaignFormState.selectedRuleNumber, obj?.conType, "is", false, []);
    };

    const renderAddCondition = () => {
        let condition = selectedRule?.conditions[obj.conType]?.condition;
        if (condition === "" || condition === undefined) {
            return (
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1} className={classes?.title}>
                        <span>{customInputLabel}</span>
                    </Box>
                    <Box>
                        <Button
                            aria-label="condition"
                            color="primary"
                            title="Add condition"
                            startIcon={<Add/>}
                            className={classes?.addConditionButton}
                            onClick={addCondition}
                        >
                            Add condition
                        </Button>
                    </Box>
                </Box>
            );
        }
    };

    const renderConditionBox = () => {
        let condition = selectedRule?.conditions[obj.conType]?.condition;
        if (condition !== "" && condition !== undefined) {
            return (
                <>
                    <Box mb={1} display="flex" flexDirection="row">
                        <Box flexGrow={1} className={classes?.title}>{customInputLabel}</Box>
                        <Box>
                            <IconButton
                                aria-label="remove"
                                color="default"
                                title="Remove"
                                size={"small"}
                                className={classes?.deleteButton}
                                onClick={removeCondition}
                            >
                                <Delete fontSize="small"/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <ReactHookFormRadio
                            defaultValue="is"
                            control={control}
                            options={radioConditions}
                            name={obj.condition}
                            label="conditions"
                            updateOnChange={updateCondition}
                        />
                    </Box>
                    <Box>
                        <ReactHookFormCheckbox
                            control={control}
                            label="Include empty value"
                            labelPlacement="end"
                            name={obj.includeEmptyValue}
                            updateOnChange={updateCondition}
                        />
                    </Box>
                    <Box mt={1}>
                        <ReactHookFormChipInput
                            control={control}
                            values={getValues(obj.values)}
                            name={obj.values}
                            label={obj.label}
                            updateOnChange={updateCondition}
                        />
                    </Box>
                </>
            );
        }
    };

    return (
        <Box display="flex" flexDirection="column" className={classes?.root}>
            {renderAddCondition()}
            {renderConditionBox()}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    updateCondition: campaignFormActions.updateCondition,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomConditions);