export const trafficSourceConstants = {
	TRAFFIC_SOURCES_LOADING_SPINNER: 'TRAFFIC_SOURCES_LOADING_SPINNER',
	TRAFFIC_SOURCES_STOP_LOADING_SPINNER: 'TRAFFIC_SOURCES_STOP_LOADING_SPINNER',
	TRAFFIC_SOURCES_INFINITY_SCROLL: 'TRAFFIC_SOURCES_INFINITY_SCROLL',
	TRAFFIC_SOURCES_GET_DATA: 'TRAFFIC_SOURCES_GET_DATA',
	TRAFFIC_SOURCES_ERROR: 'TRAFFIC_SOURCES_ERROR',
	TRAFFIC_SOURCES_UPDATE_ORDER_BY: 'TRAFFIC_SOURCES_UPDATE_ORDER_BY',
	TRAFFIC_SOURCES_UPDATE_SORT_BY: 'TRAFFIC_SOURCES_UPDATE_SORT_BY',
	TRAFFIC_SOURCES_UPDATE_NAME: 'TRAFFIC_SOURCES_UPDATE_NAME',
	TRAFFIC_SOURCES_UPDATE_TAGS: 'TRAFFIC_SOURCES_UPDATE_TAGS',
	TRAFFIC_SOURCES_UPDATE_SORT_COLUMNS: 'TRAFFIC_SOURCES_UPDATE_SORT_COLUMNS',
	TRAFFIC_SOURCES_RERENDER: 'TRAFFIC_SOURCES_RERENDER',
	TRAFFIC_SOURCES_ARCHIVE: 'TRAFFIC_SOURCES_ARCHIVE',
	TRAFFIC_SOURCES_RESTORE: 'TRAFFIC_SOURCES_RESTORE',
	BULK_UPDATE_CHANGE_WORKSPACE: 'BULK_UPDATE_CHANGE_WORKSPACE'
};
