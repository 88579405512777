import React, {useEffect, useState} from "react";
import Topbar from "../../layout/Topbar";
import SettingsSidebar from "../SettingsSidebar";
import {connect} from "react-redux";
import CustomSnackbar from "../../alert/CustomSnackbar";
import {
    Box,
    Button, CssBaseline,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Link, useTheme
} from "@mui/material";
import Spinner from "../../spinner/Spinner";
import moment from "moment-timezone";
import {apiAccessActions} from "../../../store/actions/apiAccessAction";
import CopyContentToClipboard from "../../utils/CopyContentToClipboard";
import {Delete} from "@mui/icons-material";
import CreateAccessTokenForm from "./CreateAccessTokenForm";
import {adsManagerPublicAPIDoc} from "../../../helpers/config";
import {SettingsContentBox, SettingsRoot} from "../General";
import {SettingsGeneralHeader} from "../ProfileSettings";

function ApiAccess(props) {
    const theme = useTheme();

    const {apiAccess} = props.state;

    const [open, setOpen] = useState(false);
    const handleOnClose = () => setOpen(false);
    const handleOnClick = () => setOpen(true);

    useEffect(() => {
        if (apiAccess?.reload) {
            props.fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiAccess?.reload]);

    useEffect(() => {
        props.fetch(); // load only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <Box display="flex" flexDirection="column" flexWrap="noWrap" sx={{
                    width: "100%",
                    padding: "16px 30px 0 30px",
                    maxWidth: "1280px",
                    minWidth: "300px",
                    margin: "0 auto",
                    [theme.breakpoints.down(600)]: {
                        padding: 0
                    }
                }}>
                    {props.state.alert.errorMsg !== "" &&
                        <CustomSnackbar message={props.state.alert.errorMsg}/>
                    }
                    {props.state.alert.successMsg !== "" &&
                        <CustomSnackbar message={props.state.alert.successMsg} severity="success"/>
                    }

                    <SettingsGeneralHeader>API Access</SettingsGeneralHeader>

                    <Box>
                        <Paper sx={{minHeight: 200, boxShadow: 0, padding: 2}} elevation={0}>
                            <Box display="flex" flexDirection="row" flexWrap="noWrap">
                                <Box
                                    flexGrow={1}
                                    sx={{
                                        fontSize: 16,
                                        lineHeight: 1.167,
                                        textAlign: "left",
                                        fontWeight: 700,
                                        paddingTop: 2,
                                        paddingBottom: 2
                                    }}
                                >
                                    Access Tokens
                                </Box>
                                <Box pt={1}>
                                    <CreateAccessTokenForm onClose={handleOnClose} open={open}/>
                                    <Button variant="contained" color="primary" onClick={handleOnClick}>
                                        Create
                                    </Button>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" flexWrap="noWrap"
                                 sx={{color: "text.disabledLight"}}>
                                <Box>
                                    Use our API to manage campaigns, traffic sources, aff networks, offers,
                                    landings etc. Get detailed statistics of your campaigns. <br/> You can create up
                                    to 10 access tokens with an expiration date of 1 year.
                                </Box>
                                <Box mt={1}>
                                    <span style={{paddingRight: 4}}>Public API documentation is</span>
                                    <Link target="_blank" underline="hover" href={`${adsManagerPublicAPIDoc}`}
                                          rel="noreferrer">
                                        here
                                    </Link>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1} sx={{overflow: "auto"}}>
                                <Box mt={1} flexGrow={1}>

                                    {apiAccess?.loading?.keys && <Spinner overComponentBox={true}/>}

                                    <TableContainer sx={{maxHeight: "400px", overflow: "auto", width: "100%"}}>
                                        <Table stickyHeader size="small">
                                            <TableHead sx={{whiteSpace: "nowrap"}}>
                                                <TableRow hover>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="left">Key</TableCell>
                                                    <TableCell align="left">Created</TableCell>
                                                    <TableCell align="left">Expires</TableCell>
                                                    <TableCell align="left">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {apiAccess?.keys?.length > 0 &&
                                                <TableBody sx={{whiteSpace: "nowrap", maxHeight: "300px"}}>
                                                    {apiAccess?.keys?.map((row) => (
                                                        <TableRow key={row?.id} sx={{p: 0, m: 0}}>
                                                            <TableCell component="th" scope="row">
                                                                {row?.name}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{
                                                                minWidth: 350,
                                                                maxWidth: 400,
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}>
                                                                <CopyContentToClipboard copyText={row?.jwt}/>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {moment.utc(row?.createdAt).format("YYYY-MM-DD HH:mm:ss")} (UTC)
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {moment.utc(row?.expiresAt).format("YYYY-MM-DD HH:mm:ss")} (UTC)
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="Revoke">
                                                                    <Delete
                                                                        color="error"
                                                                        fontSize="small"
                                                                        onClick={() => props?.remove(row?.id)}
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            }
                                        </Table>
                                        {apiAccess?.keys?.length === 0 &&
                                            <Box textAlign="center" sx={{pt: 2}}>
                                                No records
                                            </Box>
                                        }
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </SettingsContentBox>
        </SettingsRoot>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            alert: state.alert,
            apiAccess: state.apiAccess,
        }
    };
};

const mapDispatchToProps = {
    fetch: apiAccessActions.fetch,
    remove: apiAccessActions.revoke,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiAccess);