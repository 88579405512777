import {alertActions} from "./alertActions";
import {personConstants} from "../constants/personConstants";
import {settingsServices} from "../../service/settingsService";

export const personActions = {
    fetch
};

function fetch() {
    return dispatch => {
        dispatch({type: personConstants.PERSON_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        settingsServices.fetchProfileData()
            .then((person) => {
                dispatch({type: personConstants.PERSON_FETCH, person});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}