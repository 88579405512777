import {apiAccessConstants} from "../constants/apiAccessConstants";

const initialState = {
    keys: [],
    reload: false,
    loading: {
        keys: false,
    }
};

const apiAccessReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case apiAccessConstants.API_KEYS_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    keys: action?.payload?.loading
                }
            };
        case apiAccessConstants.API_KEYS_FETCH:
            return {
                ...state,
                keys: action?.result,
                reload: false,
                loading: {
                    ...state.loading,
                    keys: false
                }
            };
        case apiAccessConstants.API_KEYS_REVOKE:
            return {
                ...state,
                keys: state.keys.filter((key) => key?.id !== action?.payload?.id),
                loading: {
                    ...state.loading,
                    keys: false
                }
            };
        case apiAccessConstants.API_KEYS_RELOAD:
            return {
                ...state,
                reload: true
            };
        default:
            return state;
    }
};

export default apiAccessReducer;