import React, {useEffect} from "react";
import {Paper, useTheme} from "@mui/material";
import {connect} from "react-redux";
import Spinner from "../../spinner/Spinner";
import {cloakingDomainAction} from "../../../store/actions/cloakingDomainAction";
import CloakingDomainsList from "./CloakingDomainsList";
import AddCloakingDomain from "./AddCloakingDomain";
import useCustomStyles from "../../utils/UseCustomStyles";
import CustomSnackbar from "../../alert/CustomSnackbar";

const customStyles = (theme) => ({
    root: {
        width: "100%",
        padding: "20px 30px",
        [theme.breakpoints.up('lg')]: {
            padding: "0px 30px 20px 15px",
        },
        [theme.breakpoints.down(600)]: {
            padding: 0,
        },
    },
    paper: {
        position: "relative",
        padding: 12,
        minHeight: 200,
        minWidth: 300,
        maxWidth: "100%",
        backgroundColor: theme.palette.background.defaultBlue
    },
    header: {
        fontSize: 18,
        paddingBottom: 8,
        [theme.breakpoints.down(600)]: {
            paddingLeft: 16,
            paddingTop: 15
        },
    },
    email: {
        padding: "20px 12px 20px 12px",
        fontSize: 16
    },
    actions: {
        flex: "0 0 auto",
        display: "flex",
        padding: 8,
        alignItems: "center",
        justifyContent: "flex-end"
    }
});


const CloakingDomains = (props) => {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const {loadCloakingDomains} = props;
    const {errorMsg, successMsg, cloakingDomains, isLoading} = props?.state;

    useEffect(() => {
        loadCloakingDomains();
    }, [loadCloakingDomains]);

    return (
        <div className={classes?.root}>
            <h3 className={classes?.header}>Cloaking Domains</h3>
            <Paper className={classes?.paper} elevation={0}>
                {isLoading && <Spinner size={32} overComponentBox={true}/>}

                {errorMsg !== "" && <CustomSnackbar timeout={2500} message={errorMsg}/>}
                {successMsg !== "" && <CustomSnackbar timeout={2500} message={successMsg} severity="success"/>}

                <AddCloakingDomain {...props}/>

                <CloakingDomainsList data={cloakingDomains} title="Domain with HTTPS"/>
            </Paper>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        state: state.cloakingDomain
    }
};

const mapDispatchToProps = {
    loadCloakingDomains: cloakingDomainAction.fetch,
    addAndVerify: cloakingDomainAction.addAndVerify,
    clearErrors: cloakingDomainAction.clearErrors,
    setActiveStep: cloakingDomainAction.setStep,
    setCloakingDomain: cloakingDomainAction.setCloakingDomain
};

export default connect(mapStateToProps, mapDispatchToProps)(CloakingDomains);