import React from 'react';
import {TextField, Button, StepContent, StepLabel, Stepper, Step, Alert, useTheme} from "@mui/material";
import {useForm} from "react-hook-form";
import CopyContentToClipboard from "../../utils/CopyContentToClipboard";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        paddingBottom: "20px",
    },
});

function getSteps() {
    return ['Enter your custom domain', 'Set up a CNAME', 'Add & Verify DNS'];
}

export default function AddTrackingDomain(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {register, handleSubmit, formState: {errors}} = useForm();

    const {activeStep, trackingDomain} = props?.state;
    const {setActiveStep, setTrackingDomain} = props;

    const steps = getSteps();

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const onSubmit = (formData) => {
        setTrackingDomain(formData?.trackingDomain);
        setActiveStep(activeStep + 1);
    };

    const handleAddAndVerify = () => {
        props.addAndVerify(trackingDomain);
    };

    return (
        <div className={classes?.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps?.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            {index === 0 &&
                                <div>
                                    <p>
                                        If you don't have a domain, it is required to purchase one and make sure that
                                        this
                                        domain is ready. You can buy your domain through any domain registrar, e.g.
                                        <strong> GoDaddy.com</strong>, <strong>NameCheap.com</strong> or <strong>CloudFlare.com</strong>.
                                    </p>
                                    <TextField
                                        error={!!errors.trackingDomain}
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        id="trackingDomain"
                                        {...register("trackingDomain", {
                                            required: true,
                                            pattern: /^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,10})$/,
                                            maxLength: 255
                                        })}
                                        name="trackingDomain"
                                        type="text"
                                        placeholder="my-custom-domain.com"
                                        fullWidth={true}
                                        defaultValue={trackingDomain}
                                    />
                                </div>
                            }

                            {index === 1 &&
                                <div>
                                    <p>Create a CNAME record for your subdomain.</p>
                                    <p>For example: if your subdomain is <strong>go.example.com</strong>, then set
                                        HOST <strong>go</strong> and TARGET points to our tracking domain:</p>
                                    <CopyContentToClipboard label="" copyText="skrotrack.com"/>
                                    <Alert severity="info" variant="filled" style={{marginTop: 16, marginBottom: 16}}>
                                        If your are using CloudFlare domain, then you will need to
                                        create a CNAME record to your subdomain and turn proxy off.
                                    </Alert>
                                </div>
                            }

                            {index === 2 &&
                                <div>
                                    <p>
                                        Please note that updating DNS records may take up to 24 hours, but usually it
                                        takes 15-20 minutes.
                                    </p>
                                </div>
                            }

                            <div className={classes?.actionsContainer}>
                                <div>
                                    {activeStep > 0 && activeStep < 3 &&
                                        <Button onClick={handleSubmit(handleBack)} className={classes?.button}>
                                            Back
                                        </Button>
                                    }
                                    {activeStep === 0 &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit(onSubmit)}
                                            className={classes?.button}
                                        >
                                            Next
                                        </Button>
                                    }
                                    {activeStep === 1 &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes?.button}
                                        >
                                            Next
                                        </Button>
                                    }
                                    {activeStep === 2 && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit(handleAddAndVerify)}
                                            className={classes?.button}
                                        >
                                            Add & Verify
                                        </Button>)
                                    }
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
