import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import {trackingDomainServices} from "../../service/trackingDomainService";
import {Autocomplete, Box, TextField} from "@mui/material";
import {Info} from "@mui/icons-material";
import {CustomTooltip} from "../landings/LandingForm";

export default function TrackingDomainSelect({control, errors, setValue, watch}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState(null);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await trackingDomainServices.read(search);
                setOptions(
                    response.trackingDomains.map(item => {
                        return {
                            value: item.id,
                            label: item.domain
                        }
                    })
                );
            } catch (e) {
                console.log(e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData();
        }

    }, [search, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    useEffect(() => {
        if (defaultValue === null && (watch("trackingDomain") === null || watch("trackingDomain") === undefined)) {
            setLoading(true);
            let userSettings = JSON.parse(localStorage.getItem("user-settings"));
            if (userSettings?.defaultTrackingDomain !== "") {
                trackingDomainServices.read(userSettings?.defaultTrackingDomain).then((response) => {
                    response?.trackingDomains?.forEach(item => {
                        if (item?.domain === userSettings?.defaultTrackingDomain) {
                            setDefaultValue({
                                value: item?.id,
                                label: item?.domain
                            });
                            setValue("trackingDomain", {
                                value: item?.id,
                                label: item?.domain
                            });
                        }
                    })
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }, [watch, setValue, defaultValue]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    sx={{paddingTop: "4px"}}
                    id="tracking-domain-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <Box display="flex" sx={{paddingTop: "10px"}}>
                            <Box flexGrow={1}>
                                <TextField
                                    {...params}
                                    error={!!errors.trackingDomain}
                                    placeholder="Select a tracking domain"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    label="Tracking domain*"
                                    value={search}
                                    onChange={e => handleSearch(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box p={1}>
                                <Box sx={{paddingTop: "10px"}}>
                                    <CustomTooltip
                                        disableFocusListener
                                        title="Choose domain to run your campaign. We highly recommend to add your custom domains.
                                             Our provided default domains are meant for testing purposes only and is not recommended to use in production.
                                             Add custom domains in Settings view."
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={{required: true}}
            name="trackingDomain"
            control={control}
            defaultValue={defaultValue}
            onChange={([, data]) => data}
        />
    );
}
