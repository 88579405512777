import React, {useCallback, useEffect} from "react";
import {connect} from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme,
    Box,
    Button
} from "@mui/material";
import {useForm} from "react-hook-form";
import {workspaceActions} from "../../store/actions/workspaceAction";
import CustomSnackbar from "../alert/CustomSnackbar";
import TrackingDomainMultiSelect from "../tracking-domain/TrackingDomainMultiSelect";
import {alertActions} from "../../store/actions/alertActions";

function AddWorkspace(props) {

    const {create, error} = props;
    const {alert} = props.state;

    const {register, handleSubmit, control, formState: {errors}} = useForm();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOnSubmit = (data) => {
        let trackingDomainIds = "";
        data?.trackingDomain?.forEach((ws) => {
            if (trackingDomainIds !== "") {
                trackingDomainIds += "," + ws?.value
            } else {
                trackingDomainIds += ws?.value
            }
        });

        if (trackingDomainIds?.length === 0) {
            error("Choose at least 1 tracking domain.");
            return false;
        }

        let result = {
            name: data?.name,
            trackingDomainIds: trackingDomainIds
        };
        create(result);
    };

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        props.history.push({pathname: "/settings/team/workspaces", state: {from: props.location}});
    }, [props]);

    useEffect(() => {
        if (alert?.successMsg !== "") {
            props.history.push({pathname: "/settings/team/workspaces", state: {from: props.location}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert?.successMsg])

    return (
        <Dialog open={true}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="create-workspace-form"
        >
            <DialogTitle sx={{
                textAlign: "center",
                paddingBottom: 0
            }}>
                Create a new workspace
            </DialogTitle>
            {alert.errorMsg !== "" &&
                <CustomSnackbar message={alert.errorMsg} timeout={3500}/>
            }
            <DialogContent sx={{mt: 3}}>
                <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete={"off"}>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <TextField
                                error={!!errors.name}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="name"
                                {...register("name", {
                                    required: true,
                                    maxLength: 100
                                })}
                                name="name"
                                type="name"
                                placeholder="Enter workspace name"
                                fullWidth
                                label="Workspace name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" mt={1}>
                        <Box flexGrow={1}>
                            <TrackingDomainMultiSelect
                                errors={errors}
                                control={control}
                                inputLabel="Tracking domain"
                                inputName="trackingDomain"
                            />
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexWrap="noWrap">
                    <Box mr={2}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                            Create & Close
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            workspace: state.workspace,
            alert: state.alert
        }
    }
};

const mapDispatchToProps = {
    create: workspaceActions.create,
    error: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkspace);