import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from "react-redux";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import {flowActions} from "../../store/actions/flowAction";
import {flowServices} from "../../service/flowService";
import FlowForm from "./FlowForm";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {alertActions} from "../../store/actions/alertActions";
import {countries} from "../utils/DataList";

function DuplicateFlow(props) {
    const {updateSelectedPath, setFlow, clear, errorMsg, rerender, resetForm} = props;

    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState(null);
    const [flowData, setFlowData] = useState(null);
    const [created, setCreated] = useState(false);
    const [loading, setLoading] = useState(false);

    const {register, handleSubmit, control, getValues, setValue, formState: {errors}} = useForm();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        resetForm();
        clear();
        setOpen(false);
        props.history.push({pathname: "/flows", state: {from: props.location}});
    }, [clear, props.history, props.location, resetForm]);

    useEffect(() => {
        async function find(id): void {
            await flowServices.find(id).then((response) => {
                if (response?.id > 0) {
                    setFlowData(response);
                }
            }).catch((error) => {
                errorMsg(error?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
        }

        if (props?.match?.params?.id && flowData === null) {
            find(props.match.params.id);
        }

    }, [flowData, props, errorMsg]);

    useEffect(() => {
        if (flowData !== null) {
            setValue("name", flowData?.name + " - [copy]");
            setValue("tags", flowData?.tags);

            if (flowData?.countryCode !== "") {
                for (const item of countries) {
                    if (item?.code === flowData?.countryCode) {
                        setValue("country", item)
                    }
                }
            }
            let flowObj = JSON.parse(flowData?.flow);
            setFlow(flowObj);
            updateSelectedPath(0, 0, flowObj?.rules[0]?.paths[0]);
        }
    }, [flowData, setFlow, setValue, updateSelectedPath]);

    useEffect(() => {
        async function postData(): void {
            clear();
            setLoading(true);
            await flowServices.create(formData).then((response) => {
                if (response?.id > 0) {
                    resetForm();
                    rerender(true);
                    setCreated(true);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    errorMsg(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (formData !== null) {
            postData();
        }

    }, [handleClose, formData, props, resetForm, rerender, clear, errorMsg]);

    useEffect(() => {
        if (created) {
            handleClose();
        }
    }, [handleClose, created]);

    const onSubmit = ({data, flow}) => {
        let ruleName = "";
        let pathName = "";
        let valid = true;
        for (const rule of flow?.rules) {
            for (const path of rule?.paths) {
                if (path?.directLinking && path?.status) {
                    for (const offer of path?.offers) {
                        if (offer?.offerId === 0) {
                            valid = false;
                            break;
                        }
                    }
                } else if (!path?.directLinking && path?.status) {
                    for (const landing of path?.landings) {
                        if (landing?.landingId === 0) {
                            valid = false;
                            break;
                        }
                    }
                    for (const offer of path?.offers) {
                        if (offer?.offerId === 0) {
                            valid = false;
                            break;
                        }
                    }
                }
                if (!valid) {
                    pathName = path?.name;
                    break;
                }
            }
            if (!valid) {
                ruleName = rule?.name;
                break;
            }
        }

        if (!valid) {
            errorMsg("Please select the missing offers and landings in rule named: " + ruleName + " and path named: " + pathName)
            return false;
        }

        setFormData({
            name: data?.name,
            flow: flow,
            tags: data?.tags ? data?.tags : [],
            countryCode: data?.country?.code ? data.country.code : "",
            workspaceId: data?.workspace?.value
        });
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                maxWidth={"lg"}
        >
            <FlowForm
                title={"Duplicate: " + flowData?.name}
                open={open}
                control={control}
                register={register}
                handleClose={handleClose}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                flowData={flowData}
                getValues={getValues}
                setValue={setValue}
            />
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: flowActions.rerender,
    resetForm: campaignFormActions.resetForm,
    setFlow: campaignFormActions.setFlow,
    updateSelectedPath: campaignFormActions.updateSelectedPath,
    errorMsg: alertActions.error,
    clear: alertActions.clear,
};

export default connect(null, mapDispatchToProps)(DuplicateFlow);
