import {userConstants} from "../constants/userConstants";
import {userServices} from "../../service/userService";
import {alertActions} from "./alertActions";

export const userActions = {
    fetch,
    remove,
    clearErrors,
    invite
};

function fetch(name) {
    return dispatch => {
        dispatch({type: userConstants.USER_SET_IS_LOADING});
        userServices.read(name)
            .then((result) => {
                dispatch({type: userConstants.LOADING_USERS, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function clearErrors() {
    return dispatch => dispatch({type: alertActions.clear()});
}

function remove(id) {
    return (dispatch, getState) => {
        dispatch({type: userConstants.USER_SET_IS_LOADING});
        let users = getState().user.users.filter((user) => user.id !== id);
        userServices.remove(id).then(() => {
            dispatch({type: userConstants.DELETE_USER, users});
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function invite({email, roleId, workspaceIds}) {
    return dispatch => {
        dispatch({type: userConstants.INVITE_MEMBER_LOADING_SPINNER, result: {loading: true}});
        userServices.invite({email, roleId, workspaceIds}).then(() => {
            dispatch({type: userConstants.INVITE_MEMBER, result: {loading: false}});
            dispatch(alertActions.success("New member successfully invited."));
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}
