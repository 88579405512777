import {alertActions} from "./alertActions";
import {websiteDeploymentsConstants} from "../constants/websiteDeploymentsConstants";
import {websiteDeploymentsServices} from "../../service/websiteDeploymentsService";

export const websiteDeploymentsActions = {
    read,
    readMoreRows,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    deleteDeployment,
    updateDeploymentStatus,
    checkStatus,
};

function read(deployment, page, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDeploymentsServices.read(deployment, page, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_LOADING, result});
            }).catch((error) => {
                dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function readMoreRows(deployment, page, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDeploymentsServices.read(deployment, page, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_INFINITY_SCROLL, result});
            }).catch((error) => {
                dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function checkStatus(distributionId) {
    return (dispatch) => {

        if (distributionId === 0) {
            dispatch(alertActions.error("Invalid deployment ID"));
            return false;
        }

        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDeploymentsServices.checkStatus({distributionId})
            .then((result) => {
                dispatch(alertActions.success("Deployment status updated successfully."));
                dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_CHECK_STATUS, result});
            }).catch((error) => {
                dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function deleteDeployment(id) {
    return (dispatch) => {

        if (id === 0) {
            dispatch(alertActions.error("Invalid deployment ID"));
            return false;
        }

        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDeploymentsServices.deleteBucket(id)
            .then((result) => {
                dispatch(alertActions.success("Deleting deployment will take up to 2 minutes."));
                dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_DELETE_DEPLOYMENT, result});
            }).catch((error) => {
                dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_UPDATE_SORT_BY, sortBy});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function updateDeploymentStatus(status = false) {
    return (dispatch) => {
        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_UPDATE_DEPLOYMENT_STATUS, status});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_RERENDER});
    };
}
