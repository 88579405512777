import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {offerServices} from "../../service/offerService";
import {connect} from "react-redux";
import {offersActions} from "../../store/actions/offersAction";
import {OfferForm} from "./OfferForm";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";

function AddOffer(props) {
    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState(null);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, setError, control, watch, setValue, getValues, formState: {errors}} = useForm();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/offers", state: {from: props.location}});
    }, [props.history, props.location]);

    useEffect(() => {
        async function postData() {
            setRequestError("");
            setLoading(true);
            await offerServices.create(formData).then((response) => {
                if (response?.id > 0) {
                    props.rerender(true);
                    setLoading(false);
                    handleClose();
                } else {
                    setLoading(false);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
                setLoading(false);
            });
        }

        if (formData !== null) {
            postData();
        }

    }, [handleClose, formData, props]);

    const onSubmit = (data) => {

        if (data?.workspace?.value === 0) {
            setError("workspace", {
                type: "manual",
                message: "Please select workspace."
            });
            return false;
        }

        let offerName = "";
        if (data?.name !== undefined) {
            offerName = "Global - " + data.name;
            if (data?.country?.label !== undefined) {
                offerName = data.country.label + " - " + data.name;
            }
        }

        setFormData({
            workspaceId: data?.workspace?.value,
            affiliateNetworkId: data?.affiliateNetwork?.value ? data.affiliateNetwork.value : 0,
            clickIdParam: "",
            countryCode: data?.country?.code ? data.country.code : "",
            currencyCode: data?.currency?.code ? data.currency.code : "EUR",
            dailyCapFailoverOfferId: data?.dailyCapFailoverOfferId?.value ? data.dailyCapFailoverOfferId.value : 0,
            dailyCapLimit: data?.dailyCapLimit ? data?.dailyCapLimit : "",
            dailyCapTimezone: data?.timezone?.timezone ? data.timezone.timezone : "",
            enableDailyCap: data?.enableDailyCap ? data.enableDailyCap : false,
            name: offerName,
            payoutSum: data?.payoutSum ? parseFloat(data.payoutSum) : 0,
            payoutType: data?.payoutType ? data.payoutType : "auto",
            url: data?.url ? data.url : "",
            tags: data?.tags ? data?.tags : []
        });
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
        >
            <OfferForm
                title="New offer"
                watch={watch}
                control={control}
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                getValues={getValues}
            />
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: offersActions.rerender
};

export default connect(null, mapDispatchToProps)(AddOffer);
