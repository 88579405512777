import {apiRequest} from "../helpers/utils";

export const ispServices = {
    read
};

function read(name) {
    let params = {
        name,
        pageSize: 250
    };
    return apiRequest("get", "v1/isp", {}, params);
}
