import React from "react";
import {
    Button,
    Divider,
    List,
    ListItem,
    Box,
    ListItemText,
    ListItemIcon,
    useTheme,
    Typography
} from "@mui/material";
import {Check} from "@mui/icons-material";
import {SettingsGeneralPaper} from "../../ProfileSettings";

function AffiliateNetworkNoIntegrationsView(props) {
    const theme = useTheme();
    const addIntegration = () => {
        props.history.push({
            pathname: "/settings/integrations/add-affiliate-network",
            state: {background: props.location}
        });
    };

    return (
        <Box sx={{
            width: "100%",
            padding: "24px 30px 0 30px",
            maxWidth: "1280px",
            minWidth: "300px",
            margin: "0 auto",
            [theme.breakpoints.down(600)]: {
                padding: 0,
                marginTop: "16px"
            },
        }}>
            <SettingsGeneralPaper elevation={0}>
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1}>
                        <Typography variant="h3" sx={{
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "bold",
                            display: "block",
                            paddingTop: "16px",
                            paddingBottom: "24px"
                        }}>
                            Revenue Sync - Affiliate Networks & Search Feed Arbitrage
                        </Typography>
                    </Box>
                    <Box pt={1}>
                        <Button type="button" onClick={addIntegration} color="primary" variant="contained">
                            Add
                        </Button>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={{
                    paddingTop: 2,
                    paddingBottom: "10px",
                    [theme.breakpoints.down(600)]: {
                        overflow: "auto"
                    },
                }}>
                    <Box display="flex" flexDirection="row" alignItems="end" flexWrap="nowrap">
                        <Box mr={2} mt={1}>
                            <img src="/assets/images/affiliate-networks/clickbank.png" alt="Clickbank logo"
                                 width={146} style={{
                                backgroundColor: theme.palette.background.default,
                                padding: "11px 8px",
                                borderRadius: "4px",
                                marginRight: "8px"
                            }}/>
                            <img src="/assets/images/affiliate-networks/system1.svg" alt="System1 logo"
                                 width={120} style={{
                                backgroundColor: theme.palette.background.default,
                                marginRight: "8px",
                                borderRadius: "4px",
                                padding: "8px"
                            }}/>
                            <img src="/assets/images/affiliate-networks/sedo.svg" alt="Sedo logo"
                                 width={112} style={{
                                backgroundColor: theme.palette.background.default,
                                borderRadius: "4px",
                                padding: "4px 8px",
                                marginRight: "8px",
                            }}/>
                            <img src="/assets/images/affiliate-networks/bodis.svg" alt="Bodis logo"
                                 width={120} style={{
                                backgroundColor: theme.palette.background.default,
                                marginRight: "8px",
                                borderRadius: "4px",
                                padding: "8px"
                            }}/>
                            <img src="/assets/images/affiliate-networks/tonic.svg" alt="Tonic logo"
                                 width={120} style={{
                                backgroundColor: theme.palette.background.default,
                                marginRight: "8px",
                                borderRadius: "4px",
                                padding: "6px 8px 7px 8px"
                            }}/>
                            <img src="/assets/images/affiliate-networks/ads.svg" alt="Ads.com logo"
                                 width={117} style={{
                                backgroundColor: theme.palette.background.default,
                                marginRight: "8px",
                                borderRadius: "4px",
                                padding: "6px 38px",
                            }}/>
                            <img src="/assets/images/affiliate-networks/domain_active.svg" alt="Domain Active logo"
                                 width={130} style={{
                                backgroundColor: theme.palette.background.default,
                                marginRight: "8px",
                                borderRadius: "4px",
                                padding: "6px 8px",
                            }}/>
                        </Box>
                    </Box>
                </Box>
                <Box pt={1} pb={1} sx={{color: "text.disabledLight"}}>
                    You don't have any integrated affiliate network or search arbitrage. Integrate now to stream revenue
                    and conversions data.
                </Box>
                <Box flex="display" flexWrap="wrap">
                    <Box>
                        <List>
                        <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Receive conversions data via instant notification URL."/>
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Stream revenue from search feed arbitrage."/>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </SettingsGeneralPaper>
        </Box>
    );
}

export default AffiliateNetworkNoIntegrationsView;