export const landingConstants = {
	LANDINGS_LOADING_SPINNER: 'LANDINGS_LOADING_SPINNER',
	LANDINGS_STOP_LOADING_SPINNER: 'LANDINGS_STOP_LOADING_SPINNER',
	LANDINGS_INFINITY_SCROLL: 'LANDINGS_INFINITY_SCROLL',
	LANDINGS_GET_DATA: 'LANDINGS_GET_DATA',
	LANDINGS_ERROR: 'LANDINGS_ERROR',
	LANDINGS_UPDATE_ORDER_BY: 'LANDINGS_UPDATE_ORDER_BY',
	LANDINGS_UPDATE_SORT_BY: 'LANDINGS_UPDATE_SORT_BY',
	LANDINGS_UPDATE_NAME: 'LANDINGS_UPDATE_NAME',
	LANDINGS_UPDATE_TAGS: 'LANDINGS_UPDATE_TAGS',
	LANDINGS_UPDATE_SORT_COLUMNS: 'LANDINGS_UPDATE_SORT_COLUMNS',
	LANDINGS_RERENDER: 'LANDINGS_RERENDER',
	LANDINGS_ARCHIVE: 'LANDINGS_ARCHIVE',
	LANDINGS_RESTORE: 'LANDINGS_RESTORE',
	BULK_UPDATE_CHANGE_WORKSPACE: 'BULK_UPDATE_CHANGE_WORKSPACE',
	LANDINGS_BULK_UPDATE_FOR_URL: 'LANDINGS_BULK_UPDATE_FOR_URL'
};
