import React, {useEffect} from "react";
import {MenuOpen} from "@mui/icons-material";
import {connect} from "react-redux";
import {sidebarActions} from "../../store/actions/sidebarAction";
import {useMediaQuery, useTheme, IconButton, Divider, Drawer, List, Box} from "@mui/material";
import {styled} from "@mui/system";
import {MainListItems} from "./ListItems";

const drawerWidth = 210;

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: theme.palette.background.darkBlue,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    borderRight: 0,
});

const closedMixin = (theme) => ({
    backgroundColor: theme.palette.background.darkBlue,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    borderRight: 0,
    width: `calc(${theme.spacing(7)})`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)})`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: 48,
}));

const CustomDrawer = styled(Drawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Sidebar(props) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const {isOpen, isOpenMobile} = props?.sidebar;

    const handleDrawerClose = () => {
        props.handleDrawerClose();
    };

    const handleDrawerCloseOnClick = (e) => {
        if (!isDesktop) {
            // Add check for Logs collapse
            if (e?.target?.textContent !== "Logs") {
                props.handleDrawerClose();
            }
        }
    };

    useEffect(() => {
        if (props?.location?.pathname === "/log/clicks" && !props?.sidebar?.isLogsOpen) {
            props?.handleIsLogsOpen(true);
        }
        if (props?.location?.pathname === "/log/conversions" && !props?.sidebar?.isLogsOpen) {
            props?.handleIsLogsOpen(true);
        }
        if (props?.location?.pathname === "/log/postbacks" && !props?.sidebar?.isLogsOpen) {
            props?.handleIsLogsOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {!isDesktop &&
                <Box>
                    <Drawer
                        open={isOpenMobile}
                        onClose={handleDrawerClose}
                        ModalProps={{keepMounted: true}}
                        sx={{
                            whiteSpace: 'nowrap',
                            boxSizing: 'border-box',
                            ...(isOpenMobile && {
                                ...openedMixin(theme),
                                '& .MuiDrawer-paper': openedMixin(theme),
                            }),
                            ...(!isOpenMobile && {
                                ...closedMixin(theme),
                                '& .MuiDrawer-paper': closedMixin(theme),
                            }),
                        }}
                    >
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                <MenuOpen sx={{marginRight: 0, color: "background.default"}}/>
                            </IconButton>
                        </DrawerHeader>
                        <Divider/>
                        <List sx={{p: 0}} onClick={handleDrawerCloseOnClick}>
                            {MainListItems({
                                location: props?.location,
                                auth: props?.auth,
                                history: props?.history,
                                sidebar: props?.sidebar,
                                handleIsLogsOpen: props?.handleIsLogsOpen
                            })}
                        </List>
                    </Drawer>
                </Box>
            }

            {isDesktop &&
                <CustomDrawer
                    variant="permanent"
                    open={isOpen}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <MenuOpen sx={{marginRight: 0, color: "background.default"}}/>
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    <List sx={{p: 0}} onClick={handleDrawerCloseOnClick}>
                        {MainListItems({
                            location: props?.location,
                            auth: props?.auth,
                            history: props?.history,
                            sidebar: props?.sidebar,
                            handleIsLogsOpen: props?.handleIsLogsOpen
                        })}
                    </List>
                    <Divider/>
                </CustomDrawer>
            }
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        sidebar: state.sidebar,
        auth: state.auth
    }
};

const actionCreators = {
    handleDrawerClose: sidebarActions.handleDrawerClose,
    handleDrawerOpen: sidebarActions.handleDrawerOpen,
    handleIsLogsOpen: sidebarActions.handleIsLogsOpen
};

export default connect(mapStateToProps, actionCreators)(Sidebar);