import {ddToolbarConstants} from "../constants/ddToolbarConstants";
import {dashboardConstants} from "../constants/dashboardConstants";

export const ddToolbarActions = {
    updatePageSize,
    updateIsDeleted,
    updateDateRange
};

function updatePageSize(pageSize) {
    return (dispatch, /*getState*/) => {
        //console.log("getState().page", getState().ddToolbar.pageSize);
        dispatch({type: ddToolbarConstants.UPDATE_PAGE_SIZE, pageSize});
    };
}

function updateIsDeleted(isDeleted) {
    return dispatch => {
        dispatch({type: ddToolbarConstants.UPDATE_IS_DELETED, isDeleted});
    };
}

function updateDateRange(from, to, dateLabel) {
    return dispatch => {
        dispatch({type: ddToolbarConstants.UPDATE_DATE_RANGE, payload: {from, to, dateLabel}});
        dispatch({type: dashboardConstants.DASHBOARD_UPDATE_DATE_RANGE, payload: {from, to, dateLabel}});
    };
}