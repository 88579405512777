import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {ArrowDropDown, BorderAll} from '@mui/icons-material';
import 'react-dates-gte-react-17/initialize';
import 'react-dates-gte-react-17/lib/css/_datepicker.css';
import {
    Box,
    Checkbox, Divider, IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    useTheme,
    Popover,
    Button,
    Paper,
    Tooltip, ListItemButton
} from "@mui/material";
import {workspaceActions} from "../../store/actions/workspaceAction";
import Spinner from "../spinner/Spinner";
import useCustomStyles from "../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        color: theme.palette.text.secondary
    },
    paper: {
        width: "100%",
        minWidth: 250,
        maxWidth: 250,
    },
    list: {
        height: "100%",
        maxHeight: 300,
        overflow: "auto"
    },
    listItem: {
        padding: "0 16px 0 12px"
    },
    listItemIconRoot: {
        minWidth: 25,
    },
    listItemText: {
        "& span": {
            fontSize: 14,
            maxWidth: 200,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        }
    },
    badge: {
        fontSize: 10,
        height: 16,
        minWidth: 16
    }
});

function WorkspaceDropdown(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {workspace} = props;
    const [open, setOpen] = useState(null);
    const [checked, setChecked] = useState([]);

    const down625 = useMediaQuery(theme.breakpoints.down(625));

    const applyChanges = () => {
        let data = [];
        workspace?.userWorkspaces?.forEach((item) => {
            data.push({
                id: item?.id,
                selected: checked?.indexOf(item?.id) !== -1
            });
        });
        props.updateSelectedUserWorkspaces(checked, data);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleAll = () => {
        if (checked?.length !== 0 && checked?.length === workspace?.userWorkspaces?.length) {
            setChecked([]);
        } else {
            let newOptions = [];
            workspace?.userWorkspaces?.forEach((item) => {
                newOptions.push(item?.id);
            });
            setChecked(newOptions);
        }
    };

    useEffect(() => {
        if (open !== null) {
            props?.getUserWorkspaces();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        if (workspace?.userWorkspaces !== null) {
            const newChecked = [];
            workspace?.userWorkspaces?.forEach((item) => {
                if (item?.selected) {
                    newChecked.push(item?.id);
                }
            });
            setChecked(newChecked);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspace?.userWorkspaces])

    useEffect(() => {
        if (workspace?.updated !== null) {
            setOpen(null);
        }
    }, [workspace?.updated])

    return (
        <div className={classes?.root}>
            {down625 &&
                <Tooltip title="Workspaces">
                    <IconButton
                        id="workspace-multi-select-1"
                        color="inherit"
                        onClick={(e) => {
                            setOpen({anchorEl: e.currentTarget});
                        }}
                    >
                        <BorderAll/>
                    </IconButton>
                </Tooltip>
            }
            {!down625 && <Button
                id="workspace-multi-select-2"
                onClick={(e) => {
                    setOpen({anchorEl: e.currentTarget});
                }}
                startIcon={<BorderAll/>}
                endIcon={<ArrowDropDown/>}
                color="inherit"
                size="large"
                sx={{textTransform: "none", whiteSpace: "nowrap"}}
            >
                Workspaces
            </Button>}
            <Popover
                id="date-range-picker-airbnb"
                open={Boolean(open?.anchorEl)}
                anchorEl={open?.anchorEl}
                onClose={() => setOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper className={classes?.paper}>

                    {workspace?.loading?.getUserWorkspaces && <Spinner overComponentBox={true}/>}

                    <List className={classes?.list}>
                        <ListItem
                            key={"all"}
                            role={undefined}
                            dense
                            onClick={handleToggleAll}
                            className={classes?.listItem}
                        >
                            <ListItemButton>
                                <ListItemIcon classes={{root: classes?.listItemIconRoot}}>
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        edge="start"
                                        checked={checked?.length === workspace?.userWorkspaces?.length}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{'aria-labelledby': "all"}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={"all"} primary="All workspaces" className={classes?.listItemText}/>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                        {workspace?.userWorkspaces?.map((item) => {
                            return (
                                <ListItem
                                    key={item?.id}
                                    role={undefined}
                                    dense
                                    onClick={handleToggle(item?.id)}
                                    className={classes?.listItem}
                                >
                                    <ListItemButton>
                                        <ListItemIcon classes={{root: classes?.listItemIconRoot}}>
                                            <Checkbox
                                                size="small"
                                                color="primary"
                                                edge="start"
                                                checked={checked.indexOf(item?.id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{'aria-labelledby': item?.id}}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={item?.id} primary={item?.name} className={classes?.listItemText}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Box display="flex" justifyContent="flex-end">
                        <Box pt={1} pr={1} pb={1}>
                            <Button
                                disabled={checked?.length === 0}
                                variant="contained"
                                color="primary"
                                onClick={applyChanges}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Popover>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        workspace: state.workspace
    }
};

const actionCreators = {
    getUserWorkspaces: workspaceActions.getUserWorkspaces,
    updateSelectedUserWorkspaces: workspaceActions.updateSelectedUserWorkspaces
};

export default connect(mapStateToProps, actionCreators)(WorkspaceDropdown);
