import {apiRequest} from "../helpers/utils";

export const trafficSourceServices = {
    create,
    update,
    archive,
    find,
    restore,
    read,
    bulkUpdate
};

function read(name) {
    let params = {
        name,
        isDeleted: false,
        pageSize: 250
    };
    return apiRequest("get", "v1/traffic", {}, params);
}

function create(formData) {
    return apiRequest("post", "v1/traffic", formData);
}

function update(id, formData) {
    return apiRequest("put", "v1/traffic/" + parseInt(id), formData);
}

function archive(formData) {
    return apiRequest("post", "v1/traffic/archive", formData);
}

function restore(formData) {
    return apiRequest("post", "v1/traffic/restore", formData);
}

function find(id) {
    return apiRequest("get", "v1/traffic/" + parseInt(id));
}

function bulkUpdate(formData) {
    return apiRequest("patch", "v1/traffic", formData);
}