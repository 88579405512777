import React, {useEffect, useState} from "react";
import {Alert, Box, Divider, Button, Link} from "@mui/material";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {subscriptionServices} from "../../service/subscriptionService";
import Spinner from "../spinner/Spinner";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Logo from "../utils/Logo";
import CustomSnackbar from "../alert/CustomSnackbar";
import {NavLink} from "react-router-dom";

export default function RetryInvoice() {

    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [subscriptionInfo, setSubscriptionInfo] = useState("");

    const handleRetryInvoice = async event => {
        event.preventDefault();

        setLoading(true);
        setErrorMsg("");

        if (!stripe || !elements) {
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (error) {
            setLoading(false);
            setErrorMsg(error?.message);
            return;
        }

        await subscriptionServices.retryInvoice(paymentMethod?.id)
            .then((result) => {
                if (result?.status === "active") {
                    document.location.replace("/");
                } else if (result?.paymentIntentStatus === "requires_action" || result?.paymentIntentStatus === "requires_payment_method") {
                    stripe.confirmCardPayment(result?.paymentIntentClientSecret, {payment_method: result?.paymentMethodId})
                        .then((result) => {
                            if (result?.error) {
                                setErrorMsg(result?.error?.message);
                            } else {
                                if (result.paymentIntent.status === 'succeeded') {
                                    // There's a risk of the customer closing the window before callback
                                    // execution. To handle this case, set up a webhook endpoint and
                                    // listen to invoice.paid. This webhook endpoint returns an Invoice.
                                    subscriptionServices.updateSubscriptionStatus().then(() => {
                                        document.location.replace("/");
                                    }).catch((error) => {
                                        setErrorMsg(error?.response?.data?.error);
                                    });
                                }
                            }
                        });
                }
            }).catch((error) => {
                setErrorMsg(error?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        subscriptionServices.fetchSubscription(false, false, true, true).then((result) => {
            setSubscriptionInfo(result);
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <Box>
            <Box pl={1} pr={1} display="flex" flexDirection="row" flexWrap="noWrap" sx={{maxWidth: 1200, margin: "0 auto"}}>
                <Box p={1} flexGrow={1} sx={{fontSize: 20, fontWeight: 600, marginTop: "4px"}}>
                    <Logo width={75}/>
                </Box>
                <Box mt={1.2} p={1}>
                    <Link color="error" component={NavLink} to="/logout"
                          sx={{
                              textDecoration: "none",
                              fontWeight: 600,
                              fontSize: "14px",
                              "&:hover": {color: "error.dark"}
                          }}
                    >
                        Log out
                    </Link>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{width: "100%", maxWidth: 600, margin: "0 auto"}}>

                {loading && <Spinner size={32} overComponentBox={true}/>}

                <DialogTitle sx={{textAlign: "center", fontSize: 18, fontWeight: 600}}>
                    Update payment method
                </DialogTitle>
                <DialogContent sx={{padding: "12px 15px 0px 15px"}}>
                    <Alert color="warning" variant="filled" sx={{marginBottom: "20px"}}>
                        Your subscription status is past due. Please update your payment method.
                    </Alert>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1} sx={{fontWeight: 600}}>
                            Total due now
                        </Box>
                        <Box p={1}>€ {(subscriptionInfo?.amountRemaining / 100)?.toFixed(2)}</Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1} sx={{fontWeight: 600}}>
                            Subscribing to plan
                        </Box>
                        <Box p={1}>{subscriptionInfo?.plan?.name}</Box>
                    </Box>
                    <Divider/>

                    {errorMsg !== "" && <CustomSnackbar message={errorMsg} severity="error" timeout={3500}/>}

                    <Box mt={4}>
                        <Box>
                            <form onSubmit={handleRetryInvoice}>
                                <Box sx={{fontWeight: 600, paddingLeft: 1, fontSize: "14px"}}>Card details</Box>
                                <CardElement options={{
                                    style: {
                                        base: {
                                            width: "100%",
                                            iconColor: '#fff',
                                            fontSize: '16px',
                                            color: '#fff',
                                            '::placeholder': {
                                                color: '#fff',
                                                fontSize: "14px"
                                            },
                                            '::selection': {
                                                backgroundColor: "blue"
                                            }
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}/>
                            </form>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{padding: "0px 15px 12px 15px"}}>
                    <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleRetryInvoice}
                        fullWidth
                    >
                        Confirm Payment
                    </Button>
                </DialogActions>
                <Box pt={2} sx={{textAlign: "center", fontSize: 12, color: "#666"}}>
                    <a href="https://www.stripe.com" target="_blank" rel="noreferrer">
                        <img src="/assets/images/stripe.png" alt="Powered by Stripe"
                             style={{width: 120}}/></a>
                </Box>
            </Box>
        </Box>
    );
}
