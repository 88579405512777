import {workspaceConstants} from "../constants/workspaceConstants";

const initialState = {
    workspaces: null,
    workspaceUsers: null,
    userWorkspaces: null,
    rerender: false,
    loading: {
        read: false,
        getUserWorkspaces: false,
        findWorkspaceUsers: false,
        assignUserToWorkspace: false,
        updateWorkspace: false,
    },
    updated: null,
};

const workspaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case workspaceConstants.WORKSPACE_ASSIGN_USER_TO_WORKSPACE_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    assignUserToWorkspace: action?.result?.loading
                }
            };
        case workspaceConstants.WORKSPACE_ASSIGN_USER_TO_WORKSPACE:
            return {
                ...state,
                workspaceUsers: [...state?.workspaceUsers, action?.user],
                loading: {
                    findWorkspaceUsers: false
                }
            };
        case workspaceConstants.WORKSPACE_DELETE_USER_FROM_WORKSPACE_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    findWorkspaceUsers: action?.result?.loading
                }
            };
        case workspaceConstants.WORKSPACE_UPDATE_USERS_IN_WORKSPACE:
            return {
                ...state,
                workspaceUsers: state?.workspaceUsers?.filter(item => item?.id !== action?.userId),
                loading: {
                    findWorkspaceUsers: false
                }
            };
        case workspaceConstants.WORKSPACE_SET_NEW_WORKSPACE:
            return {
                ...state,
                workspaces: [...state?.workspaces, action?.result],
                loading: {
                    findWorkspaceUsers: false
                }
            };
        case workspaceConstants.WORKSPACE_FIND_WORKSPACE_USERS_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    findWorkspaceUsers: action?.result?.loading
                }
            };
        case workspaceConstants.WORKSPACE_SET_WORKSPACE_USERS:
            return {
                ...state,
                workspaceUsers: action?.data,
                loading: {
                    findWorkspaceUsers: false
                }
            };
        case workspaceConstants.WORKSPACE_READ_WORKSPACES_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    read: action?.result?.loading
                }
            };
        case workspaceConstants.WORKSPACE_READ_WORKSPACES_SET_RESULT:
            return {
                ...state,
                workspaces: action?.result?.workspaces,
                loading: {
                    read: false
                }
            };
        case workspaceConstants.WORKSPACE_GET_USER_WORKSPACES_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getUserWorkspaces: action?.result?.loading
                }
            };
        case workspaceConstants.WORKSPACE_GET_USER_WORKSPACES_SET_RESULT:
            return {
                ...state,
                userWorkspaces: action?.result,
                loading: {
                    getUserWorkspaces: false
                }
            };
        case workspaceConstants.WORKSPACE_UPDATE_USER_WORKSPACE:
            return {
                ...state,
                userWorkspaces: state.userWorkspaces.map((item) => {
                    item.selected = action?.checked?.indexOf(item?.id) !== -1
                    return item;
                }),
                loading: {
                    getUserWorkspaces: false
                },
                updated: action?.checked
            };
        case workspaceConstants.WORKSPACE_UPDATE_WORKSPACE:
            return {
                ...state,
                workspaces: state.workspaces.map((item) => {
                    if (item?.id === action?.payload?.id) {
                        item.name = action?.payload?.name;
                    }
                    return item;
                }),
                loading: {
                    updateWorkspace: false
                }
            };
        case workspaceConstants.WORKSPACE_UPDATE_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    updateWorkspace: action?.result?.loading
                }
            };
        case workspaceConstants.WORKSPACE_DELETE_WORKSPACE:
            return {
                ...state,
                workspaces: state.workspaces.filter(item => item?.id !== action?.workspaceId),
                userWorkspaces: state.userWorkspaces.filter(item => item?.workspaceId !== action?.workspaceId),
                loading: {
                    ...state.loading,
                    updateWorkspace: action?.result?.loading
                }
            };
        case workspaceConstants.WORKSPACE_RESET_UPDATED_VALUE:
            return {
                ...state,
                updated: null
            };
        default:
            return state;
    }
};

export default workspaceReducer;