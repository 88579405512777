import React, {useCallback, useEffect} from 'react';
import {connect} from "react-redux";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {websiteDomainsActions} from "../../store/actions/websiteDomainsAction";
import {useHistory, useLocation, useParams} from "react-router-dom";
import CopyContentToClipboard from "../utils/CopyContentToClipboard";

function DomainsGetCNAMERecords(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory();
    const location = useLocation();
    const {id} = useParams();

    const {getDomainVerifyRecords} = props;

    const handleClose = useCallback(() => {
        history.push({pathname: "/websites/domains", state: {from: location}});
    }, [history, location]);

    useEffect(() => {
        if (id > 0) {
            getDomainVerifyRecords(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={fullScreen}
            aria-labelledby="cname-records-dialog"
            maxWidth={"md"}
        >
            <DialogTitle id="cname-records-dialog" textAlign="center">Domain CNAME Records</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column">
                    <Box sx={{paddingLeft: 1, paddingBottom: 2, color: "text.disabledLight"}}>
                        Add the following CNAME record to the DNS configuration for your domain. This procedure for
                        adding CNAME records depends on your DNS service provider.
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1}>
                        <Box>Type:</Box>
                        <Box pl={3}>CNAME</Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1}>
                        <Box alignContent="center" pr={2}>Name:</Box>
                        <Box pr={1} width="100%">
                            <CopyContentToClipboard copyText={props?.websiteDomainsState?.cnameRecord?.name}/>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1}>
                        <Box alignContent="center" pr={2}>Value:</Box>
                        <Box pr={1} width="100%">
                            <CopyContentToClipboard copyText={props?.websiteDomainsState?.cnameRecord?.value}/>
                        </Box>
                    </Box>
                </Box>
                <Box pt={3}>
                    <Alert severity="warning" color="warning" variant="filled">
                        Changing the DNS configuration allows ACM to issue certificates for this domain name for as long
                        as
                        the DNS record exists. You can revoke permission at any time by removing the record.
                    </Alert>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        websiteDomainsState: state.websiteDomains,
    }
};

const mapDispatchToProps = {
    getDomainVerifyRecords: websiteDomainsActions.getDomainVerifyRecords,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainsGetCNAMERecords);