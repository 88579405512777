import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import {affNetworkServices} from "../../service/affiliateNetworkService";
import debounce from 'lodash/debounce'
import {Autocomplete, TextField} from "@mui/material";

export default function AffiliateNetworkSelect({control}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await affNetworkServices.read(search);
                setOptions(
                    response.affiliateNetworks.map(item => {
                        return {
                            value: item?.id,
                            label: item?.name,
                            sharedAffiliateNetworkId: item?.sharedAffiliateNetworkId,
                            usePostbackProtection: item?.usePostbackProtection,
                            postbackProtectionToken: item?.postbackProtectionToken,
                        }
                    })
                );
            } catch (e) {
                console.log({e});
            } finally {
                setLoading(false);
            }
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData();
        }

    }, [search, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="affiliate-network-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    loading={loading}
                    renderOption={(props, option, {selected, index}) => (
                        <li {...props} key={index}>
                            {option.label}
                        </li>
                    )}
                    renderInput={params => (
                        <span>
                            <TextField
                                {...params}
                                placeholder="Select an affiliate network"
                                variant="outlined"
                                margin="dense"
                                size="small"
                                value={search}
                                label="Affiliate network"
                                onChange={e => handleSearch(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </span>
                    )}
                    onChange={(e, data, reason) => {
                        if (reason === "clear") {
                            onChange(null);
                        } else {
                            onChange(data)
                        }
                    }}
                    {...props}
                />
            )}
            name="affiliateNetwork"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}
