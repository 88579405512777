import React from "react";
import {Box, Switch} from "@mui/material";

export default function TrafficSourceStatusSwitch({row, handleStatusUpdate}) {

    const handleOnChange = (checked) => {
        handleStatusUpdate(checked, row?.name);
    };

    if (!row.hasOwnProperty("status")) {
        return "";
    }

    return (
        <Box sx={{textAlign: "center", position: "relative", top: "-1px"}} key={row?.name}>
            <Switch
                checked={!!row?.status}
                onChange={(e) => handleOnChange(e?.target?.checked)}
                name="status"
                color="primary"
                size="small"
            />
        </Box>
    );
}