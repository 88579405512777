import React, {useEffect} from "react";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {TextField, Box} from "@mui/material";
import ConditionTabs from "./conditions/ConditionTabs";
import ReactHookFormRadio from "../input/ReactHookFormRadio";
import {useForm} from "react-hook-form";

function PathRules(props) {
    const {flow, selectedRuleNumber, selectedRule} = props.campaignFormState;
    const {control, setValue, getValues} = useForm({
        defaultValues: {
            logicalRelation: "and"
        }
    });
    const updateRuleName = (ruleIndex, name) => {
        props.updateRuleName(ruleIndex, name);
    };
    const radioConditions = [
        {label: "And", value: "and", color: "primary"},
        {label: "Or", value: "or", color: "primary"},
    ];
    const updateLogicalRelation = () => {
        let logicalRelation = getValues("logicalRelation");
        if (logicalRelation === "and" || logicalRelation === 'or') {
            props.updateLogicalRelation(selectedRuleNumber, logicalRelation);
        }
    };
    useEffect(() => {
        if (selectedRule !== null) {
            setValue("logicalRelation", flow.rules[selectedRuleNumber]?.logicalRelation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRuleNumber, selectedRule, flow.rules]);
    return (
        <Box display="flex" flexDirection="column">
            <Box>
                <TextField
                    sx={{width: "100%"}}
                    label="Rule name"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    size="small"
                    value={flow?.rules[selectedRuleNumber]?.name}
                    onChange={e => updateRuleName(selectedRuleNumber, e.target.value)}
                    InputLabelProps={{
                        sx: {fontSize: "14px"},
                        shrink: true,
                    }}
                />
            </Box>
            <Box ml={1} mt={2} sx={{fontSize: "14px", fontWeight: 600}}>
                Logical relation between conditions
            </Box>
            <Box ml={1}>
                <ReactHookFormRadio
                    defaultValue="and"
                    control={control}
                    options={radioConditions}
                    name="logicalRelation"
                    label="Logical Relation"
                    updateOnChange={updateLogicalRelation}
                />
            </Box>
            <Box pt={1}>
                <ConditionTabs/>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    updateRuleName: campaignFormActions.updateRuleName,
    updateLogicalRelation: campaignFormActions.updateLogicalRelation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PathRules);