import React from "react";
import PropTypes from "prop-types";
import {Box, CircularProgress} from "@mui/material";

function Spinner({size = 30, overComponentBox = false, noBackShadow = false}) {
    if (overComponentBox) {
        if (noBackShadow) {
            return (
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}>
                    <Box>
                        <CircularProgress size={size}/>
                    </Box>
                </Box>
            );
        }
        return (
            <Box sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 9999,
            }}>
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}>
                    <Box>
                        <CircularProgress size={size}/>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <div className="Spinner">
            <CircularProgress size={size}/>
        </div>
    );
}

Spinner.propTypes = {
    size: PropTypes.number,
    overComponentBox: PropTypes.bool,
    noBackShadow: PropTypes.bool
};

export default Spinner;