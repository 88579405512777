import React, {useEffect, useState} from "react";
import {Box, Button, useMediaQuery, useTheme} from "@mui/material";
import {useForm} from "react-hook-form";
import Spinner from "../spinner/Spinner";
import {settingsServices} from "../../service/settingsService";
import CurrencySelect from "../offers/CurrencySelect";
import {currencies} from "../utils/DataList";
import TimezoneSelect from "../timezone/TimezoneSelect";
import {timezones} from "../timezone/TimezonesList";
import CustomSnackbar from "../alert/CustomSnackbar";
import {SettingsGeneralButtonBox, SettingsGeneralPaper, SettingsGeneralRoot} from "./ProfileSettings";

const ReportingSettings = (props) => {
    const theme = useTheme();
    const down600 = useMediaQuery(theme.breakpoints.down(600));

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const [userSettings, setUserSettings] = useState(null);

    const {handleSubmit, setValue, control} = useForm();

    const onSubmit = (data) => {
        let obj = {
            timezone: data?.timezone?.timezone,
            currencyCode: data?.currency?.code
        };
        setFormData(obj);
    };

    useEffect(() => {
        async function updateUserSettings(): void {
            try {
                props.clear();
                setLoading(true);
                const response = await settingsServices.updateUserSettings(formData);
                if (response !== null && response !== undefined) {
                    props.updateUserSettings(response);
                    props.successMsg("Reporting settings has been successfully updated.");
                }
            } catch (error) {
                if (error?.response?.status === 403) {
                    props.errorMsg("Access forbidden. You have no rights to update your user settings.");
                } else if (error?.response?.data?.error) {
                    props.errorMsg(error?.response?.data?.error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (formData !== null) {
            updateUserSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        async function fetchUserSettings(): void {
            try {
                props.clear();
                setLoading(true);
                const response = await settingsServices.fetchUserSettings();
                if (response !== null && response !== undefined) {

                    if (response?.currencyCode && response?.currencyCode !== "") {
                        currencies.forEach((item) => {
                            if (item?.code === response?.currencyCode) {
                                setValue("currency", item)
                            }
                        });
                    }

                    if (response?.timezone && response?.timezone !== "") {
                        timezones.forEach((item) => {
                            if (item?.timezone === response?.timezone) {
                                setValue("timezone", item);
                            }
                        })
                    }

                    setUserSettings(response);
                }
            } catch (error) {
                if (error?.response?.status === 403) {
                    props.errorMsg("Access forbidden. You have no rights to fetch user settings.");
                } else if (error?.response?.data?.error) {
                    props.errorMsg(error?.response?.data?.error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (userSettings === null) {
            fetchUserSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSettings, setValue]);

    return (
        <SettingsGeneralRoot>
            <SettingsGeneralPaper elevation={0}>
                {loading && <Spinner size={32} overComponentBox={true}/>}

                <h3>Reporting Settings</h3>

                <Box sx={{color: "text.disabledLight", pb: 1}}>
                    We support time zones in hourly increments and up to 169 different currencies. These settings are
                    user specific.
                </Box>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>

                    {props?.state?.alert?.successMsg !== "" &&
                        <CustomSnackbar message={props?.state?.alert?.successMsg} severity="success" timeout={3500}/>
                    }
                    {props?.state?.alert?.errorMsg !== "" &&
                        <CustomSnackbar message={props?.state?.alert?.errorMsg} severity="error" timeout={3500}/>
                    }

                    <Box display="flex" flexWrap="wrap" flexDirection="column">
                        <Box mb={1}>
                            <TimezoneSelect control={control} setValue={setValue}/>
                        </Box>
                        <Box mb={1}>
                            <CurrencySelect control={control}/>
                        </Box>
                    </Box>
                    <SettingsGeneralButtonBox>
                        <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained" disabled={loading}
                                fullWidth={down600}>
                            Update
                        </Button>
                    </SettingsGeneralButtonBox>
                </form>
            </SettingsGeneralPaper>
        </SettingsGeneralRoot>
    );
};

export default ReportingSettings;