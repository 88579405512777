import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import {LandingForm} from "./LandingForm";
import {landingServices} from "../../service/landingService";
import {countries} from "../utils/DataList";

function LandingEditPopUp({onClose, open, landingId}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [landingData, setLandingData] = useState(null);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);

    const {register, handleSubmit, setValue, watch, control, reset, getValues, formState: {errors}} = useForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setRequestError("");
        reset();
        onClose();
    });

    useEffect(() => {
        async function find(id): void {
            await landingServices.find(id).then((response) => {
                if (response?.id > 0) {
                    setLandingData(response);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    setRequestError("Landing page not found.");
                }
                setLoading(false);
            });
        }

        if (open && landingId > 0 && landingId !== undefined && landingId !== "" && landingId !== null) {
            find(landingId);
        }

    }, [open, landingId]);

    useEffect(() => {
        if (open && landingData !== null) {

            setTimeout(() => {
                for (const [key, value] of Object.entries(landingData)) {
                    if (key === "name") {
                        setValue("name", value.slice(value.indexOf("-") + 2));
                    } else {
                        setValue(key, value);
                    }
                }
            });

            countries.forEach((item) => {
                if (item.code === landingData.countryCode) {
                    setValue("country", item)
                }
            });
        }
    }, [open, landingData, setValue]);

    const onSubmit = async (data) => {
        setLoading(true);

        let name = "";
        if (data?.name) {
            name = "Global - " + data?.name;
            if (data?.country && data?.country?.label) {
                name = data?.country?.label + " - " + data?.name;
            }
        }

        let d = {
            countryCode: data?.country && data?.country?.code ? data?.country?.code : "",
            name,
            url: data?.url ? data.url : "",
            offers: data?.offers >= 1 ? parseInt(data?.offers) : 1,
            workspaceId: data?.workspace?.value,
            tags: data?.tags ? data?.tags : [],
        };

        await landingServices.update(landingId, d).then((response) => {
            if (response?.id > 0) {
                setLoading(false);
            } else {
                setLoading(false);
            }
            onClose(d?.name);
        }).catch((e) => {
            if (e?.response?.data?.error) {
                setRequestError(e.response.data.error);
            }
            setLoading(false);
        });
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="landing-form"
                maxWidth={"md"}
        >
            <LandingForm
                title="Update landing"
                open={open}
                watch={watch}
                control={control}
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                landingData={landingData}
                getValues={getValues}
            />
        </Dialog>
    );
}

LandingEditPopUp.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    landingId: PropTypes.number.isRequired
};

export default LandingEditPopUp;