import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import {Autocomplete, TextField} from "@mui/material";
import {websiteDomainsServices} from "../../../service/websiteDomainsService";

function DomainsDropdown({control, errors, setRequestError}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && options?.length === 0) {
            setLoading(true);
            websiteDomainsServices.getOnlyVerifiedDomains(search, "ISSUED").then((result) => {
                setOptions(
                    result?.websiteDomains?.map(i => {
                        return {
                            value: i?.id,
                            label: i?.domain
                        }
                    })
                );
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error?.response?.data?.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, setRequestError]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="domain-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => {
                        if (value?.value === 0) {
                            return true;
                        }
                        return option.value === value.value
                    }}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={!!errors?.domain}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            placeholder="Choose a domain"
                            label="Domain*"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                        />
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={{required: true}}
            name="domain"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

export default DomainsDropdown;