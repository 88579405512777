import {apiRequest} from "../helpers/utils";
import {accessApiUrl} from "../helpers/config";

export const roleServices = {
    read
};

function read(appId = 0) {
    return apiRequest("get", "v1/application/" + parseInt(appId) + "/role", {}, {}, true, false, accessApiUrl);
}
