import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {campaignActions} from "../../store/actions/campaignsAction";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {
    Alert, Box,
    Dialog,
    DialogTitle,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {FormStep1} from "./FormStep1";
import {FormStep2} from "./FormStep2";
import {campaignServices} from "../../service/campaignService";
import {trafficSourceServices} from "../../service/trafficSourceService";
import {trackingDomainServices} from "../../service/trackingDomainService";
import {cloakingDomainServices} from "../../service/cloakingDomainService";
import FormStep3 from "./FormStep3";
import {flowActions} from "../../store/actions/flowAction";
import {flowServices} from "../../service/flowService";
import useCustomStyles from "../utils/UseCustomStyles";

const customStyles = (theme) => ({
    formHeight: {
        minHeight: "300px"
    },
    textArea: {
        width: "100%",
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontSize: 16,
        outlined: "",
        padding: 10.5,
        borderRadius: 4,
        "&:focus": {
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
            outline: 0
        }
    },
    toolTipHelp: theme.toolTipHelp,
    root: {
        width: '100%',
    },
    cancelButton: {
        marginRight: theme.spacing(2),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    bottomBorder: {
        borderBottom: "1px solid rgba(81, 81, 81, 1)"
    },
    flowBox: {
        minWidth: 350,
        marginBottom: 20,
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        }
    },
    pathHeader: {
        height: 48,
        alignItems: "center",
        borderBottom: "1px solid rgba(81, 81, 81, 1)"
    },
    pathTitle: {
        fontSize: 16,
        fontWeight: "bold"
    },
    disabledPathTitle: {
        color: "#656a6e"
    },
    paths: {
        height: 56,
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.background.hover
        }
    },
    landingHeader: {
        height: 48,
        alignItems: "center",
        borderBottom: "1px solid rgba(81, 81, 81, 1)"
    },
    landingTitle: {
        fontSize: 16,
        fontWeight: "bold"
    },
    offerHeader: {
        height: 48,
        alignItems: "center",
        borderBottom: "1px solid rgba(81, 81, 81, 1)"
    },
    offerTitle: {
        fontSize: 16,
        fontWeight: "bold"
    },
    itemsList: {
        height: 50
    },
    divider: {
        height: 28,
        margin: 4,
    },
    errorMsg: {
        margin: "0 24px"
    },
    stepper: {
        margin: "0 auto",
        width: "100%",
        maxWidth: 800,
        padding: "8px 24px 24px 24px"
    },
    stepConnectorLine: {
        borderColor: theme.palette.background.tableBorder
    },
    sectionTitle: {
        padding: 0,
        margin: 0,
        fontSize: 14,
        fontWeight: 600
    },
    verticalDivider: {
        width: 2,
        margin: "0 24px",
        [theme.breakpoints.down(1250)]: {
            display: "none",
            margin: 0,
        }
    },
    treeViewBox: {
        minWidth: "50%",
        maxWidth: "50%",
        [theme.breakpoints.down(1250)]: {
            width: "100%",
            maxWidth: "100%",
        }
    },
    treeView: {
        paddingBottom: theme.spacing(2),
        fontSize: 16,
    },
    treeItem: {
        paddingBottom: theme.spacing(1)
    },
    treeItemLabel: {
        wordWrap: "break-word !important",
        whiteSpace: "normal !important",
        fontSize: "14px !important",
        fontWeight: "400 !important"
    },
    treeItemContent: {
        paddingBottom: theme.spacing(1.5),
    },
    treeViewWeight: {
        color: theme.palette.primary.main
    }
});

function getSteps() {
    return ['Traffic source', 'Campaign details', 'Destination'];
}

function DuplicateCampaign(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const steps = getSteps();
    const [open, setOpen] = useState(true);

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        props.updateSharedFlow(null);
        props.resetForm();
        setOpen(false);
        props.history.push({pathname: "/campaigns", state: {from: props.location}});
    }, [props]);

    const handleBack = () => {
        props.formStepBack(props.formState.activeStep - 1);
    };

    const {formState} = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        async function fetchData(campaignId): void {
            let campaignEntity = await campaignServices.find(campaignId);
            campaignEntity.name = campaignEntity?.name + " - [copy]"
            props.findCampaign(campaignEntity);

            const trafficSourceResponse = await trafficSourceServices.find(campaignEntity?.trafficId);
            props.updateTrafficSource({
                value: trafficSourceResponse.id,
                label: trafficSourceResponse.name,
                custom1: trafficSourceResponse?.custom1Name,
                custom2: trafficSourceResponse?.custom2Name,
                custom3: trafficSourceResponse?.custom3Name,
                custom4: trafficSourceResponse?.custom4Name,
                custom5: trafficSourceResponse?.custom5Name,
                custom6: trafficSourceResponse?.custom6Name,
                custom7: trafficSourceResponse?.custom7Name,
                custom8: trafficSourceResponse?.custom8Name,
                custom9: trafficSourceResponse?.custom9Name,
                custom10: trafficSourceResponse?.custom10Name,
                integrationId: trafficSourceResponse?.integrationId
            });

            const trackingDomainResponse = await trackingDomainServices.find(campaignEntity?.trackingDomainId);
            props.updateTrackingDomain(trackingDomainResponse);

            if (campaignEntity?.cloakingDomainId !== 0) {
                const cloakingDomainResponse = await cloakingDomainServices.find(campaignEntity?.cloakingDomainId);
                props.updateCloakingDomain(cloakingDomainResponse);
            }

            if (campaignEntity?.flowId > 0) {
                const sharedFlow = await flowServices.find(campaignEntity?.flowId);
                if (sharedFlow?.id > 0) {
                    let code = sharedFlow?.countryCode
                    if (code === "") {
                        code = "Global";
                    }
                    props.updateSharedFlow({
                        value: sharedFlow?.id,
                        label: code + " - " + sharedFlow?.name,
                        flow: sharedFlow?.flow
                    });
                }
            }
        }

        if (props.match && props.match.params && props.match.params.hasOwnProperty("id") && formState.id === 0) {
            fetchData(props.match.params.id);
        }

    }, [props, formState.id]);

    useEffect(() => {
        if (formState?.id !== 0 && formState?.id !== undefined && formState?.activeStep === 3) {
            props.updateSharedFlow(null);
            props.resetForm();
            setOpen(false);
            props.history.push({
                pathname: "/campaigns/links/" + parseInt(formState?.id),
                state: {background: {pathname: "/campaigns"}}
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState?.activeStep, formState?.id, props.updateSharedFlow, props.resetForm, props.history])

    const modalMaxWidth = () => {
        if (formState.activeStep === 2) {
            // destination
            return "lg";
        } else if (formState.activeStep === 3) {
            // links
            return "md";
        } else {
            return "sm"
        }
    };

    return (
        <div className={classes?.root}>
            <Dialog open={open}
                    onClose={handleClose}
                    maxWidth={modalMaxWidth()}
                    fullWidth
                    fullScreen={fullScreen}
                    aria-labelledby="form-dialog-title"
            >
                <DialogTitle sx={{textAlign: "center"}}>
                    <Box>{formState?.activeStep === 3 ? "Campaign links" : "Duplicate campaign"}</Box>
                    <Box sx={{fontSize: 16, fontWeight: 400, color: "rgb(138, 147, 159)"}}>
                        {formState?.country?.label + " - " + formState?.trafficSource?.label + " - " + formState?.name}
                    </Box>
                </DialogTitle>

                {formState?.activeStep < 3 &&
                    <Stepper
                        activeStep={formState.activeStep}
                        connector={<StepConnector classes={{line: classes?.stepConnectorLine}}/>}
                        classes={{root: classes?.stepper}}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                }

                {formState.errorMsg.length > 0 &&
                    <Alert className={classes?.errorMsg} variant="outlined"
                           severity="error">{formState.errorMsg}</Alert>}

                {formState.activeStep === 0 &&
                    <FormStep1 {...props} handleClose={handleClose} actionName="duplicate"/>
                }

                {formState.activeStep === 1 &&
                    <FormStep2 {...props} handleBack={handleBack} handleClose={handleClose} classes={classes}/>
                }

                {formState.activeStep === 2 &&
                    <FormStep3 handleBack={handleBack} handleClose={handleClose} classes={classes}
                               actionName="duplicate"/>
                }
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    formState: state.campaignForm,
});

const mapDispatchToProps = {
    rerender: campaignActions.rerender,
    formStep1: campaignFormActions.formStep1,
    formStep2: campaignFormActions.formStep2,
    resetForm: campaignFormActions.resetForm,
    formStepBack: campaignFormActions.formStepBack,
    removeDefaultPath: campaignFormActions.removeDefaultPath,
    updateSelectedPath: campaignFormActions.updateSelectedPath,
    findCampaign: campaignFormActions.findCampaign,
    updateTrafficSource: campaignFormActions.updateTrafficSource,
    updateTrackingDomain: campaignFormActions.updateTrackingDomain,
    updateCloakingDomain: campaignFormActions.updateCloakingDomain,
    updateConversionActionEvent: campaignFormActions.updateConversionActionEvent,
    updateConversionActionPixel: campaignFormActions.updateConversionActionPixel,
    resetConversionActionEventAndPixel: campaignFormActions.resetConversionActionEventAndPixel,
    updateSharedFlow: flowActions.updateSharedFlow,
    updateTrafficSourceIntegration: campaignFormActions.updateTrafficSourceIntegration,
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateCampaign);
