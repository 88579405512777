import {Box, Button, DialogActions, DialogContent, FormHelperText, TextField} from "@mui/material";
import React from "react";
import CountrySelect from "../utils/CountrySelect";
import {useForm} from "react-hook-form";
import TrackingDomainSelect from "./TrackingDomainSelect";
import WorkspaceDropdown from "../workspace/WorkspaceDropdown";
import ReactHookTagsSelect from "../tags/ReactHookTagsSelect";
import {Info} from "@mui/icons-material";
import {CustomTooltip} from "../landings/LandingForm";

export const FormStep2 = (props) => {
    const {handleClose, handleBack} = props;
    const {trafficSource, workspaceId} = props.formState;

    const {register, handleSubmit, control, watch, setValue, getValues, formState: {errors}} = useForm({
        defaultValues: props.formState
    });

    const onSubmit = (data) => {
        props.formStep2(data?.workspace, data?.name, data?.country, data?.trackingDomain, data?.tags);
    };

    return (
        <>
            <DialogContent sx={{minHeight: "200px"}}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                    <Box display="flex" mb={2}>
                        <Box flexGrow={1}>
                            <WorkspaceDropdown
                                errors={errors} control={control} workspaceId={workspaceId} setValue={setValue}
                            />
                        </Box>
                        <Box p={1}>
                            <Box pt="10px">
                                <CustomTooltip
                                    disableFocusListener
                                    title="Workspace allows to make the required campaign accessible only by a specific user or group of users."
                                >
                                    <Info color={"primary"} fontSize={"small"}/>
                                </CustomTooltip>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" flexWrap="noWrap">
                            <Box flexGrow={1}>
                                <TextField
                                    error={!!errors.name}
                                    variant="outlined"
                                    margin="dense"
                                    id="name"
                                    {...register("name", {required: true, maxLength: 255, minLength: 3})}
                                    name="name"
                                    size="small"
                                    type="text"
                                    placeholder="Enter a campaign name"
                                    label="Campaign name*"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box p={1}>
                                <Box pt="10px">
                                    <CustomTooltip
                                        disableFocusListener
                                        title="The first part of name is generated automatically and depends on the selected traffic source and country."
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box flexGrow={1}>
                                <FormHelperText sx={{paddingBottom: "10px", paddingLeft: "14px"}}>
                                    {trafficSource !== null ? trafficSource.label : "None"} - {watch("country") !== null && watch("country") !== "" ? watch("country").label : "Global"} - {watch("name")}
                                </FormHelperText>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column">
                        <Box>
                            <CountrySelect
                                control={control}
                                title="Country select is required to navigate easily through the offers and landings list. This selection does not affect the traffic distribution."
                            />
                        </Box>
                        <Box>
                            <TrackingDomainSelect control={control} errors={errors} setValue={setValue} watch={watch}/>
                        </Box>
                        <Box mt={2} mb={2}>
                            <ReactHookTagsSelect
                                control={control}
                                inputName="tags"
                                inputLabel="Tags"
                                inputLabelShrink={true}
                                getValues={getValues}
                            />
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined" ml={2}>
                    Cancel
                </Button>
                <Button onClick={handleBack} color="inherit" variant="contained">
                    Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                    Next
                </Button>
            </DialogActions>
        </>
    );
};
