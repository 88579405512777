import {authActions} from "../../store/actions/authAction";
import {metricFilterActions} from "../../store/actions/metricFilterAction";
import {columnActions} from "../../store/actions/columnAction";
import {workspaceActions} from "../../store/actions/workspaceAction";
import {jwtDecode} from "jwt-decode";

export default function authMiddleware(store) {
    let accessToken = localStorage.getItem("access-token");
    if (accessToken !== undefined && accessToken !== "" && accessToken !== null) {
        let payload = jwtDecode(accessToken);
        let currentTime = parseInt(new Date().getTime() / 1000);
        if (payload?.hasOwnProperty("exp") && payload.exp >= currentTime) {
            store.dispatch(authActions.updateAuthenticationStatus(payload));
            store.dispatch(authActions.loadUserSettings());
            store.dispatch(authActions.fetchSubscription());
            store.dispatch(workspaceActions.getUserWorkspaces());
            store.dispatch(columnActions.getReportColumns());
            store.dispatch(metricFilterActions.setMetricFiltersFromCache());
        }
    }
}