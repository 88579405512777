import React, {useEffect, useState} from "react";
import Topbar from "../../layout/Topbar";
import SettingsSidebar from "../SettingsSidebar";
import {connect} from "react-redux";
import CustomSnackbar from "../../alert/CustomSnackbar";
import {
    Box,
    Button, Checkbox, FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    CssBaseline,
    Chip, useTheme, Typography
} from "@mui/material";
import Spinner from "../../spinner/Spinner";
import {Delete, Edit, RestoreFromTrash} from "@mui/icons-material";
import CustomEventForm from "./CustomEventForm";
import {customEventActions} from "../../../store/actions/customEventActions";
import AlertDialog from "../../alert/AlertDialog";
import {SettingsContentBox, SettingsRoot} from "../General";
import {SettingsGeneralHeader, SettingsGeneralPaper} from "../ProfileSettings";

function CustomEvents(props) {
    const theme = useTheme();

    const {customEvent} = props.state;

    const [isDeleted, setIsDeleted] = useState(false);
    const [editEventID, setEditEventID] = useState(0);
    const [prompt, setPrompt] = useState(false);
    const [archiveItem, setArchiveItem] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOnClose = () => {
        setEditEventID(0);
        setOpen(false);
    }
    const handleOnClick = () => setOpen(true);
    const handleViewArchived = (v) => {
        if (v === true) {
            setIsDeleted(true);
        } else {
            setIsDeleted(false);
        }
    };

    const handelEditEvent = (rowID) => {
        setEditEventID(rowID);
        handleOnClick();
    };

    const handleOnClickToPrompt = (row) => {
        setPrompt(true);
        setArchiveItem(row);
    };

    const handleOnClickToArchive = (confirm) => {
        if (!confirm) {
            setPrompt(false);
            setArchiveItem(null);
            return false;
        }

        props?.archive(archiveItem?.id);
        setPrompt(false);
        setArchiveItem(null);
    };

    const getChip = (events) => {
        let values = events?.split(",");
        return values?.map((v) => {
            return <Chip key={v} label={v} color="primary" size="small" variant="outlined"/>;
        });
    };

    useEffect(() => {
        props.fetch({isDeleted});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleted]);

    useEffect(() => {
        if (customEvent?.rerender) {
            props.fetch({isDeleted});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customEvent?.rerender]);

    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                    {props.state.alert.errorMsg !== "" &&
                        <CustomSnackbar message={props.state.alert.errorMsg}/>
                    }
                    {props.state.alert.successMsg !== "" &&
                        <CustomSnackbar message={props.state.alert.successMsg} severity="success"/>
                    }
                    {prompt &&
                        <AlertDialog
                            question="Archive confirmation"
                            text={"Are you sure you want to archive this custom conversion event?"}
                            confirm={handleOnClickToArchive}/>
                    }
                    <Box display="flex" flexDirection="column" flexWrap="noWrap" sx={{
                        width: "100%",
                        padding: "16px 30px 0 30px",
                        maxWidth: "1280px",
                        minWidth: "300px",
                        margin: "0 auto",
                        [theme.breakpoints.down(600)]: {
                            padding: 0
                        }
                    }}>

                        <SettingsGeneralHeader>Custom Conversions</SettingsGeneralHeader>

                        <SettingsGeneralPaper elevation={0}>
                            <Box display="flex" flexDirection="column" flexWrap="wrap">
                                <Box textAlign="right" sx={{top: 8, position: "relative"}}>
                                    {open &&
                                        <CustomEventForm
                                            onClose={handleOnClose}
                                            open={open}
                                            editEventID={editEventID}
                                        />
                                    }
                                    <Button variant="contained" color="primary" onClick={handleOnClick}>
                                        Create
                                    </Button>
                                </Box>
                                <Box sx={{
                                    marginTop: "-36px",
                                    paddingLeft: "4px",
                                    [theme.breakpoints.down(450)]: {
                                        marginTop: "-8px",
                                    }
                                }}>
                                    <Typography variant="h3" sx={{
                                        textAlign: "left",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        display: "block",
                                        paddingTop: "16px",
                                        paddingBottom: "24px"
                                    }}>
                                        Manage Your Custom Conversion Events
                                    </Typography>
                                </Box>
                                <Box ml={0.5} sx={{color: "text.disabledLight"}}>
                                    With custom events feature it is possible to track different types of
                                    conversions: e.g. installs, registrations, purchase, upsell, deposits etc. and
                                    see the detailed data in reports. You can create up to 20 custom events
                                    depending on your subscription plan.
                                </Box>
                                <Box mt={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isDeleted}
                                                onChange={(e) => handleViewArchived(e.target.checked)}
                                                name="showArchived"
                                                color="primary"
                                            />
                                        }
                                        label="View Archived"
                                    />
                                </Box>
                            </Box>
                            <Box sx={{
                                width: "100%",
                                overflow: "auto",
                                whiteSpace: "nowrap"
                            }}>
                                <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                                    <Box mt={1} flexGrow={1}>
                                        {customEvent?.isLoading && <Spinner overComponentBox={true}/>}
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nr.</TableCell>
                                                        <TableCell>Event Title</TableCell>
                                                        <TableCell align="left">Event Values</TableCell>
                                                        <TableCell align="left">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {customEvent?.data?.length > 0 &&
                                                    <TableBody sx={{
                                                        overflow: "auto",
                                                        whiteSpace: "nowrap"
                                                    }}>
                                                        {customEvent?.data?.map((row, idx) => (
                                                            <TableRow key={row?.id}>
                                                                <TableCell>
                                                                    {idx + 1}
                                                                </TableCell>
                                                                <TableCell sx={{
                                                                    minWidth: "100px",
                                                                    maxWidth: "400px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                }}>
                                                                    {row?.name}
                                                                </TableCell>
                                                                <TableCell align="left" sx={{
                                                                    minWidth: "350px",
                                                                    maxWidth: "400px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                }}>
                                                                    {getChip(row?.events)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {!row?.isDeleted &&
                                                                        <Box display="flex" flexDirection="row"
                                                                             flexWrap="noWrap"
                                                                             justifyContent="right">
                                                                            <Box>
                                                                                <Tooltip title="Edit">
                                                                                    <Edit
                                                                                        color="inherit"
                                                                                        fontSize="small"
                                                                                        sx={{cursor: "pointer"}}
                                                                                        onClick={() => handelEditEvent(row?.id)}
                                                                                    />
                                                                                </Tooltip>
                                                                            </Box>
                                                                            <Box ml={2}>
                                                                                <Tooltip title="Archive">
                                                                                    <Delete
                                                                                        color="error"
                                                                                        fontSize="small"
                                                                                        sx={{cursor: "pointer"}}
                                                                                        onClick={() => handleOnClickToPrompt(row)}
                                                                                    />
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    {row?.isDeleted &&
                                                                        <Box display="flex" flexDirection="row"
                                                                             flexWrap="noWrap"
                                                                             justifyContent="right">
                                                                            <Box ml={2}>
                                                                                <Tooltip title="Restore">
                                                                                    <RestoreFromTrash
                                                                                        color="primary"
                                                                                        fontSize="small"
                                                                                        sx={{cursor: "pointer"}}
                                                                                        onClick={() => props?.restore(row?.id)}
                                                                                    />
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                }
                                            </Table>
                                            {customEvent?.data?.length === 0 &&
                                                <Box sx={{textAlign: "center", pt: 3, pb: 2}}>No records found</Box>
                                            }
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </Box>
                        </SettingsGeneralPaper>
                    </Box>
                </Box>
            </SettingsContentBox>
        </SettingsRoot>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            alert: state.alert,
            customEvent: state.customEvent,
        }
    };
};

const mapDispatchToProps = {
    fetch: customEventActions.fetch,
    archive: customEventActions.archive,
    restore: customEventActions.restore,
    rerender: customEventActions.rerender
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomEvents);