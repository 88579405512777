import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import PropTypes from "prop-types";
import {integrationService} from "../../service/integrationService";
import Spinner from "../spinner/Spinner";
import {Autocomplete, TextField} from "@mui/material";

function IntegrationSelect({control, setValue, errors, trafficSourceData, setRequestError}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [findIntegrationLoading, setFindIntegrationLoading] = useState(false);

    useEffect(() => {
        if (trafficSourceData?.integrationId > 0) {
            setFindIntegrationLoading(true);
            integrationService.findIntegration(trafficSourceData?.integrationId)
                .then((result) => {
                    let accountId = "";
                    let adAccountName = "";
                    result?.integrationCredentials.forEach(c => {
                        if (c?.keyName === "fb_ad_account_id" || c?.keyName === "google_ad_account_id" || c?.keyName === "microsoft_ad_account_id" || c?.keyName === "taboola_account_id" || c?.keyName === "tt_ad_account_id") {
                            accountId = c?.keyValue;
                        }
                        if (c?.keyName === "fb_ad_account_name" || c?.keyName === "google_ad_account_name" || c?.keyName === "microsoft_ad_account_name" || c?.keyName === "outbrain_account_name" || c?.keyName === "tt_ad_account_name") {
                            adAccountName = c?.keyValue;
                        }
                        if (c?.keyName === "mgid_account_id") {
                            accountId = c?.keyValue;
                        }
                    });

                    let label = result?.networkName;
                    if (accountId !== "") {
                        label += " | " + accountId;
                    }
                    if (adAccountName !== "") {
                        label += " | " + adAccountName;
                    }

                    setValue("integrationId", {value: result?.id, label: label});
                }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error?.response?.data?.error);
                }
            }).finally(() => {
                setFindIntegrationLoading(false);
            });
        }
    }, [trafficSourceData, control, setRequestError, setValue])

    useEffect(() => {
        if (open && options?.length === 0) {
            setLoading(true);
            integrationService.getIntegrations({category: "traffic-source"}).then((result) => {
                setOptions(
                    result.map(i => {
                        let accountId = "";
                        let adAccountName = "";
                        i?.integrationCredentials.forEach(c => {
                            if (c?.keyName === "fb_ad_account_id" || c?.keyName === "google_ad_account_id" || c?.keyName === "microsoft_ad_account_id" || c?.keyName === "taboola_account_id" || c?.keyName === "tt_ad_account_id" || c?.keyName === "mgid_account_id") {
                                accountId = c?.keyValue;
                            }
                            if (c?.keyName === "fb_ad_account_name" || c?.keyName === "google_ad_account_name" || c?.keyName === "microsoft_ad_account_name" || c?.keyName === "outbrain_account_name" || c?.keyName === "tt_ad_account_name") {
                                adAccountName = c?.keyValue;
                            }
                        });

                        let label = i?.networkName;
                        if (accountId !== "") {
                            label += " | " + accountId;
                        }
                        if (adAccountName !== "") {
                            label += " | " + adAccountName;
                        }

                        return {
                            value: i?.id,
                            label: label
                        }
                    })
                );
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error?.response?.data?.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, setRequestError]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="integration-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    getOptionLabel={(option) => option?.label ? option.label : ""}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <>
                            {findIntegrationLoading ? <Spinner/> :
                                <TextField
                                    {...params}
                                    error={!!errors?.integrationId}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    placeholder="Choose an integration"
                                    value={search}
                                    onChange={e => handleSearch(e.target.value)}
                                />
                            }
                        </>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            name="integrationId"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

IntegrationSelect.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    trafficSourceData: PropTypes.object,
    setRequestError: PropTypes.func
};

export default IntegrationSelect;