import React from "react";
import {CssBaseline} from "@mui/material";
import Topbar from "../../layout/Topbar";
import SettingsSidebar from "../SettingsSidebar";
import ReferAndEarn from "./ReferAndEarn";
import {SettingsContentBox, SettingsRoot} from "../General";

function Referral(props) {
    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <ReferAndEarn/>
            </SettingsContentBox>
        </SettingsRoot>
    );
}

export default Referral;