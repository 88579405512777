import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export default function AlertDialog({confirm, question, text, buttonColor = "primary"}) {

    const handleConfirmation = (bool) => {
        confirm(bool);
    };

    return (
        <Dialog
            open={true}
            onClose={() => handleConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
            <DialogContent>{text}</DialogContent>
            <DialogActions>
                <Button onClick={() => handleConfirmation(false)} color="inherit">
                    Cancel
                </Button>
                <Button onClick={() => handleConfirmation(true)} variant="contained" color={buttonColor} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}