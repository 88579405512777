import {campaignConstants} from "../constants/campaignsConstants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        total: {}
    },
    errorMsg: "",
    isLoading: false,
    name: "",
    tags: [],
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false,
    selectedRows: new Set()
};

const campaignsReducer = (state = initialState, action) => {
    switch (action.type) {
        case campaignConstants.CAMPAIGNS_LOADING_SPINNER:
            return {
                ...state,
                isLoading: true,
            };
        case campaignConstants.CAMPAIGNS_STOP_LOADING_SPINNER:
            return {
                ...state,
                isLoading: false,
            };
        case campaignConstants.CAMPAIGNS_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false
            };
        case campaignConstants.CAMPAIGNS_GET_DATA:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case campaignConstants.CAMPAIGNS_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load campaigns. Please try again.',
                rerender: false,
                isLoading: false,
            };
        case campaignConstants.CAMPAIGNS_ARCHIVE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case campaignConstants.CAMPAIGNS_RESTORE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case campaignConstants.CAMPAIGNS_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case campaignConstants.CAMPAIGNS_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case campaignConstants.CAMPAIGNS_UPDATE_NAME:
            return {
                ...state,
                name: action.name
            };
        case campaignConstants.CAMPAIGNS_UPDATE_TAGS:
            return {
                ...state,
                tags: action.tags
            };
        case campaignConstants.CAMPAIGNS_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case campaignConstants.CAMPAIGNS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        case campaignConstants.CAMPAIGNS_SET_SELECTED_ROW:
            return {
                ...state,
                selectedRows: action.selectedRows
            };
        case campaignConstants.BULK_UPDATE_CHANGE_TRACKING_DOMAIN:
            return {
                ...state,
                isLoading: false,
            };
        case campaignConstants.BULK_UPDATE_CHANGE_WORKSPACE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default campaignsReducer;