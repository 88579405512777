import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import {LandingForm} from "./LandingForm";
import {landingServices} from "../../service/landingService";

function LandingAddPopUp({onClose, open}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);

    const {register, handleSubmit, setValue, watch, control, getValues, formState: {errors}} = useForm();

    const handleOnSubmit = async (data) => {
        setLoading(true);

        let name = "";
        if (data.name) {
            name = "Global - " + data.name;
            if (data?.country?.label !== undefined) {
                name = data?.country?.label + " - " + data?.name
            }
        }

        let formData = {
            name,
            countryCode: data?.country?.code ? data.country.code : "",
            url: data?.url ? data.url : "",
            workspaceId: data?.workspace?.value,
            tags: data?.tags ? data?.tags : [],
        };

        await landingServices.create(formData).then(() => {
            handleOnClose();
        }).catch((e) => {
            if (e?.response?.data?.error !== undefined) {
                setRequestError(e.response.data.error);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleOnClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setRequestError("");
        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleOnClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
        >
            <LandingForm
                title="Add new landing"
                open={open}
                watch={watch}
                control={control}
                register={register}
                handleClose={handleOnClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={handleOnSubmit}
                loading={loading}
                setValue={setValue}
                getValues={getValues}
            />
        </Dialog>
    );
}

LandingAddPopUp.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default LandingAddPopUp;