import {customEventConstants} from "../constants/customEventConstants";
import {customEventServices} from "../../service/customEventService";
import {columnActions} from "./columnAction";
import {alertConstants} from "../constants/alertConstants";
import {alertActions} from "./alertActions";

export const customEventActions = {
    fetch,
    archive,
    restore,
    rerender
};

function fetch({isDeleted}) {
    return dispatch => {
        dispatch({type: alertConstants.CLEAR});
        dispatch({type: customEventConstants.CUSTOM_EVENT_LOADING_SPINNER});
        customEventServices.fetch({isDeleted})
            .then((result) => {
                dispatch({type: customEventConstants.CUSTOM_EVENT_GET_DATA, result});
            }).catch((error) => {
                dispatch({type: alertConstants.ERROR, error});
            }
        );
    };
}

function archive(id) {
    return dispatch => {
        dispatch({type: alertConstants.CLEAR});
        dispatch({type: customEventConstants.CUSTOM_EVENT_LOADING_SPINNER});
        customEventServices.archive({id})
            .then(() => {
                dispatch({type: customEventConstants.CUSTOM_EVENT_ARCHIVE});
                dispatch(columnActions.getReportColumns());
            }).catch((error) => {
                dispatch({type: customEventConstants.CUSTOM_EVENT_STOP_LOADING_SPINNER});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function restore(id) {
    return dispatch => {
        dispatch({type: alertConstants.CLEAR});
        dispatch({type: customEventConstants.CUSTOM_EVENT_LOADING_SPINNER});
        customEventServices.restore({id})
            .then(() => {
                dispatch({type: customEventConstants.CUSTOM_EVENT_RESTORE});
                dispatch(columnActions.getReportColumns());
            }).catch((error) => {
                dispatch({type: customEventConstants.CUSTOM_EVENT_STOP_LOADING_SPINNER});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: customEventConstants.CUSTOM_EVENT_RERENDER});
    };
}
