import {authServices} from "../../service/authService";
import {history} from '../../helpers/history';
import {authConstants} from "../constants/authConstants";
import {settingsServices} from "../../service/settingsService";
import {subscriptionServices} from "../../service/subscriptionService";
import {postbackDomain} from "../../helpers/config";
import {columnActions} from "./columnAction";
import {metricFilterActions} from "./metricFilterAction";
import {workspaceActions} from "./workspaceAction";
import {jwtDecode} from "jwt-decode";

export const authActions = {
    updateAuthenticationStatus,
    verifyEmailLogin,
    logout,
    updateUserSettings,
    verifyGoogleLogin,
    fetchSubscription,
    loadUserSettings,
    updateTimezone
};

function updateAuthenticationStatus(user) {
    return async dispatch => {
        await dispatch({type: authConstants.UPDATE_AUTHENTICATION_STATUS, user});
    };
}

function loadUserSettings() {
    return async dispatch => {
        await settingsServices.fetchUserSettings().then((userSettings) => {
            localStorage.setItem('user-settings', JSON.stringify({
                    timezone: userSettings?.timezone,
                    currencyCode: userSettings?.currencyCode,
                    defaultTrackingDomain: userSettings?.defaultTrackingDomain,
                    role: userSettings?.role,
                }
            ));
            dispatch({type: authConstants.UPDATE_USER_SETTINGS, userSettings});
        });
    };
}

function fetchSubscription() {
    return async dispatch => {
        await subscriptionServices.fetchSubscription(false, false, false).then((subscription) => {
            dispatch({type: authConstants.UPDATE_SUBSCRIPTION, subscription});
        });
    };
}

function verifyEmailLogin(deviceId, token) {
    return async dispatch => {
        await authServices.verifyEmailLogin(deviceId, token)
            .then((accessToken) => {
                let user = jwtDecode(accessToken);
                settingsServices.fetchUserSettings().then((userSettings) => {
                    if (userSettings?.id === 0) {
                        settingsServices.updateUserSettings({
                            timezone: "Etc/UTC",
                            currencyCode: "EUR",
                            defaultTrackingDomain: postbackDomain
                        }).then((userSettings) => {
                            localStorage.setItem('user-settings', JSON.stringify({
                                    timezone: userSettings?.timezone,
                                    currencyCode: userSettings?.currencyCode,
                                    defaultTrackingDomain: userSettings?.defaultTrackingDomain,
                                    role: userSettings?.role
                                }
                            ));
                            settingsServices.updateCompanySettings({
                                baseCurrencyCode: "EUR",
                                conversionTime: "postback"
                            }).then(() => {
                                subscriptionServices.fetchSubscription(true, true, false).then((subscription) => {
                                    dispatch(workspaceActions.getUserWorkspaces());
                                    dispatch(columnActions.getReportColumns());
                                    dispatch(metricFilterActions.setMetricFiltersFromCache());
                                    dispatch({type: authConstants.UPDATE_SUBSCRIPTION, subscription});
                                    dispatch({type: authConstants.VERIFY_EMAIL_LOGIN_SUCCESS, payload: {user}});
                                    dispatch({type: authConstants.UPDATE_USER_SETTINGS, userSettings});
                                    history.push('/');
                                });
                            });
                        });
                    } else {
                        localStorage.setItem('user-settings', JSON.stringify({
                                timezone: userSettings?.timezone,
                                currencyCode: userSettings?.currencyCode,
                                defaultTrackingDomain: userSettings?.defaultTrackingDomain,
                                role: userSettings?.role
                            }
                        ));
                        settingsServices.fetchCompanySettings().then((companySettings) => {
                            if (companySettings?.id === 0) {
                                settingsServices.updateCompanySettings({
                                    baseCurrencyCode: "EUR",
                                    conversionTime: "postback"
                                }).then(() => {
                                    subscriptionServices.fetchSubscription(true, true, false).then((subscription) => {
                                        dispatch(workspaceActions.getUserWorkspaces());
                                        dispatch(columnActions.getReportColumns());
                                        dispatch(metricFilterActions.setMetricFiltersFromCache());
                                        dispatch({type: authConstants.UPDATE_SUBSCRIPTION, subscription});
                                        dispatch({type: authConstants.VERIFY_EMAIL_LOGIN_SUCCESS, payload: {user}});
                                        dispatch({type: authConstants.UPDATE_USER_SETTINGS, userSettings});
                                        history.push('/');
                                    });
                                });
                            } else {
                                subscriptionServices.fetchSubscription(true, true, false).then((subscription) => {
                                    dispatch(workspaceActions.getUserWorkspaces());
                                    dispatch(columnActions.getReportColumns());
                                    dispatch(metricFilterActions.setMetricFiltersFromCache());
                                    dispatch({type: authConstants.UPDATE_SUBSCRIPTION, subscription});
                                    dispatch({type: authConstants.VERIFY_EMAIL_LOGIN_SUCCESS, payload: {user}});
                                    dispatch({type: authConstants.UPDATE_USER_SETTINGS, userSettings});
                                    history.push('/');
                                });
                            }
                        });
                    }
                });
            }).catch((error) => {
                    let errorMessage = error?.response?.data?.error;
                    dispatch({type: authConstants.VERIFY_EMAIL_LOGIN_ERROR, errorMessage});
                }
            );
    };
}

function verifyGoogleLogin(applicationName, state, code) {
    return async dispatch => {
        await authServices.verifyGoogleLogin(applicationName, state, code)
            .then((accessToken) => {
                let user = jwtDecode(accessToken);
                settingsServices.fetchUserSettings().then((userSettings) => {
                    if (userSettings?.id === 0) {
                        settingsServices.updateUserSettings({
                            timezone: "Etc/UTC",
                            currencyCode: "EUR",
                            defaultTrackingDomain: postbackDomain
                        }).then((userSettings) => {
                            localStorage.setItem('user-settings', JSON.stringify({
                                    timezone: userSettings?.timezone,
                                    currencyCode: userSettings?.currencyCode,
                                    defaultTrackingDomain: userSettings?.defaultTrackingDomain,
                                    role: userSettings?.role
                                }
                            ));
                            settingsServices.updateCompanySettings({
                                baseCurrencyCode: "EUR",
                                conversionTime: "postback"
                            }).then(() => {
                                subscriptionServices.fetchSubscription(true, true, false).then((subscription) => {
                                    dispatch(workspaceActions.getUserWorkspaces());
                                    dispatch(columnActions.getReportColumns());
                                    dispatch(metricFilterActions.setMetricFiltersFromCache());
                                    dispatch({type: authConstants.UPDATE_SUBSCRIPTION, subscription});
                                    dispatch({type: authConstants.VERIFY_EMAIL_LOGIN_SUCCESS, payload: {user}});
                                    dispatch({type: authConstants.UPDATE_USER_SETTINGS, userSettings});
                                    history.push('/');
                                });
                            });
                        });
                    } else {
                        localStorage.setItem('user-settings', JSON.stringify({
                                timezone: userSettings?.timezone,
                                currencyCode: userSettings?.currencyCode,
                                defaultTrackingDomain: userSettings?.defaultTrackingDomain,
                                role: userSettings?.role
                            }
                        ));
                        settingsServices.fetchCompanySettings().then((companySettings) => {
                            if (companySettings?.id === 0) {
                                settingsServices.updateCompanySettings({
                                    baseCurrencyCode: "EUR",
                                    conversionTime: "postback"
                                }).then(() => {
                                    subscriptionServices.fetchSubscription(true, true, false).then((subscription) => {
                                        dispatch(workspaceActions.getUserWorkspaces());
                                        dispatch(columnActions.getReportColumns());
                                        dispatch(metricFilterActions.setMetricFiltersFromCache());
                                        dispatch({type: authConstants.UPDATE_SUBSCRIPTION, subscription});
                                        dispatch({type: authConstants.VERIFY_EMAIL_LOGIN_SUCCESS, payload: {user}});
                                        dispatch({type: authConstants.UPDATE_USER_SETTINGS, userSettings});
                                        history.push('/');
                                    });
                                });
                            } else {
                                subscriptionServices.fetchSubscription(true, true, false).then((subscription) => {
                                    dispatch(workspaceActions.getUserWorkspaces());
                                    dispatch(columnActions.getReportColumns());
                                    dispatch(metricFilterActions.setMetricFiltersFromCache());
                                    dispatch({type: authConstants.UPDATE_SUBSCRIPTION, subscription});
                                    dispatch({type: authConstants.VERIFY_EMAIL_LOGIN_SUCCESS, payload: {user}});
                                    dispatch({type: authConstants.UPDATE_USER_SETTINGS, userSettings});
                                    history.push('/');
                                });
                            }
                        });
                    }
                });
            }).catch((error) => {
                    let errorMessage = error?.response?.data?.error;
                    dispatch({type: authConstants.VERIFY_GOOGLE_LOGIN_ERROR, errorMessage});
                }
            );
    };
}

function logout() {
    return dispatch => {
        authServices.logout();
        dispatch({type: authConstants.LOGOUT});
        history.push('/login');
    };
}

function updateUserSettings(userSettings) {
    return async dispatch => {
        localStorage.setItem("user-settings", JSON.stringify({
            timezone: userSettings?.timezone,
            currencyCode: userSettings?.currencyCode,
            defaultTrackingDomain: userSettings?.defaultTrackingDomain,
            role: userSettings?.role
        }));
        await dispatch({type: authConstants.UPDATE_USER_SETTINGS, userSettings});
    };
}

function updateTimezone(timezone) {
    return dispatch => {
        dispatch({type: authConstants.USER_SETTINGS_UPDATE_TIMEZONE, timezone});
    };
}
