import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from "react-redux";
import {countries} from "../utils/DataList";
import {landingActions} from "../../store/actions/landingAction";
import {landingServices} from "../../service/landingService";
import {LandingForm} from "./LandingForm";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";

function DuplicateLanding(props) {
    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState(null);
    const [landingData, setLandingData] = useState(null);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {register, handleSubmit, control, watch, setValue, getValues, formState: {errors}} = useForm();

    const handleClose = useCallback(() => {
        setOpen(false);
        props.history.push({pathname: "/landings", state: {from: props.location}});
    }, [props.history, props.location]);

    useEffect(() => {
        async function find(id): void {
            setRequestError("");
            setLoading(true);
            await landingServices.find(id).then((response) => {
                if (response?.id > 0) {
                    setLandingData(response);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
                setLoading(false);
            });
        }

        if (props?.match?.params?.id && landingData === null) {
            find(props.match.params.id);
        }

    }, [landingData, props]);

    useEffect(() => {
        if (landingData !== null) {
            setDialogTitle("Duplicate: " + landingData?.name);
            setValue("name", landingData?.name?.slice(landingData?.name?.indexOf("-") + 2) + " - [copy]");
            setValue("url", landingData?.url);
            setValue("offers", landingData?.offers);
            setValue("tags", landingData?.tags);

            if (landingData?.countryCode !== "") {
                countries?.forEach((item) => {
                    if (item?.code === landingData?.countryCode) {
                        setValue("country", item)
                    }
                });
            }
        }
    }, [landingData, setValue]);

    useEffect(() => {
        async function duplicate(): void {
            setRequestError("");
            setLoading(true);
            await landingServices.create(formData).then((response) => {
                if (response?.id > 0) {
                    props.rerender(true);
                    setLoading(false);
                    handleClose();
                } else {
                    setLoading(false);
                }
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    setRequestError(e.response.data.error);
                }
                setLoading(false);
            });
        }

        if (formData !== null) {
            duplicate();
        }

    }, [handleClose, formData, props]);

    const onSubmit = (data) => {
        let name = "";
        if (data?.name) {
            name = "Global - " + data?.name;
            if (data?.country && data?.country?.label) {
                name = data?.country?.label + " - " + data?.name;
            }
        }

        setFormData({
            countryCode: data?.country && data?.country?.code ? data?.country?.code : "",
            name,
            url: data?.url ? data?.url : "",
            tags: data?.tags ? data?.tags : [],
            offers: data?.offers >= 1 ? parseInt(data?.offers) : 1,
            workspaceId: data?.workspace?.value,
        });
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
        >
            <LandingForm
                title={dialogTitle}
                open={open}
                watch={watch}
                control={control}
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                landingData={landingData}
                getValues={getValues}
            />
        </Dialog>
    );
}

const mapDispatchToProps = {
    rerender: landingActions.rerender
};

export default connect(null, mapDispatchToProps)(DuplicateLanding);