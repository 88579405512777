import {Button, Paper, Box} from "@mui/material";
import React, {useState, useEffect} from "react";
import {CopyTextToClipboard} from "../copy/CopyTextToClipboard";
import {alertActions} from "../../store/actions/alertActions";
import {connect} from "react-redux";
import {settingsServices} from "../../service/settingsService";
import Spinner from "../spinner/Spinner";
import AlertDialog from "../alert/AlertDialog";
import {landingProtectionActions} from "../../store/actions/landingProtectionAction";

function GenerateLandingProtection(props) {
    const [prompt, setPrompt] = useState(false);

    const handleGenerateTokenPrompt = () => {
        setPrompt(true);
    };

    const handleOnClickGenerateToken = async (prompt) => {

        if (!prompt) {
            setPrompt(false);
            return false;
        }

        setPrompt(false);
        props?.setLoading(true);
        props.clearMessage();
        await settingsServices.updateLandingProtection().then((response) => {
            if (response !== null && response !== undefined) {
                props?.updateToken(response?.landingProtection);
                props.success("New landing protection token has been successfully created.");
            }
        }).catch((error) => {
            if (error?.response?.status === 403) {
                props.error("Access forbidden. You have no rights to update bot redirect settings.");
            } else if (error?.response?.data?.error) {
                props.error(error.response.data.error);
            }
        }).finally(() => {
            props?.setLoading(false);
        });
    };

    const handleCodeCopy = () => {
        CopyTextToClipboard(props?.landingProtection?.token).then(() => {
            props?.success("Landing protection token is copied to clipboard.");
        }).catch(() => {
            props?.error("Unable to copy.");
        });
    };

    useEffect(() => {
        async function fetchCompanySettings() {
            props.clearMessage();
            props?.setLoading(true);
            await settingsServices.fetchCompanySettings().then((response) => {
                if (response !== null && response !== undefined) {
                    props?.updateToken(response?.landingProtection);
                }
            }).catch((error) => {
                if (error?.response?.status === 403) {
                    props.error("Access forbidden. You have no rights to fetch bot redirect settings.");
                } else if (error?.response?.data?.error) {
                    props.error(error.response.data.error);
                }
            }).finally(() => {
                props?.setLoading(false);
            });
        }

        fetchCompanySettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{
            width: "100%",
            padding: "8px 8px 16px 8px",
            marginLeft: "16px",
            marginRight: "16px",
            minWidth: 300,
            margin: "0 auto",
            backgroundColor: "background.defaultBlue",
            borderRadius: "4px"
        }}>
            <Box display="flex" ml={1} mr={1}>

                {props?.landingProtection?.isLoading &&
                    <Spinner size={32} overComponentBox={true}/>
                }

                {prompt &&
                    <AlertDialog
                        question="Confirmation"
                        text={"Are you sure you want to create a new Landing Protection token?"}
                        confirm={handleOnClickGenerateToken}
                    />
                }

                <Box flexGrow={1} sx={{fontSize: "1.17em", fontWeight: 600}} pt={1} mb={2}>
                    Protection Token
                </Box>
                <Box alignSelf="center">
                    <Button
                        type="button"
                        onClick={() => handleGenerateTokenPrompt(true)}
                        color="secondary"
                        variant="contained"
                    >
                        Generate Token
                    </Button>
                </Box>
            </Box>
            <Box mt={1}>
                <Paper elevation={0} sx={{position: "relative", boxShadow: 0}}>
                    <Box display="flex" flexDirection="row" ml={1} mr={1}>
                        <Box p={1} flexGrow={1} mr={1} sx={{backgroundColor: "background.input", borderRadius: "4px", overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",}}>
                            {props?.landingProtection?.token}
                        </Box>
                        <Box>
                            <Button onClick={handleCodeCopy} color="primary" variant="contained">
                                Copy
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        landingProtection: state.landingProtection
    }
};

const mapDispatchToProps = {
    error: alertActions.error,
    success: alertActions.success,
    clearMessage: alertActions.clear,
    updateToken: landingProtectionActions.updateToken,
    setLoading: landingProtectionActions.updateLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateLandingProtection);