import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    List,
    ListItem,
    StepConnector,
    Switch,
    TextField,
    useMediaQuery,
    useTheme,
    ListItemIcon,
    ListItemText,
    Step,
    StepLabel,
    Stepper,
    Checkbox,
    IconButton,
    FormHelperText,
    Alert,
    Link,
} from "@mui/material";
import {integrationActions} from "../../../store/actions/integrationAction";
import {connect} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import Spinner from "../../spinner/Spinner";
import {Add, Delete, Check, CheckBoxOutlineBlank, CheckBox} from "@mui/icons-material";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {integrationService} from "../../../service/integrationService";
import {alertActions} from "../../../store/actions/alertActions";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        paddingTop: 8,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15
    },
    title: {
        paddingTop: 8,
        paddingBottom: 20,
        textAlign: "center"
    },
    chooseAccount: {
        fontWeight: 500,
        fontSize: 14,
        paddingTop: 8,
        paddingBottom: 4
    },
    adAccountList: {
        maxHeight: 500,
        overflow: "auto"
    },
    adAccountListItem: {
        padding: "8px 0",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.background.hover,
        }
    },
    listItem: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0
    },
    listItemIcon: {
        minWidth: 42
    },
    listItemText: {
        paddingRight: 100,
        marginTop: 16,
        marginBottom: 10
    },
    infoAlert: {
        marginTop: 12
    },
    integrationId: {
        color: theme.palette.text.disabledLight,
        paddingLeft: theme.spacing(3),
        wordBreak: "break-word",
    },
    integrationName: {
        wordBreak: "break-word",
    },
    stepper: {
        margin: "0 auto",
        width: "100%",
        maxWidth: 800,
        padding: "0px 24px 24px 24px"
    },
    stepConnectorLine: {
        borderColor: theme.palette.background.tableBorder
    },
    helperText: {
        color: theme.palette.error.main,
    },
});

function MgidIntegration(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {integration, resetIntegration, error, create, update, find, alert} = props;

    const steps = ['Integrate Account', 'Conversion API'];

    const integrationId = parseInt(props?.match?.params?.id);

    const [open, setOpen] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [removeEventIndex, setRemoveEventIndex] = useState(null);
    const [step1Data, setStep1Data] = useState(null);

    const {register, handleSubmit, control, setValue, watch, formState: {errors}} = useForm();
    const {fields, remove, append} = useFieldArray({
        control,
        name: "events"
    });

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        resetIntegration();
        setOpen(false);
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [resetIntegration, props.history, props.location]);

    const handleOnSubmitStep1 = (data) => {
        setStep1Data(data);
        setActiveStep(2);
    };

    const handleOnSubmit = (data) => {
        if (integrationId > 0) {
            update({
                integrationId: integrationId,
                name: step1Data?.name,
                apiClient: step1Data?.apiClient,
                apiSecret: step1Data?.apiSecret,
                enableConversionAPI: data?.enableConversionAPI,
                events: data?.events
            });
        } else {
            create({
                name: step1Data?.name,
                apiClient: step1Data?.apiClient,
                apiSecret: step1Data?.apiSecret,
                enableConversionAPI: data?.enableConversionAPI,
                events: data?.events
            });
        }
    };

    const appendNewEvent = () => {
        append({
            eventId: 0,
            name: "",
            includePayout: false
        });
    };

    const handleDeleteIntegrationEvent = useCallback(({eventId, index}) => {
        if (eventId === 0) {
            remove(index);
            return false;
        }

        integrationService.deleteIntegrationEvent({integrationId, eventId}).then(() => {
            setRemoveEventIndex(index);
        }).catch((e) => {
            error(e?.response?.data?.error);
        });
    }, [integrationId, error, remove]);

    useEffect(() => {
        if (removeEventIndex !== null) {
            remove(removeEventIndex);
        }
    }, [remove, removeEventIndex]);

    useEffect(() => {
        if (integrationId > 0) {
            find(integrationId);
        }
    }, [find, integrationId]);

    useEffect(() => {
        if (activeStep === 1 && integrationId > 0 && integration?.currentIntegration !== null) {
            if (integration?.currentIntegration?.id === integrationId) {
                setValue("name", integration?.currentIntegration?.networkName);
                for (const cred of integration?.currentIntegration?.integrationCredentials) {
                    if (cred?.keyName === "mgid_api_client") {
                        setValue("apiClient", cred?.keyValue);
                    }
                    if (cred?.keyName === "mgid_api_secret") {
                        setValue("apiSecret", cred?.keyValue);
                    }
                }
            }
        }
    }, [activeStep, setValue, integration?.currentIntegration, integrationId]);

    // Set step 1 form data.
    useEffect(() => {
        if (activeStep === 1 && step1Data !== null) {
            setValue("name", step1Data?.name);
            setValue("apiClient", step1Data?.apiClient);
            setValue("apiSecret", step1Data?.apiSecret);
        }
    }, [activeStep, setValue, step1Data]);

    useEffect(() => {
        if (activeStep === 2 && integration?.currentIntegration !== null) {
            for (const cred of integration?.currentIntegration?.integrationCredentials) {
                if (cred?.keyName === "mgid_enable_conversion_api") {
                    if (cred?.keyValue === "true") {
                        setValue("enableConversionAPI", true);
                    } else {
                        setValue("enableConversionAPI", false);
                    }
                }
            }
            let events = [];
            integration?.currentIntegration?.integrationEvents?.forEach((event) => {
                events.push({
                    eventId: event?.id,
                    name: event?.name,
                    includePayout: event?.includePayout
                });
            });
            setValue("events", events);
        }
    }, [activeStep, setValue, integration?.currentIntegration]);

    useEffect(() => {
        if (alert?.successMsg !== "") {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert?.successMsg, handleClose]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="mgid-account-integration"
        >
            <DialogTitle className={classes?.title}>
                <img src="/assets/images/traffic-sources/mgid.svg" alt="MGID integration"
                     width={130}/>
            </DialogTitle>
            <DialogContent className={classes?.root}>
                <Stepper
                    activeStep={activeStep}
                    connector={<StepConnector classes={{line: classes?.stepConnectorLine}}/>}
                    classes={{root: classes?.stepper}}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 1 &&
                    <>
                        <List>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Auto-matching for all campaigns."/>
                            </ListItem>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Check the status of your campaigns."/>
                            </ListItem>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Change the bid value for widget and teaser."/>
                            </ListItem>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText disableTypography
                                              primary="Pause or resume campaigns, widget and teaser."/>
                            </ListItem>
                            <ListItem className={classes?.listItem}>
                                <ListItemIcon className={classes?.listItemIcon}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText disableTypography
                                              primary="Sync cost on campaign level and widget level every 30 min."/>
                            </ListItem>
                        </List>
                        <p style={{paddingLeft: 2}}>
                            Please check our integration
                            guide <Link target="_blank" underline="hover" rel="noreferrer"
                                        href="https://skro.eu/docs/integrations/mgid-integration">here</Link>.
                        </p>

                        {integration?.loading?.integration && <Spinner overComponentBox={true}/>}
                        <form onSubmit={handleSubmit(handleOnSubmitStep1)} autoComplete={"off"}>
                            <Box display="flex" flexWrap={"wrap"}>
                                <Box p={1} width="100%">
                                    <TextField
                                        error={!!errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        id="name"
                                        label="Integration Name*"
                                        {...register("name", {required: true, maxLength: 255, minLength: 2})}
                                        name="name"
                                        size="small"
                                        type="text"
                                        placeholder="Enter your integration name"
                                        fullWidth={true}
                                        defaultValue="MGID"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box p={1} width="100%">
                                    <TextField
                                        error={!!errors.apiClient}
                                        variant="outlined"
                                        margin="dense"
                                        id="apiClient"
                                        label="API Client*"
                                        {...register("apiClient", {required: true, maxLength: 255, minLength: 2})}
                                        name="apiClient"
                                        size="small"
                                        type="text"
                                        placeholder="Enter your API Client"
                                        fullWidth={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box p={1} width="100%">
                                    <TextField
                                        error={!!errors.apiSecret}
                                        variant="outlined"
                                        margin="dense"
                                        id="apiSecret"
                                        label="API Secret*"
                                        {...register("apiSecret", {required: true, maxLength: 255, minLength: 2})}
                                        name="apiSecret"
                                        size="small"
                                        type="text"
                                        placeholder="Enter your API Secret"
                                        fullWidth={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </form>
                    </>
                }

                {activeStep === 2 &&
                    <Box mb={2} mt={2}>
                        {integration?.loading?.conversionApi && <Spinner overComponentBox={true}/>}
                        <Box mb={2} style={{fontWeight: 600}}>
                            <Alert variant="filled" severity="info">
                                Send conversion info back to MGID via Conversion API.
                            </Alert>
                        </Box>
                        <Box mb={0.5}>
                            <Controller
                                name="enableConversionAPI"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={value}
                                                    color="primary"
                                                    onChange={(e) => {
                                                        onChange(e.target.checked);
                                                        if (fields?.length === 0) {
                                                            appendNewEvent();
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Enable Conversion API"
                                        />
                                    );
                                }}
                                defaultValue={false}
                            />
                        </Box>
                        {watch("enableConversionAPI") &&
                            <div>
                                <div style={{maxHeight: 350, overflow: "auto"}}>
                                    {fields.map(({id, eventId, name, includePayout}, index) => {
                                        register(`events.${index}.eventId`, {value: eventId});
                                        return (
                                            <Box display="flex" flexWrap="wrap" mt={1} key={id}>
                                                <Box flexGrow={1}>
                                                    <TextField
                                                        error={!!errors?.events?.[index]?.name}
                                                        variant="outlined"
                                                        margin="dense"
                                                        type="text"
                                                        size="small"
                                                        placeholder="Enter MGID conversion event name"
                                                        fullWidth
                                                        label="Conversion Event Name"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={name}
                                                        name={`events.${index}.name`}
                                                        {...register(`events.${index}.name`, {
                                                            required: {value: true, message: "This field is mandatory"},
                                                            maxLength: {
                                                                value: 100,
                                                                message: "Max length is 100 letters"
                                                            },
                                                        })}
                                                    />
                                                </Box>
                                                <Box alignSelf="center" ml={2} mt="4px">
                                                    <Controller
                                                        name={`events.${index}.includePayout`}
                                                        control={control}
                                                        defaultValue={includePayout}
                                                        render={({field: {onChange, value}}) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                                                        checkedIcon={<CheckBox fontSize="small"/>}
                                                                        color="primary"
                                                                        size="small"
                                                                        checked={value}
                                                                        onChange={(e) => onChange(e.target.checked)}
                                                                    />
                                                                }
                                                                label="Include conversion payout"
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                                {fields?.length > 1 &&
                                                    <Box alignSelf="center" mt="4px">
                                                        <IconButton
                                                            aria-label="delete"
                                                            title="Delete"
                                                            size={"small"}
                                                            onClick={() => handleDeleteIntegrationEvent({
                                                                eventId,
                                                                index
                                                            })}
                                                        >
                                                            <Delete fontSize="small" color="error"/>
                                                        </IconButton>
                                                    </Box>
                                                }
                                                <Box display="flex" flexDirection="column" flexWrap="noWrap"
                                                     width="100%">
                                                    <FormHelperText className={classes?.helperText}>
                                                        {errors?.events?.[index]?.name?.message}
                                                    </FormHelperText>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </div>
                                <Box textAlign="center" mt={2}>
                                    <Button onClick={() => appendNewEvent()}
                                            color="primary" variant="text"
                                            startIcon={<Add/>} disabled={fields?.length === 25}>
                                        Additional Conversion Action
                                    </Button>
                                </Box>
                            </div>
                        }
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end">
                    <Box mr={3}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Close
                        </Button>
                    </Box>
                    {activeStep === 1 &&
                        <Box>
                            <Button onClick={handleSubmit(handleOnSubmitStep1)} color="primary" variant="contained">
                                Next
                            </Button>
                        </Box>
                    }
                    {activeStep === 2 &&
                        <>
                            <Box mr={1}>
                                <Button onClick={() => setActiveStep(1)} color="inherit" variant="contained">
                                    Back
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                                    Save & Close
                                </Button>
                            </Box>
                        </>
                    }
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration,
        alert: state.alert
    };
};

const mapDispatchToProps = {
    resetIntegration: integrationActions.reset,
    find: integrationActions.findIntegration,
    create: integrationActions.createMgidIntegration,
    update: integrationActions.updateMgidIntegration,
    error: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(MgidIntegration);