import {websiteDomainsConstants} from "../constants/websiteDomainsConstants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        recordsReturned: 0,
        totalRecords: 0
    },
    cnameRecord: null,
    errorMsg: "",
    isLoading: false,
    domain: "",
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false,
    steps: ["Add domain", 'CNAME records'],
    activeStep: 1,
    newDomainAdded: false,
    newDomainId: 0
};

const websiteDomainsReducer = (state = initialState, action) => {
    switch (action.type) {
        case websiteDomainsConstants.WEBSITE_DOMAINS_SET_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_STOP_LOADING:
            return {
                ...state,
                isLoading: false,
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.websiteDomains],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    recordsReturned: action?.result?.recordsReturned,
                    totalRecords: action?.result?.totalRecords
                },
                errorMsg: '',
                isLoading: false
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_VERIFIED:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: state?.data?.records?.map((item) => {
                        if (item?.id === action?.result?.id) {
                            return {
                                ...item,
                                status: action?.result?.status
                            }
                        } else {
                            return item;
                        }
                    }),
                },
                errorMsg: '',
                isLoading: false
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_ADD_DOMAIN:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: [
                        ...state.data.records,
                        {
                            id: action?.payload?.result?.id,
                            domain: action?.payload?.result?.domain,
                            workspace: action?.payload?.workspace?.label,
                            integration: action?.payload?.serverIntegration?.label,
                            integrationId: action?.payload?.serverIntegration?.value,
                            createdAt: action?.payload?.result?.createdAt,
                            status: action?.payload?.result?.status,
                        }
                    ],
                },
                newDomainAdded: true,
                newDomainId: action?.payload?.result?.id,
                errorMsg: '',
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_DELETE_DOMAIN_AND_CERT:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: state?.data?.records?.filter((item) => item?.id !== action?.result?.id),
                },
                errorMsg: '',
                isLoading: false
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_CNAME_RECORDS:
            return {
                ...state,
                cnameRecord: action?.result,
                errorMsg: '',
                isLoading: false
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_LOADING:
            return {
                ...state,
                data: {
                    records: action?.result?.websiteDomains,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    recordsReturned: action?.result?.recordsReturned,
                    totalRecords: action?.result?.totalRecords
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_LOADING_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load website domains.',
                isLoading: false,
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_DOMAIN:
            return {
                ...state,
                domain: action.domain
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action?.step,
                newDomainAdded: false,
                isLoading: false
            };
        case websiteDomainsConstants.WEBSITE_DOMAINS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        default:
            return state;
    }
};

export default websiteDomainsReducer;