import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Alert,
    Box, useTheme
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import React, {useEffect, useState} from "react";
import {sharedTrafficSourceService} from "../../service/sharedTrafficSourceService";

export const TrafficSourceTemplates = ({handleClose, handleSelectedTemplate}) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [requestError, setRequestError] = useState("");
    const [templates, setTemplates] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const filteredTemps = !searchQuery
        ? templates
        : templates?.filter(item =>
            item?.name?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );

    useEffect(() => {
        async function fetchData() {
            setRequestError("");
            setLoading(true);
            await sharedTrafficSourceService.read().then((response) => {
                if (response !== null && response !== undefined) {
                    setTemplates(response?.sharedTrafficSources);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (templates === null) {
            fetchData();
        }
    }, [templates]);

    return (
        <>
            <DialogTitle sx={{textAlign: "center"}}>Choose your traffic source</DialogTitle>
            <DialogContent sx={{[theme.breakpoints.up(959)]: {minHeight: "calc(100vh - 208px)"}}}>
                {loading && <Spinner overComponentBox={true}/>}

                {requestError.length > 1 &&
                    <Alert sx={{paddingBottom: "10px"}} variant="outlined" severity="error">
                        {requestError}
                    </Alert>
                }

                <Box display="flex" flexWrap="wrap" justifyContent="center">

                    <TextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        name="traffic-source"
                        placeholder="Search..."
                        type="text"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={searchQuery}
                        onChange={handleChange}
                    />

                    {filteredTemps?.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                flexGrow={0}>
                                <Button sx={{
                                    width: "180px",
                                    height: "56px",
                                    margin: "6px",
                                    backgroundColor: "common.white",
                                    textTransform: "none",
                                    [theme.breakpoints.down(500)]: {
                                        width: "140px",
                                        height: "56px",
                                    }
                                }}
                                        variant="contained"
                                        color="inherit"
                                        onClick={() => handleSelectedTemplate(item)}
                                >
                                    {item?.logoUrl !== ""
                                        ? <img style={{maxWidth: "85%", maxHeight: "84%"}}
                                               src={item?.logoUrl}
                                               alt={item?.name}
                                        />
                                        : item?.name
                                    }
                                </Button>
                            </Box>
                        );
                    })}
                    {filteredTemps?.length < 1 &&
                        <div>
                            <Box p={1} sx={{textAlign: "center"}}>No records found.</Box>
                            <p>
                                <Button onClick={() => handleSelectedTemplate(null)} color="primary"
                                        variant="contained">
                                    Create Custom
                                </Button>
                            </p>
                        </div>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={() => handleSelectedTemplate(null)} color="primary" variant="contained">
                    Create Custom
                </Button>
            </DialogActions>
        </>
    );
};