import React from "react";
import theme from "../../theme";
import {addCurrency, addPercent} from "../utils/Filters";
import {Box, Chip, Tooltip} from "@mui/material";
import {ArrowDropDown, ArrowDropUp, Share} from "@mui/icons-material";
import {CopyTextToClipboard} from "../copy/CopyTextToClipboard";
import ColumnFilter from "./ColumnFilter";
import md5Hash from "../utils/Md5Hash";

/**
 * @return {null}
 */
export function CurrencyFormatter(value, currencySymbol, numberOfDecimals) {
    if (value === undefined) {
        return null;
    }
    return <div style={{textAlign: "right", paddingRight: 8}}>
        {addCurrency(currencySymbol, value, numberOfDecimals)}
    </div>;
}

/**
 * @return {null}
 */
export function CurrencyFormatterColor(value, currencySymbol, numberOfDecimals) {
    if (value === undefined) {
        return null;
    }
    if (value > 0) {
        return <div style={{textAlign: "right", paddingRight: 8, color: theme.palette.primary.main}}>
            {addCurrency(currencySymbol, value, numberOfDecimals)}
        </div>;
    } else if (value < 0) {
        return <div style={{textAlign: "right", paddingRight: 8, color: theme.palette.error.light}}>
            {addCurrency(currencySymbol, value, numberOfDecimals)}
        </div>;
    } else {
        return <div style={{textAlign: "right", paddingRight: 8}}>
            {addCurrency(currencySymbol, value, numberOfDecimals)}
        </div>;
    }
}

export function PercentageFormatterColor(value, numberOfDecimals) {
    if (value === undefined) {
        return null;
    }
    if (value > 0) {
        return <div style={{textAlign: "right", paddingRight: 8, color: theme.palette.primary.main}}>
            {addPercent(value, numberOfDecimals)}
        </div>;
    } else if (value < 0) {
        return <div style={{textAlign: "right", paddingRight: 8, color: theme.palette.error.light}}>
            {addPercent(value, numberOfDecimals)}
        </div>;
    } else {
        return <div style={{textAlign: "right", paddingRight: 8}}>
            {addPercent(value, numberOfDecimals)}
        </div>;
    }
}

export function TagFormatter(value) {
    if (value === undefined || value === "") {
        return null;
    }
    let tags = value?.split(',');

    return tags?.map((v) => {
        return <Chip key={v} variant="outlined" color="default" size="small" label={v}
                     sx={{marginTop: "-3px", marginRight: "3px"}}/>;
    });
}

export function PercentageFormatter(value, numberOfDecimals) {
    if (value === undefined) {
        return null;
    }
    return <div style={{textAlign: "right", paddingRight: 8}}>{addPercent(value, numberOfDecimals)}</div>;
}

export function MarkFormatter({row}) {
    if (row?.profit > 0) {
        return <Box sx={{backgroundColor: "primary.main", color: "common.black", textAlign: "center"}}>+</Box>
    } else if (row?.profit < 0) {
        return <Box sx={{backgroundColor: "error.light", color: "common.black", textAlign: "center"}}>-</Box>
    } else {
        return <Box sx={{backgroundColor: "background.tableBorder", textAlign: "center"}}>•</Box>
    }
}

export function NameFormatterWithIntegration({row}) {
    return (
        <div style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: 30,
            position: "relative"
        }}>
            <span>{row?.name}</span>
            {row?.isIntegrated &&
                <span style={{position: "absolute", marginTop: 5, marginLeft: 5, right: 2}}>
                        <Tooltip title="Integrated" aria-label="integrated">
                            <Share
                                color="primary"
                                style={{
                                    padding: 1,
                                    fontSize: 20
                                }}
                            />
                        </Tooltip>
                </span>
            }
        </div>
    );
}

export function DetailsReportNameFormatter({row}) {
    return (
        <div style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: 30,
            position: "relative"
        }}>
            {row?.trafficSourceName &&
                <span style={{
                    marginRight: 8,
                    paddingRight: 8,
                    borderRight: "1px solid rgb(38, 45, 52)"
                }}>
                    {row?.trafficSourceName}
                </span>
            }
            {row?.name}
        </div>
    );
}

/**
 * @returns {*}
 * @constructor
 */
export function EmptyRowsRenderer() {
    return (
        <div style={{textAlign: "center", paddingTop: 20, fontWeight: 600}}>
            No data found.
        </div>
    );
}

/**
 * @return {boolean}
 */
export function IsAtBottom({currentTarget}) {
    return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;
}

export function CustomTableHeaderCellRender({column, sortDirection, handleResetMetric, handleApplyMetric}) {
    return (
        <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{height: "34px"}}>
            {column?.filter === true &&
                <Box sx={{padding: "3px 2px", cursor: "pointer"}} onClick={(e) => e.stopPropagation()}>
                    <ColumnFilter
                        columnKey={column?.key}
                        columnName={column?.name}
                        handleApplyMetric={handleApplyMetric}
                        handleResetMetric={handleResetMetric}
                    />
                </Box>
            }
            <Box pl={1} flexGrow={1} sx={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                <Tooltip title="Click to sort or drag-and-drop">
                    <span>{column?.name}</span>
                </Tooltip>
            </Box>
            <Box pt="2px">
                {sortDirection === "ASC" && <ArrowDropDown fontSize="large"/>}
                {sortDirection === "DESC" && <ArrowDropUp fontSize="large"/>}
            </Box>
        </Box>
    );
}

/**
 * @param selectedRows {object}
 * @param errorMsg {func}
 * @param successMsg {func}
 * @returns {boolean}
 */
export function HandleCopyNames({selectedRows, errorMsg, successMsg}) {
    let list = GetListOfSelectedValues(selectedRows);
    if (list === "") {
        errorMsg("Please select the rows you want to copy.");
        return false;
    }
    CopyTextToClipboard(list).then(() => {
        successMsg("Selected values are copied to clipboard.");
    }).catch(() => {
        errorMsg("Unable to copy selected values.");
    });
}

/**
 * @param rows
 * @returns {number}
 */
export function GetSelectedRowID(rows) {
    let id = 0;
    for (const item of rows) {
        id = item;
    }
    return id;
}

/**
 * @param rows
 * @returns {string}
 */
export function GetSelectedValue(rows) {
    let v = "";
    for (const item of rows) {
        v = item;
    }
    return v;
}

/**
 * @param rows
 * @returns {string}
 */
export function GetListOfSelectedRowIDs(rows) {
    let list = "";
    for (const id of rows) {
        if (list === "") {
            list = id
        } else {
            list += "," + id
        }
    }
    return list;
}

/**
 * @param values {object}
 * @returns {string}
 */
export function GetListOfSelectedValues(values) {
    let l = "";
    for (const v of values) {
        if (l === "") {
            l = v;
        } else {
            l += "," + v;
        }
    }
    return l;
}

/**
 * @param groupBy
 * @param trafficSource
 * @returns {string}
 */
export function GetSelectedGroupByPlaceholderFromTrafficSource(groupBy, trafficSource) {
    const selectedGroupBy = groupBy + "Name";
    let placeholder = "";
    if (trafficSource?.hasOwnProperty(selectedGroupBy)) {
        placeholder = trafficSource[groupBy + "Placeholder"];
    }
    return placeholder;
}

/**
 * Generate hash for custom1-10 columns.
 * @param groupBy {string}
 * @param columns {array}
 * @returns {boolean}
 */
export function allowToGenerateHash(groupBy, columns) {
    let showHash = false;
    columns?.forEach((s) => {
        if (s?.columnKey === "hash" && s?.hidden) {
            showHash = true;
        }
    })
    if (showHash) {
        return false;
    }
    let l = ["custom1", "custom2", "custom3", "custom4", "custom5", "custom6", "custom7", "custom8", "custom9", "custom10"]
    return l.indexOf(groupBy) !== -1;
}

/**
 * Generate MD5 hash encode with hex and return string with length 10 char.
 * @param data {string}
 * @returns {string}
 */
export function generateMD5HashHex(data) {
    return md5Hash(data + "secret000").substring(0, 10);
}