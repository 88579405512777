export const cloakingDomainConstants = {
    LOADING_CLOAKING_DOMAINS: 'LOADING_CLOAKING_DOMAINS',
    LOADING_CLOAKING_DOMAINS_ERROR: 'LOADING_CLOAKING_DOMAINS_ERROR',
    SET_CLOAKING_IS_LOADING: 'SET_CLOAKING_IS_LOADING',
    DELETE_CLOAKING_DOMAIN: 'DELETE_CLOAKING_DOMAIN',
    DELETE_CLOAKING_DOMAIN_ERROR: 'DELETE_CLOAKING_DOMAIN_ERROR',
    CLEAR_CLOAKING_ERRORS: 'CLEAR_CLOAKING_ERRORS',
    ADD_CLOAKING_DOMAIN: 'ADD_CLOAKING_DOMAIN',
    ADD_CLOAKING_DOMAIN_ERROR: 'ADD_CLOAKING_DOMAIN_ERROR',
    SET_CLOAKING_ACTIVE_STEP: 'SET_CLOAKING_ACTIVE_STEP',
    SET_CLOAKING_DOMAIN: 'SET_CLOAKING_DOMAIN'
};
