import accessManagerAPI from './accessManagerApi';

export const authServices = {
    verifyEmailLogin,
    logout,
    login,
    getGoogleLoginURL,
    verifyGoogleLogin
};

function login(email, reCaptchaToken) {
    return accessManagerAPI.post('v1/identify/email', {
        applicationName: "skro",
        email,
        reCaptchaToken
    }).then((result) => {
        return result?.status === 200;
    }).catch((error) => {
        return Promise.reject(error);
    });
}

function verifyEmailLogin(deviceId, token) {
    return accessManagerAPI.post('v1/identify/email/verify', {
        deviceId, token
    }).then((result) => {
        if (result.status === 200) {
            localStorage.setItem('access-token', result?.data?.accessToken);
            return result?.data?.accessToken;
        }
    }).catch((error) => {
        return Promise.reject(error);
    });
}

function getGoogleLoginURL(reCaptchaToken) {
    return accessManagerAPI.post('v1/identify/google/url', {
        reCaptchaToken
    }).then((result) => {
        return result?.data;
    }).catch((error) => {
        return Promise.reject(error);
    });
}

function verifyGoogleLogin(applicationName, state, code) {
    return accessManagerAPI.post('v1/identify/google/verify', {
        applicationName,
        state,
        code
    }).then((result) => {
        if (result.status === 200) {
            localStorage.setItem('access-token', result?.data?.accessToken);
            return result?.data?.accessToken;
        }
    }).catch((error) => {
        return Promise.reject(error);
    });
}

function logout() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('user-settings');
}
