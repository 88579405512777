import {apiRequest} from "../helpers/utils";

export const sharedAffiliateNetworkService = {
    read,
    find
};

function read(name) {
    return apiRequest("get", "v1/shared-affiliate-network", {}, {name, isDeleted: false, pageSize: 500});
}

function find(id) {
    return apiRequest("get", "v1/shared-affiliate-network/" + parseInt(id));
}
