import {reportServices} from "../../service/reportService";
import {trafficSourceConstants} from "../constants/trafficSourceConstants";
import {trafficSourceServices} from "../../service/trafficSourceService";
import {alertActions} from "./alertActions";
import ExportCSV from "../../components/export/ExportCSV";

export const trafficSourceActions = {
    getReport,
    archive,
    restore,
    loadMoreRows,
    updateName,
    updateTags,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    changeWorkspace
};

function loadMoreRows(groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, metricFilters, columns, workspace) {
    return dispatch => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        reportServices.getReport(
            {
                groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode,
                metricFilters, columns, workspace
            }
        )
            .then((result) => {
                dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_INFINITY_SCROLL, result});
            }).catch((error) => {
            dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_STOP_LOADING_SPINNER});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getReport(groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, exportData, metricFilters, columns, workspace) {
    return dispatch => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        reportServices.getReport(
            {
                groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, exportData,
                metricFilters, columns, workspace
            }
        )
            .then((result) => {
                if (exportData === "csv") {
                    ExportCSV({fileName: "traffic_sources_" + from + "_to_" + to, data: result});
                    dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_STOP_LOADING_SPINNER});
                } else {
                    dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_GET_DATA, result});
                }
            }).catch((error) => {
            dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_STOP_LOADING_SPINNER});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function archive(ids) {
    return dispatch => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_LOADING_SPINNER});
        trafficSourceServices.archive(ids)
            .then(() => {
                dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_ARCHIVE});
            }).catch((error) => {
                dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_ERROR, error});
            }
        );
    };
}

function restore(ids) {
    return dispatch => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_LOADING_SPINNER});
        trafficSourceServices.restore(ids)
            .then(() => {
                dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_RESTORE});
            }).catch((error) => {
                dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_ERROR, error});
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_UPDATE_SORT_BY, sortBy});
    };
}

function updateName(name) {
    return (dispatch) => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_UPDATE_NAME, name});
    };
}

function updateTags(tags) {
    return (dispatch) => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_UPDATE_TAGS, tags});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_RERENDER});
    };
}

function changeWorkspace(data) {
    return dispatch => {
        dispatch({type: trafficSourceConstants.TRAFFIC_SOURCES_LOADING_SPINNER});
        trafficSourceServices.bulkUpdate(data)
            .then(() => {
                dispatch({type: trafficSourceConstants.BULK_UPDATE_CHANGE_WORKSPACE});
                dispatch(alertActions.success("Workspace is successfully updated."));
            }).catch((error) => {
                dispatch(alertActions.error("Changing workspace failed: " + error?.response?.data?.error));
            }
        );
    };
}
