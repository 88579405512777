import React from 'react';
import {connect} from 'react-redux'
import {authActions} from "../../store/actions/authAction";
import {Redirect} from "react-router-dom";

class Logout extends React.Component {
    constructor(props) {
        super(props);
        if (props?.auth?.user?.aid === 2120) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('user-settings');
        } else {
            this.props.logout();
        }
    }

    render() {
        return (
            <Redirect to={{pathname: "/login"}} push={true}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const actionCreators = {
    logout: authActions.logout
};

export default connect(mapStateToProps, actionCreators)(Logout);
