import {affiliateNetworkConstants} from "../constants/affiliateNetworkConstants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        total: {}
    },
    errorMsg: "",
    isLoading: false,
    name: "",
    tags: [],
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false
};

const affiliateNetworkReducer = (state = initialState, action) => {
    switch (action.type) {
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_LOADING_SPINNER:
            return {
                ...state,
                isLoading: true,
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_STOP_LOADING_SPINNER:
            return {
                ...state,
                isLoading: false,
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_GET_DATA:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load affiliate networks. Please try again.',
                rerender: false,
                isLoading: false,
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_ARCHIVE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_RESTORE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_UPDATE_NAME:
            return {
                ...state,
                name: action.name
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_UPDATE_TAGS:
            return {
                ...state,
                tags: action.tags
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case affiliateNetworkConstants.AFFILIATE_NETWORKS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        case affiliateNetworkConstants.BULK_UPDATE_CHANGE_WORKSPACE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default affiliateNetworkReducer;