import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Paper,
    Button,
    Chip,
    Tooltip,
    IconButton
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import AlertDialog from "../alert/AlertDialog";
import {Delete} from "@mui/icons-material";
import {userActions} from "../../store/actions/userAction";
import CustomSnackbar from "../alert/CustomSnackbar";

function Members(props) {

    const {loadUsers, remove} = props;
    const {isLoading, users} = props?.state;
    const {user} = props?.auth;

    const [prompt, setPrompt] = useState(false);
    const [deleteUser, setDeleteUser] = useState(null);

    const handlePrompt = (domain) => {
        setDeleteUser(domain);
        setPrompt(true);
    };

    const handleDelete = (confirm) => {
        if (!confirm) {
            setPrompt(false);
            setDeleteUser(null);
            return false;
        }
        remove(deleteUser?.id);
        setPrompt(false);
        setDeleteUser(null);
    };

    const handleInvite = () => {
        props.history.push({pathname: "/settings/team/members/invite", state: {background: props.location}});
    };

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    return (
        <Box sx={{
            width: "100%",
            padding: "8px 8px",
            marginLeft: 2,
            marginRight: 2,
            minWidth: 300,
            margin: "0 auto",
            backgroundColor: "background.defaultBlue"
        }}>
            <Box display="flex">
                <Box
                    flexGrow={1}
                    sx={{
                        fontSize: 16,
                        paddingLeft: 1,
                        lineHeight: 1.167,
                        textAlign: "left",
                        fontWeight: 700,
                        paddingTop: 2,
                        paddingBottom: 2
                    }}
                >
                    Manage members
                </Box>
                <Box alignSelf="center" mr={1}>
                    <Button type="button" onClick={handleInvite} color="primary" variant="contained">
                        Invite
                    </Button>
                </Box>
            </Box>
            <Box>
                <Paper sx={{minHeight: 200, boxShadow: 0}} elevation={0}>
                    {isLoading && <Spinner size={32} overComponentBox={true}/>}

                    {props?.alert.errorMsg !== "" &&
                        <CustomSnackbar message={props?.alert.errorMsg} timeout={3500}/>
                    }
                    {props?.alert.successMsg !== "" &&
                        <CustomSnackbar message={props?.alert.successMsg} severity="success" timeout={3500}/>
                    }

                    <Box sx={{paddingLeft: 1, paddingBottom: 2, color: "text.disabledLight"}}>
                        Manage your company members and their roles, and assign workspaces to each member. Invited
                        member will be visible after accepting the invitation.
                    </Box>


                    {prompt &&
                        <AlertDialog
                            question="Delete confirmation"
                            text={"Are you sure you want to delete user with email: " + deleteUser?.email + " ?"}
                            confirm={handleDelete}
                        />
                    }

                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>

                        {users?.isLoading && <Spinner overComponentBox={true}/>}

                        <TableContainer sx={{maxHeight: "400px", overflow: "auto", width: "100%"}}>
                            <Table stickyHeader size="small">
                                <TableHead sx={{whiteSpace: "nowrap"}}>
                                    <TableRow hover>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell align="left">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {users?.length > 0 &&
                                    <TableBody sx={{whiteSpace: "nowrap", maxHeight: "300px"}}>
                                        {users?.map((item) => (
                                            <TableRow key={item?.id}>
                                                <TableCell>
                                                    {item?.email}
                                                </TableCell>
                                                <TableCell>
                                                    {item?.fullName !== " " ? item?.fullName : "Name not specified"}
                                                </TableCell>
                                                <TableCell>
                                                    <Chip label={item?.role} color="secondary" size="small"/>
                                                </TableCell>
                                                <TableCell>
                                                    {!item?.isDeleted &&
                                                        <Box display="flex" flexDirection="row"
                                                             flexWrap="noWrap" justifyContent="right"
                                                        >
                                                            {item?.role !== "Owner" && user?.uid !== item.id &&
                                                                <Box ml={2}>
                                                                    <Tooltip title="Delete">
                                                                        <IconButton
                                                                            size="small"
                                                                            aria-label="delete"
                                                                            onClick={() => handlePrompt(item)}
                                                                        >
                                                                            <Delete fontSize="small"
                                                                                    color="error"/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                            }
                                                        </Box>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                }
                            </Table>
                            {users?.length === 0 && <Box textAlign="center">No records</Box>}
                        </TableContainer>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
        return {
            auth: state.auth,
            state: state.user,
            alert: state.alert
        }
    }
;

const mapDispatchToProps = {
    loadUsers: userActions.fetch,
    remove: userActions.remove,
    invite: userActions.invite
};

export default connect(mapStateToProps, mapDispatchToProps)(Members);