import {reportServices} from "../../service/reportService";
import {campaignConstants} from "../constants/campaignsConstants";
import {campaignServices} from "../../service/campaignService";
import {alertActions} from "./alertActions";
import ExportCSV from "../../components/export/ExportCSV";

export const campaignActions = {
    getReport,
    archive,
    restore,
    loadMoreRows,
    updateName,
    updateTags,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    setSelectedRows,
    changeTrackingDomain,
    changeWorkspace
};

function loadMoreRows(groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, metricFilters, columns, workspace) {
    return dispatch => {
        dispatch({type: campaignConstants.CAMPAIGNS_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        reportServices.getReport(
            {
                groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode,
                metricFilters, columns, workspace
            }
        ).then((result) => {
            dispatch({type: campaignConstants.CAMPAIGNS_INFINITY_SCROLL, result});
        }).catch((error) => {
            dispatch({type: campaignConstants.CAMPAIGNS_STOP_LOADING_SPINNER});
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function getReport(groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, exportData, metricFilters, columns, workspace) {
    return dispatch => {
        dispatch({type: campaignConstants.CAMPAIGNS_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        reportServices.getReport(
            {
                groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, exportData,
                metricFilters, columns, workspace
            }
        ).then((result) => {
            if (exportData === "csv") {
                ExportCSV({fileName: "campaigns_" + from + "_to_" + to, data: result});
                dispatch({type: campaignConstants.CAMPAIGNS_STOP_LOADING_SPINNER});
            } else {
                dispatch({type: campaignConstants.CAMPAIGNS_GET_DATA, result});
            }
        }).catch((error) => {
            dispatch({type: campaignConstants.CAMPAIGNS_STOP_LOADING_SPINNER});
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function archive(ids) {
    return dispatch => {
        dispatch({type: campaignConstants.CAMPAIGNS_LOADING_SPINNER});
        campaignServices.archive(ids)
            .then(() => {
                dispatch({type: campaignConstants.CAMPAIGNS_ARCHIVE});
            }).catch((error) => {
                dispatch({type: campaignConstants.CAMPAIGNS_ERROR, error});
            }
        );
    };
}

function restore(ids) {
    return dispatch => {
        dispatch({type: campaignConstants.CAMPAIGNS_LOADING_SPINNER});
        campaignServices.restore(ids)
            .then(() => {
                dispatch({type: campaignConstants.CAMPAIGNS_RESTORE});
            }).catch((error) => {
                dispatch({type: campaignConstants.CAMPAIGNS_ERROR, error});
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: campaignConstants.CAMPAIGNS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: campaignConstants.CAMPAIGNS_UPDATE_SORT_BY, sortBy});
    };
}

function updateName(name) {
    return (dispatch) => {
        dispatch({type: campaignConstants.CAMPAIGNS_UPDATE_NAME, name});
    };
}

function updateTags(tags) {
    return (dispatch) => {
        dispatch({type: campaignConstants.CAMPAIGNS_UPDATE_TAGS, tags});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: campaignConstants.CAMPAIGNS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: campaignConstants.CAMPAIGNS_RERENDER});
    };
}

function setSelectedRows(selectedRows) {
    return dispatch => {
        dispatch({type: campaignConstants.CAMPAIGNS_SET_SELECTED_ROW, selectedRows});
    };
}

function changeTrackingDomain(data) {
    return dispatch => {
        dispatch({type: campaignConstants.CAMPAIGNS_LOADING_SPINNER});
        campaignServices.bulkCampaignUpdate(data)
            .then(() => {
                dispatch({type: campaignConstants.BULK_UPDATE_CHANGE_TRACKING_DOMAIN});
                dispatch(alertActions.success("Tracking domain is successfully updated."));
            }).catch((error) => {
                dispatch(alertActions.error("Changing tracking domain failed: " + error?.response?.data?.error));
            }
        );
    };
}

function changeWorkspace(data) {
    return dispatch => {
        dispatch({type: campaignConstants.CAMPAIGNS_LOADING_SPINNER});
        campaignServices.bulkCampaignUpdate(data)
            .then(() => {
                dispatch({type: campaignConstants.BULK_UPDATE_CHANGE_WORKSPACE});
                dispatch(alertActions.success("Workspace is successfully updated."));
            }).catch((error) => {
                dispatch(alertActions.error("Changing workspace failed: " + error?.response?.data?.error));
            }
        );
    };
}
