import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, Step, StepConnector as MuiStepConnector, StepLabel, Stepper, Switch, TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {websiteDomainsActions} from "../../store/actions/websiteDomainsAction";
import {useHistory, useLocation} from "react-router-dom";
import CopyContentToClipboard from "../utils/CopyContentToClipboard";
import {useForm} from "react-hook-form";
import WorkspaceDropdown from "../workspace/WorkspaceDropdown";
import IntegrationDropdown from "./IntegrationDropdown";
import {styled} from "@mui/system";
import {alertActions} from "../../store/actions/alertActions";

const CustomStepConnector = styled(MuiStepConnector)(({theme}) => ({
    "& .MuiStepConnector-line": {
        borderColor: theme.palette.background.tableBorder,
    },
}));

function DomainsAdd(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory();
    const location = useLocation();

    const [autoVerify, setAutoVerify] = useState(false);

    const {register, handleSubmit, control, setValue, formState: {errors}, setError} = useForm();

    const {getDomainVerifyRecords, addDomain, updateActiveStep, alertError, stopLoading} = props;
    const {steps, activeStep, newDomainAdded, newDomainId} = props?.websiteDomainsState;
    const {plan} = props?.auth?.subscription;

    const handleClose = useCallback(() => {
        updateActiveStep(1);
        history.push({pathname: "/websites/domains", state: {from: location}});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, location]);

    const getSubdomain = (domain) => {
        if (domain === "") {
            return "";
        }
        const parts = domain?.split(".");
        // If the domain has fewer than 3 parts, it doesn't have a subdomain
        if (parts?.length < 3) {
            return "";
        }
        // Return everything except the last two parts (which are the domain and TLD)
        return parts?.slice(0, parts?.length - 2).join(".");
    }

    const handleOnSubmitStep1 = (data) => {
        if (data?.domainIntegration?.networkType === "server_namesilo" && getSubdomain(data?.domain) === "") {
            setError("domain", {
                type: "manual",
                message: "You will need to add \"www\" for SiloName domains to get root domain working with CNAME.",
            });
            return false;
        }
        addDomain(data);
    };

    // This feature is not available with Free plan.
    useEffect(() => {
        if (plan === "Free") {
            alertError("This feature is not available in Free subscription plan.");
            return false;
        }
    });

    // Waiting until new domain is added.
    useEffect(() => {
        if (newDomainAdded && !autoVerify) {
            setTimeout(() => {
                updateActiveStep(2);
            }, 7000);
        }
        if (newDomainAdded && autoVerify) {
            stopLoading();
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newDomainAdded]);

    // Load domain CNAME records.
    useEffect(() => {
        if (activeStep === 2) {
            getDomainVerifyRecords(newDomainId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep]);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={fullScreen}
            aria-labelledby="cname-records-dialog"
            maxWidth={"md"}
        >
            <DialogTitle id="cname-records-dialog" textAlign="center">Add Domain</DialogTitle>
            <DialogContent>
                <Stepper
                    activeStep={activeStep - 1}
                    connector={<CustomStepConnector/>}
                    sx={{
                        margin: "0 auto",
                        width: "100%",
                        maxWidth: 800,
                        padding: "0px 24px 24px 24px"
                    }}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 1 &&
                    <>
                        <form onSubmit={handleSubmit(handleOnSubmitStep1)} autoComplete={"off"}>
                            <Box display="flex" flexWrap={"wrap"}>
                                <Box p={1} width="100%">
                                    <WorkspaceDropdown control={control} errors={errors} setValue={setValue}/>
                                </Box>
                                <Box p={1} width="100%">
                                    <IntegrationDropdown
                                        networkTypes={["server_aws"]}
                                        control={control}
                                        label="AWS Integration*"
                                        name="serverIntegration"
                                        error={!!errors?.serverIntegration}
                                    />
                                </Box>
                                <Box p={1} width="100%">
                                    <TextField
                                        error={!!errors.domain}
                                        variant="outlined"
                                        margin="dense"
                                        id="domain"
                                        label="Domain*"
                                        {...register("domain", {
                                            required: {
                                                value: true,
                                                message: "Domain is mandatory field."
                                            },
                                            maxLength: 100,
                                            minLength: 4,
                                            pattern: {
                                                value: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/i,
                                                message: "Invalid domain name."
                                            }
                                        })}
                                        name="domain"
                                        size="small"
                                        type="text"
                                        placeholder="Enter your domain or subdomain (e.g. example.com or app.example.com)"
                                        fullWidth={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {errors?.domain &&
                                        <Box sx={{color: "error.main"}}>{errors?.domain?.message}</Box>
                                    }
                                </Box>
                                <Box p={1} width="100%">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={autoVerify}
                                                color="primary"
                                                onChange={(e, checked) => setAutoVerify(checked)}
                                            />
                                        }
                                        label="Verify domain automatically"
                                    />

                                </Box>
                                {autoVerify &&
                                    <Box p={1} width="100%">
                                        <IntegrationDropdown
                                            control={control}
                                            errors={errors}
                                            label="Your domain provider*"
                                            name="domainIntegration"
                                            error={!!errors?.domainIntegration}
                                        />
                                    </Box>
                                }
                            </Box>
                        </form>
                    </>
                }

                {activeStep === 2 &&
                    <>
                        <Box display="flex" flexDirection="column">
                            <Box sx={{paddingLeft: 1, paddingBottom: 2, color: "text.disabledLight"}}>
                                Add the following CNAME record to the DNS configuration for your domain. This procedure
                                for
                                adding CNAME records depends on your DNS service provider.
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1}>
                                <Box>Type:</Box>
                                <Box pl={3}>CNAME</Box>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1}>
                                <Box alignContent="center" pr={2}>Name:</Box>
                                <Box pr={1} width="100%">
                                    <CopyContentToClipboard copyText={props?.websiteDomainsState?.cnameRecord?.name}/>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1}>
                                <Box alignContent="center" pr={2}>Value:</Box>
                                <Box pr={1} width="100%">
                                    <CopyContentToClipboard copyText={props?.websiteDomainsState?.cnameRecord?.value}/>
                                </Box>
                            </Box>
                        </Box>
                        <Box pt={3}>
                            <Alert severity="warning" color="warning" variant="filled">
                                Changing the DNS configuration allows ACM to issue certificates for this domain name for
                                as long as the DNS record exists. You can revoke permission at any time by
                                removing the record.
                            </Alert>
                        </Box>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end">
                    <Box mr={3}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Close
                        </Button>
                    </Box>
                    {activeStep === 1 &&
                        <Box>
                            <Button onClick={handleSubmit(handleOnSubmitStep1)} color="primary" variant="contained">
                                Next
                            </Button>
                        </Box>
                    }
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        websiteDomainsState: state.websiteDomains,
        auth: state.auth
    }
};

const mapDispatchToProps = {
    getDomainVerifyRecords: websiteDomainsActions.getDomainVerifyRecords,
    addDomain: websiteDomainsActions.addDomain,
    stopLoading: websiteDomainsActions.stopLoading,
    updateActiveStep: websiteDomainsActions.updateActiveStep,
    alertError: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainsAdd);