import {groupBy2Constants} from "../constants/groupBy2Constants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        totalRecords: 0,
        total: {}
    },
    errorMsg: "",
    isLoading: false,
    name: "",
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false,
    selectedUUIDs: new Set(),
    selectedRows: new Set(),
    groupBy: {
        "label": "Region",
        "groupBy": "region",
        "sortBy": "region"
    }
};

const groupBy2Reducer = (state = initialState, action) => {
    switch (action.type) {
        case groupBy2Constants.GROUP_BY_2_LOADING_SPINNER:
            return {
                ...state,
                isLoading: true,
            };
        case groupBy2Constants.GROUP_BY_2_STOP_LOADING_SPINNER:
            return {
                ...state,
                isLoading: false,
            };
        case groupBy2Constants.GROUP_BY_2_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    totalRecords: action?.result?.totalRecords,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false
            };
        case groupBy2Constants.GROUP_BY_2_GET_DATA:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    totalRecords: action?.result?.totalRecords,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case groupBy2Constants.GROUP_BY_2_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load data. Please try again.',
                rerender: false,
                isLoading: false,
            };
        case groupBy2Constants.GROUP_BY_2_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case groupBy2Constants.GROUP_BY_2_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case groupBy2Constants.GROUP_BY_2_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case groupBy2Constants.GROUP_BY_2_RERENDER:
            return {
                ...state,
                rerender: true
            };
        case groupBy2Constants.GROUP_BY_2_SET_SELECTED_ROW:
            return {
                ...state,
                selectedUUIDs: action?.payload?.selectedUUIDs,
                selectedRows: action?.payload?.selectedRows
            };
        case groupBy2Constants.GROUP_BY_2_UPDATE_GROUP_BY:
            return {
                ...state,
                groupBy: action.groupBy
            };
        case groupBy2Constants.GROUP_BY_2_RESET:
            return initialState;
        default:
            return state;
    }
};

export default groupBy2Reducer;