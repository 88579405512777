import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText,
    TextField,
    useMediaQuery,
    useTheme,
    Box,
    Button
} from "@mui/material";
import {useForm} from "react-hook-form";
import {userActions} from "../../store/actions/userAction";
import RoleSelect from "./RoleSelect";
import {workspaceActions} from "../../store/actions/workspaceAction";
import Spinner from "../spinner/Spinner";
import CustomSnackbar from "../alert/CustomSnackbar";

function AddMember(props) {
    const theme = useTheme();

    const {invite, readWorkspaces} = props;
    const {workspace, alert} = props.state;

    const [checked, setChecked] = useState([]);

    const {register, handleSubmit, control, formState: {errors}} = useForm();

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOnSubmit = (data) => {
        let workspaceIds = "";
        checked?.forEach((ws) => {
            if (workspaceIds !== "") {
                workspaceIds += "," + ws
            } else {
                workspaceIds += ws
            }
        });

        let result = {
            email: data?.email,
            roleId: data?.role?.id,
            workspaceIds: workspaceIds
        };
        invite(result);
    };

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        props.history.push({pathname: "/settings/team/members", state: {from: props.location}});
    }, [props]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleAll = () => {
        if (checked?.length !== 0 && checked?.length === workspace?.workspaces?.length) {
            setChecked([]);
        } else {
            let newOptions = [];
            workspace?.workspaces?.forEach((item) => {
                newOptions.push(item?.id);
            });
            setChecked(newOptions);
        }
    };

    useEffect(() => {
        readWorkspaces({workspaceTypes: "master,private"});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (alert?.successMsg !== "") {
            props.history.push({pathname: "/settings/team/members", state: {from: props.location}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert?.successMsg])

    return (
        <Dialog open={true}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="invite-member-form"
        >
            <DialogTitle sx={{textAlign: "center", pb: 0}}>Invite a new member</DialogTitle>
            {alert.errorMsg !== "" &&
                <CustomSnackbar message={alert.errorMsg} timeout={3500}/>
            }
            <DialogContent>
                <Box p={1}>
                    <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete={"off"}>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <TextField
                                    error={!!errors.email}
                                    variant="outlined"
                                    margin="dense"
                                    id="email"
                                    {...register("email", {
                                        required: true,
                                        pattern: /^[a-zA-Z\d][a-zA-Z\d-_.]+@([a-zA-Z]|[a-zA-Z\d]?[a-zA-Z\d-]+[a-zA-Z\d])\.[a-zA-Z\d]{2,10}(?:\.[a-zA-Z]{2,10})?$/,
                                        maxLength: 255
                                    })}
                                    name="email"
                                    size="small"
                                    type="email"
                                    placeholder="Enter an email address"
                                    fullWidth
                                    label="Email address"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <RoleSelect errors={errors} control={control}/>
                            </Box>
                        </Box>
                        <Box pt={1}>
                            <h3><strong>Assign workspaces</strong></h3>
                        </Box>
                        <Box>
                            All members get access to <strong>Public</strong> workspace by default.
                        </Box>
                        <Box mt={2}>
                            {workspace?.loading?.read && <Spinner overComponentBox={true}/>}
                            <List sx={{height: "100%", maxHeight: 400, overflow: "auto"}}>
                                <ListItem
                                    key={"all"}
                                    role={undefined} dense
                                    onClick={handleToggleAll}
                                    sx={{padding: "0 16px 0 12px"}}
                                >
                                    <ListItemIcon sx={{minWidth: 20}}>
                                        <Checkbox
                                            size="small"
                                            color="primary"
                                            edge="start"
                                            checked={checked?.length === workspace?.workspaces?.length}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{'aria-labelledby': "all"}}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={"all"}
                                        primary="All workspaces"
                                        sx={{
                                            "& span": {
                                                fontSize: 14,
                                                maxWidth: 200,
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis"
                                            }
                                        }}
                                    />
                                </ListItem>
                                <Divider/>
                                {workspace?.workspaces?.map((item) => {
                                    return (
                                        <ListItem
                                            key={item?.id} role={undefined} dense
                                            onClick={handleToggle(item?.id)}
                                            sx={{padding: "0 16px 0 12px"}}
                                        >
                                            <ListItemIcon sx={{minWidth: 20}}>
                                                <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    edge="start"
                                                    checked={checked?.indexOf(item?.id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{'aria-labelledby': item?.id}}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                id={item?.id} primary={item?.name}
                                                sx={{
                                                    "& span": {
                                                        fontSize: 14,
                                                        maxWidth: 200,
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis"
                                                    }
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    </form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexWrap="noWrap">
                    <Box mr={2}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                            Invite & Close
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            workspace: state.workspace,
            alert: state.alert
        }
    }
};

const mapDispatchToProps = {
    invite: userActions.invite,
    readWorkspaces: workspaceActions.read,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);