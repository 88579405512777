import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import WorkspaceDropdown from "../../workspace/WorkspaceDropdown";
import IntegrationDropdown from "./../IntegrationDropdown";
import {alertActions} from "../../../store/actions/alertActions";
import {websiteBucketsActions} from "../../../store/actions/websiteBucketsAction";
import S3RegionDropdown from "./S3RegionDropdown";

function AddBucket(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory();
    const location = useLocation();

    const {register, handleSubmit, control, setValue, formState: {errors}} = useForm();

    const [selectedFile, setSelectedFile] = useState(null);

    const {createBucket, uploadFiles, alertError, updateUploadedFilesStatus} = props;
    const {newBucketAdded, newBucketId, filesUploaded} = props?.websiteBucketsState;
    const {plan} = props?.auth?.subscription;

    const handleClose = useCallback(() => {
        history.push({pathname: "/websites/s3-buckets", state: {from: location}});
    }, [history, location]);

    const handleOnSubmitCreateAndUpload = (data) => {
        if (plan === "Free") {
            alertError("This feature is not available in Free subscription plan.");
            return false;
        }
        if (selectedFile === null) {
            alertError("Zip file is mandatory to add.");
            return false;
        }
        createBucket(data);
    };

    const handleFileChange = (event) => {
        const file = event?.target?.files[0];
        if (file && (file?.type === 'application/zip' || file?.type === "application/x-zip-compressed")) {
            setSelectedFile(file);
        } else {
            alertError('Please select a valid ZIP file.');
            setSelectedFile(null);
        }
    };

    // Upload files to S3 bucket with some delay because bucket might be not created by AWS yet.
    useEffect(() => {
        if (newBucketAdded) {

            const formData = new FormData();
            formData.append('zipFile', selectedFile);
            formData.append('websiteId', newBucketId);

            setTimeout(() => {
                uploadFiles(formData);
            }, 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBucketAdded, uploadFiles]);

    // Close form
    useEffect(() => {
        if (filesUploaded) {
            updateUploadedFilesStatus(false);
            handleClose();
        }
    }, [filesUploaded, updateUploadedFilesStatus, handleClose]);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={fullScreen}
            aria-labelledby="create-bucket-dialog"
            sx={{maxWidth: 600, margin: "0 auto"}}
        >
            <DialogTitle id="create-bucket-dialog" textAlign="center">Create S3 bucket & Upload website</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(handleOnSubmitCreateAndUpload)} autoComplete={"off"}>
                    <Box display="flex" flexWrap={"wrap"}>
                        <Box p={1} width="100%">
                            <WorkspaceDropdown control={control} errors={errors} setValue={setValue}/>
                        </Box>
                        <Box p={1} width="100%">
                            <IntegrationDropdown control={control} errors={errors}/>
                        </Box>
                        <Box p={1} width="100%">
                            <S3RegionDropdown control={control} errors={errors}/>
                        </Box>
                        <Box p={1} width="100%">
                            <TextField
                                error={!!errors.name}
                                variant="outlined"
                                margin="dense"
                                id="name"
                                label="Website Name*"
                                {...register("name", {
                                    required: {
                                        value: true,
                                        message: "Website name is mandatory field."
                                    },
                                    maxLength: 100,
                                    minLength: 4,
                                    pattern: {
                                        value: /^[a-zA-Z0-9\s.\-()]+$/,
                                        message: "Invalid website name. Only letters, numbers, and . - ( ) symbols are allowed."
                                    }
                                })}
                                name="name"
                                size="small"
                                type="text"
                                placeholder="Enter your website name"
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {errors?.name &&
                                <Box sx={{color: "error.main"}}>{errors?.name?.message}</Box>
                            }
                        </Box>
                        <Box p={1} width="100%">
                            <TextField
                                type="file"
                                onChange={handleFileChange}
                                inputProps={{
                                    accept: '.zip',
                                }}
                                label="Select ZIP file*"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                            />
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end">
                    <Box mr={3}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Close
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmitCreateAndUpload)} color="primary"
                                variant="contained">
                            Create & Upload
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        websiteBucketsState: state.websiteBuckets,
        auth: state.auth
    }
};

const mapDispatchToProps = {
    createBucket: websiteBucketsActions.createBucket,
    uploadFiles: websiteBucketsActions.uploadFiles,
    updateUploadedFilesStatus: websiteBucketsActions.updateUploadedFilesStatus,
    alertError: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBucket);