import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, FormGroup, TextField, InputAdornment} from "@mui/material";
import {Remove, Add} from "@mui/icons-material";
import {debounce} from "lodash";
import {Controller, useForm} from "react-hook-form";

export default function TrafficSourceChangeBid({row, column}) {
    const {control, setValue, watch, formState: {errors}} = useForm({
        defaultValues: {bid: row?.bid || 0.0000},
    });
    const [changeBid, setChangeBid] = useState(false);

    const handleIncrement = () => {
        let n = parseFloat(watch("bid")) + 0.01;
        if (isNaN(n)) n = 0.01;
        n = n.toFixed(4);
        setValue("bid", n);
        setChangeBid(true);
    };

    const handleDecrement = () => {
        let n = parseFloat(watch("bid")) - 0.01;
        if (n < 0) n = 0;
        n = n.toFixed(4);
        setValue("bid", n);
        setChangeBid(true);
    };

    const handleBidUpdate = (newBid) => {
        setChangeBid(false);
        column?.handleChangeExternalBid(newBid, row?.name);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChangeHandler = useCallback(debounce(handleBidUpdate, 1000), []);

    useEffect(() => {
        if (changeBid) {
            debouncedChangeHandler(watch("bid"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch("bid"), changeBid, debouncedChangeHandler]);

    useEffect(() => {
        setValue("bid", row?.bid);
    }, [row?.bid, setValue, watch]);

    const getRateModel = (rateModel) => rateModel ? rateModel.toUpperCase() : "CPC";

    // Do not show bid modification option if the data isn't there.
    if (row?.bid === undefined || row?.bid === null) {
        return "-";
    }

    return (
        <Box display="flex" flexDirection="row" justifyContent="center"
             sx={{paddingTop: "3px", marginLeft: "-8px"}}>
            <FormGroup row aria-autocomplete="none">
                <Controller
                    name="bid"
                    control={control}
                    rules={{required: true, min: 0}}
                    render={({field}) => (
                        <TextField
                            {...field}
                            type="text"
                            autoComplete="off"
                            error={!!errors.bid}
                            onChange={(e) => {
                                field.onChange(e);
                                setChangeBid(true);
                            }}
                            onClick={(e) => e.stopPropagation()} // Prevent focus loss
                            onFocus={(e) => e.stopPropagation()}
                            InputProps={{
                                autoComplete: "off",
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                sx: {
                                    "& fieldset": { border: 'none' },
                                    position: "relative",
                                    height: "28px",
                                    minHeight: "28px",
                                    maxHeight: "28px",
                                    fontSize: "14px",
                                    width: "130px",
                                    textAlign: "center",
                                    border: "0",
                                    boxShadow: "none",
                                    backgroundColor: "background.darkBlue",
                                    color: "common.white",
                                    borderBottomLeftRadius: "4px",
                                    borderTopLeftRadius: "4px",
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                    "& .MuiOutlinedInput-input": {
                                        backgroundColor: "background.darkBlue",
                                        padding: 0,
                                        fontSize: "14px",
                                        color: "common.white",
                                    }
                                },
                                startAdornment: <InputAdornment position="start">
                                    <Box display="flex" flexDirection="row" flexWrap="noWrap">
                                        <Box sx={{
                                            paddingTop: 0,
                                            paddingLeft: 0,
                                            paddingRight: "14px",
                                            color: "text.disabledLight",
                                            fontSize: "14px",
                                        }}>
                                            {getRateModel(row?.rateModel)}
                                        </Box>
                                    </Box>
                                </InputAdornment>
                            }}
                        />
                    )}
                />
                <Button
                    type="button"
                    variant="contained"
                    onClick={handleDecrement}
                    sx={{
                        color: "text.primary",
                        backgroundColor: "background.darkBlue",
                        border: "0px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "background.darkBlue",
                            color: "error.main",
                            boxShadow: "none"
                        },
                        borderRadius: "0px",
                        position: "relative",
                        height: "100%",
                        minHeight: "28px",
                        maxHeight: "28px",
                        minWidth: "20px",
                        maxWidth: "20px",
                        padding: "4px 16px",
                    }}

                >
                    <Remove fontSize="small"/>
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    onClick={handleIncrement}
                    sx={{
                        color: "text.primary",
                        backgroundColor: "background.darkBlue",
                        border: "0px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "background.darkBlue",
                            color: "primary.main",
                            boxShadow: "none"
                        },
                        position: "relative",
                        height: "100%",
                        minHeight: "28px",
                        maxHeight: "28px",
                        minWidth: "20px",
                        maxWidth: "20px",
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px"
                    }}
                >
                    <Add fontSize="small"/>
                </Button>
            </FormGroup>
        </Box>
    );
}
