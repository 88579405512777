export const columnConstants = {
    COLUMN_FETCH_DATA: 'COLUMN_FETCH_DATA',
    COLUMN_UPDATE_COLUMN_WIDTH: 'COLUMN_UPDATE_COLUMN_WIDTH',
    COLUMN_UPDATE_COLUMN_VISIBILITY: 'COLUMN_UPDATE_COLUMN_VISIBILITY',
    COLUMN_UPDATE_COLUMN_SEQUENCE: 'COLUMN_UPDATE_COLUMN_SEQUENCE',
    COLUMN_RERENDER: 'COLUMN_RERENDER',
    COLUMN_ADD_CUSTOM_COLUMN: 'COLUMN_ADD_CUSTOM_COLUMN',
    COLUMN_UPDATE_CAMPAIGN_SELECTED_COLUMNS: 'COLUMN_UPDATE_CAMPAIGN_SELECTED_COLUMNS',
    COLUMN_DELETE_CUSTOM_METRIC_COLUMN: 'COLUMN_DELETE_CUSTOM_METRIC_COLUMN'
};
