import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import Spinner from "../../spinner/Spinner";
import {connect} from "react-redux";
import {alertActions} from "../../../store/actions/alertActions";
import {apiAccessServices} from "../../../service/apiAccessService";
import {apiAccessActions} from "../../../store/actions/apiAccessAction";

function CreateAccessTokenForm(props) {

    const {onClose, open, errorMsg, successMsg, clear, reload} = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [loading, setLoading] = useState(false);

    const {register, handleSubmit, errors} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        let formData = {
            name: data?.name
        };

        await apiAccessServices.create(formData).then(() => {
            setLoading(false);
            successMsg("Access token successfully generated.");
            reload();
            handleClose();
        }).catch((e) => {
            if (e?.response?.data?.error) {
                errorMsg(e.response.data.error);
            }
            setLoading(false);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        setTimeout(() => {
            clear();
        }, 3000);

        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="create-access-token-form"
                maxWidth={"sm"}
        >
            <DialogTitle style={{textAlign: "center"}}>Generate API Access Token</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                    <TextField
                        autoFocus
                        error={!!errors?.name}
                        type="text"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        label="Name"
                        name="name"
                        fullWidth
                        id="name"
                        placeholder="Enter access token name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...register("name", {required: true, maxLength: 100, minLength: 1})}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                {loading ? <Spinner/> :
                    <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Generate
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

CreateAccessTokenForm.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        state: {
            alert: state.alert,
        }
    };
};

const mapDispatchToProps = {
    errorMsg: alertActions.error,
    successMsg: alertActions.success,
    clear: alertActions.clear,
    reload: apiAccessActions.reload
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccessTokenForm);