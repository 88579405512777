import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {
    Dialog,
    useMediaQuery,
    useTheme
} from "@mui/material";
import PropTypes from "prop-types";
import {offerServices} from "../../service/offerService";
import {OfferForm} from "./OfferForm";

function OfferAddPopUp({onClose, open}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);

    const {register, handleSubmit, setValue, watch, control, getValues, formState:{errors}} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);

        let offerName = "";
        if (data?.name !== undefined) {
            offerName = "Global - " + data.name;
            if (data?.country?.label !== undefined) {
                offerName = data.country.label + " - " + data.name;
            }
        }

        let formData = {
            workspaceId: data?.workspace?.value,
            affiliateNetworkId: data?.affiliateNetwork?.value ? data.affiliateNetwork.value : 0,
            clickIdParam: "",
            countryCode: data?.country?.code ? data.country.code : "",
            currencyCode: data?.currency?.code ? data.currency.code : "EUR",
            dailyCapFailoverOfferId: data?.dailyCapFailoverOfferId ? data.dailyCapFailoverOfferId : 0,
            dailyCapLimit: data?.dailyCapLimit ? data?.dailyCapLimit : "",
            dailyCapTimezone: data?.timezone?.timezone ? data.timezone.timezone : "",
            enableDailyCap: data?.enableDailyCap ? data.enableDailyCap : false,
            name: offerName,
            payoutSum: data?.payoutSum ? parseFloat(data.payoutSum) : 0,
            payoutType: data?.payoutType ? data.payoutType : "auto",
            url: data?.url ? data.url : "",
            tags: data?.tags ? data?.tags : []
        };

        await offerServices.create(formData).then(() => {
            setLoading(false);
            handleClose();
        }).catch((e) => {
            if (e?.response?.data?.error) {
                setRequestError(e.response.data.error);
            }
            setLoading(false);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setRequestError("");
        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="offer-form"
                maxWidth={"md"}
        >
            <OfferForm
                title="Add new offer"
                watch={watch}
                control={control}
                register={register}
                handleClose={handleClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                getValues={getValues}
            />
        </Dialog>
    );
}

OfferAddPopUp.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default OfferAddPopUp;