import Topbar from "../layout/Topbar";
import React from "react";
import SettingsSidebar from "./SettingsSidebar";
import ReportingSettings from "./ReportingSettings";
import CompanySettings from "./CompanySettings";
import ProfileSettings from "./ProfileSettings";
import {alertActions} from "../../store/actions/alertActions";
import {connect} from "react-redux";
import {authActions} from "../../store/actions/authAction";
import {Box, CssBaseline} from "@mui/material";
import {styled} from "@mui/system";

export const SettingsRoot = styled(Box)(({theme}) => ({
    display: "flex",
    backgroundColor: theme.palette.background.darkBlue,
    color: theme.palette.common.white,
    maxWidth: "100%"
}));

export const SettingsContentBox = styled(Box)(({theme}) => ({
    height: "var(--app-height)",
    width: "100%",
    overflow: "auto",
    borderLeft: "1px solid " + theme.palette.background.tableBorder,
    paddingTop: 66,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
        paddingTop: 66,
    }
}));

function General(props) {
    const {role} = props?.state?.auth?.userSettings;
    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <Box display="flex" flexWrap="wrap" flexDirection="column" justifyContent="center">
                    <Box>
                        <ProfileSettings {...props}/>
                    </Box>
                    <Box>
                        <ReportingSettings {...props}/>
                    </Box>
                    {(role === "owner" || role === "manager") &&
                        <Box>
                            <CompanySettings {...props}/>
                        </Box>
                    }
                </Box>
            </SettingsContentBox>
        </SettingsRoot>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            alert: state.alert,
            auth: state.auth
        }
    };
};

const mapDispatchToProps = {
    errorMsg: alertActions.error,
    successMsg: alertActions.success,
    clear: alertActions.clear,
    updateUserSettings: authActions.updateUserSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(General);