export const individualSubscriptionPlans = [
    {
        name: "Free",
        price: 0.00,
        overagePrice: 0.05,
        events: "250 000",
        customDomains: 10,
        dataRetentionInMonths: 3,
        totalNumberOfRecords: "20",
        users: 1,
        integrations: 1
    },
    {
        name: "Starter",
        price: 39.00,
        overagePrice: 0.03,
        events: "2 500 000",
        customDomains: 50,
        dataRetentionInMonths: 6,
        totalNumberOfRecords: "Unlimited",
        users: 1,
        integrations: 3
    },
    {
        name: "Pro",
        price: 99.00,
        overagePrice: 0.02,
        events: "5 000 000",
        customDomains: 100,
        dataRetentionInMonths: 12,
        totalNumberOfRecords: "Unlimited",
        users: 1,
        integrations: "Unlimited"
    }
];

export const businessSubscriptionPlans = [
    {
        name: "Team",
        price: 199.00,
        overagePrice: 0.02,
        events: "10 000 000",
        customDomains: 150,
        dataRetentionInMonths: 12,
        totalNumberOfRecords: "Unlimited",
        users: 5,
        integrations: "Unlimited"
    },
    {
        name: "Enterprise",
        price: 449.00,
        overagePrice: 0.01,
        events: "30 000 000",
        customDomains: 300,
        dataRetentionInMonths: 24,
        totalNumberOfRecords: "Unlimited",
        users: 10,
        integrations: "Unlimited"
    }
];

export const baseCurrencies = [
    {code: "EUR", name: "Euro", symbol: "€"},
    {code: "USD", name: "US Dollar", symbol: "$"}
];

export const currencies = [
    {code: "AED", name: "United Arab Emirates Dirham", symbol: "AED"},
    {code: "AFN", name: "Afghan Afghani", symbol: "Af"},
    {code: "ALL", name: "Albanian Lek", symbol: "ALL"},
    {code: "AMD", name: "Armenian Dram", symbol: "AMD"},
    {code: "ANG", name: "Netherlands Antillean Guilder", symbol: "ANG"},
    {code: "AOA", name: "Angolan Kwanza", symbol: "AOA"},
    {code: "ARS", name: "Argentine Peso", symbol: "AR$"},
    {code: "AUD", name: "Australian Dollar", symbol: "$"},
    {code: "AWG", name: "Aruban Florin", symbol: "AWG"},
    {code: "AZN", name: "Azerbaijani Manat", symbol: "man."},
    {code: "BAM", name: "Bosnia-Herzegovina Convertible Mark", symbol: "KM"},
    {code: "BBD", name: "Barbadian Dollar", symbol: "BBD"},
    {code: "BDT", name: "Bangladeshi Taka", symbol: "Tk"},
    {code: "BGN", name: "Bulgarian Lev", symbol: "лв"},
    {code: "BHD", name: "Bahraini Dinar", symbol: "BD"},
    {code: "BIF", name: "Burundian Franc", symbol: "FBu"},
    {code: "BMD", name: "Bermudan Dollar", symbol: "BMD"},
    {code: "BND", name: "Brunei Dollar", symbol: "BN$"},
    {code: "BOB", name: "Bolivian Boliviano", symbol: "Bs"},
    {code: "BRL", name: "Brazilian Real", symbol: "R$"},
    {code: "BSD", name: "Bahamian Dollar", symbol: "BSD"},
    {code: "BTC", name: "Bitcoin", symbol: "BTC"},
    {code: "BTN", name: "Bhutanese Ngultrum", symbol: "BTN"},
    {code: "BWP", name: "Botswanan Pula", symbol: "BWP"},
    {code: "BYN", name: "Belarusian Ruble", symbol: "Br"},
    {code: "BZD", name: "Belize Dollar", symbol: "BZ$"},
    {code: "CAD", name: "Canadian Dollar", symbol: "$"},
    {code: "CDF", name: "Congolese Franc", symbol: "CDF"},
    {code: "CHF", name: "Swiss Franc", symbol: "CHF"},
    {code: "CLF", name: "Chilean Unit of Account (UF)", symbol: "CLF"},
    {code: "CLP", name: "Chilean Peso", symbol: "CL$"},
    {code: "CNH", name: "Chinese Yuan (Offshore)", symbol: "CNH"},
    {code: "CNY", name: "Chinese Yuan", symbol: "¥"},
    {code: "COP", name: "Colombian Peso", symbol: "CO$"},
    {code: "CRC", name: "Costa Rican Colón", symbol: "₡"},
    {code: "CUC", name: "Cuban Convertible Peso", symbol: "CUC"},
    {code: "CUP", name: "Cuban Peso", symbol: "CUP"},
    {code: "CVE", name: "Cape Verdean Escudo", symbol: "CV$"},
    {code: "CZK", name: "Czech Republic Koruna", symbol: "Kč"},
    {code: "DJF", name: "Djiboutian Franc", symbol: "Fdj"},
    {code: "DKK", name: "Danish Krone", symbol: "Dkr"},
    {code: "DOP", name: "Dominican Peso", symbol: "RD$"},
    {code: "DZD", name: "Algerian Dinar", symbol: "DA"},
    {code: "EGP", name: "Egyptian Pound", symbol: "EGP"},
    {code: "ERN", name: "Eritrean Nakfa", symbol: "Nfk"},
    {code: "ETB", name: "Ethiopian Birr", symbol: "Br"},
    {code: "EUR", name: "Euro", symbol: "€"},
    {code: "FJD", name: "Fijian Dollar", symbol: "FJD"},
    {code: "FKP", name: "Falkland Islands Pound", symbol: "FKP"},
    {code: "GBP", name: "British Pound Sterling", symbol: "£"},
    {code: "GEL", name: "Georgian Lari", symbol: "GEL"},
    {code: "GGP", name: "Guernsey Pound", symbol: "GGP"},
    {code: "GHS", name: "Ghanaian Cedi", symbol: "GH₵"},
    {code: "GIP", name: "Gibraltar Pound", symbol: "GIP"},
    {code: "GMD", name: "Gambian Dalasi", symbol: "GMD"},
    {code: "GNF", name: "Guinean Franc", symbol: "FG"},
    {code: "GTQ", name: "Guatemalan Quetzal", symbol: "GTQ"},
    {code: "GYD", name: "Guyanaese Dollar", symbol: "GYD"},
    {code: "HKD", name: "Hong Kong Dollar", symbol: "HK$"},
    {code: "HNL", name: "Honduran Lempira", symbol: "HNL"},
    {code: "HRK", name: "Croatian Kuna", symbol: "kn"},
    {code: "HTG", name: "Haitian Gourde", symbol: "HTG"},
    {code: "HUF", name: "Hungarian Forint", symbol: "Ft"},
    {code: "IDR", name: "Indonesian Rupiah", symbol: "Rp"},
    {code: "ILS", name: "Israeli New Sheqel", symbol: "₪"},
    {code: "IMP", name: "Manx pound", symbol: "IMP"},
    {code: "INR", name: "Indian Rupee", symbol: "Rs"},
    {code: "IQD", name: "Iraqi Dinar", symbol: "IQD"},
    {code: "IRR", name: "Iranian Rial", symbol: "IRR"},
    {code: "ISK", name: "Icelandic Króna", symbol: "Ikr"},
    {code: "JEP", name: "Jersey Pound", symbol: "JEP"},
    {code: "JMD", name: "Jamaican Dollar", symbol: "J$"},
    {code: "JOD", name: "Jordanian Dinar", symbol: "JD"},
    {code: "JPY", name: "Japanese Yen", symbol: "¥"},
    {code: "KES", name: "Kenyan Shilling", symbol: "Ksh"},
    {code: "KGS", name: "Kyrgystani Som", symbol: "KGS"},
    {code: "KHR", name: "Cambodian Riel", symbol: "KHR"},
    {code: "KMF", name: "Comorian Franc", symbol: "CF"},
    {code: "KPW", name: "North Korean Won", symbol: "KPW"},
    {code: "KRW", name: "South Korean Won", symbol: "₩"},
    {code: "KWD", name: "Kuwaiti Dinar", symbol: "KD"},
    {code: "KYD", name: "Cayman Islands Dollar", symbol: "KYD"},
    {code: "KZT", name: "Kazakhstani Tenge", symbol: "₸"},
    {code: "LAK", name: "Laotian Kip", symbol: "LAK"},
    {code: "LBP", name: "Lebanese Pound", symbol: "LB£"},
    {code: "LKR", name: "Sri Lankan Rupee", symbol: "SLRs"},
    {code: "LRD", name: "Liberian Dollar", symbol: "LRD"},
    {code: "LSL", name: "Lesotho Loti", symbol: "LSL"},
    {code: "LYD", name: "Libyan Dinar", symbol: "LD"},
    {code: "MAD", name: "Moroccan Dirham", symbol: "MAD"},
    {code: "MDL", name: "Moldovan Leu", symbol: "MDL"},
    {code: "MGA", name: "Malagasy Ariary", symbol: "MGA"},
    {code: "MKD", name: "Macedonian Denar", symbol: "MKD"},
    {code: "MMK", name: "Myanma Kyat", symbol: "MMK"},
    {code: "MNT", name: "Mongolian Tugrik", symbol: "MNT"},
    {code: "MOP", name: "Macanese Pataca", symbol: "MOP$"},
    {code: "MRO", name: "Mauritanian Ouguiya (pre-2018)", symbol: "MRO"},
    {code: "MRU", name: "Mauritanian Ouguiya", symbol: "MRU"},
    {code: "MUR", name: "Mauritian Rupee", symbol: "MURs"},
    {code: "MVR", name: "Maldivian Rufiyaa", symbol: "MVR"},
    {code: "MWK", name: "Malawian Kwacha", symbol: "MWK"},
    {code: "MXN", name: "Mexican Peso", symbol: "MX$"},
    {code: "MYR", name: "Malaysian Ringgit", symbol: "RM"},
    {code: "MZN", name: "Mozambican Metical", symbol: "MTn"},
    {code: "NAD", name: "Namibian Dollar", symbol: "N$"},
    {code: "NGN", name: "Nigerian Naira", symbol: "₦"},
    {code: "NIO", name: "Nicaraguan Córdoba", symbol: "C$"},
    {code: "NOK", name: "Norwegian Krone", symbol: "Nkr"},
    {code: "NPR", name: "Nepalese Rupee", symbol: "NPRs"},
    {code: "NZD", name: "New Zealand Dollar", symbol: "NZ$"},
    {code: "OMR", name: "Omani Rial", symbol: "OMR"},
    {code: "PAB", name: "Panamanian Balboa", symbol: "B/."},
    {code: "PEN", name: "Peruvian Nuevo Sol", symbol: "S/."},
    {code: "PGK", name: "Papua New Guinean Kina", symbol: "PGK"},
    {code: "PHP", name: "Philippine Peso", symbol: "₱"},
    {code: "PKR", name: "Pakistani Rupee", symbol: "PKRs"},
    {code: "PLN", name: "Polish Zloty", symbol: "zł"},
    {code: "PYG", name: "Paraguayan Guarani", symbol: "₲"},
    {code: "QAR", name: "Qatari Rial", symbol: "QR"},
    {code: "RON", name: "Romanian Leu", symbol: "RON"},
    {code: "RSD", name: "Serbian Dinar", symbol: "din."},
    {code: "RUB", name: "Russian Ruble", symbol: "RUB"},
    {code: "RWF", name: "Rwandan Franc", symbol: "RWF"},
    {code: "SAR", name: "Saudi Riyal", symbol: "SR"},
    {code: "SBD", name: "Solomon Islands Dollar", symbol: "SBD"},
    {code: "SCR", name: "Seychellois Rupee", symbol: "SCR"},
    {code: "SDG", name: "Sudanese Pound", symbol: "SDG"},
    {code: "SEK", name: "Swedish Krona", symbol: "Skr"},
    {code: "SGD", name: "Singapore Dollar", symbol: "S$"},
    {code: "SHP", name: "Saint Helena Pound", symbol: "SHP"},
    {code: "SLL", name: "Sierra Leonean Leone", symbol: "SLL"},
    {code: "SOS", name: "Somali Shilling", symbol: "Ssh"},
    {code: "SRD", name: "Surinamese Dollar", symbol: "SRD"},
    {code: "SSP", name: "South Sudanese Pound", symbol: "SSP"},
    {code: "STD", name: "São Tomé and Príncipe Dobra (pre-2018)", symbol: "STD"},
    {code: "STN", name: "São Tomé and Príncipe Dobra", symbol: "STN"},
    {code: "SVC", name: "Salvadoran Colón", symbol: "SVC"},
    {code: "SYP", name: "Syrian Pound", symbol: "SY£"},
    {code: "SZL", name: "Swazi Lilangeni", symbol: "SZL"},
    {code: "THB", name: "Thai Baht", symbol: "฿"},
    {code: "TJS", name: "Tajikistani Somoni", symbol: "TJS"},
    {code: "TMT", name: "Turkmenistani Manat", symbol: "TMT"},
    {code: "TND", name: "Tunisian Dinar", symbol: "DT"},
    {code: "TOP", name: "Tongan Paʻanga", symbol: "T$"},
    {code: "TRY", name: "Turkish Lira", symbol: "₺"},
    {code: "TTD", name: "Trinidad and Tobago Dollar", symbol: "TT$"},
    {code: "TWD", name: "New Taiwan Dollar", symbol: "NT$"},
    {code: "TZS", name: "Tanzanian Shilling", symbol: "TSh"},
    {code: "UAH", name: "Ukrainian Hryvnia", symbol: "₴"},
    {code: "UGX", name: "Ugandan Shilling", symbol: "USh"},
    {code: "USD", name: "US Dollar", symbol: "$"},
    {code: "UYU", name: "Uruguayan Peso", symbol: "$U"},
    {code: "UZS", name: "Uzbekistan Som", symbol: "UZS"},
    {code: "VEF", name: "Venezuelan Bolívar Fuerte (Old)", symbol: "VEF"},
    {code: "VES", name: "Venezuelan Bolívar Soberano", symbol: "VES"},
    {code: "VND", name: "Vietnamese Dong", symbol: "₫"},
    {code: "VUV", name: "Vanuatu Vatu", symbol: "VUV"},
    {code: "WST", name: "Samoan Tala", symbol: "WST"},
    {code: "XAF", name: "CFA Franc BEAC", symbol: "FCFA"},
    {code: "XAG", name: "Silver Ounce", symbol: "XAG"},
    {code: "XAU", name: "Gold Ounce", symbol: "XAU"},
    {code: "XCD", name: "East Caribbean Dollar", symbol: "XCD"},
    {code: "XDR", name: "Special Drawing Rights", symbol: "XDR"},
    {code: "XOF", name: "CFA Franc BCEAO", symbol: "CFA"},
    {code: "XPD", name: "Palladium Ounce", symbol: "XPD"},
    {code: "XPF", name: "CFP Franc", symbol: "XPF"},
    {code: "XPT", name: "Platinum Ounce", symbol: "XPT"},
    {code: "YER", name: "Yemeni Rial", symbol: "YR"},
    {code: "ZAR", name: "South African Rand", symbol: "R"},
    {code: "ZMW", name: "Zambian Kwacha", symbol: "ZK"},
    {code: "ZWL", name: "Zimbabwean Dollar", symbol: "ZWL$"}
];

export const countries = [
    {code: "", label: "Global", phone: ""},
    {code: "AD", label: "Andorra", phone: "376"},
    {code: "AE", label: "United Arab Emirates", phone: "971"},
    {code: "AF", label: "Afghanistan", phone: "93"},
    {code: "AG", label: "Antigua and Barbuda", phone: "1-268"},
    {code: "AI", label: "Anguilla", phone: "1-264"},
    {code: "AL", label: "Albania", phone: "355"},
    {code: "AM", label: "Armenia", phone: "374"},
    {code: "AO", label: "Angola", phone: "244"},
    {code: "AQ", label: "Antarctica", phone: "672"},
    {code: "AR", label: "Argentina", phone: "54"},
    {code: "AS", label: "American Samoa", phone: "1-684"},
    {code: "AT", label: "Austria", phone: "43"},
    {code: "AU", label: "Australia", phone: "61"},
    {code: "AW", label: "Aruba", phone: "297"},
    {code: "AX", label: "Alland Islands", phone: "358"},
    {code: "AZ", label: "Azerbaijan", phone: "994"},
    {code: "BA", label: "Bosnia and Herzegovina", phone: "387"},
    {code: "BB", label: "Barbados", phone: "1-246"},
    {code: "BD", label: "Bangladesh", phone: "880"},
    {code: "BE", label: "Belgium", phone: "32"},
    {code: "BF", label: "Burkina Faso", phone: "226"},
    {code: "BG", label: "Bulgaria", phone: "359"},
    {code: "BH", label: "Bahrain", phone: "973"},
    {code: "BI", label: "Burundi", phone: "257"},
    {code: "BJ", label: "Benin", phone: "229"},
    {code: "BL", label: "Saint Barthelemy", phone: "590"},
    {code: "BM", label: "Bermuda", phone: "1-441"},
    {code: "BN", label: "Brunei Darussalam", phone: "673"},
    {code: "BO", label: "Bolivia", phone: "591"},
    {code: "BR", label: "Brazil", phone: "55"},
    {code: "BS", label: "Bahamas", phone: "1-242"},
    {code: "BT", label: "Bhutan", phone: "975"},
    {code: "BV", label: "Bouvet Island", phone: "47"},
    {code: "BW", label: "Botswana", phone: "267"},
    {code: "BY", label: "Belarus", phone: "375"},
    {code: "BZ", label: "Belize", phone: "501"},
    {code: "CA", label: "Canada", phone: "1"},
    {code: "CC", label: "Cocos (Keeling) Islands", phone: "61"},
    {code: "CD", label: "Congo, Republic of the", phone: "242"},
    {code: "CF", label: "Central African Republic", phone: "236"},
    {code: "CG", label: "Congo, Democratic Republic of the", phone: "243"},
    {code: "CH", label: "Switzerland", phone: "41"},
    {code: "CI", label: "Cote d'Ivoire", phone: "225"},
    {code: "CK", label: "Cook Islands", phone: "682"},
    {code: "CL", label: "Chile", phone: "56"},
    {code: "CM", label: "Cameroon", phone: "237"},
    {code: "CN", label: "China", phone: "86"},
    {code: "CO", label: "Colombia", phone: "57"},
    {code: "CR", label: "Costa Rica", phone: "506"},
    {code: "CU", label: "Cuba", phone: "53"},
    {code: "CV", label: "Cape Verde", phone: "238"},
    {code: "CW", label: "Curacao", phone: "599"},
    {code: "CX", label: "Christmas Island", phone: "61"},
    {code: "CY", label: "Cyprus", phone: "357"},
    {code: "CZ", label: "Czech Republic", phone: "420"},
    {code: "DE", label: "Germany", phone: "49"},
    {code: "DJ", label: "Djibouti", phone: "253"},
    {code: "DK", label: "Denmark", phone: "45"},
    {code: "DM", label: "Dominica", phone: "1-767"},
    {code: "DO", label: "Dominican Republic", phone: "1-809"},
    {code: "DZ", label: "Algeria", phone: "213"},
    {code: "EC", label: "Ecuador", phone: "593"},
    {code: "EE", label: "Estonia", phone: "372"},
    {code: "EG", label: "Egypt", phone: "20"},
    {code: "EH", label: "Western Sahara", phone: "212"},
    {code: "ER", label: "Eritrea", phone: "291"},
    {code: "ES", label: "Spain", phone: "34"},
    {code: "ET", label: "Ethiopia", phone: "251"},
    {code: "FI", label: "Finland", phone: "358"},
    {code: "FJ", label: "Fiji", phone: "679"},
    {code: "FK", label: "Falkland Islands (Malvinas)", phone: "500"},
    {code: "FM", label: "Micronesia, Federated States of", phone: "691"},
    {code: "FO", label: "Faroe Islands", phone: "298"},
    {code: "FR", label: "France", phone: "33"},
    {code: "GA", label: "Gabon", phone: "241"},
    {code: "GB", label: "United Kingdom", phone: "44"},
    {code: "GD", label: "Grenada", phone: "1-473"},
    {code: "GE", label: "Georgia", phone: "995"},
    {code: "GF", label: "French Guiana", phone: "594"},
    {code: "GG", label: "Guernsey", phone: "44"},
    {code: "GH", label: "Ghana", phone: "233"},
    {code: "GI", label: "Gibraltar", phone: "350"},
    {code: "GL", label: "Greenland", phone: "299"},
    {code: "GM", label: "Gambia", phone: "220"},
    {code: "GN", label: "Guinea", phone: "224"},
    {code: "GP", label: "Guadeloupe", phone: "590"},
    {code: "GQ", label: "Equatorial Guinea", phone: "240"},
    {code: "GR", label: "Greece", phone: "30"},
    {code: "GS", label: "South Georgia and the South Sandwich Islands", phone: "500"},
    {code: "GT", label: "Guatemala", phone: "502"},
    {code: "GU", label: "Guam", phone: "1-671"},
    {code: "GW", label: "Guinea-Bissau", phone: "245"},
    {code: "GY", label: "Guyana", phone: "592"},
    {code: "HK", label: "Hong Kong", phone: "852"},
    {code: "HM", label: "Heard Island and McDonald Islands", phone: "672"},
    {code: "HN", label: "Honduras", phone: "504"},
    {code: "HR", label: "Croatia", phone: "385"},
    {code: "HT", label: "Haiti", phone: "509"},
    {code: "HU", label: "Hungary", phone: "36"},
    {code: "ID", label: "Indonesia", phone: "62"},
    {code: "IE", label: "Ireland", phone: "353"},
    {code: "IL", label: "Israel", phone: "972"},
    {code: "IM", label: "Isle of Man", phone: "44"},
    {code: "IN", label: "India", phone: "91"},
    {code: "IO", label: "British Indian Ocean Territory", phone: "246"},
    {code: "IQ", label: "Iraq", phone: "964"},
    {code: "IR", label: "Iran, Islamic Republic of", phone: "98"},
    {code: "IS", label: "Iceland", phone: "354"},
    {code: "IT", label: "Italy", phone: "39"},
    {code: "JE", label: "Jersey", phone: "44"},
    {code: "JM", label: "Jamaica", phone: "1-876"},
    {code: "JO", label: "Jordan", phone: "962"},
    {code: "JP", label: "Japan", phone: "81"},
    {code: "KE", label: "Kenya", phone: "254"},
    {code: "KG", label: "Kyrgyzstan", phone: "996"},
    {code: "KH", label: "Cambodia", phone: "855"},
    {code: "KI", label: "Kiribati", phone: "686"},
    {code: "KM", label: "Comoros", phone: "269"},
    {code: "KN", label: "Saint Kitts and Nevis", phone: "1-869"},
    {code: "KP", label: "Korea, Democratic People's Republic of", phone: "850"},
    {code: "KR", label: "Korea, Republic of", phone: "82"},
    {code: "KW", label: "Kuwait", phone: "965"},
    {code: "KY", label: "Cayman Islands", phone: "1-345"},
    {code: "KZ", label: "Kazakhstan", phone: "7"},
    {code: "LA", label: "Lao People's Democratic Republic", phone: "856"},
    {code: "LB", label: "Lebanon", phone: "961"},
    {code: "LC", label: "Saint Lucia", phone: "1-758"},
    {code: "LI", label: "Liechtenstein", phone: "423"},
    {code: "LK", label: "Sri Lanka", phone: "94"},
    {code: "LR", label: "Liberia", phone: "231"},
    {code: "LS", label: "Lesotho", phone: "266"},
    {code: "LT", label: "Lithuania", phone: "370"},
    {code: "LU", label: "Luxembourg", phone: "352"},
    {code: "LV", label: "Latvia", phone: "371"},
    {code: "LY", label: "Libya", phone: "218"},
    {code: "MA", label: "Morocco", phone: "212"},
    {code: "MC", label: "Monaco", phone: "377"},
    {code: "MD", label: "Moldova, Republic of", phone: "373"},
    {code: "ME", label: "Montenegro", phone: "382"},
    {code: "MF", label: "Saint Martin (French part)", phone: "590"},
    {code: "MG", label: "Madagascar", phone: "261"},
    {code: "MH", label: "Marshall Islands", phone: "692"},
    {code: "MK", label: "Macedonia, the Former Yugoslav Republic of", phone: "389"},
    {code: "ML", label: "Mali", phone: "223"},
    {code: "MM", label: "Myanmar", phone: "95"},
    {code: "MN", label: "Mongolia", phone: "976"},
    {code: "MO", label: "Macao", phone: "853"},
    {code: "MP", label: "Northern Mariana Islands", phone: "1-670"},
    {code: "MQ", label: "Martinique", phone: "596"},
    {code: "MR", label: "Mauritania", phone: "222"},
    {code: "MS", label: "Montserrat", phone: "1-664"},
    {code: "MT", label: "Malta", phone: "356"},
    {code: "MU", label: "Mauritius", phone: "230"},
    {code: "MV", label: "Maldives", phone: "960"},
    {code: "MW", label: "Malawi", phone: "265"},
    {code: "MX", label: "Mexico", phone: "52"},
    {code: "MY", label: "Malaysia", phone: "60"},
    {code: "MZ", label: "Mozambique", phone: "258"},
    {code: "NA", label: "Namibia", phone: "264"},
    {code: "NC", label: "New Caledonia", phone: "687"},
    {code: "NE", label: "Niger", phone: "227"},
    {code: "NF", label: "Norfolk Island", phone: "672"},
    {code: "NG", label: "Nigeria", phone: "234"},
    {code: "NI", label: "Nicaragua", phone: "505"},
    {code: "NL", label: "Netherlands", phone: "31"},
    {code: "NO", label: "Norway", phone: "47"},
    {code: "NP", label: "Nepal", phone: "977"},
    {code: "NR", label: "Nauru", phone: "674"},
    {code: "NU", label: "Niue", phone: "683"},
    {code: "NZ", label: "New Zealand", phone: "64"},
    {code: "OM", label: "Oman", phone: "968"},
    {code: "PA", label: "Panama", phone: "507"},
    {code: "PE", label: "Peru", phone: "51"},
    {code: "PF", label: "French Polynesia", phone: "689"},
    {code: "PG", label: "Papua New Guinea", phone: "675"},
    {code: "PH", label: "Philippines", phone: "63"},
    {code: "PK", label: "Pakistan", phone: "92"},
    {code: "PL", label: "Poland", phone: "48"},
    {code: "PM", label: "Saint Pierre and Miquelon", phone: "508"},
    {code: "PN", label: "Pitcairn", phone: "870"},
    {code: "PR", label: "Puerto Rico", phone: "1"},
    {code: "PS", label: "Palestine, State of", phone: "970"},
    {code: "PT", label: "Portugal", phone: "351"},
    {code: "PW", label: "Palau", phone: "680"},
    {code: "PY", label: "Paraguay", phone: "595"},
    {code: "QA", label: "Qatar", phone: "974"},
    {code: "RE", label: "Reunion", phone: "262"},
    {code: "RO", label: "Romania", phone: "40"},
    {code: "RS", label: "Serbia", phone: "381"},
    {code: "RU", label: "Russian Federation", phone: "7"},
    {code: "RW", label: "Rwanda", phone: "250"},
    {code: "SA", label: "Saudi Arabia", phone: "966"},
    {code: "SB", label: "Solomon Islands", phone: "677"},
    {code: "SC", label: "Seychelles", phone: "248"},
    {code: "SD", label: "Sudan", phone: "249"},
    {code: "SE", label: "Sweden", phone: "46"},
    {code: "SG", label: "Singapore", phone: "65"},
    {code: "SH", label: "Saint Helena", phone: "290"},
    {code: "SI", label: "Slovenia", phone: "386"},
    {code: "SJ", label: "Svalbard and Jan Mayen", phone: "47"},
    {code: "SK", label: "Slovakia", phone: "421"},
    {code: "SL", label: "Sierra Leone", phone: "232"},
    {code: "SM", label: "San Marino", phone: "378"},
    {code: "SN", label: "Senegal", phone: "221"},
    {code: "SO", label: "Somalia", phone: "252"},
    {code: "SR", label: "Suriname", phone: "597"},
    {code: "SS", label: "South Sudan", phone: "211"},
    {code: "ST", label: "Sao Tome and Principe", phone: "239"},
    {code: "SV", label: "El Salvador", phone: "503"},
    {code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721"},
    {code: "SY", label: "Syrian Arab Republic", phone: "963"},
    {code: "SZ", label: "Swaziland", phone: "268"},
    {code: "TC", label: "Turks and Caicos Islands", phone: "1-649"},
    {code: "TD", label: "Chad", phone: "235"},
    {code: "TF", label: "French Southern Territories", phone: "262"},
    {code: "TG", label: "Togo", phone: "228"},
    {code: "TH", label: "Thailand", phone: "66"},
    {code: "TJ", label: "Tajikistan", phone: "992"},
    {code: "TK", label: "Tokelau", phone: "690"},
    {code: "TL", label: "Timor-Leste", phone: "670"},
    {code: "TM", label: "Turkmenistan", phone: "993"},
    {code: "TN", label: "Tunisia", phone: "216"},
    {code: "TO", label: "Tonga", phone: "676"},
    {code: "TR", label: "Turkey", phone: "90"},
    {code: "TT", label: "Trinidad and Tobago", phone: "1-868"},
    {code: "TV", label: "Tuvalu", phone: "688"},
    {code: "TW", label: "Taiwan, Province of China", phone: "886"},
    {code: "TZ", label: "United Republic of Tanzania", phone: "255"},
    {code: "UA", label: "Ukraine", phone: "380"},
    {code: "UG", label: "Uganda", phone: "256"},
    {code: "US", label: "United States", phone: "1"},
    {code: "UY", label: "Uruguay", phone: "598"},
    {code: "UZ", label: "Uzbekistan", phone: "998"},
    {code: "VA", label: "Holy See (Vatican City State)", phone: "379"},
    {code: "VC", label: "Saint Vincent and the Grenadines", phone: "1-784"},
    {code: "VE", label: "Venezuela", phone: "58"},
    {code: "VG", label: "British Virgin Islands", phone: "1-284"},
    {code: "VI", label: "US Virgin Islands", phone: "1-340"},
    {code: "VN", label: "Vietnam", phone: "84"},
    {code: "VU", label: "Vanuatu", phone: "678"},
    {code: "WF", label: "Wallis and Futuna", phone: "681"},
    {code: "WS", label: "Samoa", phone: "685"},
    {code: "XK", label: "Kosovo", phone: "383"},
    {code: "YE", label: "Yemen", phone: "967"},
    {code: "YT", label: "Mayotte", phone: "262"},
    {code: "ZA", label: "South Africa", phone: "27"},
    {code: "ZM", label: "Zambia", phone: "260"},
    {code: "ZW", label: "Zimbabwe", phone: "263"}
];

export const browserLanguages = [
    {code: "af", language: "Afrikaans"},
    {code: "sq", language: "Albanian"},
    {code: "ar", language: "Arabic"},
    {code: "an", language: "Aragonese"},
    {code: "hy", language: "Armenian"},
    {code: "as", language: "Assamese"},
    {code: "ast", language: "Asturian"},
    {code: "az", language: "Azerbaijani"},
    {code: "eu", language: "Basque"},
    {code: "be", language: "Belarusian"},
    {code: "bn", language: "Bengali"},
    {code: "bs", language: "Bosnian"},
    {code: "br", language: "Breton"},
    {code: "bg", language: "Bulgarian"},
    {code: "my", language: "Burmese"},
    {code: "ca", language: "Catalan"},
    {code: "ch", language: "Chamorro"},
    {code: "ce", language: "Chechen"},
    {code: "zh", language: "Chinese"},
    {code: "cv", language: "Chuvash"},
    {code: "co", language: "Corsican"},
    {code: "cr", language: "Cree"},
    {code: "hr", language: "Croatian"},
    {code: "cs", language: "Czech"},
    {code: "da", language: "Danish"},
    {code: "nl", language: "Dutch"},
    {code: "en", language: "English"},
    {code: "eo", language: "Esperanto"},
    {code: "et", language: "Estonian"},
    {code: "fo", language: "Faeroese"},
    {code: "fa", language: "Farsi"},
    {code: "fj", language: "Fijian"},
    {code: "fi", language: "Finnish"},
    {code: "fr", language: "French"},
    {code: "fy", language: "Frisian"},
    {code: "fur", language: "Friulian"},
    {code: "gd", language: "Gaelic"},
    {code: "gl", language: "Galacian"},
    {code: "ka", language: "Georgian"},
    {code: "de", language: "German"},
    {code: "el", language: "Greek"},
    {code: "gu", language: "Gujurati"},
    {code: "ht", language: "Haitian"},
    {code: "he", language: "Hebrew"},
    {code: "hi", language: "Hindi"},
    {code: "hu", language: "Hungarian"},
    {code: "is", language: "Icelandic"},
    {code: "id", language: "Indonesian"},
    {code: "iu", language: "Inuktitut"},
    {code: "ga", language: "Irish"},
    {code: "it", language: "Italian"},
    {code: "ja", language: "Japanese"},
    {code: "kn", language: "Kannada"},
    {code: "ks", language: "Kashmiri"},
    {code: "kk", language: "Kazakh"},
    {code: "km", language: "Khmer"},
    {code: "ky", language: "Kirghiz"},
    {code: "tlh", language: "Klingon"},
    {code: "ko", language: "Korean"},
    {code: "la", language: "Latin"},
    {code: "lv", language: "Latvian"},
    {code: "lt", language: "Lithuanian"},
    {code: "lb", language: "Luxembourgish"},
    {code: "mk", language: "FYRO Macedonian"},
    {code: "ms", language: "Malay"},
    {code: "ml", language: "Malayalam"},
    {code: "mt", language: "Maltese"},
    {code: "mi", language: "Maori"},
    {code: "mr", language: "Marathi"},
    {code: "mo", language: "Moldavian"},
    {code: "nv", language: "Navajo"},
    {code: "ng", language: "Ndonga"},
    {code: "ne", language: "Nepali"},
    {code: "no", language: "Norwegian"},
    {code: "nb", language: "Norwegian (Bokmal)"},
    {code: "nn", language: "Norwegian (Nynorsk)"},
    {code: "oc", language: "Occitan"},
    {code: "or", language: "Oriya"},
    {code: "om", language: "Oromo"},
    {code: "fa", language: "Persian"},
    {code: "pl", language: "Polish"},
    {code: "pt", language: "Portuguese"},
    {code: "pa", language: "Punjabi"},
    {code: "qu", language: "Quechua"},
    {code: "rm", language: "Rhaeto-Romanic"},
    {code: "ro", language: "Romanian"},
    {code: "ru", language: "Russian"},
    {code: "sz", language: "Sami (Lappish)"},
    {code: "sg", language: "Sango"},
    {code: "sa", language: "Sanskrit"},
    {code: "sc", language: "Sardinian"},
    {code: "sd", language: "Sindhi"},
    {code: "si", language: "Singhalese"},
    {code: "sr", language: "Serbian"},
    {code: "sk", language: "Slovak"},
    {code: "sl", language: "Slovenian"},
    {code: "so", language: "Somani"},
    {code: "sb", language: "Sorbian"},
    {code: "es", language: "Spanish"},
    {code: "sx", language: "Sutu"},
    {code: "sw", language: "Swahili"},
    {code: "sv", language: "Swedish"},
    {code: "ta", language: "Tamil"},
    {code: "tt", language: "Tatar"},
    {code: "te", language: "Teluga"},
    {code: "th", language: "Thai"},
    {code: "tig", language: "Tigre"},
    {code: "ts", language: "Tsonga"},
    {code: "tn", language: "Tswana"},
    {code: "tr", language: "Turkish"},
    {code: "tk", language: "Turkmen"},
    {code: "uk", language: "Ukrainian"},
    {code: "hsb", language: "Upper Sorbian"},
    {code: "ur", language: "Urdu"},
    {code: "ve", language: "Venda"},
    {code: "vi", language: "Vietnamese"},
    {code: "vo", language: "Volapuk"},
    {code: "wa", language: "Walloon"},
    {code: "cy", language: "Welsh"},
    {code: "xh", language: "Xhosa"},
    {code: "ji", language: "Yiddish"},
    {code: "zu", language: "Zulu"}
];

export const dayOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
];

export const timeOfDay = [
    {code: "00", label: "00:00 - 00:59"},
    {code: "01", label: "01:00 - 01:59"},
    {code: "02", label: "02:00 - 02:59"},
    {code: "03", label: "03:00 - 03:59"},
    {code: "04", label: "04:00 - 04:59"},
    {code: "05", label: "05:00 - 05:59"},
    {code: "06", label: "06:00 - 06:59"},
    {code: "07", label: "07:00 - 07:59"},
    {code: "08", label: "08:00 - 08:59"},
    {code: "09", label: "09:00 - 09:59"},
    {code: "10", label: "10:00 - 10:59"},
    {code: "11", label: "11:00 - 11:59"},
    {code: "12", label: "12:00 - 12:59"},
    {code: "13", label: "13:00 - 13:59"},
    {code: "14", label: "14:00 - 14:59"},
    {code: "15", label: "15:00 - 15:59"},
    {code: "16", label: "16:00 - 16:59"},
    {code: "17", label: "17:00 - 17:59"},
    {code: "18", label: "18:00 - 18:59"},
    {code: "19", label: "19:00 - 19:59"},
    {code: "20", label: "20:00 - 20:59"},
    {code: "21", label: "21:00 - 21:59"},
    {code: "22", label: "22:00 - 22:59"},
    {code: "23", label: "23:00 - 23:59"}
];

export const deviceTypes = [
    "Desktop",
    "Mobile",
    "Tablet",
    "TV",
    "Portable Media Player",
    "Gaming Console",
    "Car Browser",
    "Camera"
];

export const operationSystems = [
    "AIX",
    "AmigaOS",
    "Android",
    "Arch Linux",
    "Bada",
    "BeOS",
    "BlackBerry OS",
    "BlackBerry Tablet OS",
    "Brew",
    "Caixa Mágica",
    "Chrome OS",
    "CyanogenMod",
    "DVKBuntu",
    "Deepin",
    "DragonFly",
    "Fenix",
    "Fire OS",
    "Firefox OS",
    "FreeBSD",
    "FydeOS",
    "GNU/Linux",
    "Google TV",
    "GridOS",
    "HP-UX",
    "Haiku OS",
    "HarmonyOS",
    "HasCodingOS",
    "IRIX",
    "Inferno",
    "Java ME",
    "KaiOS",
    "Lumin OS",
    "MRE",
    "MTK / Nucleus",
    "Mac",
    "Maemo",
    "Mageia",
    "MeeGo",
    "MildWild",
    "MocorDroid",
    "MorphOS",
    "NetBSD",
    "Nintendo",
    "Nintendo Mobile",
    "OS/2",
    "OSF1",
    "OpenBSD",
    "OpenWrt",
    "Ordissimo",
    "PCLinuxOS",
    "PlayStation",
    "PlayStation Portable",
    "REX",
    "RISC OS",
    "RazoDroiD",
    "Remix OS",
    "Roku OS",
    "Rosa",
    "Sailfish OS",
    "SeewoOS",
    "Solaris",
    "Syllable",
    "Symbian",
    "Symbian OS",
    "Symbian OS Series 40",
    "Symbian OS Series 60",
    "Symbian^3",
    "ThreadX",
    "Tizen",
    "TmaxOS",
    "VectorLinux",
    "WebTV",
    "Whale OS",
    "Windows",
    "Windows CE",
    "Windows IoT",
    "Windows Mobile",
    "Windows Phone",
    "Windows RT",
    "Xbox",
    "YunOS",
    "iOS",
    "iPadOS",
    "palmOS",
    "tvOS",
    "watchOS",
    "webOS"
];

export const browsers = [
    "115 Browser",
    "2345 Browser",
    "360 Browser",
    "360 Phone Browser",
    "7654 Browser",
    "7Star",
    "ABrowse",
    "ANT Fresco",
    "ANTGalio",
    "AOL Desktop",
    "AOL Shield",
    "AVG Secure Browser",
    "Aloha Browser",
    "Aloha Browser Lite",
    "Amaya",
    "Amiga Aweb",
    "Amiga Voyager",
    "Amigo",
    "Android Browser",
    "Arctic Fox",
    "Arora",
    "Arvin",
    "Atom",
    "Atomic Web Browser",
    "Avant Browser",
    "Avast Secure Browser",
    "Avira Scout",
    "AwoX",
    "B-Line",
    "Baidu Browser",
    "Baidu Spark",
    "Basilisk",
    "Beaker Browser",
    "Beamrise",
    "Beonex",
    "Biyubi",
    "BlackBerry Browser",
    "BlackHawk",
    "Blue Browser",
    "Bonsai",
    "Borealis Navigator",
    "Brave",
    "BriskBard",
    "BrowseX",
    "Browzar",
    "Bunjalloo",
    "Byffox",
    "CCleaner",
    "CM Browser",
    "COS Browser",
    "Camino",
    "Centaury",
    "ChanjetCloud",
    "Charon",
    "Chedot",
    "Cheetah Browser",
    "Cheshire",
    "Chim Lac",
    "Chrome",
    "Chrome Frame",
    "Chrome Mobile",
    "Chrome Mobile iOS",
    "Chrome Webview",
    "ChromePlus",
    "Chromium",
    "Chromium GOST",
    "Coast",
    "Coc Coc",
    "Colibri",
    "CometBird",
    "Comodo Dragon",
    "Conkeror",
    "CoolBrowser",
    "CoolNovo",
    "Cornowser",
    "Craving Explorer",
    "Crazy Browser",
    "Crusta",
    "Cunaguaro",
    "Cyberfox",
    "Deepnet Explorer",
    "Deledao",
    "Delta Browser",
    "DeskBrowse",
    "Dillo",
    "Dolphin",
    "Dooble",
    "Dorado",
    "Dot Browser",
    "DuckDuckGo Privacy Browser",
    "EUI Browser",
    "Ecosia",
    "Edge WebView",
    "Element Browser",
    "Elements Browser",
    "Elinks",
    "Epic",
    "Espial TV Browser",
    "Falkon",
    "Faux Browser",
    "Fennec",
    "Firebird",
    "Firefox",
    "Firefox Focus",
    "Firefox Mobile",
    "Firefox Mobile iOS",
    "Firefox Reality",
    "Firefox Rocket",
    "Fireweb",
    "Fireweb Navigator",
    "Flash Browser",
    "Flast",
    "Flock",
    "Flow",
    "Fluid",
    "FreeU",
    "GNOME Web",
    "GOG Galaxy",
    "Galeon",
    "Ghostery Privacy Browser",
    "GinxDroid Browser",
    "Glass Browser",
    "GoBrowser",
    "Google Earth",
    "Harman Browser",
    "HasBrowser",
    "Hawk Turbo Browser",
    "Headless Chrome",
    "Helio",
    "HotJava",
    "Huawei Browser",
    "Huawei Browser Mobile",
    "IBrowse",
    "IE Mobile",
    "IceCat",
    "IceDragon",
    "Iceweasel",
    "Internet Explorer",
    "Iridium",
    "Iron",
    "Iron Mobile",
    "Isivioo",
    "Japan Browser",
    "Jasmine",
    "JavaFX",
    "Jig Browser",
    "Jig Browser Plus",
    "Jio Browser",
    "K-meleon",
    "K.Browser",
    "Kapiko",
    "Kazehakase",
    "Kindle Browser",
    "Kinza",
    "Kiwi",
    "Kode Browser",
    "Konqueror",
    "Kylo",
    "LG Browser",
    "LT Browser",
    "Lagatos Browser",
    "LieBaoFast",
    "Light",
    "Links",
    "Lolifox",
    "Lovense Browser",
    "LuaKit",
    "Lulumi",
    "Lunascape",
    "Lunascape Lite",
    "Lynx",
    "MAUI WAP Browser",
    "MIUI Browser",
    "Maelstrom",
    "Mandarin",
    "Maxthon",
    "Meizu Browser",
    "Mercury",
    "MicroB",
    "Microsoft Edge",
    "Midori",
    "Minimo",
    "Mint Browser",
    "Mobicip",
    "Mobile Safari",
    "Mobile Silk",
    "Monument Browser",
    "MxNitro",
    "Mypal",
    "NCSA Mosaic",
    "NFS Browser",
    "NTENT Browser",
    "Navigateur Web",
    "NetFront",
    "NetFront Life",
    "NetPositive",
    "NetSurf",
    "Netscape",
    "Nokia Browser",
    "Nokia OSS Browser",
    "Nokia Ovi Browser",
    "Nox Browser",
    "ONE Browser",
    "Obigo",
    "OceanHero",
    "Oculus Browser",
    "Odin",
    "Odyssey Web Browser",
    "Off By One",
    "OhHai Browser",
    "OmniWeb",
    "OpenFin",
    "Openwave Mobile Browser",
    "Opera",
    "Opera Devices",
    "Opera GX",
    "Opera Mini",
    "Opera Mini iOS",
    "Opera Mobile",
    "Opera Neon",
    "Opera Next",
    "Opera Touch",
    "Oppo Browser",
    "Orca",
    "Ordissimo",
    "Oregano",
    "Origin In-Game Overlay",
    "Origyn Web Browser",
    "Otter Browser",
    "PSI Secure Browser",
    "Pale Moon",
    "Palm Blazer",
    "Palm Pre",
    "Palm WebPro",
    "Palmscape",
    "Peeps dBrowser",
    "Perfect Browser",
    "Phantom Browser",
    "Phoenix",
    "Phoenix Browser",
    "PlayFree Browser",
    "Polaris",
    "Polarity",
    "PolyBrowser",
    "Polypane",
    "PrivacyWall",
    "Puffin",
    "QQ Browser",
    "QQ Browser Lite",
    "QQ Browser Mini",
    "Qazweb",
    "QtWebEngine",
    "Quark",
    "QupZilla",
    "Qutebrowser",
    "Qwant Mobile",
    "Realme Browser",
    "Rekonq",
    "RockMelt",
    "SEMC-Browser",
    "SFive",
    "SP Browser",
    "START Internet Browser",
    "Safari",
    "Safe Exam Browser",
    "Sailfish Browser",
    "SalamWeb",
    "Samsung Browser",
    "Secure Browser",
    "Seewo Browser",
    "Seraphic Sraf",
    "Seznam Browser",
    "Shiira",
    "SimpleBrowser",
    "Sizzy",
    "Skyfire",
    "Sleipnir",
    "Slimjet",
    "Smart Lenovo Browser",
    "Smooz",
    "Snowshoe",
    "Sogou Explorer",
    "Sogou Mobile Browser",
    "Spectre Browser",
    "Splash",
    "Sputnik Browser",
    "Stampy Browser",
    "Stargon",
    "Steam In-Game Overlay",
    "Streamy",
    "Sunrise",
    "Super Fast Browser",
    "SuperBird",
    "Sushi Browser",
    "Swiftfox",
    "T+Browser",
    "T-Browser",
    "TV Bro",
    "Tao Browser",
    "TenFourFox",
    "Tenta Browser",
    "Tizen Browser",
    "ToGate",
    "Tungsten",
    "TweakStyle",
    "UBrowser",
    "UC Browser",
    "UC Browser HD",
    "UC Browser Mini",
    "UC Browser Turbo",
    "UR Browser",
    "Uzbl",
    "VMware AirWatch",
    "Venus Browser",
    "Vision Mobile Browser",
    "Vivaldi",
    "Waterfox",
    "WeTab Browser",
    "Wear Internet Browser",
    "Web Explorer",
    "WebPositive",
    "Whale Browser",
    "Xiino",
    "Xvast",
    "Yaani Browser",
    "Yahoo! Japan Browser",
    "Yandex Browser",
    "Yandex Browser Lite",
    "Yolo Browser",
    "Zetakey",
    "Zvu",
    "dbrowser",
    "deg-degan",
    "eZ Browser",
    "hola! Browser",
    "iBrowser",
    "iBrowser Mini",
    "iCab",
    "iCab Mobile",
    "mCent",
    "surf",
    "t-online.de Browser",
    "vivo Browser",
    "wOSBrowser",
    "xStand"
];

export const deviceVendors = [
    "2E",
    "360",
    "3GNET",
    "3GO",
    "3Q",
    "4Good",
    "4ife",
    "7 Mobile",
    "8848",
    "A1",
    "AG Mobile",
    "AGM",
    "AIRON",
    "AIS",
    "AMGOO",
    "ANS",
    "AOC",
    "AOYODKG",
    "AOpen",
    "ARRIS",
    "AT&T",
    "AURIS",
    "AVH",
    "Accent",
    "Ace",
    "Acer",
    "Acteck",
    "Adronix",
    "Advan",
    "Advance",
    "AfriOne",
    "Ainol",
    "Airness",
    "Aiuto",
    "Aiwa",
    "Akai",
    "Alba",
    "Alcatel",
    "Alcor",
    "Alfawise",
    "Aligator",
    "AllCall",
    "AllDocube",
    "Allview",
    "Allwinner",
    "Altice",
    "Amazon",
    "Amigoo",
    "Amoi",
    "Andowl",
    "Anker",
    "Anry",
    "Aocos",
    "Aoson",
    "Apple",
    "Archos",
    "Arian Space",
    "Ark",
    "ArmPhone",
    "Arnova",
    "Artel",
    "Artizlee",
    "Asano",
    "Asanzo",
    "Ask",
    "Aspera",
    "Assistant",
    "Astro",
    "Asus",
    "Atom",
    "Atvio",
    "Audiovox",
    "Avenzo",
    "Avvio",
    "Awow",
    "Axioo",
    "Axxion",
    "Azumi Mobile",
    "BB Mobile",
    "BBK",
    "BDF",
    "BGH",
    "BIHEE",
    "BMAX",
    "BS Mobile",
    "Barnes & Noble",
    "Becker",
    "Beeline",
    "Beelink",
    "Beetel",
    "Bellphone",
    "BenQ",
    "BenQ-Siemens",
    "Benzo",
    "Beyond",
    "Bezkam",
    "Bigben",
    "BilimLand",
    "Billion",
    "BioRugged",
    "Bird",
    "Bitel",
    "Bitmore",
    "Bkav",
    "Black Bear",
    "Black Fox",
    "Blackview",
    "Blaupunkt",
    "Bleck",
    "Blloc",
    "Blow",
    "Blu",
    "Bluboo",
    "Bluebird",
    "Bluedot",
    "Bluegood",
    "Bluewave",
    "Bmobile",
    "Bobarry",
    "Boway",
    "Brandt",
    "Bravis",
    "BrightSign",
    "Brondi",
    "Bubblegum",
    "Bundy",
    "Bush",
    "CAGI",
    "CG Mobile",
    "CGV",
    "CORN",
    "CUBOT",
    "CVTE",
    "Camfone",
    "Canal Digital",
    "Capitel",
    "Captiva",
    "Carrefour",
    "Casio",
    "Casper",
    "Cat",
    "Cavion",
    "Celkon",
    "Cell-C",
    "CellAllure",
    "Cellution",
    "Centric",
    "Changhong",
    "Cherry Mobile",
    "Chico Mobile",
    "China Mobile",
    "Chuwi",
    "Claresta",
    "Clarmin",
    "Clementoni",
    "Cloud",
    "Cloudfone",
    "Cloudpad",
    "Clout",
    "CnM",
    "Cobalt",
    "Coby Kyros",
    "Colors",
    "ComTrade Tesla",
    "Comio",
    "Compal",
    "ConCorde",
    "Concord",
    "Condor",
    "Connectce",
    "Connex",
    "Conquest",
    "Contixo",
    "Coolpad",
    "Cosmote",
    "Covia",
    "Crescent",
    "Cricket",
    "Crius Mea",
    "Crony",
    "Crosscall",
    "Cube",
    "Cyrus",
    "DEXP",
    "DIGIFORS",
    "DING DING",
    "DISH",
    "DIXON",
    "DNS",
    "Daewoo",
    "Danew",
    "Datalogic",
    "Datamini",
    "Datang",
    "Datawind",
    "Datsun",
    "Dazen",
    "Dbtel",
    "DeWalt",
    "Dell",
    "Denver",
    "Desay",
    "Dialog",
    "Dicam",
    "Digi",
    "Digicel",
    "Digiland",
    "Digma",
    "Ditecma",
    "Diva",
    "Divisat",
    "DoCoMo",
    "Doffler",
    "Dolamee",
    "Doogee",
    "Doopro",
    "Doov",
    "Dopod",
    "Doro",
    "Dragon Touch",
    "Dreamgate",
    "Droxio",
    "Dune HD",
    "E-Boda",
    "E-Ceros",
    "E-tel",
    "EBEST",
    "ECS",
    "EE",
    "EKO",
    "EKT",
    "ELARI",
    "EWIS",
    "EXCEED",
    "EXO",
    "Eagle",
    "Easypix",
    "Echo Mobiles",
    "Einstein",
    "Eks Mobility",
    "Electroneum",
    "Elekta",
    "Element",
    "Elenberg",
    "Elephone",
    "Eltex",
    "Energizer",
    "Energy Sistem",
    "Engel",
    "Enot",
    "Epik One",
    "Epson",
    "Ergo",
    "Ericsson",
    "Ericy",
    "Erisson",
    "Essential",
    "Essentielb",
    "Eton",
    "Etuline",
    "Eurostar",
    "Evercoss",
    "Evertek",
    "Evolio",
    "Evolveo",
    "Evoo",
    "EvroMedia",
    "ExMobile",
    "Exmart",
    "Explay",
    "Extrem",
    "Ezio",
    "Ezze",
    "F150",
    "F2 Mobile",
    "FISE",
    "FLYCAT",
    "FMT",
    "FNB",
    "FNF",
    "FORME",
    "FaRao Pro",
    "Facebook",
    "Fairphone",
    "Famoco",
    "Fantec",
    "FarEasTone",
    "Fengxiang",
    "Fero",
    "FiGO",
    "FinePower",
    "FireFly Mobile",
    "Fly",
    "Fondi",
    "Fonos",
    "Formuler",
    "Forstar",
    "Fortis",
    "Four Mobile",
    "Fourel",
    "Foxconn",
    "Freetel",
    "Fujitsu",
    "G-TiDE",
    "G-Touch",
    "GEOFOX",
    "GFive",
    "GLX",
    "GOCLEVER",
    "Galaxy Innovations",
    "Garmin-Asus",
    "Gateway",
    "Gemini",
    "General Mobile",
    "Genesis",
    "Geotel",
    "Geotex",
    "Ghia",
    "Ghong",
    "Ghost",
    "Gigabyte",
    "Gigaset",
    "Gini",
    "Ginzzu",
    "Gionee",
    "Globex",
    "Glofiish",
    "GoMobile",
    "Gocomma",
    "Gol Mobile",
    "Goly",
    "Gome",
    "Google",
    "Goophone",
    "Gooweel",
    "Gradiente",
    "Grape",
    "Gree",
    "Greentel",
    "Gresso",
    "Gretel",
    "Grundig",
    "Gtel",
    "H96",
    "HERO",
    "HP",
    "HTC",
    "Hafury",
    "Haier",
    "HannSpree",
    "Hardkernel",
    "Hasee",
    "Helio",
    "Hezire",
    "Hi",
    "Hi-Level",
    "HiMax",
    "High Q",
    "Highscreen",
    "Hipstreet",
    "Hisense",
    "Hitech",
    "Hoffmann",
    "Hometech",
    "Homtom",
    "Honeywell",
    "Hoozo",
    "Hosin",
    "Hotwav",
    "How",
    "Huadoo",
    "Huavi",
    "Huawei",
    "Hurricane",
    "Huskee",
    "Hyundai",
    "Hyve",
    "IKI Mobile",
    "IKU Mobile",
    "IMO Mobile",
    "INCAR",
    "INQ",
    "INSYS",
    "IQM",
    "IT",
    "IUNI",
    "IconBIT",
    "Impression",
    "InFocus",
    "Inch",
    "Inco",
    "Infinix",
    "InfoKit",
    "Inkti",
    "InnJoo",
    "Innos",
    "Innostream",
    "Inoi",
    "Insignia",
    "Intex",
    "Invens",
    "Invin",
    "Irbis",
    "Iris",
    "JAY-Tech",
    "JFone",
    "JKL",
    "JVC",
    "Jeka",
    "Jesy",
    "Jiake",
    "Jiayu",
    "Jinga",
    "Jivi",
    "Jolla",
    "Juniper Systems",
    "Just5",
    "K-Touch",
    "KATV1",
    "KDDI",
    "KINGZONE",
    "KOPO",
    "KREZ",
    "KRIP",
    "KRONO",
    "KT-Tech",
    "Kaan",
    "Kaiomy",
    "Kalley",
    "Kanji",
    "Karbonn",
    "Kata",
    "Kazam",
    "Kazuna",
    "Kempler & Strauss",
    "Keneksi",
    "Kenxinda",
    "Kiano",
    "Kingsun",
    "Kiowa",
    "Kivi",
    "Klipad",
    "Kocaso",
    "Kodak",
    "Kogan",
    "Komu",
    "Konka",
    "Konrow",
    "Koobee",
    "Koolnee",
    "Kooper",
    "Koridy",
    "Krüger&Matz",
    "Kuliao",
    "Kult",
    "Kumai",
    "Kurio",
    "Kyocera",
    "Kyowon",
    "Kzen",
    "L-Max",
    "LAIQ",
    "LCT",
    "LEMFO",
    "LG",
    "LT Mobile",
    "LYF",
    "Land Rover",
    "Landvo",
    "Lanix",
    "Lark",
    "Lava",
    "Le Pan",
    "LeEco",
    "Leader Phone",
    "Leagoo",
    "Ledstar",
    "Lemhoov",
    "Lenco",
    "Lenovo",
    "Leotec",
    "Lephone",
    "Lesia",
    "Lexand",
    "Lexibook",
    "Liberton",
    "Lingwin",
    "Linnex",
    "Logic",
    "Logicom",
    "Lumigon",
    "Lumus",
    "Luna",
    "M-Horse",
    "M-Tech",
    "M.T.T.",
    "M4tel",
    "MAXVI",
    "MBOX",
    "MDC Store",
    "MDTV",
    "MEEG",
    "MEU",
    "MIVO",
    "MIXC",
    "MLLED",
    "MLS",
    "MSI",
    "MTC",
    "MTN",
    "MYFON",
    "Macoox",
    "Mafe",
    "Magnus",
    "Majestic",
    "Malata",
    "Mann",
    "Manta Multimedia",
    "Mantra",
    "Mara",
    "Masstel",
    "Matrix",
    "Maxcom",
    "Maximus",
    "Maxtron",
    "Maxwest",
    "Maze",
    "Maze Speed",
    "Mecer",
    "Mecool",
    "Mediacom",
    "Medion",
    "MegaFon",
    "Meitu",
    "Meizu",
    "Melrose",
    "Memup",
    "MiXzo",
    "MicroMax",
    "Microsoft",
    "Minix",
    "Mintt",
    "Mio",
    "Miray",
    "Mito",
    "Mitsubishi",
    "Mitsui",
    "MobiIoT",
    "MobiWire",
    "Mobicel",
    "Mobiistar",
    "Mobiola",
    "Mobistel",
    "Mobo",
    "Modecom",
    "Mofut",
    "Motorola",
    "Movic",
    "Mpman",
    "Multilaser",
    "MyGica",
    "MyPhone",
    "MyTab",
    "MyWigo",
    "Mymaga",
    "Myria",
    "Myros",
    "Mystery",
    "NEC",
    "NEXBOX",
    "NEXON",
    "NG Optics",
    "NGM",
    "NOA",
    "NOBUX",
    "NOGA",
    "NUU Mobile",
    "NYX Mobile",
    "Nabi",
    "Naomi Phone",
    "National",
    "Navcity",
    "Navitech",
    "Navitel",
    "Navon",
    "Necnot",
    "Neffos",
    "Neomi",
    "Neon IQ",
    "Netgear",
    "NeuImage",
    "New Balance",
    "Newgen",
    "Newland",
    "Newman",
    "NewsMy",
    "Newsday",
    "Nexian",
    "NextBook",
    "NextTab",
    "Nextbit",
    "Noain",
    "Nobby",
    "Noblex",
    "Nokia",
    "Nomi",
    "Nomu",
    "NorthTech",
    "Nos",
    "Nous",
    "Novex",
    "NuAns",
    "Nubia",
    "Nuvo",
    "Nvidia",
    "O+",
    "O2",
    "OASYS",
    "OINOM",
    "OKSI",
    "OKWU",
    "ONN",
    "ONYX BOOX",
    "OPPO",
    "Oale",
    "Obi",
    "Oculus",
    "Odys",
    "Ok",
    "Okapia",
    "Onda",
    "OnePlus",
    "Onix",
    "OpelMobile",
    "Openbox",
    "Opsson",
    "Orange",
    "Orbic",
    "Ordissimo",
    "Ouki",
    "Oukitel",
    "Overmax",
    "Ovvi",
    "Owwo",
    "Oysters",
    "Oyyu",
    "OzoneHD",
    "P-UP",
    "PCBOX",
    "PCD",
    "PCD Argentina",
    "POCO",
    "POPTEL",
    "PPTV",
    "PULID",
    "Packard Bell",
    "Paladin",
    "Palm",
    "Panacom",
    "Panasonic",
    "Pantech",
    "Parrot Mobile",
    "Pendoo",
    "Pentagram",
    "Perfeo",
    "Phicomm",
    "Philco",
    "Philips",
    "Phonemax",
    "PiPO",
    "Pico",
    "Pioneer",
    "Pixela",
    "Pixelphone",
    "Pixus",
    "Planet Computers",
    "Ployer",
    "Plum",
    "Pluzz",
    "PocketBook",
    "Point of View",
    "Polar",
    "PolarLine",
    "Polaroid",
    "PolyPad",
    "Polytron",
    "Pomp",
    "Poppox",
    "Positivo",
    "Positivo BGH",
    "Premio",
    "Prestigio",
    "Primepad",
    "Primux",
    "Prixton",
    "ProScan",
    "ProVision",
    "Proline",
    "Protruly",
    "Q-Box",
    "Q-Touch",
    "Q.Bell",
    "QMobile",
    "Qilive",
    "Qnet Mobile",
    "Qtek",
    "Quantum",
    "Qubo",
    "Quechua",
    "Qumo",
    "R-TV",
    "RCA Tablets",
    "RED",
    "RIM",
    "RT Project",
    "Rakuten",
    "Ramos",
    "Raspberry",
    "Ravoz",
    "Razer",
    "Reach",
    "Readboy",
    "Realme",
    "Reeder",
    "Remdun",
    "Revo",
    "Rikomagic",
    "Rinno",
    "Ritmix",
    "Ritzviva",
    "Riviera",
    "Rivo",
    "Roadrover",
    "Rokit",
    "Roku",
    "Rombica",
    "Ross&Moor",
    "Rover",
    "RoverPad",
    "RoyQueen",
    "RugGear",
    "Ruio",
    "Runbo",
    "Ryte",
    "S-TELL",
    "SEMP TCL",
    "SFR",
    "SKG",
    "SMARTEC",
    "SOLE",
    "SOLO",
    "SPC",
    "SQOOL",
    "STF Mobile",
    "STG Telecom",
    "STK",
    "STRONG",
    "SUNWIND",
    "SWISSMOBILITY",
    "Safaricom",
    "Sagem",
    "Samsung",
    "Sanei",
    "Sansui",
    "Santin",
    "Sanyo",
    "Savio",
    "Seatel",
    "Seeken",
    "Selecline",
    "Selenga",
    "Selfix",
    "Sencor",
    "Sendo",
    "Senkatel",
    "Senseit",
    "Senwa",
    "Seuic",
    "Sharp",
    "Shift Phones",
    "Shivaki",
    "Shtrikh-M",
    "Shuttle",
    "Sico",
    "Siemens",
    "Sigma",
    "Silelis",
    "Silent Circle",
    "Simbans",
    "Simply",
    "Singtech",
    "Siragon",
    "Sirin labs",
    "Sky",
    "Skyworth",
    "Smadl",
    "Smailo",
    "Smart",
    "SmartBook",
    "Smartab",
    "Smartfren",
    "Smartisan",
    "Smooth Mobile",
    "Smotreshka",
    "Softbank",
    "Soho Style",
    "Solone",
    "Sonim",
    "Sony",
    "Soundmax",
    "Soyes",
    "Spark",
    "Spectralink",
    "Spectrum",
    "Spice",
    "Sprint",
    "Star",
    "Starlight",
    "Starmobile",
    "Starway",
    "Stonex",
    "Storex",
    "StrawBerry",
    "Stylo",
    "Subor",
    "Sugar",
    "Sumvision",
    "SunVan",
    "Sunny",
    "Sunstech",
    "Sunvell",
    "SuperSonic",
    "SuperTab",
    "Supra",
    "Suzuki",
    "Swipe",
    "Swisstone",
    "Symphony",
    "Syrox",
    "T-Mobile",
    "TB Touch",
    "TCL",
    "TD Systems",
    "TEENO",
    "TIANYU",
    "TTEC",
    "TVC",
    "TWM",
    "TWZ",
    "Taiga System",
    "Takara",
    "Tambo",
    "Tanix",
    "TechPad",
    "Teclast",
    "Tecno Mobile",
    "Teknosa",
    "Tele2",
    "Telefunken",
    "Telego",
    "Telenor",
    "Telia",
    "Telit",
    "Telpo",
    "Tesco",
    "Tetratab",
    "ThL",
    "Thomson",
    "Thuraya",
    "TiPhone",
    "Time2",
    "Timovi",
    "Tinai",
    "Tinmo",
    "Tolino",
    "Tone",
    "Tooky",
    "Top House",
    "Topelotek",
    "Toplux",
    "Torex",
    "Toshiba",
    "Touchmate",
    "Transpeed",
    "TrekStor",
    "Trevi",
    "Trident",
    "Trifone",
    "Trio",
    "Tronsmart",
    "True",
    "True Slim",
    "Tunisie Telecom",
    "Turbo",
    "Turbo-X",
    "TurboKids",
    "TurboPad",
    "Turkcell",
    "Twoe",
    "Tymes",
    "Türk Telekom",
    "U.S. Cellular",
    "UMIDIGI",
    "UNIWA",
    "UTOK",
    "UTStarcom",
    "UTime",
    "UZ Mobile",
    "Ugoos",
    "Uhans",
    "Uhappy",
    "Ulefone",
    "Umax",
    "Unihertz",
    "Unimax",
    "Uniscope",
    "Unknown",
    "Unnecto",
    "Unonu",
    "Unowhy",
    "Urovo",
    "VAIO",
    "VAVA",
    "VC",
    "VGO TEL",
    "VIWA",
    "VK Mobile",
    "VKworld",
    "VVETIME",
    "Vargo",
    "Vastking",
    "Vega",
    "Venso",
    "Venturer",
    "Verico",
    "Verizon",
    "Vernee",
    "Vertex",
    "Vertu",
    "Verykool",
    "Vesta",
    "Vestel",
    "Vexia",
    "Videocon",
    "ViewSonic",
    "Vinga",
    "Vinsoc",
    "Vipro",
    "Virzo",
    "Vision Touch",
    "Vitelcom",
    "Viumee",
    "Vivax",
    "Vivo",
    "Vizio",
    "Vodacom",
    "Vodafone",
    "Vonino",
    "Vontar",
    "Vorago",
    "Vorke",
    "Voto",
    "Voxtel",
    "Voyo",
    "Vsmart",
    "Vsun",
    "Vulcan",
    "WE",
    "WIWA",
    "Walton",
    "Weimei",
    "WellcoM",
    "Western Digital",
    "Westpoint",
    "Wexler",
    "Wieppo",
    "Wigor",
    "Wiko",
    "Wileyfox",
    "Winds",
    "Wink",
    "Winmax",
    "Winnovo",
    "Wintouch",
    "Wiseasy",
    "Wolder",
    "Wolfgang",
    "Wolki",
    "Wonu",
    "Woo",
    "Wortmann",
    "Woxter",
    "X-BO",
    "X-TIGI",
    "X-View",
    "XGIMI",
    "Xgody",
    "Xiaolajiao",
    "Xiaomi",
    "Xion",
    "Xolo",
    "Xoro",
    "Xshitou",
    "Xtouch",
    "Xtratech",
    "YASIN",
    "YUHO",
    "Yandex",
    "Yarvik",
    "Yes",
    "Yezz",
    "Yoka TV",
    "Yota",
    "Ytone",
    "Yu",
    "Yuandao",
    "Yusun",
    "Yxtel",
    "ZH&K",
    "ZIFRO",
    "ZTE",
    "ZYQ",
    "Zaith",
    "Zatec",
    "Zebra",
    "Zeemi",
    "Zen",
    "Zenek",
    "Zentality",
    "Zfiner",
    "Zidoo",
    "Ziox",
    "Zonda",
    "Zopo",
    "Zuum",
    "Zync",
    "altron",
    "bogo",
    "bq",
    "eSTAR",
    "eTouch",
    "i-Cherry",
    "i-Joy",
    "i-mate",
    "i-mobile",
    "iBall",
    "iBerry",
    "iBrit",
    "iDroid",
    "iGet",
    "iHunt",
    "iKoMo",
    "iLA",
    "iLife",
    "iMan",
    "iMars",
    "iNew",
    "iOcean",
    "iOutdoor",
    "iPro",
    "iQ&T",
    "iRola",
    "iRulu",
    "iSWAG",
    "iTel",
    "iTruck",
    "iVA",
    "iView",
    "iVooMi",
    "iZotron",
    "ivvi",
    "mPhone",
    "meanIT",
    "phoneOne",
    "teXet",
    "v-mobile",
    "öwn"
];

export const startHours = () => {
    let hours = [];
    for (let i = 0; i < 24; i++) {
        let hour = (i < 10 ? '0' : '') + i + ':00';
        hours.push({label: hour, value: hour});
    }
    return hours;
};

export const endHours = () => {
    let hours = [];
    for (let i = 0; i < 24; i++) {
        let hour = (i < 10 ? '0' : '') + i + ':59';
        hours.push({label: hour, value: hour});
    }
    return hours;
};