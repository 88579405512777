import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme,
    Alert, Box, FormControlLabel, Switch, Typography
} from "@mui/material";
import React, {useCallback, useState} from "react";
import {useForm} from "react-hook-form";
import CurrencySelect from "../offers/CurrencySelect";
import Spinner from "../spinner/Spinner";
import {connect} from "react-redux";
import {logServices} from "../../service/logService";
import {logConversionsActions} from "../../store/actions/logConversionsAction";

function LogConversionsOverrideConversion(props) {

    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const {handleSubmit, register, control, formState: {errors}} = useForm();

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/log/conversions", state: {from: props.location}});
    }, [props]);

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [clickId] = useState(props?.match?.params?.id);
    const [postback, setPostback] = useState(true);

    const onSubmit = (data) => {
        let formData = {
            clickId: clickId,
            payout: parseFloat(data?.payout),
            payoutCurrencyCode: data?.currency?.code,
            transactionId: data?.transactionId,
            allowPostback: postback ? "force" : "false",
            status: data?.status
        };

        async function addConversion(formData) {
            setLoading(true);
            setErrorMsg("");
            await logServices.addConversion(formData).then(() => {
                setLoading(false);
                props.rerender();
                handleClose();
            }).catch((e) => {
                setLoading(false);
                setErrorMsg(e?.response?.data?.error);
            });
        }

        addConversion(formData);
    };

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box width="100%">
            {loading && <Spinner size={32} overComponentBox={true}/>}
            <Dialog open={open}
                    onClose={handleClose}
                    fullWidth
                    fullScreen={fullScreen}
                    aria-labelledby="form-dialog-title"
            >
                <DialogTitle textAlign="center">Override conversion</DialogTitle>
                <DialogContent>
                    {errorMsg !== "" &&
                        <Alert severity={"error"} variant="filled" sx={{mb: 2}}>
                            {errorMsg}
                        </Alert>
                    }
                    <Box sx={{minHeight: "200px"}}>
                        <Box display="flex" flexDirection="column" flexWrap="nowrap" mb={2}>
                            <Box p={1} flexGrow={1} sx={{fontWeight: 500}}>
                                ClickID: <Typography variant="span" sx={{color: "primary.main"}}>{clickId}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="left">
                            <Box width="40%">
                                <TextField
                                    error={!!errors?.payout}
                                    variant="outlined"
                                    margin="dense"
                                    id="payout"
                                    {...register("payout",{
                                        required: true,
                                        pattern: /^(\d+)(?:[.]\d+)?$/
                                    })}
                                    size="small"
                                    name="payout"
                                    label="Payout"
                                    placeholder="e.g. 5.40"
                                    type="text"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box ml={2} width="60%">
                                <CurrencySelect control={control}/>
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="nowrap" mt={1} flexDirection="column">
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box width="40%">
                                    <TextField
                                        error={!!errors?.status}
                                        variant="outlined"
                                        margin="dense"
                                        id="status"
                                        {...register("status",{
                                            maxLength: 255
                                        })}
                                        size="small"
                                        name="status"
                                        label="Status (optional)"
                                        placeholder="e.g. lead"
                                        type="text"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box ml={2} width="60%">
                                    <TextField
                                        error={!!errors?.transactionId}
                                        variant="outlined"
                                        margin="dense"
                                        id="transactionId"
                                        {...register("transactionId",{
                                            maxLength: 255
                                        })}
                                        size="small"
                                        name="transactionId"
                                        label="Transaction ID (optional)"
                                        placeholder="e.g. jnkjfudsi92jk32"
                                        type="text"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={postback}
                                            color="primary"
                                            onChange={() => setPostback(!postback)}
                                        />
                                    }
                                    label="Run postback to traffic source"
                                />
                            </Box>
                        </Box>
                        <Alert severity="info" variant="filled" sx={{marginTop: 3}}>
                            A click ID which already has a conversion will be overwritten.
                        </Alert>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit" variant="outlined">
                        Close
                    </Button>
                    <Button disabled={loading} onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Override
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const actionCreators = {
    rerender: logConversionsActions.rerender
};

export default connect(null, actionCreators)(LogConversionsOverrideConversion);