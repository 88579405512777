import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {GetLocalStorageCache, SetLocalStorageCache} from "../cache/Cache";
import {Box, Button, Popover, Tooltip} from "@mui/material";
import {FilterList} from "@mui/icons-material";
import ReactHookFromSelect from "../input/ReactHookFormSelect";
import ReactHookFormTextField from "../input/ReactHookFormTextField";
import {connect} from "react-redux";
import {metricFilterActions} from "../../store/actions/metricFilterAction";

function ColumnFilter(props) {

    const {columnKey, addMetric, removeMetric} = props;

    const [open, setOpen] = useState(null);
    const [active, setActive] = useState(false);

    const {handleSubmit, control, register, setValue, formState: {errors}} = useForm();

    const handleOnClickToOpen = (target) => setOpen(target);
    const handleOnClose = () => setOpen(null);

    const handleOnSubmit = (metric) => {
        addMetric(metric);
        cacheMetric(metric);
        setActive(true);
        handleOnClose();
    };

    const handleReset = (keyNameOperator, keyNameValue) => {
        setValue(keyNameOperator, "bigger");
        setValue(keyNameValue, "");

        removeMetric(keyNameOperator);
        removeMetricFromCache(keyNameOperator);
        setActive(false);
        handleOnClose();
    };

    const cacheMetric = (metric) => {
        let metricFilters = GetLocalStorageCache("metricFilters");
        if (metricFilters === null) {
            SetLocalStorageCache("metricFilters", [metric]);
        } else {
            for (const key in metric) {
                // Remove current metric
                metricFilters = metricFilters.filter((obj) => !obj.hasOwnProperty(key))
            }
            SetLocalStorageCache("metricFilters", [...metricFilters, metric]);
        }
    };

    const removeMetricFromCache = (keyName) => {
        let metricFilters = GetLocalStorageCache("metricFilters");
        if (metricFilters !== null) {
            metricFilters = metricFilters.filter((obj) => !obj.hasOwnProperty(keyName))
            SetLocalStorageCache("metricFilters", metricFilters);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        props?.states?.metricFilter?.filters?.filter((metric) => {
            if (metric.hasOwnProperty(columnKey + "Operator")) {
                setActive(true);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Popover
                id={columnKey + "column-filter-popup"}
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleOnClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
                sx={{maxWidth: 250}}
            >
                <Box p={2} sx={{backgroundColor: "background.darkBlue"}}>
                    <Box display="flex" flexDirection="row" alignItems="stretch">
                        <Box ml={1}>
                            <ReactHookFromSelect
                                setValue={setValue}
                                filters={props?.states?.metricFilter?.filters}
                                defaultValue="bigger"
                                control={control}
                                name={columnKey + "Operator"}
                                options={[
                                    {value: "equals", label: "="},
                                    {value: "bigger", label: ">"},
                                    {value: "smaller", label: "<"}
                                ]}
                            />
                        </Box>
                        <Box flex={1} p={1}>
                            <ReactHookFormTextField
                                type="number"
                                name={columnKey + "Value"}
                                filters={props?.states?.metricFilter?.filters}
                                control={control}
                                defaultValue={""}
                                placeholder={"0"}
                                setValue={setValue}
                                register={register}
                                errors={errors}
                                rules={{
                                    required: true,
                                    pattern: /^[+-]?\d+(\.\d+)?$/
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="stretch">
                        <Box flex={1} p={1}>
                            <Button size="small" fullWidth variant="contained" color="inherit"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleReset(columnKey + "Operator", columnKey + "Value")
                                    }}>
                                Clear
                            </Button>
                        </Box>
                        <Box flex={1} p={1}>
                            <Button size="small" fullWidth variant="contained" color="primary"
                                    onClick={handleSubmit(handleOnSubmit)}>
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Popover>
            <Tooltip title="Filter">
                <FilterList
                    aria-controls={columnKey + "column-filter-popup"}
                    aria-haspopup="true"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOnClickToOpen(e.currentTarget);
                    }}
                    sx={{
                        fontSize: "15px",
                        "&:hover": {
                            color: "primary.main"
                        },
                        ...(active && {
                            fontSize: "15px",
                            color: "primary.main",
                            "&:hover": {
                                color: "primary.dark"
                            }
                        })
                    }}
                />
            </Tooltip>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        states: {
            metricFilter: state.metricFilter
        }
    }
};

const actionCreators = {
    addMetric: metricFilterActions.addMetric,
    removeMetric: metricFilterActions.removeMetric,
};

export default connect(mapStateToProps, actionCreators)(ColumnFilter);