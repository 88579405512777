import {apiRequest, apiRequestWithFormData} from "../helpers/utils";

export const websiteBucketsServices = {
    read,
    create,
    upload,
    deleteBucket,
    deployWebsite
};

function read(domain, page, orderBy, sortBy, workspace) {
    let params = {
        domain,
        page,
        orderBy,
        sortBy,
        workspace,
        pageSize: 100
    };
    return apiRequest("get", "v1/aws/s3", {}, params);
}

function create(formData) {
    return apiRequest("post", "v1/aws/s3/create", formData);
}

function deployWebsite(formData) {
    return apiRequest("post", "v1/aws/cloudfront/distribution", formData);
}

function upload(formData) {
    return apiRequestWithFormData("post", "v1/aws/s3/upload", formData);
}

function deleteBucket(id) {
    return apiRequest("delete", "v1/aws/s3/" + parseInt(id), {});
}
