import React from 'react';
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {
    Badge,
    Box, Collapse,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip
} from "@mui/material";
import {
    BlurOn,
    CallSplit,
    EmojiEvents,
    Share,
    Star,
    Add,
    Speed,
    DesktopWindows, TouchApp, AdsClick, Flag, MyLocation, ExpandMore, ExpandLess, FindInPage, Sell, Reply, TravelExplore
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {styled} from "@mui/system";

export const CustomListItem = styled(ListItemButton)(({theme}) => {
    return ({
        "&:hover": {
            backgroundColor: theme.palette.background.rowHover
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.primary.light,
            "&:hover": {
                backgroundColor: theme.palette.background.rowHover
            },
        },
    })
});

const CustomListItemIcon = styled(ListItemIcon)(({theme}) => ({
    minWidth: 40,
    paddingLeft: 2,
    color: theme.palette.primary.white,
}));

const AddCampaignIcon = styled(ListItemIcon)(({theme}) => ({
    minWidth: 40,
    paddingLeft: 2,
    color: theme.palette.primary.main
}));

export function MainListItems(props) {

    const location = useLocation();
    const history = useHistory();

    const {auth, sidebar, handleIsLogsOpen} = props;
    const [selected, setSelected] = useState("");

    const toggleLogsNav = () => {
        handleIsLogsOpen(!sidebar?.isLogsOpen);
    };

    useEffect(() => {
        if (location?.pathname) {
            setSelected(location?.pathname);
        }
    }, [location?.pathname]);

    return (
        <Box>
            <CustomListItem
                sx={{paddingTop: "12px", paddingBottom: "12px"}}
                onClick={() => {
                    history?.push({
                        pathname: "/campaigns/new",
                        state: {background: {pathname: "/campaigns"}}
                    });
                }}
            >
                <Tooltip title="New Campaign" placement="right-end">
                    <AddCampaignIcon><Add/></AddCampaignIcon>
                </Tooltip>
                <ListItemText primary="New Campaign" primaryTypographyProps={{color: "primary"}}/>
            </CustomListItem>
            <Divider/>
            <CustomListItem
                component={NavLink}
                to="/dashboard"
                selected={selected === "/dashboard" || selected === "/"}
            >
                <Tooltip title="Dashboard" placement="right-end">
                    <CustomListItemIcon><Speed/></CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Dashboard"/>
            </CustomListItem>
            <CustomListItem
                component={NavLink}
                to="/campaigns"
                selected={selected === "/campaigns"}
            >
                <Tooltip title="Campaigns" placement="right-end">
                    <CustomListItemIcon><MyLocation/></CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Campaigns"/>
            </CustomListItem>
            <CustomListItem
                component={NavLink}
                to="/offers"
                selected={selected === "/offers"}
            >
                <Tooltip title="Offers" placement="right-end">
                    <CustomListItemIcon><Flag/></CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Offers"/>
            </CustomListItem>
            <CustomListItem
                component={NavLink}
                to="/landings"
                selected={selected === "/landings"}
            >
                <Tooltip title="Landings" placement="right-end">
                    <CustomListItemIcon><DesktopWindows/></CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Landings"/>
            </CustomListItem>
            <CustomListItem
                component={NavLink}
                to="/flows"
                selected={selected === "/flows"}
            >
                <Tooltip title="Flows" placement="right-end">
                    <CustomListItemIcon><CallSplit/></CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Flows"/>
            </CustomListItem>
            <CustomListItem
                component={NavLink}
                to="/traffic-sources"
                selected={selected === "/traffic-sources"}
            >
                <Tooltip title="Traffic Sources" placement="right-end">
                    <CustomListItemIcon><AdsClick/></CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Traffic Sources"/>
            </CustomListItem>
            <CustomListItem
                component={NavLink}
                to="/affiliate-networks"
                selected={selected === "/affiliate-networks"}
            >
                <Tooltip title="Affiliate Networks" placement="right-end">
                    <CustomListItemIcon>
                        <BlurOn/>
                    </CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Affiliate Networks"/>
            </CustomListItem>

            <Box>
                <CustomListItem onClick={toggleLogsNav}>
                    <Tooltip title="Logs" placement="right-end">
                        <CustomListItemIcon placement="right-end">
                            <FindInPage/>
                        </CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Logs"/>
                    {sidebar?.isLogsOpen ? <ExpandLess/> : <ExpandMore/>}
                </CustomListItem>

                <Collapse
                    key={"logs"}
                    in={sidebar?.isLogsOpen}
                    timeout='auto'
                    unmountOnExit
                >
                    <CustomListItem
                        component={NavLink}
                        to="/log/clicks"
                        selected={selected === "/log/clicks"}
                    >
                        <Tooltip title="Clicks" placement="right-end">
                            <CustomListItemIcon placement="right-end">
                                <TouchApp/>
                            </CustomListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Clicks"/>
                    </CustomListItem>

                    <CustomListItem
                        component={NavLink}
                        to="/log/conversions"
                        selected={selected === "/log/conversions"}
                    >
                        <Tooltip title="Conversions" placement="right-end">
                            <CustomListItemIcon placement="right-end">
                                <Sell/>
                            </CustomListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Conversions"/>
                    </CustomListItem>

                    <CustomListItem
                        component={NavLink}
                        to="/log/postbacks"
                        selected={selected === "/log/postbacks"}
                    >
                        <Tooltip title="Postbacks" placement="right-end">
                            <CustomListItemIcon placement="right-end">
                                <Reply/>
                            </CustomListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Postbacks"/>
                    </CustomListItem>
                </Collapse>
            </Box>
            <Divider sx={{marginTop: "15px", marginBottom: "15px"}}/>
            {(auth?.userSettings?.role === "owner" || auth?.userSettings?.role === "manager") &&
                <CustomListItem
                    component={NavLink}
                    to="/settings/integrations"
                    selected={selected === "/settings/integrations"}
                >
                    <Tooltip title="Integrations" placement="right-end">
                        <CustomListItemIcon><Badge color="error" variant="dot"><Share/></Badge></CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary={"Integrations"}/>
                </CustomListItem>
            }
            <CustomListItem
                component={NavLink}
                to="/settings/custom-events"
                selected={selected === "/settings/custom-events"}
            >
                <Tooltip title="Custom Conversions" placement="right-end">
                    <CustomListItemIcon><EmojiEvents/></CustomListItemIcon>
                </Tooltip>
                <ListItemText primary="Custom Conversions"/>
            </CustomListItem>
            {(auth?.userSettings?.role === "owner" || auth?.userSettings?.role === "manager") &&
                <CustomListItem
                    selected={selected === "/websites/domains" || selected === "/websites/s3-buckets" || selected === "/websites/deployments"}
                    component={NavLink}
                    to="/websites/domains"
                >
                    <Tooltip title="Websites" placement="right-end">
                        <CustomListItemIcon><Badge color="error"
                                                   variant="dot"><TravelExplore/></Badge></CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary={"Websites"}/>
                </CustomListItem>
            }
            {auth?.userSettings?.role === "owner" &&
                <CustomListItem
                    component={NavLink}
                    to="/settings/referral"
                    selected={selected === "/settings/referral"}
                >
                    <Tooltip title="Refer & Earn" placement="right-end">
                        <CustomListItemIcon><Star sx={{color: "icon.yellow"}}/></CustomListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Refer & Earn"/>
                </CustomListItem>
            }
        </Box>
    );
}
