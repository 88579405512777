import React, {useEffect, useState} from 'react';
import Topbar from "../layout/Topbar";
import Sidebar from "../layout/Sidebar";
import Stats from "./Stats";
import {reportServices} from "../../service/reportService";
import OverviewTable from "./OverviewTable";
import LineChart from "./LineChart";
import {connect} from "react-redux";
import {dashboardActions} from "../../store/actions/dashboardActions";
import {Refresh} from "@mui/icons-material";
import {Box, CssBaseline, IconButton, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import CustomSnackbar from "../alert/CustomSnackbar";
import AirbnbDateRangePicker from "../utils/AirbnbDateRangePicker";
import {workspaceActions} from "../../store/actions/workspaceAction";
import {styled} from "@mui/system";
import {alertActions} from "../../store/actions/alertActions";

export const CustomMainRootBox = styled(Box)(({theme}) => ({
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.white,
    maxWidth: '100%',
}));

export const CustomDataGridBox = styled(Box)(({theme}) => ({
    height: "calc(var(--app-height) - 102px)",
    width: "100%",
    [theme.breakpoints.down(1469)]: {
        height: "calc(var(--app-height) - 112px)",
    }
}));

export const CustomMainContainerBox = styled(Box)(({theme}) => ({
    height: "var(--app-height)",
    width: "100%",
    overflow: "auto",
    borderLeft: "1px solid " + theme.palette.background.tableBorder,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: "58px",
    [theme.breakpoints.down(600)]: {
        paddingTop: "58px",
    }
}));

function Dashboard(props) {
    const theme = useTheme();
    const overMD = useMediaQuery(theme.breakpoints.up("md"));
    const over600 = useMediaQuery(theme.breakpoints.up(600));

    const [campaigns, setCampaign] = useState(null);
    const [offers, setOffers] = useState(null);
    const [total, setTotal] = useState(null);
    const [chartData, setChartData] = useState(null);

    const {from, to, dateLabel} = props.dashboardState;
    const {timezone, currencyCode, loaded} = props.auth.userSettings;

    const getCampaignsReport = () => {
        reportServices.getReport(
            {
                groupBy: "campaign_id", from, to, timezone, page: 1, pageSize: 5, currencyCode,
                columns: ["campaign_name", "metric_revenue", "metric_visits", "metric_clicks", "metric_conversionsAll", "metric_cost", "metric_profit", "metric_roi"],
                workspace: props.getWorkspaceIDs()
            }
        ).then((result) => {
            setCampaign(result?.records);
            setTotal(result?.total);
        }).catch((error) => {
            setCampaign([]);
            props?.errorMessage(error?.response?.data?.error);
        });
    };

    const getOffersReport = () => {
        reportServices.getReport(
            {
                groupBy: "offer_id", from, to, timezone, page: 1, pageSize: 5, currencyCode,
                columns: ["offer_name", "metric_revenue"],
                workspace: props.getWorkspaceIDs()
            }
        ).then((result) => {
            setOffers(result?.records);
        }).catch((error) => {
            setOffers([]);
            props?.errorMessage(error?.response?.data?.error);
        });
    };

    const getChartReport = () => {
        reportServices.getChartData(
            {
                groupBy: "day", from, to, timezone, currencyCode, workspace: props.getWorkspaceIDs()
            }).then((result) => {
            setChartData(result);
        }).catch((error) => {
            setChartData([]);
            props?.errorMessage(error?.response?.data?.error);
        });
    };

    useEffect(() => {
        if (campaigns === null && loaded && props?.workspace?.userWorkspaces !== null) {
            getCampaignsReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaigns, loaded, props?.workspace?.userWorkspaces]);

    useEffect(() => {
        if (offers === null && loaded && props?.workspace?.userWorkspaces !== null) {
            getOffersReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offers, loaded, props?.workspace?.userWorkspaces]);

    useEffect(() => {
        if (props?.workspace?.updated !== null) {
            getCampaignsReport();
            getOffersReport();
            getChartReport();
            props?.resetUpdatedWorkspaceValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.workspace?.updated])

    useEffect(() => {
        if (props?.workspace?.userWorkspaces !== null && chartData === null && loaded && over600) {
            getChartReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone, currencyCode, over600, props, from, to, chartData, loaded]);

    const reRenderAll = () => {
        setOffers(null);
        setCampaign(null);
        setTotal(null);
        setChartData(null);
    };

    return (
        <CustomMainRootBox>
            <CssBaseline/>
            <Topbar/>
            <Sidebar {...props}/>
            <CustomMainContainerBox>
                {props?.alert?.errorMsg !== "" && <CustomSnackbar message={props?.alert?.errorMsg}/>}
                <Box display="flex" flexWrap="wrap" sx={{paddingBottom: 1, paddingRight: 0}}>
                    <Box flexGrow={1}/>
                    <Box>
                        <AirbnbDateRangePicker
                            from={from}
                            to={to}
                            dateLabel={dateLabel}
                            onChangeDate={(from, to, dateLabel) => {
                                props.updateDateRange(from, to, dateLabel);
                                reRenderAll();
                            }}
                        />
                    </Box>
                    <Box>
                        <Tooltip title="Refresh">
                            <IconButton
                                color="inherit"
                                onClick={() => reRenderAll()}
                                aria-label="refresh"
                            >
                                <Refresh color={"inherit"}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Stats stats={total}/>
                <Box
                    sx={{
                        paddingTop: 2,
                        display: "block",
                        [theme.breakpoints.down(600)]: {
                            display: "none"
                        }
                    }}
                    display="flex"
                    flexWrap="nowrap"
                >
                    <Box flexGrow={1}>
                        <LineChart data={chartData}/>
                    </Box>
                </Box>
                <Box display="flex" flexWrap="wrap" m={-1} p={overMD ? 0 : 1} flexDirection="row"
                     justifyContent="space-between">
                    <Box width={overMD ? "50%" : "100%"} p={overMD ? 1 : 0}>
                        <OverviewTable data={campaigns} title="Top Campaigns"/>
                    </Box>
                    <Box width={overMD ? "50%" : "100%"} p={overMD ? 1 : 0}>
                        <OverviewTable data={offers} title="Top Offers"/>
                    </Box>
                </Box>
            </CustomMainContainerBox>
        </CustomMainRootBox>
    );
}

const mapStateToProps = (state) => ({
    dashboardState: state.dashboard,
    auth: state.auth,
    alert: state.alert,
    workspace: state.workspace
});

const mapDispatchToProps = {
    updateDateRange: dashboardActions.updateDateRange,
    getWorkspaceIDs: workspaceActions.getWorkspaceIDs,
    resetUpdatedWorkspaceValue: workspaceActions.resetUpdatedWorkspaceValue,
    errorMessage: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);