import {logClicksConstants} from "../constants/logClicksConstants";
import {alertActions} from "./alertActions";
import {logServices} from "../../service/logService";

export const logClicksActions = {
    getClicks,
    loadMoreClickRows,
    updateClickId,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender
};

function getClicks(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: logClicksConstants.LOG_CLICKS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        logServices.getListOfClicks(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: logClicksConstants.LOG_CLICKS_LOADING, result});
            }).catch((error) => {
                dispatch({type: logClicksConstants.LOG_CLICKS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function loadMoreClickRows(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: logClicksConstants.LOG_CLICKS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        logServices.getListOfClicks(from, to, timezone, clickId, page, pageSize, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: logClicksConstants.LOG_CLICKS_INFINITY_SCROLL, result});
            }).catch((error) => {
                dispatch({type: logClicksConstants.LOG_CLICKS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: logClicksConstants.LOG_CLICKS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: logClicksConstants.LOG_CLICKS_UPDATE_SORT_BY, sortBy});
    };
}

function updateClickId(clickId) {
    return (dispatch) => {
        dispatch({type: logClicksConstants.LOG_CLICKS_UPDATE_CLICK_ID, clickId});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: logClicksConstants.LOG_CLICKS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: logClicksConstants.LOG_CLICKS_RERENDER});
    };
}
