import React, {useEffect} from 'react';
import {Controller} from "react-hook-form";
import {timezones} from "./TimezonesList";
import PropTypes from "prop-types";
import {Autocomplete, TextField} from "@mui/material";

function TimezoneSelect({control, setValue, inputName, selectedValue, defaultValue, updateOnChange, style}) {
    useEffect(() => {
        if (selectedValue === "") {
            let userSettings = localStorage.getItem("user-settings");
            let settings = JSON.parse(userSettings);
            if (userSettings?.timezone !== "") {
                timezones.forEach((timezone) => {
                    if (timezone?.timezone === settings?.timezone) {
                        setTimeout(() => {

                        setValue(inputName, timezone);
                        })
                    }
                });
            }
        } else {
            timezones.forEach((timezone) => {
                if (timezone?.timezone === selectedValue) {
                    setTimeout(() => {
                        setValue(inputName, timezone);
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue, inputName, selectedValue]);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => {
                return (
                    <Autocomplete
                        {...props}
                        id="timezone-select"
                        disableClearable
                        isOptionEqualToValue={(option, value) => {
                            if (value?.timezone === 0) {
                                return true;
                            }
                            return option.timezone === value.timezone
                        }}
                        getOptionLabel={(option) => option?.offset}
                        options={timezones}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                margin="dense"
                                label="Timezone"
                                size="small"
                                sx={style}
                            />
                        )}
                        onChange={(e, data) => {
                            onChange(data);
                            updateOnChange();
                        }}
                    />
                )
            }}
            name={inputName}
            control={control}
            defaultValue={defaultValue}
            onChange={([, data]) => data}
        />
    );
}

TimezoneSelect.propTypes = {
    control: PropTypes.object,
    inputName: PropTypes.string,
    selectedValue: PropTypes.string,
    defaultValue: PropTypes.object,
    updateOnChange: PropTypes.func
};

TimezoneSelect.defaultProps = {
    control: {},
    inputName: "timezone",
    selectedValue: "",
    defaultValue: {timezone: "Etc/UTC", offset: "(GMT+00:00) UTC (no daylight saving)"},
    updateOnChange: () => null,
};

export default TimezoneSelect;