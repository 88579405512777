import {apiRequest} from "../helpers/utils";
import {accessApiUrl} from "../helpers/config";

export const settingsServices = {
    fetchCompanySettings,
    updateCompanySettings,
    updateUserDetails,
    fetchProfileData,
    fetchUserSettings,
    updateUserSettings,
    switchAgency,
    updateBotDetection,
    updateLandingProtection
};

function fetchCompanySettings() {
    return apiRequest("get", "v1/tenant-settings");
}

function updateCompanySettings(formData) {
    return apiRequest("put", "v1/tenant-settings", formData);
}

function updateUserDetails(formData) {
    return apiRequest("put", "v1/settings/user-details", formData, {}, true, true, accessApiUrl);
}

function fetchProfileData() {
    return apiRequest("get", "v1/settings/user-details", {}, {}, true, true, accessApiUrl);
}

function fetchUserSettings() {
    return apiRequest("get", "v1/user-settings");
}

function updateUserSettings(formData) {
    return apiRequest("put", "v1/user-settings", formData);
}

function switchAgency(formData) {
    return apiRequest("post", "v1/settings/switch-agency", formData, {}, true, false, accessApiUrl);
}

function updateBotDetection(formData) {
    return apiRequest("put", "v1/tenant-settings/bot-detection", formData);
}

function updateLandingProtection() {
    return apiRequest("put", "v1/tenant-settings/landing-protection", {});
}
