export const logConversionsConstants = {
    LOG_CONVERSIONS_LOADING: 'LOG_CONVERSIONS_LOADING',
    LOG_CONVERSIONS_LOADING_ERROR: 'LOG_CONVERSIONS_LOADING_ERROR',
    LOG_CONVERSIONS_SET_IS_LOADING: 'LOG_CONVERSIONS_SET_IS_LOADING',
    LOG_CONVERSIONS_UPDATE_ORDER_BY: 'LOG_CONVERSIONS_UPDATE_ORDER_BY',
    LOG_CONVERSIONS_UPDATE_SORT_BY: 'LOG_CONVERSIONS_UPDATE_SORT_BY',
    LOG_CONVERSIONS_UPDATE_CLICK_ID: 'LOG_CONVERSIONS_UPDATE_CLICK_ID',
    LOG_CONVERSIONS_UPDATE_SORT_COLUMNS: 'LOG_CONVERSIONS_UPDATE_SORT_COLUMNS',
    LOG_CONVERSIONS_INFINITY_SCROLL: 'LOG_CONVERSIONS_INFINITY_SCROLL',
    LOG_CONVERSIONS_RERENDER: 'LOG_CONVERSIONS_RERENDER'
};
