import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import {offerServices} from "../../service/offerService";
import {OfferForm} from "./OfferForm";
import {affNetworkServices} from "../../service/affiliateNetworkService";
import {countries, currencies} from "../utils/DataList";

function OfferEditPopUp({onClose, open, offerId}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [offerData, setOfferData] = useState(null);
    const [affiliateNetworkData, setAffiliateNetworkData] = useState(null);
    const [affiliateNetworkId, setAffiliateNetworkId] = useState(0);
    const [requestError, setRequestError] = useState("");
    const [loading, setLoading] = useState(false);

    const {register, handleSubmit, setValue, watch, control, getValues, reset, formState: {errors}} = useForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleOnClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        reset();
        setOfferData(null);
        setRequestError("");
        onClose();
    });

    useEffect(() => {
        async function find(id): void {
            setRequestError("");
            setLoading(true);
            await offerServices.find(id).then((response) => {
                if (response?.id > 0) {
                    setOfferData(response);
                    setAffiliateNetworkId(response?.affiliateNetworkId ? response.affiliateNetworkId : 0);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (open && offerId > 0 && offerId !== undefined && offerId !== "" && offerId !== null) {
            find(offerId);
        }

    }, [open, offerId]);

    useEffect(() => {
        async function findAffiliateNetwork(id): void {
            setRequestError("");
            setLoading(true);
            await affNetworkServices.find(id).then((response) => {
                setAffiliateNetworkData({
                    value: response?.id,
                    label: response?.name,
                    sharedAffiliateNetworkId: response?.sharedAffiliateNetworkId,
                    usePostbackProtection: response?.usePostbackProtection,
                    postbackProtectionToken: response?.postbackProtectionToken
                });
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    setRequestError(e.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (open && affiliateNetworkId !== 0) {
            findAffiliateNetwork(affiliateNetworkId)
        }

    }, [open, affiliateNetworkId]);

    useEffect(() => {
        if (open && offerData !== null) {
            setTimeout(() => {
                for (const [key, value] of Object.entries(offerData)) {
                    if (key === "dailyCapFailoverOfferId") {
                        continue;
                    }
                    if (key === "name") {
                        setValue("name", value.slice(value.indexOf("-") + 2));
                    } else {
                        setValue(key, value);
                    }
                }
            });

            currencies.forEach((item) => {
                if (item.code === offerData.currencyCode) {
                    setValue("currency", item)
                }
            });

            countries.forEach((item) => {
                if (item.code === offerData.countryCode) {
                    setValue("country", item)
                }
            });
        }
    }, [open, offerData, setValue]);

    useEffect(() => {
        if (open && affiliateNetworkData !== null) {
            setValue("affiliateNetwork", affiliateNetworkData);
        }
    }, [open, affiliateNetworkData, setValue]);

    const onSubmit = async (data) => {

        setLoading(true);

        let offerName = "";
        if (data.name) {
            offerName = "Global - " + data.name;
            if (data.country && data.country.label) {
                offerName = data.country.label + " - " + data.name;
            }
        }

        let d = {
            workspaceId: data?.workspace?.value,
            affiliateNetworkId: data.affiliateNetwork && data.affiliateNetwork.value ? data.affiliateNetwork.value : 0,
            clickIdParam: "",
            countryCode: data.country && data.country.code ? data.country.code : "",
            currencyCode: data.currency && data.currency.code ? data.currency.code : "EUR",
            dailyCapFailoverOfferId: data?.dailyCapFailoverOfferId?.value ? data.dailyCapFailoverOfferId.value : 0,
            dailyCapLimit: data?.dailyCapLimit ? data?.dailyCapLimit : "",
            dailyCapTimezone: data?.timezone?.timezone ? data.timezone.timezone : "",
            enableDailyCap: data?.enableDailyCap ? data.enableDailyCap : false,
            name: offerName,
            payoutSum: data.payoutSum ? parseFloat(data.payoutSum) : 0,
            payoutType: data.payoutType ? data.payoutType : "auto",
            url: data.url ? data.url : "",
            tags: data?.tags ? data?.tags : []
        };

        await offerServices.update(offerId, d).then((response) => {
            if (response?.id > 0) {
                setLoading(false);
            } else {
                setLoading(false);
            }
            setOfferData(null);
            onClose(d?.name);
        }).catch((e) => {
            if (e?.response?.data?.error) {
                setRequestError(e.response.data.error);
            }
            setLoading(false);
        });
    };

    return (
        <Dialog open={open}
                onClose={handleOnClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="offer-form"
                maxWidth={"md"}
        >
            <OfferForm
                title="Update offer"
                watch={watch}
                control={control}
                register={register}
                handleClose={handleOnClose}
                requestError={requestError}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                setValue={setValue}
                offerData={offerData}
                getValues={getValues}
            />
        </Dialog>
    );
}

OfferEditPopUp.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    offerId: PropTypes.number.isRequired
};

export default OfferEditPopUp;