import React from 'react';
import Spinner from "../spinner/Spinner";
import {currencies} from "../utils/DataList";
import {addCurrency} from "../utils/Filters";
import {Box, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, useTheme} from "@mui/material";

export default function OverviewTable({data, title}) {
    const theme = useTheme();
    const dataLength = data?.length;

    // update currency symbol
    let currencySymbol = "€";
    if (localStorage.getItem("user-settings") !== null) {
        let userSettings = JSON.parse(localStorage.getItem("user-settings"));
        if (userSettings?.currencyCode !== "") {
            currencies.forEach((item) => {
                if (item?.code === userSettings?.currencyCode) {
                    currencySymbol = item?.symbol;
                }
            });
        }
    }

    return (
        <Box pt={2} width="100%">
            <Paper sx={{minHeight: "275px", minWidth: "250px", backgroundColor: "background.darkBlue"}} elevation={3}>
                <Box display="flex" flexWrap="nowrap" sx={{
                    padding: "12px 8px",
                    color: "rgb(138, 147, 159)",
                    [theme.breakpoints.down(600)]: {
                        fontSize: "16px"
                    },
                }}>
                    <Box p={1} flexGrow={1}>{title}</Box>
                    <Box p={1}>Revenue ({currencySymbol})</Box>
                </Box>
                <Divider/>
                {dataLength === 0 ?
                    <Box p={1} textAlign="center">Nothing to display</Box>
                    :
                    <List disablePadding>
                        {
                            data === null ?
                                <Box display="flex" justifyContent="center" m={1} p={1} alignItems="center">
                                    <Spinner/>
                                </Box>
                                :
                                data.map((item, index) => {
                                    return (
                                        <ListItem
                                            key={item?.id}
                                            disableGutters={false}
                                            divider={dataLength !== index + 1}
                                        >
                                            <ListItemText
                                                id={item?.id} primary={item?.name}
                                                disableTypography
                                                sx={{
                                                    [theme.breakpoints.down(600)]: {
                                                        fontSize: "15px",
                                                        paddingRight: 6
                                                    }
                                                }}
                                            />
                                            <ListItemSecondaryAction>
                                                <Box
                                                    sx={{
                                                        [theme.breakpoints.down(600)]: {
                                                            fontSize: "15px"
                                                        }
                                                    }}
                                                >
                                                    {item?.revenue ? addCurrency(currencySymbol, item.revenue, 2) : currencySymbol + " 0.00"}
                                                </Box>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })
                        }
                    </List>
                }
            </Paper>
        </Box>
    );
}
