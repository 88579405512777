import {websiteDeploymentsConstants} from "../constants/websiteDeploymentsConstants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        recordsReturned: 0,
        totalRecords: 0
    },
    errorMsg: "",
    isLoading: false,
    deployment: "",
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false,
};

const websiteDeploymentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_SET_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    recordsReturned: action?.result?.recordsReturned,
                    totalRecords: action?.result?.totalRecords
                },
                errorMsg: '',
                isLoading: false
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_UPDATE_DEPLOYMENT_STATUS:
            return {
                ...state,
                newDeployment: action?.status,
                isLoading: false
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_DELETE_DEPLOYMENT:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: state?.data?.records?.map((item) => {
                        if (item?.id === action?.result?.id) {
                            return {
                                ...item,
                                distributionStatus: action?.result?.distributionStatus
                            }
                        } else {
                            return item;
                        }
                    }),
                },
                errorMsg: '',
                isLoading: false
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_LOADING:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    recordsReturned: action?.result?.recordsReturned,
                    totalRecords: action?.result?.totalRecords
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_CHECK_STATUS:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: state?.data?.records?.map((item) => {
                        if (item?.id === action?.result?.id) {
                            return {
                                ...item,
                                distributionDomain: action?.result?.distributionDomain,
                                distributionStatus: action?.result?.distributionStatus
                            }
                        } else {
                            return item;
                        }
                    }),
                },
                errorMsg: '',
                isLoading: false
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_LOADING_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load deployments.',
                isLoading: false,
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case websiteDeploymentsConstants.WEBSITE_DEPLOYMENTS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        default:
            return state;
    }
};

export default websiteDeploymentsReducer;