import React, {createRef, useCallback, useEffect, useState} from 'react';
import Topbar from "../layout/Topbar";
import Sidebar from "../layout/Sidebar";
import {connect} from "react-redux";
import {compose} from "redux";
import DataGrid, {SelectColumn} from 'react-data-grid';
import Spinner from "../spinner/Spinner";
import CustomSnackbar from "../alert/CustomSnackbar";
import {debounce} from "lodash";
import {
    CurrencyFormatter,
    EmptyRowsRenderer,
    IsAtBottom
} from "../reporting-table/ReactDataGridUtils";
import {getCurrencySymbol} from "../utils/Filters";
import {workspaceActions} from "../../store/actions/workspaceAction";
import {Box, CssBaseline, Divider, useTheme} from "@mui/material";
import {CustomMainRootBox} from "../dashboard/Dashboard";
import 'react-data-grid/lib/styles.css';
import useCustomStyles from "../utils/UseCustomStyles";
import {styled} from "@mui/system";
import {logClicksActions} from "../../store/actions/logClicksAction";
import LogClicksToolbar from "./LogClicksToolbar";

const customStyles = (theme) => ({
    dataGrid: theme.clicksReportDataGrid
});

export const CustomTableContainer = styled(Box)(({theme}) => ({
    height: "var(--app-height)",
    width: "100%",
    overflow: "auto",
    borderLeft: "1px solid " + theme.palette.background.tableBorder,
    paddingTop: "48px",
    [theme.breakpoints.down(600)]: {
        paddingTop: "48px",
    }
}));

function getColumns() {
    let cachedColumns = JSON.parse(localStorage.getItem("log-clicks-columns"));
    let currencySymbol = getCurrencySymbol();
    let columns = [
        SelectColumn,
        {key: 'clickId', name: 'Click ID', hidden: false, width: 200, frozen: true, sortable: true, resizable: true},
        {
            key: 'externalId',
            name: 'External ID',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'campaignName',
            name: 'Campaign Name',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'landingName',
            name: 'Landing Name',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'offerName',
            name: 'Offer Name',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'clickedAt',
            name: 'Click Time',
            hidden: false,
            width: 180,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'cost', name: 'Cost', hidden: false, width: 150, sortable: true, draggable: true, resizable: true,
            renderCell({row}) {
                return CurrencyFormatter(row?.cost, currencySymbol, 6);
            }
        },
        {
            key: 'deviceVendor',
            name: 'Device Vendor',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'deviceModel',
            name: 'Device Model',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'deviceType',
            name: 'Device Type',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {key: 'os', name: 'OS', hidden: false, width: 150, sortable: true, draggable: true, resizable: true},
        {
            key: 'osVersion',
            name: 'OS Version',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {key: 'browser', name: 'Browser', hidden: false, width: 150, sortable: true, draggable: true, resizable: true},
        {
            key: 'browserVersion',
            name: 'Browser Version',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {key: 'isp', name: 'ISP', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'country', name: 'Country', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'state', name: 'State', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'city', name: 'City', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'language', name: 'Language', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'ip', name: 'IP', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'referrerDomain', name: 'Referrer Domain', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom1', name: 'Custom 1', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom2', name: 'Custom 2', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom3', name: 'Custom 3', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom4', name: 'Custom 4', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom5', name: 'Custom 5', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom6', name: 'Custom 6', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom7', name: 'Custom 7', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom8', name: 'Custom 8', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom9', name: 'Custom 9', hidden: false, width: 150, sortable: true, draggable: true},
        {key: 'custom10', name: 'Custom 10', hidden: false, width: 150, sortable: true, draggable: true},
    ];

    if (cachedColumns !== null && cachedColumns?.length !== columns.length) {
        return columns;
    }

    if (cachedColumns !== null && cachedColumns !== "" && cachedColumns !== undefined) {
        let sortedColumns = [];
        cachedColumns?.forEach(cachedCol => {
            columns?.forEach(defCol => {
                if (cachedCol?.key === defCol?.key) {
                    defCol.width = cachedCol.width;
                    sortedColumns.push(defCol);
                }
            });
        });
        return sortedColumns;
    }
    localStorage.setItem("log-clicks-columns", JSON.stringify(columns));
    return columns;
}

function LogClicksTable(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const {
        alert, getClicksReport, updateSortBy, updateOrderBy, updateSortColumns, loadMoreRows, history,
        location, getWorkspaceIDs, resetUpdatedWorkspaceValue
    } = props;
    const {data, isLoading, clickId, orderBy, sortBy, sortColumns, rerender} = props?.logClicksState;
    const {from, to} = props?.ddToolbarState;
    const {timezone, currencyCode} = props?.auth?.userSettings;

    const gridRef = createRef(null);

    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [columns, setColumns] = useState(getColumns());

    const onColumnsReorder = (sourceKey, targetKey) => {
        const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
        const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
        const reorderedColumns = [...columns];
        reorderedColumns.splice(targetColumnIndex, 0, reorderedColumns.splice(sourceColumnIndex, 1)[0]);
        localStorage.setItem("log-clicks-columns", JSON.stringify(reorderedColumns));
        setColumns(reorderedColumns);
    };

    const handleInfinityScroll = (event) => {
        if (isLoading || !IsAtBottom(event)) {
            return;
        }

        let disable = data.page >= Math.ceil(data?.totalRecords / data?.recordsPerPage);
        if (!disable) {
            loadMoreRows(from, to, timezone, clickId, data.page + 1, 100, orderBy, sortBy, getWorkspaceIDs());
        }
    };

    const handleGetClicksReport = () => {
        gridRef?.current?.element?.scrollTo(0, 0);
        getClicksReport(from, to, timezone, clickId, 1, 100, orderBy, sortBy, getWorkspaceIDs());
    };

    const handleRefresh = () => {
        gridRef?.current?.element?.scrollTo(0, 0);
        handleGetClicksReport();
    };

    const cacheColumnResize = (idx, width) => {
        let cachedColumns = JSON.parse(localStorage.getItem("log-clicks-columns"));
        let modColumns = [];
        cachedColumns?.forEach((item, index) => {
            if (index === idx) {
                item.width = width;
            }
            modColumns.push(item);
        });
        localStorage.setItem("log-clicks-columns", JSON.stringify(modColumns));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleColumnResize = useCallback(debounce(cacheColumnResize, 50), []);

    useEffect(() => {
        handleGetClicksReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getClicksReport, from, to, timezone, clickId, sortBy, orderBy, currencyCode]);

    // Only for rerender
    useEffect(() => {
        if (rerender) {
            handleGetClicksReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerender]);

    useEffect(() => {
        if (sortColumns.length === 0) {
            updateSortBy("");
            updateOrderBy("");
            return () => {
            }
        }

        const {columnKey, direction} = sortColumns[0];
        updateOrderBy(columnKey);
        updateSortBy(direction);

    }, [updateOrderBy, updateSortBy, sortColumns]);

    // 6. Rerender if user workspaces has changed.
    useEffect(() => {
        if (props?.workspace?.updated !== null) {
            handleGetClicksReport();
            resetUpdatedWorkspaceValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.workspace?.updated])

    return (
        <CustomMainRootBox>
            <CssBaseline/>
            <Sidebar {...props}/>
            <Topbar/>
            <CustomTableContainer>
                <LogClicksToolbar
                    rows={data?.records ? data.records : []}
                    onClickRefresh={handleRefresh}
                    searchTerm={clickId}
                    onChangeSearch={(searchTerm) => {
                        props.updateClickId(searchTerm);
                    }}
                    selectedRows={selectedRows}
                    history={history}
                    location={location}
                />
                <Divider/>
                <Box sx={{height: "calc(var(--app-height) - 114px)", width: "100%"}}>

                    {alert.errorMsg !== "" && <CustomSnackbar message={alert.errorMsg}/>}
                    {isLoading && <Spinner overComponentBox={true}/>}

                    {(columns !== null && classes !== null) &&
                        <DataGrid
                            ref={gridRef}
                            rowKeyGetter={(row) => row.uniqueId}
                            columns={columns}
                            onColumnsReorder={onColumnsReorder}
                            rows={data?.records ? data.records : []}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                                draggable: true
                            }}
                            onColumnResize={(idx, width) => handleColumnResize(idx, width)}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            onSelectedCellChange={({rowIdx, row, column}) => {
                                if (rowIdx !== -1 && column?.key !== "select-row") {
                                    setSelectedRows(new Set().add(row.uniqueId));
                                }
                            }}
                            sortColumns={sortColumns}
                            onSortColumnsChange={updateSortColumns}
                            onScroll={handleInfinityScroll}
                            noRowsFallback={<EmptyRowsRenderer/>}
                            enableVirtualization={true}
                            className={classes?.dataGrid}
                        />
                    }
                </Box>
            </CustomTableContainer>
        </CustomMainRootBox>
    );
}

const mapStateToProps = (state) => {
    return {
        logClicksState: state.logClicks,
        ddToolbarState: state.ddToolbar,
        auth: state.auth,
        alert: state.alert,
        workspace: state.workspace
    }
};

const actionCreators = {
    getClicksReport: logClicksActions.getClicks,
    loadMoreRows: logClicksActions.loadMoreClickRows,
    updateOrderBy: logClicksActions.updateOrderBy,
    updateSortBy: logClicksActions.updateSortBy,
    updateClickId: logClicksActions.updateClickId,
    updateSortColumns: logClicksActions.updateSortColumns,
    getWorkspaceIDs: workspaceActions.getWorkspaceIDs,
    resetUpdatedWorkspaceValue: workspaceActions.resetUpdatedWorkspaceValue
};

export default compose(connect(mapStateToProps, actionCreators))(LogClicksTable);