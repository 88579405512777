export const authConstants = {
    VERIFY_EMAIL_LOGIN_SUCCESS: 'VERIFY_EMAIL_LOGIN_SUCCESS',
    VERIFY_EMAIL_LOGIN_ERROR: 'VERIFY_EMAIL_LOGIN_ERROR',
    UPDATE_AUTHENTICATION_STATUS: 'UPDATE_AUTHENTICATION_STATUS',
    UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
    UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
    LOGOUT: 'LOGOUT',
    USER_SETTINGS_UPDATE_TIMEZONE: 'USER_SETTINGS_UPDATE_TIMEZONE',
    VERIFY_GOOGLE_LOGIN_ERROR: 'VERIFY_GOOGLE_LOGIN_ERROR'
};
