import {alertActions} from "./alertActions";
import {columnServices} from "../../service/columnService";
import {columnConstants} from "../constants/columnConstants";

export const columnActions = {
    getReportColumns,
    updateColumnWidth,
    updateColumnVisibility,
    updateColumnSequence,
    rerender,
    setColumns,
    updateUserColumnSettings,
    createCustomColumn,
    setCampaignSelectedColumns,
    deleteCustomMetricColumn
}

function getReportColumns() {
    return dispatch => {
        dispatch(alertActions.error(""));
        columnServices.fetch().then((result) => {
            dispatch({type: columnConstants.COLUMN_FETCH_DATA, result});
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function updateColumnWidth({columnKey, width, columnGroup, report}) {
    return (dispatch, getState) => {
        let listOfColumns = getState().column?.data[columnGroup];
        let columnID = 0;
        for (const col of listOfColumns) {
            if (col?.columnKey === columnKey) {
                columnID = col?.id;
                if (width === undefined) {
                    width = col?.width;
                }
            }
        }

        dispatch(alertActions.error(""));
        columnServices.updateColumnWidth({columnID, width, report}).then(() => {
            dispatch({
                type: columnConstants.COLUMN_UPDATE_COLUMN_WIDTH,
                payload: {columnID, width, columnGroup}
            });
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function updateUserColumnSettings({report, settings}) {
    return (dispatch) => {
        dispatch(alertActions.error(""));
        columnServices.updateUserColumnSettings({report, settings}).then((result) => {
            dispatch({type: columnConstants.COLUMN_FETCH_DATA, result});
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function createCustomColumn(data) {
    return (dispatch) => {
        dispatch(alertActions.error(""));
        columnServices.createCustomColumn(data).then((result) => {
            dispatch({type: columnConstants.COLUMN_ADD_CUSTOM_COLUMN, payload: {data: result}});
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function updateColumnVisibility({columnID, hidden, columnGroup}) {
    return dispatch => {
        dispatch(alertActions.error(""));
        dispatch({
            type: columnConstants.COLUMN_UPDATE_COLUMN_VISIBILITY,
            payload: {columnID, hidden, columnGroup}
        });
    };
}

function updateColumnSequence({sourceColumnKey, targetColumnKey, columnGroup, report}) {

    return (dispatch, getState) => {
        let sourceColumnID, sourceColumnSequence, targetColumnID, targetColumnSequence;
        let listOfColumns = getState().column?.data[columnGroup];

        for (const col of listOfColumns) {
            if (col?.columnKey === sourceColumnKey) {
                sourceColumnID = col?.id;
                sourceColumnSequence = col?.sequence;
            }
            if (col?.columnKey === targetColumnKey) {
                targetColumnID = col?.id;
                targetColumnSequence = col?.sequence;
            }
        }

        dispatch(alertActions.error(""));
        columnServices.updateColumnSequence({
            sourceColumnID, sourceColumnSequence, targetColumnID, targetColumnSequence, report
        }).then(() => {
            dispatch({
                type: columnConstants.COLUMN_UPDATE_COLUMN_SEQUENCE,
                payload: {sourceColumnID, sourceColumnSequence, targetColumnID, targetColumnSequence, columnGroup}
            });
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}

function setCampaignSelectedColumns(result) {
    return dispatch => dispatch({type: columnConstants.COLUMN_UPDATE_CAMPAIGN_SELECTED_COLUMNS, result});
}

function setColumns(result) {
    return dispatch => dispatch({type: columnConstants.COLUMN_FETCH_DATA, result});
}

function rerender() {
    return dispatch => dispatch({type: columnConstants.COLUMN_RERENDER});
}

function deleteCustomMetricColumn({columnId}) {
    return dispatch => {
        dispatch(alertActions.error(""));
        columnServices.deleteCustomMetricColumn({id: columnId}).then(() => {
            dispatch({
                type: columnConstants.COLUMN_DELETE_CUSTOM_METRIC_COLUMN,
                payload: {columnId}
            });
        }).catch((error) => {
            dispatch(alertActions.error(error?.response?.data?.error));
        });
    };
}