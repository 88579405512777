import {alertActions} from "./alertActions";
import {websiteBucketsConstants} from "../constants/websiteBucketsConstants";
import {websiteBucketsServices} from "../../service/websiteBucketsService";

export const websiteBucketsActions = {
    read,
    readMoreRows,
    updateBucket,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    createBucket,
    deleteBucket,
    uploadFiles,
    updateUploadedFilesStatus,
    deployWebsite,
    updateDeploymentStatus
};

function read(bucket, page, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteBucketsServices.read(bucket, page, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_LOADING, result});
            }).catch((error) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function readMoreRows(bucket, page, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteBucketsServices.read(bucket, page, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_INFINITY_SCROLL, result});
            }).catch((error) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function createBucket(
    {
        name = "",
        region = "",
        integration = {value: 0, label: ""},
        workspace = {value: 0, label: ""}
    }
) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteBucketsServices.create({
            name,
            region,
            integrationId: integration?.value,
            workspaceId: workspace?.value
        })
            .then((result) => {
                dispatch({
                    type: websiteBucketsConstants.WEBSITE_BUCKETS_CREATE_BUCKET,
                    payload: {result, workspace, integration}
                });
            }).catch((error) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function deployWebsite({websiteId = 0, domainId = 0, domainIntegrationId = 0}) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteBucketsServices.deployWebsite({websiteId, domainId, domainIntegrationId})
            .then((result) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_DEPLOY_WEBSITE, result});
            }).catch((error) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function uploadFiles(formData) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteBucketsServices.upload(formData)
            .then((result) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_UPLOAD_FILES, result});
            }).catch((error) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function deleteBucket(id) {
    return (dispatch) => {

        if (id === 0) {
            dispatch(alertActions.error("Invalid bucket ID"));
            return false;
        }

        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteBucketsServices.deleteBucket(id)
            .then((result) => {
                dispatch(alertActions.success(result?.bucketName + " has been deleted successfully."));
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_DELETE_BUCKET, result});
            }).catch((error) => {
                dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_SORT_BY, sortBy});
    };
}

function updateBucket(bucket) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_BUCKET, bucket});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function updateUploadedFilesStatus(status = false) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_UPLOADED_FILES_STATUS, status});
    };
}

function updateDeploymentStatus(status = false) {
    return (dispatch) => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_DEPLOYMENT_STATUS, status});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: websiteBucketsConstants.WEBSITE_BUCKETS_RERENDER});
    };
}
