import {websiteBucketsConstants} from "../constants/websiteBucketsConstants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        recordsReturned: 0,
        totalRecords: 0
    },
    errorMsg: "",
    isLoading: false,
    bucket: "",
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false,
    newBucketAdded: false,
    newBucketId: 0,
    filesUploaded: false,
    newDeployment: false
};

const websiteBucketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case websiteBucketsConstants.WEBSITE_BUCKETS_SET_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    recordsReturned: action?.result?.recordsReturned,
                    totalRecords: action?.result?.totalRecords
                },
                errorMsg: '',
                isLoading: false
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_CREATE_BUCKET:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: [
                        ...state.data.records,
                        {
                            id: action?.payload?.result?.id,
                            name: action?.payload?.result?.name,
                            bucketName: action?.payload?.result?.bucketName,
                            region: action?.payload?.result?.region,
                            workspace: action?.payload?.workspace?.label,
                            integrationId: action?.payload?.integration?.value,
                            integration: action?.payload?.integration?.label,
                            websiteUrl: "http://" + action?.payload?.result?.bucketName + ".s3-website." + action?.payload?.result?.region + ".amazonaws.com",
                            createdAt: action?.payload?.result?.createdAt,
                        }
                    ],
                },
                newBucketAdded: true,
                newBucketId: action?.payload?.result?.id,
                errorMsg: '',
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_DEPLOY_WEBSITE:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: state?.data?.records?.map((item) => {
                        if (item?.id === action?.result?.id) {
                            return {
                                ...item,
                                deploymentStatus: action?.result?.status
                            }
                        } else {
                            return item;
                        }
                    }),
                },
                newDeployment: true,
                errorMsg: '',
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_DEPLOYMENT_STATUS:
            return {
                ...state,
                newDeployment: action?.status,
                isLoading: false
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_DELETE_BUCKET:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: state?.data?.records?.filter((item) => item?.id !== action?.result?.id),
                },
                errorMsg: '',
                isLoading: false
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_UPLOAD_FILES:
            return {
                ...state,
                errorMsg: '',
                isLoading: false,
                newBucketAdded: false,
                newBucketId: 0,
                filesUploaded: true,
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_UPLOADED_FILES_STATUS:
            return {
                ...state,
                filesUploaded: action?.status
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_LOADING:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    recordsReturned: action?.result?.recordsReturned,
                    totalRecords: action?.result?.totalRecords
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_LOADING_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load S3 Buckets.',
                isLoading: false,
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_BUCKET:
            return {
                ...state,
                bucket: action.bucket
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case websiteBucketsConstants.WEBSITE_BUCKETS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        default:
            return state;
    }
};

export default websiteBucketsReducer;