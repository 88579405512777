import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {alertActions} from "../../store/actions/alertActions";
import {Alert, Box, Snackbar} from "@mui/material";

function CustomSnackbar({message, clear, severity = "error", timeout}) {
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        clear();
        setOpen(false);
    };

    useEffect(() => {
        if (timeout !== undefined) {
            setTimeout(() => {
                clear();
            }, timeout);
        }
    }, [clear, timeout])

    return (
        <Box sx={{
            width: '100%',
            '& > * + *': {
                marginTop: 2,
            },
        }}>
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                sx={{zIndex: 9999}}
            >
                <Alert onClose={handleClose} variant="filled" severity={severity === "" ? "error" : severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

const mapDispatchToProps = {
    clear: alertActions.clear
};

export default connect(null, mapDispatchToProps)(CustomSnackbar);
