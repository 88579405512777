import {apiRequest} from "../helpers/utils";

export const sharedTrafficSourceService = {
    read,
    find
};

function read(name) {
    return apiRequest("get", "v1/shared-traffic-source", {}, {name, isDeleted: false, pageSize: 500});
}

function find(id) {
    return apiRequest("get", "v1/shared-traffic-source/" + parseInt(id));
}
