import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Box, Button, Popover, Tooltip, IconButton} from "@mui/material";
import ReactHookFormTextField from "../input/ReactHookFormTextField";
import {Edit} from "@mui/icons-material";

function ChangeDailyBudgetForm({row, handleChangeDailyBudget}) {

    const [open, setOpen] = useState(null);

    const {handleSubmit, register, control, setValue, formState: {errors}} = useForm();

    const handleOnClickToOpen = (target) => setOpen(target);
    const handleOnClose = () => setOpen(null);

    const handleOnSubmit = (data) => {
        if (data?.dailyBudget === "" || data?.dailyBudget === undefined || parseFloat(data?.dailyBudget) === 0) {
            return false;
        }
        handleChangeDailyBudget(data?.dailyBudget);
        handleOnClose();
    };

    const handlePopoverDoubleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    return (
        <Box onDoubleClick={handlePopoverDoubleClick}>
            <Popover
                id={"change-daily-budget-popup"}
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleOnClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Box sx={{padding: "16px", backgroundColor: "background.darkBlue", zIndex: 9999}}>
                    <Box display="flex" flexDirection="row" alignItems="stretch">
                        <Box flex={1} p={1}>
                            <ReactHookFormTextField
                                register={register}
                                setValue={setValue}
                                type="text"
                                name={"dailyBudget"}
                                control={control}
                                defaultValue={row?.fbDailyBudget}
                                placeholder={"10.00"}
                                errors={errors}
                                rules={{
                                    required: true,
                                    pattern: /^\d+(\.\d+)?$/
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="stretch">
                        <Box flex={1} p={1}>
                            <Button size="small" fullWidth variant="contained" color="inherit"
                                    onClick={() => handleOnClose()}>
                                Cancel
                            </Button>
                        </Box>
                        <Box flex={1} p={1}>
                            <Button size="small" fullWidth variant="contained" color="primary"
                                    onClick={handleSubmit(handleOnSubmit)}>
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Popover>
            <Tooltip title="Change daily budget">
                <span>
                    <IconButton
                        aria-controls={"change-daily-budget-popup"} aria-haspopup="true"
                        color="inherit"
                        onClick={(e) => handleOnClickToOpen(e?.currentTarget)}
                        aria-label="Change daily budget"
                        size="small"
                    >
                        <Edit fontSize={"small"} sx={{fontSize: "14px"}}/>
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    );
}

export default ChangeDailyBudgetForm;