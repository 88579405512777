import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import {offerServices} from "../../service/offerService";
import {Autocomplete, TextField} from "@mui/material";

export default function OfferSelect(
    {control, errors, updateOfferInPath, inputValue, inputName, pathIndex, offerIndex, setValue, countryCode}
) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(countryCode): void {
            try {
                setLoading(true);
                const response = await offerServices.read(search, countryCode, true);
                setOptions(
                    response?.offers?.map(item => {
                        return {
                            value: item.id,
                            label: item.name
                        }
                    })
                );
            } catch (e) {
                console.log({e});
            } finally {
                setLoading(false);
            }
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData(countryCode);
        }

    }, [search, open, countryCode]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    // set new values
    useEffect(() => {
        setValue(inputName, inputValue);
    }, [control, inputName, inputValue, setValue]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => {
                        if (value?.value === 0) {
                            return true;
                        }
                        return option.value === value.value
                    }}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    loading={loading}
                    renderOption={(props, option, {selected, index}) => (
                        <li {...props} key={index}>{option.label}</li>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={!!errors?.defaultOffers?.paths[pathIndex]?.offers[offerIndex]?.offerId}
                            placeholder="Select an offer"
                            variant="outlined"
                            margin="dense"
                            size="small"
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                                sx: {
                                    "input": {
                                        fontSize: "14px",
                                        height: "10px"
                                    }
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(e, data) => {
                        onChange(data);
                        if (data !== null) {
                            updateOfferInPath(data?.value, data?.label)
                        } else {
                            updateOfferInPath(0, "")
                        }
                    }}
                    {...props}
                />
            )}
            rules={{required: true}}
            name={inputName}
            control={control}
            defaultValue={null}
        />
    );
}
