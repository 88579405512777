import React, {useState} from "react";
import {useStripe, useElements, CardElement} from "@stripe/react-stripe-js";
import {subscriptionServices} from "../../../service/subscriptionService";
import {Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme, Alert, Button} from "@mui/material";
import Spinner from "../../spinner/Spinner";

function UpdatePaymentMethod(props) {
    const {onClose, open, reloadPaymentMethod} = props;

    const stripe = useStripe();
    const elements = useElements();

    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        setErrorMsg("");

        if (!stripe || !elements) {
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (error) {
            setLoading(false);
            setErrorMsg(error?.message);
        } else {
            await subscriptionServices.updatePaymentMethod(paymentMethod?.id).then(() => {
                reloadPaymentMethod();
                handleClose();
            }).catch((error) => {
                setErrorMsg(error?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setErrorMsg("");
        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="update-payment-method"
        >
            <DialogTitle sx={{textAlign: "center", fontSize: 18, fontWeight: 600}}>
                Update payment method
            </DialogTitle>

            {loading && <Spinner size={32} overComponentBox={true}/>}

            <DialogContent>
                <form onSubmit={handleSubmit}>

                    {errorMsg !== "" &&
                        <Alert sx={{mb: 1}} variant="filled" severity="error">{errorMsg}</Alert>
                    }

                    <CardElement
                        options={{
                            style: {
                                base: {
                                    iconColor: '#fff',
                                    fontSize: '16px',
                                    color: '#fff',
                                    '::placeholder': {
                                        color: '#fff',
                                        fontSize: "14px"
                                    },
                                    '::selection': {
                                        backgroundColor: "blue"
                                    }
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                    <div style={{textAlign: "center", fontSize: 12, color: "#666"}}>
                        <a href="https://www.stripe.com" target="_blank" rel="noreferrer">
                            <img src="/assets/images/stripe.png" alt="Powered by Stripe" style={{width: 120}}/></a>
                    </div>
                </form>
            </DialogContent>
            <DialogActions style={{padding: 15}}>
                <Button type="button" color="inherit" variant="text" onClick={handleClose}>
                    Cancel
                </Button>
                <Button type="submit" disabled={!stripe} color="primary" variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UpdatePaymentMethod;