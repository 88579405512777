import Topbar from "../../layout/Topbar";
import SettingsSidebar from "../SettingsSidebar";
import React, {useEffect, useState} from "react";
import {
    CssBaseline,
    Box,
    Button, Chip,
    Divider, FormControlLabel,
    List,
    ListItem,
    Switch,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ListItemIcon,
    ListItemText,
    IconButton,
    Tooltip, useTheme, Typography,
} from "@mui/material";
import CustomSnackbar from "../../alert/CustomSnackbar";
import {connect} from "react-redux";
import {integrationActions} from "../../../store/actions/integrationAction";
import {Delete, Edit, RefreshOutlined, Check} from "@mui/icons-material";
import Spinner from "../../spinner/Spinner";
import AlertDialog from "../../alert/AlertDialog";
import moment from "moment";
import AffiliateNetworkNoIntegrationsView from "./affiliate-network/AffiliateNetworkNoIntegrationsView";
import AffiliateNetworkIntegrations from "./affiliate-network/AffiliateNetworkIntegrations";
import {alertActions} from "../../../store/actions/alertActions";
import {SettingsContentBox, SettingsRoot} from "../General";
import {SettingsGeneralHeader, SettingsGeneralPaper} from "../ProfileSettings";
import RsocNoIntegrationsView from "./rsoc/RsocNoIntegrationsView";
import RsocIntegrations from "./rsoc/RsocIntegrations";
import ServerNoIntegrationsView from "./server/ServerNoIntegrationsView";
import ServerIntegrations from "./server/ServerIntegrations";

function Integrations(props) {
    const {
        alert,
        integration,
        getTrafficSourceIntegrations,
        getAffiliateNetworkIntegrations,
        getRsocIntegrations,
        deleteIntegration,
        enableDataTransfer,
        getRenewableFacebookLoginLink,
        getRenewableTikTokLoginLink,
        getRenewableGoogleLoginLink,
        getRenewableMicrosoftLoginLink,
        clear,
        getServerIntegrations
    } = props;

    /* Force to open modal with parent route on the background */
    useEffect(() => {
        if (props.location.pathname !== props.match.path) {
            props.history.push({
                pathname: props.location.pathname,
                state: {background: props.location}
            });
        }
    }, [props.location, props.history, props.match.path])

    useEffect(() => {
        if (integration?.integrations === null) {
            getTrafficSourceIntegrations();
        }
        if (integration?.affiliateNetworkIntegrations === null) {
            getAffiliateNetworkIntegrations();
        }
        if (integration?.rsocIntegrations === null) {
            getRsocIntegrations();
        }
        if (integration?.serverIntegrations === null) {
            getServerIntegrations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integration?.affiliateNetworkIntegrations, integration?.integrations])

    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                    {integration?.loading?.integrations &&
                        <div style={{textAlign: "center"}}>
                            <Spinner/>
                        </div>
                    }

                    {integration?.integrations?.length === 0 && integration?.loading?.integrations === false &&
                        <NoIntegrationsView props={props}/>}

                    {integration?.integrations?.length > 0 &&
                        <IntegrationsView
                            integration={integration}
                            data={integration?.integrations}
                            history={props.history}
                            location={props.location}
                            deleteIntegration={deleteIntegration}
                            enableDataTransfer={enableDataTransfer}
                            getRenewableFacebookLoginLink={getRenewableFacebookLoginLink}
                            getRenewableTikTokLoginLink={getRenewableTikTokLoginLink}
                            getRenewableGoogleLoginLink={getRenewableGoogleLoginLink}
                            getRenewableMicrosoftLoginLink={getRenewableMicrosoftLoginLink}
                            clear={clear}
                        />
                    }

                    {integration?.affiliateNetworkIntegrations?.length === 0 && integration?.loading?.affiliateNetworkIntegrations === false &&
                        <AffiliateNetworkNoIntegrationsView {...props}/>}

                    {integration?.affiliateNetworkIntegrations?.length > 0 &&
                        <AffiliateNetworkIntegrations {...props}/>
                    }

                    {integration?.rsocIntegrations?.length === 0 && !integration?.loading?.rsocIntegrations &&
                        <RsocNoIntegrationsView {...props}/>}

                    {integration?.rsocIntegrations?.length > 0 && <RsocIntegrations {...props}/>}

                    {integration?.serverIntegrations?.length === 0 && !integration?.loading?.serverIntegrations &&
                        <ServerNoIntegrationsView {...props}/>}

                    {integration?.serverIntegrations?.length > 0 && <ServerIntegrations {...props}/>}
                </Box>
                {alert.errorMsg !== "" && <CustomSnackbar message={alert.errorMsg} timeout={3000}/>}
                {alert.successMsg !== "" &&
                    <CustomSnackbar severity="success" message={alert.successMsg} timeout={3000}/>}
            </SettingsContentBox>
        </SettingsRoot>
    );
}

function IntegrationsView(
    {
        data,
        history,
        location,
        deleteIntegration,
        enableDataTransfer,
        integration,
        getRenewableFacebookLoginLink,
        getRenewableTikTokLoginLink,
        getRenewableGoogleLoginLink,
        getRenewableMicrosoftLoginLink,
        clear
    }) {
    const theme = useTheme();

    const [prompt, setPrompt] = useState(false);
    const [deleteIntegrationId, setDeleteIntegrationId] = useState(0);

    const addIntegration = () => {
        history.push({
            pathname: "/settings/integrations/add",
            state: {background: location}
        });
    };

    const account = (networkType, credentials) => {
        if (networkType === "facebook") {
            return credentials?.map((i) => {
                if (i?.keyName === "fb_ad_account_id") {
                    return i?.keyValue;
                }
                return "";
            });
        } else if (networkType === "google") {
            return credentials?.map((i) => {
                if (i?.keyName === "google_ad_account_id") {
                    const v = i?.keyValue;
                    return v.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
                }
                return "";
            });
        } else if (networkType === "microsoft") {
            return credentials?.map((i) => {
                if (i?.keyName === "microsoft_ad_account_id") {
                    return i?.keyValue;
                }
                return "";
            });
        } else if (networkType === "taboola") {
            return credentials?.map((i) => {
                if (i?.keyName === "taboola_account_id") {
                    return i?.keyValue;
                }
                return "";
            });
        } else if (networkType === "mgid") {
            return credentials?.map((i) => {
                if (i?.keyName === "mgid_account_id") {
                    return i?.keyValue;
                }
                return "";
            });
        } else if (networkType === "outbrain") {
            return credentials?.map((i) => {
                if (i?.keyName === "outbrain_account_name") {
                    return i?.keyValue;
                }
                return "";
            });
        } else if (networkType === "tiktok") {
            return credentials?.map((i) => {
                if (i?.keyName === "tt_ad_account_id") {
                    return i?.keyValue;
                }
                return "";
            });
        }
    };

    const renewAccessToken = (selectedIntegration) => {
        if (selectedIntegration?.networkType === "facebook") {
            return (
                <Box ml={1}>
                    <Tooltip title="Renew access token">
                    <span>
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => getRenewableFacebookLoginLink(selectedIntegration?.id)}
                            aria-label="renew-access-token"
                        >
                            <RefreshOutlined fontSize="small" color="secondary"/>
                        </IconButton>
                    </span>
                    </Tooltip>
                </Box>
            );
        }
        if (selectedIntegration?.networkType === "google") {
            return (
                <Box ml={1}>
                    <Tooltip title="Renew access token">
                    <span>
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => getRenewableGoogleLoginLink(selectedIntegration?.id)}
                            aria-label="renew-access-token"
                        >
                            <RefreshOutlined fontSize="small" color="secondary"/>
                        </IconButton>
                    </span>
                    </Tooltip>
                </Box>
            );
        }
        if (selectedIntegration?.networkType === "microsoft") {
            return (
                <Box ml={1}>
                    <Tooltip title="Renew access token">
                    <span>
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => getRenewableMicrosoftLoginLink(selectedIntegration?.id)}
                            aria-label="renew-access-token"
                        >
                            <RefreshOutlined fontSize="small" color="secondary"/>
                        </IconButton>
                    </span>
                    </Tooltip>
                </Box>
            );
        }
        if (selectedIntegration?.networkType === "tiktok") {
            return (
                <Box ml={1}>
                    <Tooltip title="Renew access token">
                    <span>
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => getRenewableTikTokLoginLink(selectedIntegration?.id)}
                            aria-label="renew-access-token"
                        >
                            <RefreshOutlined fontSize="small" color="secondary"/>
                        </IconButton>
                    </span>
                    </Tooltip>
                </Box>
            );
        }
    };

    const edit = (rowData) => {

        // Clear error/success messages.
        clear();

        if (rowData?.networkType === "facebook") {
            history.push({
                pathname: "/settings/integrations/facebook/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "tiktok") {
            history.push({
                pathname: "/settings/integrations/tiktok/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "google") {
            history.push({
                pathname: "/settings/integrations/google/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "microsoft") {
            history.push({
                pathname: "/settings/integrations/microsoft/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "pushground") {
            history.push({
                pathname: "/settings/integrations/pushground/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "propeller-ads") {
            history.push({
                pathname: "/settings/integrations/propeller-ads/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "exoclick") {
            history.push({
                pathname: "/settings/integrations/exoclick/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "mondiad") {
            history.push({
                pathname: "/settings/integrations/mondiad/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "taboola") {
            history.push({
                pathname: "/settings/integrations/taboola/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "mgid") {
            history.push({
                pathname: "/settings/integrations/mgid/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
        if (rowData?.networkType === "outbrain") {
            history.push({
                pathname: "/settings/integrations/outbrain/" + parseInt(rowData?.id),
                state: {background: location}
            });
        }
    };

    const handleDeletePrompt = (id) => {
        setDeleteIntegrationId(id);
        setPrompt(true);
    };

    const handleDeleteIntegration = (prompt) => {
        if (!prompt) {
            setPrompt(false);
            return false;
        } else {
            deleteIntegration(deleteIntegrationId);
        }
    };

    const handleDataTransferSwitch = (id, status) => {
        if (status) {
            enableDataTransfer(id, false);
        } else {
            enableDataTransfer(id, true);
        }
    };

    useEffect(() => {
        if (integration?.facebook?.renewableLoginLink !== null) {
            document.location.href = integration?.facebook?.renewableLoginLink;
        }
        if (integration?.google?.renewableLoginLink !== null) {
            document.location.href = integration?.google?.renewableLoginLink;
        }
        if (integration?.microsoft?.renewableLoginLink !== null) {
            document.location.href = integration?.microsoft?.renewableLoginLink;
        }
        if (integration?.tiktok?.renewableLoginLink !== null) {
            document.location.href = integration?.tiktok?.renewableLoginLink;
        }
    }, [integration?.facebook?.renewableLoginLink, integration?.google?.renewableLoginLink, integration?.microsoft?.renewableLoginLink, integration?.tiktok?.renewableLoginLink])

    return (
        <Box sx={{
            width: "100%",
            padding: "16px 30px 0 30px",
            maxWidth: "1280px",
            minWidth: "300px",
            margin: "0 auto",
            [theme.breakpoints.down(600)]: {
                padding: 0
            },
        }}>
            <SettingsGeneralHeader>Integrations</SettingsGeneralHeader>
            <SettingsGeneralPaper elevation={0}>
                <Box display="flex" flexDirection="row">

                    {integration?.loading.integrationsView && <Spinner overComponentBox={true}/>}

                    <Box flexGrow={1}>
                        <Typography variant="h3" sx={{
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "bold",
                            display: "block",
                            paddingTop: "16px",
                            paddingBottom: "24px"
                        }}>
                            Cost Sync - Traffic Sources
                        </Typography>
                    </Box>
                    <Box pt={1}>
                        <Button type="button" onClick={addIntegration} color="primary"
                                variant="contained">
                            Add
                        </Button>
                    </Box>
                </Box>

                {prompt && <AlertDialog question="Delete confirmation"
                                        text={"Are you sure you want to delete current integration?"}
                                        confirm={handleDeleteIntegration}/>
                }

                <TableContainer sx={{maxHeight: "400px"}}>
                    <Table stickyHeader size="small" aria-label="traffic-sources-integrations">
                        <TableHead sx={{
                            whiteSpace: "nowrap",
                            ":hover": {
                                backgroundColor: "red !important"
                            }
                        }}>
                            <TableRow hover>
                                <TableCell>Network Name</TableCell>
                                <TableCell>Account</TableCell>
                                <TableCell>Sync Cost</TableCell>
                                <TableCell>Last Synced Time</TableCell>
                                <TableCell>Error</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{
                            whiteSpace: "nowrap",
                            maxHeight: "300px",
                            overflow: "auto"
                        }}>
                            {data?.map((row) => (
                                <TableRow key={row?.id}>
                                    <TableCell>{row?.networkName}</TableCell>
                                    <TableCell sx={{
                                        maxWidth: "200px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis"
                                    }}>
                                        {account(row?.networkType, row?.integrationCredentials)}
                                    </TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={row?.enableDataTransfer}
                                                    onChange={() => handleDataTransferSwitch(row?.id, row?.enableDataTransfer)}
                                                    name="enableDataTransfer"
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={row?.enableDataTransfer ? "On" : "Off"}
                                        />
                                    </TableCell>
                                    <TableCell>{row?.lastDataTransfer === null ? "-" : moment.utc(row?.lastDataTransfer).format("YYYY-MM-DD HH:mm") + " (UTC)"}</TableCell>
                                    <TableCell>
                                        {row?.hasError ?
                                            <Chip size="small" variant="default"
                                                  style={{backgroundColor: "#f44336"}}
                                                  label="Token expired"/> : "-"}
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center" justifyContent="right">
                                            <Box mr={1}>
                                                <Tooltip title="Edit">
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        color="inherit"
                                                        onClick={() => edit(row)}
                                                        aria-label="edit"
                                                    >
                                                        <Edit fontSize="small" color="inherit"/>
                                                    </IconButton>
                                                </span>
                                                </Tooltip>
                                            </Box>
                                            <Box>
                                                <Tooltip title="Delete">
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        color="inherit"
                                                        onClick={() => handleDeletePrompt(row?.id)}
                                                        aria-label="delete"
                                                    >
                                                        <Delete fontSize="small" color="error"/>
                                                    </IconButton>
                                                </span>
                                                </Tooltip>
                                            </Box>
                                            {renewAccessToken(row)}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </SettingsGeneralPaper>
        </Box>
    );
}

function NoIntegrationsView({props}) {
    const theme = useTheme();
    const addIntegration = () => {
        props.history.push({
            pathname: "/settings/integrations/add",
            state: {background: props.location}
        });
    };

    return (
        <Box sx={{
            width: "100%",
            padding: "16px 30px 0 30px",
            maxWidth: "1280px",
            minWidth: "300px",
            margin: "0 auto",
            [theme.breakpoints.down(600)]: {
                padding: 0
            },
        }}>
            <SettingsGeneralHeader>Integrations</SettingsGeneralHeader>
            <SettingsGeneralPaper elevation={0}>
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1}>
                        <Typography variant="h3" sx={{
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "bold",
                            display: "block",
                            paddingTop: "16px",
                            paddingBottom: "24px"
                        }}>
                            Cost Sync - Traffic Sources
                        </Typography>
                    </Box>
                    <Box pt={1}>
                        <Button type="button" onClick={addIntegration} color="primary"
                                variant="contained">
                            Add
                        </Button>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={{
                    paddingTop: "16px",
                    paddingBottom: "10px",
                    [theme.breakpoints.down(600)]: {
                        overflow: "auto"
                    },
                }}>
                    <Box display="flex" flexDirection="row" alignItems="end" flexWrap="wrap">
                        <Box mr={2} mt={1} mb={1}>
                            <img src="/assets/images/traffic-sources/metawhite.png" alt="Meta (Facebook) integration"
                                 width={90}/>
                        </Box>
                        <Box mr={2} mt={1} mb={1} sx={{position: "relative", top: "5px"}}>
                            <img src="/assets/images/traffic-sources/tiktokwhite.png" alt="TikTok integration"
                                 width={100}/>
                        </Box>
                        <Box mr={2} mt={1} mb={1} sx={{position: "relative", top: "4px"}}>
                            <img src="/assets/images/traffic-sources/googleadswhite.svg" alt="Google Ads integration"
                                 width={130}/>
                        </Box>
                        <Box mr={2} mt={1} mb={1} sx={{position: "relative", top: "4px"}}>
                            <img src="/assets/images/traffic-sources/microsoftads-white.svg"
                                 alt="Microsoft Ads integration"
                                 width={110}/>
                        </Box>
                        <Box mt={1} mb={1} mr={1} sx={{position: "relative", top: "4px"}}>
                            <img src="/assets/images/traffic-sources/taboolawhite.png" alt="Taboola integration"
                                 width={85}/>
                        </Box>
                        <Box mt={1} mb={1} mr={1} ml={1.2} sx={{position: "relative", top: "0px"}}>
                            <img src="/assets/images/traffic-sources/outbrainwhite.png" alt="Outbrain integration"
                                 width={95}/>
                        </Box>
                        <Box mt={1} mb={1} mr={1} ml={1.2} sx={{position: "relative", top: "10px"}}>
                            <img src="/assets/images/traffic-sources/mgidwhite.png" alt="MGID integration"
                                 width={65}/>
                        </Box>
                        <Box mt={1} mb={1} mr={2} ml={1.2} sx={{position: "relative", top: "6px"}}>
                            <img src="/assets/images/traffic-sources/propelleradswhite.png"
                                 alt="PropellerAds integration"
                                 width={120}/>
                        </Box>
                        <Box mt={1} mb={1} mr={1.2} sx={{position: "relative", top: "8px"}}>
                            <img src="/assets/images/traffic-sources/exoclick-white.png" alt="ExoClick integration"
                                 width={80}/>
                        </Box>
                        <Box mt={1} mb={1} mr={1.2}>
                            <img src="/assets/images/traffic-sources/pushground_white.png" alt="Pushground integration"
                                 width={120}/>
                        </Box>
                        <Box mt={1} mb={1} sx={{position: "relative"}}>
                            <img src="/assets/images/traffic-sources/mondiadwhite.png" alt="Mondiad integration"
                                 width={100}/>
                        </Box>
                    </Box>
                </Box>
                <Box pt={1} pb={1} sx={{color: "text.disabledLight"}}>
                    You don't have any integrated traffic source.
                    <br/>
                    Integrate your traffic source to perform actions on your campaigns straight from Skro.
                </Box>
                <Box flex="display" flexWrap="wrap">
                    <Box>
                        <List>
                            <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Auto-update your traffic source cost."/>
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Pause/activate your campaigns, ad groups, ads."/>
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Exclude non-converting zones, banners and sites."/>
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Bid up/down based on campaigns, zones, banners and sites."/>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </SettingsGeneralPaper>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        integration: state.integration
    };
};

const mapDispatchToProps = {
    getTrafficSourceIntegrations: integrationActions.getTrafficSourceIntegrations,
    getAffiliateNetworkIntegrations: integrationActions.getAffiliateNetworkIntegrations,
    getRsocIntegrations: integrationActions.getRsocIntegrations,
    getServerIntegrations: integrationActions.getServerIntegrations,
    deleteIntegration: integrationActions.deleteIntegration,
    enableDataTransfer: integrationActions.enableDataTransfer,
    getRenewableFacebookLoginLink: integrationActions.getRenewableFacebookLoginLink,
    getRenewableTikTokLoginLink: integrationActions.getRenewableTikTokLoginLink,
    getRenewableGoogleLoginLink: integrationActions.getRenewableGoogleLoginLink,
    getRenewableMicrosoftLoginLink: integrationActions.getRenewableMicrosoftLoginLink,
    updateSelectedIntegration: integrationActions.updateSelectedIntegration,
    clear: alertActions.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);