import React, {useEffect, useState} from "react";
import {DateRange, ArrowDropDown, ChevronRight, ChevronLeft} from '@mui/icons-material';
import PropTypes from "prop-types";
import {DayPickerRangeController} from "react-dates-gte-react-17/esm";
import 'react-dates-gte-react-17/initialize';
import 'react-dates-gte-react-17/lib/css/_datepicker.css';
import {
    Box,
    Button,
    Chip,
    IconButton,
    Paper,
    Popover,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";
import moment from 'moment-timezone';
import {compose} from "redux";
import {connect} from "react-redux";
import TimezoneSelect from "../timezone/TimezoneSelect";
import {useForm} from "react-hook-form";
import ReactHookFormSelect from "../input/ReactHookFormSelect";
import {endHours, startHours} from "./DataList";
import {authActions} from "../../store/actions/authAction";

function AirbnbDateRangePicker(props) {
    const theme = useTheme();
    const {dateLabel, onChangeDate, auth: {userSettings: {timezone}}, hideTimezoneSelect = false} = props;

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [focusedInput, setFocusedInput] = useState("startDate");
    const [open, setOpen] = useState(null);

    const {handleSubmit, control, setValue} = useForm();

    const down768 = useMediaQuery(theme.breakpoints.down(768));

    const dateRange = [
        {
            label: 'Today',
            startDate: moment().tz(timezone).startOf("d"),
            endDate: moment().tz(timezone).endOf("d")
        },
        {
            label: 'Yesterday',
            startDate: moment().tz(timezone).subtract(1, 'day').startOf("d"),
            endDate: moment().tz(timezone).subtract(1, 'day').endOf("d")
        },
        {
            label: 'Last 3 Days',
            startDate: moment().tz(timezone).subtract(2, 'day').startOf("d"),
            endDate: moment().tz(timezone).startOf("d")
        },
        {
            label: 'Last 7 Days',
            startDate: moment().tz(timezone).subtract(6, 'day').startOf("d"),
            endDate: moment().tz(timezone).startOf("d")
        },
        {
            label: 'This Week',
            startDate: moment().tz(timezone).startOf("isoWeek"),
            endDate: moment().tz(timezone).endOf("isoWeek")
        },
        {
            label: 'Last Week',
            startDate: moment().tz(timezone).startOf("isoWeek").subtract(1, 'week'),
            endDate: moment().tz(timezone).endOf("isoWeek").subtract(1, 'week')
        },
        {
            label: 'This Month',
            startDate: moment().tz(timezone).startOf("M"),
            endDate: moment().tz(timezone).endOf("d")
        },
        {
            label: 'Last Month',
            startDate: moment().tz(timezone).subtract(1, 'month').startOf("M"),
            endDate: moment().tz(timezone).subtract(1, 'month').endOf("M")
        },
        {
            label: 'Last 3 Months',
            startDate: moment().tz(timezone).subtract(3, 'month').startOf("d"),
            endDate: moment().tz(timezone).endOf("d")
        },
        {
            label: 'Last 6 Months',
            startDate: moment().tz(timezone).subtract(6, 'month').startOf("d"),
            endDate: moment().tz(timezone).endOf("d")
        },
        {
            label: 'Last 12 Months',
            startDate: moment().tz(timezone).subtract(12, 'month').startOf("d"),
            endDate: moment().tz(timezone).endOf("d")
        },
    ];

    const handleDateRangeChange = (start, end, label) => {
        setStartDate(start);
        setEndDate(end);

        if (focusedInput === "startDate") {
            setEndDate(null);
        }

        if (end !== null && end !== undefined && start !== null && start !== undefined && focusedInput === "endDate") {
            let from = moment(start).startOf("d").set({
                hour: parseInt(moment(props?.from).format("HH")),
            }).format("YYYY-MM-DD HH:mm:ss");

            let to = moment(end).endOf("d").set({
                hour: parseInt(moment(props?.to).format("HH")),
                minute: parseInt(moment(props?.to).format("mm")),
            }).format("YYYY-MM-DD HH:mm:ss");

            onChangeDate(from, to, label);
            setOpen(null);
        }
    };

    const handleChipClick = (start, end, label) => {

        let from = moment(start).startOf("d").set({
            hour: parseInt(moment(props?.from).format("HH")),
        }).format("YYYY-MM-DD HH:mm:ss");

        let to = moment(end).endOf("d").set({
            hour: parseInt(moment(props?.to).format("HH")),
            minute: parseInt(moment(props?.to).format("mm")),
        }).format("YYYY-MM-DD HH:mm:ss");

        onChangeDate(from, to, label);
        setOpen(null);
    };

    const handleOnClickPreviousDay = (start, end) => {
        let from = moment(start).subtract(1, 'day').startOf("d").set({
            hour: parseInt(moment(props?.from).format("HH")),
        }).format("YYYY-MM-DD HH:mm:ss");

        let to = moment(end).subtract(1, 'day').endOf("d").set({
            hour: parseInt(moment(props?.to).format("HH")),
            minute: parseInt(moment(props?.to).format("mm")),
        }).format("YYYY-MM-DD HH:mm:ss");

        onChangeDate(from, to, "");
    };

    const handleOnClickNextDay = (start, end) => {
        let from = moment(start).add(1, 'day').startOf("d").set({
            hour: parseInt(moment(props?.from).format("HH")),
        }).format("YYYY-MM-DD HH:mm:ss");

        let to = moment(end).add(1, 'day').endOf("d").set({
            hour: parseInt(moment(props?.to).format("HH")),
            minute: parseInt(moment(props?.to).format("mm")),
        }).format("YYYY-MM-DD HH:mm:ss");

        onChangeDate(from, to, "");
    };

    const handleOnClickApplyTimezoneAndTime = (data) => {
        props?.updateTimezone(data?.timezone?.timezone);

        const [startHour, startMinute] = data?.startTime.split(':');
        const [endHour, endMinute] = data?.endTime.split(':');

        let from = moment(props?.from).startOf("d").set({
            hour: parseInt(startHour),
            minute: parseInt(startMinute)
        }).format("YYYY-MM-DD HH:mm:ss");
        let to = moment(props?.to).endOf("d").set({
            hour: parseInt(endHour),
            minute: parseInt(endMinute)
        }).format("YYYY-MM-DD HH:mm:ss");
        onChangeDate(from, to, "");
        setOpen(null);
    };

    useEffect(() => {
        if (open !== null) {
            setStartDate(moment(props?.from));
            setEndDate(moment(props?.to));
            setFocusedInput("startDate");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Box sx={{flexShrink: 0, color: "text.secondary", display: "flex", paddingRight: 1}}>
            <Box display="flex" flexDirection="row">
                <Box>
                    <Button
                        id="airbnb-calendar"
                        onClick={(e) => {
                            setOpen({anchorEl: e.currentTarget});
                        }}
                        startIcon={<DateRange/>}
                        endIcon={<ArrowDropDown/>}
                        color="inherit"
                        size="medium"
                        sx={{textTransform: "none", whiteSpace: "nowrap", color: "text.primary"}}
                    >
                        {dateLabel !== undefined && dateLabel !== "" ? dateLabel : moment(props?.from).format("D MMM YYYY") + "-" + moment(props?.to).format("D MMM YYYY")}
                    </Button>
                </Box>
                <Box pt="3px" pl={0.5}>
                    <Tooltip title="Previous day" placement="top">
                        <span>
                            <IconButton
                                color="inherit"
                                onClick={() => handleOnClickPreviousDay(props?.from, props?.to)}
                                aria-label="previous-day"
                                size="small"
                            >
                                <ChevronLeft color="inherit"/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box pt="3px">
                    <Tooltip title="Next day" placement="top">
                        <span>
                            <IconButton
                                color="inherit"
                                onClick={() => handleOnClickNextDay(props?.from, props?.to)}
                                aria-label="next-day"
                                size="small"
                                disabled={moment().startOf("d").format("YYYY-MM-DD") === moment(props?.from).startOf("d").format("YYYY-MM-DD")}
                                sx={{":disabled": {color: "text.disabledDark"}}}
                            >
                                <ChevronRight color="inherit"/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            <Popover
                id="date-range-picker-airbnb"
                open={Boolean(open?.anchorEl)}
                anchorEl={open?.anchorEl}
                onClose={() => setOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper sx={{padding: 1, backgroundColor: "background.darkBlue"}}>
                    <DayPickerRangeController
                        minDate={moment("2021-03-01 00:00:00")}
                        keepOpenOnDateSelect={true}
                        numberOfMonths={2}
                        minimumNights={0}
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={({startDate, endDate}) => handleDateRangeChange(startDate, endDate, "")}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                        orientation={down768 ? "vertical" : "horizontal"}
                        hideKeyboardShortcutsPanel={true}
                        noBorder={true}
                    />
                    <Box p={2} sx={{maxWidth: 600}}>
                        {dateRange.map((i) => {
                            return (
                                <Chip
                                    color="primary"
                                    variant={dateLabel === i?.label ? "filled" : "outlined"}
                                    key={i?.label}
                                    label={i?.label}
                                    onClick={() => handleChipClick(i?.startDate, i?.endDate, i?.label)}
                                    sx={{marginRight: "4px", marginBottom: 1}}
                                />
                            );
                        })}
                    </Box>
                    {hideTimezoneSelect &&
                        <Box pb={1} pl={2} sx={{color: "text.primary"}}>
                            Timezone: {timezone}
                        </Box>
                    }
                    {!hideTimezoneSelect &&
                        <Box display="flex" flexDirection={down768 ? "column" : "row"} pt={1} pl={2} pr={2} pb={2}
                             flexWrap="wrap">
                            <Box flexGrow={1} minWidth="150px">
                                <TimezoneSelect
                                    setValue={setValue}
                                    control={control}
                                    selectedValue={timezone}
                                    inputName="timezone"
                                    style={{
                                        "& .MuiAutocomplete-input": {
                                            fontSize: 14,
                                            minHeight: 24,
                                            padding: "0px 6px !important",
                                            ...(down768 && {fontSize: 16})
                                        }
                                    }}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row" flexWrap="noWrap">
                                <Box sx={{...(!down768 && {pl: 2})}}>
                                    <ReactHookFormSelect
                                        setValue={setValue}
                                        control={control}
                                        label="Start time"
                                        name="startTime"
                                        options={startHours()}
                                        defaultValue={moment(props?.from).format("HH:mm")}
                                        style={{
                                            "& .MuiSelect-select": {
                                                minHeight: 15,
                                                fontSize: 14,
                                                padding: "8px 14px",
                                                ...(down768 && {fontSize: 16})
                                            }
                                        }}
                                    />
                                </Box>
                                <Box p={0.5} mt={1.5}>-</Box>
                                <Box>
                                    <ReactHookFormSelect
                                        setValue={setValue}
                                        control={control}
                                        label="End time"
                                        name="endTime"
                                        options={endHours()}
                                        defaultValue={moment(props?.to).format("HH:mm")}
                                        style={{
                                            "& .MuiSelect-select": {
                                                minHeight: 15,
                                                fontSize: 14,
                                                padding: "8px 14px",
                                                ...(down768 && {fontSize: 16})
                                            }
                                        }}
                                    />
                                </Box>
                                <Box
                                    flexGrow={down768 ? 1 : 0}
                                    sx={{...(down768 && {pt: 1, pl: 2}), pt: 1, pl: 2}}
                                >
                                    <Button
                                        onClick={handleSubmit(handleOnClickApplyTimezoneAndTime)}
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                    >
                                        Apply
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    }
                </Paper>
            </Popover>
        </Box>
    );
}

AirbnbDateRangePicker.propTypes = {
    onChangeDate: PropTypes.func,
    from: PropTypes.string,
    to: PropTypes.string,
    dateLabel: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const actionCreators = {
    updateTimezone: authActions.updateTimezone,
};

export default compose(connect(mapStateToProps, actionCreators))(AirbnbDateRangePicker);
