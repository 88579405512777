export const websiteDeploymentsConstants = {
    WEBSITE_DEPLOYMENTS_LOADING: 'WEBSITE_DEPLOYMENTS_LOADING',
    WEBSITE_DEPLOYMENTS_LOADING_ERROR: 'WEBSITE_DEPLOYMENTS_LOADING_ERROR',
    WEBSITE_DEPLOYMENTS_SET_IS_LOADING: 'WEBSITE_DEPLOYMENTS_SET_IS_LOADING',
    WEBSITE_DEPLOYMENTS_UPDATE_ORDER_BY: 'WEBSITE_DEPLOYMENTS_UPDATE_ORDER_BY',
    WEBSITE_DEPLOYMENTS_UPDATE_SORT_BY: 'WEBSITE_DEPLOYMENTS_UPDATE_SORT_BY',
    WEBSITE_DEPLOYMENTS_UPDATE_BUCKET: 'WEBSITE_DEPLOYMENTS_UPDATE_BUCKET',
    WEBSITE_DEPLOYMENTS_UPDATE_SORT_COLUMNS: 'WEBSITE_DEPLOYMENTS_UPDATE_SORT_COLUMNS',
    WEBSITE_DEPLOYMENTS_INFINITY_SCROLL: 'WEBSITE_DEPLOYMENTS_INFINITY_SCROLL',
    WEBSITE_DEPLOYMENTS_RERENDER: 'WEBSITE_DEPLOYMENTS_RERENDER',
    WEBSITE_DEPLOYMENTS_DELETE_DEPLOYMENT: 'WEBSITE_DEPLOYMENTS_DELETE_DEPLOYMENT',
    WEBSITE_DEPLOYMENTS_UPDATE_DEPLOYMENT_STATUS: 'WEBSITE_DEPLOYMENTS_UPDATE_DEPLOYMENT_STATUS',
    WEBSITE_DEPLOYMENTS_CHECK_STATUS: 'WEBSITE_DEPLOYMENTS_CHECK_STATUS'
};
