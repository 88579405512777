import React from "react";
import {
    Button,
    Divider,
    List,
    ListItem,
    Box,
    ListItemText,
    ListItemIcon,
    useTheme,
    Typography
} from "@mui/material";
import {Check} from "@mui/icons-material";
import {SettingsGeneralPaper} from "../../ProfileSettings";

function ServerNoIntegrationsView(props) {
    const theme = useTheme();
    const addIntegration = () => {
        props.history.push({
            pathname: "/settings/integrations/add-server",
            state: {background: props.location}
        });
    };

    return (
        <Box sx={{
            width: "100%",
            padding: "24px 30px 0 30px",
            maxWidth: "1280px",
            minWidth: "300px",
            margin: "0 auto",
            [theme.breakpoints.down(600)]: {
                padding: 0,
                marginTop: "16px"
            },
        }}>
            <SettingsGeneralPaper elevation={0}>
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1}>
                        <Typography variant="h3" sx={{
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "bold",
                            display: "block",
                            paddingTop: "16px",
                            paddingBottom: "24px"
                        }}>
                            Servers / Domains - Deploy Websites & Issue SSL Certs
                        </Typography>
                    </Box>
                    <Box pt={1}>
                        <Button type="button" onClick={addIntegration} color="primary" variant="contained">
                            Add
                        </Button>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" pb={2}>
                    <Box flexGrow={1} sx={{color: "text.disabledLight"}}>
                        Simply add your domains and we'll generate free SSL certificates for your domains. Upload and
                        deploy your websites in just 2 minutes, ensuring secure and seamless online experiences.
                    </Box>
                </Box>
                <Divider/>
                <Box pt={2} pb={1} sx={{[theme.breakpoints.down(600)]: {overflow: "auto"}}}>
                    <Box display="flex" flexDirection="row" alignItems="end" flexWrap="nowrap">
                        <Box mt={1}>
                            <img
                                src="/assets/images/servers/aws.svg"
                                alt="AWS logo"
                                width={105}
                                style={{
                                    backgroundColor: theme.palette.background.default,
                                    marginRight: "8px",
                                    borderRadius: "4px",
                                    padding: "6px 32px"
                                }}
                            />
                        </Box>
                        <Box mt={1}>
                            <img
                                src="/assets/images/servers/cloudflare.svg"
                                alt="Cloudflare logo"
                                width={102}
                                style={{
                                    backgroundColor: theme.palette.background.default,
                                    marginRight: "8px",
                                    borderRadius: "4px",
                                    padding: "6px 14px"
                                }}
                            />
                        </Box>
                        <Box mt={1}>
                            <img
                                src="/assets/images/servers/namesilo.svg"
                                alt="Namesilo logo"
                                width={104}
                                style={{
                                    backgroundColor: theme.palette.background.default,
                                    marginRight: "8px",
                                    borderRadius: "4px",
                                    padding: "10px 8px"
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box pt={1} pb={1} sx={{color: "text.disabledLight"}}>
                    You don't have any integrations. Integrate now and deploy websites to AWS.
                </Box>
                <Box flex="display" flexWrap="wrap">
                    <Box>
                        <List>
                            <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Deploy websites on AWS."/>
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                <ListItemIcon sx={{minWidth: 42}}>
                                    <Check color={"primary"}/>
                                </ListItemIcon>
                                <ListItemText primary="Issue free SSL certificates automatically."/>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </SettingsGeneralPaper>
        </Box>
    );
}

export default ServerNoIntegrationsView;