import React, {useCallback} from "react";
import {connect} from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemText,
    Tooltip,
    useMediaQuery,
    useTheme,
    Box,
    Button,
    Avatar,
    ListItemSecondaryAction
} from "@mui/material";
import {workspaceActions} from "../../store/actions/workspaceAction";
import CustomSnackbar from "../alert/CustomSnackbar";
import {alertActions} from "../../store/actions/alertActions";
import {Person, Add} from "@mui/icons-material";

function AddWorkspace(props) {
    const {assignUserToWorkspace, error} = props;
    const {alert, user, workspace} = props.state;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleAssignUser = (u) => {
        const workspaceId = parseInt(props?.match?.params?.id);
        if (parseInt(workspaceId) === 0) {
            error("Workspace ID is missing.");
            return false;
        }
        assignUserToWorkspace({userId: u?.id, workspaceId: workspaceId, user: u});
    };

    const handleAlreadyAssignUser = (id) => {
        let res = false;
        workspace?.workspaceUsers?.filter((u) => {
            if (u?.id === id) {
                res = true;
            }
            return u;
        });
        return res;
    };

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        props.history.push({pathname: "/settings/team/workspaces", state: {from: props.location}});
    }, [props]);

    return (
        <Dialog open={true}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="create-workspace-form"
        >
            <DialogTitle sx={{
                textAlign: "center",
                paddingBottom: 0
            }}>
                Create a new workspace
            </DialogTitle>

            {alert.errorMsg !== "" &&
                <CustomSnackbar message={alert.errorMsg} timeout={3500}/>
            }

            <DialogContent>
                <Box display="flex">
                    <List sx={{
                        width: "100%",
                        height: "100%",
                        maxHeight: 400,
                        overflow: "auto"
                    }}>
                        {user?.users?.map((item) => {
                            return (
                                <ListItem key={item?.id} role={undefined} dense>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Person/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={item?.id} primary={item?.email}/>
                                    {!handleAlreadyAssignUser(item?.id) &&
                                        <ListItemSecondaryAction>
                                            <Tooltip title={"Assign user to workspace"}>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Assign"
                                                    onClick={() => handleAssignUser(item)}
                                                    color="primary"
                                                    size="medium"
                                                >
                                                    <Add/>
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexWrap="noWrap">
                    <Box>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Close
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            workspace: state.workspace,
            alert: state.alert,
            user: state.user,
        }
    }
};

const mapDispatchToProps = {
    assignUserToWorkspace: workspaceActions.assignUserToWorkspace,
    error: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkspace);