import {integrationConstants} from "../constants/integrationConstants";

const initialState = {
    formStep: "integrations",
    selectedIntegration: null,
    currentIntegration: null,
    errorMsg: "",
    rerender: false,
    integrations: null,
    adAccounts: null,
    loading: {
        integration: false,
        integrations: false,
        adAccounts: false,
        facebookLoginLink: false,
        enableDataTransfer: false,
        integrationsView: false,
        affiliateNetworkIntegrations: false,
        rsocIntegrations: false,
        googleLoginLink: false,
        microsoftLoginLink: false,
        conversionApi: false,
        serverIntegrations: false
    },
    facebook: {
        loginLink: null,
        renewableLoginLink: null
    },
    tiktok: {
        loginLink: null,
        renewableLoginLink: null
    },
    google: {
        loginLink: null,
        renewableLoginLink: null
    },
    microsoft: {
        loginLink: null,
        renewableLoginLink: null
    },
    affiliateNetworkIntegrations: null,
    rsocIntegrations: null,
    serverIntegrations: null
};

const integrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case integrationConstants.INTEGRATIONS_UPDATE_DATA_TRANSFER_STATUS:
            return {
                ...state,
                integrations: state.integrations.map((item) => {
                    if (action?.integrationId === item?.id) {
                        item.enableDataTransfer = !item.enableDataTransfer;
                    }
                    return item;
                }),
                loading: {
                    ...state.loading,
                    integrationsView: false
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_SPINNER:
            return {
                ...state,
                integrations: [],
                loading: {
                    ...state.loading,
                    integrations: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_INTEGRATIONS:
            return {
                ...state,
                integrations: action?.result,
                loading: {
                    ...state.loading,
                    integrations: false
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_CURRENT_INTEGRATION_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    integration: true
                }
            };
        case integrationConstants.INTEGRATIONS_STOP_LOADING_CURRENT_INTEGRATION_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    integration: false
                }
            };
        case integrationConstants.INTEGRATIONS_SET_CURRENT_INTEGRATION:
            return {
                ...state,
                currentIntegration: action?.result,
                loading: {
                    ...state.loading,
                    integration: false
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_AD_ACCOUNTS_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    adAccounts: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_AD_ACCOUNTS:
            return {
                ...state,
                adAccounts: action?.result?.result,
                loading: {
                    ...state.loading,
                    adAccounts: false
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_FB_LOGIN_LINK_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    adAccounts: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_FB_LOGIN_LINK:
            return {
                ...state,
                facebook: {
                    loginLink: action?.result?.url
                },
                loading: {
                    ...state.loading,
                    facebookLoginLink: false
                }
            };
        case integrationConstants.INTEGRATIONS_SET_TIKTOK_LOGIN_LINK:
            return {
                ...state,
                tiktok: {
                    loginLink: action?.result?.url
                },
                loading: {
                    ...state.loading,
                    facebookLoginLink: false
                }
            };
        case integrationConstants.INTEGRATIONS_SET_RENEWABLE_TIKTOK_LOGIN_LINK:
            return {
                ...state,
                tiktok: {
                    renewableLoginLink: action?.result?.url
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_TIKTOK_LOGIN_LINK_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    adAccounts: true
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_INTEGRATIONS_VIEW_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    integrationsView: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_RENEWABLE_FB_LOGIN_LINK:
            return {
                ...state,
                facebook: {
                    renewableLoginLink: action?.result?.url
                }
            };
        case integrationConstants.INTEGRATIONS_ERROR:
            return {
                ...state,
                errorMsg: action?.message,
                rerender: false,
                loading: {
                    ...state.loading,
                    adAccounts: false,
                    integration: false,
                    integrations: false,
                    facebookLoginLink: false,
                    googleLoginLink: false
                }
            };
        case integrationConstants.INTEGRATIONS_UPDATE_STEP:
            return {
                ...state,
                formStep: action.formStep
            };
        case integrationConstants.INTEGRATIONS_UPDATE_SELECTED_INTEGRATION:
            return {
                ...state,
                selectedIntegration: action?.selectedIntegration,
                formStep: action?.selectedIntegration?.name
            };
        case integrationConstants.INTEGRATIONS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        case integrationConstants.INTEGRATIONS_RESET:
            return initialState;
        case integrationConstants.INTEGRATIONS_LOADING_AFFILIATE_NETWORK_INTEGRATIONS_SPINNER:
            return {
                ...state,
                affiliateNetworkIntegrations: [],
                loading: {
                    ...state.loading,
                    affiliateNetworkIntegrations: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_AFFILIATE_NETWORK_INTEGRATIONS:
            return {
                ...state,
                affiliateNetworkIntegrations: action?.result,
                loading: {
                    ...state.loading,
                    affiliateNetworkIntegrations: false
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_RSOC_INTEGRATIONS_SPINNER:
            return {
                ...state,
                rsocIntegrations: [],
                loading: {
                    ...state.loading,
                    rsocIntegrations: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_RSOC_INTEGRATIONS:
            return {
                ...state,
                rsocIntegrations: action?.result,
                loading: {
                    ...state.loading,
                    rsocIntegrations: false
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_SERVER_INTEGRATIONS_SPINNER:
            return {
                ...state,
                serverIntegrations: [],
                loading: {
                    ...state.loading,
                    serverIntegrations: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_SERVER_INTEGRATIONS:
            return {
                ...state,
                serverIntegrations: action?.result,
                loading: {
                    ...state.loading,
                    serverIntegrations: false
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_GOOGLE_LOGIN_LINK_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    adAccounts: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_GOOGLE_LOGIN_LINK:
            return {
                ...state,
                google: {
                    loginLink: action?.result?.url
                },
                loading: {
                    ...state.loading,
                    googleLoginLink: false
                }
            };
        case integrationConstants.INTEGRATIONS_SET_RENEWABLE_GOOGLE_LOGIN_LINK:
            return {
                ...state,
                google: {
                    renewableLoginLink: action?.result?.url
                }
            };
        case integrationConstants.INTEGRATIONS_SET_AD_ACCOUNT_INTEGRATED:
            return {
                ...state,
                adAccounts: state.adAccounts.map((account) => {
                    account.integrated = account?.customerId === action?.adAccountId;
                    return account;
                }),
                loading: {
                    ...state.loading,
                    adAccounts: false
                }
            };
        case integrationConstants.INTEGRATIONS_CONVERSION_API_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    conversionApi: action?.result?.loading
                }
            };
        case integrationConstants.INTEGRATIONS_LOADING_MICROSOFT_LOGIN_LINK_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    adAccounts: true
                }
            };
        case integrationConstants.INTEGRATIONS_SET_MICROSOFT_LOGIN_LINK:
            return {
                ...state,
                microsoft: {
                    loginLink: action?.result?.url
                },
                loading: {
                    ...state.loading,
                    microsoftLoginLink: false
                }
            };
        case integrationConstants.INTEGRATIONS_SET_RENEWABLE_MICROSOFT_LOGIN_LINK:
            return {
                ...state,
                microsoft: {
                    renewableLoginLink: action?.result?.url
                }
            };
        case integrationConstants.INTEGRATIONS_SET_MICROSOFT_AD_ACCOUNT_INTEGRATED:
            return {
                ...state,
                adAccounts: state.adAccounts.map((account) => {
                    account.integrated = account?.accountId === action?.accountId;
                    return account;
                }),
                loading: {
                    ...state.loading,
                    adAccounts: false
                }
            };
        default:
            return state;
    }
};

export default integrationReducer;