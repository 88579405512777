import {Box, Button, Paper, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {settingsServices} from "../../service/settingsService";
import Spinner from "../spinner/Spinner";
import CustomSnackbar from "../alert/CustomSnackbar";
import {Person} from "@mui/icons-material";
import {styled} from "@mui/system";

export const SettingsGeneralRoot = styled(Box)(({theme}) => ({
    margin: "0 auto",
    maxWidth: 900,
    padding: "20px 30px",
    [theme.breakpoints.down(600)]: {
        padding: 0,
    },
}));

export const SettingsGeneralHeader = styled(Typography)(({theme}) => ({
    fontWeight: "bold",
    fontSize: "18px",
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down(600)]: {
        paddingTop: "12px",
        paddingLeft: theme.spacing(2),
    }
}));

export const SettingsGeneralPaper = styled(Paper)(() => ({
    position: "relative",
    padding: "8px 20px",
    minHeight: "200px",
    minWidth: "300px",
    maxWidth: "100%",
    backgroundColor: "background.defaultBlue"
}));

export const SettingsGeneralButtonBox = styled(Box)(() => ({
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
}));

function ProfileSettings(props) {
    const theme = useTheme();
    const down600 = useMediaQuery(theme.breakpoints.down(600));

    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(null);
    const [profile, setProfile] = useState(null);

    const {register, handleSubmit, setValue, formState: {errors}} = useForm({
        defaultValues: {firstName: "", lastName: ""}
    });

    const onSubmit = (data) => {
        setFormData(data);
    };

    useEffect(() => {
        async function updateProfile(): void {
            try {
                props.clear();
                setLoading(true);
                const response = await settingsServices.updateUserDetails(formData);
                if (response !== null && response !== undefined) {
                    props.successMsg("Profile information has been successfully updated.");
                }
            } catch (error) {
                if (error?.response?.status === 403) {
                    props.errorMsg("Access forbidden. You have no rights to update your user profile.");
                } else if (error?.response?.data?.error) {
                    props.errorMsg(error.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (formData !== null) {
            updateProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        async function fetchProfileData(): void {
            props.clear();
            setLoading(true);
            await settingsServices.fetchProfileData().then((response) => {
                if (response !== null && response !== undefined) {
                    setValue("firstName", response?.firstName);
                    setValue("lastName", response?.lastName);
                    setProfile(response);
                }
            }).then((error) => {
                if (error?.response?.status === 403) {
                    props.errorMsg("Access forbidden. You have no rights to fetch profile data.");
                } else if (error?.response?.data?.error) {
                    props.errorMsg(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (profile === null) {
            fetchProfileData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, setValue]);

    return (
        <SettingsGeneralRoot>
            <SettingsGeneralHeader variant="h3">General</SettingsGeneralHeader>
            <SettingsGeneralPaper elevation={0}>
                {loading && <Spinner size={32} overComponentBox={true}/>}

                <h3>Profile Information</h3>

                <Box sx={{color: "text.disabledLight", pb: 1}}>
                    Update your personal information. If you would like to update your email, then please contact to our
                    customer support.
                </Box>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>

                    {props?.state?.alert?.successMsg !== "" &&
                        <CustomSnackbar message={props?.state?.alert?.successMsg} severity="success" timeout={3500}/>
                    }
                    {props?.state?.alert?.errorMsg !== "" &&
                        <CustomSnackbar message={props?.state?.alert?.errorMsg} severity="error" timeout={3500}/>
                    }

                    <Box sx={{padding: "10px 0 15px 0", fontSize: "16px"}}>
                        <Person sx={{fontSize: 20, position: "relative", top: 4}}/>
                        <span style={{paddingLeft: 8}}>
                            {profile !== null && profile.hasOwnProperty("email") ? profile?.email : ""}
                        </span>
                    </Box>
                    <Box display="flex" flexWrap="wrap" flexDirection="column">
                        <Box mb={1}>
                            <TextField
                                error={!!errors.firstName}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                type="text"
                                placeholder="Enter your first name"
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register("firstName", {required: true, maxLength: 255, minLength: 2})}
                            />
                        </Box>
                        <Box mb={1}>
                            <TextField
                                error={!!errors.lastName}
                                variant="outlined"
                                margin="dense"
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                size="small"
                                type="text"
                                placeholder="Enter your last name"
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register("lastName", {required: true, maxLength: 255, minLength: 2})}
                            />
                        </Box>
                    </Box>
                    <SettingsGeneralButtonBox>
                        <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained"
                                disabled={loading} fullWidth={down600}>
                            Update
                        </Button>
                    </SettingsGeneralButtonBox>
                </form>
            </SettingsGeneralPaper>
        </SettingsGeneralRoot>
    );
}

export default ProfileSettings;