import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, Switch,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {alertActions} from "../../../store/actions/alertActions";
import {websiteBucketsActions} from "../../../store/actions/websiteBucketsAction";
import {useParams} from "react-router-dom";
import DomainsDropdown from "./DomainsDropdown";
import IntegrationDropdown from "../IntegrationDropdown";

function DeployWebsite(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const [autoVerify, setAutoVerify] = useState(false);

    const {handleSubmit, control, formState: {errors}, watch} = useForm();

    const {alertError, deployWebsite, updateDeploymentStatus} = props;
    const {newDeployment} = props?.websiteBucketsState;
    const {plan} = props?.auth?.subscription;

    const handleClose = useCallback(() => {
        history.push({pathname: "/websites/s3-buckets", state: {from: location}});
    }, [history, location]);

    const handleOnSubmitDeploy = (data) => {
        if (plan === "Free") {
            alertError("This feature is not available in Free subscription plan.");
            return false;
        }

        deployWebsite({
            websiteId: parseInt(params?.id),
            domainId: data?.domain?.value,
            domainIntegrationId: data?.domainIntegration?.value
        });
    };

    useEffect(() => {
        if (newDeployment) {
            updateDeploymentStatus(false);
            history.push({pathname: "/websites/deployments", state: {from: location}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newDeployment, history, location]);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={fullScreen}
            aria-labelledby="deploy-website-dialog"
            sx={{maxWidth: 600, margin: "0 auto"}}
        >
            <DialogTitle id="deploy-website-dialog" textAlign="center">Deploy website</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(handleOnSubmitDeploy)} autoComplete={"off"}>
                    <Box display="flex" flexWrap={"wrap"}>
                        <Box p={1} width="100%">
                            <DomainsDropdown control={control} errors={errors}/>
                        </Box>
                        <Box p={1} width="100%">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={autoVerify}
                                        color="primary"
                                        onChange={(e, checked) => setAutoVerify(checked)}
                                    />
                                }
                                label="Distribute selected domain automatically"
                            />

                        </Box>
                        {autoVerify &&
                            <>
                                <Box p={1} width="100%">
                                    <IntegrationDropdown
                                        control={control}
                                        errors={errors}
                                        label="Your domain provider*"
                                        name="domainIntegration"
                                        error={!!errors?.domainIntegration}
                                    />
                                </Box>
                                {watch("domainIntegration")?.networkType === "server_namesilo" &&
                                    <Box p={1} width="100%">
                                        <Alert severity="warning" variant="filled" color="warning">
                                            NameSilo domain parking system redirects any "naked" domains (example.com)
                                            to
                                            the www.example.com equivalent. Therefore, all that must be done
                                            is to create an A record to NameSilo parking IP (which can be done by
                                            applying the
                                            parking/forwarding DNS template) for the SLD hostname.
                                        </Alert>
                                    </Box>
                                }
                            </>
                        }
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end">
                    <Box mr={3}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Close
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmitDeploy)} color="primary"
                                variant="contained">
                            Deploy
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        websiteBucketsState: state.websiteBuckets,
        auth: state.auth
    }
};

const mapDispatchToProps = {
    deployWebsite: websiteBucketsActions.deployWebsite,
    updateDeploymentStatus: websiteBucketsActions.updateDeploymentStatus,
    alertError: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(DeployWebsite);