import React, {useEffect} from "react";
import {campaignFormActions} from "../../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import ReactHookFormCheckbox from "../../input/ReactHookFormCheckbox";
import ReactHookFormRadio from "../../input/ReactHookFormRadio";
import DeviceTypeMultiSelect from "./DeviceTypeMultiSelect";
import {Add, Delete} from "@mui/icons-material";
import {Box, Button, IconButton, useTheme} from "@mui/material";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        padding: "12px 10px",
        backgroundColor: theme.palette.background.darkBlue,
        borderRadius: 4
    },
    title: {
        paddingTop: 4,
        fontSize: 14,
        fontWeight: 600
    },
    radio: {
        '&$checked': {
            color: theme.palette.error.light
        }
    },
    checked: {},
    deleteButton: {
        color: theme.palette.error.dark
    },
    addConditionButton: {
        textTransform: "none",
        padding: "2px 8px"
    }
});

function DeviceType(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {campaignFormState: {flow, selectedRuleNumber, selectedRule}} = props;

    const {control, setValue, reset, getValues} = useForm({
        defaultValues: {
            deviceTypeCondition: "is",
            deviceTypeIncludeEmptyValue: false,
            deviceTypeValues: []
        }
    });

    const radioConditions = [
        {label: "Is", value: "is", color: "primary"},
        {label: "Is not", value: "isNot", color: "error"},
    ];

    useEffect(() => {
        let condition = selectedRule?.conditions?.deviceType?.condition;
        if (condition === undefined || condition === "") {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRule, selectedRuleNumber, selectedRule?.conditions?.deviceType?.condition]);

    useEffect(() => {
        let type = flow?.rules[selectedRuleNumber]?.conditions?.deviceType;
        if (selectedRule !== null && type?.condition !== "") {
            setValue("deviceTypeCondition", type?.condition);
            setValue("deviceTypeIncludeEmptyValue", type?.includeEmptyValue);
            setValue("deviceTypeValues", type?.values !== null ? type?.values : []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRuleNumber, selectedRule, flow.rules]);

    const updateCondition = () => {
        props.updateCondition(
            props.campaignFormState.selectedRuleNumber,
            "deviceType",
            getValues("deviceTypeCondition"),
            getValues("deviceTypeIncludeEmptyValue"),
            getValues("deviceTypeValues")
        );
    };

    const removeCondition = () => {
        props.updateCondition(props.campaignFormState.selectedRuleNumber, "deviceType", "", false, null);
    };

    const addCondition = () => {
        props.updateCondition(props.campaignFormState.selectedRuleNumber, "deviceType", "is", false, []);
    };

    const renderAddCondition = () => {
        let condition = selectedRule?.conditions?.deviceType?.condition;
        if (condition === "" || condition === undefined) {
            return (
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1} className={classes?.title}>
                        <span>Device type</span>
                    </Box>
                    <Box>
                        <Button
                            aria-label="condition"
                            color="primary"
                            title="Add condition"
                            startIcon={<Add/>}
                            className={classes?.addConditionButton}
                            onClick={addCondition}
                        >
                            Add condition
                        </Button>
                    </Box>
                </Box>
            );
        }
    };

    const renderConditionBox = () => {
        let condition = selectedRule?.conditions?.deviceType?.condition;
        if (condition !== "" && condition !== undefined) {
            return (
                <>
                    <Box mb={1} display="flex" flexDirection="row">
                        <Box flexGrow={1} className={classes?.title}>Device type</Box>
                        <Box>
                            <IconButton
                                aria-label="remove"
                                color="default"
                                title="Remove"
                                size={"small"}
                                className={classes?.deleteButton}
                                onClick={removeCondition}
                            >
                                <Delete fontSize="small"/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <ReactHookFormRadio
                            defaultValue="is"
                            control={control}
                            options={radioConditions}
                            name="deviceTypeCondition"
                            label="conditions"
                            updateOnChange={updateCondition}
                        />
                    </Box>
                    <Box>
                        <ReactHookFormCheckbox
                            control={control}
                            label="Include empty value"
                            labelPlacement="end"
                            name="deviceTypeIncludeEmptyValue"
                            updateOnChange={updateCondition}
                        />
                    </Box>
                    <Box mt={1}>
                        <DeviceTypeMultiSelect
                            control={control}
                            inputName={"deviceTypeValues"}
                            inputLabel={"Device type"}
                            updateOnChange={updateCondition}
                        />
                    </Box>
                </>
            );
        }
    };

    return (
        <Box display="flex" flexDirection="column" className={classes?.root}>
            {renderAddCondition()}
            {renderConditionBox()}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    updateCondition: campaignFormActions.updateCondition,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceType);