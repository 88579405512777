import {sidebarConstants} from "../constants/sidebarConstants";

export const sidebarActions = {
	handleDrawerClose,
	handleDrawerOpen,
	handleIsLogsOpen
};

function handleDrawerClose() {
	return {type: sidebarConstants.DRAWER_CLOSE};
}

function handleDrawerOpen() {
	return {type: sidebarConstants.DRAWER_OPEN};
}

function handleIsLogsOpen(isOpen) {
	return dispatch => {
		return dispatch({type: sidebarConstants.SIDEBAR_IS_LOGS_OPEN, isOpen});
	}
}