import React, {useEffect, useState} from 'react';
import {TextField, Autocomplete} from '@mui/material';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import PropTypes from "prop-types";
import {workspaceActions} from "../../store/actions/workspaceAction";
import {connect} from "react-redux";

function WorkspaceDropdown(props) {
    const {control, errors, workspaceId, setValue} = props;

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (!open) {
            setSearch("");
        }
    }, [open]);

    useEffect(() => {
        if (parseInt(workspaceId) > 0 && props?.workspace?.userWorkspaces !== null) {
            props?.workspace?.userWorkspaces.forEach((item) => {
                if (workspaceId === item?.workspaceId) {
                    setValue("workspace", {value: item?.workspaceId, label: item?.name});
                }
            });
        }
    }, [props?.workspace?.userWorkspaces, control, workspaceId, setValue])

    useEffect(() => {
        if (props?.workspace?.userWorkspaces !== null) {
            let data = [];
            props?.workspace?.userWorkspaces.forEach((item) => {
                data.push({value: item?.workspaceId, label: item?.name});
            });
            setOptions(data);
        }
    }, [props?.workspace?.userWorkspaces]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="workspace-dropdown"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => {
                        if (value?.value === 0) {
                            return true;
                        }
                        return option.value === value.value
                    }}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    loading={props?.workspace?.loading?.getUserWorkspaces}
                    renderInput={params => (
                        <TextField
                            autoFocus
                            {...params}
                            error={!!errors?.workspace}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            placeholder="Select a workspace"
                            label="Workspace*"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                        />
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={{required: true}}
            name="workspace"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

WorkspaceDropdown.propTypes = {
    setValue: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        workspace: state.workspace
    }
};

const actionCreators = {
    getUserWorkspaces: workspaceActions.getUserWorkspaces
};

export default connect(mapStateToProps, actionCreators)(WorkspaceDropdown);