import {campaignConstants} from "../constants/campaignsConstants";
import {campaignServices} from "../../service/campaignService";
import {countries, currencies} from "../../components/utils/DataList";

export const campaignFormActions = {
    formStep1,
    formStep2,
    formStep3,
    create,
    update,
    resetForm,
    formStepBack,
    updateSelectedPath,
    findCampaign,
    updateTrafficSource,
    updateTrackingDomain,
    updateCloakingDomain,
    updateSelectedRule,
    addRule,
    updateRuleName,
    removeRule,
    updateRuleStatus,
    addRulePath,
    removeRulePath,
    updateRulePathStatus,
    updateRulePathName,
    updateRulePathWeight,
    changeRulePriority,
    updateLogicalRelation,
    addDefaultPath,
    removeDefaultPath,
    updateDefaultPathStatus,
    updateDefaultPathName,
    updateDefaultPathWeight,
    updatePathDirectLinking,
    addLandingToPath,
    removeLandingFromPath,
    updateLandingNameInPath,
    updateLandingWeightInPath,
    addOfferToPath,
    removeOfferFromPath,
    updateOfferNameInPath,
    updateOfferWeightInPath,
    formValidationError,
    updateCondition,
    setFlow,
    updateDestinationType,
    updateConversionActionEvent,
    updateConversionActionPixel,
    updateTrafficSourceIntegration,
    resetConversionActionEventAndPixel
};

function formStep1({trafficSource, costModel, costValue, currency, conversionAction, conversionPixel}) {
    return dispatch => {
        dispatch({
            type: campaignConstants.SET_FORM_STEP_1,
            data: {trafficSource, costModel, costValue, currency, conversionAction, conversionPixel}
        });
    };
}

function formStep2(workspace, name, country, trackingDomain, tags) {
    return dispatch => {
        dispatch({type: campaignConstants.SET_FORM_STEP_2, details: {workspace, name, country, trackingDomain, tags}});
    };
}

function formStep3(destination) {
    return dispatch => {
        dispatch({type: campaignConstants.SET_FORM_STEP_3, destination});
    };
}

function create(campaignData) {
    return dispatch => {
        campaignServices.create(campaignData)
            .then((response) => {
                dispatch({
                    type: campaignConstants.CREATE_CAMPAIGN, result: {
                        id: response?.id,
                        publicId: response?.publicId,
                    }
                });
            }).catch((error) => {
                dispatch({type: campaignConstants.CREATE_CAMPAIGN_ERROR, message: error.response.data.error});
            }
        );
    };
}

function update(id, campaignData) {
    return dispatch => {
        campaignServices.update(id, campaignData)
            .then(() => {
                dispatch({type: campaignConstants.UPDATE_CAMPAIGN});
            }).catch((error) => {
                dispatch({type: campaignConstants.UPDATE_CAMPAIGN_ERROR, message: error.response.data.error});
            }
        );
    };
}

function resetForm() {
    return dispatch => {
        dispatch({type: campaignConstants.RESET_FORM});
    };
}

function formStepBack(step) {
    return dispatch => {
        dispatch({type: campaignConstants.FORM_STEP_BACK, step});
    };
}

function updateSelectedPath(ruleIndex, pathIndex, path) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_SELECTED_PATH, payload: {ruleIndex, pathIndex, path}});
    };
}

function updateTrafficSource(trafficSource) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_TRAFFIC_SOURCE, trafficSource});
    }
}

function updateTrackingDomain(trackingDomain) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_TRACKING_DOMAIN, trackingDomain: {
                value: trackingDomain?.id,
                label: trackingDomain?.domain
            }
        });
    };
}

function updateCloakingDomain(cloakingDomain) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_CLOAKING_DOMAIN, cloakingDomain: {
                value: cloakingDomain?.id,
                label: cloakingDomain?.domain
            }
        });
    };
}

function findCampaign(entity) {
    return dispatch => {

        countries.forEach((item) => {
            if (item.code === entity.countryCode) {
                entity.countryCode = item;
            }
        });

        if (entity?.currencyCode !== "") {
            currencies.forEach((item) => {
                if (item.code === entity.currencyCode) {
                    entity.currencyCode = item;
                }
            });
        }

        dispatch({
            type: campaignConstants.FIND_CAMPAIGN, result: {
                id: entity?.id,
                workspaceId: entity?.workspaceId,
                publicId: entity?.publicId,
                trafficSource: entity?.trafficId,
                costModel: entity?.costModel,
                costValue: entity?.costValue,
                currency: entity?.currencyCode,
                name: entity?.name,
                country: entity?.countryCode,
                conversionActionId: entity?.conversionActionId,
                conversionPixelId: entity?.conversionPixelId,
                trackingDomain: entity?.trackingDomainId,
                cloakingDomain: entity?.cloakingDomainId,
                destinationUrl: entity?.destinationUrl,
                destinationType: entity?.destinationType,
                redirectMode: entity?.redirectMode,
                flow: entity?.flow,
                tags: entity?.tags
            }
        });
    };
}

function updateSelectedRule(ruleIndex, rule) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_SELECTED_RULE, payload: {ruleIndex, rule}});
    };
}

function addRule(priority) {
    return dispatch => {
        dispatch({type: campaignConstants.ADD_RULE, payload: {priority}});
    };
}

function updateRuleName(ruleIndex, name) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_RULE_NAME, payload: {ruleIndex, name}});
    };
}

function removeRule(index) {
    return dispatch => {
        dispatch({type: campaignConstants.REMOVE_RULE, payload: {index}});
    };
}

function updateRuleStatus(checked, index) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_RULE_STATUS, payload: {checked, index}});
    };
}

function addRulePath(index) {
    return dispatch => {
        dispatch({type: campaignConstants.ADD_RULE_PATH, payload: {index}});
    };
}

function removeRulePath(ruleIndex, pathIndex) {
    return dispatch => {
        dispatch({type: campaignConstants.REMOVE_RULE_PATH, payload: {ruleIndex, pathIndex}});
    };
}

function updateRulePathStatus(ruleIndex, pathIndex, checked) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_RULE_PATH_STATUS, payload: {ruleIndex, pathIndex, checked}});
    };
}

function updateRulePathName(ruleIndex, pathIndex, name) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_RULE_PATH_NAME, payload: {ruleIndex, pathIndex, name}});
    };
}

function updateRulePathWeight(ruleIndex, pathIndex, weight) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_RULE_PATH_WEIGHT, payload: {ruleIndex, pathIndex, weight}});
    };
}

function changeRulePriority(ruleIndex, to) {
    return dispatch => {
        dispatch({type: campaignConstants.CHANGE_RULE_PRIORITY, payload: {ruleIndex, to}});
    };
}

function updateLogicalRelation(ruleIndex, logicalRelation) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_RULE_LOGICAL_RELATION, payload: {ruleIndex, logicalRelation}});
    };
}

function addDefaultPath() {
    return dispatch => {
        dispatch({type: campaignConstants.ADD_DEFAULT_PATH});
    };
}

function removeDefaultPath(pathIndex) {
    return dispatch => {
        dispatch({type: campaignConstants.REMOVE_DEFAULT_PATH, payload: {pathIndex}});
    };
}

function updateDefaultPathStatus(pathIndex, checked) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_DEFAULT_PATH_STATUS, payload: {pathIndex, checked}});
    };
}

function updateDefaultPathName(pathIndex, name) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_DEFAULT_PATH_NAME, payload: {pathIndex, name}});
    };
}

function updateDefaultPathWeight(pathIndex, weight) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_DEFAULT_PATH_WEIGHT, payload: {pathIndex, weight}});
    };
}

function updatePathDirectLinking(ruleIndex, pathIndex, directLinking) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_PATH_DIRECT_LINKING, payload: {ruleIndex, pathIndex, directLinking}});
    };
}

function addLandingToPath(ruleIndex, pathIndex) {
    return dispatch => {
        dispatch({type: campaignConstants.ADD_LANDING_TO_PATH, payload: {ruleIndex, pathIndex}});
    };
}

function removeLandingFromPath(ruleIndex, pathIndex, landingIndex) {
    return dispatch => {
        dispatch({type: campaignConstants.REMOVE_LANDING_FROM_PATH, payload: {ruleIndex, pathIndex, landingIndex}});
    };
}

function updateLandingNameInPath(ruleIndex, pathIndex, landingIndex, landingId, name) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_LANDING_NAME_IN_PATH,
            payload: {ruleIndex, pathIndex, landingIndex, landingId, name}
        });
    };
}

function updateLandingWeightInPath(ruleIndex, pathIndex, landingIndex, weight) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_LANDING_WEIGHT_IN_PATH,
            payload: {ruleIndex, pathIndex, landingIndex, weight}
        });
    };
}

function addOfferToPath(ruleIndex, pathIndex) {
    return dispatch => {
        dispatch({type: campaignConstants.ADD_OFFER_TO_PATH, payload: {ruleIndex, pathIndex}});
    };
}

function removeOfferFromPath(ruleIndex, pathIndex, offerIndex) {
    return dispatch => {
        dispatch({type: campaignConstants.REMOVE_OFFER_FROM_PATH, payload: {ruleIndex, pathIndex, offerIndex}});
    };
}

function updateOfferNameInPath(ruleIndex, pathIndex, offerIndex, offerId, name) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_OFFER_NAME_IN_PATH,
            payload: {ruleIndex, pathIndex, offerIndex, offerId, name}
        });
    };
}

function updateOfferWeightInPath(ruleIndex, pathIndex, offerIndex, weight) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_OFFER_WEIGHT_IN_PATH,
            payload: {ruleIndex, pathIndex, offerIndex, weight}
        });
    };
}

function formValidationError(errorMessage) {
    return dispatch => {
        dispatch({type: campaignConstants.FORM_VALIDATION_ERROR, payload: {errorMessage}});
    };
}

function updateCondition(ruleIndex, type, condition, includeEmptyValue, values) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_CONDITION,
            payload: {ruleIndex, type, condition, includeEmptyValue, values}
        });
    };
}

function setFlow(flow) {
    return dispatch => {
        dispatch({type: campaignConstants.SET_FLOW, payload: {flow}});
    };
}

function updateDestinationType(type) {
    return dispatch => {
        dispatch({type: campaignConstants.UPDATE_DESTINATION_TYPE, payload: {type}});
    };
}

function updateConversionActionEvent({id, name}) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_CONVERSION_ACTION_EVENT, event: {id, name}
        });
    };
}

function updateConversionActionPixel({id, name, pixelId}) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_CONVERSION_ACTION_PIXEL, pixel: {id, name, pixelId}
        });
    };
}

function updateTrafficSourceIntegration({integration}) {
    return dispatch => {
        dispatch({
            type: campaignConstants.UPDATE_TRAFFIC_SOURCE_INTEGRATION, integration: integration
        });
    };
}

function resetConversionActionEventAndPixel() {
    return dispatch => {
        dispatch({type: campaignConstants.RESET_CONVERSION_ACTION_AND_PIXEL});
    };
}