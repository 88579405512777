import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import PropTypes from "prop-types";
import {ispServices} from "../../../service/ispService";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {Autocomplete, Checkbox, Chip, TextField} from "@mui/material";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

function ISPMultiSelect({control, errors, inputLabel, inputName, updateOnChange}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await ispServices.read(search);
                setOptions(
                    response?.records.map(item => {
                        return item?.isp;
                    })
                );
            } catch (e) {
                console.log("Error: ", e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        if (open && (search?.length === 0 || search?.length > 0)) {
            fetchData();
        }

    }, [search, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="isp-multi-select"
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    multiple
                    freeSolo
                    disableCloseOnSelect={true}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => option}
                    options={options}
                    loading={loading}
                    renderOption={(props, option, {selected, index}) => (
                        <li {...props} key={index}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1}}
                                checked={selected}
                                color="primary"
                            />
                            {option}
                        </li>
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" color="primary" size="small"
                                  label={option} {...getTagProps({index})} />
                        ))
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            label={inputLabel}
                            value={search}
                            onChange={e => handleSearch(e?.target?.value)}
                        />
                    )}
                    onChange={(e, data) => {
                        onChange(data);
                        updateOnChange();
                    }}
                    {...props}
                />
            )}
            defaultValue={[]}
            name={inputName}
            control={control}
            onChange={([, data]) => data}
        />
    );
}

ISPMultiSelect.propTypes = {
    control: PropTypes.object.isRequired,
    inputLabel: PropTypes.string,
    inputName: PropTypes.string,
    updateOnChange: PropTypes.func
};

ISPMultiSelect.defaultProps = {
    control: {},
    inputName: "ISP",
    inputLabel: "Select ISP",
    updateOnChange: () => null,
};

export default ISPMultiSelect;