import React from 'react';
import {Controller} from "react-hook-form";
import {useEffect} from "react";
import {TextField} from "@mui/material";

export default function ReactHookFormTextField(
    {name, type, register, filters, label, setValue, placeholder, control, defaultValue, rules, errors}
) {
    useEffect(() => {
        if (filters?.length > 0) {
            // eslint-disable-next-line array-callback-return
            filters?.filter((metric) => {
                if (metric.hasOwnProperty(name)) {
                    setValue(name, metric[name])
                }
            });
        }
    }, [control, filters, name, setValue]);

    return (
        <Controller
            render={() => (
                <TextField
                    error={!!errors.hasOwnProperty(name)}
                    type={type}
                    size="small"
                    variant="outlined"
                    label={label}
                    placeholder={placeholder}
                    fullWidth
                    {...register(name, rules)}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
}
