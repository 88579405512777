import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle, TextField, Alert, Box
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import React, {useEffect, useState} from "react";
import {sharedAffiliateNetworkService} from "../../service/sharedAffiliateNetworkService";
import {useTheme} from "@mui/material/styles";

export const AffiliateNetworkTemplates = ({handleClose, handleSelectedTemplate}) => {

    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [requestError, setRequestError] = useState("");

    const [templates, setTemplates] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const filteredTemps = !searchQuery
        ? templates
        : templates?.filter(item =>
            item?.name?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );

    useEffect(() => {
        async function fetchData() {
            setRequestError("");
            setLoading(true);
            await sharedAffiliateNetworkService.read().then((response) => {
                if (response !== null && response !== undefined) {
                    setTemplates(response?.sharedAffiliateNetworks);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (templates === null) {
            fetchData();
        }
    }, [templates]);

    return (
        <>
            <DialogTitle sx={{textAlign: "center"}}>Choose your affiliate network</DialogTitle>
            <DialogContent sx={{
                [theme.breakpoints.up(959)]: {
                    minHeight: "calc(100vh - 208px)"
                }
            }}>
                {loading && <Spinner overComponentBox={true}/>}

                {requestError.length > 1 && <Alert variant="outlined" severity="error">{requestError}</Alert>}

                <Box display="flex" flexWrap="wrap" justifyContent="center" mt={1}>

                    <TextField
                        variant="outlined"
                        margin="dense"
                        name="affiliate-network"
                        placeholder="Search..."
                        type="text"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={searchQuery}
                        onChange={handleChange}
                    />

                    {filteredTemps?.map((item, index) => {
                        return (
                            <Box key={index} flexGrow={0}>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => handleSelectedTemplate(item)}
                                    sx={{
                                        width: "180px",
                                        height: "56px",
                                        margin: "6px",
                                        backgroundColor: theme.palette.common.white,
                                        textTransform: "none",
                                        [theme.breakpoints.down(500)]: {
                                            width: "140px",
                                            height: "56px",
                                        }
                                    }}
                                >
                                    {item?.logoUrl !== ""
                                        ? <img
                                            src={item?.logoUrl}
                                            alt={item?.name}
                                            style={{
                                                maxWidth: "75%",
                                                maxHeight: "84%"
                                            }}
                                        />
                                        : item?.name
                                    }
                                </Button>
                            </Box>
                        );
                    })}
                    {filteredTemps?.length < 1 &&
                        <Box display="flex" flexDirection="row" justifyContent="center">
                            <Box p={1}>No records found.</Box>
                            <Box p={1}>
                                <Button
                                    onClick={() => handleSelectedTemplate(null)}
                                    color="primary"
                                    variant="contained"
                                >
                                    Create Custom
                                </Button>
                            </Box>
                        </Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={() => handleSelectedTemplate(null)} color="primary" variant="contained">
                    Create Custom
                </Button>
            </DialogActions>
        </>
    );
};