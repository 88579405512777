import {landingProtectionConstants} from "../constants/landingProtectionConstants";

export const landingProtectionActions = {
    updateLoading,
    updateToken
};

function updateLoading(loading) {
    return (dispatch) => {
        dispatch({type: landingProtectionConstants.LANDING_PROTECTION_LOADING_SPINNER, loading});
    };
}

function updateToken(token) {
    return (dispatch) => {
        dispatch({type: landingProtectionConstants.LANDING_PROTECTION_SET_TOKEN, token});
    };
}
