import {
    Box,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    InputBase,
    Radio,
    Switch,
    TextField, useTheme
} from "@mui/material";
import React from "react";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {isNumber} from "chart.js/helpers";
import {Add, Delete} from "@mui/icons-material";
import useCustomStyles from "../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.darkBlue,
        borderRadius: 4,
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
    button: {
        textTransform: "none",
        padding: "2px 8px"
    },
    deleteButton: {
        color: theme.palette.error.dark
    },
    pathHeader: {
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },
    pathTitle: {
        fontSize: 14,
        fontWeight: "bold"
    },
    disabledPathTitle: {
        color: "#656a6e",
        "&:hover": {
            backgroundColor: theme.palette.background.input,
            borderRadius: 4
        }
    },
    paths: {
        //height: 42,
        marginBottom: 2,
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 4,
        "&:hover": {
            backgroundColor: theme.palette.background.paper
        }
    },
    margin: {
        "&:hover": {
            backgroundColor: theme.palette.background.input,
            borderRadius: 4
        }
    },
    inputWeight: {
        fontSize: 14,
        height: 10
    },
    inputWeightPercent: {
        paddingLeft: 6,
        fontSize: 14,
        color: theme.palette.text.disabledLight
    }
});

function DefaultPaths(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const activeRowColor = "rgb(18, 29, 39)";

    const {flow, selectedRuleNumber, selectedPathNumber, selectedRule} = props.campaignFormState;
    const maxPaths = 10;

    const updateDefaultPathWeight = (pathIndex, weight) => {
        if (isNumber(weight)) {
            weight = parseInt(weight);
        } else {
            weight = "";
        }
        props.updateDefaultPathWeight(pathIndex, weight);
    };

    const updateDefaultPathStatus = (pathIndex, checked) => {
        props.updateDefaultPathStatus(pathIndex, checked);
    };

    const updateDefaultPathName = (pathIndex, name) => {
        props.updateDefaultPathName(pathIndex, name);
    };

    const removeDefaultPath = (pathIndex) => {
        props.removeDefaultPath(pathIndex);
    };

    const addDefaultPath = () => {
        props.addDefaultPath();
    };

    const updateSelectedPath = (pathIndex, path) => {
        props.updateSelectedPath(0, pathIndex, path);
    };

    return (
        <div className={classes?.root}>
            <Box display="flex" className={classes?.pathHeader}>
                <Box flexGrow={1}>
                    <div className={classes?.pathTitle}>Default paths {flow?.rules[0].paths?.length}/10</div>
                </Box>
                <Box>
                    {flow?.rules[0].paths?.length < maxPaths &&
                        <Button color="primary" startIcon={<Add/>} onClick={() => addDefaultPath()}
                                className={classes?.button}>
                            Add path
                        </Button>
                    }
                </Box>
            </Box>
            {
                flow?.rules[0]?.paths?.map((path, pathIndex) => {
                    return (
                        <Box display="flex" flexWrap="nowrap" className={classes?.paths} key={pathIndex}
                             bgcolor={(selectedRuleNumber === 0 && selectedPathNumber === pathIndex) ? activeRowColor : ""}>
                            <Box>
                                <Radio
                                    checked={(selectedRuleNumber === 0 && selectedPathNumber === pathIndex && selectedRule === null)}
                                    onChange={() => updateSelectedPath(pathIndex, path)}
                                    size="small"
                                    name="selected-radio-button"
                                    inputProps={{'aria-label': 'Selected'}}
                                    color="primary"
                                />
                            </Box>
                            <Box ml={1} flexGrow={1} onClick={() => updateSelectedPath(pathIndex, path)}>
                                <InputBase
                                    sx={{maxWidth: 200}}
                                    className={!path?.status ? classes?.disabledPathTitle : classes?.margin}
                                    value={path?.name}
                                    name={`flow.rules[0].paths[${pathIndex}].name`}
                                    inputProps={{'aria-label': 'naked'}}
                                    onChange={(e) => updateDefaultPathName(pathIndex, e.target.value)}
                                />
                            </Box>
                            <Box mr={1}>
                                <TextField
                                    sx={{maxWidth: 110, marginTop: "4px", fontSize: "14px"}}
                                    label="Weight"
                                    variant="outlined"
                                    margin="dense"
                                    type="text"
                                    size="small"
                                    value={path?.weight}
                                    onChange={e => updateDefaultPathWeight(pathIndex, e.target.value)}
                                    InputProps={{
                                        classes: {input: classes?.inputWeight},
                                        endAdornment: <InputAdornment position="end">
                                            <Divider className={classes?.divider} orientation="vertical"/>
                                            <span className={classes?.inputWeightPercent}>
                                                ({props.getCurrentWeight(path.weight, flow.rules[0].paths)}%)
                                            </span>
                                        </InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            {
                                flow?.rules[0]?.paths?.length > 1 &&
                                <Box display="flex">
                                    <Box sx={{paddingTop: "1px"}} mr={1}>
                                        <Switch
                                            checked={path?.status}
                                            onChange={(e, checked) => updateDefaultPathStatus(pathIndex, checked)}
                                            name={`flow.rules[0].paths[${pathIndex}].status`}
                                            color="primary"
                                            size="small"
                                        />
                                    </Box>
                                    <Box>
                                        <IconButton
                                            aria-label="delete"
                                            color="default"
                                            title="Delete"
                                            size={"small"}
                                            sx={{paddingTop: "3px", marginRight: "6px"}}
                                            onClick={() => removeDefaultPath(pathIndex)}
                                        >
                                            <Delete fontSize="small" className={classes?.deleteButton}/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    );
                })
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    updateDefaultPathStatus: campaignFormActions.updateDefaultPathStatus,
    updateDefaultPathName: campaignFormActions.updateDefaultPathName,
    updateDefaultPathWeight: campaignFormActions.updateDefaultPathWeight,
    removeDefaultPath: campaignFormActions.removeDefaultPath,
    addDefaultPath: campaignFormActions.addDefaultPath,
    updateSelectedPath: campaignFormActions.updateSelectedPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPaths);