import {campaignConstants} from "../constants/campaignsConstants";

const initialState = {
    id: 0,
    publicId: null,
    trafficSource: null,
    costModel: "auto",
    costValue: "",
    currency: {code: "EUR", name: "Euro", symbol: "€"},
    integration: null,
    conversionPixel: null,
    conversionAction: null,
    conversionActionId: 0,
    conversionPixelId: 0,
    workspace: null,
    workspaceId: 0,
    name: "",
    country: {code: "", label: "Global", phone: ""},
    trackingDomain: null,
    cloakingDomain: null,
    tags: [],
    destinationUrl: "",
    destinationType: "flow",
    redirectMode: "302",
    flow: {
        rules: [
            {
                name: "Default",
                default: true,
                priority: 0,
                status: true,
                logicalRelation: "",
                conditions: {},
                paths: [
                    {
                        name: "New path",
                        directLinking: false,
                        status: true,
                        weight: 100,
                        landings: [
                            {
                                landingId: 0,
                                name: "",
                                weight: 100
                            }
                        ],
                        offers: [
                            {
                                offerId: 0,
                                name: "",
                                weight: 100
                            }
                        ]
                    }
                ],
            }
        ]
    },
    activeStep: 0,
    isLoading: false,
    errorMsg: "",
    selectedRuleNumber: 0,
    selectedRule: null,
    selectedPathNumber: 0,
    selectedPath: null,
};

const campaignFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case campaignConstants.SET_FORM_STEP_1:
            return {
                ...state,
                trafficSource: action.data.trafficSource,
                costModel: action.data.costModel,
                costValue: action.data.costValue,
                currency: action.data.currency,
                conversionAction: action.data.conversionAction,
                conversionPixel: action.data.conversionPixel,
                activeStep: 1
            };
        case campaignConstants.SET_FORM_STEP_2:
            return {
                ...state,
                workspace: action.details.workspace,
                name: action.details.name,
                country: action.details.country,
                trackingDomain: action.details.trackingDomain,
                tags: action.details.tags,
                activeStep: 2
            };
        case campaignConstants.SET_FORM_STEP_3:
            if (action.destination.hasOwnProperty("destinationType") && action.destination.destinationType === "url") {
                return {
                    ...state,
                    destinationType: action.destination.destinationType,
                    destinationUrl: action.destination.destinationUrl,
                    redirectMode: action.destination.redirectMode,
                    cloakingDomain: action.destination.cloakingDomain,
                    isLoading: true,
                    errorMsg: ""
                };
            }
            return {
                ...state,
                destinationType: action.destination.destinationType,
                redirectMode: action.destination.redirectMode,
                cloakingDomain: action.destination.cloakingDomain,
                isLoading: true,
                errorMsg: ""
            };
        case campaignConstants.UPDATE_SELECTED_PATH:
            return {
                ...state,
                selectedRuleNumber: action.payload.ruleIndex,
                selectedPathNumber: action.payload.pathIndex,
                selectedPath: action.payload.path,
                selectedRule: null,
            };
        case campaignConstants.CREATE_CAMPAIGN:
            return {
                ...state,
                id: action.result?.id,
                publicId: action.result?.publicId,
                isLoading: false,
                activeStep: 3,
                errorMsg: ""
            };
        case campaignConstants.CREATE_CAMPAIGN_ERROR:
            return {
                ...state,
                errorMsg: 'Creating a campaign failed. ' + action.message,
                isLoading: false
            };
        case campaignConstants.UPDATE_CAMPAIGN:
            return {
                ...state,
                isLoading: false,
                activeStep: 3,
                errorMsg: ""
            };
        case campaignConstants.UPDATE_CAMPAIGN_ERROR:
            return {
                ...state,
                errorMsg: 'Updating a campaign failed. ' + action.message,
                isLoading: false
            };
        case campaignConstants.FORM_STEP_BACK:
            return {
                ...state,
                activeStep: action.step,
                errorMsg: ""
            };
        case campaignConstants.UPDATE_TRAFFIC_SOURCE:
            return {
                ...state,
                trafficSource: action.trafficSource
            };
        case campaignConstants.UPDATE_TRACKING_DOMAIN:
            return {
                ...state,
                trackingDomain: action.trackingDomain,
            };
        case campaignConstants.UPDATE_CLOAKING_DOMAIN:
            return {
                ...state,
                cloakingDomain: action.cloakingDomain,
            };
        case campaignConstants.FIND_CAMPAIGN:
            return {
                ...state,
                id: action.result.id,
                workspaceId: action.result.workspaceId,
                publicId: action.result.publicId,
                trafficSource: action.result.trafficSource,
                costModel: action.result.costModel,
                costValue: action.result.costValue,
                currency: action.result.currency,
                name: action.result.name,
                country: action.result.country,
                conversionActionId: action.result.conversionActionId,
                conversionPixelId: action.result.conversionPixelId,
                destinationUrl: action.result.destinationUrl,
                destinationType: action.result.destinationType,
                redirectMode: action.result.redirectMode,
                trackingDomain: action.result.trackingDomain,
                cloakingDomain: action.result.cloakingDomain,
                flow: action.result.destinationType === "flow" ? action.result.flow : initialState.flow,
                tags: action.result.tags,
                activeStep: 0,
                isLoading: false,
                errorMsg: "",
                selectedPathNumber: 0,
                selectedPath: null
            };
        case campaignConstants.FIND_CAMPAIGN_ERROR:
            return {
                ...state,
                errorMsg: 'Finding a campaign failed. ' + action.message,
                isLoading: false
            };
        case campaignConstants.RESET_FORM:
            initialState.flow = {
                rules: [
                    {
                        name: "Default",
                        default: true,
                        priority: 0,
                        status: true,
                        logicalRelation: "",
                        conditions: {},
                        paths: [
                            {
                                name: "New path",
                                directLinking: false,
                                status: true,
                                weight: 100,
                                landings: [
                                    {
                                        landingId: 0,
                                        name: "",
                                        weight: 100
                                    }
                                ],
                                offers: [
                                    {
                                        offerId: 0,
                                        name: "",
                                        weight: 100
                                    }
                                ]
                            }
                        ],
                    }
                ]
            };
            return initialState;
        case campaignConstants.UPDATE_SELECTED_RULE:
            return {
                ...state,
                selectedRuleNumber: action.payload.ruleIndex,
                selectedRule: action.payload.rule,
                selectedPathNumber: 0,
                selectedPath: null
            };
        case campaignConstants.ADD_RULE:
            const newRule = {
                name: "#" + action.payload.priority + " Rule",
                default: false,
                priority: action.payload.priority,
                status: true,
                logicalRelation: "and",
                conditions: {},
                paths: [
                    {
                        name: "New path",
                        directLinking: false,
                        status: true,
                        weight: 100,
                        landings: [
                            {
                                landingId: 0,
                                name: "",
                                weight: 100
                            }
                        ],
                        offers: [
                            {
                                offerId: 0,
                                name: "",
                                weight: 100
                            }
                        ]
                    }
                ],
            };

            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: [
                        ...state.flow.rules,
                        newRule
                    ],
                },
                selectedRuleNumber: state.flow.rules.length,
                selectedRule: newRule,
                selectedPath: null,
                selectedPathNumber: 0
            };
        case campaignConstants.UPDATE_RULE_NAME:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (action.payload.ruleIndex === ruleIndex) {
                            rule.name = action.payload.name;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.REMOVE_RULE:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: [...state.flow.rules.slice(0, action.payload.index), ...state.flow.rules.slice(action.payload.index + 1)]
                }
            };
        case campaignConstants.UPDATE_RULE_STATUS:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((item, index) => {
                        if (action.payload.index === index) {
                            item.status = action.payload.checked;
                            return item;
                        }
                        return item;
                    })
                }
            };
        case campaignConstants.ADD_RULE_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (action.payload.index === ruleIndex) {
                            rule.paths = [
                                ...rule.paths,
                                {
                                    name: "New path",
                                    directLinking: false,
                                    status: true,
                                    weight: 100,
                                    landings: [
                                        {
                                            landingId: 0,
                                            name: "",
                                            weight: 100
                                        }
                                    ],
                                    offers: [
                                        {
                                            offerId: 0,
                                            name: "",
                                            weight: 100
                                        }
                                    ]
                                }
                            ]
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.REMOVE_RULE_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (action.payload.ruleIndex === ruleIndex) {
                            rule.paths = [
                                ...rule.paths.slice(0, action.payload.pathIndex),
                                ...rule.paths.slice(action.payload.pathIndex + 1)
                            ];
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_RULE_PATH_STATUS:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (action.payload.ruleIndex === ruleIndex) {
                            rule.paths[action.payload.pathIndex].status = action.payload.checked;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_RULE_PATH_NAME:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (action.payload.ruleIndex === ruleIndex) {
                            rule.paths[action.payload.pathIndex].name = action.payload.name;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_RULE_PATH_WEIGHT:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (action.payload.ruleIndex === ruleIndex) {
                            rule.paths[action.payload.pathIndex].weight = action.payload.weight;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_RULE_LOGICAL_RELATION:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (action.payload.ruleIndex === ruleIndex) {
                            rule.logicalRelation = action.payload.logicalRelation;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.ADD_DEFAULT_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === 0) {
                            rule.paths = [
                                ...rule.paths,
                                {
                                    name: "New path",
                                    directLinking: false,
                                    status: true,
                                    weight: 100,
                                    landings: [
                                        {
                                            landingId: 0,
                                            name: "",
                                            weight: 100
                                        }
                                    ],
                                    offers: [
                                        {
                                            offerId: 0,
                                            name: "",
                                            weight: 100
                                        }
                                    ]
                                }
                            ]
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.REMOVE_DEFAULT_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === 0) {
                            rule.paths = [
                                ...rule.paths.slice(0, action.payload.pathIndex),
                                ...rule.paths.slice(action.payload.pathIndex + 1)
                            ];
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_DEFAULT_PATH_STATUS:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === 0) {
                            rule.paths[action.payload.pathIndex].status = action.payload.checked;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_DEFAULT_PATH_NAME:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === 0) {
                            rule.paths[action.payload.pathIndex].name = action.payload.name;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_DEFAULT_PATH_WEIGHT:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === 0) {
                            rule.paths[action.payload.pathIndex].weight = action.payload.weight;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_PATH_DIRECT_LINKING:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].directLinking = action.payload.directLinking;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_LANDING_WEIGHT_IN_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].landings[action.payload.landingIndex].weight = action.payload.weight;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_LANDING_NAME_IN_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].landings[action.payload.landingIndex].landingId = action.payload.landingId;
                            rule.paths[action.payload.pathIndex].landings[action.payload.landingIndex].name = action.payload.name;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.REMOVE_LANDING_FROM_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].landings = [
                                ...rule.paths[action.payload.pathIndex].landings.slice(0, action.payload.landingIndex),
                                ...rule.paths[action.payload.pathIndex].landings.slice(action.payload.landingIndex + 1)
                            ];
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.ADD_LANDING_TO_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].landings = [
                                ...rule.paths[action.payload.pathIndex].landings,
                                {
                                    landingId: 0,
                                    name: "",
                                    weight: 100
                                }
                            ]
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_OFFER_WEIGHT_IN_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].offers[action.payload.offerIndex].weight = action.payload.weight;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_OFFER_NAME_IN_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].offers[action.payload.offerIndex].offerId = action.payload.offerId;
                            rule.paths[action.payload.pathIndex].offers[action.payload.offerIndex].name = action.payload.name;
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.REMOVE_OFFER_FROM_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].offers = [
                                ...rule.paths[action.payload.pathIndex].offers.slice(0, action.payload.offerIndex),
                                ...rule.paths[action.payload.pathIndex].offers.slice(action.payload.offerIndex + 1)
                            ];
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.ADD_OFFER_TO_PATH:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            rule.paths[action.payload.pathIndex].offers = [
                                ...rule.paths[action.payload.pathIndex].offers,
                                {
                                    offerId: 0,
                                    name: "",
                                    weight: 100
                                }
                            ]
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.UPDATE_CONDITION:
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: state.flow.rules.map((rule, ruleIndex) => {
                        if (ruleIndex === action.payload.ruleIndex) {
                            let add = true;
                            for (const [key] of Object.entries(rule.conditions)) {
                                if (key === action.payload.type) {
                                    add = false;
                                }
                            }

                            if (add) {
                                rule.conditions = {
                                    ...rule.conditions,
                                    [action.payload.type]: {
                                        condition: action.payload.condition,
                                        includeEmptyValue: action.payload.includeEmptyValue,
                                        values: action.payload.values
                                    }
                                }
                            } else {
                                rule.conditions[action.payload.type].condition = action.payload.condition;
                                rule.conditions[action.payload.type].includeEmptyValue = action.payload.includeEmptyValue;
                                rule.conditions[action.payload.type].values = action.payload.values;
                            }
                        }
                        return rule;
                    })
                }
            };
        case campaignConstants.CHANGE_RULE_PRIORITY:
            const clonedRules = [...state.flow.rules];
            const clonedRule = clonedRules[action.payload.ruleIndex];
            clonedRules.splice(action.payload.ruleIndex, 1);
            clonedRules.splice(action.payload.ruleIndex + action.payload.to, 0, clonedRule);
            clonedRules.forEach((e, i) => e.priority = (i + 1));
            return {
                ...state,
                flow: {
                    ...state.flow,
                    rules: clonedRules
                }
            };
        case campaignConstants.FORM_VALIDATION_ERROR:
            return {
                ...state,
                errorMsg: action.payload.errorMessage
            };
        case campaignConstants.SET_FLOW:
            return {
                ...state,
                flow: action.payload.flow
            };
        case campaignConstants.UPDATE_DESTINATION_TYPE:
            return {
                ...state,
                destinationType: action.payload.type
            };
        case campaignConstants.UPDATE_CONVERSION_ACTION_EVENT:
            return {
                ...state,
                conversionAction: {id: action?.event?.id, name: action?.event?.name}
            };
        case campaignConstants.UPDATE_CONVERSION_ACTION_PIXEL:
            return {
                ...state,
                conversionPixel: {id: action?.pixel?.id, name: action?.pixel?.name, pixelId: action?.pixel?.pixelId}
            };
        case campaignConstants.UPDATE_TRAFFIC_SOURCE_INTEGRATION:
            return {
                ...state,
                integration: action.integration,
            };
        case campaignConstants.RESET_CONVERSION_ACTION_AND_PIXEL:
            return {
                ...state,
                conversionAction: null,
                conversionPixel: null,
                conversionActionId: 0,
                conversionPixelId: 0
            };
        default:
            return state;
    }
};

export default campaignFormReducer;