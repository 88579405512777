import {userSettingsConstants} from "../constants/userSettingsConstants";
import {postbackDomain} from "../../helpers/config";

const initialState = {
    timezone: "Etc/UTC",
    currencyCode: "EUR",
    defaultTrackingDomain: postbackDomain,
    role: "",
    loaded: false
};

const userSettingsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case userSettingsConstants.USER_SETTINGS_UPDATE:
            return {
                ...state,
                timezone: action?.response?.timezone,
                currencyCode: action?.response?.currencyCode,
                defaultTrackingDomain: action?.response?.defaultTrackingDomain,
                role: action?.response?.role,
                loaded: true
            };
        default:
            return state;
    }
};

export default userSettingsReducer;