import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import {integrationService} from "../../service/integrationService";
import {Autocomplete, TextField} from "@mui/material";

function IntegrationDropdown({control, networkTypes = null, name = "integration", label = "Integration*", error, setRequestError}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && options?.length === 0) {
            setLoading(true);
            integrationService.getIntegrations({category: "server"}).then((result) => {

                let modResults = [];
                if (networkTypes !== null) {
                    // eslint-disable-next-line array-callback-return
                    result.filter(i => {
                        if (networkTypes?.includes(i?.networkType)) {
                            modResults.push({
                                value: i?.id,
                                label: i?.networkName,
                                networkType: i?.networkType
                            });
                        }
                    });
                } else {
                    // eslint-disable-next-line array-callback-return
                    result.map(i => {
                        modResults.push({
                            value: i?.id,
                            label: i?.networkName,
                            networkType: i?.networkType
                        });
                    });
                }

                setOptions(modResults);
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error?.response?.data?.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, setRequestError]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id={name + "-select"}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => {
                        if (value?.value === 0) {
                            return true;
                        }
                        return option.value === value.value
                    }}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={error}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            placeholder="Choose an integration"
                            label={label}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                        />
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={{required: true}}
            name={name}
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

export default IntegrationDropdown;