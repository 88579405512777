export const trackingDomainConstants = {
    LOADING_TRACKING_DOMAINS: 'LOADING_TRACKING_DOMAINS',
    LOADING_TRACKING_DOMAINS_ERROR: 'LOADING_TRACKING_DOMAINS_ERROR',
    TRACKING_DOMAIN_SET_IS_LOADING: 'TRACKING_DOMAIN_SET_IS_LOADING',
    DELETE_TRACKING_DOMAIN: 'DELETE_TRACKING_DOMAIN',
    DELETE_TRACKING_DOMAIN_ERROR: 'DELETE_TRACKING_DOMAIN_ERROR',
    TRACKING_DOMAIN_CLEAR_ERRORS: 'TRACKING_DOMAIN_CLEAR_ERRORS',
    ADD_TRACKING_DOMAIN: 'ADD_TRACKING_DOMAIN',
    ADD_TRACKING_DOMAIN_ERROR: 'ADD_TRACKING_DOMAIN_ERROR',
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
    SET_TRACKING_DOMAIN: 'SET_TRACKING_DOMAIN',
    SET_NEW_DEFAULT_DOMAIN: 'SET_NEW_DEFAULT_DOMAIN'
};
