import {createTheme} from "@mui/material/styles";

const rowHover = "rgb(55, 69, 82)";
const input = "rgb(38, 53, 67)";
const darkBlue = "rgb(6, 13, 19)";
const tableOdd = "rgb(14,24,32)";
const tableSelectedRow = "#1d304a";
const dividerBorder = "rgb(38, 45, 52)";
const mainBackground = "rgb(18, 29, 39)";
const textPrimary = "rgba(255, 255, 255, 0.87)";
const primaryMain = "rgba(68, 199, 103, 1)";
const navbarHover = "rgba(255, 255, 255, 0.08)";
const white = "rgba(255, 255, 255, 0.87)";
const disabledDark = "rgb(255, 255, 255, 0.3)"

const theme = createTheme({
    palette: {
        type: 'dark',
        common: {
            black: "#000",
            white: "rgba(255, 255, 255, 0.87)"
        },
        background: {
            paper: mainBackground,
            default: "rgba(255, 255, 255, 1)",
            hover: "rgba(6, 13, 19, 0.7)",
            defaultBlue: "rgb(18, 29, 39)",
            darkBlue: darkBlue,
            input: input,
            rowHover: "rgba(77, 131, 225, 0.1)",
            tableOdd: tableOdd,
            tableRowHover: rowHover,
            tableBorder: dividerBorder,
            tableSelectedRow: tableSelectedRow,
            navbarHover: navbarHover
        },
        border: {
            icon: dividerBorder,
            button: dividerBorder
        },
        primary: {
            light: "rgba(106, 206, 133, 1)",
            main: primaryMain,
            dark: "rgba(59, 172, 89, 1)",
            contrastText: "rgba(245, 245, 245, 1)",
            white: white
        },
        secondary: {
            light: "rgb(35,140,246, 1)",
            main: "rgba(0, 119, 246, 1)",
            dark: "rgb(0, 80, 175, 1)",
            contrastText: "#fff"
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        text: {
            primary: textPrimary,
            secondary: "rgba(195, 195, 195, 1)",
            disabled: "rgba(0, 0, 0, 0.38)",
            disabledLight: "rgb(138, 147, 159)",
            disabledDark: "rgb(255, 255, 255, 0.3)",
            hint: "rgba(0, 0, 0, 0.38)",
            tableHeader: "rgb(138, 147, 159)",
            darkBlue: "rgb(18, 29, 39)",
            noticeDark: "rgb(255, 255, 255, 0.5)",
        },
        action: {
            selected: "rgb(18, 29, 39)"
        },
        icon: {
            yellow: "rgb(255,202,67)"
        }
    },
    divider: {
        main: dividerBorder,
    },
    toolTipHelp: {
        marginLeft: 6,
        fontSize: 18,
        position: "relative",
        cursor: "pointer"
    },
    typography: {
        fontFamily: `'Rubik', sans-serif !important`
    },
    defaultDataGrid: {
        "@media (prefers-color-scheme: light)": {
            "--rdg-color-scheme": "dark",
            "--rdg-color": "#ddd",
            "--rdg-border-color": dividerBorder,
            "--rdg-summary-border-color": "#555",
            "--rdg-background-color": mainBackground,
            "--rdg-header-background-color": darkBlue,
            "--rdg-header-draggable-background-color": darkBlue,
            "--rdg-row-hover-background-color": rowHover,
            "--rdg-row-selected-background-color": tableSelectedRow,
            "--rdg-row-selected-hover-background-color": rowHover,
            "--rdg-checkbox-color": primaryMain,
            "--rdg-checkbox-focus-color": dividerBorder,
            "--rdg-checkbox-disabled-border-color": dividerBorder,
            "--rdg-checkbox-disabled-background-color": dividerBorder
        },
        "@media (prefers-color-scheme: dark)": {
            "--rdg-color-scheme": "dark",
            "--rdg-color": "#ddd",
            "--rdg-border-color": dividerBorder,
            "--rdg-summary-border-color": "#555",
            "--rdg-background-color": mainBackground,
            "--rdg-header-background-color": darkBlue,
            "--rdg-header-draggable-background-color": darkBlue,
            "--rdg-row-hover-background-color": rowHover,
            "--rdg-row-selected-background-color": tableSelectedRow,
            "--rdg-row-selected-hover-background-color": rowHover,
            "--rdg-checkbox-color": primaryMain,
            "--rdg-checkbox-focus-color": dividerBorder,
            "--rdg-checkbox-disabled-border-color": dividerBorder,
            "--rdg-checkbox-disabled-background-color": dividerBorder
        },
        height: "100%",
        backgroundColor: mainBackground,
        border: "none",
        userSelect: "auto",
        "& div.rdg-header-row": {
            userSelect: "none",
            fontWeight: 500,
            fontSize: 13,
            "& ::after": {
                width: 8,
                cursor: "col-resize",
                backgroundColor: dividerBorder,
                position: "absolute",
                right: 0
            },
            "& .rdg-cell": {
                borderRight: "1px solid " + dividerBorder,
                "&:nth-child(1),:nth-child(2)": {
                    borderRight: 0
                }
            },
            "& .rdg-cell-resizable .rdg-sort-arrow": {
                marginRight: 8,
                marginLeft: 8,
            },
            "& .rdg-cell-resizable > div:nth-child(2)": {
                width: "10px",
                "&:hover": {
                    borderRight: "10px solid " + dividerBorder,
                }
            },
            "& .rdg-cell-drag-over": {
                backgroundColor: primaryMain
            }
        },
        "& div.rdg-checkbox-label": {
            height: 34,
        },
        "& div.rdg-cell": {
            boxShadow: "none",
            border: "none",
            borderBottom: "1px solid " + dividerBorder,
            padding: "0 8px",
            "&[aria-selected=true]": {
                outline: 0
            }
        },
        "& div.rdg-row.rdg-row-even": {
            "&:hover": {
                cursor: "pointer"
            }
        },
        "& div.rdg-row.rdg-row-odd": {
            backgroundColor: tableOdd,
            "&:hover": {
                backgroundColor: rowHover,
                cursor: "pointer"
            },
            "&[aria-selected=true]": {
                backgroundColor: tableSelectedRow,
                "&:hover": {
                    backgroundColor: rowHover,
                }
            }
        },
        "& div.rdg-row.rdg-summary-row": {
            backgroundColor: darkBlue
        }
    },
    clicksReportDataGrid: {
        "@media (prefers-color-scheme: dark)": {
            "--rdg-color-scheme": "dark",
            "--rdg-color": "#ddd",
            "--rdg-border-color": dividerBorder,
            "--rdg-summary-border-color": "#555",
            "--rdg-background-color": mainBackground,
            "--rdg-header-background-color": darkBlue,
            "--rdg-header-draggable-background-color": darkBlue,
            "--rdg-row-hover-background-color": rowHover,
            "--rdg-row-selected-background-color": tableSelectedRow,
            "--rdg-row-selected-hover-background-color": rowHover,
            "--rdg-checkbox-color": primaryMain,
            "--rdg-checkbox-focus-color": dividerBorder,
            "--rdg-checkbox-disabled-border-color": dividerBorder,
            "--rdg-checkbox-disabled-background-color": dividerBorder
        },
        "@media (prefers-color-scheme: light)": {
            "--rdg-color-scheme": "dark",
            "--rdg-color": "#ddd",
            "--rdg-border-color": dividerBorder,
            "--rdg-summary-border-color": "#555",
            "--rdg-background-color": mainBackground,
            "--rdg-header-background-color": darkBlue,
            "--rdg-header-draggable-background-color": darkBlue,
            "--rdg-row-hover-background-color": rowHover,
            "--rdg-row-selected-background-color": tableSelectedRow,
            "--rdg-row-selected-hover-background-color": rowHover,
            "--rdg-checkbox-color": primaryMain,
            "--rdg-checkbox-focus-color": dividerBorder,
            "--rdg-checkbox-disabled-border-color": dividerBorder,
            "--rdg-checkbox-disabled-background-color": dividerBorder
        },
        height: "100%",
        backgroundColor: mainBackground,
        border: "none",
        userSelect: "auto",
        "& div.rdg-header-row": {
            userSelect: "none",
            fontWeight: 500,
            fontSize: 13,
            "& ::after": {
                width: 8,
                cursor: "col-resize",
                backgroundColor: dividerBorder,
                position: "absolute",
                right: 0
            },
            "& .rdg-cell-resizable .rdg-sort-arrow": {
                marginRight: 8,
                marginLeft: 8,
            },
            "& .rdg-cell-resizable > div": {
                width: "10px",
                borderRight: "1px solid " + dividerBorder,
                "&:hover": {
                    borderRight: "10px solid " + dividerBorder,
                }
            },
            "& .rdg-cell-drag-over": {
                backgroundColor: primaryMain
            }
        },
        "& div.rdg-cell": {
            boxShadow: "none",
            border: "none",
            borderBottom: "1px solid " + dividerBorder,
            padding: "0 8px",
            "&[aria-selected=true]": {
                outline: 0
            }
        },
        "& div.rdg-row.rdg-row-even": {
            "&:hover": {
                cursor: "pointer"
            }
        },
        "& div.rdg-row.rdg-row-odd": {
            backgroundColor: tableOdd,
            "&:hover": {
                backgroundColor: rowHover,
                cursor: "pointer"
            },
            "&[aria-selected=true]": {
                backgroundColor: tableSelectedRow,
                "&:hover": {
                    backgroundColor: rowHover,
                }
            }
        }
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        borderColor: dividerBorder + " !important",
                    },
                },
                input: {
                    backgroundColor: input,
                    borderWidth: 1,
                    borderRadius: 4,
                    "&::placeholder": {
                        fontSize: 13
                    },
                    fontSize: 16
                },
                notchedOutline: {
                    borderWidth: 0
                },
                adornedStart: {
                    backgroundColor: input,
                },
                adornedEnd: {
                    backgroundColor: input
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderWidth: 0
                        },
                        '&:hover fieldset': {
                            borderWidth: 0
                        },
                        '&.Mui-focused fieldset': {
                            borderWidth: 1
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderWidth: 1
                        }
                    },
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: 14,
                    color: "rgba(255, 255, 255, 0.87)",
                    fontWeight: 500,
                    whiteSpace: "nowrap"
                }
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: 20,
                    marginTop: 12
                }
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "16px 22px"
                }
            },
        },
        MuiAlert: {
            styleOverrides: {
                filledInfo: {
                    backgroundColor: "rgba(50, 112, 220, 1)"
                },
                filledWarning: {
                    color: darkBlue
                },
                filledError: {
                    color: darkBlue
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation2: {
                    boxShadow: 'none'
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                head: {
                    backgroundColor: darkBlue,
                    "&:hover": {
                        backgroundColor: darkBlue + " !important"
                    }
                },
                root: {
                    "&:hover": {
                        backgroundColor: rowHover + " !important"
                    }
                }
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid " + dividerBorder,
                    fontSize: 14
                },
                head: {
                    border: 0,
                    borderLeft: "1px solid " + dividerBorder,
                    borderRight: "1px solid " + dividerBorder,
                },
                stickyHeader: {
                    backgroundColor: darkBlue
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                    fontSize: 13,
                },
                outlinedInherit: {
                    borderColor: dividerBorder,
                    padding: "6px 16px",
                    "&.Mui-disabled": {
                        borderColor: dividerBorder,
                        color: disabledDark,
                    }
                },
                containedInherit: {
                    color: darkBlue,
                },
                containedSecondary: {
                    color: white,
                    "&.Mui-disabled": {
                        color: "rgba(255, 255, 255, 0.4)",
                    }
                },
                containedPrimary: {
                    "&.Mui-disabled": {
                        color: "rgba(255, 255, 255, 0.4)",
                    }
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    backgroundColor: input
                },
                paper: {
                    backgroundColor: rowHover,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    paddingTop: 0,
                    marginTop: 0,
                    fontSize: 14
                },
                clearIndicator: {
                    color: white
                },
                popupIndicator: {
                    color: white
                }
            },
        },
        MuiChip: {
            styleOverrides: {
                colorPrimary: {
                    margin: "3px 2px",
                },
                filledPrimary: {
                    color: darkBlue
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(0, 0, 0, 0.8)"
                }
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: dividerBorder
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: darkBlue,
                }
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: white
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    backgroundColor: white
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: disabledDark,
                    }
                },
                colorInherit: {
                    color: "#fff",
                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.08)"
                    }
                },
            }
        },
        MuiModal: {
            styleOverrides: {
                backdrop: {
                    opacity: "0.3 !important"
                }
            }
        }
    }
});
export default theme;