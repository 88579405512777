import React from "react";
import {connect} from "react-redux";
import {Box, Paper, Button, Alert, useTheme} from "@mui/material";
import CustomSnackbar from "../alert/CustomSnackbar";
import {CopyTextToClipboard} from "../copy/CopyTextToClipboard";
import {alertActions} from "../../store/actions/alertActions";

function LandingPhpScript(props) {
    const theme = useTheme();

    const obj = `
<?php
    const PROTECTION_TOKEN = '${props?.landingProtection?.token}'; // Your landing protection token
    const KEY_TTL = '30 minutes'; // How long KEY should be valid. Valid formats are explained here: http://php.net/manual/en/datetime.formats.php
    const KEY_GET_PARAM = 'key'; // GET parameter with Skro landing key.

    $key = isset($_GET[KEY_GET_PARAM]) ? rawurldecode($_GET[KEY_GET_PARAM]) : exit('Access denied');
    if (!$key = base64_decode($key)) {
        exit('Access denied');
    }
    if (!$key = json_decode($key, true)) {
        exit('Access denied');
    }
    if (!isset($key['timestamp']) || !isset($key['hash'])) {
        exit('Access denied');
    }
    $signedHash = hash_hmac('sha1', $key['timestamp'], PROTECTION_TOKEN);
    if ($signedHash !== $key['hash'] || strtotime(KEY_TTL, $key['timestamp']) < time()) {
        exit('Access denied');
    }
?>
    `;

    const handleCodeCopy = () => {
        CopyTextToClipboard(obj).then(() => {
            props?.success("PHP code is copied to clipboard.");
        }).catch(() => {
            props?.error("Unable to copy.");
        });
    };

    const Snippet = () => {
        return (
            <pre
                style={{
                    color: "#fff",
                    background: theme.palette.background.input,
                    padding: 8,
                    display: "table",
                    tableLayout: "fixed",
                    width: "100%",
                    borderRadius: 3,
                }}
            >
                <code
                    style={{
                        overflow: "auto",
                        fontSize: 13,
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                        wordSpacing: "normal",
                        wordBreak: "break-all",
                        wordWrap: "break-word",
                        lineHeight: 1.5
                    }}
                >
                    {obj}
                </code>
            </pre>
        )
    };

    return (
        <Box sx={{
            width: "100%",
            padding: "8px 8px",
            marginLeft: 2,
            marginRight: 2,
            minWidth: 200,
            margin: "0 auto",
            backgroundColor: "background.defaultBlue",
        }}>
            <Box display="flex">
                <Box
                    flexGrow={1}
                    sx={{
                        fontSize: 16,
                        paddingLeft: 1,
                        lineHeight: 1.167,
                        textAlign: "left",
                        fontWeight: 700,
                        paddingTop: 2,
                        paddingBottom: 1
                    }}
                >
                    PHP Protection Script
                </Box>
                <Box alignSelf="center" mr={1}>
                    <Button type="button" onClick={handleCodeCopy} color="primary" variant="contained">
                        Copy Code
                    </Button>
                </Box>
            </Box>
            <Box>
                <Paper sx={{position: "relative", minHeight: 200, boxShadow: 0}} elevation={0}>

                    {props?.alert.successMsg !== "" &&
                        <CustomSnackbar message={props?.alert.successMsg} severity="success" timeout={3500}/>
                    }

                    <Box pl={1} pb={2} sx={{color: "text.disabledLight"}}>
                        <p>
                            You can protect your landing pages from direct visits. Protection is based on calculating
                            time difference between forwarding a user to landing page and time of actual visit to
                            landing page. Copy and paste the code at the top of your landing page.
                        </p>
                        <p>
                            Your landing page will only be visible if a visitor goes through the campaign link. If the
                            landing link is placed it in the browser's string and accessed directly the error will be
                            displayed. It will not be possible to see the content of landing page or any part of its
                            code which basically means that no competitor's spy tool is able to reach your page.
                        </p>
                    </Box>
                    <Alert severity="warning" variant="filled">
                        If you use the Direct Tracking when the landing page URL is used as the campaign URL it
                        is not possible to apply the landing protection script.
                    </Alert>

                    {Snippet()}
                </Paper>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
        return {
            auth: state.auth,
            state: state.user,
            alert: state.alert,
            landingProtection: state.landingProtection
        }
    }
;

const mapDispatchToProps = {
    error: alertActions.error,
    success: alertActions.success,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPhpScript);