import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme
} from "@mui/material";
import PropTypes from "prop-types";
import TrackingDomainMultiSelect from "../tracking-domain/TrackingDomainMultiSelect";
import {workspaceServices} from "../../service/workspaceService";

function EditWorkspacePopUp({onClose, open, onSubmit, item}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {handleSubmit, control, register, setValue, formState: {errors}} = useForm();

    const handleOnSubmit = (data) => {
        onSubmit(item, data);
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        onClose();
    };

    useEffect(() => {
        if (item !== null && open) {
            setValue("name", item?.name);
            let workspaces = [];
            workspaceServices.findWorkspaceTrackingDomains(item?.id).then((data) => {
                data?.forEach(data => {
                    workspaces.push({value: data.id, label: data.domain});
                    setValue("trackingDomain", workspaces);
                })
            });
        }
    }, [item, open, setValue]);

    return (
        <Dialog open={true}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="edit-workspace-form"
                maxWidth={"sm"}
        >
            <DialogTitle sx={{textAlign: "center"}}>Edit workspace name</DialogTitle>
            <DialogContent>
                <Box>
                    <Box>
                        <TextField
                            error={!!errors.name}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            id="name"
                            {...register("name", {
                                required: true,
                                maxLength: 100
                            })}
                            name="name"
                            type="name"
                            placeholder="Enter workspace name"
                            fullWidth
                            label="Workspace name"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box flexGrow={1}>
                        <TrackingDomainMultiSelect
                            errors={errors}
                            control={control}
                            inputLabel="Tracking domain"
                            inputName="trackingDomain"
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                    Save & Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EditWorkspacePopUp.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired
};

export default EditWorkspacePopUp;