import {
    Alert, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {campaignServices} from "../../service/campaignService";
import TimezoneSelect from "../timezone/TimezoneSelect";
import {useForm} from "react-hook-form";
import CurrencySelect from "../offers/CurrencySelect";
import Spinner from "../spinner/Spinner";
import moment from 'moment-timezone';
import {connect} from "react-redux";
import {campaignActions} from "../../store/actions/campaignsAction";
import AirbnbDateRangePicker from "../utils/AirbnbDateRangePicker";

const CampaignCost = (props) => {

    const {auth: {userSettings: {timezone}}} = props;
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const {handleSubmit, register, control, setValue, formState: {errors}} = useForm();

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setOpen(false);
        props.history.push({pathname: "/campaigns", state: {from: props.location}});
    }, [props]);

    const [loading, setLoading] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const [from, setFrom] = useState(moment().tz(timezone).startOf("d").format("YYYY-MM-DD HH:mm:ss"));
    const [to, setTo] = useState(moment().tz(timezone).endOf("d").format("YYYY-MM-DD HH:mm:ss"));
    const [dateLabel, setDateLabel] = useState("Today");
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        async function fetchData(campaignId): void {
            try {
                setLoading(true);
                const campaignResponse = await campaignServices.find(campaignId);
                setCampaign(campaignResponse);
            } catch (e) {
                setErrorMsg(e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        if (props?.match?.params?.id && campaign === null) {
            fetchData(props?.match?.params?.id);
        }
    }, [campaign, props.match]);

    const onSubmit = (data) => {
        let formData = {
            campaignId: campaign?.id,
            from: from,
            to: to,
            timezone: data?.timezone?.timezone,
            currencyCode: data?.currency?.code,
            cost: parseFloat(data?.cost)
        };

        async function updateCost(formData): void {
            try {
                setLoading(true);
                setErrorMsg("");
                await campaignServices.updateCost(formData);
                handleClose();
                props.rerender();
            } catch (e) {
                setErrorMsg(e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        updateCost(formData);
    };

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box width="100%">
            {loading && <Spinner size={32} overComponentBox={true}/>}
            <Dialog open={open}
                    onClose={handleClose}
                    fullWidth
                    fullScreen={fullScreen}
                    aria-labelledby="form-dialog-title"
            >
                <DialogTitle sx={{textAlign: "center"}}>Update campaign cost</DialogTitle>
                <DialogContent>

                    {errorMsg !== "" && <Alert color="error" variant="outlined" sx={{pb: 1}}>{errorMsg}</Alert>}

                    <Box sx={{minHeight: 200}}>
                        <Box pl="4px" pb={1} sx={{color: "primary.dark"}}>{campaign?.fullName}</Box>
                        <Box pb={1}>
                            <AirbnbDateRangePicker
                                onChangeDate={(from, to, label) => {
                                    setFrom(from);
                                    setTo(to);
                                    setDateLabel(label);
                                }}
                                dateLabel={dateLabel}
                                from={from}
                                to={to}
                                hideTimezoneSelect={true}
                            />
                        </Box>
                        <Box pb={1}>
                            <TimezoneSelect setValue={setValue} control={control}/>
                        </Box>
                        <Box pb={1}>
                            <CurrencySelect control={control}/>
                        </Box>
                        <TextField
                            error={!!errors?.cost}
                            variant="outlined"
                            margin="dense"
                            id="cost"
                            {...register("cost", {
                                required: true,
                                pattern: /^(\d+)(?:[.]\d+)?$/
                            })}
                            name="cost"
                            size="small"
                            label="Cost"
                            placeholder="0.00"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit" variant="outlined">
                        Close
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Update Cost
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const actionCreators = {
    rerender: campaignActions.rerender
};

export default connect(mapStateToProps, actionCreators)(CampaignCost);