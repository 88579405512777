import {Button, Alert, Box, Divider, Link} from "@mui/material";
import React, {useEffect, useState} from "react";
import {userServices} from "../../service/userService";
import Spinner from "../spinner/Spinner";
import Logo from "../utils/Logo";
import {NavLink} from "react-router-dom";

function ConfirmInvitation() {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const action = params.get('action');

    useEffect(() => {
        setLoading(true);
        userServices.updateInvitation(token, action).then((response) => {
            console.log(response);
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });

    }, [token, action]);

    if (action === "decline") {
        return (
            <Box>
                <Box pl={1} pr={1} display="flex" flexDirection="row" flexWrap="noWrap" sx={{maxWidth: 1200, margin: "0 auto"}}>
                    <Box p={1} flexGrow={1} sx={{fontSize: 20, fontWeight: 600, marginTop: "4px"}}>
                        <Logo width={75}/>
                    </Box>
                    <Box mt={1.2} p={1}>
                        <Link color="error" component={NavLink} to="/logout"
                              sx={{
                                  textDecoration: "none",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  "&:hover": {color: "error.dark"}
                              }}
                        >
                            Log out
                        </Link>
                    </Box>
                </Box>
                <Divider/>
                <Box display="flex" flexWrap={"wrap"} flexDirection="row" flex="1 0" justifyContent="center">
                    <Box p={1}>
                        {loading ? <Spinner size={32}/> :
                            <Box textAlign="center">
                                <Box pt={4}>
                                    <Alert severity="warning" color="warning" variant="filled">
                                        Your invitation has been declined.
                                    </Alert>
                                </Box>
                                <Box pt={4}>
                                    <Button type="button" variant="text" color="primary" size="large"
                                            href={"https://skro.eu"}>
                                        Go to homepage
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            <Box pl={1} pr={1} display="flex" flexDirection="row" flexWrap="noWrap" sx={{maxWidth: 1200, margin: "0 auto"}}>
                <Box p={1} flexGrow={1} sx={{fontSize: 20, fontWeight: 600, marginTop: "4px"}}>
                    <Logo width={75}/>
                </Box>
                <Box mt={1.2} p={1}>
                    <Link color="error" component={NavLink} to="/logout"
                          sx={{
                              textDecoration: "none",
                              fontWeight: 600,
                              fontSize: "14px",
                              "&:hover": {color: "error.dark"}
                          }}
                    >
                        Log out
                    </Link>
                </Box>
            </Box>
            <Divider/>
            <Box display="flex" flexWrap={"wrap"} flexDirection="row" flex="1 0" justifyContent="center">
                <Box p={1}>
                    {loading ? <Spinner size={32}/>
                        : errorMsg !== "" ?
                            <Box textAlign="center">
                                <Box pt={4}>
                                    <Alert color="error" severity="error" variant="filled">{errorMsg}</Alert>
                                </Box>
                                <Box pt={4}>
                                    <Button type="button" variant="text" color="primary" size="large"
                                            href={"https://skro.eu"}>
                                        Go to homepage
                                    </Button>
                                </Box>
                            </Box>
                            :
                            <Box textAlign="center">
                                <Box pt={4}>
                                    <Alert color="success" severity="success" variant="filled">
                                        You invitation has been accepted and access granted.
                                    </Alert>
                                </Box>
                                <Box pt={4}>
                                    <Button type="button" variant="text" color="primary" size="large" href={"/login"}>
                                        Go to login page
                                    </Button>
                                </Box>
                            </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default ConfirmInvitation;