import {offersConstants} from "../constants/offersConstants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        total: {}
    },
    errorMsg: "",
    isLoading: false,
    name: "",
    tags: [],
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false
};

const offersReducer = (state = initialState, action) => {
    switch (action.type) {
        case offersConstants.OFFERS_LOADING_SPINNER:
            return {
                ...state,
                isLoading: true,
            };
        case offersConstants.OFFERS_STOP_LOADING_SPINNER:
            return {
                ...state,
                isLoading: false,
            };
        case offersConstants.OFFERS_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false
            };
        case offersConstants.OFFERS_GET_DATA:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case offersConstants.OFFERS_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load offers. Please try again.',
                rerender: false,
                isLoading: false,
            };
        case offersConstants.OFFERS_ARCHIVE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case offersConstants.OFFERS_RESTORE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case offersConstants.OFFERS_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case offersConstants.OFFERS_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case offersConstants.OFFERS_UPDATE_NAME:
            return {
                ...state,
                name: action.name
            };
        case offersConstants.OFFERS_UPDATE_TAGS:
            return {
                ...state,
                tags: action.tags
            };
        case offersConstants.OFFERS_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case offersConstants.OFFERS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        case offersConstants.BULK_UPDATE_CHANGE_WORKSPACE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case offersConstants.OFFERS_BULK_UPDATE_FOR_URL:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default offersReducer;