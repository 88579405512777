import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    TextField,
    Alert,
    Tooltip,
    Box
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import React from "react";
import CountrySelect from "../utils/CountrySelect";
import {Info} from "@mui/icons-material";
import UrlWithParams from "../input/UrlWithParams";
import {RenderClickToOfferLinks} from "../campaigns/CampaignLinks";
import {GetDefaultTrackingDomain} from "../../helpers/common";
import WorkspaceDropdown from "../workspace/WorkspaceDropdown";
import ReactHookTagsSelect from "../tags/ReactHookTagsSelect";
import {styled} from "@mui/system";

export const CustomTooltip = styled(Tooltip)(({theme}) => ({
    marginLeft: 6,
    fontSize: 18,
    position: "relative",
    cursor: "pointer"
}));

export const LandingForm = (props) => {
    const {
        title,
        watch,
        control,
        register,
        handleClose,
        requestError,
        errors,
        handleSubmit,
        onSubmit,
        loading,
        setValue,
        landingData,
        getValues
    } = props;

    return (
        <>
            <DialogTitle sx={{textAlign: "center"}}>{title}</DialogTitle>
            <DialogContent>
                {requestError.length > 1 &&
                    <Alert sx={{marginBottom: "10px"}} variant="filled" severity="error">
                        {requestError}
                    </Alert>
                }
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} style={{minHeight: "220px"}}>
                    <Box display="flex" mb={2}>
                        <Box flexGrow={1}>
                            <WorkspaceDropdown
                                errors={errors} control={control} workspaceId={landingData?.workspaceId}
                                setValue={setValue}
                            />
                        </Box>
                        <Box p={1}>
                            <Box sx={{paddingTop: "10px"}}>
                                <CustomTooltip
                                    disableFocusListener
                                    title="Workspace allows to make the required landing accessible only by a specific user or group of users."
                                >
                                    <Info color={"primary"} fontSize={"small"}/>
                                </CustomTooltip>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <TextField
                                error={!!errors.name}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="name"
                                {...register("name", {required: true, maxLength: 255, minLength: 3})}
                                name="name"
                                type="text"
                                placeholder="Landing page name"
                                label="Name*"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <Box sx={{paddingTop: "10px"}}>
                                <CustomTooltip
                                    disableFocusListener
                                    title="The first part of name is generated automatically and it depends on the selected country."
                                >
                                    <Info color={"primary"} fontSize={"small"}/>
                                </CustomTooltip>
                            </Box>
                        </Box>
                    </Box>

                    <FormHelperText style={{paddingBottom: "10px", paddingLeft: "14px"}}>
                        {watch("country") !== undefined && watch("country") !== null ? watch("country").label : "Global"} - {watch("name")}
                    </FormHelperText>

                    <Box display="flex" flexDirection="column">
                        <Box sx={{maxWidth: 390}}>
                            <CountrySelect
                                control={control}
                                title="Country select is required to navigate easily through the landings list. This selection does not affect the traffic distribution."
                            />
                        </Box>
                        <Box mt={-1}>
                            <UrlWithParams
                                label="Landing URL*"
                                name="url"
                                register={register}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                            />
                        </Box>
                        <Box flexGrow={1}>
                            <ReactHookTagsSelect
                                control={control}
                                inputName="tags"
                                inputLabel="Tags"
                                inputLabelShrink={true}
                                getValues={getValues}
                            />
                        </Box>
                    </Box>

                    <RenderClickToOfferLinks trackingDomain={GetDefaultTrackingDomain()}/>

                    <input type="submit" hidden="hidden"/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                {loading ? <Spinner/> :
                    <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Save & Close
                    </Button>
                }
            </DialogActions>
        </>
    );
};