import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import {trafficSourceServices} from "../../service/trafficSourceService";
import PropTypes from "prop-types";
import {Autocomplete, Box, FormLabel, TextField, Typography} from "@mui/material";
import {Info} from "@mui/icons-material";
import {CustomTooltip} from "../landings/LandingForm";

function TrafficSourceSelect({control, errors, updateFunc}) {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await trafficSourceServices.read(search);
                setOptions(
                    response.traffics.map(item => {
                        return {
                            value: item?.id,
                            label: item?.name,
                            custom1: item?.custom1Name,
                            custom2: item?.custom2Name,
                            custom3: item?.custom3Name,
                            custom4: item?.custom4Name,
                            custom5: item?.custom5Name,
                            custom6: item?.custom6Name,
                            custom7: item?.custom7Name,
                            custom8: item?.custom8Name,
                            custom9: item?.custom9Name,
                            custom10: item?.custom10Name,
                            integrationId: item?.integrationId
                        }
                    })
                );
            } catch (e) {
                console.log("Error: ", e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData();
        }

    }, [search, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="traffic-source-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    getOptionLabel={(option) => option?.label}
                    options={options}
                    loading={loading}
                    disableClearable
                    renderOption={(props, option, {index}) => (
                        <li {...props} key={index}>{option?.label}</li>
                    )}
                    renderInput={params => (
                        <>
                            <FormLabel component="legend">
                                <Box display="flex" flexDirection="row">
                                    <Box>
                                        <Typography variant="body1">
                                            Traffic Source
                                        </Typography>
                                    </Box>
                                    <Box mt={0.2}>
                                        <CustomTooltip
                                            disableFocusListener
                                            title="Select traffic source to automatically append query parameters to the campaign URL."
                                        >
                                            <Info color={"primary"} fontSize={"small"}/>
                                        </CustomTooltip>
                                    </Box>
                                </Box>
                            </FormLabel>
                            <TextField
                                autoFocus
                                {...params}
                                error={!!errors.trafficSource}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                placeholder="Select a traffic source"
                                value={search}
                                onChange={e => handleSearch(e.target.value)}
                            />
                        </>
                    )}
                    onChange={(e, data) => {
                        updateFunc(data);
                        onChange(data);
                    }}
                    {...props}
                />
            )}
            rules={{required: true}}
            name="trafficSource"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

TrafficSourceSelect.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

export default TrafficSourceSelect;