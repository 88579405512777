import React, {useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {subscriptionServices} from "../../service/subscriptionService";
import Spinner from "../spinner/Spinner";
import {DialogActions, DialogContent, DialogTitle, Alert, Divider, Button, Box} from "@mui/material";
import Logo from "../utils/Logo";

export default function AddPaymentMethod() {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        setErrorMsg("");

        if (!stripe || !elements) {
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (error) {
            setLoading(false);
            setErrorMsg(error?.message);
        } else {
            await subscriptionServices.updatePaymentMethod(paymentMethod?.id).then(() => {
                setLoading(false);
                document.location.replace("/");
            }).catch((error) => {
                setErrorMsg(error?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    return (
        <Box>
            <Box sx={{textAlign: "center", fontSize: "20px", fontWeight: 600, padding: "15px"}}>
                <Logo width={85}/>
            </Box>
            <Divider/>
            <Box sx={{width: "100%", maxWidth: 600, margin: "0 auto"}}>

                {loading && <Spinner size={32} overComponentBox={true}/>}

                <DialogTitle sx={{textAlign: "center", fontSize: 18, fontWeight: 600}}>
                    Add payment method
                </DialogTitle>

                <DialogContent sx={{padding: "12px 15px 0px 15px"}}>
                    <Alert severity="warning" variant="filled" sx={{paddingBottom: "8px"}}>
                        Your subscription plan has reached to 250 000 used events per month. Please add your payment
                        method.
                    </Alert>

                    {errorMsg !== "" &&
                        <Alert sx={{marginBottom: "10px"}} variant="outlined" severity="error">{errorMsg}</Alert>}

                    <Box pt={2}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{fontWeight: 600, fontSize: 14}}>Card details</Box>
                            <CardElement options={{
                                style: {
                                    base: {
                                        width: "100%",
                                        iconColor: '#fff',
                                        fontSize: '16px',
                                        color: '#fff',
                                        '::placeholder': {
                                            color: '#fff',
                                            fontSize: "14px"
                                        },
                                        '::selection': {
                                            backgroundColor: "blue"
                                        }
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}/>
                        </form>
                    </Box>
                </DialogContent>
                <DialogActions sx={{padding: "0px 15px"}}>
                    <Button fullWidth size="large" type="submit" color="primary" variant="contained"
                            onClick={handleSubmit}>
                        Add Payment Method
                    </Button>
                </DialogActions>
                <Box pt={4} textAlign="center">
                    <a href="https://www.stripe.com" target="_blank" rel="noreferrer">
                        <img src="/assets/images/stripe.png" alt="Powered by Stripe"
                             style={{width: 120}}/></a>
                </Box>
            </Box>
        </Box>
    );
}
