import React, {useState} from 'react';
import {
    Button,
    Tooltip,
    IconButton,
    Box,
    Divider,
    ListItemText,
    ListItemSecondaryAction,
    List,
    ListItem,
    Paper, useTheme
} from '@mui/material';
import {trackingDomainActions} from "../../../store/actions/trackingDomainAction";
import {connect} from "react-redux";
import {Delete} from "@mui/icons-material";
import AlertDialog from "../../alert/AlertDialog";
import {userSettingsActions} from "../../../store/actions/userSettingsAction";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        paddingTop: 16,
        width: "100%"
    },
    paper: {
        minHeight: 200,
        minWidth: 450,
        backgroundColor: theme.palette.background.defaultBlue,
        [theme.breakpoints.down(600)]: {
            minWidth: 300,
        },
    },
    header: {
        padding: "12px 8px",
        color: "rgb(138, 147, 159)"
    },
    listItems: {
        maxHeight: 400,
        overflowY: "scroll",
    }
});

function TrackingDomainsList(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const {trackingDomains} = props?.state;

    const [prompt, setPrompt] = useState(false);
    const [deleteDomain, setDeleteDomain] = useState(null);

    const handlePrompt = (domain) => {
        setDeleteDomain(domain);
        setPrompt(true);
    };

    const handleDelete = (confirm) => {
        if (!confirm) {
            setPrompt(false);
            setDeleteDomain(null);
            return false;
        }
        props.remove(deleteDomain?.id);
        setPrompt(false);
        setDeleteDomain(null);
    };

    const handleSetAsDefault = (domain) => {
        if (domain?.domain !== "") {
            props.updateUserSettings({defaultTrackingDomain: domain?.domain});
            props.setNewDefaultTrackingDomain(domain?.domain);
        }
    };

    return (
        <div className={classes?.root}>
            <Paper className={classes?.paper} elevation={0}>
                <Box display="flex" flexWrap="nowrap" className={classes?.header}>
                    <Box p={1} flexGrow={1}>{props?.title}</Box>
                    <Box p={1}>Actions</Box>
                </Box>
                <Divider/>
                {prompt && <AlertDialog question="Delete confirmation"
                                        text={"Are you sure you want to delete this tracking domain: " + deleteDomain?.domain + " ?"}
                                        confirm={handleDelete}/>
                }
                <div className={classes?.listItems}>
                    <List disablePadding>
                        {trackingDomains.length === 0 ?
                            <p style={{textAlign: "center"}}>Nothing to display</p>
                            :
                            props?.data.map((item, index) => {
                                return (
                                    <ListItem key={item.id}
                                              disableGutters={false}
                                              divider={trackingDomains.length !== index + 1}
                                    >
                                        <ListItemText id={item?.id} primary={<span>https://{item?.domain}</span>}/>
                                        <ListItemSecondaryAction>
                                            {!item?.isDefault &&
                                                <Button style={{textTransform: "none"}} color="primary" variant="text"
                                                        aria-label="delete" size="small" className={classes?.margin}
                                                        onClick={() => handleSetAsDefault(item)}>
                                                    Set as default
                                                </Button>
                                            }
                                            {item?.custom &&
                                                <Tooltip title="Delete">
                                                    <IconButton aria-label="delete" className={classes?.margin}
                                                                onClick={() => handlePrompt(item)}>
                                                        <Delete fontSize="small" color="error"/>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            trackingDomains: state.trackingDomain,
        }
    }
};

const mapDispatchToProps = {
    setNewDefaultTrackingDomain: trackingDomainActions.setNewDefaultTrackingDomain,
    remove: trackingDomainActions.remove,
    updateUserSettings: userSettingsActions.update
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingDomainsList);