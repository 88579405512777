import {apiRequest} from "../helpers/utils";
import {accessApiUrl} from "../helpers/config";

export const userServices = {
    read,
    remove,
    invite,
    updateInvitation
};

function read(name) {
    let params = {
        name,
        isDeleted: false,
        pageSize: 250
    };
    return apiRequest("get", "v1/user", {}, params, true, false, accessApiUrl);
}

function remove(id) {
    return apiRequest("delete", "v1/user/" + parseInt(id), {}, {}, true, false, accessApiUrl);
}

function invite({email, roleId, workspaceIds}) {
    return apiRequest("post", "v1/user/invite", {email, roleId, workspaceIds}, {}, true, false, accessApiUrl);
}

function updateInvitation(token, action) {
    return apiRequest("get", "v1/user/invite", {}, {t: token, a: action}, true, false, accessApiUrl);
}
