import {Box, Button, Divider, IconButton, InputAdornment, TextField} from "@mui/material";
import {Add, Delete, Edit, Launch} from "@mui/icons-material";
import OfferSelect from "./OfferSelect";
import React, {useEffect, useState} from "react";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {isNumber} from "chart.js/helpers";
import OfferAddPopUp from "../offers/OfferAddPopUp";
import OfferEditPopUp from "../offers/OfferEditPopUp";
import {offerServices} from "../../service/offerService";
import {Controller} from "react-hook-form";

function PathOffers(props) {

    const {
        errors,
        control,
        setValue,
        getCurrentWeight,
        campaignFormState: {flow, selectedRuleNumber, selectedPathNumber, selectedPath}
    } = props;

    const [open, setOpen] = useState(false);
    const [openOfferEditPopUp, setOpenOfferEditPopUp] = useState(false);
    const [editableOffer, setEditableOffer] = useState(null);

    const maxOffers = 100;

    useEffect(() => {
        if (selectedPath === null) {
            props.updateSelectedPath(selectedRuleNumber, selectedPathNumber, flow?.rules[selectedRuleNumber]?.paths[selectedPathNumber])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPath, flow]);

    const handleOnClose = () => {
        setOpen(false);
    };

    const handleOnOpenOfferPopUp = () => {
        setOpen(true);
    };

    const handleOnCloseOfferEditPopUp = (offerName) => {
        setOpenOfferEditPopUp(false);
        if (offerName !== undefined && offerName !== null && offerName !== "") {
            updateOfferName(selectedRuleNumber, selectedPathNumber, editableOffer?.offerIndex, editableOffer?.offerId, offerName);
        }
    };

    const handleOnClickOfferEditPopUp = (offerIndex, offerId) => {
        if (offerId > 0) {
            setEditableOffer({offerIndex: offerIndex, offerId: offerId});
            setOpenOfferEditPopUp(true);
        }
    };

    const addOffer = (ruleIndex, pathIndex) => {
        props.addOfferToPath(ruleIndex, pathIndex);
    };

    const removeOffer = (ruleIndex, pathIndex, offerIndex) => {
        props.removeOfferFromPath(ruleIndex, pathIndex, offerIndex);
    };

    const updateOfferName = (ruleIndex, pathIndex, offerIndex, offerId, name) => {
        props.updateOfferNameInPath(ruleIndex, pathIndex, offerIndex, offerId, name);
    };

    const handleOfferWeightUpdate = (ruleIndex, pathIndex, offerIndex, weight) => {
        if (isNumber(weight)) {
            weight = parseInt(weight);
        } else {
            weight = "";
        }
        props.updateOfferWeightInPath(ruleIndex, pathIndex, offerIndex, weight);
    };

    const handleOnClickPreview = async (id) => {
        if (id === 0) {
            props.error("Offer page not found.");
            return
        }

        await offerServices.find(id).then((result) => {
            if (result?.url) {
                window.open(result?.url, '_blank').focus();
            }
        }).catch((e) => {
            if (e?.response?.data?.error) {
                props.error("Offer page not found.");
            }
        });
    }

    // Ensure setValue is only called when offers or their weights change
    useEffect(() => {
        if (selectedPath?.offers) {
            selectedPath?.offers?.forEach((offer, offerIndex) => {
                setValue(`offer.rules[${selectedRuleNumber}].paths[${selectedPathNumber}].offers[${offerIndex}].weight`, parseInt(offer?.weight));
            });
        }
    }, [selectedPath?.offers, selectedRuleNumber, selectedPathNumber, setValue]);

    return (
        <Box sx={{backgroundColor: "background.darkBlue", borderRadius: "4px", padding: 1}}>
            <Box display="flex" p={1} sx={{height: "44px", alignItems: "center"}}>
                <Box flexGrow={1} sx={{fontSize: "14px", fontWeight: "bold"}}>
                    Offers {selectedPath?.offers?.length}/{maxOffers}
                </Box>
                <Box>
                    <Button
                        color="primary"
                        startIcon={<Add/>}
                        onClick={() => handleOnOpenOfferPopUp()}
                        sx={{textTransform: "none", padding: "2px 8px"}}
                    >
                        Create new
                    </Button>
                    <OfferAddPopUp open={open} onClose={handleOnClose}/>
                </Box>
            </Box>
            {
                selectedPath?.offers?.map((offer, offerIndex) => {
                    return (
                        <Box display="flex" flexWrap="nowrap" sx={{height: "42px"}} key={offerIndex}>
                            <Box sx={{paddingTop: "20px", paddingLeft: "4px", paddingRight: "4px"}}>
                                {(offerIndex + 1)}
                            </Box>
                            <Box p="10px" flexGrow={1}>
                                <OfferSelect
                                    control={control}
                                    errors={errors}
                                    updateOfferInPath={(value, label) => updateOfferName(selectedRuleNumber, selectedPathNumber, offerIndex, value, label)}
                                    inputValue={offer.offerId === 0 ? null : {
                                        value: offer.offerId,
                                        label: offer.name
                                    }}
                                    inputName={`defaultOffers.paths[${selectedPathNumber}].offers[${offerIndex}].offerId`}
                                    pathIndex={selectedPathNumber}
                                    offerIndex={offerIndex}
                                    countryCode={props.campaignFormState?.country?.code}
                                    setValue={setValue}
                                />
                            </Box>
                            <Box p="10px">
                                <Controller
                                    name={`offer.rules[${selectedRuleNumber}].paths[${selectedPathNumber}].offers[${offerIndex}].weight`}
                                    control={control}
                                    defaultValue={100}
                                    rules={{
                                        required: true,
                                        min: 0,
                                        max: 100,
                                        validate: (value) => Number.isInteger(Number(value)),
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            sx={{maxWidth: "120px", fontSize: "14px"}}
                                            label="Weight"
                                            variant="outlined"
                                            margin="dense"
                                            type="text"
                                            size="small"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleOfferWeightUpdate(selectedRuleNumber, selectedPathNumber, offerIndex, e.target.value);
                                            }}
                                            error={!!errors?.offer?.rules[selectedRuleNumber]?.paths[selectedPathNumber]?.offers[offerIndex]?.weight}
                                            InputProps={{
                                                inputMode: 'numeric', // Mobile-friendly number input
                                                pattern: '[0-9]*', // Allows only numeric input
                                                sx: {
                                                    "input": {
                                                        fontSize: "14px",
                                                        height: "10px"
                                                    }
                                                },
                                                endAdornment: <InputAdornment position="end">
                                                    <Divider sx={{height: "28px", margin: "4px"}} orientation="vertical"/>
                                                    <Box sx={{
                                                        paddingLeft: "6px",
                                                        fontSize: "14px",
                                                        color: "text.disabledLight"
                                                    }}
                                                    >
                                                        ({getCurrentWeight(offer.weight, selectedPath.offers)}%)
                                                    </Box>
                                                </InputAdornment>,
                                            }}
                                            InputLabelProps={{
                                                sx: {fontSize: "14px"},
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row" flexWrap="nowrap">
                                <Box>
                                    <IconButton
                                        aria-label="preview"
                                        color="secondary"
                                        title="Preview"
                                        size={"small"}
                                        sx={{marginTop: "18px"}}
                                        disabled={offer?.offerId === 0}
                                        onClick={() => handleOnClickPreview(offer?.offerId)}>
                                        <Launch color={offer?.offerId !== 0 ? "secondary" : "inherit"}
                                                fontSize="small"/>
                                    </IconButton>
                                </Box>
                                <Box>
                                    <IconButton
                                        aria-label="edit"
                                        color="inherit"
                                        title="Edit"
                                        size={"small"}
                                        sx={{marginTop: "18px"}}
                                        disabled={offer?.offerId === 0}
                                        onClick={() => handleOnClickOfferEditPopUp(offerIndex, offer?.offerId)}>
                                        <Edit fontSize="small"/>
                                    </IconButton>
                                </Box>
                                <Box>
                                    {selectedPath.offers.length > 1 &&
                                        <IconButton
                                            aria-label="delete"
                                            color="error"
                                            title="Delete"
                                            size="small"
                                            sx={{marginTop: "18px", color: "error.dark"}}
                                            onClick={() => removeOffer(selectedRuleNumber, selectedPathNumber, offerIndex)}>
                                            <Delete fontSize="small"/>
                                        </IconButton>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    );
                })
            }
            <Box display="flex" pt={3} p={1} justifyContent="center">
                {selectedPath?.offers?.length < maxOffers &&
                    <Button
                        sx={{
                            textTransform: "none",
                            padding: "2px 8px"
                        }}
                        color="primary"
                        startIcon={<Add/>}
                        onClick={() => addOffer(selectedRuleNumber, selectedPathNumber)}
                    >
                        Add more
                    </Button>
                }
            </Box>
            <OfferEditPopUp
                open={openOfferEditPopUp}
                onClose={handleOnCloseOfferEditPopUp}
                offerId={editableOffer?.offerId}
            />
        </Box>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    addOfferToPath: campaignFormActions.addOfferToPath,
    removeOfferFromPath: campaignFormActions.removeOfferFromPath,
    updateOfferNameInPath: campaignFormActions.updateOfferNameInPath,
    updateOfferWeightInPath: campaignFormActions.updateOfferWeightInPath,
    updateSelectedPath: campaignFormActions.updateSelectedPath
};

export default connect(mapStateToProps, mapDispatchToProps)(PathOffers);
