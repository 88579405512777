import {apiRequest} from "../helpers/utils";

export const affNetworkServices = {
    read,
    find,
    create,
    update,
    archive,
    restore,
    bulkAffiliateNetworkUpdate
};

function read(name) {
    let params = {
        name,
        isDeleted: false,
        pageSize: 250
    };
    return apiRequest("get", "v1/affiliate-network", {}, params);
}

function find(id) {
    return apiRequest("get", "v1/affiliate-network/" + parseInt(id));
}

function create(formData) {
    return apiRequest("post", "v1/affiliate-network", formData);
}

function update(id, formData) {
    return apiRequest("put", "v1/affiliate-network/" + parseInt(id), formData);
}

function archive(formData) {
    return apiRequest("post", "v1/affiliate-network/archive", formData);
}

function restore(formData) {
    return apiRequest("post", "v1/affiliate-network/restore", formData);
}

function bulkAffiliateNetworkUpdate(formData) {
    return apiRequest("patch", "v1/affiliate-network", formData);
}
