import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import PropTypes from "prop-types";
import {Autocomplete, TextField} from "@mui/material";

function ConversionPixelSelect({control, errors, options = []}) {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (!open) {
            setSearch("");
        }
    }, [open]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="conversion-pixel-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => {
                        if (option?.id === value?.id) {
                            return true;
                        }
                        if (value === null || value === "") {
                            return true;
                        }
                    }}
                    getOptionLabel={(option) => option.name ? option?.name + " | " + option?.pixelId : ""}
                    options={options}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={!!errors.conversionPixel}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            placeholder="Select a conversion pixel"
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                            label="Conversion Pixel"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(e, selectedObject) => {
                        onChange(selectedObject);
                    }}
                    {...props}
                />
            )}
            rules={{required: false}}
            name="conversionPixel"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

ConversionPixelSelect.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired
};

export default ConversionPixelSelect;