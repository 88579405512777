import {sidebarConstants} from "../constants/sidebarConstants";

const initialState = {
    isOpen: true,
    isOpenMobile: false,
    isLogsOpen: false
};

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case sidebarConstants.DRAWER_OPEN:
            return {
                ...state,
                isOpen: true,
                isOpenMobile: true,
            };
        case sidebarConstants.DRAWER_CLOSE:
            return {
                ...state,
                isOpen: false,
                isOpenMobile: false,
            };
        case sidebarConstants.SIDEBAR_IS_LOGS_OPEN:
            return {
                ...state,
                isLogsOpen: action?.isOpen
            };
        default:
            return state;
    }
};

export default sidebarReducer;