import React, {Component} from 'react';
import {connect} from 'react-redux'
import {authActions} from "../../store/actions/authAction";
import {Redirect} from "react-router-dom";
import Spinner from "../spinner/Spinner";

class VerifyEmailLogin extends Component {
    constructor(props) {
        super(props);

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const deviceId = params.get('deviceId');
        const token = params.get('token');

        this.state = {
            token: token,
            deviceId: deviceId
        };
    }

    componentDidMount() {
        const {deviceId, token} = this.state;
        if (deviceId && token) {
            this.props.verifyEmailLogin(deviceId, token);
        }
    }

    render() {
        const {isAuthenticated, authError} = this.props.auth;
        const {loaded} = this.props.auth.userSettings;

        if (authError) {
            return (
                <Redirect to={{pathname: "/login"}}/>
            );
        }

        if (isAuthenticated && loaded) {
            return (
                <Redirect to={{pathname: "/"}}/>
            );
        } else {
            return (
                <Spinner overComponentBox={true}/>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const actionCreators = {
    verifyEmailLogin: authActions.verifyEmailLogin
};

export default connect(mapStateToProps, actionCreators)(VerifyEmailLogin);