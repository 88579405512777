import {apiRequest} from "../helpers/utils";

export const websiteDeploymentsServices = {
    read,
    deleteBucket,
    checkStatus
};

function read(domain, page, orderBy, sortBy, workspace) {
    let params = {
        domain,
        page,
        orderBy,
        sortBy,
        workspace,
        pageSize: 100
    };
    return apiRequest("get", "v1/aws/cloudfront/distribution", {}, params);
}

function deleteBucket(id) {
    return apiRequest("delete", "v1/aws/cloudfront/distribution/" + parseInt(id), {});
}

function checkStatus(formData) {
    return apiRequest("post", "v1/aws/cloudfront/verify", formData);
}
