import {alertConstants} from "../constants/alertConstants";

const initialState = {
    successMsg: "",
    errorMsg: ""
};

const alertReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case alertConstants.ERROR:
            return {
                ...state,
                successMsg: "",
                errorMsg: action.message
            };
        case alertConstants.SUCCESS:
            return {
                ...state,
                errorMsg: "",
                successMsg: action.message
            };
        case alertConstants.CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default alertReducer;