import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import {roleServices} from "../../service/roleService";
import {Autocomplete, TextField} from "@mui/material";
import {jwtDecode} from "jwt-decode";

export default function RoleSelect({control, errors}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                let accessToken = localStorage.getItem('access-token');
                if (!accessToken && accessToken !== undefined && accessToken !== "" && accessToken !== null) {
                    return false;
                }

                // decode token
                let payload = jwtDecode(accessToken);
                if (!payload) {
                    return false;
                }

                const response = await roleServices.read(payload?.app);
                setOptions(response);
            } catch (e) {
                console.log("Error: ", e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        if (open) {
            fetchData();
        }

    }, [open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="role"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    getOptionLabel={(option) => option?.title}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <span>
                            <TextField
                                {...params}
                                error={!!errors.role}
                                placeholder="Select a role"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                label="Role"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </span>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={{required: true}}
            name="role"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}
