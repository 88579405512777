import {campaignFormActions} from "../../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import TimezoneSelect from "../../timezone/TimezoneSelect";
import {Box, useTheme} from "@mui/material";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        marginTop: theme.spacing(1),
        padding: "12px 10px",
        backgroundColor: theme.palette.background.darkBlue,
        borderRadius: 4
    },
    title: {
        paddingTop: 4,
        fontSize: 14,
        fontWeight: 600
    }
});

function TimeZoneSection(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {campaignFormState: {flow, selectedRuleNumber, selectedRule}} = props;

    const defaultTimeZone = "Etc/UTC";
    const [timeZoneName, setTimeZoneName] = useState(defaultTimeZone);
    const {control, getValues, reset, setValue} = useForm({
        defaultValues: {
            timezone: "Etc/UTC",
            offset: "(GMT+00:00) UTC (no daylight saving)"
        }
    });

    useEffect(() => {
        let dayOfWeek = selectedRule?.conditions?.dayOfWeek?.condition;
        let timeOfDay = selectedRule?.conditions?.timeOfDay?.condition;
        let timezoneValue = selectedRule?.conditions?.timezone?.values;

        if (timeOfDay !== "" && timeOfDay !== undefined && (timezoneValue === undefined || timezoneValue === "")) {
            setTimeZoneName(defaultTimeZone);
            props.updateCondition(props.campaignFormState.selectedRuleNumber, "timezone", "", false, defaultTimeZone);
            reset();
        }
        if (timeOfDay === "" && timezoneValue !== "" && (dayOfWeek === undefined || dayOfWeek === "")) {
            props.updateCondition(props.campaignFormState.selectedRuleNumber, "timezone", "", false, "");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRule, selectedRuleNumber, selectedRule?.conditions?.timeOfDay?.condition]);

    useEffect(() => {
        let dayOfWeek = selectedRule?.conditions?.dayOfWeek?.condition;
        let timeOfDay = selectedRule?.conditions?.timeOfDay?.condition;
        let timezoneValue = selectedRule?.conditions?.timezone?.values;

        if (dayOfWeek !== "" && dayOfWeek !== undefined && (timezoneValue === undefined || timezoneValue === "")) {
            setTimeZoneName(defaultTimeZone);
            props.updateCondition(props.campaignFormState.selectedRuleNumber, "timezone", "", false, defaultTimeZone);
            reset();
        }
        if (dayOfWeek === "" && timezoneValue !== "" && (timeOfDay === undefined || timeOfDay === "")) {
            props.updateCondition(props.campaignFormState.selectedRuleNumber, "timezone", "", false, "");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRule, selectedRuleNumber, selectedRule?.conditions?.dayOfWeek?.condition]);

    useEffect(() => {
        let timezone = flow?.rules[selectedRuleNumber]?.conditions?.timezone;
        if (selectedRule !== null && timezone?.values !== "") {
            setTimeZoneName(timezone?.values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRuleNumber, selectedRule, flow.rules]);

    const updateCondition = () => {
        props.updateCondition(
            props.campaignFormState.selectedRuleNumber,
            "timezone",
            "",
            false,
            getValues("timezone")?.timezone
        );
    };

    const renderRuleBox = () => {
        let timeOfDay = flow?.rules[selectedRuleNumber]?.conditions?.timeOfDay?.condition;
        let dayOfWeek = flow?.rules[selectedRuleNumber]?.conditions?.dayOfWeek?.condition;
        if ((timeOfDay !== "" && timeOfDay !== undefined) || (dayOfWeek !== "" && dayOfWeek !== undefined)) {
            return (
                <Box display="flex" flexDirection="column" className={classes?.root}>
                    <Box mb={1} display="flex" flexDirection="row">
                        <Box flexGrow={1} className={classes?.title}>Time zone</Box>
                    </Box>
                    <Box>
                        <TimezoneSelect
                            control={control}
                            inputName="timezone"
                            selectedValue={timeZoneName}
                            updateOnChange={updateCondition}
                            setValue={setValue}
                        />
                    </Box>
                </Box>
            );
        }
    };

    return (
        <>
            {renderRuleBox()}
        </>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    updateCondition: campaignFormActions.updateCondition,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeZoneSection);