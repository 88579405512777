import {apiRequest} from "../helpers/utils";

export const reportServices = {
    getReport,
    getChartData,
    getDetailsReport
};

function getReport(
    {
        groupBy = "",
        from = "",
        to = "",
        timezone = "Etc/UTC",
        name = "",
        tags = "",
        isDeleted = 0,
        page = 1,
        pageSize = 100,
        orderBy = "",
        sortBy = "",
        currencyCode = "EUR",
        exportData = "",
        metricFilters = [],
        columns = [],
        workspace = ""
    }
) {
    let params = {
        groupBy,
        from,
        to,
        timezone,
        name,
        tags,
        isDeleted,
        page,
        pageSize,
        orderBy,
        sortBy,
        currencyCode,
        export: exportData,
        columns,
        workspace
    };

    if (metricFilters?.length > 0) {
        // eslint-disable-next-line array-callback-return
        metricFilters.map((obj) => {
            for (const metric in obj) {
                params[`metricFilters[${metric}]`] = obj[metric];
            }
        });
    }

    return apiRequest("get", "v1/report", {}, params);
}

function getDetailsReport(params) {
    return apiRequest("get", "v1/report", {}, params);
}

function getChartData({groupBy, from, to, timezone, currencyCode, workspace}) {
    return apiRequest("get", "v1/report/chart", {}, {groupBy, from, to, timezone, currencyCode, workspace});
}
