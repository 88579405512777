import React, {useEffect} from "react";
import {Box, Button, IconButton} from "@mui/material";
import {campaignFormActions} from "../../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import ReactHookFormRadio from "../../input/ReactHookFormRadio";
import {Add, Delete} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        padding: "12px 10px",
        backgroundColor: theme.palette.background.darkBlue,
        borderRadius: 4
    },
    title: {
        paddingTop: 4,
        fontSize: 14,
        fontWeight: 600
    },
    radio: {
        '&$checked': {
            color: theme.palette.error.light
        }
    },
    checked: {},
    deleteButton: {
        color: theme.palette.error.dark
    },
    addConditionButton: {
        textTransform: "none",
        padding: "2px 8px"
    }
});

function BotFilterMarks(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {campaignFormState: {flow, selectedRuleNumber, selectedRule}} = props;

    const {control, setValue, reset, getValues} = useForm({
        defaultValues: {
            botFilterMarksCondition: "is"
        }
    });

    const radioConditions = [
        {label: "Is marked as bot", value: "is", color: "primary"},
        {label: "Is not marked as bot", value: "isNot", color: "error"},
    ];

    useEffect(() => {
        let condition = selectedRule?.conditions?.botFilterMarks?.condition;
        if (condition === undefined || condition === "") {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRule, selectedRuleNumber, selectedRule?.conditions?.botFilterMarks?.condition]);

    useEffect(() => {
        let type = flow?.rules[selectedRuleNumber]?.conditions?.botFilterMarks;
        if (selectedRule !== null && type?.condition !== "") {
            setValue("botFilterMarksCondition", type?.condition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRuleNumber, selectedRule, flow.rules]);

    const updateCondition = () => {
        props.updateCondition(
            props.campaignFormState.selectedRuleNumber,
            "botFilterMarks",
            getValues("botFilterMarksCondition"),
            false,
            null
        );
    };

    const removeCondition = () => {
        props.updateCondition(props.campaignFormState.selectedRuleNumber, "botFilterMarks", "", false, null);
    };

    const addCondition = () => {
        props.updateCondition(props.campaignFormState.selectedRuleNumber, "botFilterMarks", "is", false, null);
    };

    const renderAddCondition = () => {
        let condition = selectedRule?.conditions?.botFilterMarks?.condition;
        if (condition === "" || condition === undefined) {
            return (
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1} className={classes?.title}>
                        <span>Bot Filter Marks</span>
                    </Box>
                    <Box>
                        <Button
                            aria-label="condition"
                            color="primary"
                            title="Add condition"
                            startIcon={<Add/>}
                            className={classes?.addConditionButton}
                            onClick={addCondition}
                        >
                            Add condition
                        </Button>
                    </Box>
                </Box>
            );
        }
    };

    const renderConditionBox = () => {
        let condition = selectedRule?.conditions?.botFilterMarks?.condition;
        if (condition !== "" && condition !== undefined) {
            return (
                <>
                    <Box mb={1} display="flex" flexDirection="row">
                        <Box flexGrow={1} className={classes?.title}>Bot Filter Marks</Box>
                        <Box>
                            <IconButton
                                aria-label="remove"
                                color="default"
                                title="Remove"
                                size={"small"}
                                className={classes?.deleteButton}
                                onClick={removeCondition}
                            >
                                <Delete fontSize="small"/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <ReactHookFormRadio
                            defaultValue="is"
                            control={control}
                            options={radioConditions}
                            name="botFilterMarksCondition"
                            label="conditions"
                            updateOnChange={updateCondition}
                        />
                    </Box>
                </>
            );
        }
    };

    return (
        <Box display="flex" flexDirection="column" className={classes?.root}>
            {renderAddCondition()}
            {renderConditionBox()}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    updateCondition: campaignFormActions.updateCondition,
};

export default connect(mapStateToProps, mapDispatchToProps)(BotFilterMarks);