import {logPostbacksConstants} from "../constants/logPostbacksConstants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        recordsReturned: 0,
        totalRecords: 0
    },
    errorMsg: "",
    isLoading: false,
    clickId: "",
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false
};

const logPostbacksReducer = (state = initialState, action) => {
    switch (action.type) {
        case logPostbacksConstants.LOG_POSTBACKS_SET_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case logPostbacksConstants.LOG_POSTBACKS_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    recordsReturned: action?.result?.recordsReturned,
                    totalRecords: action?.result?.totalRecords
                },
                errorMsg: '',
                isLoading: false
            };
        case logPostbacksConstants.LOG_POSTBACKS_LOADING:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    recordsReturned: action?.result?.recordsReturned,
                    totalRecords: action?.result?.totalRecords
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case logPostbacksConstants.LOG_POSTBACKS_LOADING_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load postbacks log.',
                isLoading: false,
            };
        case logPostbacksConstants.LOG_POSTBACKS_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case logPostbacksConstants.LOG_POSTBACKS_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case logPostbacksConstants.LOG_POSTBACKS_UPDATE_CLICK_ID:
            return {
                ...state,
                clickId: action.clickId
            };
        case logPostbacksConstants.LOG_POSTBACKS_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case logPostbacksConstants.LOG_POSTBACKS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        default:
            return state;
    }
};

export default logPostbacksReducer;