export const timezones = [
    {
        "timezone": "Pacific/Niue",
        "offset": "(GMT-11:00) Niue"
    },
    {
        "timezone": "Pacific/Pago_Pago",
        "offset": "(GMT-11:00) Pago Pago"
    },
    {
        "timezone": "Pacific/Honolulu",
        "offset": "(GMT-10:00) Hawaii Time"
    },
    {
        "timezone": "Pacific/Rarotonga",
        "offset": "(GMT-10:00) Rarotonga"
    },
    {
        "timezone": "Pacific/Tahiti",
        "offset": "(GMT-10:00) Tahiti"
    },
    {
        "timezone": "America/Anchorage",
        "offset": "(GMT-09:00) Alaska Time"
    },
    {
        "timezone": "Pacific/Gambier",
        "offset": "(GMT-09:00) Gambier"
    },
    {
        "timezone": "America/Los_Angeles",
        "offset": "(GMT-08:00) Pacific Time"
    },
    {
        "timezone": "America/Tijuana",
        "offset": "(GMT-08:00) Pacific Time - Tijuana"
    },
    {
        "timezone": "America/Vancouver",
        "offset": "(GMT-08:00) Pacific Time - Vancouver"
    },
    {
        "timezone": "America/Whitehorse",
        "offset": "(GMT-08:00) Pacific Time - Whitehorse"
    },
    {
        "timezone": "Pacific/Pitcairn",
        "offset": "(GMT-08:00) Pitcairn"
    },
    {
        "timezone": "America/Dawson_Creek",
        "offset": "(GMT-07:00) Mountain Time - Dawson Creek"
    },
    {
        "timezone": "America/Denver",
        "offset": "(GMT-07:00) Mountain Time"
    },
    {
        "timezone": "America/Edmonton",
        "offset": "(GMT-07:00) Mountain Time - Edmonton"
    },
    {
        "timezone": "America/Hermosillo",
        "offset": "(GMT-07:00) Mountain Time - Hermosillo"
    },
    {
        "timezone": "America/Mazatlan",
        "offset": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan"
    },
    {
        "timezone": "America/Phoenix",
        "offset": "(GMT-07:00) Mountain Time - Arizona"
    },
    {
        "timezone": "America/Yellowknife",
        "offset": "(GMT-07:00) Mountain Time - Yellowknife"
    },
    {
        "timezone": "America/Belize",
        "offset": "(GMT-06:00) Belize"
    },
    {
        "timezone": "America/Chicago",
        "offset": "(GMT-06:00) Central Time"
    },
    {
        "timezone": "America/Costa_Rica",
        "offset": "(GMT-06:00) Costa Rica"
    },
    {
        "timezone": "America/El_Salvador",
        "offset": "(GMT-06:00) El Salvador"
    },
    {
        "timezone": "America/Guatemala",
        "offset": "(GMT-06:00) Guatemala"
    },
    {
        "timezone": "America/Managua",
        "offset": "(GMT-06:00) Managua"
    },
    {
        "timezone": "America/Mexico_City",
        "offset": "(GMT-06:00) Central Time - Mexico City"
    },
    {
        "timezone": "America/Regina",
        "offset": "(GMT-06:00) Central Time - Regina"
    },
    {
        "timezone": "America/Tegucigalpa",
        "offset": "(GMT-06:00) Central Time - Tegucigalpa"
    },
    {
        "timezone": "America/Winnipeg",
        "offset": "(GMT-06:00) Central Time - Winnipeg"
    },
    {
        "timezone": "Pacific/Galapagos",
        "offset": "(GMT-06:00) Galapagos"
    },
    {
        "timezone": "America/Bogota",
        "offset": "(GMT-05:00) Bogota"
    },
    {
        "timezone": "America/Cancun",
        "offset": "(GMT-05:00) America Cancun"
    },
    {
        "timezone": "America/Cayman",
        "offset": "(GMT-05:00) Cayman"
    },
    {
        "timezone": "America/Guayaquil",
        "offset": "(GMT-05:00) Guayaquil"
    },
    {
        "timezone": "America/Havana",
        "offset": "(GMT-05:00) Havana"
    },
    {
        "timezone": "America/Iqaluit",
        "offset": "(GMT-05:00) Eastern Time - Iqaluit"
    },
    {
        "timezone": "America/Jamaica",
        "offset": "(GMT-05:00) Jamaica"
    },
    {
        "timezone": "America/Lima",
        "offset": "(GMT-05:00) Lima"
    },
    {
        "timezone": "America/Nassau",
        "offset": "(GMT-05:00) Nassau"
    },
    {
        "timezone": "America/New_York",
        "offset": "(GMT-05:00) Eastern Time"
    },
    {
        "timezone": "America/Panama",
        "offset": "(GMT-05:00) Panama"
    },
    {
        "timezone": "America/Port-au-Prince",
        "offset": "(GMT-05:00) Port-au-Prince"
    },
    {
        "timezone": "America/Rio_Branco",
        "offset": "(GMT-05:00) Rio Branco"
    },
    {
        "timezone": "America/Toronto",
        "offset": "(GMT-05:00) Eastern Time - Toronto"
    },
    {
        "timezone": "Pacific/Easter",
        "offset": "(GMT-05:00) Easter Island"
    },
    {
        "timezone": "America/Asuncion",
        "offset": "(GMT-03:00) Asuncion"
    },
    {
        "timezone": "America/Barbados",
        "offset": "(GMT-04:00) Barbados"
    },
    {
        "timezone": "America/Boa_Vista",
        "offset": "(GMT-04:00) Boa Vista"
    },
    {
        "timezone": "America/Campo_Grande",
        "offset": "(GMT-03:00) Campo Grande"
    },
    {
        "timezone": "America/Cuiaba",
        "offset": "(GMT-03:00) Cuiaba"
    },
    {
        "timezone": "America/Curacao",
        "offset": "(GMT-04:00) Curacao"
    },
    {
        "timezone": "America/Grand_Turk",
        "offset": "(GMT-04:00) Grand Turk"
    },
    {
        "timezone": "America/Guyana",
        "offset": "(GMT-04:00) Guyana"
    },
    {
        "timezone": "America/Halifax",
        "offset": "(GMT-04:00) Atlantic Time - Halifax"
    },
    {
        "timezone": "America/La_Paz",
        "offset": "(GMT-04:00) La Paz"
    },
    {
        "timezone": "America/Manaus",
        "offset": "(GMT-04:00) Manaus"
    },
    {
        "timezone": "America/Martinique",
        "offset": "(GMT-04:00) Martinique"
    },
    {
        "timezone": "America/Port_of_Spain",
        "offset": "(GMT-04:00) Port of Spain"
    },
    {
        "timezone": "America/Porto_Velho",
        "offset": "(GMT-04:00) Porto Velho"
    },
    {
        "timezone": "America/Puerto_Rico",
        "offset": "(GMT-04:00) Puerto Rico"
    },
    {
        "timezone": "America/Santo_Domingo",
        "offset": "(GMT-04:00) Santo Domingo"
    },
    {
        "timezone": "America/Thule",
        "offset": "(GMT-04:00) Thule"
    },
    {
        "timezone": "Atlantic/Bermuda",
        "offset": "(GMT-04:00) Bermuda"
    },
    {
        "timezone": "America/Araguaina",
        "offset": "(GMT-03:00) Araguaina"
    },
    {
        "timezone": "America/Argentina/Buenos_Aires",
        "offset": "(GMT-03:00) Buenos Aires"
    },
    {
        "timezone": "America/Bahia",
        "offset": "(GMT-03:00) Salvador"
    },
    {
        "timezone": "America/Belem",
        "offset": "(GMT-03:00) Belem"
    },
    {
        "timezone": "America/Cayenne",
        "offset": "(GMT-03:00) Cayenne"
    },
    {
        "timezone": "America/Fortaleza",
        "offset": "(GMT-03:00) Fortaleza"
    },
    {
        "timezone": "America/Godthab",
        "offset": "(GMT-03:00) Godthab"
    },
    {
        "timezone": "America/Maceio",
        "offset": "(GMT-03:00) Maceio"
    },
    {
        "timezone": "America/Miquelon",
        "offset": "(GMT-03:00) Miquelon"
    },
    {
        "timezone": "America/Montevideo",
        "offset": "(GMT-03:00) Montevideo"
    },
    {
        "timezone": "America/Paramaribo",
        "offset": "(GMT-03:00) Paramaribo"
    },
    {
        "timezone": "America/Recife",
        "offset": "(GMT-03:00) Recife"
    },
    {
        "timezone": "America/Santiago",
        "offset": "(GMT-03:00) Santiago"
    },
    {
        "timezone": "America/Sao_Paulo",
        "offset": "(GMT-02:00) Sao Paulo"
    },
    {
        "timezone": "Antarctica/Palmer",
        "offset": "(GMT-03:00) Palmer"
    },
    {
        "timezone": "Antarctica/Rothera",
        "offset": "(GMT-03:00) Rothera"
    },
    {
        "timezone": "Atlantic/Stanley",
        "offset": "(GMT-03:00) Stanley"
    },
    {
        "timezone": "America/Noronha",
        "offset": "(GMT-02:00) Noronha"
    },
    {
        "timezone": "Atlantic/South_Georgia",
        "offset": "(GMT-02:00) South Georgia"
    },
    {
        "timezone": "America/Scoresbysund",
        "offset": "(GMT-01:00) Scoresbysund"
    },
    {
        "timezone": "Atlantic/Azores",
        "offset": "(GMT-01:00) Azores"
    },
    {
        "timezone": "Atlantic/Cape_Verde",
        "offset": "(GMT-01:00) Cape Verde"
    },
    {
        "timezone": "Africa/Abidjan",
        "offset": "(GMT+00:00) Abidjan"
    },
    {
        "timezone": "Africa/Accra",
        "offset": "(GMT+00:00) Accra"
    },
    {
        "timezone": "Africa/Bissau",
        "offset": "(GMT+00:00) Bissau"
    },
    {
        "timezone": "Africa/Casablanca",
        "offset": "(GMT+00:00) Casablanca"
    },
    {
        "timezone": "Africa/El_Aaiun",
        "offset": "(GMT+00:00) El Aaiun"
    },
    {
        "timezone": "Africa/Monrovia",
        "offset": "(GMT+00:00) Monrovia"
    },
    {
        "timezone": "America/Danmarkshavn",
        "offset": "(GMT+00:00) Danmarkshavn"
    },
    {
        "timezone": "Atlantic/Canary",
        "offset": "(GMT+00:00) Canary Islands"
    },
    {
        "timezone": "Atlantic/Faroe",
        "offset": "(GMT+00:00) Faeroe"
    },
    {
        "timezone": "Atlantic/Reykjavik",
        "offset": "(GMT+00:00) Reykjavik"
    },
    {
        "timezone": "Etc/UTC",
        "offset": "(GMT+00:00) UTC (no daylight saving)"
    },
    {
        "timezone": "Europe/Dublin",
        "offset": "(GMT+00:00) Dublin"
    },
    {
        "timezone": "Europe/Lisbon",
        "offset": "(GMT+00:00) Lisbon"
    },
    {
        "timezone": "Europe/London",
        "offset": "(GMT+00:00) London"
    },
    {
        "timezone": "Africa/Algiers",
        "offset": "(GMT+01:00) Algiers"
    },
    {
        "timezone": "Africa/Ceuta",
        "offset": "(GMT+01:00) Ceuta"
    },
    {
        "timezone": "Africa/Lagos",
        "offset": "(GMT+01:00) Lagos"
    },
    {
        "timezone": "Africa/Ndjamena",
        "offset": "(GMT+01:00) Ndjamena"
    },
    {
        "timezone": "Africa/Tunis",
        "offset": "(GMT+01:00) Tunis"
    },
    {
        "timezone": "Africa/Windhoek",
        "offset": "(GMT+02:00) Windhoek"
    },
    {
        "timezone": "Europe/Amsterdam",
        "offset": "(GMT+01:00) Amsterdam"
    },
    {
        "timezone": "Europe/Andorra",
        "offset": "(GMT+01:00) Andorra"
    },
    {
        "timezone": "Europe/Belgrade",
        "offset": "(GMT+01:00) Central European Time - Belgrade"
    },
    {
        "timezone": "Europe/Berlin",
        "offset": "(GMT+01:00) Berlin"
    },
    {
        "timezone": "Europe/Brussels",
        "offset": "(GMT+01:00) Brussels"
    },
    {
        "timezone": "Europe/Budapest",
        "offset": "(GMT+01:00) Budapest"
    },
    {
        "timezone": "Europe/Copenhagen",
        "offset": "(GMT+01:00) Copenhagen"
    },
    {
        "timezone": "Europe/Gibraltar",
        "offset": "(GMT+01:00) Gibraltar"
    },
    {
        "timezone": "Europe/Luxembourg",
        "offset": "(GMT+01:00) Luxembourg"
    },
    {
        "timezone": "Europe/Madrid",
        "offset": "(GMT+01:00) Madrid"
    },
    {
        "timezone": "Europe/Malta",
        "offset": "(GMT+01:00) Malta"
    },
    {
        "timezone": "Europe/Monaco",
        "offset": "(GMT+01:00) Monaco"
    },
    {
        "timezone": "Europe/Oslo",
        "offset": "(GMT+01:00) Oslo"
    },
    {
        "timezone": "Europe/Paris",
        "offset": "(GMT+01:00) Paris"
    },
    {
        "timezone": "Europe/Prague",
        "offset": "(GMT+01:00) Central European Time - Prague"
    },
    {
        "timezone": "Europe/Rome",
        "offset": "(GMT+01:00) Rome"
    },
    {
        "timezone": "Europe/Stockholm",
        "offset": "(GMT+01:00) Stockholm"
    },
    {
        "timezone": "Europe/Tirane",
        "offset": "(GMT+01:00) Tirane"
    },
    {
        "timezone": "Europe/Vienna",
        "offset": "(GMT+01:00) Vienna"
    },
    {
        "timezone": "Europe/Warsaw",
        "offset": "(GMT+01:00) Warsaw"
    },
    {
        "timezone": "Europe/Zurich",
        "offset": "(GMT+01:00) Zurich"
    },
    {
        "timezone": "Africa/Cairo",
        "offset": "(GMT+02:00) Cairo"
    },
    {
        "timezone": "Africa/Johannesburg",
        "offset": "(GMT+02:00) Johannesburg"
    },
    {
        "timezone": "Africa/Maputo",
        "offset": "(GMT+02:00) Maputo"
    },
    {
        "timezone": "Africa/Tripoli",
        "offset": "(GMT+02:00) Tripoli"
    },
    {
        "timezone": "Asia/Amman",
        "offset": "(GMT+02:00) Amman"
    },
    {
        "timezone": "Asia/Beirut",
        "offset": "(GMT+02:00) Beirut"
    },
    {
        "timezone": "Asia/Damascus",
        "offset": "(GMT+02:00) Damascus"
    },
    {
        "timezone": "Asia/Gaza",
        "offset": "(GMT+02:00) Gaza"
    },
    {
        "timezone": "Asia/Jerusalem",
        "offset": "(GMT+02:00) Jerusalem"
    },
    {
        "timezone": "Asia/Nicosia",
        "offset": "(GMT+02:00) Nicosia"
    },
    {
        "timezone": "Europe/Athens",
        "offset": "(GMT+02:00) Athens"
    },
    {
        "timezone": "Europe/Bucharest",
        "offset": "(GMT+02:00) Bucharest"
    },
    {
        "timezone": "Europe/Chisinau",
        "offset": "(GMT+02:00) Chisinau"
    },
    {
        "timezone": "Europe/Helsinki",
        "offset": "(GMT+02:00) Helsinki"
    },
    {
        "timezone": "Europe/Istanbul",
        "offset": "(GMT+02:00) Istanbul"
    },
    {
        "timezone": "Europe/Kaliningrad",
        "offset": "(GMT+02:00) Moscow-01 - Kaliningrad"
    },
    {
        "timezone": "Europe/Kiev",
        "offset": "(GMT+02:00) Kiev"
    },
    {
        "timezone": "Europe/Riga",
        "offset": "(GMT+02:00) Riga"
    },
    {
        "timezone": "Europe/Sofia",
        "offset": "(GMT+02:00) Sofia"
    },
    {
        "timezone": "Europe/Tallinn",
        "offset": "(GMT+02:00) Tallinn"
    },
    {
        "timezone": "Europe/Vilnius",
        "offset": "(GMT+02:00) Vilnius"
    },
    {
        "timezone": "Africa/Khartoum",
        "offset": "(GMT+03:00) Khartoum"
    },
    {
        "timezone": "Africa/Nairobi",
        "offset": "(GMT+03:00) Nairobi"
    },
    {
        "timezone": "Antarctica/Syowa",
        "offset": "(GMT+03:00) Syowa"
    },
    {
        "timezone": "Asia/Baghdad",
        "offset": "(GMT+03:00) Baghdad"
    },
    {
        "timezone": "Asia/Qatar",
        "offset": "(GMT+03:00) Qatar"
    },
    {
        "timezone": "Asia/Riyadh",
        "offset": "(GMT+03:00) Riyadh"
    },
    {
        "timezone": "Europe/Minsk",
        "offset": "(GMT+03:00) Minsk"
    },
    {
        "timezone": "Europe/Moscow",
        "offset": "(GMT+03:00) Moscow+00 - Moscow"
    },
    {
        "timezone": "Asia/Baku",
        "offset": "(GMT+04:00) Baku"
    },
    {
        "timezone": "Asia/Dubai",
        "offset": "(GMT+04:00) Dubai"
    },
    {
        "timezone": "Asia/Tbilisi",
        "offset": "(GMT+04:00) Tbilisi"
    },
    {
        "timezone": "Asia/Yerevan",
        "offset": "(GMT+04:00) Yerevan"
    },
    {
        "timezone": "Europe/Samara",
        "offset": "(GMT+04:00) Moscow+01 - Samara"
    },
    {
        "timezone": "Indian/Mahe",
        "offset": "(GMT+04:00) Mahe"
    },
    {
        "timezone": "Indian/Mauritius",
        "offset": "(GMT+04:00) Mauritius"
    },
    {
        "timezone": "Indian/Reunion",
        "offset": "(GMT+04:00) Reunion"
    },
    {
        "timezone": "Antarctica/Mawson",
        "offset": "(GMT+05:00) Mawson"
    },
    {
        "timezone": "Asia/Aqtau",
        "offset": "(GMT+05:00) Aqtau"
    },
    {
        "timezone": "Asia/Aqtobe",
        "offset": "(GMT+05:00) Aqtobe"
    },
    {
        "timezone": "Asia/Ashgabat",
        "offset": "(GMT+05:00) Ashgabat"
    },
    {
        "timezone": "Asia/Dushanbe",
        "offset": "(GMT+05:00) Dushanbe"
    },
    {
        "timezone": "Asia/Karachi",
        "offset": "(GMT+05:00) Karachi"
    },
    {
        "timezone": "Asia/Tashkent",
        "offset": "(GMT+05:00) Tashkent"
    },
    {
        "timezone": "Asia/Yekaterinburg",
        "offset": "(GMT+05:00) Moscow+02 - Yekaterinburg"
    },
    {
        "timezone": "Indian/Kerguelen",
        "offset": "(GMT+05:00) Kerguelen"
    },
    {
        "timezone": "Indian/Maldives",
        "offset": "(GMT+05:00) Maldives"
    },
    {
        "timezone": "Antarctica/Vostok",
        "offset": "(GMT+06:00) Vostok"
    },
    {
        "timezone": "Asia/Almaty",
        "offset": "(GMT+06:00) Almaty"
    },
    {
        "timezone": "Asia/Bishkek",
        "offset": "(GMT+06:00) Bishkek"
    },
    {
        "timezone": "Asia/Dhaka",
        "offset": "(GMT+06:00) Dhaka"
    },
    {
        "timezone": "Asia/Omsk",
        "offset": "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk"
    },
    {
        "timezone": "Asia/Thimphu",
        "offset": "(GMT+06:00) Thimphu"
    },
    {
        "timezone": "Indian/Chagos",
        "offset": "(GMT+06:00) Chagos"
    },
    {
        "timezone": "Antarctica/Davis",
        "offset": "(GMT+07:00) Davis"
    },
    {
        "timezone": "Asia/Bangkok",
        "offset": "(GMT+07:00) Bangkok"
    },
    {
        "timezone": "Asia/Hovd",
        "offset": "(GMT+07:00) Hovd"
    },
    {
        "timezone": "Asia/Jakarta",
        "offset": "(GMT+07:00) Jakarta"
    },
    {
        "timezone": "Asia/Krasnoyarsk",
        "offset": "(GMT+07:00) Moscow+04 - Krasnoyarsk"
    },
    {
        "timezone": "Asia/Saigon",
        "offset": "(GMT+07:00) Hanoi"
    },
    {
        "timezone": "Asia/Ho_Chi_Minh",
        "offset": "(GMT+07:00) Ho Chi Minh"
    },
    {
        "timezone": "Indian/Christmas",
        "offset": "(GMT+07:00) Christmas"
    },
    {
        "timezone": "Antarctica/Casey",
        "offset": "(GMT+08:00) Casey"
    },
    {
        "timezone": "Asia/Brunei",
        "offset": "(GMT+08:00) Brunei"
    },
    {
        "timezone": "Asia/Choibalsan",
        "offset": "(GMT+08:00) Choibalsan"
    },
    {
        "timezone": "Asia/Hong_Kong",
        "offset": "(GMT+08:00) Hong Kong"
    },
    {
        "timezone": "Asia/Irkutsk",
        "offset": "(GMT+08:00) Moscow+05 - Irkutsk"
    },
    {
        "timezone": "Asia/Kuala_Lumpur",
        "offset": "(GMT+08:00) Kuala Lumpur"
    },
    {
        "timezone": "Asia/Macau",
        "offset": "(GMT+08:00) Macau"
    },
    {
        "timezone": "Asia/Makassar",
        "offset": "(GMT+08:00) Makassar"
    },
    {
        "timezone": "Asia/Manila",
        "offset": "(GMT+08:00) Manila"
    },
    {
        "timezone": "Asia/Shanghai",
        "offset": "(GMT+08:00) China Time - Beijing"
    },
    {
        "timezone": "Asia/Singapore",
        "offset": "(GMT+08:00) Singapore"
    },
    {
        "timezone": "Asia/Taipei",
        "offset": "(GMT+08:00) Taipei"
    },
    {
        "timezone": "Asia/Ulaanbaatar",
        "offset": "(GMT+08:00) Ulaanbaatar"
    },
    {
        "timezone": "Australia/Perth",
        "offset": "(GMT+08:00) Western Time - Perth"
    },
    {
        "timezone": "Asia/Dili",
        "offset": "(GMT+09:00) Dili"
    },
    {
        "timezone": "Asia/Jayapura",
        "offset": "(GMT+09:00) Jayapura"
    },
    {
        "timezone": "Asia/Seoul",
        "offset": "(GMT+09:00) Seoul"
    },
    {
        "timezone": "Asia/Tokyo",
        "offset": "(GMT+09:00) Tokyo"
    },
    {
        "timezone": "Asia/Yakutsk",
        "offset": "(GMT+09:00) Moscow+06 - Yakutsk"
    },
    {
        "timezone": "Pacific/Palau",
        "offset": "(GMT+09:00) Palau"
    },
    {
        "timezone": "Antarctica/DumontDUrville",
        "offset": "(GMT+10:00) Dumont D'Urville"
    },
    {
        "timezone": "Asia/Magadan",
        "offset": "(GMT+10:00) Moscow+07 - Magadan"
    },
    {
        "timezone": "Asia/Vladivostok",
        "offset": "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk"
    },
    {
        "timezone": "Australia/Brisbane",
        "offset": "(GMT+10:00) Eastern Time - Brisbane"
    },
    {
        "timezone": "Australia/Hobart",
        "offset": "(GMT+11:00) Eastern Time - Hobart"
    },
    {
        "timezone": "Australia/Sydney",
        "offset": "(GMT+11:00) Eastern Time - Melbourne, Sydney"
    },
    {
        "timezone": "Pacific/Chuuk",
        "offset": "(GMT+10:00) Truk"
    },
    {
        "timezone": "Pacific/Guam",
        "offset": "(GMT+10:00) Guam"
    },
    {
        "timezone": "Pacific/Port_Moresby",
        "offset": "(GMT+10:00) Port Moresby"
    },
    {
        "timezone": "Pacific/Efate",
        "offset": "(GMT+11:00) Efate"
    },
    {
        "timezone": "Pacific/Guadalcanal",
        "offset": "(GMT+11:00) Guadalcanal"
    },
    {
        "timezone": "Pacific/Kosrae",
        "offset": "(GMT+11:00) Kosrae"
    },
    {
        "timezone": "Pacific/Norfolk",
        "offset": "(GMT+11:00) Norfolk"
    },
    {
        "timezone": "Pacific/Noumea",
        "offset": "(GMT+11:00) Noumea"
    },
    {
        "timezone": "Pacific/Pohnpei",
        "offset": "(GMT+11:00) Ponape"
    },
    {
        "timezone": "Asia/Kamchatka",
        "offset": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy"
    },
    {
        "timezone": "Pacific/Auckland",
        "offset": "(GMT+13:00) Auckland"
    },
    {
        "timezone": "Pacific/Fiji",
        "offset": "(GMT+13:00) Fiji"
    },
    {
        "timezone": "Pacific/Funafuti",
        "offset": "(GMT+12:00) Funafuti"
    },
    {
        "timezone": "Pacific/Kwajalein",
        "offset": "(GMT+12:00) Kwajalein"
    },
    {
        "timezone": "Pacific/Majuro",
        "offset": "(GMT+12:00) Majuro"
    },
    {
        "timezone": "Pacific/Nauru",
        "offset": "(GMT+12:00) Nauru"
    },
    {
        "timezone": "Pacific/Tarawa",
        "offset": "(GMT+12:00) Tarawa"
    },
    {
        "timezone": "Pacific/Wake",
        "offset": "(GMT+12:00) Wake"
    },
    {
        "timezone": "Pacific/Wallis",
        "offset": "(GMT+12:00) Wallis"
    },
    {
        "timezone": "Pacific/Apia",
        "offset": "(GMT+14:00) Apia"
    },
    {
        "timezone": "Pacific/Enderbury",
        "offset": "(GMT+13:00) Enderbury"
    },
    {
        "timezone": "Pacific/Fakaofo",
        "offset": "(GMT+13:00) Fakaofo"
    },
    {
        "timezone": "Pacific/Tongatapu",
        "offset": "(GMT+13:00) Tongatapu"
    },
    {
        "timezone": "Pacific/Kiritimati",
        "offset": "(GMT+14:00) Kiritimati"
    }
];
