import {metricFilterConstants} from "../constants/metricFilterConstants";

const initialState = {
    filters: []
};

const metricFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case metricFilterConstants.METRIC_FILTER_SET:
            return {
                ...state,
                filters: action?.metricFilters
            };
        case metricFilterConstants.METRIC_FILTER_ADD:
            let metricFilters = [...state.filters];
            for (const metric in action?.metric) {
                metricFilters = metricFilters.filter((obj) => !obj.hasOwnProperty(metric))
            }
            return {
                ...state,
                filters: [...metricFilters, action?.metric]
            };
        case metricFilterConstants.METRIC_FILTER_REMOVE:
            return {
                ...state,
                filters: state?.filters.filter((obj) => !obj.hasOwnProperty(action.key))
            };
        default:
            return state;
    }
};

export default metricFilterReducer;