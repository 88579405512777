import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {campaignActions} from "../../store/actions/campaignsAction";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {
    Alert, Box,
    Dialog,
    DialogTitle, Step,
    StepConnector,
    StepLabel,
    Stepper,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {FormStep1} from "./FormStep1";
import {FormStep2} from "./FormStep2";
import FormStep3 from "./FormStep3";
import {flowActions} from "../../store/actions/flowAction";
import {alertActions} from "../../store/actions/alertActions";
import useCustomStyles from "../utils/UseCustomStyles";

const customStyles = (theme) => ({
    formHeight: {
        minHeight: "300px"
    },
    textArea: {
        width: "100%",
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontSize: 16,
        outlined: "",
        padding: 10.5,
        borderRadius: 4,
        "&:focus": {
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
            outline: 0
        }
    },
    toolTipHelp: theme.toolTipHelp,
    root: {
        width: '100%',
    },
    cancelButton: {
        marginRight: theme.spacing(2),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    bottomBorder: {
        borderBottom: "1px solid rgba(81, 81, 81, 1)"
    },
    flowBox: {
        minWidth: 350,
        marginBottom: 20,
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        }
    },
    pathHeader: {
        height: 48,
        alignItems: "center",
        borderBottom: "1px solid rgba(81, 81, 81, 1)"
    },
    pathTitle: {
        fontSize: 16,
        fontWeight: "bold"
    },
    disabledPathTitle: {
        color: "#656a6e"
    },
    paths: {
        height: 56,
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.background.hover
        }
    },
    itemsList: {
        height: 50
    },
    divider: {
        height: 28,
        margin: 4,
    },
    errorMsg: {
        margin: "0 24px"
    },
    stepper: {
        margin: "0 auto",
        width: "100%",
        maxWidth: 800,
        padding: "8px 24px 24px 24px"
    },
    stepConnectorLine: {
        borderColor: theme.palette.background.tableBorder
    },
    sectionTitle: {
        padding: 0,
        margin: 0,
        fontSize: 14,
        fontWeight: 600
    },
    verticalDivider: {
        width: 2,
        margin: "0 24px",
        [theme.breakpoints.down(1250)]: {
            display: "none",
            margin: 0,
        }
    },
    treeViewBox: {
        minWidth: "50%",
        maxWidth: "50%",
        [theme.breakpoints.down(1250)]: {
            width: "100%",
            maxWidth: "100%",
        }
    },
    treeView: {
        paddingBottom: theme.spacing(2),
        fontSize: 16,
    },
    treeItem: {
        paddingBottom: theme.spacing(1)
    },
    treeItemLabel: {
        wordWrap: "break-word !important",
        whiteSpace: "normal !important",
        fontSize: "14px !important",
        fontWeight: "400 !important"
    },
    treeItemContent: {
        paddingBottom: theme.spacing(1.5),
    },
    treeViewWeight: {
        color: theme.palette.primary.main
    }
});

function getSteps() {
    return ['Traffic source', 'Campaign details', 'Destination'];
}

function AddCampaign(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const steps = getSteps();
    const [open, setOpen] = useState(true);

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        props.updateSharedFlow(null);
        props.resetForm();
        setOpen(false);
        props.history.push({pathname: "/campaigns", state: {from: props.location}});
    }, [props]);

    const handleBack = () => {
        props.formStepBack(props.formState.activeStep - 1);
    };

    const {formState} = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (formState?.id !== 0 && formState?.id !== undefined && formState?.activeStep === 3) {
            props.updateSharedFlow(null);
            props.resetForm();
            setOpen(false);
            props.history.push({
                pathname: "/campaigns/links/" + parseInt(formState?.id),
                state: {background: {pathname: "/campaigns"}}
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState?.activeStep, formState?.id, props.updateSharedFlow, props.resetForm, props.history])

    const modalMaxWidth = () => {
        if (formState.activeStep === 2) {
            return "lg";
        } else if (formState.activeStep === 3) {
            return "md";
        } else {
            return "sm"
        }
    };

    return (
        <Box width="100%">
            <Dialog open={open}
                    onClose={handleClose}
                    maxWidth={modalMaxWidth()}
                    fullWidth
                    fullScreen={fullScreen}
                    aria-labelledby="form-dialog-title"
            >
                <DialogTitle sx={{textAlign: "center"}}>
                    <Box>{formState?.activeStep === 3 ? "Campaign links" : "New campaign"}</Box>
                    {formState?.activeStep > 1 &&
                        <Box sx={{fontSize: 16, fontWeight: 400, color: "rgb(138, 147, 159)"}}>
                            {formState?.country?.label + " - " + formState?.trafficSource?.label + " - " + formState?.name}
                        </Box>
                    }
                </DialogTitle>

                {formState?.activeStep < 3 &&
                    <Stepper
                        activeStep={formState.activeStep}
                        connector={<StepConnector classes={{line: classes?.stepConnectorLine}}/>}
                        classes={{root: classes?.stepper}}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                }

                {formState.errorMsg.length > 0 &&
                    <Alert sx={{m: "0 24px"}} variant="filled" severity="error">{formState.errorMsg}</Alert>
                }

                {formState.activeStep === 0 &&
                    <FormStep1 {...props} handleClose={handleClose} actionName="create"/>
                }

                {formState.activeStep === 1 &&
                    <FormStep2 {...props} handleBack={handleBack} handleClose={handleClose}/>
                }

                {formState.activeStep === 2 &&
                    <FormStep3 handleBack={handleBack} handleClose={handleClose} classes={classes}/>
                }
            </Dialog>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    formState: state.campaignForm,
    alert: state.alert
});

const mapDispatchToProps = {
    rerender: campaignActions.rerender,
    formStep1: campaignFormActions.formStep1,
    formStep2: campaignFormActions.formStep2,
    resetForm: campaignFormActions.resetForm,
    formStepBack: campaignFormActions.formStepBack,
    removeDefaultPath: campaignFormActions.removeDefaultPath,
    updateSelectedPath: campaignFormActions.updateSelectedPath,
    updateSharedFlow: flowActions.updateSharedFlow,
    error: alertActions.error,
    updateTrafficSource: campaignFormActions.updateTrafficSource,
    updateConversionActionEvent: campaignFormActions.updateConversionActionEvent,
    updateConversionActionPixel: campaignFormActions.updateConversionActionPixel,
    resetConversionActionEventAndPixel: campaignFormActions.resetConversionActionEventAndPixel,
    updateTrafficSourceIntegration: campaignFormActions.updateTrafficSourceIntegration,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCampaign);
