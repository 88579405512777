import React, {createRef, useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import DataGrid, {SelectColumn} from 'react-data-grid';
import Spinner from "../spinner/Spinner";
import CustomSnackbar from "../alert/CustomSnackbar";
import {debounce} from "lodash";
import {
    EmptyRowsRenderer,
    IsAtBottom
} from "../reporting-table/ReactDataGridUtils";
import {workspaceActions} from "../../store/actions/workspaceAction";
import {Box, Button, Chip, Divider, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import 'react-data-grid/lib/styles.css';
import useCustomStyles from "../utils/UseCustomStyles";
import {websiteDomainsActions} from "../../store/actions/websiteDomainsAction";
import {styled} from "@mui/system";
import moment from "moment/moment";
import {useHistory, useLocation} from "react-router-dom";
import {alertActions} from "../../store/actions/alertActions";
import AlertDialog from "../alert/AlertDialog";
import {Add, Delete, Public, Sync} from "@mui/icons-material";

const customStyles = (theme) => ({
    dataGrid: theme.clicksReportDataGrid
});

const DomainTableContainer = styled(Box)(({theme}) => ({
    //height: "calc(var(--app-height) - 190px)",
    //width: "100%",
    [theme.breakpoints.down(1000)]: {
        height: "100%",
    }
}));

function DomainsTable(props) {

    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const history = useHistory();
    const location = useLocation();

    const down500 = useMediaQuery(theme.breakpoints.down(500));

    const [prompt, setPrompt] = useState(false);
    const [deleteDomainId, setDeleteDomainId] = useState(0);
    const [selectedRows, setSelectedRows] = useState(() => new Set());

    const {
        alert, getReport, updateSortBy, updateOrderBy, updateSortColumns, loadMoreRows,
        getWorkspaceIDs, resetUpdatedWorkspaceValue, verifyDomain, deleteDomainAndCert, alertError
    } = props;
    const {data, isLoading, domain, orderBy, sortBy, sortColumns, rerender} = props?.websiteDomainsState;
    const {plan} = props?.auth?.subscription;

    const getColumns = () => {
        let cachedColumns = JSON.parse(localStorage.getItem("website-domains-columns"));
        let columns = [
            SelectColumn,
            {key: 'id', name: 'ID', hidden: false, width: 70, frozen: false, sortable: true, resizable: true},
            {
                key: 'workspace',
                name: 'Workspace',
                hidden: false,
                width: 105,
                sortable: true,
                draggable: true,
                resizable: true
            },
            {
                key: 'integration',
                name: 'Integration',
                hidden: false,
                width: 115,
                sortable: true,
                draggable: true,
                resizable: true
            },
            {
                key: 'domain',
                name: 'Domain',
                hidden: false,
                width: 200,
                sortable: true,
                draggable: true,
                resizable: true
            },
            {
                key: 'status',
                name: 'Status',
                hidden: false,
                width: 140,
                sortable: true,
                draggable: true,
                resizable: true,
                renderCell({row}) {
                    if (row?.status === "ISSUED") {
                        return <Chip label={row?.status} variant="outlined" color="primary"
                                     sx={{height: "24px", marginTop: 0}}/>
                    } else {
                        return <Chip label={row?.status} variant="outlined" color="warning" sx={{height: "20px"}}/>
                    }
                }
            },
            {
                key: 'created',
                name: 'Created At',
                hidden: false,
                width: 190,
                sortable: true,
                draggable: true,
                resizable: true,
                renderCell({row}) {
                    return row?.createdAt === null ? "-" : moment.utc(row?.createdAt).format("YYYY-MM-DD HH:mm") + " (UTC)"
                }
            },
        ];

        if (cachedColumns !== null && cachedColumns?.length !== columns.length) {
            return columns;
        }

        if (cachedColumns !== null && cachedColumns !== "" && cachedColumns !== undefined) {
            let sortedColumns = [];
            cachedColumns?.forEach(cachedCol => {
                columns?.forEach(defCol => {
                    if (cachedCol?.key === defCol?.key) {
                        defCol.width = cachedCol.width;
                        sortedColumns.push(defCol);
                    }
                });
            });
            return sortedColumns;
        }
        localStorage.setItem("website-domains-columns", JSON.stringify(columns));
        return columns;
    };

    const handleDeletePrompt = () => {
        if (plan === "Free") {
            alertError("This feature is not available in Free subscription plan.");
            return false;
        }

        if (selectedRows.size === 1) {
            for (const selectedRowId of selectedRows) {
                setDeleteDomainId(selectedRowId);
                setPrompt(true);
            }
        }
    };

    const handleClickOnCNAMERecords = () => {
        if (plan === "Free") {
            alertError("This feature is not available in Free subscription plan.");
            return false;
        }

        if (selectedRows.size === 1) {
            for (const selectedRowId of selectedRows) {
                history.push({
                    pathname: "/websites/domains/" + selectedRowId,
                    state: {background: location}
                });
            }
        }
    };

    const handleDeleteIntegration = (prompt) => {
        if (!prompt) {
            setPrompt(false);
            return false;
        } else {
            deleteDomainAndCert(deleteDomainId);
            setPrompt(false);
        }
    };

    const handleClickOnVerify = () => {
        if (plan === "Free") {
            alertError("This feature is not available in Free subscription plan.");
            return false;
        }
        if (selectedRows.size === 1) {
            for (const selectedRowId of selectedRows) {
                verifyDomain(selectedRowId);
            }
        }
    };

    const gridRef = createRef(null);

    const [columns, setColumns] = useState(getColumns());

    const onColumnsReorder = (sourceKey, targetKey) => {
        const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
        const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
        const reorderedColumns = [...columns];
        reorderedColumns.splice(targetColumnIndex, 0, reorderedColumns.splice(sourceColumnIndex, 1)[0]);
        localStorage.setItem("website-domains-columns", JSON.stringify(reorderedColumns));
        setColumns(reorderedColumns);
    };

    const handleInfinityScroll = (event) => {
        if (isLoading || !IsAtBottom(event)) {
            return;
        }
        let disable = data.page >= Math.ceil(data?.totalRecords / data?.recordsPerPage);
        if (!disable) {
            loadMoreRows(domain, data.page + 1, orderBy, sortBy, getWorkspaceIDs());
        }
    };

    const handleGetReport = () => {
        gridRef?.current?.element?.scrollTo(0, 0);
        getReport(domain, 1, orderBy, sortBy, getWorkspaceIDs());
    };

    const cacheColumnResize = (idx, width) => {
        let cachedColumns = JSON.parse(localStorage.getItem("website-domains-columns"));
        let modColumns = [];
        cachedColumns?.forEach((item, index) => {
            if (index === idx) {
                item.width = width;
            }
            modColumns.push(item);
        });
        localStorage.setItem("website-domains-columns", JSON.stringify(modColumns));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleColumnResize = useCallback(debounce(cacheColumnResize, 50), []);

    const handleClickOnAddDomain = () => {

        if (plan === "Free") {
            alertError("This feature is not available in Free subscription plan.");
            return false;
        }

        history.push({
            pathname: "/websites/domains/add",
            state: {background: location}
        });
    };

    useEffect(() => {
        handleGetReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getReport, domain, sortBy, orderBy]);

    // Only for rerender
    useEffect(() => {
        if (rerender) {
            handleGetReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerender]);

    useEffect(() => {
        if (sortColumns.length === 0) {
            updateSortBy("");
            updateOrderBy("");
            return () => {
            }
        }

        const {columnKey, direction} = sortColumns[0];
        updateOrderBy(columnKey);
        updateSortBy(direction);

    }, [updateOrderBy, updateSortBy, sortColumns]);

    // 6. Rerender if user workspaces has changed.
    useEffect(() => {
        if (props?.workspace?.updated !== null) {
            handleGetReport();
            resetUpdatedWorkspaceValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.workspace?.updated])

    return (

        <Box display="flex" flexDirection="column">
            <Box
                sx={{
                    fontSize: 16,
                    paddingLeft: 1,
                    lineHeight: 1.167,
                    textAlign: "left",
                    fontWeight: 700,
                    paddingTop: 2,
                    paddingBottom: 2,
                    [theme.breakpoints.down(500)]: {
                        display: "none"
                    }
                }}
            >
                Manage Domains
            </Box>

            <Box sx={{
                paddingLeft: 1, paddingRight: 1, paddingBottom: 1, color: "text.disabledLight",
                [theme.breakpoints.down(500)]: {
                    display: "none"
                }
            }}>
                Easily manage your website domains and SSL certificates. Just add your domain, create a CNAME
                record for verification, and we'll automatically generate a free SSL certificate for you using
                AWS Certificate Manager.
            </Box>

            <Box display="flex" flexDirection="row" flexWrap="noWrap" p={1} sx={{overflow: "auto"}}
            >
                <Box>
                    <Button
                        onClick={handleClickOnAddDomain} type="button" color="primary" variant="contained"
                        startIcon={<Add size="small"/>}
                    >
                        Add
                    </Button>
                </Box>
                <Box pl={1}>
                    {down500 &&
                        <Tooltip title="View CNAME Records" placement="top">
                        <span>
                            <Button
                                sx={{
                                    p: "5px 6px",
                                    minWidth: 30,
                                    minHeight: 34.75
                                }}
                                onClick={handleClickOnVerify}
                                variant="outlined"
                                color="inherit"
                                disabled={selectedRows.size !== 1}
                            >
                                <Sync color={selectedRows.size !== 1 ? "inherit" : "warning"}/>
                            </Button>
                        </span>
                        </Tooltip>
                    }
                    {!down500 &&
                        <Button
                            onClick={handleClickOnVerify}
                            type="button"
                            color={selectedRows.size !== 1 ? "inherit" : "warning"}
                            variant={selectedRows.size !== 1 ? "outlined" : "contained"}
                            startIcon={<Sync size="small"/>}
                            disabled={selectedRows.size !== 1}
                            sx={{
                                minWidth: 142,
                                minHeight: 34.75,
                                maxHeight: 34.75
                            }}
                        >
                            Check Status
                        </Button>
                    }
                </Box>
                <Box pl={1} pr={2}>
                    {down500 &&
                        <Tooltip title="View CNAME Records" placement="top">
                        <span>
                            <Button
                                sx={{
                                    p: "5px 6px",
                                    minWidth: 30,
                                    minHeight: 34.75
                                }}
                                onClick={handleClickOnCNAMERecords}
                                variant="outlined"
                                color="inherit"
                                disabled={selectedRows.size !== 1}
                            >
                                <Public size="small" color={"inherit"}/>
                            </Button>
                        </span>
                        </Tooltip>
                    }
                    {!down500 &&
                        <Button
                            onClick={handleClickOnCNAMERecords}
                            type="button"
                            color="inherit"
                            variant="outlined"
                            startIcon={<Public size="small"/>}
                            disabled={selectedRows.size !== 1}
                            sx={{
                                minWidth: 159,
                                minHeight: 34.75,
                                maxHeight: 34.75
                            }}
                        >
                            CNAME Records
                        </Button>
                    }
                </Box>
                <Divider orientation="vertical" flexItem/>
                <Box pl={2}>
                    <Tooltip title="Delete permanently" placement="top">
                        <span>
                            <Button
                                sx={{
                                    p: "5px 6px",
                                    minWidth: 30,
                                    minHeight: 34.75
                                }}
                                onClick={handleDeletePrompt}
                                variant="outlined"
                                color="inherit"
                                disabled={selectedRows.size !== 1}
                            >
                                <Delete color={selectedRows.size !== 1 ? "inherit" : "error"}/>
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            <Divider/>

            <DomainTableContainer>

                {prompt &&
                    <AlertDialog
                        question="Delete confirmation"
                        text={"Are you sure you want to delete that domain?"}
                        confirm={handleDeleteIntegration}
                    />
                }

                {alert.errorMsg !== "" && <CustomSnackbar message={alert.errorMsg} timeout={8000}/>}
                {alert.successMsg !== "" &&
                    <CustomSnackbar message={alert.successMsg} severity="primary" timeout={8000}/>}

                {isLoading && <Spinner overComponentBox={true}/>}

                {(columns !== null && classes !== null) &&
                    <DataGrid
                        ref={gridRef}
                        rowKeyGetter={(row) => row?.id}
                        columns={columns}
                        onColumnsReorder={onColumnsReorder}
                        rows={data?.records ? data.records : []}
                        defaultColumnOptions={{
                            sortable: true,
                            resizable: true,
                            draggable: true
                        }}
                        onColumnResize={(idx, width) => handleColumnResize(idx, width)}
                        sortColumns={sortColumns}
                        onSortColumnsChange={updateSortColumns}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        onSelectedCellChange={({rowIdx, row, column}) => {
                            if (rowIdx !== -1 && column?.key !== "select-row") {
                                setSelectedRows(new Set().add(row?.id));
                            }
                        }}
                        onScroll={handleInfinityScroll}
                        noRowsFallback={<EmptyRowsRenderer/>}
                        enableVirtualization={false}
                        className={classes?.dataGrid}
                    />
                }
            </DomainTableContainer>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        websiteDomainsState: state.websiteDomains,
        auth: state.auth,
        alert: state.alert,
        workspace: state.workspace
    }
};

const actionCreators = {
    getReport: websiteDomainsActions.read,
    loadMoreRows: websiteDomainsActions.readMoreRows,
    updateOrderBy: websiteDomainsActions.updateOrderBy,
    updateSortBy: websiteDomainsActions.updateSortBy,
    updateDomain: websiteDomainsActions.updateDomain,
    updateSortColumns: websiteDomainsActions.updateSortColumns,
    verifyDomain: websiteDomainsActions.verifyDomain,
    getDomainVerifyRecords: websiteDomainsActions.getDomainVerifyRecords,
    deleteDomainAndCert: websiteDomainsActions.deleteDomainAndCert,
    getWorkspaceIDs: workspaceActions.getWorkspaceIDs,
    resetUpdatedWorkspaceValue: workspaceActions.resetUpdatedWorkspaceValue,
    alertError: alertActions.error,
};

export default compose(connect(mapStateToProps, actionCreators))(DomainsTable);