import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Box, Button, Popover, Tooltip, IconButton} from "@mui/material";
import ReactHookFormTextField from "../input/ReactHookFormTextField";
import {Edit} from "@mui/icons-material";

function AdjustCPCForm({row, handleOutbrainCpcAdjustment}) {

    const [open, setOpen] = useState(null);

    const {handleSubmit, register, setValue, control, formState: {errors}} = useForm();

    const handleOnClickToOpen = (target) => setOpen(target);
    const handleOnClose = () => setOpen(null);

    const handleOnSubmit = (data) => {
        handleOutbrainCpcAdjustment(data?.adjustCpcPercentage, row?.name);
        handleOnClose();
    };

    const handlePopoverDoubleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    return (
        <Box onDoubleClick={handlePopoverDoubleClick}>
            <Popover
                id={"adjust-cpc-popup-" + row?.id}
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleOnClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Box sx={{padding: "16px", backgroundColor: "background.darkBlue", zIndex: 9999}}>
                    <Box display="flex" flexDirection="row" alignItems="stretch">
                        <Box flex={1} p={1}>
                            <ReactHookFormTextField
                                register={register}
                                setValue={setValue}
                                type="text"
                                name={"adjustCpcPercentage"}
                                control={control}
                                defaultValue={row?.adjustCpcPercentage === 0 ? "" : row?.adjustCpcPercentage}
                                placeholder={"10.00"}
                                errors={errors}
                                rules={{
                                    required: true,
                                    pattern: /^-?[0-9]\d*(\.\d+)?$/
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="stretch">
                        <Box flex={1} p={1}>
                            <Button size="small" fullWidth variant="contained" color="inherit"
                                    onClick={() => handleOnClose()}>
                                Cancel
                            </Button>
                        </Box>
                        <Box flex={1} p={1}>
                            <Button size="small" fullWidth variant="contained" color="primary"
                                    onClick={handleSubmit(handleOnSubmit)}>
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Popover>
            <Tooltip title="Adjust CPC %">
                <span>
                    <IconButton
                        aria-controls={"adjust-cpc-popup-" + row?.id} aria-haspopup="true"
                        color="inherit"
                        onClick={(e) => handleOnClickToOpen(e?.currentTarget)}
                        aria-label="Adjust CPC %"
                        size="small"
                    >
                        <Edit fontSize={"small"} style={{fontSize: "14px"}}/>
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    );
}

export default AdjustCPCForm;