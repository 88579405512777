import React, {useState} from 'react';
import {Controller} from "react-hook-form";
import {Autocomplete, TextField} from "@mui/material";

function S3RegionDropdown({control, errors}) {
    const [open, setOpen] = useState(false);

    const options = ["ap-south-1", "eu-north-1", "eu-central-1", "eu-west-1", "eu-west-2", "sa-east-1", "us-east-2", "us-west-1", "us-west-2"];

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="s3-region-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => {
                        if (value === 0) {
                            return true;
                        }
                        return option === value
                    }}
                    getOptionLabel={(option) => option}
                    options={options}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={!!errors?.region}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            placeholder="Choose S3 bucket region"
                            label="S3 Bucket Region*"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={{required: true}}
            name="region"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

export default S3RegionDropdown;