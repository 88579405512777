import React from "react";
import {IconButton, List, ListItemButton, Popover} from "@mui/material";
import {MoreVert} from "@mui/icons-material";

export default function WorkspaceMenuButton({item, clickOnDelete, clickOnEdit}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOnEditButton = () => {
        setAnchorEl(null);
        clickOnEdit(item);
    };

    const handleClickOnDeleteButton = () => {
        setAnchorEl(null);
        clickOnDelete(item);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton color="inherit" aria-describedby={id} onClick={handleClick}>
                <MoreVert fontSize="small" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List
                    id="basic-menu"
                    anchorel={anchorEl}
                    open={open}
                    onClose={handleClose}
                    menulistprops={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <ListItemButton
                        onClick={handleClickOnEditButton}
                        sx={{
                            padding: "8px 16px",
                            "&.Mui-selected": {
                                backgroundColor: "background.navbarHover",
                                borderLeftWidth: "2px",
                                borderLeftStyle: "solid",
                                borderLeftColor: "primary.main",
                                ":hover": {
                                    backgroundColor: "background.rowHover"
                                }
                            },
                            ":hover": {
                                backgroundColor: "background.rowHover"
                            }
                        }}
                    >
                        Edit
                    </ListItemButton>
                    <ListItemButton
                        onClick={handleClickOnDeleteButton}
                        sx={{
                            padding: "8px 16px",
                            "&.Mui-selected": {
                                backgroundColor: "background.navbarHover",
                                borderLeftWidth: "2px",
                                borderLeftStyle: "solid",
                                borderLeftColor: "primary.main",
                                ":hover": {
                                    backgroundColor: "background.rowHover"
                                }
                            },
                            ":hover": {
                                backgroundColor: "background.rowHover"
                            }
                        }}
                    >
                        Delete
                    </ListItemButton>
                </List>
            </Popover>
        </div>
    );
}