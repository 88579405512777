import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import {tagServices} from "../../service/tagService";
import {CheckBox, CheckBoxOutlineBlank, Info} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Chip, TextField, Tooltip} from "@mui/material";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

function ReactHookMultiTagSelect({
                                     control,
                                     inputLabel = "Tags",
                                     inputName = "tags",
                                     updateOnChange = () => null,
                                     getValues,
                                     inputLabelShrink = true
                                 }) {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await tagServices.read({name: search});
                setOptions(
                    response.tags.map(item => {
                        return item?.name;
                    })
                );
            } catch (e) {
                console.log("Error: ", e?.response?.data?.error);
            } finally {
                setLoading(false);
            }
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData();
        }

    }, [search, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    freeSolo
                    multiple
                    disableCloseOnSelect={true}
                    id="tags-multi-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => option}
                    options={options}
                    loading={loading}
                    value={search}
                    renderOption={(props, option, {selected, index}) => (
                        <li {...props} key={index}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{marginRight: "8px", padding: "2px"}}
                                checked={selected}
                                color="primary"
                            />
                            {option}
                        </li>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                            const {key, ...rest} = getTagProps({index});
                            return (
                                <Chip
                                    key={key} variant="outlined" color="primary" size="small"
                                    label={option} {...rest}
                                />);
                        })
                    }
                    renderInput={params => (
                        <Box display="flex" flexDirection="row" flexWrap="noWrap">
                            <Box flexGrow={1}>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    placeholder={getValues("tags")?.length === 0 ? "Select tags or enter new" : ""}
                                    label={inputLabel}
                                    onChange={e => handleSearch(e.target.value)}
                                    InputLabelProps={{
                                        shrink: inputLabelShrink
                                    }}
                                />
                            </Box>
                            <Box p={1}>
                                <div style={{paddingTop: 10}}>
                                    <Tooltip disableFocusListener
                                             sx={{
                                                 marginLeft: "6px",
                                                 fontSize: "18px",
                                                 position: "relative",
                                                 cursor: "pointer"
                                             }}
                                             title="Type the value and press ENTER to create a new tag.">
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </Tooltip>
                                </div>
                            </Box>
                        </Box>
                    )}
                    onChange={(e, data) => {
                        onChange(data);
                        updateOnChange();
                    }}
                    {...props}
                />
            )}
            defaultValue={[]}
            name={inputName}
            control={control}
            onChange={([, data]) => data}
        />
    );
}

ReactHookMultiTagSelect.propTypes = {
    control: PropTypes.object.isRequired,
    inputLabel: PropTypes.string,
    inputName: PropTypes.string,
    updateOnChange: PropTypes.func
};

export default ReactHookMultiTagSelect;