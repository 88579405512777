import {Box, CssBaseline, useTheme} from "@mui/material";
import Topbar from "../../layout/Topbar";
import SettingsSidebar from "../SettingsSidebar";
import React from "react";
import PaymentMethod from "./PaymentMethod";
import SubscriptionDetails from "./SubscriptionDetails";
import Invoices from "./Invoices";
import useCustomStyles from "../../utils/UseCustomStyles";
import {SettingsContentBox, SettingsRoot} from "../General";

const customStyles = (theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.darkBlue,
        color: theme.palette.common.white,
        maxWidth: '100%',
    },
    container: {
        height: "var(--app-height)",
        width: '100%',
        overflow: 'auto',
        paddingTop: 66,
        maxWidth: 1170,
        margin: "0 auto",
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down(600)]: {
            paddingTop: 66,
        }
    },
    subscriptionDetailsContainer: {
        width: "100%",
        maxWidth: 550,
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down(1171)]: {
            width: "100%",
            minWidth: "100%",
            paddingRight: theme.spacing(0),
        },
    },
    paymentMethodContainer: {
        width: "100%",
        maxWidth: 550,
        paddingTop: theme.spacing(8.6),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down(1171)]: {
            width: "100%",
            minWidth: "100%",
            paddingTop: theme.spacing(4),
            paddingLeft: theme.spacing(0),
        },
    },
    invoiceContainer: {
        width: "100%",
        maxWidth: 550,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down(1171)]: {
            width: "100%",
            minWidth: "100%",
            paddingLeft: theme.spacing(0),
        },
    },
    header: {
        fontSize: 18,
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingBottom: 0,
        [theme.breakpoints.down(1171)]: {
            paddingTop: theme.spacing(0),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        },
    },
});

export const Subscription = (props) => {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="center" sx={{margin: "0 auto", maxWidth: 1170}}>
                    <Box className={classes?.subscriptionDetailsContainer}>
                        <Box>
                            <h3 className={classes?.header}>Subscription</h3>
                        </Box>
                        <SubscriptionDetails/>
                    </Box>
                    <Box flexGrow={1} display="flex" flexWrap="wrap" flexDirection="column">
                        <Box className={classes?.paymentMethodContainer}>
                            <PaymentMethod/>
                        </Box>
                        <Box className={classes?.invoiceContainer}>
                            <Invoices/>
                        </Box>
                    </Box>
                </Box>
            </SettingsContentBox>
        </SettingsRoot>
    );
};