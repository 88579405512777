export const workspaceConstants = {
    WORKSPACE_READ_WORKSPACES_LOADING_SPINNER: 'WORKSPACE_READ_WORKSPACES_LOADING_SPINNER',
    WORKSPACE_READ_WORKSPACES_SET_RESULT: 'WORKSPACE_READ_WORKSPACES_SET_RESULT',
    WORKSPACE_GET_USER_WORKSPACES_LOADING_SPINNER: 'WORKSPACE_GET_USER_WORKSPACES_LOADING_SPINNER',
    WORKSPACE_GET_USER_WORKSPACES_SET_RESULT: 'WORKSPACE_GET_USER_WORKSPACES_SET_RESULT',
    WORKSPACE_UPDATE_USER_WORKSPACE: 'WORKSPACE_UPDATE_USER_WORKSPACE',
    WORKSPACE_RESET_UPDATED_VALUE: 'WORKSPACE_RESET_UPDATED_VALUE',
    WORKSPACE_FIND_WORKSPACE_USERS_LOADING_SPINNER: 'WORKSPACE_FIND_WORKSPACE_USERS_LOADING_SPINNER',
    WORKSPACE_SET_WORKSPACE_USERS: 'WORKSPACE_SET_WORKSPACE_USERS',
    WORKSPACE_DELETE_USER_FROM_WORKSPACE_LOADING_SPINNER: 'WORKSPACE_DELETE_USER_FROM_WORKSPACE_LOADING_SPINNER',
    WORKSPACE_UPDATE_USERS_IN_WORKSPACE: 'WORKSPACE_UPDATE_USERS_IN_WORKSPACE',
    WORKSPACE_SET_NEW_WORKSPACE: 'WORKSPACE_SET_NEW_WORKSPACE',
    WORKSPACE_ASSIGN_USER_TO_WORKSPACE_LOADING_SPINNER: 'WORKSPACE_ASSIGN_USER_TO_WORKSPACE_LOADING_SPINNER',
    WORKSPACE_ASSIGN_USER_TO_WORKSPACE: 'WORKSPACE_ASSIGN_USER_TO_WORKSPACE',

    WORKSPACE_UPDATE_LOADING_SPINNER: 'WORKSPACE_UPDATE_LOADING_SPINNER',
    WORKSPACE_UPDATE_WORKSPACE: 'WORKSPACE_UPDATE_WORKSPACE',
    WORKSPACE_DELETE_WORKSPACE: 'WORKSPACE_DELETE_WORKSPACE',
};
