import React from 'react';
import {Controller} from "react-hook-form";
import {countries} from "./DataList";
import {Info} from "@mui/icons-material";
import {Autocomplete, Box, TextField} from "@mui/material";
import {CustomTooltip} from "../landings/LandingForm";

export default function CountrySelect({control, title}) {
    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="country-select"
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    getOptionLabel={(option) => option.label}
                    options={countries}
                    renderInput={params => (
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    placeholder="Select a country"
                                    label="Country"
                                />
                            </Box>
                            <Box p={1}>
                                <Box sx={{paddingTop: "10px"}}>
                                    <CustomTooltip
                                        disableFocusListener
                                        title={title}
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            name="country"
            defaultValue={{code: "", label: "Global", phone: ""}}
            control={control}
            onChange={([, data]) => data}
        />
    );
}
