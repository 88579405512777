import {personConstants} from "../constants/personConstants";

const initialState = {
    person: null,
    isLoading: false,
};

const personReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case personConstants.PERSON_LOADING_SPINNER:
            return {
                ...state,
                isLoading: true,
            };
        case personConstants.PERSON_FETCH:
            return {
                ...state,
                person: action?.person,
                isLoading: false
            };
        default:
            return state;
    }
};

export default personReducer;