import {apiRequest} from "../helpers/utils";
import {clickApiUrl} from "../helpers/config";

export const logServices = {
    getListOfConversions,
    getListOfClicks,
    getListOfPostbacks,
    addConversion
};

function getListOfConversions(from, to, timezone, clickId, page = 1, pageSize = 100, orderBy = "", sortBy = "", workspace) {
    let params = {
        from,
        to,
        timezone,
        clickId,
        page,
        pageSize,
        orderBy,
        sortBy,
        workspace
    };
    return apiRequest("get", "v1/log/conversions", {}, params);
}

function getListOfClicks(from, to, timezone, clickId, page = 1, pageSize = 100, orderBy = "", sortBy = "", workspace) {
    let params = {
        from,
        to,
        timezone,
        clickId,
        page,
        pageSize,
        orderBy,
        sortBy,
        workspace
    };
    return apiRequest("get", "v1/log/clicks", {}, params);
}

function getListOfPostbacks(from, to, timezone, clickId, page = 1, pageSize = 100, orderBy = "", sortBy = "", workspace) {
    let params = {
        from,
        to,
        timezone,
        clickId,
        page,
        pageSize,
        orderBy,
        sortBy,
        workspace
    };
    return apiRequest("get", "v1/log/postbacks", {}, params);
}

function addConversion({clickId, payoutCurrencyCode, payout, allowPostback, transactionId, status}) {
    let params = {
        clickId,
        payoutCurrencyCode,
        payout,
        allowPostback,
        transactionId,
        status
    };
    return apiRequest("get", "postback", {}, params, false, false, clickApiUrl);
}
