import {userConstants} from "../constants/userConstants";

const initialState = {
    users: [],
    successMsg: '',
    errorMsg: '',
    isLoading: false,
    loading: {
        inviteMember: false
    }
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.USER_SET_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.LOADING_USERS:
            return {
                ...state,
                users: action?.result?.users,
                successMsg: '',
                errorMsg: '',
                isLoading: false,
            };
        case userConstants.LOADING_USERS_ERROR:
            return {
                ...state,
                successMsg: '',
                errorMsg: action?.error,
                isLoading: false
            };
        case userConstants.DELETE_USER:
            return {
                ...state,
                users: action?.users,
                successMsg: 'User has been successfully deleted.',
                errorMsg: '',
                isLoading: false,
            };
        case userConstants.DELETE_USER_ERROR:
            return {
                ...state,
                successMsg: '',
                errorMsg: action?.error,
                isLoading: false,
            };
        case userConstants.INVITE_MEMBER_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    inviteMember: action?.result?.loading
                }
            };
        case userConstants.INVITE_MEMBER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    inviteMember: action?.result?.loading
                }
            };
        default:
            return state;
    }
};

export default userReducer;