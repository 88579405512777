import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {alertActions} from "../../../store/actions/alertActions";
import {websiteBucketsActions} from "../../../store/actions/websiteBucketsAction";
import {useParams} from "react-router-dom";

function UploadFilesToS3Bucket(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const {handleSubmit} = useForm();

    const [selectedFile, setSelectedFile] = useState(null);

    const {uploadFiles, alertError, updateUploadedFilesStatus} = props;
    const {filesUploaded} = props?.websiteBucketsState;
    const {plan} = props?.auth?.subscription;

    const handleClose = useCallback(() => {
        history.push({pathname: "/websites/s3-buckets", state: {from: location}});
    }, [history, location]);

    const handleOnSubmitUpload = () => {

        if (plan === "Free") {
            alertError("This feature is not available in Free subscription plan.");
            return false;
        }

        if (selectedFile === null) {
            alertError("Zip file is mandatory to add.");
            return false;
        }

        const formData = new FormData();
        formData.append('zipFile', selectedFile);
        formData.append('websiteId', params?.id);
        uploadFiles(formData);
    };

    const handleFileChange = (event) => {
        const file = event?.target?.files[0];
        if (file && file?.type === 'application/zip') {
            setSelectedFile(file);
        } else {
            alertError('Please select a valid ZIP file.');
            setSelectedFile(null);
        }
    };

    // Close form
    useEffect(() => {
        if (filesUploaded) {
            updateUploadedFilesStatus(false);
            handleClose();
        }
    }, [filesUploaded, updateUploadedFilesStatus, handleClose]);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={fullScreen}
            aria-labelledby="upload-files-dialog"
            sx={{maxWidth: 600, margin: "0 auto"}}
        >
            <DialogTitle id="upload-files-dialog" textAlign="center">Upload</DialogTitle>
            <DialogContent>

                <Alert severity="warning" color="warning" variant="filled" sx={{mt: 1, mb: 4}}>
                    When you upload a new version of your website, it will replace the existing files in your S3 bucket
                    and add any new ones. Please note that these changes are permanent and cannot be undone.
                </Alert>

                <form onSubmit={handleSubmit(handleOnSubmitUpload)} autoComplete={"off"}>
                    <Box display="flex" flexWrap={"wrap"}>
                        <Box width="100%">
                            <TextField
                                type="file"
                                onChange={handleFileChange}
                                inputProps={{
                                    accept: '.zip',
                                }}
                                label="Select ZIP file*"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                            />
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end">
                    <Box mr={3}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            Close
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmitUpload)} color="primary"
                                variant="contained">
                            Upload
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        websiteBucketsState: state.websiteBuckets,
        auth: state.auth
    }
};

const mapDispatchToProps = {
    uploadFiles: websiteBucketsActions.uploadFiles,
    updateUploadedFilesStatus: websiteBucketsActions.updateUploadedFilesStatus,
    alertError: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFilesToS3Bucket);