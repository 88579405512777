import React from 'react';
import {TextField, Autocomplete} from '@mui/material';
import {Controller} from "react-hook-form";
import {baseCurrencies} from "../utils/DataList";

export default function BaseCurrencySelect({control}) {
    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    disableClearable
                    id="base-currency-select"
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={baseCurrencies}
                    renderInput={params => (
                        <span>
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                margin="dense"
                                label="Base Currency"
                            />
                        </span>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            name="currency"
            control={control}
            defaultValue={{code: "EUR", name: "Euro", symbol: "€"}}
            onChange={([, data]) => data}
        />
    );
}
