import {flowConstants} from "../constants/flowConstants";

const initialState = {
    data: {
        records: [],
        page: 0,
        recordsPerPage: 0,
        total: {}
    },
    errorMsg: "",
    isLoading: false,
    name: "",
    tags: [],
    orderBy: "",
    sortBy: "",
    sortColumns: [],
    rerender: false,
    sharedFlow: null
};

const flowReducer = (state = initialState, action) => {
    switch (action.type) {
        case flowConstants.FLOWS_LOADING_SPINNER:
            return {
                ...state,
                isLoading: true,
            };
        case flowConstants.FLOWS_STOP_LOADING_SPINNER:
            return {
                ...state,
                isLoading: false,
            };
        case flowConstants.FLOWS_INFINITY_SCROLL:
            return {
                ...state,
                data: {
                    records: [...state.data.records, ...action?.result?.records],
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false
            };
        case flowConstants.FLOWS_GET_DATA:
            return {
                ...state,
                data: {
                    records: action?.result?.records,
                    page: action?.result?.page,
                    recordsPerPage: action?.result?.recordsPerPage,
                    total: action?.result?.total
                },
                errorMsg: '',
                isLoading: false,
                rerender: false
            };
        case flowConstants.FLOWS_ERROR:
            return {
                ...state,
                errorMsg: 'Unable to load flows. Please try again.',
                rerender: false,
                isLoading: false,
            };
        case flowConstants.FLOWS_ARCHIVE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case flowConstants.FLOWS_RESTORE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        case flowConstants.FLOWS_UPDATE_ORDER_BY:
            return {
                ...state,
                orderBy: action.orderBy
            };
        case flowConstants.FLOWS_UPDATE_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };
        case flowConstants.FLOWS_UPDATE_NAME:
            return {
                ...state,
                name: action.name
            };
        case flowConstants.FLOWS_UPDATE_TAGS:
            return {
                ...state,
                tags: action.tags
            };
        case flowConstants.FLOWS_UPDATE_SORT_COLUMNS:
            return {
                ...state,
                sortColumns: action.sortColumns
            };
        case flowConstants.FLOWS_RERENDER:
            return {
                ...state,
                rerender: true
            };
        case flowConstants.FLOWS_UPDATE_SHARED_FLOW:
            return {
                ...state,
                sharedFlow: action.payload.flow
            };
        case flowConstants.BULK_UPDATE_CHANGE_WORKSPACE:
            return {
                ...state,
                rerender: true,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default flowReducer;