export const campaignConstants = {
    CAMPAIGNS_LOADING_SPINNER: 'CAMPAIGNS_LOADING_SPINNER',
    CAMPAIGNS_STOP_LOADING_SPINNER: 'CAMPAIGNS_STOP_LOADING_SPINNER',
    CAMPAIGNS_INFINITY_SCROLL: 'CAMPAIGNS_INFINITY_SCROLL',
    CAMPAIGNS_GET_DATA: 'CAMPAIGNS_GET_DATA',
    CAMPAIGNS_ERROR: 'CAMPAIGNS_ERROR',
    CAMPAIGNS_UPDATE_ORDER_BY: 'CAMPAIGNS_UPDATE_ORDER_BY',
    CAMPAIGNS_UPDATE_SORT_BY: 'CAMPAIGNS_UPDATE_SORT_BY',
    CAMPAIGNS_UPDATE_NAME: 'CAMPAIGNS_UPDATE_NAME',
    CAMPAIGNS_UPDATE_TAGS: 'CAMPAIGNS_UPDATE_TAGS',
    CAMPAIGNS_UPDATE_SORT_COLUMNS: 'CAMPAIGNS_UPDATE_SORT_COLUMNS',
    CAMPAIGNS_RERENDER: 'CAMPAIGNS_RERENDER',
    CAMPAIGNS_ARCHIVE: 'CAMPAIGNS_ARCHIVE',
    CAMPAIGNS_RESTORE: 'CAMPAIGNS_RESTORE',
    CAMPAIGNS_SET_SELECTED_ROW: 'CAMPAIGNS_SET_SELECTED_ROW',

    SET_FORM_STEP_1: 'SET_FORM_STEP_1',
    SET_FORM_STEP_2: 'SET_FORM_STEP_2',
    SET_FORM_STEP_3: 'SET_FORM_STEP_3',
    RESET_FORM: 'RESET_FORM',
    FORM_STEP_BACK: 'FORM_STEP_BACK',

    CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
    CREATE_CAMPAIGN_ERROR: 'CREATE_CAMPAIGN_ERROR',
    UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
    UPDATE_CAMPAIGN_ERROR: 'UPDATE_CAMPAIGN_ERROR',
    FIND_CAMPAIGN: 'FIND_CAMPAIGN',
    FIND_CAMPAIGN_ERROR: 'FIND_CAMPAIGN_ERROR',
    UPDATE_TRAFFIC_SOURCE: 'UPDATE_TRAFFIC_SOURCE',
    UPDATE_TRACKING_DOMAIN: 'UPDATE_TRACKING_DOMAIN',
    UPDATE_CLOAKING_DOMAIN: 'UPDATE_CLOAKING_DOMAIN',

    UPDATE_DESTINATION_TYPE: 'UPDATE_DESTINATION_TYPE',
    UPDATE_SELECTED_RULE: 'UPDATE_SELECTED_RULE',
    UPDATE_SELECTED_PATH: 'UPDATE_SELECTED_PATH',
    ADD_RULE: 'ADD_RULE',
    UPDATE_RULE_NAME: 'UPDATE_RULE_NAME',
    REMOVE_RULE: 'REMOVE_RULE',
    UPDATE_RULE_STATUS: 'UPDATE_RULE_STATUS',
    ADD_RULE_PATH: 'ADD_RULE_PATH',
    REMOVE_RULE_PATH: 'REMOVE_RULE_PATH',
    UPDATE_RULE_PATH_STATUS: 'UPDATE_RULE_PATH_STATUS',
    UPDATE_RULE_PATH_NAME: 'UPDATE_RULE_PATH_NAME',
    UPDATE_RULE_PATH_WEIGHT: 'UPDATE_RULE_PATH_WEIGHT',
    CHANGE_RULE_PRIORITY: 'CHANGE_RULE_PRIORITY',
    UPDATE_RULE_LOGICAL_RELATION: 'UPDATE_RULE_LOGICAL_RELATION',

    ADD_DEFAULT_PATH: 'ADD_DEFAULT_PATH',
    REMOVE_DEFAULT_PATH: 'REMOVE_DEFAULT_PATH',
    UPDATE_DEFAULT_PATH_STATUS: 'UPDATE_DEFAULT_PATH_STATUS',
    UPDATE_DEFAULT_PATH_NAME: 'UPDATE_DEFAULT_PATH_NAME',
    UPDATE_DEFAULT_PATH_WEIGHT: 'UPDATE_DEFAULT_PATH_WEIGHT',

    UPDATE_PATH_DIRECT_LINKING: 'UPDATE_PATH_DIRECT_LINKING',
    ADD_LANDING_TO_PATH: 'ADD_LANDING_TO_PATH',
    REMOVE_LANDING_FROM_PATH: 'REMOVE_LANDING_FROM_PATH',
    UPDATE_LANDING_NAME_IN_PATH: 'UPDATE_LANDING_NAME_IN_PATH',
    UPDATE_LANDING_WEIGHT_IN_PATH: 'UPDATE_LANDING_WEIGHT_IN_PATH',

    ADD_OFFER_TO_PATH: 'ADD_OFFER_TO_PATH',
    REMOVE_OFFER_FROM_PATH: 'REMOVE_OFFER_FROM_PATH',
    UPDATE_OFFER_NAME_IN_PATH: 'UPDATE_OFFER_NAME_IN_PATH',
    UPDATE_OFFER_WEIGHT_IN_PATH: 'UPDATE_OFFER_WEIGHT_IN_PATH',

    FORM_VALIDATION_ERROR: 'FORM_VALIDATION_ERROR',

    UPDATE_CONDITION: 'UPDATE_CONDITION',

    SET_FLOW: 'SET_FLOW',

    UPDATE_CONVERSION_ACTION_EVENT: 'UPDATE_CONVERSION_ACTION_EVENT',
    UPDATE_CONVERSION_ACTION_PIXEL: 'UPDATE_CONVERSION_ACTION_PIXEL',
    UPDATE_TRAFFIC_SOURCE_INTEGRATION: 'UPDATE_TRAFFIC_SOURCE_INTEGRATION',
    RESET_CONVERSION_ACTION_AND_PIXEL: 'RESET_CONVERSION_ACTION_AND_PIXEL',

    BULK_UPDATE_CHANGE_TRACKING_DOMAIN: 'BULK_UPDATE_CHANGE_TRACKING_DOMAIN',
    BULK_UPDATE_CHANGE_WORKSPACE: 'BULK_UPDATE_CHANGE_WORKSPACE'
};
