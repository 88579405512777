export const offersConstants = {
    OFFERS_LOADING_SPINNER: 'OFFERS_LOADING_SPINNER',
    OFFERS_STOP_LOADING_SPINNER: 'OFFERS_STOP_LOADING_SPINNER',
    OFFERS_INFINITY_SCROLL: 'OFFERS_INFINITY_SCROLL',
    OFFERS_GET_DATA: 'OFFERS_GET_DATA',
    OFFERS_ERROR: 'OFFERS_ERROR',
    OFFERS_UPDATE_ORDER_BY: 'OFFERS_UPDATE_ORDER_BY',
    OFFERS_UPDATE_SORT_BY: 'OFFERS_UPDATE_SORT_BY',
    OFFERS_UPDATE_NAME: 'OFFERS_UPDATE_NAME',
    OFFERS_UPDATE_TAGS: 'OFFERS_UPDATE_TAGS',
    OFFERS_UPDATE_SORT_COLUMNS: 'OFFERS_UPDATE_SORT_COLUMNS',
    OFFERS_RERENDER: 'OFFERS_RERENDER',
    OFFERS_ARCHIVE: 'OFFERS_ARCHIVE',
    OFFERS_RESTORE: 'OFFERS_RESTORE',
    BULK_UPDATE_CHANGE_WORKSPACE: 'BULK_UPDATE_CHANGE_WORKSPACE',
    OFFERS_BULK_UPDATE_FOR_URL: 'OFFERS_BULK_UPDATE_FOR_URL',
};
