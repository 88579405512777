import {reportServices} from "../../service/reportService";
import {landingServices} from "../../service/landingService";
import {landingConstants} from "../constants/landingConstants";
import {alertActions} from "./alertActions";
import ExportCSV from "../../components/export/ExportCSV";

export const landingActions = {
    getReport,
    archive,
    restore,
    loadMoreRows,
    updateName,
    updateTags,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    changeWorkspace,
    bulkUpdateForUrl
};

function loadMoreRows(groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, metricFilters, columns, workspace) {
    return dispatch => {
        dispatch({type: landingConstants.LANDINGS_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        reportServices.getReport(
            {
                groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode,
                metricFilters, columns, workspace
            }
        )
            .then((result) => {
                dispatch({type: landingConstants.LANDINGS_INFINITY_SCROLL, result});
            }).catch((error) => {
                dispatch({type: landingConstants.LANDINGS_STOP_LOADING_SPINNER});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getReport(groupBy, from, to, timezone, name, tags, isDeleted, page, pageSize, orderBy, sortBy, currencyCode, exportData, metricFilters, columns, workspace) {
    return dispatch => {
        dispatch({type: landingConstants.LANDINGS_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        reportServices.getReport(
            {
                groupBy,
                from,
                to,
                timezone,
                name,
                tags,
                isDeleted,
                page,
                pageSize,
                orderBy,
                sortBy,
                currencyCode,
                exportData,
                metricFilters,
                columns,
                workspace
            }
        )
            .then((result) => {
                if (exportData === "csv") {
                    ExportCSV({fileName: "landings_" + from + "_to_" + to, data: result});
                    dispatch({type: landingConstants.LANDINGS_STOP_LOADING_SPINNER});
                } else {
                    dispatch({type: landingConstants.LANDINGS_GET_DATA, result});
                }
            }).catch((error) => {
                dispatch({type: landingConstants.LANDINGS_STOP_LOADING_SPINNER});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function archive(ids) {
    return dispatch => {
        dispatch({type: landingConstants.LANDINGS_LOADING_SPINNER});
        landingServices.archive(ids)
            .then(() => {
                dispatch({type: landingConstants.LANDINGS_ARCHIVE});
            }).catch((error) => {
                dispatch({type: landingConstants.LANDINGS_ERROR, error});
            }
        );
    };
}

function restore(ids) {
    return dispatch => {
        dispatch({type: landingConstants.LANDINGS_LOADING_SPINNER});
        landingServices.restore(ids)
            .then(() => {
                dispatch({type: landingConstants.LANDINGS_RESTORE});
            }).catch((error) => {
                dispatch({type: landingConstants.LANDINGS_ERROR, error});
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: landingConstants.LANDINGS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: landingConstants.LANDINGS_UPDATE_SORT_BY, sortBy});
    };
}

function updateName(name) {
    return (dispatch) => {
        dispatch({type: landingConstants.LANDINGS_UPDATE_NAME, name});
    };
}

function updateTags(tags) {
    return (dispatch) => {
        dispatch({type: landingConstants.LANDINGS_UPDATE_TAGS, tags});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: landingConstants.LANDINGS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: landingConstants.LANDINGS_RERENDER});
    };
}

function changeWorkspace(data) {
    return dispatch => {
        dispatch({type: landingConstants.LANDINGS_LOADING_SPINNER});
        landingServices.bulkUpdate(data)
            .then(() => {
                dispatch({type: landingConstants.BULK_UPDATE_CHANGE_WORKSPACE});
                dispatch(alertActions.success("Workspace is successfully updated."));
            }).catch((error) => {
                dispatch(alertActions.error("Changing workspace failed: " + error?.response?.data?.error));
            }
        );
    };
}

function bulkUpdateForUrl(data) {
    return dispatch => {
        dispatch({type: landingConstants.LANDINGS_LOADING_SPINNER});
        landingServices.bulkUpdateForUrl(data)
            .then(() => {
                dispatch({type: landingConstants.LANDINGS_BULK_UPDATE_FOR_URL});
                dispatch(alertActions.success("Landing URLs are successfully updated."));
            }).catch((error) => {
                dispatch(alertActions.error("Updating URLs failed: " + error?.response?.data?.error));
                dispatch({type: landingConstants.LANDINGS_STOP_LOADING_SPINNER});
            }
        );
    };
}
