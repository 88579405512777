import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
    TextField,
    Tooltip
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import Spinner from "../../spinner/Spinner";
import {connect} from "react-redux";
import {alertActions} from "../../../store/actions/alertActions";
import {customEventServices} from "../../../service/customEventService";
import {customEventActions} from "../../../store/actions/customEventActions";
import {Info} from "@mui/icons-material";
import ReactHookFormChipInput from "../../input/ReactHookFormChipInput";
import {columnServices} from "../../../service/columnService";
import ReactHookFormCheckbox from "../../input/ReactHookFormCheckbox";
import {columnActions} from "../../../store/actions/columnAction";

function CustomEventForm(props) {

    const theme = useTheme();
    const {onClose, open, errorMsg, successMsg, clear, rerender, editEventID, setColumns} = props;

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [loading, setLoading] = useState(false);

    const {register, handleSubmit, control, getValues, setValue, formState: {errors}} = useForm();

    const onSubmit = async (data) => {

        setLoading(true);
        clear();

        let ceParamValues = "";
        if (data?.values?.length === 0) {
            errorMsg("Values of The ce Parameter is mandatory field.");
            setLoading(false);
            return false;
        }
        if (data?.values?.length > 0) {
            let valid = true;
            let pattern = /^[a-z-A-Z\s-_]*$/;
            for (const ce of data?.values) {
                if (pattern.test(ce) === false) {
                    errorMsg("Values of The ce Parameter should be a unique value " +
                        "with 100 alphabet characters (a-zA-Z) max and “-”, “_” allowed.");
                    valid = false;
                    break;
                }
                if (ceParamValues === "") {
                    ceParamValues += ce
                } else {
                    ceParamValues += "," + ce
                }
            }
            if (!valid) {
                setLoading(false);
                return false;
            }
        }

        let formData = {
            name: data?.name,
            events: ceParamValues,
            approvedConversionType: data?.approvedConversionType === "" ? false : data?.approvedConversionType,
            includeInConversions: data?.includeInConversions === "" ? false : data?.includeInConversions,
            includeInRevenue: data?.includeInRevenue === "" ? false : data?.includeInRevenue,
            includeInDailyCap: data?.includeInDailyCap === "" ? false : data?.includeInDailyCap,
            sendPostback: data?.sendPostback === "" ? false : data?.sendPostback,
            includePayoutInPostback: data?.includePayoutInPostback === "" ? false : data?.includePayoutInPostback,
            includeInCost: data?.includeInCost === "" ? false : data?.includeInCost,
        };

        if (editEventID > 0) {
            await customEventServices.update(editEventID, formData).then(async () => {
                await columnServices.fetch().then((result) => {
                    setLoading(false);
                    successMsg("Custom event has been successfully updated.");
                    rerender();
                    setColumns(result);
                    handleClose();
                }).catch((e) => {
                    if (e?.response?.data?.error) {
                        errorMsg(e.response.data.error);
                    }
                    setLoading(false);
                });
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    errorMsg(e.response.data.error);
                }
                setLoading(false);
            });
            return false;
        }

        await customEventServices.create(formData).then(async () => {
            await columnServices.fetch().then((result) => {
                setLoading(false);
                successMsg("New custom event has been created successfully.");
                rerender();
                setColumns(result);
                handleClose();
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    errorMsg(e.response.data.error);
                }
                setLoading(false);
            });
        }).catch((e) => {
            if (e?.response?.data?.error) {
                errorMsg(e.response.data.error);
            }
            setLoading(false);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }

        setTimeout(() => {
            clear();
        }, 3000);

        onClose();
    };

    useEffect(() => {
        if (editEventID > 0) {
            setLoading(true);
            customEventServices.find(editEventID).then((result) => {
                setValue("name", result?.name);
                setValue("approvedConversionType", result?.approvedConversionType);
                setValue("includeInConversions", result?.includeInConversions);
                setValue("includeInRevenue", result?.includeInRevenue);
                setValue("includeInDailyCap", result?.includeInDailyCap);
                setValue("sendPostback", result?.sendPostback);
                setValue("includePayoutInPostback", result?.includePayoutInPostback);
                setValue("includeInCost", result?.includeInCost);
                setValue("values", result?.events?.split(","));
                setLoading(false);
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    errorMsg(e.response.data.error);
                }
                setLoading(false);
            });
        }
    }, [editEventID, errorMsg, setValue]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="create-access-token-form"
                maxWidth={"sm"}
        >
            <DialogTitle sx={{textAlign: "center"}}>Create a new custom conversion event</DialogTitle>
            <DialogContent>
                <Box sx={{color: "text.disabledLight", pt: 1, pb: 1}}>
                    <strong>Example:</strong><br/>
                    For the <strong>App Install</strong> custom conversion event with the parameter’s values such
                    as <strong>inst</strong> and <strong>install</strong>, every postback fired
                    with <strong>&ce=inst</strong> or <strong>&ce=install</strong> will be counted as
                    a new conversion. Those conversions will be categorized as the <strong>App Install</strong> event.
                    The
                    custom conversion event name will be displayed in reports.
                </Box>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Box mt={2}>
                            <TextField
                                error={!!errors?.name}
                                type="text"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Custom Conversion Event Name"
                                {...register("name", {
                                    required: true,
                                    maxLength: 100,
                                    minLength: 1,
                                    pattern: /^[a-z-A-Z\s-_]*$/
                                })}
                                size="small"
                                placeholder="Event name for reporting"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box mt={2} mb={1}>
                            <ReactHookFormChipInput
                                control={control}
                                name="values"
                                label="Values Of The ce Parameter"
                                placeholder="Type your event name and press enter."
                                values={getValues("values")}
                                inputLabelShrink={true}
                            />
                        </Box>
                        <Box>
                            <ReactHookFormCheckbox
                                control={control}
                                labelPlacement="end"
                                name="approvedConversionType"
                                defaultValue={true}
                                label={
                                    <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{marginTop: "4px"}}>
                                        <Box sx={{fontWeight: 400}}>Use as <strong>Approved</strong> conversion
                                            type</Box>
                                        <Box mt="2px" ml={1}>
                                            <Tooltip disableFocusListener
                                                     sx={{cursor: "pointer"}}
                                                     title="If that option is selected, the custom conversion event will be
                                                 marked as approved conversion type and included in the Conversions
                                                 (Approved) column in reports and is used for CR, CV and AP
                                                 calculations."
                                            >
                                                <Info color={"primary"} fontSize={"small"}/>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                            />
                        </Box>
                        <Box>
                            <ReactHookFormCheckbox
                                control={control}
                                labelPlacement="end"
                                name="includeInConversions"
                                defaultValue={""}
                                label={
                                    <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{marginTop: "4px"}}>
                                        <Box sx={{fontWeight: 400}}> Include in <strong>Conversions
                                            (All)</strong> column</Box>
                                        <Box mt="2px" ml={1}>
                                            <Tooltip disableFocusListener
                                                     sx={{cursor: "pointer"}}
                                                     title="If that option is selected, the custom conversion event will be
                                                 included in the Conversions (All) value."
                                            >
                                                <Info color={"primary"} fontSize={"small"}/>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                            />
                        </Box>
                        <Box>
                            <ReactHookFormCheckbox
                                control={control}
                                labelPlacement="end"
                                name="includeInRevenue"
                                defaultValue={""}
                                label={
                                    <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{marginTop: "4px"}}>
                                        <Box sx={{fontWeight: 400}}>Include in <strong>Revenue</strong> column</Box>
                                        <Box mt="2px" ml={1}>
                                            <Tooltip disableFocusListener
                                                     sx={{cursor: "pointer"}}
                                                     title="If that option is selected, the custom conversion event will be
                                                 included in the Revenue amount."
                                            >
                                                <Info color={"primary"} fontSize={"small"}/>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                            />
                        </Box>
                        <Box>
                            <ReactHookFormCheckbox
                                control={control}
                                labelPlacement="end"
                                name="includeInDailyCap"
                                defaultValue={""}
                                label={
                                    <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{marginTop: "4px"}}>
                                        <Box sx={{fontWeight: 400}}>
                                            Include in conversions <strong>Daily Cap</strong> value
                                        </Box>
                                        <Box mt="2px" ml={1}>
                                            <Tooltip disableFocusListener
                                                     sx={{cursor: "pointer"}}
                                                     title="If that option is selected, the custom conversion event will be
                                                 counted in the Daily Cap value configured on the level of the offer
                                                 settings."
                                            >
                                                <Info color={"primary"} fontSize={"small"}/>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                            />
                        </Box>
                        <Box>
                            <ReactHookFormCheckbox
                                control={control}
                                labelPlacement="end"
                                name="sendPostback"
                                defaultValue={""}
                                label={
                                    <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{marginTop: "4px"}}>
                                        <Box sx={{fontWeight: 400}}>
                                            Send postback to <strong>Traffic Source</strong>
                                        </Box>
                                        <Box mt="2px" ml={1}>
                                            <Tooltip disableFocusListener
                                                     sx={{cursor: "pointer"}}
                                                     title="That option allows to send the custom conversion event value
                                                 to your traffic source via postback. Also works with Facebook CAPI."
                                            >
                                                <Info color={"primary"} fontSize={"small"}/>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                            />
                        </Box>
                        <Box ml={3}>
                            <ReactHookFormCheckbox
                                control={control}
                                labelPlacement="end"
                                name="includePayoutInPostback"
                                defaultValue={""}
                                label={
                                    <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{marginTop: "4px"}}>
                                        <Box sx={{fontWeight: 400}}>
                                            Include <strong>Payout</strong> value in postback
                                        </Box>
                                        <Box mt="2px" ml={1}>
                                            <Tooltip disableFocusListener
                                                     sx={{cursor: "pointer"}}
                                                     title="It applies only for Facebook and TikTok. If that option is selected, then payout value
                                                 is sent to traffic source."
                                            >
                                                <Info color={"primary"} fontSize={"small"}/>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                            />
                        </Box>
                        <Box ml={3}>
                            <ReactHookFormCheckbox
                                control={control}
                                labelPlacement="end"
                                name="includeInCost"
                                defaultValue={""}
                                label={
                                    <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{marginTop: "4px"}}>
                                        <Box sx={{fontWeight: 400}}>
                                            Include in <strong>Cost</strong> column
                                        </Box>
                                        <Box mt="2px" ml={1}>
                                            <Tooltip disableFocusListener
                                                     sx={{cursor: "pointer"}}
                                                     title="It is applicable to the CPA and CPS (RevShare) cost models. If that option is selected, the custom conversion event will be
                                                 counted in the Cost value."
                                            >
                                                <Info color={"primary"} fontSize={"small"}/>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                }
                            />
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                {loading ? <Spinner/> :
                    <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained">
                        Save & Close
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

CustomEventForm.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    editEventID: PropTypes.number
};

const mapStateToProps = (state) => {
        return {
            state: {
                alert: state.alert,
            }
        };
    }
;

const mapDispatchToProps = {
    errorMsg: alertActions.error,
    successMsg: alertActions.success,
    clear: alertActions.clear,
    rerender: customEventActions.rerender,
    setColumns: columnActions.setColumns
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomEventForm);