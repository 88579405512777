import React, {useEffect} from "react";
import {campaignFormActions} from "../../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import {countries} from "../../utils/DataList";
import ReactHookFormRadio from "../../input/ReactHookFormRadio";
import ReactHookFormCheckbox from "../../input/ReactHookFormCheckbox";
import CountryMultiSelect from "./CountryMultiSelect";
import {Box, Button, IconButton, useTheme} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        padding: "12px 10px",
        backgroundColor: theme.palette.background.darkBlue,
        borderRadius: 4
    },
    title: {
        paddingTop: 4,
        fontSize: 14,
        fontWeight: 600
    },
    radio: {
        '&$checked': {
            color: theme.palette.error.light
        }
    },
    checked: {},
    deleteButton: {
        color: theme.palette.error.dark
    },
    addConditionButton: {
        textTransform: "none",
        padding: "2px 8px"
    }
});

function Geo(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {campaignFormState: {flow, selectedRuleNumber, selectedRule}} = props;

    const {control, setValue, getValues, reset} = useForm({
        defaultValues: {
            countryCondition: "is",
            countryIncludeEmptyValue: false,
            countryValues: []
        }
    });

    const radioConditions = [
        {label: "Is", value: "is", color: "primary"},
        {label: "Is not", value: "isNot", color: "error"},
    ];

    useEffect(() => {
        let condition = selectedRule?.conditions?.country?.condition;
        if (condition === undefined || condition === "") {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRule, selectedRuleNumber, selectedRule?.conditions?.country?.condition]);

    useEffect(() => {
        let country = flow?.rules[selectedRuleNumber]?.conditions?.country;
        if (selectedRule !== null && country?.condition !== "") {
            setValue("countryCondition", country?.condition);
            setValue("countryIncludeEmptyValue", country?.includeEmptyValue);

            let listOfCountries = [];
            country?.values?.forEach((l) => {
                countries?.forEach((c) => {
                    if (l === c?.code) {
                        listOfCountries.push(c);
                    }
                });
            });
            setValue("countryValues", listOfCountries);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRuleNumber, selectedRule, flow.rules]);

    const updateCondition = () => {
        let countries = [];
        getValues("countryValues")?.forEach((country) => {
            countries.push(country.code);
        });

        props.updateCondition(
            props.campaignFormState.selectedRuleNumber,
            "country",
            getValues("countryCondition"),
            getValues("countryIncludeEmptyValue"),
            countries
        );
    };

    const removeCondition = () => {
        props.updateCondition(props.campaignFormState.selectedRuleNumber, "country", "", false, null);
    };

    const addCondition = () => {
        props.updateCondition(props.campaignFormState.selectedRuleNumber, "country", "is", false, []);
    };

    const renderAddCondition = () => {
        let condition = selectedRule?.conditions?.country?.condition;
        if (condition === "" || condition === undefined) {
            return (
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1} className={classes?.title}>
                        <span>Country</span>
                    </Box>
                    <Box>
                        <Button
                            aria-label="condition"
                            color="primary"
                            title="Add condition"
                            startIcon={<Add/>}
                            className={classes?.addConditionButton}
                            onClick={addCondition}
                        >
                            Add condition
                        </Button>
                    </Box>
                </Box>
            );
        }
    };

    const renderConditionBox = () => {
        let condition = selectedRule?.conditions?.country?.condition;
        if (condition !== "" && condition !== undefined) {
            return (
                <>
                    <Box mb={1} display="flex" flexDirection="row">
                        <Box flexGrow={1} className={classes?.title}>Country</Box>
                        <Box>
                            <IconButton
                                aria-label="remove"
                                color="default"
                                title="Remove"
                                size={"small"}
                                className={classes?.deleteButton}
                                onClick={removeCondition}
                            >
                                <Delete fontSize="small"/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <ReactHookFormRadio
                            defaultValue="is"
                            control={control}
                            options={radioConditions}
                            name="countryCondition"
                            label="conditions"
                            updateOnChange={updateCondition}
                        />
                    </Box>
                    <Box>
                        <ReactHookFormCheckbox
                            control={control}
                            label="Include empty value"
                            labelPlacement="end"
                            name="countryIncludeEmptyValue"
                            updateOnChange={updateCondition}
                        />
                    </Box>
                    <Box>
                        {getValues("dayOfWeekValues")}
                        <CountryMultiSelect
                            control={control}
                            inputName="countryValues"
                            inputLabel="Select countries"
                            updateCondition={updateCondition}
                        />
                    </Box>
                </>
            );
        }
    };

    return (
        <Box display="flex" flexDirection="column" className={classes?.root}>
            {renderAddCondition()}
            {renderConditionBox()}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
});

const mapDispatchToProps = {
    updateCondition: campaignFormActions.updateCondition
};

export default connect(mapStateToProps, mapDispatchToProps)(Geo);