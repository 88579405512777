import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField, useMediaQuery, useTheme,
    Link, FormHelperText
} from "@mui/material";
import {Check} from "@mui/icons-material";
import {integrationActions} from "../../../../store/actions/integrationAction";
import {connect} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Spinner from "../../../spinner/Spinner";
import {integrationService} from "../../../../service/integrationService";
import {alertActions} from "../../../../store/actions/alertActions";
import CopyContentToClipboard from "../../../utils/CopyContentToClipboard";
import {GetDefaultTrackingDomain} from "../../../../helpers/common";
import useCustomStyles from "../../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        paddingTop: 8,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15
    },
    title: {
        textAlign: "center",
        paddingBottom: 0
    },
    description: {
        color: theme.palette.text.disabledLight
    },
    chooseAccount: {
        fontWeight: 500,
        fontSize: 14,
        paddingTop: 24,
        paddingBottom: 4
    },
    adAccountList: {
        maxHeight: 500,
        overflow: "auto",
        paddingBottom: 8,
        marginBottom: 32
    },
    adAccountListItem: {
        paddingBottom: 16
    },
    listItem: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0
    },
    listItemIcon: {
        minWidth: 42
    },
    listItemText: {
        whiteSpace: "normal"
    },
    infoAlert: {
        marginTop: 12
    },
    logo: {
        backgroundColor: theme.palette.background.default,
        padding: 12,
        borderRadius: 4
    },
    helperText: {
        color: theme.palette.error.main,
    },
});

function ClickbankIntegration(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {integration, create, error, update} = props;

    const integrationIdParam = props?.match?.params?.id;

    const {register, handleSubmit, setValue, formState: {errors}} = useForm();

    const [loading, setLoading] = useState(false);
    const [integrationUUID, setIntegrationUUID] = useState("");
    const [integrationID, setIntegrationID] = useState(null);

    const defaultDomain = GetDefaultTrackingDomain();

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleOnSubmit = useCallback((data) => {
        if (integrationID > 0) {
            update(
                integrationID,
                {
                    name: data?.name,
                    token: data?.secretKey,
                }
            );
            handleClose();
        } else {
            create({
                name: data?.name,
                token: data?.secretKey,
            });
        }
    });

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        props.reset();
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [props]);

    // Set integration ID if URL contains ID.
    // Set form status to OPEN.
    useEffect(() => {
        if (props?.match?.params?.id) {
            setIntegrationID(props?.match?.params?.id);
        }
    }, [props?.match?.params?.id])

    useEffect(() => {
        if (integration?.currentIntegration !== null && integration?.currentIntegration?.id) {
            setIntegrationID(integration?.currentIntegration?.id);
            setIntegrationUUID(integration?.currentIntegration?.uuid)
        }
    }, [integration?.currentIntegration])

    useEffect(() => {
        async function find(id): void {
            setLoading(true);
            await integrationService.findIntegration(id).then((result) => {
                setValue("name", result?.networkName);
                setIntegrationUUID(result?.uuid);
                setValue("secretKey", result?.integrationCredentials[0]?.keyValue)
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                error(e?.response?.data?.error);
                props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
            });
        }

        if (integrationIdParam > 0) {
            find(integrationIdParam);
        }
    }, [error, integrationIdParam, props.history, props.location, setValue]);

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                handleSubmit(handleOnSubmit)();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [handleOnSubmit, handleSubmit]);

    return (

        <Dialog open={true}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"sm"}
                aria-labelledby="clickbank-integration-form"
        >
            <DialogTitle className={classes?.title}>
                <img src="/assets/images/affiliate-networks/clickbank.png" alt="Clickbank integration" width={170}
                     className={classes?.logo}
                />
            </DialogTitle>
            <DialogContent>
                <div className={classes?.root}>

                    {(integration.loading.integration || loading) && <Spinner size={32} overComponentBox={true}/>}

                    <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete={"off"}>
                        <Box display="flex" flexWrap={"wrap"}>
                            <Box p={1} className={classes?.description}>
                                <p>
                                    Use Secret Key and Instant Notification URL specified below for accurate conversion
                                    tracking with ClickBank.
                                </p>
                                <p>
                                    To perform this action log in to your ClickBank account and follow these steps:
                                    Accounts -> Click on your account -> Vendor Settings -> My Site sub-menu -> Advanced
                                    Tools section -> Edit.
                                </p>
                                <p>
                                    1. Update Secret Key and press Save Changes button.<br/>
                                    2. Update and test Instant Notification URL only after new settings for Secret Key
                                    were saved.
                                </p>
                                <p>
                                    Please see our integration
                                    guide <Link target="_blank" underline="hover" rel="noreferrer"
                                                href="https://skro.eu/docs/integrations/clickbank-integration"
                                >
                                    here
                                </Link>
                                </p>
                            </Box>
                            <Box p={1} width="100%">
                                <TextField
                                    error={!!errors.name}
                                    variant="outlined"
                                    margin="dense"
                                    id="name"
                                    label="Integration Name"
                                    size="small"
                                    {...register("name", {required: true, maxLength: 255, minLength: 2})}
                                    name="name"
                                    type="text"
                                    placeholder="Enter your integration name"
                                    fullWidth={true}
                                    defaultValue="ClickBank"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box p={1} width="100%">
                                <TextField
                                    error={!!errors?.secretKey}
                                    variant="outlined"
                                    margin="dense"
                                    id="secretKey"
                                    label="ClickBank Secret Key"
                                    {...register("secretKey", {
                                        required: {value: true, message: "This field is mandatory"},
                                        maxLength: {value: 16, message: "Max length is 16 char"},
                                        minLength: {value: 5, message: "Min length is 5 char"},
                                        pattern: {
                                            value: /^[A-Z0-9]{5,16}$/,
                                            message: "Only capital letters and numbers are allowed."
                                        }
                                    })}
                                    name="secretKey"
                                    size="small"
                                    type="text"
                                    placeholder="Enter Your ClickBank Secret Key"
                                    fullWidth={true}
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <FormHelperText className={classes?.helperText}>
                                    {errors?.secretKey?.message}
                                </FormHelperText>
                            </Box>
                            {integrationUUID !== "" &&
                                <Box p={1} width="100%">
                                    <CopyContentToClipboard
                                        label="ClickBank Instant Notification URL"
                                        copyText={"https://" + defaultDomain + "/clickbank-ins/" + integrationUUID}
                                    />
                                </Box>
                            }
                        </Box>
                    </form>

                    <Box display="flex" flexDirection="row">
                        <Box p={1}>
                            <List>
                                <ListItem className={classes?.listItem}>
                                    <ListItemIcon className={classes?.listItemIcon}>
                                        <Check color={"primary"}/>
                                    </ListItemIcon>
                                    <ListItemText classes={{primary: classes?.listItemText}}>
                                        Receive conversions data via instant notification URL
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </div>
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexWrap="noWrap">
                    <Box mr={2}>
                        <Button onClick={handleClose} color="inherit" variant="outlined">
                            {integrationIdParam > 0 ? "Cancel" : "Close"}
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                            {integrationUUID === "" ? "Generate Instant Notification URL" : "Save & Close"}
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration
    };
};

const mapDispatchToProps = {
    reset: integrationActions.reset,
    create: integrationActions.createClickBankIntegration,
    update: integrationActions.updateClickBankIntegration,
    error: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(ClickbankIntegration);