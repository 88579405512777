import {Controller} from "react-hook-form";
import CloakingDomainSelect from "./CloakingDomainSelect";
import React from "react";
import {Box, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {Info} from "@mui/icons-material";
import {CustomTooltip} from "../landings/LandingForm";

function RedirectMode({watch, control, errors}) {
    return (
        <Box>
            <Box sx={{paddingTop: 1, paddingBottom: "2px", fontSize: "14px", fontWeight: 600}}>
                Redirect mode
            </Box>
            <Controller
                render={({field: {onChange, value, ...props}}) => (
                    <RadioGroup
                        row
                        aria-label="redirectMode"
                        defaultValue="url"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    >
                        <FormControlLabel
                            value="302"
                            control={<Radio color="primary"/>}
                            label={
                                <span>
                                    302
                                    <CustomTooltip
                                        disableFocusListener
                                        title="Standard redirect mode"
                                        sx={{top: 4}}
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </span>
                            }
                        />
                        <FormControlLabel
                            value="meta"
                            control={<Radio color="primary"/>}
                            label={
                                <span>
                                    Meta refresh
                                    <CustomTooltip
                                        disableFocusListener
                                        title="Meta is used to hide your traffic sources from affiliate networks. Referrer is equal to Campaign URL or Click URL."
                                        sx={{top: 4}}
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </span>
                            }
                        />
                        <FormControlLabel
                            value="doubleMeta"
                            control={<Radio color="primary"/>}
                            label={
                                <span>
                                    Double meta refresh
                                    <CustomTooltip
                                        disableFocusListener
                                        title="Double Meta can be applied to hide traffic sources and landing pages from affiliate networks. With this mode selected it is required to add a domain to be displayed as a referrer."
                                        sx={{top: 4}}
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </span>
                            }
                        />
                    </RadioGroup>
                )}
                control={control}
                name="redirectMode"
                defaultValue="302"
            />

            <Box sx={{paddingTop: "6px"}}>
                {watch("redirectMode") === "doubleMeta" && <CloakingDomainSelect control={control} errors={errors}/>}
            </Box>
        </Box>
    );
}

export default RedirectMode;