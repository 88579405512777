import React from "react";
import {Link} from "@mui/material";
import {NavLink} from "react-router-dom";

function Logo(props) {
    const {width} = props;
    return (
        <div>
            <Link color="inherit" component={NavLink} to="/">
                <img src="https://app.skro.eu/logo.svg" alt="Skro" width={width}/>
            </Link>
        </div>
    );
}

export default Logo;