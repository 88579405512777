import React, {useEffect, useMemo, useState} from 'react';
import {Controller} from "react-hook-form";
import {trafficSourceServices} from "../../service/trafficSourceService";
import {campaignServices} from "../../service/campaignService";
import {Autocomplete, TextField} from "@mui/material";
import {useTheme} from "@mui/system";

function GroupBySelect({control, watch, campaignId, inputName, inputLabel, updateGroupBy, defaultValue, setValue}) {

    const theme = useTheme();
    const defaultOptions = useMemo(() => {
        return [
            {label: "Campaign", groupBy: "campaign_id", sortBy: "campaign_id"},
            {label: "Flow", groupBy: "flow_id", sortBy: "flow_id"},
            {label: "Landing", groupBy: "landing_id", sortBy: "landing_id"},
            {label: "Offer", groupBy: "offer_id", sortBy: "offer_id"},
            {label: "Traffic Source", groupBy: "traffic_source_id", sortBy: "traffic_source_id"},
            {label: "Affiliate Network", groupBy: "affiliate_network_id", sortBy: "affiliate_network_id"},
            {label: "Country", groupBy: "country", sortBy: "country"},
            {label: "Region", groupBy: "region", sortBy: "region"},
            {label: "City", groupBy: "city", sortBy: "city"},
            {label: "Device Vendor", groupBy: "device_vendor", sortBy: "device_vendor"},
            {label: "Device Model", groupBy: "device_model", sortBy: "device_model"},
            {label: "Device Type", groupBy: "device_type", sortBy: "device_type"},
            {label: "OS", groupBy: "os", sortBy: "os"},
            {label: "OS Version", groupBy: "os_version", sortBy: "os_version"},
            {label: "Browser", groupBy: "browser", sortBy: "browser"},
            {label: "Browser Version", groupBy: "browser_version", sortBy: "browser_version"},
            {label: "Browser Language", groupBy: "browser_language", sortBy: "browser_language"},
            {label: "ISP", groupBy: "isp", sortBy: "isp"},
            {label: "IPV4", groupBy: "ipv4", sortBy: "ipv4"},
            {label: "Referrer Domain", groupBy: "referrer_domain", sortBy: "referrer_domain"},
            {label: "Date", groupBy: "date", sortBy: "date"},
            {label: "Day", groupBy: "day", sortBy: "day"},
            {label: "Month", groupBy: "month", sortBy: "month"},
            {label: "Quarter", groupBy: "quarter", sortBy: "quarter"},
            {label: "Year", groupBy: "year", sortBy: "year"},
            {label: "Hour Of Day", groupBy: "hour_of_day", sortBy: "hour_of_day"},
            {label: "Day Of Week", groupBy: "day_of_week", sortBy: "day_of_week"},
            {label: "Day Of Year", groupBy: "day_of_year", sortBy: "day_of_year"},
            {label: "Custom 1", groupBy: "custom1", sortBy: "custom1"},
            {label: "Custom 2", groupBy: "custom2", sortBy: "custom2"},
            {label: "Custom 3", groupBy: "custom3", sortBy: "custom3"},
            {label: "Custom 4", groupBy: "custom4", sortBy: "custom4"},
            {label: "Custom 5", groupBy: "custom5", sortBy: "custom5"},
            {label: "Custom 6", groupBy: "custom6", sortBy: "custom6"},
            {label: "Custom 7", groupBy: "custom7", sortBy: "custom7"},
            {label: "Custom 8", groupBy: "custom8", sortBy: "custom8"},
            {label: "Custom 9", groupBy: "custom9", sortBy: "custom9"},
            {label: "Custom 10", groupBy: "custom10", sortBy: "custom10"},
            {label: "Postback Param 1", groupBy: "postback_param1", sortBy: "postback_param1"},
            {label: "Postback Param 2", groupBy: "postback_param2", sortBy: "postback_param2"},
            {label: "Postback Param 3", groupBy: "postback_param3", sortBy: "postback_param3"},
            {label: "Postback Param 4", groupBy: "postback_param4", sortBy: "postback_param4"},
            {label: "Postback Param 5", groupBy: "postback_param5", sortBy: "postback_param5"}
        ];
    }, []);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(defaultOptions);
    const [search, setSearch] = useState("");
    const [trafficData, setTrafficData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        async function fetchData(campaignId) {
            try {
                setLoading(true);
                const campaign = await campaignServices.find(campaignId);
                const traffic = await trafficSourceServices.find(campaign?.trafficId);
                setTrafficData(traffic);
                setOptions(defaultOptions.map((item) => {
                    if (item?.groupBy === "custom1") {
                        item.label = "C1: " + traffic?.custom1Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom2") {
                        item.label = "C2: " + traffic?.custom2Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom3") {
                        item.label = "C3: " + traffic?.custom3Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom4") {
                        item.label = "C4: " + traffic?.custom4Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom5") {
                        item.label = "C5: " + traffic?.custom5Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom6") {
                        item.label = "C6: " + traffic?.custom6Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom7") {
                        item.label = "C7: " + traffic?.custom7Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom8") {
                        item.label = "C8: " + traffic?.custom8Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom9") {
                        item.label = "C9: " + traffic?.custom9Name + "";
                        return item;
                    }
                    if (item?.groupBy === "custom10") {
                        item.label = "C10: " + traffic?.custom10Name + "";
                        return item;
                    }
                    return item;
                }));
            } catch (e) {
                console.log({e});
            } finally {
                setLoading(false);
            }
        }

        if (open && trafficData === null) {
            fetchData(campaignId);
        }

    }, [defaultOptions, trafficData, open, campaignId]);

    const handleSearch = event => {
        setSearch(event.target.value);
    };

    const filteredSelection = () => {
        let ipGroupByList = ["date", "day", "month", "quarter", "year", "day_of_week", "day_of_year", "hour_of_day"];
        let postbackParamGroupByList = ["date", "day", "month", "quarter", "year", "day_of_week", "day_of_year", "hour_of_day", "campaign_id", "offer_id", "landing_id", "flow_id", "affiliate_network_id", "traffic_source_id", "postback_param1", "postback_param2", "postback_param3", "postback_param4", "postback_param5"];
        let removePostbackParamFromGroupByList = ["postback_param1", "postback_param2", "postback_param3", "postback_param4", "postback_param5"];
        let selection = [];
        if (!search) {
            options?.forEach(function (item) {
                if (inputName === "groupBy" && watch("groupBy2")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy" && watch("groupBy3")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy2" && watch("groupBy")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy2" && watch("groupBy3")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy3" && watch("groupBy")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy3" && watch("groupBy2")?.groupBy === item?.groupBy) {
                } else if ((inputName === "groupBy2" || inputName === "groupBy3") && item?.groupBy === "ipv4") {
                } else {
                    if ((inputName === "groupBy2" || inputName === "groupBy3") && watch("groupBy")?.groupBy === "ipv4") {
                        if (ipGroupByList.includes(item?.groupBy)) {
                            selection.push(item);
                        }
                    } else if (inputName === "groupBy2" || inputName === "groupBy3") {
                        if (removePostbackParamFromGroupByList.includes(watch("groupBy")?.groupBy)) {
                            if (postbackParamGroupByList.includes(item?.groupBy)) {
                                selection.push(item);
                            }
                        } else if (postbackParamGroupByList.includes(watch("groupBy")?.groupBy) && inputName === "groupBy2") {
                            selection.push(item);
                        } else if (postbackParamGroupByList.includes(watch("groupBy")?.groupBy) && postbackParamGroupByList.includes(watch("groupBy2")?.groupBy) && inputName === "groupBy3") {
                            if (postbackParamGroupByList.includes(item?.groupBy)) {
                                selection.push(item);
                            }
                        } else {
                            if (!removePostbackParamFromGroupByList.includes(item?.groupBy)) {
                                selection.push(item);
                            }
                        }
                    } else {
                        selection.push(item);
                    }
                }
            });
        } else {
            options?.forEach(function (item) {
                if (inputName === "groupBy" && watch("groupBy2")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy" && watch("groupBy3")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy2" && watch("groupBy")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy2" && watch("groupBy3")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy3" && watch("groupBy")?.groupBy === item?.groupBy) {
                } else if (inputName === "groupBy3" && watch("groupBy2")?.groupBy === item?.groupBy) {
                } else if ((inputName === "groupBy2" || inputName === "groupBy3") && item?.groupBy === "ipv4") {
                } else {
                    if ((inputName === "groupBy2" || inputName === "groupBy3") && watch("groupBy")?.groupBy === "ipv4") {
                        if (ipGroupByList.includes(item?.groupBy)) {
                            selection.push(item);
                        }
                    } else if (inputName === "groupBy2" || inputName === "groupBy3") {
                        if (removePostbackParamFromGroupByList.includes(watch("groupBy")?.groupBy)) {
                            if (postbackParamGroupByList.includes(item?.groupBy)) {
                                selection.push(item);
                            }
                        } else if (postbackParamGroupByList.includes(watch("groupBy")?.groupBy) && inputName === "groupBy2") {
                            selection.push(item);
                        } else if (postbackParamGroupByList.includes(watch("groupBy")?.groupBy) && postbackParamGroupByList.includes(watch("groupBy2")?.groupBy) && inputName === "groupBy3") {
                            if (postbackParamGroupByList.includes(item?.groupBy)) {
                                selection.push(item);
                            }
                        } else {
                            if (!removePostbackParamFromGroupByList.includes(item?.groupBy)) {
                                selection.push(item);
                            }
                        }
                    } else {
                        selection.push(item);
                    }
                }
            });
        }
        return selection;
    };

    useEffect(() => {
        if (!open) {
            setSearch("");
        }
    }, [open]);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id={inputName}
                    disableClearable
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.groupBy === value?.groupBy}
                    getOptionLabel={(option) => option?.label}
                    options={filteredSelection()}
                    loading={loading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            onChange={handleSearch}
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={{
                                minWidth: 150,
                                marginTop: "7px",
                                "& .MuiAutocomplete-input": {
                                    fontSize: 13,
                                    [theme.breakpoints.down(600)]: {
                                        fontSize: 16,
                                    }
                                }
                            }}
                            InputProps={{
                                ...params.InputProps
                            }}
                        />
                    )}
                    onChange={(e, selectedOption) => {
                        setValue(inputName, selectedOption);
                        updateGroupBy(selectedOption);
                    }}
                    {...props}
                />)}
            onChange={([, data]) => data}
            name={inputName}
            control={control}
            defaultValue={defaultValue}
        />
    );
}

export default GroupBySelect;