import React, {createRef, useCallback, useEffect, useState} from 'react';
import Topbar from "../layout/Topbar";
import Sidebar from "../layout/Sidebar";
import {connect} from "react-redux";
import {compose} from "redux";
import DataGrid, {SelectColumn} from 'react-data-grid';
import Spinner from "../spinner/Spinner";
import CustomSnackbar from "../alert/CustomSnackbar";
import {debounce} from "lodash";
import {EmptyRowsRenderer, IsAtBottom} from "../reporting-table/ReactDataGridUtils";
import {workspaceActions} from "../../store/actions/workspaceAction";
import {Box, Chip, CssBaseline, Divider, useTheme} from "@mui/material";
import {CustomMainRootBox} from "../dashboard/Dashboard";
import 'react-data-grid/lib/styles.css';
import useCustomStyles from "../utils/UseCustomStyles";
import {CustomTableContainer} from "./LogClicksTable";
import LogPostbacksToolbar from "./LogPostabcksToolbar";
import {logPostbacksActions} from "../../store/actions/logPostbacksAction";

const customStyles = (theme) => ({
    dataGrid: theme.clicksReportDataGrid
});

function getColumns() {
    let cachedColumns = JSON.parse(localStorage.getItem("log-postbacks-columns"));
    let columns = [
        SelectColumn,
        {key: 'clickId', name: 'Click ID', hidden: false, width: 200, frozen: true, sortable: true, resizable: true},
        {
            key: 'createdAt',
            name: 'Postback Time',
            hidden: false,
            width: 180,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'trafficId',
            name: 'Traffic Source ID',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'method',
            name: 'Method',
            hidden: false,
            width: 90,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'status',
            name: 'Postback Status',
            hidden: false,
            width: 200,
            sortable: true,
            draggable: true,
            renderCell({row}) {
                if (row?.status === "200 OK") {
                    return <Chip label={row?.status} color="success" sx={{height: "20px"}}/>
                } else if (row?.status === "0") {
                    return <Chip label="0" color="success" sx={{height: "20px"}}/>
                } else {
                    return <Chip label={row?.status} color="error" sx={{height: "20px"}}/>
                }
            }
        },
        {key: 'url', name: 'Postback URL', hidden: false, width: 750, sortable: true, draggable: true},
        {key: 'response', name: 'Postback Response', hidden: false, width: 1000, sortable: true, draggable: true},
        {
            key: 'campaignName',
            name: 'Campaign Name',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        },
        {
            key: 'trafficSourceName',
            name: 'Traffic Source Name',
            hidden: false,
            width: 150,
            sortable: true,
            draggable: true,
            resizable: true
        }
    ];

    if (cachedColumns !== null && cachedColumns?.length !== columns.length) {
        return columns;
    }

    if (cachedColumns !== null && cachedColumns !== "" && cachedColumns !== undefined) {
        let sortedColumns = [];
        cachedColumns?.forEach(cachedCol => {
            columns?.forEach(defCol => {
                if (cachedCol?.key === defCol?.key) {
                    defCol.width = cachedCol.width;
                    sortedColumns.push(defCol);
                }
            });
        });
        return sortedColumns;
    }
    return columns;
}

function LogPostbacksTable(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);

    const {
        alert, getClicksReport, updateSortBy, updateOrderBy, updateSortColumns, loadMoreRows, history,
        location, getWorkspaceIDs, resetUpdatedWorkspaceValue
    } = props;
    const {data, isLoading, clickId, orderBy, sortBy, sortColumns, rerender} = props?.logPostbacksState;
    const {from, to} = props?.ddToolbarState;
    const {timezone, currencyCode} = props?.auth?.userSettings;

    const gridRef = createRef(null);

    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [columns, setColumns] = useState(getColumns());

    const onColumnsReorder = (sourceKey, targetKey) => {
        const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
        const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
        const reorderedColumns = [...columns];
        reorderedColumns.splice(targetColumnIndex, 0, reorderedColumns.splice(sourceColumnIndex, 1)[0]);
        localStorage.setItem("log-postbacks-columns", JSON.stringify(reorderedColumns));
        setColumns(reorderedColumns);
    };

    const handleInfinityScroll = (event) => {
        if (isLoading || !IsAtBottom(event)) {
            return;
        }

        let disable = data.page >= Math.ceil(data?.totalRecords / data?.recordsPerPage);
        if (!disable) {
            loadMoreRows(from, to, timezone, clickId, data.page + 1, 100, orderBy, sortBy, getWorkspaceIDs());
        }
    };

    const handleGetClicksReport = () => {
        gridRef?.current?.element?.scrollTo(0, 0);
        getClicksReport(from, to, timezone, clickId, 1, 100, orderBy, sortBy, getWorkspaceIDs());
    };

    const handleRefresh = () => {
        gridRef?.current?.element?.scrollTo(0, 0);
        handleGetClicksReport();
    };

    const cacheColumnResize = (idx, width) => {
        let cachedColumns = JSON.parse(localStorage.getItem("log-postbacks-columns"));
        let modColumns = [];
        cachedColumns?.forEach((item, index) => {
            if (index === idx) {
                item.width = width;
            }
            modColumns.push(item);
        });
        localStorage.setItem("log-postbacks-columns", JSON.stringify(modColumns));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleColumnResize = useCallback(debounce(cacheColumnResize, 50), []);

    useEffect(() => {
        handleGetClicksReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getClicksReport, from, to, timezone, clickId, sortBy, orderBy, currencyCode]);

    // Only for rerender
    useEffect(() => {
        if (rerender) {
            handleGetClicksReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerender]);

    useEffect(() => {
        if (sortColumns.length === 0) {
            updateSortBy("");
            updateOrderBy("");
            return () => {
            }
        }

        const {columnKey, direction} = sortColumns[0];
        updateOrderBy(columnKey);
        updateSortBy(direction);

    }, [updateOrderBy, updateSortBy, sortColumns]);

    // 6. Rerender if user workspaces has changed.
    useEffect(() => {
        if (props?.workspace?.updated !== null) {
            handleGetClicksReport();
            resetUpdatedWorkspaceValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.workspace?.updated])

    return (
        <CustomMainRootBox>
            <CssBaseline/>
            <Sidebar {...props}/>
            <Topbar/>
            <CustomTableContainer>
                <LogPostbacksToolbar
                    rows={data?.records ? data.records : []}
                    onClickRefresh={handleRefresh}
                    searchTerm={clickId}
                    onChangeSearch={(searchTerm) => {
                        props.updateClickId(searchTerm);
                    }}
                    selectedRows={selectedRows}
                    history={history}
                    location={location}
                />
                <Divider/>
                <Box sx={{height: "calc(var(--app-height) - 114px)", width: "100%"}}>

                    {alert.errorMsg !== "" && <CustomSnackbar message={alert.errorMsg}/>}
                    {isLoading && <Spinner overComponentBox={true}/>}

                    {(columns !== null && classes !== null) &&
                        <DataGrid
                            ref={gridRef}
                            rowKeyGetter={(row) => row?.uniqueId}
                            columns={columns}
                            onColumnsReorder={onColumnsReorder}
                            rows={data?.records ? data.records : []}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                                draggable: true
                            }}
                            onColumnResize={(idx, width) => handleColumnResize(idx, width)}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            onSelectedCellChange={({rowIdx, row, column}) => {
                                if (rowIdx !== -1 && column?.key !== "select-row") {
                                    setSelectedRows(new Set().add(row?.uniqueId));
                                }
                            }}
                            sortColumns={sortColumns}
                            onSortColumnsChange={updateSortColumns}
                            onScroll={handleInfinityScroll}
                            noRowsFallback={<EmptyRowsRenderer/>}
                            enableVirtualization={true}
                            className={classes?.dataGrid}
                        />
                    }
                </Box>
            </CustomTableContainer>
        </CustomMainRootBox>
    );
}

const mapStateToProps = (state) => {
    return {
        logPostbacksState: state.logPostbacks,
        ddToolbarState: state.ddToolbar,
        auth: state.auth,
        alert: state.alert,
        workspace: state.workspace
    }
};

const actionCreators = {
    getClicksReport: logPostbacksActions.getPostbacks,
    loadMoreRows: logPostbacksActions.loadMorePostbackRows,
    updateOrderBy: logPostbacksActions.updateOrderBy,
    updateSortBy: logPostbacksActions.updateSortBy,
    updateClickId: logPostbacksActions.updateClickId,
    updateSortColumns: logPostbacksActions.updateSortColumns,
    getWorkspaceIDs: workspaceActions.getWorkspaceIDs,
    resetUpdatedWorkspaceValue: workspaceActions.resetUpdatedWorkspaceValue
};

export default compose(connect(mapStateToProps, actionCreators))(LogPostbacksTable);