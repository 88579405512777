import PropTypes from "prop-types";
import React, {Component} from "react";
import debounce from "lodash/debounce";
import {connect} from "react-redux";
import {compose} from "redux";
import {ddToolbarActions} from "../../store/actions/ddToolbarActions";
import AirbnbDateRangePicker from "../utils/AirbnbDateRangePicker";
import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip
} from "@mui/material";
import {Clear, Refresh} from "@mui/icons-material";

class LogPostbacksToolbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: props.searchTerm
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateSearchChange = this.updateSearchChange.bind(this);
        // Debounce
        this.updateSearchChange = debounce(this.updateSearchChange, 800);
    }

    handleChange(e) {
        this.setState({search: e.target.value});
        this.updateSearchChange();
    }

    updateSearchChange() {
        this.props.onChangeSearch(this.state.search);
    }

    render() {
        const {from, to, dateLabel} = this.props.ddToolbarState;

        return (
            <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{
                color: "text.secondary",
                backgroundColor: "background.paper",
                overflow: "auto",
                width: "100%",
                paddingTop: "8px",
                paddingBottom: "2px"
            }}>
                <Box display="flex" flexGrow={1}>
                    <Box sx={{paddingLeft: "8px", paddingRight: "8px", minWidth: "254px"}}>
                        <TextField
                            autoFocus={this.state.search !== ""}
                            type="text"
                            sx={{marginTop: "0"}}
                            id="search-input"
                            size="small"
                            placeholder={"Search by Click ID"}
                            value={this.state.search}
                            variant="outlined"
                            margin="dense"
                            InputProps={{
                                sx: {
                                    "& input": {
                                        paddingTop: "10px",
                                        backgroundColor: "background.defaultBlue",
                                        width: "204px",
                                        height: "24px",
                                        "&::placeholder": {
                                            fontSize: "13px",
                                            fontWeight: 600,
                                            color: "common.white"
                                        },
                                        '&.MuiOutlinedInput-input': {
                                            padding: "0px 9px",
                                            marginTop: "-1px",
                                        }
                                    },
                                    '&.MuiOutlinedInput-root': {
                                        backgroundColor: "background.defaultBlue",
                                        height: 34.75,
                                        borderWidth: "1px",
                                        borderColor: "border.button",
                                        borderStyle: "solid"
                                    }
                                },
                                'aria-label': 'Search...',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {this.state.search.length > 0 &&
                                            <IconButton
                                                color="inherit"
                                                size="small"
                                                onClick={(e) => {
                                                    e.target.value = "";
                                                    this.handleChange(e);
                                                }}
                                            >
                                                <Clear sx={{color: "text.primary"}} color="inherit"
                                                       fontSize="small"/>
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleChange}
                        />
                    </Box>
                </Box>
                <Box>
                    {this.props.children}
                </Box>
                <Box display="flex" sx={{paddingLeft: "20px", paddingRight: "16px"}}>
                    <Box sx={{paddingLeft: "16px", marginTop: "-1px"}}>
                        <AirbnbDateRangePicker
                            from={from}
                            to={to}
                            dateLabel={dateLabel}
                            onChangeDate={(from, to, dateLabel) => {
                                this.props.updateDateRange(from, to, dateLabel)
                            }}
                        />
                    </Box>
                    <Box sx={{marginTop: "-1px"}}>
                        <Tooltip title="Refresh">
                                <span>
                                    <IconButton
                                        color="inherit"
                                        onClick={() => this.props.onClickRefresh()}
                                        aria-label="refresh"
                                    >
                                        <Refresh color={"inherit"}/>
                                    </IconButton>
                                </span>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        );
    }
}

LogPostbacksToolbar.propTypes = {
    rows: PropTypes.array.isRequired,
    onClickRefresh: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    selectedRows: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ddToolbarState: state.ddToolbar
    }
};

const actionCreators = {
    updateDateRange: ddToolbarActions.updateDateRange,
};

export default compose(connect(mapStateToProps, actionCreators))(LogPostbacksToolbar);