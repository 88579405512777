import React, {useEffect, useState} from "react";
import Spinner from "../../spinner/Spinner";
import {Alert, Paper, Box, Divider, useTheme} from "@mui/material";
import {subscriptionServices} from "../../../service/subscriptionService";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        width: "100%",
        padding: "2px 16px 16px 16px",
        backgroundColor: theme.palette.background.defaultBlue,
        borderRadius: 4
    },
    paper: {
        position: "relative",
        minHeight: 150
    },
    header: {
        fontSize: 18,
        padding: "0px 16px 0 0px"
    },
    download: {
        color: theme.palette.primary.light,
        textDecoration: "none",
        "&:hover": {
            color: theme.palette.primary.dark,
        }
    },
    flexBoxHeader: {
        fontSize: 12,
        textAlign: "left",
        padding: "2px 4px 12px 4px",
        color: theme.palette.text.tableHeader,
        [theme.breakpoints.down(600)]: {
            paddingLeft: 16,
            paddingRight: 16
        },
    }
});

export default function Invoices() {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [invoices, setInvoices] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setErrorMsg("");
            await subscriptionServices.getInvoices().then((result) => {
                setInvoices(result);
            }).catch((error) => {
                setErrorMsg(error?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
        }

        if (invoices === null) {
            fetchData();
        }
    }, [invoices]);

    return (
        <Box className={classes?.root}>
            <Box mt={1}>
                {errorMsg !== "" && <Alert variant="outlined" color="error">{errorMsg}</Alert>}
            </Box>
            <Box>
                <Box>
                    <h3>Invoices</h3>
                </Box>
                <Box>
                    <Paper className={classes?.paper} elevation={0}>
                        {loading && <Spinner overComponentBox={true} size={32} noBackShadow={true}/>}
                        {!loading &&
                            <div>
                                <div style={{paddingBottom: 12}}>
                                    <Box display="flex" flexDirection="row" alignItems="center"
                                         justifyContent="space-between" className={classes?.flexBoxHeader}>
                                        <Box width="130px">Date</Box>
                                        <Box width="100px">Paid</Box>
                                        <Box>Actions</Box>
                                    </Box>
                                    <Divider/>
                                </div>
                                <div style={{overflowY: "auto", maxHeight: 422}}>
                                    {
                                        invoices !== null && invoices?.map((invoice, index) => {
                                            return (
                                                <div key={index}>
                                                    <Box display="flex" flexDirection="row" alignItems="center"
                                                         justifyContent="space-between" style={{padding: "10px 2px"}}>
                                                        <Box width="130px">{invoice?.dateTime}</Box>
                                                        <Box width="98px">€ {(invoice?.amountPaid / 100).toFixed(2)}</Box>
                                                        <Box>
                                                            <a href={invoice?.pdf} rel="noreferrer"
                                                               className={classes?.download}>
                                                                Download
                                                            </a>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}