import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import {Autocomplete, Box, Chip, TextField, Tooltip} from "@mui/material";
import {Info} from "@mui/icons-material";

function ReactHookFormChipInput(
    {name, label, placeholder, control, values, defaultValue, rules, updateOnChange, inputLabelShrink = true}
) {
    const labelId = `${name}-label`;
    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    open={false}
                    openOnFocus={false}
                    multiple
                    id={labelId}
                    options={values.map((i) => i)}
                    defaultValue={defaultValue}
                    freeSolo
                    fullWidth={true}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{
                                    userSelect: 'unset',
                                    '& .MuiChip-label': {
                                        userSelect: 'unset',
                                    },
                                }}
                                label={option}
                                {...getTagProps({index})}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    label={label}
                                    placeholder={placeholder}
                                    InputLabelProps={{
                                        shrink: inputLabelShrink,
                                    }}
                                />
                            </Box>
                            <Box p={1}>
                                <Box sx={{paddingTop: "2px", cursor: "pointer", paddingLeft: "6px"}}>
                                    <Tooltip disableFocusListener
                                             title="Press ENTER to add value.">
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    onChange={(e, data) => {
                        onChange(data);
                        updateOnChange();
                    }}
                    {...props}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
}

ReactHookFormChipInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    control: PropTypes.object.isRequired,
    values: PropTypes.array,
    defaultValue: PropTypes.array,
    rules: PropTypes.any,
    updateOnChange: PropTypes.func
};

ReactHookFormChipInput.defaultProps = {
    name: "",
    label: "",
    placeholder: "",
    control: {},
    values: [],
    defaultValue: [],
    updateOnChange: () => null,
};

export default ReactHookFormChipInput;