import React from 'react';
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import {deviceVendors} from "../../utils/DataList";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Chip, TextField} from "@mui/material";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

function DeviceVendorMultiSelect({control, inputLabel, inputName, updateOnChange}) {
    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    multiple
                    disableCloseOnSelect={true}
                    id="device-vendor-multi-select"
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => option}
                    options={deviceVendors}
                    renderOption={(props, option, {selected, index}) => (
                        <li {...props} key={index}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                sx={{mr: 1}}
                                checked={selected}
                                color="primary"
                            />
                            {option}
                        </li>
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" color="primary" size="small"
                                  label={option} {...getTagProps({index})} />
                        ))
                    }
                    renderInput={params => (
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    label={inputLabel}
                                />
                            </Box>
                        </Box>
                    )}
                    onChange={(e, data) => {
                        onChange(data);
                        updateOnChange();
                    }}
                    {...props}
                />
            )}
            defaultValue={[]}
            name={inputName}
            control={control}
            onChange={([, data]) => data}
        />
    );
}

DeviceVendorMultiSelect.propTypes = {
    control: PropTypes.object.isRequired,
    inputLabel: PropTypes.string,
    inputName: PropTypes.string,
    updateOnChange: PropTypes.func
};

DeviceVendorMultiSelect.defaultProps = {
    control: {},
    inputName: "deviceVendor",
    inputLabel: "Select device vendor",
    updateOnChange: () => null,
};

export default DeviceVendorMultiSelect;