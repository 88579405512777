import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControlLabel,
    RadioGroup,
    Tooltip,
    useMediaQuery,
    useTheme,
    Radio
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {settingsServices} from "../../service/settingsService";
import Spinner from "../spinner/Spinner";
import BaseCurrencySelect from "./BaseCurrencySelect";
import {baseCurrencies} from "../utils/DataList";
import CustomSnackbar from "../alert/CustomSnackbar";
import {Info} from "@mui/icons-material";
import {SettingsGeneralButtonBox, SettingsGeneralPaper, SettingsGeneralRoot} from "./ProfileSettings";

const CompanySettings = (props) => {
    const theme = useTheme();
    const down600 = useMediaQuery(theme.breakpoints.down(600));

    const [loading, setLoading] = useState(true);
    const [companySettings, setCompanySettings] = useState(null);

    const {handleSubmit, setValue, control} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        props.clear();
        let formData = {baseCurrencyCode: data?.currency?.code, conversionTime: data?.conversionTime};
        await settingsServices.updateCompanySettings(formData).then((response) => {
            if (response !== null && response !== undefined) {
                props.successMsg("Company account settings has been successfully updated.");
            }
        }).catch((error) => {
            if (error?.response?.status === 403) {
                props.errorMsg("Access forbidden. You have no rights to update your company settings.");
            } else if (error?.response?.data?.error) {
                props.errorMsg(error.response.data.error);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        async function fetchCompanySettings() {
            props.clear();
            setLoading(true);
            await settingsServices.fetchCompanySettings().then((response) => {
                if (response !== null && response !== undefined) {
                    baseCurrencies.forEach((item) => {
                        if (item.code === response?.baseCurrencyCode) {
                            setValue("currency", item);
                        }
                        setValue("conversionTime", response?.conversionTime);
                    });
                    setCompanySettings(response);
                }
            }).catch((error) => {
                if (error?.response?.status === 403) {
                    props.errorMsg("Access forbidden. You have no rights to fetch company settings.");
                } else if (error?.response?.data?.error) {
                    props.errorMsg(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (companySettings === null) {
            fetchCompanySettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companySettings, setValue]);

    return (
        <SettingsGeneralRoot>
            <SettingsGeneralPaper elevation={0}>
                {loading && <Spinner size={32} overComponentBox={true}/>}

                <h3>Company Account Settings</h3>

                <Box sx={{color: "text.disabledLight", pb: 1}}>
                    Base currency will be used to store your data. Changing the base currency does not change data
                    that has already been saved. These settings affect all users.
                </Box>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>

                    {props?.state?.alert?.successMsg !== "" &&
                        <CustomSnackbar message={props?.state?.alert?.successMsg} severity="success" timeout={3500}/>
                    }
                    {props?.state?.alert?.errorMsg !== "" &&
                        <CustomSnackbar message={props?.state?.alert?.errorMsg} severity="error" timeout={3500}/>
                    }

                    <Box display="flex" flexWrap="wrap" flexDirection="column">
                        <Box mb={1}>
                            <BaseCurrencySelect control={control}/>
                        </Box>
                        <Box>
                            <Controller
                                render={({field: {onChange, value}}) => (
                                    <RadioGroup
                                        row
                                        aria-label="Conversion postback time"
                                        defaultValue="postback"
                                        value={value}
                                        onChange={(e) => onChange(e.target.value)}
                                    >
                                        <Box display="flex" flexDirection="column" flexWrap="wrap">
                                            <Box display="flex" flexDirection="row" mr={3}>
                                                <Box>
                                                    <FormControlLabel
                                                        value="postback"
                                                        control={<Radio color="primary"/>}
                                                        label="Conversion postback time"
                                                    />
                                                </Box>
                                                <Box sx={{
                                                    paddingTop: "11px",
                                                    marginLeft: "-8px",
                                                    cursor: "pointer"
                                                }}>
                                                    <Tooltip disableFocusListener
                                                             title="Conversions will be counted on postback date time in reports. This change affect new conversions only."
                                                    >
                                                        <Info color={"primary"} fontSize={"small"}/>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                            <Box display="flex" flexDirection="row" mr={3}>
                                                <Box>
                                                    <FormControlLabel
                                                        value="visit"
                                                        control={<Radio color="primary"/>}
                                                        label="Conversion visit time"
                                                    />
                                                </Box>
                                                <Box sx={{
                                                    paddingTop: "11px",
                                                    marginLeft: "-8px",
                                                    cursor: "pointer"
                                                }}>
                                                    <Tooltip disableFocusListener
                                                             title="Conversions will be counted on visit date time in reports. This change affect new conversions only."
                                                    >
                                                        <Info color={"primary"} fontSize={"small"}/>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </RadioGroup>
                                )}
                                control={control}
                                name="conversionTime"
                                defaultValue="postback"
                            />
                        </Box>
                    </Box>

                    <SettingsGeneralButtonBox>
                        <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained"
                                disabled={loading} fullWidth={down600}>
                            Update
                        </Button>
                    </SettingsGeneralButtonBox>
                </form>
            </SettingsGeneralPaper>
        </SettingsGeneralRoot>
    );
};

export default CompanySettings;