import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme,
    Box,
    Autocomplete, Alert, Button
} from "@mui/material";
import Spinner from "../../spinner/Spinner";
import {Controller, useForm} from "react-hook-form";
import {subscriptionServices} from "../../../service/subscriptionService";
import {countries} from "../../utils/DataList";

function UpdateBillingDetails(props) {
    const {onClose, open} = props;

    const {register, handleSubmit, control, setValue, formState: {errors}} = useForm();

    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOnSubmit = async (data) => {
        setLoading(true);
        let dataObj = {
            name: data?.name,
            address1: data?.address1,
            address2: data?.address2,
            country: data?.country?.code,
            city: data?.city,
            state: data?.state,
            zip: data?.zip
        };

        await subscriptionServices.updateBillingDetails(dataObj).then(() => {
            handleClose();
        }).catch((error) => {
            setErrorMsg(error?.response?.data?.error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        setErrorMsg("");
        onClose();
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            subscriptionServices.getBillingDetails().then((result) => {
                setValue("name", result?.name);
                setValue("address1", result?.address1);
                setValue("address2", result?.address2);
                setValue("city", result?.city);
                setValue("state", result?.state);
                setValue("zip", result?.zip);
                countries.forEach((item) => {
                    if (item.code === result?.country && item.code !== "") {
                        setValue("country", {code: item.code, label: item.label});
                    }
                });
            }).catch((error) => {
                setErrorMsg(error?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [open, control, setValue]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={fullScreen}
            aria-labelledby="update-billing-details"
        >
            <DialogTitle style={{textAlign: "center"}} disableTypography>Update billing details</DialogTitle>
            {loading && <Spinner size={32} overComponentBox={true}/>}
            <DialogContent style={{padding: 6}}>
                <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete={"off"}>
                    {errorMsg !== "" &&
                        <Alert
                            style={{marginBottom: 10, marginLeft: 8, marginRight: 8}}
                            variant="outlined"
                            severity="error">
                            {errorMsg}
                        </Alert>
                    }
                    <Box display="flex" p={1}>
                        <TextField
                            error={!!errors?.name}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            id="name"
                            {...register("name", {maxLength: 100})}
                            name="name"
                            label="Name or Company name"
                            placeholder="Enter your name or company name"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box display="flex" flexWrap="nowrap">
                        <Box p={1} flexGrow={1}>
                            <TextField
                                error={!!errors?.address1}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="address1"
                                {...register("address1", {maxLength: 100})}
                                name="address1"
                                label="Address 1"
                                placeholder="Enter your address 1"
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box p={1} flexGrow={1}>
                            <TextField
                                error={!!errors?.address2}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="address2"
                                {...register("address2", {maxLength: 100})}
                                name="address2"
                                label="Address 2"
                                placeholder="Enter your address 2"
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexWrap="nowrap" justifyContent="space-between" alignItems="stretch">
                        <Box p={1} flexGrow={3}>
                            <BillingDetailsCountrySelect control={control}/>
                        </Box>
                        <Box p={1} flexGrow={1}>
                            <TextField
                                error={!!errors?.city}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="city"
                                {...register("city", {maxLength: 100})}
                                name="city"
                                label="City"
                                placeholder="Enter your city"
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexWrap="nowrap">
                        <Box p={1} flexGrow={1}>
                            <TextField
                                error={!!errors?.state}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="state"
                                {...register("state", {maxLength: 100})}
                                name="state"
                                label="State"
                                placeholder="Enter your state"
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box p={1} flexGrow={1}>
                            <TextField
                                error={!!errors?.zip}
                                variant="outlined"
                                margin="dense"
                                size="small"
                                id="zip"
                                {...register("zip", {maxLength: 100})}
                                name="zip"
                                label="Zip"
                                placeholder="Enter your zip code"
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions style={{padding: 15}}>
                <Button type="button" color="inherit" variant="text" onClick={handleClose}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" onClick={handleSubmit(handleOnSubmit)}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function BillingDetailsCountrySelect({control}) {
    let listOptions = [];
    countries?.forEach((item) => {
        if (item?.label !== "Global") {
            listOptions.push({code: item?.code, label: item?.label});
        }
    });

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    id="billing-details-country-select"
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    getOptionLabel={(option) => option.label}
                    options={listOptions}
                    renderInput={params => (
                        <TextField
                            {...params}
                            id="billing-details-country"
                            variant="outlined"
                            margin="dense"
                            size="small"
                            placeholder="Select a country"
                            label="Country"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    style={{width: "100%"}}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            name="country"
            defaultValue={null}
            control={control}
            onChange={([, data]) => data}
        />
    );
}

export default UpdateBillingDetails;