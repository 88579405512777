import {postbackDomain, postbackURL} from "./config";

export function GetDefaultTrackingDomain() {
    let userSettings = JSON.parse(localStorage.getItem("user-settings"));
    return userSettings?.defaultTrackingDomain !== "" ? userSettings?.defaultTrackingDomain : postbackDomain
}

export function GetUserTimezone() {
    let userSettings = JSON.parse(localStorage.getItem("user-settings"));
    return userSettings?.timezone !== "" ? userSettings?.timezone : "Etc/UTC"
}

export function GetPostbackLink(clickId, payout, transactionId, status) {
    let userSettings = JSON.parse(localStorage.getItem("user-settings"));
    return ReplaceAll(
        postbackURL,
        {
            "skrotrack.com": (userSettings?.defaultTrackingDomain !== "" ? userSettings?.defaultTrackingDomain : postbackDomain),
            "clickId=REPLACE": "clickId=" + (clickId !== "" && clickId !== undefined ? clickId : "REPLACE"),
            "payout=OPTIONAL": "payout=" + (payout !== "" && payout !== undefined ? payout : "OPTIONAL"),
            "transactionId=OPTIONAL": "transactionId=" + (transactionId !== "" && transactionId !== undefined ? transactionId : "OPTIONAL"),
            "status=OPTIONAL": "status=" + (status !== "" && status !== undefined ? status : "OPTIONAL")
        }
    );
}

export function GetReplacedPostbackDomainLink() {
    let userSettings = JSON.parse(localStorage.getItem("user-settings"));
    return ReplaceAll(
        postbackURL,
        {
            "skrotrack.com": (userSettings?.defaultTrackingDomain !== "" ? userSettings?.defaultTrackingDomain : postbackDomain)
        }
    );
}

export function ReplaceAll(str, mapObj) {
    let re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    return str.replace(re, function (matched) {
        return mapObj[matched];
    });
}

export function IsNumber(str) {
    if (str === null || str === undefined || str === "") {
        return false;
    }
    return str.match(/^\d+$/) !== null;
}

export function HasOnlyNumbersAndLetters(str) {
    if (str === null || str === undefined || str === "") {
        return false;
    }
    return str.match(/^[A-Za-z0-9]*$/) !== null;
}

export function GenerateRandomToken(n) {
    let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let token = '';
    for (let i = 0; i < n; i++) {
        token += chars[Math.floor(Math.random() * chars.length)];
    }
    return token;
}

export function FormatGoogleAdAccountId(s) {
    return s.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
}

export function GetCommaSeparatedList(data) {
    let s = "";
    for (const id of data) {
        if (s === "") {
            s = id;
        } else {
            s += "," + id;
        }
    }
    return s;
}