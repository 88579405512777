import moment from 'moment-timezone';
import {dashboardConstants} from "../constants/dashboardConstants";
import {GetUserTimezone} from "../../helpers/common";

const initialState = {
    from: moment.tz(GetUserTimezone()).startOf("d").format("YYYY-MM-DD HH:mm:ss"),
    to: moment.tz(GetUserTimezone()).endOf("d").format("YYYY-MM-DD HH:mm:ss"),
    dateLabel: "Today"
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case dashboardConstants.DASHBOARD_UPDATE_DATE_RANGE:
            return {
                ...state,
                from: action.payload.from,
                to: action.payload.to,
                dateLabel: action.payload.dateLabel
            };
        default:
            return state;
    }
};

export default dashboardReducer;