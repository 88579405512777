import {cloakingDomainConstants} from "../constants/cloakingDomainConstants";

const initialState = {
    cloakingDomains: [],
    successMsg: '',
    errorMsg: '',
    isLoading: false,
    activeStep: 0,
    cloakingDomain: ""
};

const cloakingDomainReducer = (state = initialState, action) => {
    switch (action.type) {
        case cloakingDomainConstants.SET_CLOAKING_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case cloakingDomainConstants.LOADING_CLOAKING_DOMAINS:
            return {
                ...state,
                cloakingDomains: action?.result?.cloakingDomains,
                successMsg: '',
                errorMsg: '',
                isLoading: false,
            };
        case cloakingDomainConstants.LOADING_CLOAKING_DOMAINS_ERROR:
            return {
                ...state,
                successMsg: '',
                errorMsg: 'Unable to load cloaking domains. Please try again.',
                isLoading: false,
            };
        case cloakingDomainConstants.DELETE_CLOAKING_DOMAIN:
            return {
                ...state,
                cloakingDomains: action.domains,
                successMsg: 'Cloaking domain has been successfully deleted.',
                errorMsg: '',
                isLoading: false,
            };
        case cloakingDomainConstants.DELETE_CLOAKING_DOMAIN_ERROR:
            return {
                ...state,
                successMsg: '',
                errorMsg: action.error,
                isLoading: false,
            };
        case cloakingDomainConstants.ADD_CLOAKING_DOMAIN:
            return {
                ...state,
                cloakingDomains: action.domains,
                successMsg: 'Cloaking domain has been successfully added.',
                errorMsg: '',
                isLoading: false,
                activeStep: 0,
                cloakingDomain: ""
            };
        case cloakingDomainConstants.ADD_CLOAKING_DOMAIN_ERROR:
            return {
                ...state,
                successMsg: '',
                errorMsg: action.error,
                isLoading: false
            };
        case cloakingDomainConstants.CLEAR_CLOAKING_ERRORS:
            return {
                ...state,
                successMsg: '',
                errorMsg: ''
            };
        case cloakingDomainConstants.SET_CLOAKING_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.activeStep
            };
        case cloakingDomainConstants.SET_CLOAKING_DOMAIN:
            return {
                ...state,
                cloakingDomain: action.cloakingDomain
            };
        default:
            return state;
    }
};

export default cloakingDomainReducer;