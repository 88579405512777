import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    Divider,
    List,
    ListItemText,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Paper,
    Button,
    Chip,
    Tooltip,
    IconButton, useTheme, ListItemButton
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import AlertDialog from "../alert/AlertDialog";
import {Delete} from "@mui/icons-material";
import CustomSnackbar from "../alert/CustomSnackbar";
import {workspaceActions} from "../../store/actions/workspaceAction";
import WorkspaceMenuButton from "./WorkspaceMenuButton";
import EditWorkspacePopUp from "./EditWorkspacePopUp";
import {alertActions} from "../../store/actions/alertActions";

function Workspaces(props) {
    const theme = useTheme();

    const {
        loadWorkspaces, findWorkspaceUsers, deleteUserFromWorkspace, updateWorkspace, deleteWorkspace, error
    } = props;

    const {workspace, auth, alert} = props?.state;

    const [prompt, setPrompt] = useState(false);
    const [deleteUser, setDeleteUser] = useState(null);

    const [removeWorkspacePrompt, setRemoveWorkspacePrompt] = useState(false);
    const [removeWorkspace, setRemoveWorkspace] = useState(null);

    const [editWorkspacePrompt, setEditWorkspacePrompt] = useState(false);
    const [editWorkspace, setEditWorkspace] = useState(null);

    const [selectedWorkspace, setSelectedWorkspace] = useState({id: 1, name: "Master", workspaceType: "master"});

    const handlePrompt = (u) => {
        setDeleteUser(u);
        setPrompt(true);
    };

    const handleDelete = (confirm) => {
        if (!confirm) {
            setPrompt(false);
            setDeleteUser(null);
            return false;
        }
        deleteUserFromWorkspace(selectedWorkspace?.id, deleteUser?.id);
        setPrompt(false);
        setDeleteUser(null);
    };

    const handleRemoveWorkspacePrompt = (i) => {
        setRemoveWorkspacePrompt(true);
        setRemoveWorkspace(i);
    };

    const handleRemoveWorkspace = (confirm) => {
        if (!confirm) {
            setRemoveWorkspacePrompt(false);
            setRemoveWorkspace(null);
            return false;
        }
        deleteWorkspace(removeWorkspace?.id);
        setRemoveWorkspacePrompt(false);
        setRemoveWorkspace(null);
    };

    const handleEditWorkspacePrompt = (i) => {
        setEditWorkspacePrompt(true);
        setEditWorkspace(i);
    };

    const handleEditWorkspace = (item, data) => {
        let trackingDomainIds = "";
        data?.trackingDomain?.forEach((ws) => {
            if (trackingDomainIds !== "") {
                trackingDomainIds += "," + ws?.value
            } else {
                trackingDomainIds += ws?.value
            }
        });

        if (trackingDomainIds?.length === 0) {
            error("Choose at least 1 tracking domain.");
            return false;
        }

        updateWorkspace(item?.id, {name: data?.name, trackingDomainIds: trackingDomainIds});
        setEditWorkspacePrompt(false);
        setEditWorkspace(null);
    };

    const handleAssignUser = () => {
        props.history.push({
            pathname: "/settings/team/workspaces/" + parseInt(selectedWorkspace?.id) + "/assign/",
            state: {background: props.location}
        });
    };

    const handleCreateWorkspace = () => {
        props.history.push({pathname: "/settings/team/workspaces/add", state: {background: props.location}});
    };

    const handleChangeWorkspace = (i) => {
        if (selectedWorkspace?.id === i?.id) {
            return false;
        }
        setSelectedWorkspace(i);
        findWorkspaceUsers(i?.id);
    };

    const permissionToRemoveUserFromWorkspace = (u) => {
        if (selectedWorkspace?.workspaceType === "master" && u.role === "Owner" && auth?.user?.uid === u?.id) {
            return false;
        }
        if (selectedWorkspace?.workspaceType === "master" && u.role === "Owner") {
            return false;
        } else if (selectedWorkspace?.workspaceType === "public") {
            return false;
        }
        return true;
    };

    useEffect(() => {
        loadWorkspaces({});
        findWorkspaceUsers(selectedWorkspace?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [findWorkspaceUsers, loadWorkspaces]);

    return (
        <Box
            sx={{
                width: "100%",
                padding: "8px 8px",
                marginLeft: 2,
                marginRight: 2,
                minWidth: 300,
                margin: "0 auto",
                backgroundColor: "background.defaultBlue"
            }}
        >

            {workspace?.loading?.read && <Spinner size={32} overComponentBox={true}/>}
            {workspace?.loading?.findWorkspaceUsers && <Spinner overComponentBox={true}/>}

            <Box display="flex">
                <Box
                    flexGrow={1}
                    sx={{
                        fontSize: 16,
                        paddingLeft: 1,
                        lineHeight: 1.167,
                        textAlign: "left",
                        fontWeight: 700,
                        paddingTop: 2,
                        paddingBottom: 2
                    }}
                >
                    Manage workspaces
                </Box>
                <Box alignSelf="center" mr={1}>
                    <Button type="button" onClick={handleCreateWorkspace} color="primary" variant="contained">
                        Create
                    </Button>
                </Box>
            </Box>
            <Box>
                <Paper sx={{minHeight: 200, boxShadow: 0}} elevation={0}>

                    {alert?.errorMsg !== "" &&
                        <CustomSnackbar message={alert?.errorMsg} timeout={3500}/>
                    }
                    {alert?.successMsg !== "" &&
                        <CustomSnackbar message={alert?.successMsg} severity="success" timeout={3500}/>
                    }

                    <Box sx={{paddingLeft: 1, paddingBottom: 2, color: "text.disabledLight"}}>
                        <ul style={{padding: 0, margin: 0, paddingLeft: "12px"}}>
                            <li>By default there are 2 default
                                workspaces <strong>Master</strong> and <strong>Public</strong>.
                            </li>
                            <li>Public workspace is visible for all your members.</li>
                            <li>Master is a default private workspace and only Owner has the access to this workspace
                                and can
                                invite other members to Master.
                            </li>
                            <li>
                                As Account Owner you can create unlimited amount of private workspaces to separate the
                                required
                                campaigns and elements and delegate the management to your team members.
                            </li>
                        </ul>
                    </Box>

                    {prompt &&
                        <AlertDialog
                            question="Delete confirmation"
                            text={"Are you sure you want to remove user from workspace?"}
                            confirm={handleDelete}
                        />
                    }

                    {removeWorkspacePrompt &&
                        <AlertDialog
                            question="Delete confirmation"
                            text={"Are you sure you want to delete the workspace named \"" + removeWorkspace?.name + "\"? " +
                                "This action cannot be undone. All associated records will be moved to the Master workspace."}
                            confirm={handleRemoveWorkspace}
                            buttonColor="error"
                        />
                    }

                    {editWorkspacePrompt &&
                        <EditWorkspacePopUp
                            onSubmit={handleEditWorkspace}
                            item={editWorkspace}
                            onClose={() => setEditWorkspacePrompt(false)}
                            open={true}
                        />
                    }

                    <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
                        <Box
                            p={1}
                            sx={{
                                width: 300,
                                [theme.breakpoints.down(1090)]: {
                                    width: "100%",
                                    overflow: "auto",
                                    maxHeight: 200,
                                    marginBottom: 3
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    maxWidth: 350,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                Workspaces
                            </Box>
                            <Box>
                                <List sx={{height: "100%", maxHeight: 500, overflow: "auto"}}>
                                    {workspace?.workspaces?.map((item, inx) => {
                                        return (
                                            <Box display="flex" key={item?.id} sx={{
                                                ":hover": {
                                                    backgroundColor: "background.rowHover"
                                                }
                                            }}>
                                                <Box flexGrow={1}>
                                                    <ListItemButton
                                                        role={undefined} dense
                                                        selected={selectedWorkspace?.id === item?.id}
                                                        onClick={() => {
                                                            handleChangeWorkspace(item);
                                                        }}
                                                        sx={{
                                                            padding: "8px 16px",
                                                            "&.Mui-selected": {
                                                                backgroundColor: "background.navbarHover",
                                                                borderLeftWidth: "2px",
                                                                borderLeftStyle: "solid",
                                                                borderLeftColor: "primary.main",
                                                                ":hover": {
                                                                    backgroundColor: "background.rowHover"
                                                                }
                                                            },
                                                            ":hover": {
                                                                backgroundColor: "background.rowHover"
                                                            }
                                                        }}
                                                    >
                                                        <ListItemText
                                                            id={item?.id}
                                                            primary={item?.name}
                                                            sx={{
                                                                "& span": {
                                                                    fontSize: 14,
                                                                    maxWidth: 200,
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis"
                                                                }
                                                            }}
                                                        />
                                                    </ListItemButton>
                                                </Box>
                                                {item?.id !== 1 && item?.id !== 2 &&
                                                    <Box
                                                        sx={{
                                                            paddingTop: "5px",
                                                            paddingLeft: "5px",
                                                            paddingRight: "5px",
                                                            ...(selectedWorkspace?.id === item?.id && {
                                                                backgroundColor: "background.navbarHover",
                                                            })
                                                        }}
                                                    >
                                                        <WorkspaceMenuButton
                                                            item={item}
                                                            clickOnDelete={handleRemoveWorkspacePrompt}
                                                            clickOnEdit={handleEditWorkspacePrompt}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Box>

                        <Divider orientation="vertical" flexItem
                                 sx={{[theme.breakpoints.down(1090)]: {display: "none"}}}/>

                        <Box flexGrow={1} p={1} sx={{overflow: "auto"}}>
                            <Box display="flex" pb={2}>
                                <Box
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        maxWidth: 350,
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis"
                                    }}
                                    alignSelf="center"
                                >
                                    {selectedWorkspace?.name}
                                </Box>
                                <Box display="flex" sx={{marginLeft: "auto"}}>
                                    <Box alignSelf="center">
                                        <Button type="button" onClick={handleAssignUser} color="primary"
                                                variant="contained">
                                            Assign Users
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <TableContainer>
                                    <Table stickyHeader size="small">
                                        <TableHead>
                                            <TableRow sx={{whiteSpace: "nowrap"}}>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Role</TableCell>
                                                <TableCell align="left">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {workspace?.workspaceUsers?.length > 0 &&
                                            <TableBody sx={{whiteSpace: "nowrap", maxHeight: "300px"}}>
                                                {workspace?.workspaceUsers?.map((item) => (
                                                    <TableRow key={item?.id}>
                                                        <TableCell>
                                                            {item?.email}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.fullName !== " " ? item?.fullName : "Name not specified"}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Chip label={item?.role} color="secondary" size="small"/>
                                                        </TableCell>
                                                        <TableCell>
                                                            {!item?.isDeleted &&
                                                                <Box display="flex" flexDirection="row"
                                                                     flexWrap="noWrap" justifyContent="right"
                                                                >
                                                                    {permissionToRemoveUserFromWorkspace(item) &&
                                                                        <Box ml={2}>
                                                                            <Tooltip title="Remove user from workspace">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    aria-label="remove user form workspace"
                                                                                    onClick={() => handlePrompt(item)}
                                                                                >
                                                                                    <Delete fontSize="small"
                                                                                            color="error"/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        }
                                    </Table>
                                    {workspace?.workspaces?.length === 0 && <Box textAlign="center">No records</Box>}
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
        return {
            state: {
                workspace: state.workspace,
                auth: state.auth,
                alert: state.alert
            }
        }
    }
;

const mapDispatchToProps = {
    loadWorkspaces: workspaceActions.read,
    findWorkspaceUsers: workspaceActions.findWorkspaceUsers,
    deleteUserFromWorkspace: workspaceActions.deleteUserFromWorkspace,
    updateWorkspace: workspaceActions.updateWorkspace,
    deleteWorkspace: workspaceActions.deleteWorkspace,
    error: alertActions.error
};

export default connect(mapStateToProps, mapDispatchToProps)(Workspaces);