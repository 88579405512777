import {alertActions} from "./alertActions";
import {apiAccessConstants} from "../constants/apiAccessConstants";
import {apiAccessServices} from "../../service/apiAccessService";

export const apiAccessActions = {
    fetch,
    revoke,
    reload
};

function fetch() {
    return dispatch => {
        dispatch({type: apiAccessConstants.API_KEYS_LOADING_SPINNER, payload: {loading: true}});
        dispatch(alertActions.error(""));
        apiAccessServices.fetch()
            .then((result) => {
                if (result === null) {
                    result = [];
                }
                dispatch({type: apiAccessConstants.API_KEYS_FETCH, result});
            }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
                dispatch({type: apiAccessConstants.API_KEYS_LOADING_SPINNER, payload: {loading: false}});
            }
        );
    };
}

function revoke(id) {
    return dispatch => {
        dispatch({type: apiAccessConstants.API_KEYS_LOADING_SPINNER, payload: {loading: true}});
        dispatch(alertActions.error(""));
        apiAccessServices.remove(id).then((result) => {
            dispatch({type: apiAccessConstants.API_KEYS_REVOKE, payload: {id: result?.id}});
        }).catch((error) => {
                dispatch(alertActions.error(error?.response?.data?.error));
                dispatch({type: apiAccessConstants.API_KEYS_LOADING_SPINNER, payload: {loading: false}});
            }
        );
    };
}

function reload() {
    return dispatch => {
        dispatch({type: apiAccessConstants.API_KEYS_RELOAD});
    };
}