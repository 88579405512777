import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import {flowServices} from "../../service/flowService";
import {alertActions} from "../../store/actions/alertActions";
import {connect} from "react-redux";
import {Autocomplete, TextField} from "@mui/material";

function FlowSelect(props) {

    const {control, errors, updateSelection, inputValue, inputName, countryCode, errorMsg, setValue} = props;

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(search, countryCode): void {
            setLoading(true);
            await flowServices.read(search, countryCode).then((response) => {
                setOptions(
                    response?.flows?.map(item => {
                        let code = item?.countryCode
                        if (code === "") {
                            code = "Global";
                        }
                        return {
                            value: item?.id,
                            label: code + " - " + item?.name,
                            flow: item?.flow
                        }
                    })
                );
            }).catch((e) => {
                errorMsg(e?.response?.data?.errors);
            }).finally(() => {
                setLoading(false);
            });
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData(search, countryCode);
        }

    }, [search, open, countryCode, errorMsg]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    // set new values
    useEffect(() => {
        setValue(inputName, inputValue);
    }, [control, inputName, inputValue, setValue]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.label === value?.label}
                    getOptionLabel={(option) => option?.label ? option.label : ""}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={!!errors?.flow}
                            placeholder="Select a flow"
                            variant="outlined"
                            margin="dense"
                            size="small"
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(e, data) => {
                        onChange(data);
                        updateSelection(data)
                    }}
                    {...props}
                />
            )}
            rules={{required: true}}
            name={inputName}
            control={control}
            defaultValue={null}
        />
    );
}

const mapDispatchToProps = {
    errorMsg: alertActions.error
};

export default connect(null, mapDispatchToProps)(FlowSelect);