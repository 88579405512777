import React from 'react';
import {Controller} from "react-hook-form";
import {currencies} from "../utils/DataList";
import {Autocomplete, TextField} from "@mui/material";

export default function CurrencySelect({control}) {

    let defaultStatus = {code: "EUR", name: "Euro", symbol: "€"};
    if (localStorage.getItem("user-settings") !== null) {
        let userSettings = JSON.parse(localStorage.getItem("user-settings"));
        if (userSettings?.currencyCode !== "") {
            currencies.forEach((item) => {
                if (item?.code === userSettings?.currencyCode) {
                    defaultStatus = item;
                }
            })
        }
    }

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    disableClearable
                    id="currency-select"
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.code + " - " + option.name}
                    options={currencies}
                    renderInput={params => (
                        <span>
                            <TextField
                                {...params}
                                variant="outlined"
                                margin="dense"
                                label="Currency"
                                size="small"
                            />
                        </span>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            name="currency"
            control={control}
            defaultValue={defaultStatus}
            onChange={([, data]) => data}
        />
    );
}
