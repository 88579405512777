import {ddToolbarConstants} from "../constants/ddToolbarConstants";
import moment from 'moment-timezone';
import {GetUserTimezone} from "../../helpers/common";

const initialState = {
    pageSize: 20,
    isDeleted: 0,
    from: moment.tz(GetUserTimezone()).startOf("d").format("YYYY-MM-DD HH:mm:ss"),
    to: moment.tz(GetUserTimezone()).endOf("d").format("YYYY-MM-DD HH:mm:ss"),
    dateLabel: "Today"
};

const ddToolbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case ddToolbarConstants.UPDATE_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.pageSize
            };
        case ddToolbarConstants.UPDATE_IS_DELETED:
            return {
                ...state,
                isDeleted: action.isDeleted
            };
        case ddToolbarConstants.UPDATE_DATE_RANGE:
            return {
                ...state,
                from: action.payload.from,
                to: action.payload.to,
                dateLabel: action.payload.dateLabel
            };
        default:
            return state;
    }
};

export default ddToolbarReducer;