import React, {useRef, useState} from 'react';
import {ArrowDropDown, BorderAll, Edit, Language, Link} from "@mui/icons-material";
import {
    ListItemIcon, Paper,
    Grid,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Popper,
    MenuItem,
    MenuList
} from "@mui/material";

export default function EditButtonGroup(
    {
        clickEditButton,
        selectedId,
        selectedRows,
        onlyCampaignView,
        onlyOfferView,
        onlyLandingView,
        handleOnClickMenuItemChangeTrackingDomain,
        handleOnClickMenuItemChangeWorkspace,
        handleOnClickMenuItemOfferUrlBulkUpdate,
        handleOnClickMenuItemBulkUpdateForLandingUrl,
    }
) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleClickOnMenuItemChangeTrackingDomain = () => {
        setOpen(false);
        handleOnClickMenuItemChangeTrackingDomain();
    };

    const handleClickOnMenuItemChangeWorkspace = () => {
        setOpen(false);
        handleOnClickMenuItemChangeWorkspace();
    };

    const handleClickOnMenuItemOfferUrlBulkUpdate = () => {
        setOpen(false);
        handleOnClickMenuItemOfferUrlBulkUpdate();
    };

    const handleClickOnMenuItemBulkUpdateForLandingUrl = () => {
        setOpen(false);
        handleOnClickMenuItemBulkUpdateForLandingUrl();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup variant="outlined" color="inherit" ref={anchorRef} aria-label="split button">
                    <Button
                        onClick={() => clickEditButton(selectedId)}
                        variant="outlined"
                        color="inherit"
                        size="small"
                        sx={{
                            textTransform: "capitalize",
                            borderColor: "border.button",
                            whiteSpace: "nowrap",
                            padding: "5px 10px"
                        }}
                        startIcon={<Edit/>}
                        disabled={selectedRows.size !== 1}
                    >
                        Edit
                    </Button>
                    <Button
                        sx={{
                            borderColor: "border.button",
                            padding: "0px",
                            "&.MuiButtonGroup-grouped": {
                                minWidth: "30px"
                            }
                        }}
                        variant="outlined"
                        color="inherit"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        disabled={selectedRows.size === 0}
                    >
                        <ArrowDropDown/>
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center top',
                            }}
                        >
                            <Paper elevation={1} sx={{backgroundColor: "background.darkBlue"}}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" dense>
                                        {onlyCampaignView &&
                                            <MenuItem
                                                dense
                                                sx={{fontSize: "13px"}}
                                                key="change-tracking-domain"
                                                onClick={() => handleClickOnMenuItemChangeTrackingDomain()}
                                            >
                                                <ListItemIcon sx={{color: "text.primary"}}>
                                                    <Language color="inherit" fontSize="small"/>
                                                </ListItemIcon>
                                                Bulk update for tracking domain
                                            </MenuItem>
                                        }
                                        <MenuItem
                                            dense
                                            sx={{fontSize: "13px"}}
                                            key={"change-workspace"}
                                            onClick={() => handleClickOnMenuItemChangeWorkspace()}
                                        >
                                            <ListItemIcon sx={{color: "text.primary"}}>
                                                <BorderAll color="inherit" fontSize="small"/>
                                            </ListItemIcon>
                                            Bulk update for workspace
                                        </MenuItem>
                                        {onlyOfferView &&
                                            <MenuItem
                                                dense
                                                sx={{fontSize: "13px"}}
                                                key={"offer-url-bulk-update"}
                                                onClick={() => handleClickOnMenuItemOfferUrlBulkUpdate()}
                                            >
                                                <ListItemIcon sx={{color: "text.primary"}}>
                                                    <Link color="inherit" fontSize="small"/>
                                                </ListItemIcon>
                                                Bulk update for URL
                                            </MenuItem>
                                        }
                                        {onlyLandingView &&
                                            <MenuItem
                                                dense
                                                sx={{fontSize: "13px"}}
                                                key={"bulk-update-for-landing-url"}
                                                onClick={() => handleClickOnMenuItemBulkUpdateForLandingUrl()}
                                            >
                                                <ListItemIcon sx={{color: "text.primary"}}>
                                                    <Link color="inherit" fontSize="small"/>
                                                </ListItemIcon>
                                                Bulk update for URL
                                            </MenuItem>
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}
