import React from "react";
import {useForm} from "react-hook-form";
import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, useMediaQuery, useTheme
} from "@mui/material";
import PropTypes from "prop-types";
import ReactHookFormTextField from "../input/ReactHookFormTextField";

function OfferUrlBulkUpdatePopUp({onClose, open, onSubmit}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {reset, register, handleSubmit, control, setValue, formState: {errors}} = useForm();

    const handleOnSubmit = (data) => {
        onSubmit(data);
        reset();
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="change-workspace-form"
                maxWidth={"sm"}
        >
            <DialogTitle sx={{textAlign: "center"}}>Find & replace string in the URL</DialogTitle>
            <DialogContent>
                <Box p={1} sx={{color: "text.disabledLight"}}>
                    Find and replace the domain or any other string in the offer URL. Select multiple offers and apply
                    the changes simultaneously.
                </Box>
                <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
                    <Box dispaly="flex" flexDirection="column" pt={1}>
                        <Box pt={1} flexGrow={1}>
                            <ReactHookFormTextField
                                register={register}
                                errors={errors}
                                name="find"
                                type="text"
                                variant="outlined"
                                setValue={setValue}
                                control={control}
                                placeholder="Find in URL (e.g. domain, parameter name or value)"
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: /^[a-zA-Z0-9.\-=%&{}\\[\]()]+$/,
                                        message: "The following characters are permitted a-zA-Z0-9.-=%&{}[]()"
                                    }
                                }}
                            />
                            <FormHelperText sx={{color: "error.main", marginBottom: 1, paddingTop: 1}}>
                                {errors?.find?.message}
                            </FormHelperText>
                        </Box>
                        <Box pt={1}>
                            <ReactHookFormTextField
                                register={register}
                                errors={errors}
                                name="replace"
                                type="text"
                                variant="outlined"
                                setValue={setValue}
                                control={control}
                                placeholder="Replace with..."
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: /^[a-zA-Z0-9.\-=%&{}\\[\]()]+$/,
                                        message: "The following characters are permitted a-zA-Z0-9.-=%&{}[]()"
                                    }
                                }}
                            />
                            <FormHelperText sx={{color: "error.main", marginBottom: 1, paddingTop: 1}}>
                                {errors?.replace?.message}
                            </FormHelperText>
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                    Find & Replace
                </Button>
            </DialogActions>
        </Dialog>
    );
}

OfferUrlBulkUpdatePopUp.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default OfferUrlBulkUpdatePopUp;