import React, {useEffect, useState} from "react";
import {TextField, Chip, FormHelperText, Box} from "@mui/material";
import {evaluate} from "mathjs";

export default function FormulaInput(
    {columns, label, name, register, errors, errorMessage, watch, setValue, setError, clearErrors}
) {

    const [cursorPosition, setCursorPosition] = useState(null);
    const [variables, setVariables] = useState({});

    function verifyFormula(formula, variables) {
        // Step 1: Create a dynamic regular expression to match all variable names safely
        const variableNames = Object.keys(variables)
            .map(name => name.replace(/[-_.*+?^${}()|[\]\\]/g, '\\$&')) // Escape special characters
            .map(name => `(?:^|\\s|\\W)${name}(?=\\s|\\W|$)`) // Add word boundaries and non-word character checks
            .join('|');

        // Create a regex pattern to validate characters in the formula (digits, operators, spaces, and variable names)
        const allowedCharacters = new RegExp(`^[0-9+\\-*/().\\s${variableNames}]+$`);

        // Check if the formula contains only allowed characters and does not contain `%`
        const valid = allowedCharacters.test(formula && !formula.includes("%"));

        if (!valid) {
            return {valid: false, error: "Invalid characters in the formula."};
        }

        // Step 2: Replace variable names with their corresponding values
        let formulaWithValues = formula;

        // Replace variables with values
        for (const [key, value] of Object.entries(variables)) {
            // Escape key to be used in regex
            const escapedKey = key.replace(/[-_.*+?^${}()|[\]\\]/g, '\\$&');
            // Use a regular expression to replace only whole words
            const regex = new RegExp(`(?<![\\w])${escapedKey}(?![\\w])`, 'g');
            formulaWithValues = formulaWithValues.replace(regex, value || 0);
        }

        try {
            const result = evaluate(formulaWithValues, variables);
            return {valid: true, result};
        } catch (error) {
            return {valid: false, error: error.message};
        }
    }

    const handleChipClick = (item) => {
        const currentValue = watch(name) || ""; // Get current text field value
        const inputElement = document.getElementsByName(name)[0]; // Access the input element directly

        // Use the cursor position or default to the end of the text if no cursor position is set
        const position = cursorPosition !== null ? cursorPosition : currentValue.length;

        // Insert the chip value at the cursor position
        const updatedText = [
            currentValue.slice(0, position),
            item,
            currentValue.slice(position)
        ].join(''); // Concatenate the strings with proper spaces

        // Update the text field with the new value
        setValue(name, updatedText);

        // Update the cursor position to be after the inserted chip text
        const newCursorPosition = position + item.length;

        // Set the cursor position back to the new position and ensure input focus
        setCursorPosition(newCursorPosition);
        inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
        inputElement.focus(); // Ensure the input field is focused so that the cursor is visible

        // Verify formula and validator
        const verification = verifyFormula(updatedText, variables)
        if (verification.valid) {
            clearErrors("formula");
        } else {
            setError("formula", {type: "manual"});
        }
    };

    const handleTextFieldClick = (e) => {
        setCursorPosition(e?.target?.selectionStart);
        setValue(name, e?.target?.value);
    };

    const handleTextFieldChange = (e) => {
        const verification = verifyFormula(e?.target?.value, variables);
        if (verification.valid) {
            clearErrors("formula");
        } else {
            setError("formula", {type: "manual"});
        }
        setCursorPosition(e?.target?.selectionStart);
        setValue(name, e?.target?.value);
    };

    const MetricChips = () => {
        return Object.entries(variables).map(([key]) => (
            <Chip
                key={key}  // Provide a unique key for each Chip
                label={key}
                variant="filled"
                color="primary"
                size="small"
                sx={{borderRadius: 1, padding: 1, marginRight: 1, fontSize: 12}}
                onMouseDown={(e) => e.preventDefault()}  // Prevent default text selection
                onClick={() => handleChipClick(key)}  // Handle chip click
            />
        ));
    };

    const CalculationChips = () => {
        let placeholders = ["(", ")", "+", "-", "*", "/"];
        return placeholders.map((value, idx) => (
            <Chip
                key={idx}
                label={value}
                variant="filled"
                color="info"
                size="medium"
                sx={{borderRadius: 1, padding: 1, marginRight: 1, fontSize: 16}}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleChipClick(value)}
            />
        ));
    };

    useEffect(() => {
        if (columns) {
            let c = {};
            columns?.forEach(column => {
                if (column?.type === "metric" || column?.type === "customEvent") {
                    c["{" + column?.title + "}"] = 1;
                }
            });
            setVariables(c);
        }
    }, [columns]);

    return (
        <Box>
            <TextField
                error={errors}
                variant="outlined"
                margin="dense"
                size="small"
                {...register(name, {
                    required: true,
                    validate: (value) => {
                        return verifyFormula(value, variables)?.valid;
                    }
                })}
                name={name}
                label={label}
                placeholder="{Cost} / {Conversions (Approved)}"
                type="text"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onClick={handleTextFieldClick}
                onChange={handleTextFieldChange}
            />

            <FormHelperText sx={{color: "error.main", marginBottom: 1, paddingTop: 1}}>
                {errorMessage}
            </FormHelperText>

            <Box display="flex" justifyContent="stretch" flexDirection="row" flexWrap="wrap">
                <CalculationChips/>
            </Box>

            <Box pt={2} display="flex" justifyContent="stretch" flexDirection="row" flexWrap="wrap">
                <MetricChips/>
            </Box>
        </Box>
    );
}