import {referralConstants} from "../constants/referralConstants";

const initialState = {
    balance: 0,
    transactions: [],
    loading: {
        balance: false,
        transactions: false
    }
};

const referralReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case referralConstants.REFERRAL_BALANCE_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    balance: true
                }
            };
        case referralConstants.REFERRAL_BALANCE_FETCH:
            return {
                ...state,
                balance: action?.result?.balance,
                loading: {
                    ...state.loading,
                    balance: false
                }
            };
        case referralConstants.REFERRAL_TRANSACTIONS_LOADING_SPINNER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    transactions: true
                }
            };
        case referralConstants.REFERRAL_TRANSACTIONS_FETCH:
            return {
                ...state,
                transactions: action?.result,
                loading: {
                    ...state.loading,
                    transactions: false
                }
            };
        default:
            return state;
    }
};

export default referralReducer;