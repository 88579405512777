import {apiRequest} from "../helpers/utils";

export const customEventServices = {
    fetch,
    create,
    update,
    archive,
    restore,
    find
};

function fetch({isDeleted = false}) {
    let params = {
        isDeleted
    };
    return apiRequest("get", "v1/custom-event", {}, params);
}

function create(formData) {
    return apiRequest("post", "v1/custom-event", formData);
}

function update(id, formData) {
    return apiRequest("put", "v1/custom-event/" + parseInt(id), formData);
}

function archive({id}) {
    return apiRequest("post", "v1/custom-event/archive", {id});
}

function restore({id}) {
    return apiRequest("post", "v1/custom-event/restore", {id});
}

function find(id) {
    return apiRequest("get", "v1/custom-event/" + parseInt(id));
}