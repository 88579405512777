import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import {Autocomplete, TextField} from "@mui/material";

export default function ReactHookFormDropdown(
    {name, filters, options, control, setValue, defaultValue, rules, label, placeholder, disableClearable}
) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (filters?.length > 0) {
            // eslint-disable-next-line array-callback-return
            filters?.filter((metric) => {
                if (metric.hasOwnProperty(name)) {
                    setValue(name, metric[name])
                }
            });
        }
    }, [setValue, filters, name]);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    getOptionLabel={(option) => option?.label}
                    options={options}
                    disableClearable={disableClearable}
                    renderInput={params => (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            variant="outlined"
                            size="small"
                            margin="dense"
                            label={label}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={rules}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
}
