import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    FormControlLabel,
    Switch,
    Tooltip, useTheme
} from "@mui/material";
import Spinner from "../spinner/Spinner";
import React from "react";
import CountrySelect from "../utils/CountrySelect";
import DefaultPaths from "../campaigns/DefaultPaths";
import RuleBasedPaths from "../campaigns/RuleBasedPaths";
import PathRules from "../campaigns/PathRules";
import PathLandings from "../campaigns/PathLandings";
import PathOffers from "../campaigns/PathOffers";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import {connect} from "react-redux";
import WorkspaceDropdown from "../workspace/WorkspaceDropdown";
import {Info} from "@mui/icons-material";
import ReactHookTagsSelect from "../tags/ReactHookTagsSelect";

function FlowForm(props) {
    const theme = useTheme();

    const {
        title,
        control,
        register,
        handleClose,
        errors,
        handleSubmit,
        onSubmit,
        loading,
        flowData,
        getValues,
        setValue
    } = props;

    const {flow, selectedRuleNumber, selectedPathNumber, selectedRule} = props?.state?.campaignForm;

    const updatePathDirectLinking = (selectedRuleNumber, selectedPathNumber, checked) => {
        props.updatePathDirectLinking(selectedRuleNumber, selectedPathNumber, checked);
    };

    const getTotalWeight = (items) => {
        let totalPathWeight = 0;
        items.forEach(item => {
            totalPathWeight = totalPathWeight + item.weight;
        });
        return totalPathWeight;
    };

    const getCurrentWeight = (weight, items) => {
        return Math.round(parseInt(weight) / getTotalWeight(items) * 100);
    };

    return (
        <>
            <DialogTitle sx={{textAlign: "center"}}>{title}</DialogTitle>
            <DialogContent sx={{[theme.breakpoints.down(1000)]: {padding: "8px 16px"}}}>
                <form
                    onSubmit={handleSubmit((data) => {
                        onSubmit({data, flow})
                    })}
                    autoComplete={"off"}
                    style={{
                        minHeight: `calc(80vh - 60px)`,
                        maxHeight: `calc(80vh - 60px)`,
                    }}
                >

                    <Box display="flex" flexDirection="row" flexWrap="noWrap" sx={{
                        [theme.breakpoints.down(1130)]: {flexWrap: "wrap"}
                    }}>
                        <Box display="flex" flexDirection="column" sx={{
                            width: 520,
                            marginRight: 2,
                            [theme.breakpoints.down(1130)]: {
                                marginRight: 0,
                                width: "100%"
                            }
                        }}>
                            <Box display="flex" mb={1}>
                                <Box flexGrow={1}>
                                    <WorkspaceDropdown
                                        errors={errors}
                                        control={control}
                                        workspaceId={flowData?.workspaceId}
                                        setValue={setValue}
                                    />
                                </Box>
                                <Box p={1}>
                                    <Box sx={{paddingTop: "10px"}}>
                                        <Tooltip disableFocusListener
                                                 sx={{
                                                     marginLeft: "6px",
                                                     fontSize: "18px",
                                                     position: "relative",
                                                     cursor: "pointer"
                                                 }}
                                                 title="Workspace allows to make the required flow accessible only by a specific user or group of users.">
                                            <Info color={"primary"} fontSize={"small"}/>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                            <Box flexWrap="nowrap">
                                <Box mr={5}>
                                    <TextField
                                        error={!!errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        id="name"
                                        {...register("name", {required: true, maxLength: 255, minLength: 1})}
                                        name="name"
                                        type="text"
                                        placeholder="Flow name"
                                        label="Name*"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box mt={1}>
                                    <CountrySelect
                                        control={control}
                                        title="Country select is required to navigate easily through the flows
                                        list. This selection does not affect the traffic distribution."
                                    />
                                </Box>
                                <Box flexGrow={1} pt={1}>
                                    <ReactHookTagsSelect
                                        control={control}
                                        inputName="tags"
                                        inputLabel="Tags"
                                        inputLabelShrink={true}
                                        getValues={getValues}
                                    />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <DefaultPaths getCurrentWeight={getCurrentWeight}/>
                                <RuleBasedPaths getCurrentWeight={getCurrentWeight}/>
                            </Box>
                        </Box>

                        <Box sx={{width: 630, [theme.breakpoints.down(1130)]: {width: "100%", mt: 4}}} display="flex" flexDirection="column">

                            {selectedRule !== null && <Box flexGrow={3}><PathRules/></Box>}

                            {selectedRule === null &&
                                <Box>
                                    <Box sx={{paddingBottom: "20px", paddingLeft: "4px"}}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={flow?.rules[selectedRuleNumber]?.paths[selectedPathNumber]?.directLinking}
                                                    color="primary"
                                                    onChange={(e, checked) => updatePathDirectLinking(selectedRuleNumber, selectedPathNumber, checked)}
                                                    name="directLinking"
                                                />
                                            }
                                            label="Use only offers"
                                        />
                                    </Box>
                                    <Box>
                                        {
                                            !flow?.rules[selectedRuleNumber]?.paths[selectedPathNumber]?.directLinking &&
                                            <PathLandings
                                                control={control}
                                                register={register}
                                                errors={errors}
                                                getCurrentWeight={getCurrentWeight}
                                                setValue={setValue}
                                            />
                                        }
                                        <PathOffers
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            getCurrentWeight={getCurrentWeight}
                                            setValue={setValue}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>

                    <input type="submit" hidden="hidden"/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined">
                    Cancel
                </Button>
                {loading ? <Spinner/> :
                    <Button
                        onClick={handleSubmit((data) => {
                            onSubmit({data, flow})
                        })}
                        color="primary"
                        variant="contained"
                    >
                        Save & Close
                    </Button>
                }
            </DialogActions>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            campaignForm: state.campaignForm,
        }
    }
};

const mapDispatchToProps = {
    updatePathDirectLinking: campaignFormActions.updatePathDirectLinking,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowForm);