import {
    CurrencyFormatter,
    CurrencyFormatterColor, DetailsReportNameFormatter,
    MarkFormatter, NameFormatterWithIntegration,
    PercentageFormatter,
    PercentageFormatterColor, TagFormatter
} from "./ReactDataGridUtils";
import {addCurrency, addPercent, findCurrencySymbol, getCurrencySymbol} from "../utils/Filters";
import {SelectCellFormatter, useRowSelection} from "react-data-grid";
import React from "react";
import {ExpandMore, FiberManualRecord} from '@mui/icons-material';
import TrafficSourceChangeBid from "./TrafficSourceChangeBid";
import TrafficSourceStatusSwitch from "./TrafficSourceStatusSwitch";
import {Box, Button, IconButton} from "@mui/material";
import ChangeDailyBudgetForm from "./ChangeDailyBudgetForm";
import AdjustCPCForm from "./AdjustCPCForm";

function SelectFormatter(props) {
    const [isRowSelected, onRowSelectionChange] = useRowSelection();
    return (
        <SelectCellFormatter
            aria-label="Select"
            isCellSelected={props.isCellSelected}
            value={isRowSelected}
            onClick={(event) => event.stopPropagation()}
            onChange={(checked, isShiftClick) => {
                onRowSelectionChange({row: props.row, checked, isShiftClick});
            }}
        />
    );
}

export const CustomSelectRowColumn = (hideSelectCellFromHeader) => ({
    key: "custom-select-row",
    name: '',
    width: 34,
    minWidth: 34,
    maxWidth: 34,
    resizable: false,
    sortable: false,
    draggable: false,
    frozen: true,
    renderHeaderCell: SelectFormatter,
    renderCell: SelectFormatter,
});

export const MarkColumn = {
    key: 'mark',
    name: '',
    minWidth: 25,
    width: 25,
    maxWidth: 25,
    resizable: false,
    sortable: false,
    draggable: false,
    frozen: true,
    renderCell: MarkFormatter
};

export const StatusColumn = (handleStatusUpdate) => ({
    key: 'status',
    name: 'Off/On',
    minWidth: 68,
    width: 68,
    maxWidth: 68,
    resizable: false,
    sortable: false,
    draggable: false,
    frozen: false,
    renderCell({row}) {
        return <TrafficSourceStatusSwitch row={row} handleStatusUpdate={handleStatusUpdate}/>;
    },
});

export const BidColumn = (handleChangeBid) => ({
    key: 'bid',
    name: 'Bid',
    minWidth: 225,
    width: 225,
    maxWidth: 225,
    resizable: true,
    sortable: false,
    draggable: false,
    frozen: false,
    renderCell({row}) {
        return <TrafficSourceChangeBid row={row} handleChangeBid={handleChangeBid}/>;
    }
});

export const VerifyColumn = (handleFunc) => ({
    key: 'verifyButton',
    name: 'Verify',
    minWidth: 150,
    width: 150,
    maxWidth: 150,
    resizable: false,
    sortable: false,
    draggable: false,
    frozen: false,
    renderCell({row}) {
        return <Button onClick={{row, handleFunc}}>Verify</Button>;
    },
});

function AdjustCPCPercentageColumnFormatter({row, column}) {
    if (row?.adjustCpcPercentage === undefined || row?.adjustCpcPercentage === "") {
        return "";
    }
    return <Box display="flex" flexWrap="no-wrap" flexDirection="row" justifyContent="space-between"
                pl="4px" pr="4px">
        <Box mt="-1px" pr="8px">
            <AdjustCPCForm row={row} handleOutbrainCpcAdjustment={column?.handleOutbrainCpcAdjustment}/>
        </Box>
        <Box>
            {addPercent(row?.adjustCpcPercentage, 0)}
        </Box>
    </Box>;
}

const DailyBudgetColumnFormatter = ({row, column}) => {

    const editCell = (newDailyBudget) => {
        column?.handleChangeDailyBudget(newDailyBudget, row?.name, row?.budgetId);
    };

    if (row?.fbDailyBudget !== undefined && row?.fbDailyBudget !== "") {
        return <Box display="flex" flexWrap="no-wrap" flexDirection="row" justifyContent="space-between"
                    pl="4px" pr="4px">
            <Box mt="-1px" pr="8px">
                <ChangeDailyBudgetForm row={row} handleChangeDailyBudget={editCell}/>
            </Box>
            <Box>
                {addCurrency(findCurrencySymbol(row?.fbAccountCurrency), row?.fbDailyBudget, 2)}
            </Box>
        </Box>;
    }
};

function DeliveryStatusColumnFormatter({row}) {

    if (row?.deliveryStatus === undefined || row?.deliveryStatus === "") {
        return "";
    }

    let color = "inherit"
    if (row?.deliveryStatus === "Active" || row?.deliveryStatus === "In Process") {
        color = "primary"
    } else if (row?.deliveryStatus === "Paused" || row?.deliveryStatus === "Deleted" || row?.deliveryStatus === "Archived") {
        color = "disabled"
    } else if (row?.deliveryStatus === "Error" || row?.deliveryStatus === "Rejected") {
        color = "error"
    }
    return <>
        <FiberManualRecord
            fontSize={"small"} color={color}
            style={{position: "relative", top: "2px", right: "3px", fontSize: "13px"}}
        />
        {row?.deliveryStatus}
    </>;
}

function FbMetricImpressionsColumnFormatter({row, column}) {
    return <Box sx={{textAlign: "right", pr: 1}}>{row?.fbImpressions}</Box>;
}

function FbMetricCpmColumnFormatter({row}) {
    return CurrencyFormatter(row?.fbCpm, findCurrencySymbol(row?.fbAccountCurrency), 4);
}

function FbMetricLinkCpcColumnFormatter({row}) {
    return CurrencyFormatter(row?.fbLinkCpc, findCurrencySymbol(row?.fbAccountCurrency), 4);
}

function FbMetricEpcColumnFormatter({row}) {
    return CurrencyFormatter(row?.fbEpc, findCurrencySymbol(row?.fbAccountCurrency), 4);
}

function FbMetricLinkCtrColumnFormatter({row}) {
    return PercentageFormatter(row?.fbCtr, 2);
}

function FbMetricLinkClicksColumnFormatter({row}) {
    return <Box sx={{textAlign: "right", pr: 1}}>{row?.fbLinkClicks}</Box>;
}

export const ExpandedColumn = {
    key: 'expanded',
    name: '',
    minWidth: 35,
    width: 35,
    maxWidth: 35,
    resizable: false,
    sortable: false,
    frozen: true,
    formatter({row, onRowChange}) {
        return (
            <div style={{margin: 0, padding: 0, position: "absolute", left: 4, top: -1}}>
                <IconButton aria-label="details" color="default" size="small" title="Expand"
                            style={row.expanded ? {
                                transition: "all 200ms ease 0s",
                                transform: "rotate(180deg)"
                            } : {
                                transition: "all 200ms ease 0s", transform: "none"
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRowChange({...row, expanded: !row.expanded})
                            }}
                >
                    <ExpandMore/>
                </IconButton>
            </div>
        );
    }
};

function tagsFormatter({row, column}) {
    return TagFormatter(row[column?.key])
}

function currencyFormatter({row, column}) {
    let currencySymbol = getCurrencySymbol();
    if (column?.key === "profit") {
        return CurrencyFormatterColor(row[column?.key], currencySymbol, column?.decimals === 0 ? 4 : column?.decimals);
    }
    return CurrencyFormatter(row[column?.key], currencySymbol, column?.decimals === 0 ? 4 : column?.decimals);
}

function percentageFormatter({row, column}) {
    if (column?.key === "roi") {
        return PercentageFormatterColor(row[column?.key], column?.decimals === 0 ? 2 : column?.decimals);
    }
    return PercentageFormatter(row[column?.key], column?.decimals === 0 ? 2 : column?.decimals)
}

function integrationNameFormatter({row, column}) {
    return NameFormatterWithIntegration({row});
}

function detailsReportNameFormatter({row, column}) {
    return DetailsReportNameFormatter({row});
}

function numberFormatter({row, column}) {
    return <Box sx={{textAlign: "right", pr: 1}}>{row[column?.key]}</Box>;
}

function summaryNumberFormatter({row, column}) {
    return <Box sx={{textAlign: "right", pr: 1}}>{row?.total[column?.key]}</Box>;
}

function summaryPercentageFormatter({row, column}) {
    if (column?.key === "roi") {
        return PercentageFormatterColor(row?.total[column?.key], 2);
    }
    return PercentageFormatter(row?.total[column?.key], 2);
}

function summaryCurrencyFormatter({row, column}) {
    let currencySymbol = getCurrencySymbol();
    if (column?.key === "profit") {
        return CurrencyFormatterColor(row?.total[column?.key], currencySymbol, 4);
    }
    return CurrencyFormatter(row?.total[column?.key], currencySymbol, 4);
}

function summaryTotalCountFormatter({row}) {
    return <Box>Total records: {row?.records?.length}</Box>;
}

export function DynamicGridColumnCreator(
    {
        columns, handleChangeDailyBudget, addBidColumn, handleChangeExternalBid,
        addDeliveryStatusColumn, addFbMetricCpmColumn, addFbMetricLinkCpcColumn, addFbMetricLinkCtrColumn,
        addFbMetricLinkClicksColumn, addFbMetricEpcColumn, addFbImpressionsColumn, addFbDailyBudgetColumn,
        handleOutbrainCpcAdjustment, addOutbrainCpcAdjustmentColumn
    }
) {

    let innerWidth = window.innerWidth;

    // Sort columns by sequence number.
    columns?.sort((a, b) => (a?.sequence - b?.sequence));

    let modColumns = [];
    for (const column of columns) {

        // Skip Outbrain dynamic columns.
        if (column?.columnKey === "outbrain_cpc_adjustment" && !addOutbrainCpcAdjustmentColumn) {
            continue;
        }

        // Skip Facebook dynamic columns.
        if (column?.columnKey === "delivery_status" && !addDeliveryStatusColumn) {
            continue;
        }
        if (column?.columnKey === "fb_daily_budget" && !addFbDailyBudgetColumn) {
            continue;
        }
        if (column?.columnKey === "fb_cpm" && !addFbMetricCpmColumn) {
            continue;
        }
        if (column?.columnKey === "fb_impressions" && !addFbImpressionsColumn) {
            continue;
        }
        if (column?.columnKey === "fb_link_cpc" && !addFbMetricLinkCpcColumn) {
            continue;
        }
        if (column?.columnKey === "fb_link_ctr" && !addFbMetricLinkCtrColumn) {
            continue;
        }
        if (column?.columnKey === "fb_link_clicks" && !addFbMetricLinkClicksColumn) {
            continue;
        }
        if (column?.columnKey === "fb_epc" && !addFbMetricEpcColumn) {
            continue;
        }
        if (column?.columnKey === "external_bid" && !addBidColumn) {
            continue;
        }

        if (!column?.hidden) {
            let obj = {
                key: column?.columnKey,
                name: column?.title,
                width: column?.width,
                hidden: column?.hidden,
                sortDescendingFirst: column?.sortDescendingFirst,
                frozen: column?.frozen,
                filter: column?.filter,
                decimals: column?.decimals,
            };

            if (innerWidth < 768 && column?.columnKey === "name") {
                obj.frozen = false;
            }

            if (column?.type === "detailsReport" && column?.columnKey === "hash") {
                obj.sortable = false;
            }

            if (column?.format === "currency") {
                obj["renderCell"] = currencyFormatter;
                obj["renderSummaryCell"] = summaryCurrencyFormatter;
            } else if (column?.format === "percent") {
                obj["renderCell"] = percentageFormatter;
                obj["renderSummaryCell"] = summaryPercentageFormatter;
            } else if (column?.format === "number") {
                obj["renderCell"] = numberFormatter;
                obj["renderSummaryCell"] = summaryNumberFormatter;
            } else if (column?.columnKey === "name") {
                obj["renderSummaryCell"] = summaryTotalCountFormatter;
                // Add integration icon
                if (column?.type === "campaign" || column?.type === "trafficSource") {
                    obj["renderCell"] = integrationNameFormatter;
                } else if (column?.type === "detailsReport") {
                    obj["renderCell"] = detailsReportNameFormatter;
                }
            } else if (column?.columnKey === "tags") {
                obj["renderCell"] = tagsFormatter;
            }

            // Outbrain dynamic column in details report
            if (column?.columnKey === "outbrain_cpc_adjustment" && addOutbrainCpcAdjustmentColumn) {
                obj.sortable = false;
                obj["renderCell"] = AdjustCPCPercentageColumnFormatter;
                obj["handleOutbrainCpcAdjustment"] = handleOutbrainCpcAdjustment;
            }

            // Add bid column
            if (column?.columnKey === "external_bid" && addBidColumn) {
                obj.sortable = false;
                obj.maxWidth = 225;
                obj.minWidth = 225;
                obj.resizable = false;
                obj["renderCell"] = TrafficSourceChangeBid;
                obj["handleChangeExternalBid"] = handleChangeExternalBid;
            }

            // Facebook/TikTok/Taboola/Outbrain dynamic columns in details report
            if (column?.columnKey === "delivery_status" && addDeliveryStatusColumn) {
                obj.sortable = false;
                obj["renderCell"] = DeliveryStatusColumnFormatter;
            }
            if (column?.columnKey === "fb_daily_budget" && addFbDailyBudgetColumn) {
                obj.sortable = false;
                obj["renderCell"] = DailyBudgetColumnFormatter;
                obj["handleChangeDailyBudget"] = handleChangeDailyBudget;
            }
            if (column?.columnKey === "fb_impressions" && addFbImpressionsColumn) {
                obj.sortable = false;
                obj["renderCell"] = FbMetricImpressionsColumnFormatter;
            }
            if (column?.columnKey === "fb_cpm" && addFbMetricCpmColumn) {
                obj.sortable = false;
                obj["renderCell"] = FbMetricCpmColumnFormatter;
            }
            if (column?.columnKey === "fb_link_cpc" && addFbMetricLinkCpcColumn) {
                obj.sortable = false;
                obj["renderCell"] = FbMetricLinkCpcColumnFormatter;
            }
            if (column?.columnKey === "fb_link_ctr" && addFbMetricLinkCtrColumn) {
                obj.sortable = false;
                obj["renderCell"] = FbMetricLinkCtrColumnFormatter;
            }
            if (column?.columnKey === "fb_link_clicks" && addFbMetricLinkClicksColumn) {
                obj.sortable = false;
                obj["renderCell"] = FbMetricLinkClicksColumnFormatter;
            }
            if (column?.columnKey === "fb_epc" && addFbMetricEpcColumn) {
                obj.sortable = false;
                obj["renderCell"] = FbMetricEpcColumnFormatter;
            }

            modColumns.push(obj);
        }
    }
    return modColumns;
}
