import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import {cloakingDomainServices} from "../../service/cloakingDomainService";
import {Autocomplete, Box, TextField} from "@mui/material";
import {Info} from "@mui/icons-material";
import {CustomTooltip} from "../landings/LandingForm";

export default function CloakingDomainSelect({control, errors}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(): void {
            try {
                setLoading(true);
                const response = await cloakingDomainServices.read(search);
                setOptions(
                    response.cloakingDomains.map(item => {
                        return {
                            value: item.id,
                            label: item.domain
                        }
                    })
                );
            } catch (e) {
                console.log({e});
            } finally {
                setLoading(false);
            }
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData();
        }

    }, [search, open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <Autocomplete
                    sx={{paddingTop: "4px"}}
                    id="cloaking-domain-select"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => {
                        if (value?.value === 0) {
                            return true;
                        }
                        return option.value === value.value
                    }}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    loading={loading}
                    renderInput={params => (
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="row" flexWrap="noWrap">
                                <Box sx={{fontSize: "14px", fontWeight: 600, color: "text.primary"}}>
                                    Cloaking domain
                                </Box>
                                <Box>
                                    <CustomTooltip
                                        disableFocusListener
                                        title="This domain will be applied to hide the referrer info."
                                        sx={{top: 2}}
                                    >
                                        <Info color={"primary"} fontSize={"small"}/>
                                    </CustomTooltip>
                                </Box>
                            </Box>
                            <Box>
                                <TextField
                                    {...params}
                                    error={!!errors.cloakingDomain}
                                    placeholder="Select a cloaking domain"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={search}
                                    onChange={e => handleSearch(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            rules={{required: true}}
            name="cloakingDomain"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}
