import React, {useCallback, useState} from 'react';
import {connect} from "react-redux";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import ChooseTrafficSourceIntegration from "./ChooseTrafficSourceIntegration";
import {integrationActions} from "../../../store/actions/integrationAction";
import FacebookLoginRedirect from "./FacebookLoginRedirect";
import PushgroundIntegration from "./PushgroundIntegration";
import PropellerAdsIntegration from "./PropellerAdsIntegration";
import ExoClickIntegration from "./ExoClickIntegration";
import MondiadIntegration from "./MondiadIntegration";
import GoogleLoginRedirect from "./GoogleLoginRedirect";
import MicrosoftLoginRedirect from "./MicrosoftLoginRedirect";
import TaboolaIntegration from "./TaboolaIntegration";
import MgidIntegration from "./MgidIntegration";
import OutbrainIntegration from "./OutbrainIntegration";
import TikTokLoginRedirect from "./TikTokLoginRedirect";

function AddIntegration(props) {

    const [open, setOpen] = useState(true);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        props.reset();
        setOpen(false);
        props.history.push({pathname: "/settings/integrations", state: {from: props.location}});
    }, [props]);

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"md"}
                aria-labelledby="form-add-integration"
        >
            {props.integration.formStep === "integrations" &&
                <ChooseTrafficSourceIntegration handleClose={handleClose}/>
            }

            {props?.integration?.selectedIntegration?.name === "Google Ads" &&
                <GoogleLoginRedirect history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "Microsoft Ads" &&
                <MicrosoftLoginRedirect history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "Facebook" &&
                <FacebookLoginRedirect history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "TikTok" &&
                <TikTokLoginRedirect history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "Pushground" &&
                <PushgroundIntegration history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "PropellerAds" &&
                <PropellerAdsIntegration history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "ExoClick" &&
                <ExoClickIntegration history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "Mondiad" &&
                <MondiadIntegration history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "Taboola" &&
                <TaboolaIntegration history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "MGID" &&
                <MgidIntegration history={props?.history}/>
            }

            {props?.integration?.selectedIntegration?.name === "Outbrain" &&
                <OutbrainIntegration history={props?.history}/>
            }
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration
    };
};

const mapDispatchToProps = {
    reset: integrationActions.reset
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIntegration);
