import React, {useEffect} from 'react';
import {Controller} from "react-hook-form";
import {MenuItem, TextField} from "@mui/material";

export default function ReactHookFormSelect(
    {name, filters, options, control, setValue, defaultValue, rules, label, placeholder, style}
) {
    const handleChange = (event) => {
        setValue(name, event.target.value);
    };
    useEffect(() => {
        if (filters?.length > 0) {
            // eslint-disable-next-line array-callback-return
            filters?.filter((metric) => {
                if (metric.hasOwnProperty(name)) {
                    setValue(name, metric[name])
                }
            });
        }
    }, [setValue, filters, name]);
    return (
        <Controller
            render={({field: {onChange, ...props}}) => (
                <TextField
                    {...props}
                    select
                    onChange={handleChange}
                    variant="outlined"
                    color="primary"
                    size="small"
                    margin="dense"
                    id={name}
                    fullWidth
                    label={label}
                    placeholder={placeholder}
                    InputLabelProps={{
                        shrink: true
                    }}
                    sx={style}
                >
                    {options.map((item) => {
                        return (
                            <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                        );
                    })}
                </TextField>
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
}
