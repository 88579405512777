import React, {Component} from 'react';
import {BrowserRouter} from "react-router-dom";
import Routes from '../routes/Routes';
import {ThemeProvider} from '@mui/material/styles';
import theme from "../../theme";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <div className="App">
                        <Routes/>
                    </div>
                </ThemeProvider>
            </BrowserRouter>
        );
    }
}

export default App;
