import {apiRequest} from "../helpers/utils";

export const trackingDomainServices = {
    read,
    find,
    remove,
    addAndVerify
};

function read(name) {
    let params = {
        name,
        isDeleted: false,
        pageSize: 250
    };
    return apiRequest("get", "v1/tracking-domain", {}, params);
}

function find(id) {
    return apiRequest("get", "v1/tracking-domain/" + parseInt(id));
}

function addAndVerify(domain) {
    return apiRequest("post", "v1/tracking-domain", {domain});
}

function remove(id) {
    return apiRequest("delete", "v1/tracking-domain/" + parseInt(id));
}
