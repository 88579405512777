import {dashboardConstants} from "../constants/dashboardConstants";
import {ddToolbarConstants} from "../constants/ddToolbarConstants";

export const dashboardActions = {
    updateDateRange
};

function updateDateRange(from, to, dateLabel) {
    return dispatch => {
        dispatch({type: dashboardConstants.DASHBOARD_UPDATE_DATE_RANGE, payload: {from, to, dateLabel}});
        dispatch({type: ddToolbarConstants.UPDATE_DATE_RANGE, payload: {from, to, dateLabel}});
    };
}
