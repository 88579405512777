import {alertActions} from "./alertActions";
import {websiteDomainsServices} from "../../service/websiteDomainsService";
import {websiteDomainsConstants} from "../constants/websiteDomainsConstants";

export const websiteDomainsActions = {
    read,
    readMoreRows,
    updateDomain,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    verifyDomain,
    getDomainVerifyRecords,
    deleteDomainAndCert,
    addDomain,
    updateActiveStep,
    stopLoading
};

function read(domain, page, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDomainsServices.read(domain, page, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_LOADING, result});
            }).catch((error) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function readMoreRows(domain, page, orderBy, sortBy, workspace) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDomainsServices.read(domain, page, orderBy, sortBy, workspace)
            .then((result) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_INFINITY_SCROLL, result});
            }).catch((error) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function addDomain(
    {
        domain = "",
        serverIntegration = {value: 0, label: ""},
        domainIntegration = {value: 0, label: ""},
        workspace = {value: 0, label: ""}
    }
) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDomainsServices.addDomain({
            domain,
            serverIntegrationId: serverIntegration?.value,
            domainIntegrationId: domainIntegration?.value,
            workspaceId: workspace?.value
        })
            .then((result) => {
                dispatch({
                    type: websiteDomainsConstants.WEBSITE_DOMAINS_ADD_DOMAIN,
                    payload: {result, workspace, serverIntegration, domainIntegration}
                });
            }).catch((error) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function verifyDomain(id) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDomainsServices.verifyDomain(id)
            .then((result) => {
                if (result?.status === "ISSUED") {
                    dispatch(alertActions.success(result?.domain + " is verified and SSL cert issued."));
                } else {
                    dispatch(alertActions.error(result?.domain + " isn't verified yet. Typically, DNS changes take 5-15 minutes to propagate."));
                }
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_VERIFIED, result});
            }).catch((error) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function getDomainVerifyRecords(id) {
    return (dispatch) => {

        if (id === 0) {
            dispatch(alertActions.error("Invalid domain ID"));
            return false;
        }

        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDomainsServices.getDomainVerifyRecords(id)
            .then((result) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_CNAME_RECORDS, result});
            }).catch((error) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function deleteDomainAndCert(id) {
    return (dispatch) => {

        if (id === 0) {
            dispatch(alertActions.error("Invalid domain ID"));
            return false;
        }

        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_SET_IS_LOADING});
        dispatch(alertActions.error(""));
        websiteDomainsServices.deleteDomainAndCert(id)
            .then((result) => {
                dispatch(alertActions.success(result?.domain + " has been deleted successfully."));
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_DELETE_DOMAIN_AND_CERT, result});
            }).catch((error) => {
                dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_LOADING_ERROR, error});
                dispatch(alertActions.error(error?.response?.data?.error));
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_SORT_BY, sortBy});
    };
}

function updateDomain(domain) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_DOMAIN, domain});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function updateActiveStep(step = 1) {
    return (dispatch) => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_UPDATE_ACTIVE_STEP, step});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_RERENDER});
    };
}

function stopLoading() {
    return dispatch => {
        dispatch({type: websiteDomainsConstants.WEBSITE_DOMAINS_STOP_LOADING});
    };
}
