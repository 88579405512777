export const groupBy1Constants = {
    GROUP_BY_1_LOADING_SPINNER: 'GROUP_BY_1_LOADING_SPINNER',
    GROUP_BY_1_STOP_LOADING_SPINNER: 'GROUP_BY_1_STOP_LOADING_SPINNER',
    GROUP_BY_1_INFINITY_SCROLL: 'GROUP_BY_1_INFINITY_SCROLL',
    GROUP_BY_1_GET_DATA: 'GROUP_BY_1_GET_DATA',
    GROUP_BY_1_ERROR: 'GROUP_BY_1_ERROR',
    GROUP_BY_1_UPDATE_ORDER_BY: 'GROUP_BY_1_UPDATE_ORDER_BY',
    GROUP_BY_1_UPDATE_SORT_BY: 'GROUP_BY_1_UPDATE_SORT_BY',
    GROUP_BY_1_UPDATE_SORT_COLUMNS: 'GROUP_BY_1_UPDATE_SORT_COLUMNS',
    GROUP_BY_1_RERENDER: 'GROUP_BY_1_RERENDER',
    GROUP_BY_1_SET_SELECTED_ROW: 'GROUP_BY_1_SET_SELECTED_ROW',
    GROUP_BY_1_UPDATE_GROUP_BY: 'GROUP_BY_1_UPDATE_GROUP_BY',
    GROUP_BY_1_SET_INTEGRATION_DATA: 'GROUP_BY_1_SET_INTEGRATION_DATA',
    GROUP_BY_1_SET_TRAFFIC_SOURCE_DATA: 'GROUP_BY_1_SET_TRAFFIC_SOURCE_DATA',
    GROUP_BY_1_SET_SELECTED_NAME: 'GROUP_BY_1_SET_SELECTED_NAME',
    GROUP_BY_1_RESET: 'GROUP_BY_1_RESET',
};
