import {Button, DialogActions, DialogContent, DialogTitle, TextField, Alert, Box, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {sharedTrafficSourceService} from "../../../service/sharedTrafficSourceService";
import Spinner from "../../spinner/Spinner";
import {integrationActions} from "../../../store/actions/integrationAction";
import {connect} from "react-redux";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    errorMsg: {
        marginBottom: 10
    },
    template: {
        width: 180,
        height: 56,
        margin: 6,
        backgroundColor: theme.palette.common.white,
        textTransform: "none",
        [theme.breakpoints.down(500)]: {
            width: 140,
            height: 56,
        }
    },
    logo: {
        maxWidth: "85%",
        maxHeight: "84%"
    },
    contentBox: {
        [theme.breakpoints.up(959)]: {
            minHeight: "calc(100vh - 208px)"
        }
    }
});

function ChooseTrafficSourceIntegration(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const [loading, setLoading] = useState(true);
    const [requestError, setRequestError] = useState("");
    const [templates, setTemplates] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const integrations = ["Google Ads", "TikTok", "Facebook", "Microsoft Ads", "Taboola", "MGID", "Outbrain", "PropellerAds", "Pushground", "ExoClick", "Mondiad"];

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const filteredTemps = !searchQuery
        ? templates
        : templates?.filter(item =>
            item?.name?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );

    useEffect(() => {
        async function fetchData() {
            setRequestError("");
            setLoading(true);
            await sharedTrafficSourceService.read().then((response) => {
                if (response !== null && response !== undefined) {
                    setTemplates(response?.sharedTrafficSources);
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    setRequestError(error.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
        }

        if (templates === null) {
            fetchData();
        }
    }, [templates]);

    const selectIntegration = (item) => {
        props.updateSelectedIntegration(item);
    };

    const handleCloseButton = () => {
        props.handleClose();
    };

    return (
        <>
            <DialogTitle style={{textAlign: "center"}}>Add integration</DialogTitle>
            <DialogContent className={classes?.contentBox}>
                {loading && <Spinner overComponentBox={true}/>}
                {requestError.length > 1 &&
                    <Alert className={classes?.errorMsg} variant="outlined" severity="error">
                        {requestError}
                    </Alert>
                }
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                    <TextField
                        variant="outlined"
                        margin="dense"
                        name="traffic-source"
                        placeholder="Search..."
                        type="text"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={searchQuery}
                        onChange={handleChange}
                    />

                    {filteredTemps?.map((item, index) => {
                        if (integrations.includes(item?.name)) {
                            return (
                                <Box key={index} flexGrow={0}>
                                    <Button className={classes?.template} variant="contained" color="inherit"
                                            onClick={() => selectIntegration(item)}
                                    >
                                        {item?.logoUrl !== ""
                                            ? <img className={classes?.logo} src={item?.logoUrl} alt={item?.name}/>
                                            : item?.name
                                        }
                                    </Button>
                                </Box>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {filteredTemps?.length < 1 && <p style={{textAlign: "center"}}>No records found.</p>}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseButton} color="inherit" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
}

const mapDispatchToProps = {
    updateSelectedIntegration: integrationActions.updateSelectedIntegration
};

export default connect(null, mapDispatchToProps)(ChooseTrafficSourceIntegration);