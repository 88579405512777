import React, {useState} from 'react';
import Topbar from "../layout/Topbar";
import Sidebar from "../layout/Sidebar";
import CampaignsGrid from "./CampaignsGrid";
import {Box, Chip, CssBaseline, Paper, Tab, Tabs, Tooltip} from "@mui/material";
import {compose} from "redux";
import {connect} from "react-redux";
import GroupBy2Grid from "./GroupBy2Grid";
import GroupBy3Grid from "./GroupBy3Grid";
import {groupBy1Action} from "../../store/actions/groupBy1Action";
import GroupBy1Grid from "./GroupBy1Grid";
import {CustomMainRootBox} from "../dashboard/Dashboard";
import {CustomTableContainer} from "../logs/LogClicksTable";
import {styled} from "@mui/system";

export const CustomCampaignDataGridBox = styled(Box)(({theme}) => ({
    height: "calc(var(--app-height) - 158px)",
    width: "100%",
    [theme.breakpoints.down(1480)]: {
        height: "calc(var(--app-height) - 170px)",
    }
}));

export const CustomTab = styled(Tab)(({theme}) => ({
    width: 300,
    maxWidth: 300,
    marginTop: "1px",
    minHeight: 53,
    maxHeight: 53,
    textTransform: "none",
    borderRight: "1px solid " + theme.palette.background.tableBorder,
    "&.Mui-disabled": {
        backgroundColor: theme.palette.background.darkBlue,
        color: theme.palette.text.disabledDark,
        cursor: "not-allowed !important",
    },
    "&:hover": {
        backgroundColor: theme.palette.background.navbarHover
    },
    "&.MuiTab-textColorPrimary.Mui-selected": {
        color: theme.palette.text.primary,
        boxShadow: "inset 0px 7px 4px -6px " + theme.palette.primary.main
    }
}));

const CustomChip = styled(Chip)(({theme}) => ({
    "&.MuiChip-root": {
        fontSize: 12,
        "&.MuiChip-colorPrimary": {
            margin: "2px 2px",
            height: 19,
            maxWidth: 280,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.tableSelectedRow
        }
    }
}));

function CampaignsView(props) {

    const {selectedRows} = props.campaignsState;
    const [tabValue, setTabValue] = useState("campaigns");

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCampaignRowDoubleClick = (data, column) => {
        // block double-click on these columns
        if (column?.key === "select-row") {
            return false;
        }
        setTabValue("groupBy1");
    };

    const handleGroupBy1RowDoubleClick = (data, column) => {
        // block double-click on these columns
        if (column?.key === "status" || column?.key === "bid" || column?.key === "select-row") {
            return false;
        }
        setTabValue("groupBy2")
    };

    const handleGroupBy2RowDoubleClick = (data, column) => {
        // block double-click on these columns
        if (column?.key === "status" || column?.key === "bid" || column?.key === "select-row") {
            return false;
        }
        setTabValue("groupBy3")
    };

    const getChipLabel = (v) => {
        if (v === "groupBy1") {
            for (let item of props.groupBy1State.selectedRows) {
                if (Number.isInteger(item)) {
                    let name = "";
                    props.groupBy1State.data.records.forEach(data => {
                        if (data.id === item) {
                            name = data.name;
                        }
                    });
                    return name;
                } else {
                    return item;
                }
            }
        }
        if (v === "groupBy2") {
            for (let item of props.groupBy2State.selectedRows) {
                if (Number.isInteger(item)) {
                    let name = "";
                    props.groupBy2State.data.records.forEach(data => {
                        if (data.id === item) {
                            name = data.name;
                        }
                    });
                    return name;
                } else {
                    return item;
                }
            }
        }
        if (v === "campaign") {
            for (let item of selectedRows) {
                if (Number.isInteger(item)) {
                    let name = "";
                    props?.campaignsState?.data?.records?.forEach(data => {
                        if (data?.id === item) {
                            name = data?.name;
                        }
                    });
                    return name;
                } else {
                    return item;
                }
            }
        }
    };

    return (
        <CustomMainRootBox>
            <CssBaseline/>
            <Sidebar {...props}/>
            <Topbar/>
            <CustomTableContainer>
                <Paper
                    elevation={0}
                    sx={{
                        backgroundColor: "background.darkBlue",
                        borderRadius: 0,
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        borderBottomColor: "background.tableBorder"
                    }}
                >
                    <Tabs
                        variant="scrollable"
                        value={tabValue}
                        onChange={handleChange}
                        textColor="primary"
                        sx={{
                            '& .MuiTabs-indicator': {display: 'none'},
                            overflow: "auto !important",
                        }}
                    >
                        <CustomTab
                            value="campaigns"
                            onClick={() => {
                                props.resetIntegration();
                                props.resetTrafficSource();
                            }}
                            label={
                                <Tooltip
                                    title="Campaigns">
                                    <Box sx={{fontSize: 13, lineHeight: "1.6em"}}>
                                        Campaigns<br/>
                                        {
                                            selectedRows?.size === 1 ?
                                                <CustomChip
                                                    size="small"
                                                    label={getChipLabel("campaign")}
                                                    color="primary"
                                                />
                                                :
                                                <CustomChip
                                                    size="small"
                                                    label={"(" + selectedRows?.size + ") Selected"}
                                                    color="primary"
                                                />
                                        }
                                    </Box>
                                </Tooltip>
                            }
                        />
                        <CustomTab
                            sx={{pointerEvents: "auto"}}
                            value="groupBy1"
                            disabled={selectedRows?.size === 0}
                            label={
                                <Tooltip
                                    title={selectedRows?.size === 0 ? "Select a row/rows or double-click on a grid row" : "Group by " + props.groupBy1State.groupBy.label}>
                                    <Box sx={{fontSize: 13, lineHeight: "1.6em"}}>
                                        Group by {props.groupBy1State.groupBy.label}<br/>
                                        {
                                            props.groupBy1State.selectedRows?.size > 0 &&
                                            <CustomChip
                                                size="small"
                                                label={getChipLabel("groupBy1")}
                                                color="primary"
                                            />
                                        }
                                    </Box>
                                </Tooltip>
                            }
                        />
                        <CustomTab
                            sx={{pointerEvents: "auto"}}
                            value="groupBy2"
                            disabled={selectedRows?.size === 0 || props.groupBy1State.selectedRows?.size !== 1}
                            label={
                                <Tooltip
                                    title={props.groupBy1State.selectedRows?.size !== 1 ? "Select a row or double-click on a grid row" : "Group by " + props.groupBy2State.groupBy.label}>
                                    <Box sx={{fontSize: 13, lineHeight: "1.6em"}}>
                                        Group by {props.groupBy2State.groupBy.label}<br/>
                                        {
                                            props.groupBy2State.selectedRows?.size === 1 &&
                                            <CustomChip
                                                size="small"
                                                label={getChipLabel("groupBy2")}
                                                color="primary"
                                            />
                                        }
                                    </Box>
                                </Tooltip>
                            }
                        />
                        <CustomTab
                            sx={{pointerEvents: "auto"}}
                            value="groupBy3"
                            disabled={selectedRows?.size === 0 || props.groupBy2State.selectedRows?.size !== 1 || props.groupBy1State.selectedRows?.size !== 1}
                            label={
                                <Tooltip
                                    title={props.groupBy2State.selectedRows?.size !== 1 ? "Select a row or double-click on a grid row" : "Group by " + props.groupBy3State.groupBy.label}>
                                    <Box sx={{fontSize: 13, lineHeight: "1.6em"}}>
                                        Group by {props.groupBy3State.groupBy.label}
                                    </Box>
                                </Tooltip>
                            }
                        />
                    </Tabs>
                </Paper>
                {tabValue === "campaigns" &&
                    <CampaignsGrid
                        {...props}
                        handleDoubleClick={handleCampaignRowDoubleClick}
                        groupBy="campaign_id"
                        columnGroup="campaigns"
                        report="campaign"
                    />
                }
                {tabValue === "groupBy1" &&
                    <GroupBy1Grid
                        {...props}
                        handleDoubleClick={handleGroupBy1RowDoubleClick}
                        columnGroup="detailsReport"
                        report="detailsReport"
                    />
                }
                {tabValue === "groupBy2" &&
                    <GroupBy2Grid
                        {...props}
                        handleDoubleClick={handleGroupBy2RowDoubleClick}
                        columnGroup="detailsReport"
                        report="detailsReport"
                    />
                }
                {tabValue === "groupBy3" &&
                    <GroupBy3Grid
                        {...props}
                        columnGroup="detailsReport"
                        report="detailsReport"
                    />
                }
            </CustomTableContainer>
        </CustomMainRootBox>
    );
}

const mapStateToProps = (state) => {
    return {
        campaignsState: state.campaign,
        groupBy1State: state.groupBy1,
        groupBy2State: state.groupBy2,
        groupBy3State: state.groupBy3,
    }
};

const actionCreators = {
    resetTrafficSource: groupBy1Action.resetTrafficSource,
    resetIntegration: groupBy1Action.resetIntegration,
};

export default compose(connect(mapStateToProps, actionCreators))(CampaignsView);