import {apiRequest} from "../helpers/utils";
import {accessApiUrl} from "../helpers/config";

export const apiAccessServices = {
    fetch,
    create,
    remove
};

function fetch() {
    return apiRequest("get", "v1/access-token", {}, {}, true, false, accessApiUrl);
}

function create(formData) {
    return apiRequest("post", "v1/access-token", formData, {}, true, false, accessApiUrl);
}

function remove(id) {
    return apiRequest("delete", "v1/access-token/" + parseInt(id), {}, {}, true, false, accessApiUrl);
}