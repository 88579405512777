export const logPostbacksConstants = {
    LOG_POSTBACKS_LOADING: 'LOG_POSTBACKS_LOADING',
    LOG_POSTBACKS_LOADING_ERROR: 'LOG_POSTBACKS_LOADING_ERROR',
    LOG_POSTBACKS_SET_IS_LOADING: 'LOG_POSTBACKS_SET_IS_LOADING',
    LOG_POSTBACKS_UPDATE_ORDER_BY: 'LOG_POSTBACKS_UPDATE_ORDER_BY',
    LOG_POSTBACKS_UPDATE_SORT_BY: 'LOG_POSTBACKS_UPDATE_SORT_BY',
    LOG_POSTBACKS_UPDATE_CLICK_ID: 'LOG_POSTBACKS_UPDATE_CLICK_ID',
    LOG_POSTBACKS_UPDATE_SORT_COLUMNS: 'LOG_POSTBACKS_UPDATE_SORT_COLUMNS',
    LOG_POSTBACKS_INFINITY_SCROLL: 'LOG_POSTBACKS_INFINITY_SCROLL',
    LOG_POSTBACKS_RERENDER: 'LOG_POSTBACKS_RERENDER'
};
