import Topbar from "../../layout/Topbar";
import SettingsSidebar from "../SettingsSidebar";
import {Box, CssBaseline} from "@mui/material";
import React from "react";
import BotRedirect from "./BotRedirect";
import BotFilters from "./BotFilters";
import {SettingsContentBox, SettingsRoot} from "../General";

function Bot(props) {
    return (
        <SettingsRoot>
            <CssBaseline/>
            <Topbar/>
            <SettingsSidebar {...props}/>
            <SettingsContentBox>
                <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="center">
                    <Box>
                        <BotRedirect/>
                    </Box>
                    <Box>
                        <BotFilters/>
                    </Box>
                </Box>
            </SettingsContentBox>
        </SettingsRoot>
    );
}

export default Bot;