import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import debounce from 'lodash/debounce'
import {offerServices} from "../../service/offerService";
import PropTypes from "prop-types";
import {Autocomplete, TextField} from "@mui/material";

function SelectOffer({control, errors, countryCode, offerData, setValue}) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [dailyCapFailoverOfferData, setDailyCapFailoverOfferData] = useState(null);

    useEffect(() => {
        async function fetchData(countryCode) {
            setLoading(true);
            await offerServices.read(search, countryCode, true).then((response) => {
                setOptions(
                    response.offers.map(item => {
                        return {
                            value: item.id,
                            label: item.name
                        }
                    })
                );
            }).catch((e) => {
                console.log(e?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
        }

        if (open && (search.length === 0 || search.length > 0)) {
            fetchData(countryCode);
        }

    }, [search, open, countryCode]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearch("");
        }
    }, [open]);

    useEffect(() => {
        async function findOffer(offerId) {
            await offerServices.find(offerId).then((response) => {
                setDailyCapFailoverOfferData({
                    value: response?.id,
                    label: response?.name
                });
            }).catch((e) => {
                if (e?.response?.data?.error) {
                    console.log(e.response.data.error);
                }
            });
        }

        if (offerData !== null && offerData?.dailyCapFailoverOfferId > 0) {
            findOffer(offerData?.dailyCapFailoverOfferId);
        }
    }, [offerData]);

    useEffect(() => {
        if (dailyCapFailoverOfferData !== null) {
            setValue("dailyCapFailoverOfferId", dailyCapFailoverOfferData);
        }
    }, [control, dailyCapFailoverOfferData, setValue]);

    const handleSearch = debounce(searchTerm => {
        setSearch(searchTerm);
    }, 800);

    return (
        <Controller
            render={({field: {onChange, ...props}}) => {
                return (
                    <Autocomplete
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        options={options}
                        isOptionEqualToValue={(option, value) => {
                            if (value?.value === 0) {
                                return true;
                            }
                            return option.value === value.value
                        }}
                        getOptionLabel={(option) => option?.label}
                        loading={loading}
                        renderOption={(props, option, {selected, index}) => (
                            <li {...props} key={index}>{option.label}</li>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                error={!!errors?.dailyCapFailoverOfferId}
                                placeholder="Select an offer"
                                variant="outlined"
                                margin="dense"
                                size="small"
                                label="Redirect to offer"
                                value={search}
                                onChange={e => handleSearch(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                        onChange={(e, data) => onChange(data)}
                        {...props}
                    />
                )
            }}
            rules={
                {
                    required: true
                }
            }
            name="dailyCapFailoverOfferId"
            control={control}
            defaultValue={null}
            onChange={([, data]) => data}
        />
    );
}

SelectOffer.prototype = {
    control: PropTypes.object,
    error: PropTypes.object,
    countryCode: PropTypes.string,
    offerData: PropTypes.object
};

SelectOffer.defaultProps = {
    countryCode: ""
};

export default SelectOffer;